import * as React from 'react'
import { Button } from '@components/Button'
import { DateRange } from '@components/DateRangePicker'
import { Heading } from '@components/Heading'
import { Modal, ModalProps } from '@components/Modal'
import { Stamp } from '@components/Stamp'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { getDateRangeString } from '@lib/date/get-date-range-string'
import styles from './CsvDownloadModal.module.css'

export type CsvDownloadNoticeModalProps = ModalProps &
  Pick<CsvDownloadNoticeProps, 'onConfirmDownload' | 'dateRange'>

export const CsvDownloadNoticeModal: React.FC<CsvDownloadNoticeModalProps> = ({
  dateRange,
  onConfirmDownload,
  ...modalProps
}) => (
  <>
    <Modal {...modalProps} width={400}>
      <CsvDownloadNotice
        dateRange={dateRange}
        onConfirmDownload={onConfirmDownload}
        onClose={modalProps.onClose}
      />
    </Modal>
  </>
)

interface CsvDownloadNoticeProps {
  onConfirmDownload: () => void
  onClose: () => void
  dateRange?: DateRange
}

export const CsvDownloadNotice: React.FC<CsvDownloadNoticeProps> = ({
  dateRange,
  onClose,
  onConfirmDownload,
}) => {
  const exportMessage = dateRange
    ? `Export transactions from ${getDateRangeString(
        dateRange,
        'medium'
      )} as a CSV file. This is currently an in-development feature and the format is likely to change in the future.`
    : `Export transactions as a CSV file. This is currently an in-development feature and the format is likely to change in the future.`

  return (
    <div>
      <VSpace space={16} className={styles['content']}>
        <Stamp label="Beta feature" color="yellow" />
        <Heading h3 asElement="h2" textAlign="center">
          Export to CSV
        </Heading>
        <Text
          fontSize="C2"
          textAlign="center"
          color="grey-800"
          data-selector="fs-show"
        >
          {exportMessage}
        </Text>
      </VSpace>

      <hr className={styles['divider']} />

      <div className={styles['actions']}>
        <Button
          className={styles['download-option']}
          medium
          intent="MUTED"
          text="Dismiss"
          onClick={onClose}
        />
        <div className="u-marginRight8" />
        <Button
          className={styles['download-option']}
          medium
          fill
          intent="PRIMARY"
          text="Download .csv"
          onClick={onConfirmDownload}
        />
      </div>
    </div>
  )
}
