import { cloneDeep, orderBy } from 'lodash'
import { DuffelAPI } from '@lib/types'

export const getOrderedAICs = (
  order: DuffelAPI.Types.Order
): DuffelAPI.Types.AirlineInitiatedChange[] | null => {
  const { airlineInitiatedChanges } = order

  if (airlineInitiatedChanges && airlineInitiatedChanges?.length > 0) {
    let orderedAICs = cloneDeep(airlineInitiatedChanges)
    orderedAICs = orderBy(
      airlineInitiatedChanges,
      (change) => change.createdAt,
      ['asc']
    )
    return orderedAICs
  }

  return null
}
