import { DateRange } from '@components/DateRangePicker'

export const isDateRange = (
  value: Date | DateRange | null | undefined
): value is DateRange => {
  return (
    value !== null &&
    value !== undefined &&
    !(value instanceof Date) &&
    value.start !== undefined &&
    value.end !== undefined
  )
}
