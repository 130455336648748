const ABSOLUTE_URL_REGEX = /^https?:\/\//
const PREVIEW_HOSTNAME_REGEX = /^dashboard-?\d{4,}.app.preview.duffel.com$/
const LOCAL_HOSTNAMES = [
  'localhost:3000',
  'app.staging.duffel.com',
  'app.duffel.com',
]

export const isExternalUrl = (url: string) => {
  if (ABSOLUTE_URL_REGEX.test(url)) {
    const parser = document.createElement('a')
    parser.href = url

    return !(
      LOCAL_HOSTNAMES.some((hostname) => parser.hostname === hostname) ||
      PREVIEW_HOSTNAME_REGEX.test(parser.hostname)
    )
  } else {
    return false
  }
}
