import { FieldArray, FormikProps } from 'formik'
import { noop } from 'lodash'
import * as React from 'react'
import { animateScroll } from 'react-scroll'
import { Stamp } from '@components/Stamp'
import { Form } from '@components/Form'
import { Button } from '@components/Button'
import { VSpace } from '@components/VSpace'
import { Notice } from '@components/Notice'
import { CommonSearchFormSection } from '../CommonSearchFormSection'
import { DepartureDateField } from '../FormFields/DepartureDateField'
import { OriginDestinationFields } from '../FormFields/OriginDestinationFields'
import {
  getDefaultSlice,
  MultiCitySearchFormValues,
  Slice,
  validationSchema,
} from './lib'

interface MulticitySearchSliceFormProps {
  slice: Slice
  index: number
  removeSlice: () => void
  disableRemove: boolean
}

const MulticitySearchSliceForm: React.FC<MulticitySearchSliceFormProps> = ({
  index,
  removeSlice,
  disableRemove,
}) => (
  <div key={`slice-${index}`} className="multicity-search-slice-form">
    <div className="multicity-search-slice-form--header">
      <Stamp label={`Flight ${index + 1}`} />
      {!disableRemove && (
        <Button
          onClick={removeSlice}
          type="button"
          text="Remove"
          iconOnly="close"
          intent="MUTED"
          outlined
        />
      )}
    </div>

    <OriginDestinationFields
      originFieldName={`slices[${index}].origin`}
      destinationFieldName={`slices[${index}].destination`}
    />
    <DepartureDateField
      name={`slices[${index}].departureDate`}
      searchTimeRangesFieldName={`slices[${index}].timeRanges`}
    />

    <style jsx>{`
      .multicity-search-slice-form {
        padding: 24px;
        border: solid 1px var(--grey-200);
        border-radius: var(--border-radius-4);
      }

      .multicity-search-slice-form--header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
      }
      .multicity-search-slice-form--header h3 {
        margin: 0;
      }
    `}</style>
  </div>
)

export const MultiCitySearchForm: React.FC<{
  formik: Pick<
    FormikProps<MultiCitySearchFormValues>,
    'handleSubmit' | 'values' | 'errors'
  >
}> = ({ formik: { handleSubmit, values, errors } }) => {
  const errorCount = Object.keys(errors).length

  return (
    <Form onSubmit={handleSubmit}>
      <VSpace space={16}>
        {errorCount > 0 && (
          <Notice intent="severe" iconName="alert">
            Please fix the errors below.
          </Notice>
        )}
        <FieldArray name="slices">
          {({ remove, push }) => (
            <>
              {values.slices.map((slice, index) => (
                <div className="slice-form-container" key={index}>
                  <MulticitySearchSliceForm
                    index={index}
                    slice={slice}
                    removeSlice={() => remove(index)}
                    disableRemove={values.slices.length === 1}
                  />
                </div>
              ))}
              <div className="add-flight-btn-container">
                <Button
                  type="button"
                  text="Add another flight"
                  iconBefore="add"
                  onClick={() =>
                    push(
                      getDefaultSlice(
                        values.slices[values.slices.length - 1].destination
                      )
                    )
                  }
                  intent="MUTED"
                />
              </div>
            </>
          )}
        </FieldArray>

        <CommonSearchFormSection
          onSubmitButtonClicked={() => {
            validationSchema
              .validate(values)
              .then(noop)
              .catch(() => {
                animateScroll.scrollToTop({
                  containerId: 'workspace-main',
                  smooth: true,
                  duration: 300,
                })
              })
          }}
        />
      </VSpace>

      <style jsx>{`
        .slice-form-container {
          margin-bottom: 16px;
        }
        .add-flight-btn-container {
          margin-bottom: 16px;
        }

        :global(.callout) {
          margin-bottom: 24px;
        }
      `}</style>
    </Form>
  )
}
