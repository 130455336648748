import { DuffelAPI } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import { OrderSupport } from '../components/OrderSupport/OrderSupport'
import { SupportOrderManagementAction } from '../components/OrderSupport/types'

export const useUnavailableActionDialog = () => {
  const { addToast, openDialog, closeDialog, openAssistantChat } =
    useWorkspace()
  return {
    openSupportDialog: (
      order: DuffelAPI.Types.Order,
      actionToSupport: SupportOrderManagementAction
    ) => {
      openDialog({
        customRenderer: () => (
          <div className="u-padding24">
            <OrderSupport
              supportType="action_unavailable"
              openAssistantChat={openAssistantChat}
              actionToSupport={actionToSupport}
              order={order}
              onClose={closeDialog}
              onSubmitSuccess={() => {
                addToast({
                  title: 'Request submitted',
                  message:
                    'Thanks for sending the request. We will get in touch soon.',
                  intent: 'info',
                })
                closeDialog()
              }}
            />
          </div>
        ),
        onCancel: closeDialog,
        customActions: 'no-actions',
        width: 524,
        showCloseButton: true,
      })
    },
  }
}
