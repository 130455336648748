import * as React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { VSpace } from '@components/VSpace'
import { Condition } from '../Condition'
import { getSliceDetails, Slice } from '../lib'
import { SliceDetailsItem } from '../SliceDetailsItem'
import { SliceDetailsSummary } from '../SliceDetailsSummary'

export interface SliceDetailsProps {
  slice: Slice
  isExpanded?: boolean
  showFullDate?: boolean
  hideConditions?: boolean
  hideFareBrand?: boolean
}

export const SliceDetails: React.FC<SliceDetailsProps> = ({
  slice,
  isExpanded,
  showFullDate,
  hideConditions = false,
  hideFareBrand = false,
}) => {
  const sliceDetails = getSliceDetails(slice)
  if (sliceDetails.length === 0) return null

  return (
    <div data-selector="fs-show">
      <SliceDetailsSummary
        slice={slice}
        showFullDate={showFullDate}
        showFlightNumbers={!isExpanded}
        hideFareBrand={hideFareBrand}
      />
      <AnimatePresence initial={false}>
        {isExpanded && (
          <motion.div
            initial="collapse"
            animate="open"
            exit="collapse"
            variants={{
              open: {
                opacity: 1,
                height: 'auto',
                marginTop: '32px',
              },
              collapse: {
                opacity: 0,
                height: 0,
                marginTop: '0px',
              },
            }}
            transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <VSpace space={32}>
              {sliceDetails.map((item, index) => (
                <SliceDetailsItem item={item} key={index} />
              ))}
            </VSpace>
          </motion.div>
        )}
      </AnimatePresence>

      {isExpanded &&
        !hideConditions &&
        slice.conditions?.changeBeforeDeparture?.allowed && (
          <div className="slice-summary__condition">
            <Condition
              target="slice"
              type="changeBeforeDeparture"
              condition={slice.conditions.changeBeforeDeparture}
            />
          </div>
        )}

      <style jsx>{`
        .slice-summary__condition {
          margin-top: 24px;
          margin-left: 64px;
          max-width: 500px;
        }
      `}</style>
    </div>
  )
}
