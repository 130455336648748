const REMOVE_QUOTES_BRACKETS_CURLY_BRACKETS = /['":{}]|[\[\]]+/g
const REMOVE_DOUBLE_QUOTES_REGEX = /['"]+/g

export function stripStringDoubleQuotes(value: string) {
  if (!value) new Error('You must provide a value')
  return value.replace(REMOVE_DOUBLE_QUOTES_REGEX, '')
}

export function stripQuotesBracketsAndCurlyBrackets(value: string) {
  if (!value) new Error('You must provide a value')
  return value.replace(REMOVE_QUOTES_BRACKETS_CURLY_BRACKETS, '')
}
