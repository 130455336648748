import { DuffelAPI } from '@lib/types'

export type PassengersChangeType = 'add' | 'remove' | 'no_change'

export enum PassengerType {
  Adult = 'adult',
  Child = 'child',
}

export interface PassengersSortedByType {
  adults: DuffelAPI.Inputs.OfferRequestAdultPassenger[]
  children: DuffelAPI.Inputs.OfferRequestChildPassenger[]
}

export interface ChangeOptions {
  /** The type of change we're going to perform on the passengers data */
  changeType: PassengersChangeType

  /** The type of passenger to apply the change to */
  passengerType: PassengerType
}

/**
 * Note: Unfortunately, Formik and TypeScript says that the `FieldMetaProps.error` value
 * returned from `useField` is of type `string|undefined`, but that's not necessarily true.
 * The shape of `FieldMetaProps.error` is supposed to mirror the yup passengerSchema, which
 * in turn is supposed to mirror DuffelAPI.Inputs.OfferRequestPassenger. The `PassengerErrorObject`
 * type has been created for a more accurate representation of what this object will look like.
 */
export type PassengerErrorObject = {
  [Property in keyof Partial<DuffelAPI.Inputs.OfferRequestPassenger>]: string
}
