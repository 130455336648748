import { DuffelAPI } from '@lib/types'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.AccessToken => ({
    token: 'duffel_test_TOKEN',
    scope: 'roles/user/admin',
    name: null,
    liveMode: false,
    lastVersionUsed: null,
    lastUsedAt: null,
    expiresAt: '2020-07-25T16:57:25.314295Z',
    createdAt: '2020-07-24T16:57:25.314529Z',
    id: `token_${seedId++}`,
  })
})()

export const makeMockAccessToken = (
  extendDefault?: Partial<DuffelAPI.Types.AccessToken>
): DuffelAPI.Types.AccessToken =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
