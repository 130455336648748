import type { IncomingMessage, ServerResponse } from 'http'
import { FlashActions } from '@components/Flash'
import { clearAuthenticationCookies } from '@lib/authentication/clearAuthenticationCookies'
import { clearJWTCookies, getCookieDomain } from '@lib/cookies'
import {
  PAYLOAD_COOKIE_NAME,
  SESSION_EXPIRY_TIMEOUT_COOKIE_NAME,
} from '@lib/cookies/constants'
import { createDuffelClient } from '@lib/duffel-client'
import { redirect } from '@lib/helpers'
import {
  LAST_LIVE_MODE_COOKIE_NAME,
  LAST_ORG_COOKIE_NAME,
} from '@lib/proxy/lib/cookie'
import { setSentryUser } from '@lib/sentry'
import { clearSessionPropertiesFromCookie } from '@lib/tracking/session-tracking'

export const signOut = async (req?: IncomingMessage, res?: ServerResponse) => {
  setSentryUser(undefined)
  clearSessionPropertiesFromCookie()

  if (typeof window === 'undefined' && req && res) {
    clearJWTCookies(req, res)
  } else {
    const cookieDomain = getCookieDomain(req, true) || 'localhost'
    document.cookie = `${PAYLOAD_COOKIE_NAME}=; expires=${new Date()}; domain=${cookieDomain}; ' +
    '${SESSION_EXPIRY_TIMEOUT_COOKIE_NAME}=; expires=${new Date()}; domain=${cookieDomain}; '+
    '${LAST_ORG_COOKIE_NAME}=; expires=${new Date()}; domain=${cookieDomain}; ' + 
    '${LAST_LIVE_MODE_COOKIE_NAME}=; expires=${new Date()}; domain=${cookieDomain};}`
    await createDuffelClient().Identity.signOut()
  }

  await clearAuthenticationCookies(req, res)
}

export const signOutAndRedirect = async (
  next?: string | string[],
  req?: IncomingMessage,
  res?: ServerResponse,
  useFlashAction?: FlashActions
) => {
  await signOut(req, res)

  const searchParams = new URLSearchParams()
  if (next) {
    searchParams.append('next', encodeURIComponent(next.toString()))
  }
  if (useFlashAction) {
    searchParams.append('flash', useFlashAction)
  }
  const searchParamString = searchParams.toString()
  redirect(`/sign-in${searchParamString && `?${searchParamString}`}`, res)
}
