import * as React from 'react'
import classNames from 'classnames'
import { FormDatePicker } from '@components/DatePicker'
import { FormField } from '@components/Form'

import styles from './DateRangePicker.module.css'

export type StringDateRange = { start: string; end: string }
export type DateRange = { start: Date | null; end: Date | null }
export type ReactDatePickerRange = { from: string; to: string }

export const safelyGetDateRange = (
  value: DateRange | null,
  backwardRangeBehaviour:
    | 'clear-end-if-start-is-after'
    | 'swap-start-end' = 'swap-start-end'
): DateRange => {
  if (value === null) return { start: null, end: null }
  if (!value.start || !value.end) return value

  const { start, end } = value

  if (start > end) {
    switch (backwardRangeBehaviour) {
      case 'clear-end-if-start-is-after':
        return { start, end: null }
      case 'swap-start-end':
        return { start: end, end: start }
    }
  }

  return { start, end }
}

interface DateRangePickerProps {
  id: string
  className?: string
  value: DateRange
  onChange: (value: DateRange) => void
  onBlur?: (
    event: React.FocusEvent<Element, Element>,
    field: 'startDate' | 'endDate'
  ) => void

  startDateLabel?: string
  startDateError?: string
  endDateLabel?: string
  endDateError?: string
  disabled?: boolean
  tooltipText?: string
  skipLabels?: boolean
  shouldGoToNextTabbable?: boolean
  minStartDate?: Date
  maxEndDate?: Date
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  id,
  className,
  value,
  onChange,
  onBlur,
  startDateLabel,
  endDateLabel,
  startDateError,
  endDateError,
  disabled,
  tooltipText,
  skipLabels,
  minStartDate = null,
  maxEndDate = null,
  shouldGoToNextTabbable = true,
}) => {
  const { start, end } = safelyGetDateRange(
    value,
    'clear-end-if-start-is-after'
  )

  React.useEffect(() => {
    if (start === value.start && end === value.end) {
      return
    }

    // make sure that when we show the safe date range, we reflect the value
    // back to the state controlling this.
    onChange({ start, end })
  }, [start, end, value.start, value.end])

  const setStartDate = (value: Date | null) => onChange({ start: value, end })
  const setEndDate = (value: Date | null) => onChange({ start, end: value })

  return (
    <div className={classNames(styles['container'], className)}>
      <FormField
        htmlFor={`date-range-picker-start-date-${id}`}
        label={!skipLabels ? startDateLabel || 'Start date' : undefined}
        optionalField
        error={startDateError}
        disabled={disabled}
        tooltipText={tooltipText}
      >
        <FormDatePicker
          id={`date-range-picker-start-date-${id}`}
          value={start}
          rangeValue={{ start, end }}
          onChange={setStartDate}
          numberOfMonths={2}
          disabled={disabled}
          onBlur={(event) => {
            onBlur?.(event, 'startDate')
          }}
          onlyAllowRange={{ start: minStartDate, end: maxEndDate }}
        />
      </FormField>
      <FormField
        htmlFor={`date-range-picker-end-date-${id}`}
        label={!skipLabels ? endDateLabel || 'End date' : undefined}
        optionalField
        error={endDateError}
        disabled={disabled}
        tooltipText={tooltipText}
      >
        <FormDatePicker
          id={`date-range-picker-end-date-${id}`}
          value={end}
          onChange={setEndDate}
          rangeValue={{ start, end }}
          numberOfMonths={2}
          onlyAllowRange={{ start, end: maxEndDate }}
          disabled={disabled}
          onBlur={(event) => {
            onBlur?.(event, 'endDate')
          }}
          shouldGoToNextTabbable={shouldGoToNextTabbable}
        />
      </FormField>
    </div>
  )
}
