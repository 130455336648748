import * as React from 'react'
import { SearchMenu } from './SearchMenu'

const options = {
  total_amount: 'Least expensive',
  '-total_amount': 'Most expensive',
  total_duration: 'Shortest duration',
  '-total_duration': 'Longest duration',
}

export const SortMenu: React.FC = () => (
  <SearchMenu
    label="SORT"
    options={options}
    urlQueryParam="sort"
    selectedOptionLabelModifier=" first"
  />
)
