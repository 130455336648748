import { DuffelAPI } from '@lib/types'
import { makeMockOfferSliceSegmentPassengerBaggage } from './make-mock-offer-slice-segment-passenger-baggage'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.OfferSliceSegmentPassenger => ({
    baggages: [
      makeMockOfferSliceSegmentPassengerBaggage(),
      makeMockOfferSliceSegmentPassengerBaggage({ type: 'checked' }),
    ],
    cabinClass: 'economy',
    cabinClassMarketingName: 'Economy',
    passengerId: `pas_${(seedId++).toString().padStart(5, '0')}`,
    fareBasisCode: 'Y',
    cabin: null,
  })
})()

export const makeMockOfferSliceSegmentPassenger = (
  extendDefault?: Partial<DuffelAPI.Types.OfferSliceSegmentPassenger>
): DuffelAPI.Types.OfferSliceSegmentPassenger =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
