import { DuffelAPI } from '@lib/types'

/**
 * Returns a count of non-empty loyaltyProgrammeAccounts contained in the passed-in array.
 *
 * A non-empty account is defined by having both a loyalty programme and an account number.
 * Example of non-empty account: { airlineIataCode: 'LX', accountNumber: 'LX60' }
 */
export const getLoyaltyProgrammeAccountsCount = (
  loyaltyProgrammeAccounts: DuffelAPI.Types.LoyaltyProgrammeAccount[]
) => {
  if (!loyaltyProgrammeAccounts) {
    return 0
  }

  return loyaltyProgrammeAccounts.reduce(
    (count, { airlineIataCode, accountNumber }) =>
      count + Number(!!airlineIataCode && !!accountNumber),
    0
  )
}
