import { array, object } from 'yup'
import { iataCode, sliceDepartureDate } from '@lib/form-validation'
import { DuffelAPI } from '@lib/types'
import {
  CommonSearchFormSectionValues,
  commonValidationSchema,
  searchTimeRangesSchema,
} from '../CommonSearchFormSection'
import { sanitisePassengersLoyaltyProgrammeAccounts } from '../lib'
import {
  defaultSearchTimeRanges,
  formatTimeRangeForPayload,
  SearchTimeRanges,
} from '../lib/search-time-ranges'

export interface Slice {
  origin: string
  destination: string
  departureDate: Date | null
  timeRanges?: SearchTimeRanges
}

export interface MultiCitySearchFormValues
  extends CommonSearchFormSectionValues {
  type: 'multi_city'
  slices: Slice[]
}

export const transformValuesIntoPayload = (
  data: MultiCitySearchFormValues
): DuffelAPI.Inputs.OfferRequest => {
  const payload: DuffelAPI.Inputs.OfferRequest = {
    slices: data.slices.map(
      ({ origin, destination, departureDate, timeRanges }) => {
        const departureTime = formatTimeRangeForPayload(
          timeRanges?.departureTime
        )
        const arrivalTime = formatTimeRangeForPayload(timeRanges?.arrivalTime)
        return {
          origin,
          destination,
          departureDate: departureDate?.toISOString().split('T')[0] || '',
          ...(departureTime ? { departureTime } : {}),
          ...(arrivalTime ? { arrivalTime } : {}),
        }
      }
    ),
    // sanitise loyalty programme accounts to avoid any API errors
    passengers: sanitisePassengersLoyaltyProgrammeAccounts(data.passengers),
    supplierTimeout: data.supplierTimeout,
  }

  if (data.cabinClass !== 'any') {
    payload.cabinClass = data.cabinClass
  }

  if (data.airlines.length > 0) {
    payload.requestedSources = data.airlines
  }

  return payload
}

export const getDefaultSlice = (departingFrom: string): Slice => ({
  origin: departingFrom,
  destination: '',
  departureDate: null,
  timeRanges: defaultSearchTimeRanges,
})

export const validationSchema = object().shape({
  slices: array().of(
    object().shape({
      origin: iataCode('Origin'),
      destination: iataCode('Destination'),
      departureDate: sliceDepartureDate('departure'),
    })
  ),
  timeRanges: array().of(searchTimeRangesSchema),
  ...commonValidationSchema,
})
