import { Range } from '@components/RangeSlider'
import {
  SearchTimeRanges,
  SEARCH_TIME_OPTIONS,
} from '@modules/air-search-v2/forms/SearchForm/lib/search-time-ranges'

export const isAnyTimeRange = (range: Range) =>
  range.from === 0 && range.to === 24

export const parseSearchTimeRanges = ({
  departureTime,
  arrivalTime,
}: SearchTimeRanges) => {
  return {
    departureTimeRange: {
      from: convertTimeToHourNumber(departureTime.from, 0),
      to: convertTimeToHourNumber(departureTime.to, 24),
    },
    arrivalTimeRange: {
      from: convertTimeToHourNumber(arrivalTime.from, 0),
      to: convertTimeToHourNumber(arrivalTime.to, 24),
    },
  }
}

const convertTimeToHourNumber = (time: string, fallback: 0 | 24): number => {
  const hour = SEARCH_TIME_OPTIONS.indexOf(time)
  return hour >= 0 ? hour : fallback
}

export const isTimeRangeContained = (
  containerTimeRange: { from: string; to: string },
  timeRange: { from: string; to: string }
) =>
  containerTimeRange.from.localeCompare(timeRange.from) <= 0 &&
  containerTimeRange.to.localeCompare(timeRange.to) >= 0
