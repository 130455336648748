import { BaseClientConfig, DuffelContext } from '@lib/types'
import { BaseClient } from './lib'

export interface OrderComment {
  createdAt: {
    seconds: number
    nanoseconds: number
  }
  userId: string
  message: string
  userFullName: string
}

export class Firestore extends BaseClient {
  constructor(config: BaseClientConfig) {
    super(config)
  }

  createOrderComment(orderId: string, message: string, ctx?: DuffelContext) {
    return this.request({
      method: 'POST',
      url: '/api/firestore/order_comments',
      data: { orderId, message },
      ...this.getRequestOptions(ctx),
    })
  }

  getOrderComments(orderId: string, ctx?: DuffelContext) {
    return this.request<Array<OrderComment>>({
      method: 'GET',
      url: '/api/firestore/order_comments',
      params: { orderId },
      ...this.getRequestOptions(ctx),
    })
  }
}
