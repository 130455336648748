import {
  arrayUnion,
  deleteField,
  doc,
  DocumentData,
  DocumentReference,
  getFirestore,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { FeedCardVariantType } from '@modules/feed'
import { getUserPropertiesFromCookie } from './get-user-properties-from-cookie'
import { captureException } from '@lib/sentry'

export interface FirestoreUserProfile {
  closedTestOrderCreatedPopover?: boolean
  hasSeenWelcomeModal?: boolean
  dismissedFeedCards?: FeedCardVariantType[]
  userTypes?: string[] // not used anymore
  hasPostponedUserQuestionnaire?: boolean // not used anymore
  hasSeenAICModal?: boolean // not used anymore
  hasSeenInsightsModal?: boolean // not used anymore
}

export const getFirestoreDocRef =
  (): DocumentReference<DocumentData> | null => {
    try {
      const { user_id } = getUserPropertiesFromCookie()

      if (user_id) {
        return doc(getFirestore(), 'users', user_id)
      } else {
        captureException(
          new Error(
            "Missing user_id in user's cookie when trying to get firestore doc ref"
          )
        )
        return null
      }
    } catch (error) {
      if (error instanceof Error) {
        captureException(error)
      }

      return null
    }
  }

export const updateFirestoreUserProfile = (
  userProfileUpdate: FirestoreUserProfile
) => {
  const docRef = getFirestoreDocRef()

  return (
    (docRef && setDoc(docRef, userProfileUpdate, { merge: true })) ||
    Promise.resolve()
  )
}

export const updateFirestoreUserDismissedFeedCards = (
  dismissedFeedCard: FeedCardVariantType
) => {
  const docRef = getFirestoreDocRef()

  return (
    (docRef &&
      setDoc(
        docRef,
        {
          dismissedFeedCards: arrayUnion(dismissedFeedCard),
        },
        { merge: true }
      )) ||
    Promise.resolve()
  )
}

export const deleteFirestoreUserDismissedFeedCards = () => {
  const docRef = getFirestoreDocRef()

  return (
    (docRef &&
      updateDoc(docRef, {
        dismissedFeedCards: deleteField(),
      })) ||
    Promise.resolve()
  )
}
