import * as React from 'react'
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion'
import { Text } from '@components/legacy-design-system/product/components/Text'
import { Button } from '@components/Button'
import { VSpace } from '@components/VSpace'
import { useKeyPress } from '@lib/hooks'
import styles from './Dialog.module.css'

export interface DialogProps {
  /**
   * The heading of the dialog.
   */
  title?: string

  /**
   * The content of the Dialog.
   */
  message?: string | React.ReactNode

  /**
   * @default
   * 'Cancel
   */
  cancelButtonLabel?: string

  /**
   * @default
   * 'Confirm'
   */
  confirmButtonLabel?: string

  /**
   * Width of the Dialog in pixels
   */
  width?: number

  /**
   * @default
   * false
   */
  isConfirmButtonLoading?: boolean

  /**
   * @default
   * false
   */
  isConfirmButtonDestructive?: boolean

  /**
   * @default
   * false
   */
  isConfirmButtonDisabled?: boolean

  /**
   * @default
   * false
   */
  disableCloseOnEscapeKey?: boolean

  /**
   * @default
   * false
   */
  disableCloseOnClickOutside?: boolean

  /**
   * Overrides the default rendered content.
   */
  customRenderer?: () => React.ReactNode

  /**
   * Overrides the default 'cancel' and 'confirm' action buttons.
   */
  customActions?: (() => React.ReactNode) | 'no-actions'

  /**
   *
   * Show close button.
   */
  showCloseButton?: boolean

  /**
   * Function called when the 'Confirm' button is clicked. If `customActions` is
   * supplied, this function will not be triggered.
   */
  onConfirm?: () => void

  /**
   * Function called when the 'Cancel' button is clicked. If `customActions` is
   * supplied, this function will not be triggered.
   */
  onCancel?: () => void
}

export const Dialog: React.FC<DialogProps> = ({
  cancelButtonLabel = 'Cancel',
  confirmButtonLabel = 'Confirm',
  width = 400,
  isConfirmButtonLoading = false,
  isConfirmButtonDisabled = false,
  isConfirmButtonDestructive = false,
  disableCloseOnEscapeKey = false,
  disableCloseOnClickOutside = false,
  title,
  message,
  onCancel,
  onConfirm,
  customRenderer,
  customActions,
  showCloseButton = false,
}) => {
  const hasContent = (!!title && !!message) || !!customRenderer
  const hasAction = !!onConfirm || !!customActions

  useKeyPress('Escape', () => {
    !disableCloseOnEscapeKey && onCancel && onCancel()
  })

  if (!hasContent || !hasAction) {
    console.warn(
      'It seems like you are trying to open a dialog without passing all required props.',
      {
        title: title ? 'OK' : 'Missing',
        message: message ? 'OK' : 'Missing',
        customRenderer: customRenderer ? 'OK' : 'Missing',
        customActions: customActions ? 'OK' : 'Missing',
        onConfirm: onConfirm ? 'OK' : 'Missing',
      }
    )
  }

  return (
    <AnimatePresence>
      {hasContent && hasAction && (
        <motion.div
          className={styles['wrapper']}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
        >
          <button
            className={styles['overlay']}
            onClick={
              disableCloseOnClickOutside || isConfirmButtonLoading
                ? undefined
                : onCancel
            }
            onKeyUp={() => {
              !isConfirmButtonLoading && onCancel?.()
            }}
          />
          <LayoutGroup id="dialog">
            <motion.div
              className={styles['dialog']}
              style={{ width: `${width}px` }}
              layout
              transition={{ type: 'tween', ease: 'easeInOut', duration: 0.3 }}
            >
              <div>
                {showCloseButton && (
                  <div className={styles['dialog__close-button']}>
                    <Button
                      iconOnly="close"
                      onClick={onCancel}
                      transparent
                      text=""
                    />
                  </div>
                )}
                {customRenderer ? (
                  customRenderer()
                ) : (
                  <div className={styles['dialog__content']}>
                    <VSpace space={16}>
                      <Text
                        typeStyle="heading4"
                        asElement="h2"
                        data-selector="fs-show"
                      >
                        {title}
                      </Text>
                      <Text color="grey-700">{message}</Text>
                    </VSpace>
                  </div>
                )}
                {customActions !== 'no-actions' && (
                  <div className={styles['dialog__actions']}>
                    {customActions ? (
                      customActions()
                    ) : (
                      <>
                        {onCancel && (
                          <Button
                            text={cancelButtonLabel || 'Dismiss'}
                            onClick={onCancel}
                            disabled={isConfirmButtonLoading}
                            fill
                            medium
                            outlined
                            intent="MUTED"
                            className="u-marginRight16"
                            data-selector="fs-show"
                          />
                        )}
                        <Button
                          intent={
                            isConfirmButtonDestructive
                              ? 'DESTRUCTIVE'
                              : undefined
                          }
                          text={confirmButtonLabel || 'Confirm'}
                          onClick={onConfirm}
                          isWaiting={isConfirmButtonLoading}
                          disabled={isConfirmButtonDisabled}
                          fill
                          medium
                          data-selector="fs-show"
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            </motion.div>
          </LayoutGroup>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
