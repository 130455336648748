import * as React from 'react'
import { Text } from '@components/legacy-design-system/product/components/Text'
import { VSpace, VSpaceProps } from '@components/VSpace'

interface KeyValueItemCommonProps {
  /**
   * The key for the key/value pair.
   */
  label: string
  space?: VSpaceProps['space']
  className?: string
}

interface KeyValueItemPropsWithValue extends KeyValueItemCommonProps {
  /**
   * The value for the key/value pair. If you want to display the value as
   * something different to just a string (e.g a `Stamp`), then omit this
   * prop and add a child element.
   */
  value: string
  children?: never
}

interface KeyValueItemPropsWithChildren extends KeyValueItemCommonProps {
  value?: never
  children: React.ReactNode
}

export type KeyValueItemProps =
  | KeyValueItemPropsWithValue
  | KeyValueItemPropsWithChildren

export const KeyValueItem: React.FC<KeyValueItemProps> = ({
  label,
  space = 4,
  value,
  className,
  children,
}) => (
  <VSpace space={space} className={className}>
    <Text
      asElement="h3"
      color="grey-600"
      typeStyle="paragraph3"
      fontWeight="normal"
      data-selector="fs-show"
    >
      {label}
    </Text>
    {value && <Text typeStyle="numerical2">{value}</Text>}
    {children && children}
  </VSpace>
)
