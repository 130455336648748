import * as React from 'react'
import { snakeCase } from 'lodash'
import { Modal } from '@components/Modal'
import { ChromelessButton } from '@components/Button'
import { Icon } from '@components/Icon'
import { Text } from '@components/Text'
import { DuffelAPI } from '@lib/types'
import { transformDataKeys } from '@lib/proxy/lib'
import { trackEvent } from '@lib/tracking'
import styles from './BaggageModal.module.css'
import { CreateOrderService } from '@duffel/api/types'
import { AdditionalBaggageSelection } from './AdditionalBaggageSelection'

export interface BaggageModalProps {
  order: DuffelAPI.Types.Order
  availableServices: DuffelAPI.Types.OrderAvailableService[]
  additionalBaggage?: CreateOrderService[]
  setAdditionalBaggages: (
    value: React.SetStateAction<CreateOrderService[] | undefined>
  ) => void
  setBaggageModalOpen: (value: React.SetStateAction<boolean>) => void
  setBaggageConfirmationModalOpen: (
    value: React.SetStateAction<boolean>
  ) => void
}
export const BaggageModal: React.FC<BaggageModalProps> = ({
  order,
  availableServices,
  additionalBaggage,
  setAdditionalBaggages,
  setBaggageModalOpen,
  setBaggageConfirmationModalOpen,
}) => {
  const transformedOrder = transformDataKeys(order, snakeCase)
  const transformedAvailableServices = transformDataKeys(
    availableServices,
    snakeCase
  )
  const transformedPassengers = order.passengers.map((passenger) => {
    return {
      id: passenger.id,
      name: `${passenger.givenName} ${passenger.familyName}`,
    }
  })

  return (
    <Modal
      onClose={() => setBaggageModalOpen(false)}
      className={styles['baggage-modal']}
    >
      <div className={styles['baggage-container']}>
        <div className={styles['modal-close__container']}>
          <Text
            fontSize="H3"
            fontWeight="medium"
            className={styles['modal-title']}
          >
            Add baggage
          </Text>
          <ChromelessButton
            type="button"
            aria-label="Close"
            className={styles['modal-overlay__button']}
            onClick={() => setBaggageModalOpen(false)}
          >
            <Icon name="close" />
          </ChromelessButton>
        </div>

        <AdditionalBaggageSelection
          order={transformedOrder}
          availableServices={transformedAvailableServices}
          passengers={transformedPassengers}
          initialBaggageSelection={additionalBaggage}
          onSubmit={(additionalBaggages) => {
            trackEvent('dashboard_add_service_baggage_selection_clicked', {
              event_type: 'interaction',
            })
            setAdditionalBaggages(additionalBaggages)
            setBaggageConfirmationModalOpen(true)
            setTimeout(() => setBaggageModalOpen(false), 0)
          }}
        />
      </div>
    </Modal>
  )
}
