import * as React from 'react'
import { createPortal } from 'react-dom'

export const Portal: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isMounted, setIsMounted] = React.useState<boolean>(false)
  const ref = React.useRef<HTMLElement>()

  React.useEffect(() => {
    const portalEl = document.querySelector('#__portal__') as HTMLElement
    if (portalEl) ref.current = portalEl
    setIsMounted(true)
  }, [isMounted])

  // `as any` is needed here because it seems the `react` and `react-dom`
  // type definitons for `ReactNode` are no converging into the same type
  return isMounted && ref.current
    ? createPortal(children as any, ref.current)
    : null
}
