import { cloneDeep } from 'lodash'
import { DuffelAPI, DuffelProxy, JwtPayload } from '@lib/types'

/**
 * Sorts organisation memberships by organisation name
 *
 * @param {Object} first An organisation membership
 * @param {Object} second A second organisation membership
 *
 * @returns {Int} Returns 1, -1, or 0 based on comparison.
 */
function sortOrganisationsByName(first: any, second: any) {
  if (first.organisation.name < second.organisation.name) return -1
  if (first.organisation.name > second.organisation.name) return 1
  return 0
}

function getCorrectTimestamp({
  acceptedAt,
  revokedAt,
  expiresAt,
  createdAt,
}: any) {
  if (acceptedAt) return acceptedAt
  if (revokedAt) return revokedAt
  if (expiresAt && new Date(expiresAt) < new Date()) return expiresAt
  return createdAt
}

/**
 * Sort array based on `createdAt`
 *
 * @param {Object} first An object
 * @param {Object} second A second object
 *
 * @returns {Int} Returns 1, -1, or 0 based on comparison.
 */
function sortByCreatedAt(first: any, second: any) {
  const aCreatedAt = new Date(first.createdAt)
  const bCreatedAt = new Date(second.createdAt)

  if (aCreatedAt > bCreatedAt) return -1
  if (aCreatedAt < bCreatedAt) return 1
  return 0
}

/**
 * Sort array based on `createdAt`
 *
 * @param {Object} first An object
 * @param {Object} second A second object
 *
 * @returns {Int} Returns 1, -1, or 0 based on comparison.
 */
function sortByTimeStamp(first: any, second: any) {
  const aCreatedAt = new Date(getCorrectTimestamp(first))
  const bCreatedAt = new Date(getCorrectTimestamp(second))

  if (aCreatedAt > bCreatedAt) return -1
  if (aCreatedAt < bCreatedAt) return 1
  return 0
}

function groupTokens(prev: any, item: any) {
  const tokenKey = item.liveMode ? 'live' : 'test'
  prev[tokenKey][item.id] = item
  return prev
}

/**
 * Reduces organisations to an array of slugs
 *
 * @param {Array} prev The accumulator from reduce function
 * @param {{ organisation: Object }} membership The organisation membership
 *
 * @returns {Array} Returns accumulator
 */
function getOrganisationSlugs(prev: any, { organisation: { slug } }: any) {
  return [...prev, slug]
}

/**
 * Reduces organisation memberships into a dictionary and makes them easier to work with.
 *
 * @param {Object} prev The accumulator from reduce function
 * @param {Object} membership The organisation membership
 *
 * @returns {Array} Returns accumulator
 */
function getOrganisationBySlug(prev: any, membership: any) {
  const { organisation } = membership

  prev[organisation.slug] = {
    accessTokens: organisation.accessTokens
      .sort(sortByCreatedAt)
      .reduce(groupTokens, {
        live: {},
        test: {},
      }),
    settlementCurrency: organisation.settlementCurrency,
    avatarUrl: organisation.avatarUrl,
    createdAt: organisation.createdAt,
    isVerified: organisation.verified,
    isOwner: membership.owner,
    joinedAt: membership.createdAt,
    name: organisation.name,
    slug: organisation.slug,
    scope: membership.scope,
    id: organisation.id,
    scheduleChangeEmails: organisation.scheduleChangeEmails,
    contactEmails: organisation.contactEmails,
    verificationFlow: organisation.verificationFlow,
    isDuffelLinksEnabled: organisation.isDuffelLinksEnabled,
    stripePaymentMethodId: organisation.stripePaymentMethodId,
    stripeCustomerId: organisation.stripeCustomerId,
    sessionExpiryTimeout: organisation.sessionExpiryTimeout,
    staysAccessStatus: organisation.staysAccessStatus,
  }

  return prev
}

/**
 * Reduces array to ids
 *
 * @param {Array} prev The accumulator from reduce function
 * @param {{ id: String }} object The object with an id
 *
 * @returns {Array} Returns accumulator
 */
function getIds(prev: any, { id }: any) {
  return [...prev, id]
}

/**
 * Reduces organisationInvitations to a dictionary
 *
 * @param {Object} prev The accumulator from reduce function
 * @param {Object} invitation An invitation object
 *
 * @returns {Object} Returns accumulator
 */
function getInvitationsById(prev: any, invitation: any) {
  const { id, ...props } = invitation
  prev[id] = props
  return prev
}

/**
 * Transforms raw API response into frontend friendly response.
 *
 * @param {Object} data Raw API response
 *
 * @returns {Object} Returns frontend auth object.
 */
export function transformSelf(
  data: DuffelAPI.Types.User
): DuffelProxy.Types.Self {
  const { organisationMemberships, organisationInvitations, ...props } = data

  const sortedOrgs = organisationMemberships
    ? organisationMemberships
        .filter((membership) => !membership.disabledAt)
        .sort(sortOrganisationsByName)
    : []

  const sortedInvitations = organisationInvitations
    ? organisationInvitations.sort(sortByTimeStamp)
    : []

  return {
    organisationSlugs: sortedOrgs.reduce(getOrganisationSlugs, []),
    organisationsBySlug: sortedOrgs.reduce(getOrganisationBySlug, {}),
    invitationIds: sortedInvitations.reduce(getIds, []),
    invitationsById: sortedInvitations.reduce(getInvitationsById, {}),
    ...props,
  }
}

/**
 * Strips any sensitive data from our transformed object.
 *
 * @param {Object} data Result of `transformSelf()`
 *
 * @returns {Object} Returns safe version of `transformSelf()`
 */
export function stripAccessTokens(
  user: DuffelProxy.Types.Self
): Omit<DuffelProxy.Types.Self, 'accessTokens'> {
  const self = cloneDeep(user)

  if (!self.organisationSlugs) return self

  for (const slug of self.organisationSlugs) {
    delete self.organisationsBySlug[slug].accessTokens
  }

  return self
}

/**
 * Gets the personal token for a user
 *
 * @param self Authenticated user's self object
 *
 * @returns Aa JWT payload for a user access token
 */
export function getPersonalToken(
  self: DuffelAPI.Types.User
): JwtPayload | null {
  if (
    !self.temporaryPersonalAccessTokens ||
    !self.temporaryPersonalAccessTokens[0]
  )
    return null
  const personalToken = self.temporaryPersonalAccessTokens[0]

  return {
    scope: personalToken.scope,
    token: personalToken.token,
    liveMode: personalToken.liveMode,
    organisation: '',
    userId: self.id,
    organisationId: '',
    email: self.email,
  }
}
