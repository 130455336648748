export type ScrollLocation = 'start' | 'end' | 'middle'

interface ScrollLocations {
  scrollX: ScrollLocation
  scrollY: ScrollLocation
}

export const getScrollLocations = (element: HTMLElement): ScrollLocations => {
  let scrollX: ScrollLocation = 'middle'
  let scrollY: ScrollLocation = 'middle'

  const {
    scrollHeight,
    scrollWidth,
    scrollTop,
    scrollLeft,
    clientHeight,
    clientWidth,
  } = element

  if (scrollLeft === 0) {
    scrollX = 'start'
  } else if (scrollWidth - clientWidth === scrollLeft) {
    scrollX = 'end'
  }

  if (scrollTop === 0) {
    scrollY = 'start'
  } else if (scrollHeight - clientHeight === scrollTop) {
    scrollY = 'end'
  }

  return {
    scrollX,
    scrollY,
  }
}
