import * as React from 'react'
import { FormikInput } from '@components/Form'
import { FormikPhoneInput } from '@components/Form/FormikPhoneInput'
import { SplitFormFields } from '../../SearchForm/FormFields'

export const ContactDetails: React.FC = () => {
  return (
    <>
      <SplitFormFields>
        <FormikInput name="email" label="Email" type="email" />
        <FormikPhoneInput
          name="phoneNumber"
          label="Phone number"
          showRequired
        />
      </SplitFormFields>
      <style jsx>{`
        :global(.ff-form-input) {
          margin-bottom: 0px !important;
        }
      `}</style>
    </>
  )
}
