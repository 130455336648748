import { useFormikContext } from 'formik'
import * as React from 'react'
import { Button, ButtonProps } from '@components/Button'

type FormikSubmitButtonProps = Exclude<ButtonProps, 'type'>

export const FormikSubmitButton = React.forwardRef<
  HTMLButtonElement,
  FormikSubmitButtonProps
>(({ disabled, fill = true, isWaiting, large = true, ...props }, ref) => {
  const { isSubmitting } = useFormikContext()

  return (
    <Button
      type="submit"
      isWaiting={isWaiting || isSubmitting}
      disabled={disabled || isSubmitting}
      large={large}
      fill={fill}
      ref={ref}
      {...props}
    />
  )
})
