export default class Clipboard {
  private textArea!: HTMLTextAreaElement
  private document: Document
  private userAgent: string
  private window: Window

  constructor(window: Window) {
    this.window = window
    this.document = window.document
    this.userAgent = window.navigator.userAgent
  }

  public copyText(text: string) {
    this.createTextArea(text)
    this.selectText()
    this.copyToClipboard()
  }

  private createTextArea(text: string) {
    this.textArea = this.document.createElement('textarea')
    this.textArea.innerHTML = text
    this.document.body.appendChild(this.textArea)
  }

  private selectText() {
    if (this.isIOS) {
      const range = this.document.createRange()
      range.selectNodeContents(this.textArea)

      const selection = this.window.getSelection()
      if (!selection) return
      selection.removeAllRanges()
      selection.addRange(range)

      this.textArea.setSelectionRange(0, 99999)
    } else {
      this.textArea.select()
    }
  }

  private copyToClipboard() {
    this.document.execCommand('copy')
    this.document.body.removeChild(this.textArea)
  }

  private get isIOS() {
    return /ipad|iphone/i.test(this.userAgent)
  }
}

export const copyToClipboard = (text: string) => {
  const clip = new Clipboard(window)
  clip.copyText(text)
}
