import { AnchorButton, Button } from '@components/Button'
import { HSpace } from '@components/HSpace'
import { Menu } from '@components/Menu'
import { isDateTimeInTheFuture } from '@lib/date'
import { download } from '@lib/helpers'
import { useDuffelPopper, useNavigationStateStopper } from '@lib/hooks'
import { flightSearchV2IndexPathArray } from '@lib/paths'
import { DuffelAPI, SearchType } from '@lib/types'
import { useFeatureFlags } from '@lib/unleash'
import { useWorkspace } from '@lib/workspace-context'
import { useAddServices } from '@modules/air-order-change/lib'
import { getOrderStatus, isOrderInThePast } from '@modules/air-order/helpers'
import { isOrderCancelled } from '@modules/air-order/helpers/is-order-cancelled'
import { getDefaultAdultPassenger } from '@modules/air-search-v2/forms/SearchForm/lib'
import {
  SearchFormValues,
  getInitialSearchFormValues,
} from '@modules/air-search-v2/lib'
import { saveSearchFormValues } from '@modules/air-search-v2/lib/multi-step-search/search-params'
import { useRouter } from 'next/router'
import * as React from 'react'
import { BaggageConfirmationModal } from '../BaggageConfirmationModal/BaggageConfirmationModal'
import { BaggageModal } from '../BaggageModal/BaggageModal'
import styles from './OrderManagementMenu.module.css'
import {
  OrderManagementMenuItem,
  OrderManagementMenuItemProps,
} from './OrderManagementMenuItem/OrderManagementMenuItem'
import { CreateOrderService } from '@duffel/api/types'
import { OrderTicketWithPassengerName } from '@modules/air-order/components/OrderSummary/types'
import { VSpace } from '@components/VSpace'
import { Heading } from '@components/Heading'
import { Text } from '@components/Text'
import { getOrderTicketsWithPassengerNames } from '@modules/air-order/components/OrderSummary/helpers'

export interface OrderManagementMenuProps {
  order: DuffelAPI.Types.Order
}

// Get the best-effort search form values that could lead to similar offers to this order.
// This form values will be used to populate the initial search form upon rebooking.
// Note: This is by no means an exact replication and the user will have a chance to update any info
const getSearchFormValuesSimilarToOrder = (
  order: DuffelAPI.Types.Order
): SearchFormValues => {
  let searchType: SearchType = 'return'
  if (order.slices.length === 1) {
    searchType = 'one_way'
  }
  if (
    order.slices.length > 2 ||
    (order.slices.length === 2 &&
      // it's a multi-city flight if the origin is not the same with the final destination (e.g. LHR-JFK and JFK-LIS)...
      (order.slices[1].destination.iataCode !==
        order.slices[0].origin.iataCode ||
        // ... or if the the destination doesn't become the origin of the subsequent flight (e.g. LHR-JFK and LAX-LHR)
        order.slices[0].destination.iataCode !==
          order.slices[1].origin.iataCode))
  ) {
    searchType = 'multi_city'
  }

  const commonFormValues = {
    // we probably could be a little more specific about the passengers, but for now we will just use the default
    passengers: [getDefaultAdultPassenger()],
    // we don't have the mapping from the order's airline to sources, so we can't really be specific right now
    airlines: [],
    // we also can't extract the cabinClass yet
    cabinClass: 'economy' as const,
  }

  switch (searchType) {
    case 'one_way': {
      const departureDate = new Date(
        order.slices[0].segments[0].departingAt.split('T')[0]
      )

      return {
        type: 'one_way',
        departureDate: isDateTimeInTheFuture(departureDate)
          ? departureDate
          : null,
        origin: order.slices[0].origin.iataCode!,
        destination: order.slices[0].destination.iataCode!,
        supplierTimeout: 30000,
        ...commonFormValues,
      }
    }
    case 'return': {
      const departureDate = new Date(
        order.slices[0].segments[0].departingAt.split('T')[0]
      )
      const returnDate = new Date(
        order.slices[1].segments[0].departingAt.split('T')[0]
      )
      return {
        ...getInitialSearchFormValues(),
        origin: order.slices[0].origin.iataCode!,
        destination: order.slices[0].destination.iataCode!,
        departureDate: isDateTimeInTheFuture(departureDate)
          ? departureDate
          : null,
        returnDate: isDateTimeInTheFuture(returnDate) ? returnDate : null,
      }
    }
    case 'multi_city': {
      return {
        type: 'multi_city',
        slices: order.slices.map((slice) => {
          const departureDate = new Date(
            slice.segments[0].departingAt.split('T')[0]
          )
          return {
            departureDate: isDateTimeInTheFuture(departureDate)
              ? departureDate
              : null,
            origin: slice.origin.iataCode!,
            destination: slice.origin.iataCode!,
          }
        }),
        supplierTimeout: 30000,
        ...commonFormValues,
      }
    }
  }
}

const getTicketNumbersDialogContentRenderer = (
  orderTickets: OrderTicketWithPassengerName[]
): (() => React.ReactNode) => {
  return () => (
    <VSpace space={16} className={styles['tickets-dialog-content']}>
      <Heading h3 asElement="h2" textAlign="center">
        Ticket numbers
      </Heading>
      <VSpace space={4}>
        {orderTickets?.map((orderTicket) => (
          <Text key={`ticket_${orderTicket.uid}`} textAlign="center">
            {orderTicket.passengerName && `${orderTicket.passengerName}: `}
            {orderTicket.uid}
          </Text>
        ))}
      </VSpace>
    </VSpace>
  )
}

export const OrderManagementMenu: React.FC<OrderManagementMenuProps> = ({
  order,
}) => {
  const router = useRouter()
  const {
    addToast,
    permissions,
    currentOrganisation,
    duffelClient,
    openDialog,
    closeDialog,
  } = useWorkspace()
  const [additionalBaggages, setAdditionalBaggages] =
    React.useState<CreateOrderService[]>()
  const [baggageModalOpen, setBaggageModalOpen] = React.useState(false)
  const [baggageConfirmationModalOpen, setBaggageConfirmationModalOpen] =
    React.useState(false)
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
  const onClosePopover = () => setIsPopoverOpen(false)
  const { popper, setReferenceElement, setPopperElement } = useDuffelPopper(
    isPopoverOpen,
    onClosePopover,
    {
      placement: 'bottom-end',
      modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
    },
    {
      shouldInsideClickClose: true,
    }
  )
  const { styles: popperStyles, attributes } = popper
  const [isExporting, setIsExporting] = React.useState(false)
  const [isNavigatingToSearch, setIsNavigatingToSearch] =
    useNavigationStateStopper()
  const [availableServices, setAvailableServices] = React.useState<
    DuffelAPI.Types.OrderAvailableService[] | null
  >(null)
  const { handleAddServicesToExistingOrder, getAvailableServices } =
    useAddServices(order)
  // We can't add bags to flown orders, so no point in checking
  // TODO: this feature flag doesn't show up in unleash, so do we just never show this feature? Context seems lost
  const enableAddingBagsToExistingOrders =
    useFeatureFlags('dashboard_enable_adding_bags_to_existing_orders') &&
    !isOrderInThePast(order) &&
    !isOrderCancelled(order)

  const openTicketNumbersDialog = (
    orderTickets: OrderTicketWithPassengerName[]
  ) => {
    openDialog({
      customRenderer: getTicketNumbersDialogContentRenderer(orderTickets),
      onConfirm: closeDialog,
      confirmButtonLabel: 'Close',
    })
  }

  const orderTickets =
    order.documents &&
    getOrderTicketsWithPassengerNames(order.documents, order.passengers)

  const hasTickets = orderTickets && orderTickets.length > 0

  // @TODO: remove this and replace with ORDER_MANAGEMENT_ACTIONS
  const IMPLEMENTED_ORDER_MANAGEMENT_ACTIONS: OrderManagementMenuItemProps['orderManagementAction'][] =
    [
      'change_order',
      'cancel_order',
      'add_seats',
      'add_bags',
      'name_correction',
      'dashboard_other',
    ]

  // Check whether services are available for the order. The platform currently supports adding bags only.
  React.useEffect(() => {
    if (!enableAddingBagsToExistingOrders) {
      return
    }

    if (availableServices === null) {
      const getServices = async () => {
        const availableServices = await getAvailableServices()
        setAvailableServices(availableServices)
      }
      getServices()
    }
  }, [order, enableAddingBagsToExistingOrders])

  React.useEffect(() => {
    if (baggageModalOpen) {
      setIsPopoverOpen(false)
    }
  }, [baggageModalOpen])

  return (
    <>
      <div className={styles['menu']}>
        <HSpace space={8}>
          {hasTickets && (
            <Button
              text="Ticket numbers"
              intent="MUTED"
              outlined
              onClick={() => openTicketNumbersDialog(orderTickets)}
            />
          )}
          <Button
            text="Export itinerary"
            intent="MUTED"
            outlined
            isWaiting={isExporting}
            onClick={async () => {
              setIsExporting(true)
              const { data, errors } =
                await duffelClient.Manage.exportPdfItinerary(
                  order.id,
                  currentOrganisation,
                  permissions?.liveMode
                )
              if (errors || !data) {
                addToast({
                  title: 'Export failed',
                  message: 'Something went wrong. Please try again later',
                  intent: 'error',
                })
                setIsExporting(false)
                return
              }

              download(data.content, data.fileName, 'application/pdf')
              addToast({
                title: 'Success',
                message: 'The download will begin shortly',
                intent: 'success',
              })
              setIsExporting(false)
            }}
          />
          {getOrderStatus(order) === 'expired' ? (
            <AnchorButton
              ref={setReferenceElement}
              text="Search for similar flights"
              onClick={async () => {
                setIsNavigatingToSearch(true)
                router.push(
                  ...flightSearchV2IndexPathArray(
                    permissions?.organisation,
                    permissions?.liveMode,
                    await saveSearchFormValues(
                      getSearchFormValuesSimilarToOrder(order)
                    )
                  )
                )
              }}
              isWaiting={isNavigatingToSearch}
            />
          ) : (
            <Button
              intent="MUTED"
              iconAfter="arrow_drop_down"
              ref={setReferenceElement}
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              text="Manage this order"
            />
          )}
        </HSpace>

        {isPopoverOpen && (
          <div
            className={styles['popover']}
            ref={setPopperElement}
            style={{ ...popperStyles.popper }}
            {...attributes}
          >
            <Menu>
              {IMPLEMENTED_ORDER_MANAGEMENT_ACTIONS.map(
                (orderManagementAction) => {
                  return (
                    <OrderManagementMenuItem
                      key={orderManagementAction}
                      order={order}
                      orderManagementAction={orderManagementAction}
                      availableServices={availableServices}
                      setBaggageModalOpen={setBaggageModalOpen}
                      setBaggageConfirmationModalOpen={
                        setBaggageConfirmationModalOpen
                      }
                    />
                  )
                }
              )}
            </Menu>
          </div>
        )}
      </div>

      {baggageModalOpen && availableServices !== null && (
        <BaggageModal
          additionalBaggage={additionalBaggages}
          order={order}
          availableServices={availableServices}
          setAdditionalBaggages={setAdditionalBaggages}
          setBaggageModalOpen={setBaggageModalOpen}
          setBaggageConfirmationModalOpen={setBaggageConfirmationModalOpen}
        />
      )}

      {baggageConfirmationModalOpen && additionalBaggages && (
        <BaggageConfirmationModal
          additionalBaggages={additionalBaggages}
          availableServices={availableServices}
          passengers={order.passengers}
          setAdditionalBaggages={setAdditionalBaggages}
          setBaggageModalOpen={setBaggageModalOpen}
          setBaggageConfirmationModalOpen={setBaggageConfirmationModalOpen}
          handleAddServicesToExistingOrder={handleAddServicesToExistingOrder}
        />
      )}
    </>
  )
}
