import * as React from 'react'
import { differenceWith, isEqual, uniqWith } from 'lodash'
import { ColorWithoutWeight } from '@components/legacy-design-system/product/styles'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { TabButtons, TabOption } from '@components/TabButtons'
import { HSpace } from '@components/HSpace'
import {
  OrderInformationBox,
  OrderInformationBoxContent,
  OrderInformationBoxDivider,
} from '@components/OrderInformationBox'
import { Callout } from '@components/Callout'
import { DuffelAPI, SliceDetailItemChangeStatus } from '@lib/types'
import { getDateString } from '@lib/date'
import { trackEvent } from '@lib/tracking'
import {
  getBeforeAndAfterSegmentsWithAICs,
  getLatestAIC,
  getOrderStatus,
} from '@modules/air-order'
import {
  getBeforeAndAfterSlicesAndSegmentsWithAICsByOriginDestinationKey,
  Slice,
} from '@modules/air-order/helpers/get-before-and-after-slices-with-aics-by-origin-destinaton-key'
import {
  getDiffsInBeforeAndAfterObjects,
  getLayoverDetails,
  getOriginDestinationKey,
  getLayoverDiffs,
  getSegmentsInDiffForm,
  getSliceDetails,
  getSliceWithComparableProperties,
  getTravelDetailsWithComparableProperties,
} from '../lib'
import { SliceDetailsLayoverItem } from '../SliceDetailsLayoverItem'
import { SliceDetailsTravelItem } from '../SliceDetailsTravelItem'
import { FlightCancelled } from '../FlightCancelled'
import { ChangeSummary } from '../ChangeSummary'
import { getChangedProperties } from '../lib/get-changed-properties'
import { SliceDetailsSummaryCompact } from '../SliceDetailsSummaryCompact'
import { FlightSummaryWithAICsActionButtons } from './FlightSummaryWithAICsActionButtons'
import styles from './FlightSummaryWithAICs.module.css'

export interface FlightSummaryWithAICsProps {
  order: DuffelAPI.Types.Order
  isOrderManagementEnabled?: boolean
}

export const FlightSummaryWithAICs: React.FC<FlightSummaryWithAICsProps> = ({
  order,
  isOrderManagementEnabled,
}) => {
  // If there are slices with non-unique keys the new AIC might break, so fall back to old view for now
  // TODO If we're going to keep the old view around we can combine, merge & clean up some of the code
  //  Initially we assumed we would migrate fully and delete the old stuff (see TODO a few lines below)
  //  This needs to be tested with users before we make the final decision
  const newAICViewMightBreak =
    new Set(order.slices.map((slice) => getOriginDestinationKey(slice)))
      .size !== order.slices.length
  if (newAICViewMightBreak) {
    trackEvent(`dashboard_order_incompatible_aic_view_visited`, {
      event_type: 'interaction',
    })
  }

  const orderStatus = getOrderStatus(order)

  const latestAIC = getLatestAIC(order)
  const latestAICTimestamp = latestAIC?.createdAt

  let hasInvalidLayovers = false

  type Tab = 'new' | 'previous'
  const [tab, setTab] = React.useState<Tab>('new')
  const tabButtonOptions: Record<string, TabOption> = {
    new: {
      label: 'New details',
    },
    previous: {
      label: 'Previous details',
    },
  }

  const { beforeSlices, afterSlices } =
    getBeforeAndAfterSlicesAndSegmentsWithAICsByOriginDestinationKey(order)

  const beforeSlicesDetails = beforeSlices.map((slice) =>
    getSliceDetails(slice)
  )
  const afterSlicesDetails = afterSlices.map((slice) => getSliceDetails(slice))

  const isFullCancellation =
    afterSlices.length === 0 || afterSlices.every((slice) => slice.isCancelled)

  const getTabInfo = (section: 'before' | 'after') =>
    section === 'before'
      ? {
          slices: beforeSlices,
          slicesDetails: beforeSlicesDetails,
          correspondingSlices: afterSlices,
          correspondingSlicesDetails: afterSlicesDetails,
          getHighlightColor: (_changed?: boolean): ColorWithoutWeight => 'pink',
          getLayoverHighlightColor: (
            changeStatus: SliceDetailItemChangeStatus
          ): ColorWithoutWeight =>
            changeStatus === 'removed' ? 'pink' : 'grey',
        }
      : {
          slices: afterSlices,
          slicesDetails: afterSlicesDetails,
          correspondingSlices: beforeSlices,
          correspondingSlicesDetails: beforeSlicesDetails,
          getHighlightColor: (changed?: boolean): ColorWithoutWeight =>
            changed ? 'yellow' : 'green',
          getLayoverHighlightColor: (
            changeStatus: SliceDetailItemChangeStatus
          ): ColorWithoutWeight => {
            switch (changeStatus) {
              case 'added':
                return 'green'
              case 'changed':
                return 'yellow'
              default:
                return 'grey'
            }
          },
        }

  const renderAICDiffSection = (section: 'before' | 'after') => {
    const {
      slices,
      slicesDetails,
      correspondingSlices,
      correspondingSlicesDetails,
      getHighlightColor,
      getLayoverHighlightColor,
    } = getTabInfo(section)

    return (
      <VSpace space={24}>
        {slices.map((slice: Slice, index: number) => {
          if (slice.isCancelled) {
            return (
              <>
                <OrderInformationBoxContent>
                  <FlightCancelled
                    from={slice.origin.iataCode}
                    to={slice.destination.iataCode}
                  />
                </OrderInformationBoxContent>
                {index < slices.length - 1 && (
                  <OrderInformationBoxDivider dashed />
                )}
              </>
            )
          }

          const sliceDetails = slicesDetails[index]

          const sliceKey = getOriginDestinationKey(slice)
          const correspondingIndex = correspondingSlices.findIndex(
            (sliceToCheck: Slice) =>
              getOriginDestinationKey(sliceToCheck) === sliceKey &&
              !sliceToCheck.isCancelled
          )

          let sliceDiff: string[] = []
          if (correspondingIndex > -1) {
            const correspondingSlice = correspondingSlices[correspondingIndex]
            const correspondingSliceDetails =
              correspondingSlicesDetails[correspondingIndex]

            sliceDiff = getDiffsInBeforeAndAfterObjects(
              getSliceWithComparableProperties(slice, sliceDetails),
              getSliceWithComparableProperties(
                correspondingSlice,
                correspondingSliceDetails
              )
            )
          }

          const returnFlightName = index === 0 ? 'Outbound f' : 'Inbound f'

          return (
            <div key={slice.id} data-selector="fs-show">
              <OrderInformationBoxContent>
                <VSpace space={16}>
                  <HSpace space={8}>
                    <Text fontWeight="medium">
                      {slices.length === 2 ? returnFlightName : 'F'}
                      light to {slice.destination.iataCode}
                    </Text>
                    <Text color="grey-600">
                      {getDateString(
                        sliceDetails[0].travelDetails?.departingAt || null,
                        'medium'
                      )}
                    </Text>
                  </HSpace>
                  <SliceDetailsSummaryCompact
                    slice={slice}
                    withAirlineLogo
                    fill
                    highlightAll={correspondingIndex === -1}
                    keysToHighlight={sliceDiff}
                    highlightColor={getHighlightColor(correspondingIndex > -1)}
                    className={styles['flight-summary__slice-summary']}
                  />
                </VSpace>

                {sliceDetails.map((item, index: number) => {
                  if (item.type === 'travel') {
                    const travelDetails = sliceDetails[index].travelDetails!

                    let travelDiff: string[] = []
                    let correspondingItem
                    if (correspondingIndex > -1) {
                      correspondingItem = correspondingSlicesDetails[
                        correspondingIndex
                      ].find(
                        (itemToCheck) =>
                          itemToCheck.travelDetails &&
                          getOriginDestinationKey(itemToCheck.travelDetails) ===
                            getOriginDestinationKey(travelDetails)
                      )
                      if (correspondingItem?.travelDetails) {
                        travelDiff = getDiffsInBeforeAndAfterObjects(
                          getTravelDetailsWithComparableProperties(
                            travelDetails
                          ),
                          getTravelDetailsWithComparableProperties(
                            correspondingItem.travelDetails
                          )
                        )
                      }
                    }

                    return (
                      <SliceDetailsTravelItem
                        key={item.id + index}
                        travelDetails={travelDetails}
                        highlightAll={!correspondingItem}
                        keysToHighlight={travelDiff}
                        highlightColor={getHighlightColor(
                          correspondingIndex > -1
                        )}
                      />
                    )
                  } else if (item.type === 'layover') {
                    const layoverDetails = getLayoverDetails(item)!

                    let layoverDiff: string[] = []
                    let changeStatus: SliceDetailItemChangeStatus =
                      section === 'before' ? 'removed' : 'added'
                    if (correspondingIndex > -1) {
                      const correspondingLayover = correspondingSlicesDetails[
                        correspondingIndex
                      ].find(
                        (itemToCheck) =>
                          itemToCheck.layoverDetails?.originDestinationKey ===
                          layoverDetails?.originDestinationKey
                      )
                      layoverDiff = getDiffsInBeforeAndAfterObjects(
                        layoverDetails,
                        correspondingLayover?.layoverDetails || {}
                      )

                      if (section === 'before') {
                        changeStatus = layoverDiff.includes('duration')
                          ? 'removed'
                          : 'no_change'
                      } else {
                        changeStatus = layoverDiff.includes('duration')
                          ? 'changed'
                          : 'no_change'
                      }
                    }

                    if (layoverDetails?.duration.charAt(0) === '-') {
                      hasInvalidLayovers = true
                    }

                    return (
                      <SliceDetailsLayoverItem
                        key={`${item.type}-${index}`}
                        layoverDetails={layoverDetails}
                        changeStatus={changeStatus}
                        className={
                          styles[
                            `stamp--dark-` +
                              getLayoverHighlightColor(
                                changeStatus
                              ).toLowerCase()
                          ]
                        }
                      />
                    )
                  }
                })}
              </OrderInformationBoxContent>

              {index < slices.length - 1 && (
                <OrderInformationBoxDivider dashed />
              )}
            </div>
          )
        })}
      </VSpace>
    )
  }

  const renderChangeSummary = () => {
    const { beforeSegments, afterSegments } = getBeforeAndAfterSegmentsWithAICs(
      beforeSlices,
      afterSlices.filter((slice) => !slice.isCancelled)
    )

    const { beforeSegmentsForDiff, afterSegmentsForDiff } =
      getSegmentsInDiffForm(beforeSegments, afterSegments)

    const segmentsWithADiff = differenceWith(
      beforeSegmentsForDiff,
      afterSegmentsForDiff,
      isEqual
    ).map((segment) => segment.originDestination)

    // Create changed property diffs and de-duplicate entries
    // caused by multiple AICs affecting the same segment.
    let changedProperties = getChangedProperties(
      segmentsWithADiff,
      beforeSegmentsForDiff,
      afterSegmentsForDiff
    )
    changedProperties = uniqWith(changedProperties, isEqual)

    // Create layover diffs
    const layoverDiffs = getLayoverDiffs(beforeSlices, afterSlices)

    return (
      <OrderInformationBoxContent>
        <ChangeSummary
          beforeSegments={beforeSegmentsForDiff}
          afterSegments={afterSegmentsForDiff}
          segmentsWithADiff={segmentsWithADiff}
          changedProperties={changedProperties}
          layoverDiffs={layoverDiffs}
        />
      </OrderInformationBoxContent>
    )
  }

  if (!latestAIC || !latestAICTimestamp) {
    return null
  }

  return (
    <OrderInformationBox
      stampColor="yellow"
      buttons={
        orderStatus !== 'pending' && latestAIC && isOrderManagementEnabled ? (
          <FlightSummaryWithAICsActionButtons
            order={order}
            isFullCancellation={isFullCancellation}
            latestAIC={latestAIC}
            hasInvalidLayovers={hasInvalidLayovers}
          />
        ) : undefined
      }
    >
      {renderChangeSummary()}
      {/* TODO Add who and when accepted AICs once information is available from the API */}
      {orderStatus === 'pending' && (
        <Callout iconName="info" intent="secondary">
          Airline initiated changes were accepted and are pending confirmation
          by the Duffel Travel Operations team.
        </Callout>
      )}
      <TabButtons
        value={tab}
        onChange={(value: Tab) => {
          trackEvent(`dashboard_order_aic_tab_clicked`, {
            event_type: 'interaction',
            value,
          })
          setTab(value)
        }}
        options={tabButtonOptions}
        className={styles['flight-summary__tabs']}
        data-selector="fs-show"
      />
      {tab === 'previous' && renderAICDiffSection('before')}
      {tab === 'new' && renderAICDiffSection('after')}{' '}
    </OrderInformationBox>
  )
}
