import * as React from 'react'
import { Anchor } from '@components/Anchor'
import { Button } from '@components/Button'
import { HSpace } from '@components/HSpace'
import { Modal, ModalProps } from '@components/Modal'
import { Switch } from '@components/Switch'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { ConsentManagerBuilderChildrenRenderProps } from './types'
import styles from './AnalyticsConsentManager.module.css'

export interface AnalyticsConsentManagerModalProps
  extends Pick<ModalProps, 'onClose'> {
  onDenyCookies: () => void
  onAcceptCookies: () => void
  preferences: ConsentManagerBuilderChildrenRenderProps['preferences']
}

export const AnalyticsConsentManagerModal: React.FC<
  AnalyticsConsentManagerModalProps
> = ({ onClose, onDenyCookies, onAcceptCookies, preferences }) => {
  const [willAcceptAnalyticsCookies, setWillAcceptAnalyticsCookies] =
    React.useState(() => {
      const preferencesList = Object.entries(preferences)
      return (
        preferencesList.length > 0 &&
        preferencesList.every(([_, value]) => value)
      )
    })

  return (
    <Modal
      onClose={onClose}
      className="consent-manager-modal"
      shouldCloseOverlayOnClick
    >
      <style jsx>{`
        :global(.consent-manager-modal) {
          background-color: var(--white);
          max-width: 670px;
          padding: var(--space-32);
          border-radius: var(--border-radius-4);
        }
      `}</style>
      <VSpace space={48}>
        <VSpace space={32}>
          <VSpace space={16}>
            <Text fontSize="H3">Cookie Settings</Text>
            <Text>
              Duffel uses cookies to improve your experience and for marketing.
              Review and manage your cookie settings below to control your
              privacy.
            </Text>
            <HSpace space={16}>
              <Anchor href="https://duffel.com/cookies-policy" target="_blank">
                Cookie policy
              </Anchor>
              <Anchor href="https://duffel.com/privacy-policy" target="_blank">
                Privacy policy
              </Anchor>
            </HSpace>
          </VSpace>
        </VSpace>

        <VSpace space={24}>
          <Text fontSize="H3">Manage Preferences</Text>
          <VSpace space={8}>
            <HSpace
              className={styles['consent-manager-modal__control']}
              space={32}
            >
              <Text fontWeight="medium">Strickly necessary cookies</Text>
              <Text
                fontSize="C2"
                color="grey-700"
                className={
                  styles['consent-manager-modal__control__always-active']
                }
              >
                Always active
              </Text>
            </HSpace>
            <Text>
              These cookies are necessary for the website to function and cannot
              be switched off in our systems.
            </Text>
          </VSpace>

          <VSpace space={8}>
            <HSpace
              className={styles['consent-manager-modal__control']}
              space={32}
            >
              <Text fontWeight="medium">Analytics and Marketing</Text>
              <div>
                <Switch
                  switchedOn={willAcceptAnalyticsCookies}
                  onChange={setWillAcceptAnalyticsCookies}
                  type="button"
                />
              </div>
            </HSpace>

            <Text>
              These cookies allow us to count visits and traffic sources so we
              can measure and improve the performance of our site. They help us
              to know which pages are the most and least popular and see how
              visitors move around the site.
            </Text>
          </VSpace>
          <Button
            onClick={() => {
              if (willAcceptAnalyticsCookies) onAcceptCookies()
              else onDenyCookies()
            }}
            text="Save my preferences"
          />
        </VSpace>
      </VSpace>
    </Modal>
  )
}
