import type { ServerResponse } from 'http'
import Router from 'next/router'

export const redirect = async (toPage: string, withRes?: ServerResponse) => {
  if (withRes) {
    withRes.writeHead(302, { Location: toPage })
    withRes.end()
  } else {
    Router.push(toPage)
  }
}
