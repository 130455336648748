import { capitalize, startCase } from 'lodash'
import * as React from 'react'
import { HSpace } from '@components/HSpace'
import { Stamp } from '@components/Stamp'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { moneyStringFormatter } from '@lib/helpers'
import { captureException } from '@lib/sentry'
import { DuffelAPI } from '@lib/types'
import { getDateLabel, getSearchType } from '@modules/air-search-v2'
import { OrderChangeResultsHeaderTitle } from '../OrderChangeResultsHeader/OrderChangeResultsHeaderTitle'

interface OrderChangeOfferHeaderProps {
  order: DuffelAPI.Types.Order
  orderChangeRequest: DuffelAPI.Types.OrderChangeRequest
  orderChange: DuffelAPI.Types.OrderChange
}

export const OrderChangeHeader: React.FC<OrderChangeOfferHeaderProps> = ({
  order,
  orderChange,
}) => {
  const searchType = getSearchType(order.slices)
  if (searchType === null) {
    captureException(
      Error('Unknown search type - offer request has no slices'),
      {
        offerRequestId: order.id,
        slices: order.slices,
      }
    )
  }

  const changeWillRefund = !!orderChange.changeTotalAmount?.startsWith('-')

  return (
    <div>
      <div className="search-results-header__info">
        <VSpace space={16}>
          <HSpace space={16}>
            <Stamp
              color="grey"
              size="medium"
              label={capitalize(searchType!.replace('_', ' '))}
            />
            <Stamp color="grey" size="medium" label={getDateLabel(order)} />
            <Stamp
              color="grey"
              size="medium"
              label={startCase(
                `${order.passengers.length} passenger${
                  order.passengers.length > 1 ? 's' : ''
                }`
              )}
            />
          </HSpace>
          {searchType && <OrderChangeResultsHeaderTitle order={order} />}
        </VSpace>
        <VSpace space={4}>
          <Text textAlign="right" color="grey-600">
            {changeWillRefund ? 'Refund amount' : 'Cost of change'}
          </Text>
          <Text
            fontSize="H3"
            fontWeight="medium"
            asElement="h2"
            textAlign="right"
          >
            {moneyStringFormatter(orderChange.newTotalCurrency, undefined, {
              signDisplay: 'never',
            })(+orderChange.changeTotalAmount)}
          </Text>
        </VSpace>
      </div>

      <style jsx>{`
        .search-results-header__info {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .search-results-header__info > div {
          display: flex;
          align-items: center;
        }

        .search-results-header__info > div > div {
          margin-right: var(--space-16);
        }

        // TODO: we probably should find a way to remove this. This class is modifying something outside its subtree...
        :global(.edit-search-modal) {
          min-width: 500px !important;
          width: 500px !important;
          max-width: 500px !important;
        }
      `}</style>
    </div>
  )
}
