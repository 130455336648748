import * as React from 'react'
import { Stamp } from '@components/Stamp'
import { Icon } from '@components/Icon'

export interface PassengerLoyaltyAccountsFormHeaderProps {
  /**
   * Title to display in the header
   * Will either be a generic title based on the type of passenger
   * or will show passengers name if it has been provided
   */
  title: string

  /**
   * State of the PassengerLoyaltyAccountsFormDrawer
   * This determines which icon is displayed
   */
  isDrawerOpen?: boolean

  /**
   * onClick even handler for when this component is clicked
   */
  onClick?: (e: React.SyntheticEvent) => void

  /**
   * This is number of non-empty loyalty programme accounts that have
   * been applied through the LoyaltyAccountInput component
   */
  accountsApplied?: number
}

export const PassengerLoyaltyAccountsFormHeader: React.FC<
  PassengerLoyaltyAccountsFormHeaderProps
> = ({ title, isDrawerOpen = false, onClick, accountsApplied = 0 }) => {
  const generateStampText = (accountsApplied: number) =>
    `${accountsApplied > 0 ? accountsApplied : 'No'} account${
      accountsApplied !== 1 ? 's' : ''
    } added`

  return (
    <button
      className={
        isDrawerOpen
          ? 'loyalty-code__header loyalty-code__header--open'
          : 'loyalty-code__header'
      }
      onClick={onClick}
      data-testid="loyalty-code__header"
    >
      <span
        className="loyalty-code-header__title"
        data-testid="loyalty-code-header__title"
        data-selector="fs-mask"
      >
        {title}
      </span>
      <div className="loyalty-code-header__status">
        <Stamp
          size="medium"
          color={accountsApplied > 0 ? 'green' : 'grey'}
          label={generateStampText(accountsApplied)}
          borderRadius="full"
          data-testid="loyalty-code-header__stamp"
        />
        <Icon
          name={isDrawerOpen ? 'unfold_less' : 'unfold_more'}
          className="loyalty-code-header__icon"
        />
      </div>
      <style jsx>{`
        .loyalty-code__header {
          align-items: center;
          border-radius: var(--border-radius-6);
          display: grid;
          grid-auto-flow: column;
          justify-content: space-between;
          margin: -1px; /* to eliminate the "double border" effect */
          padding: var(--space-12) var(--space-12) var(--space-12)
            var(--space-16);
          border: 1px solid var(--grey-300);
          width: calc(100% + 2px); // 1px * 2 to account for the borders
        }

        .loyalty-code__header:hover {
          border-color: var(--purple-500);
          cursor: pointer;
        }

        .loyalty-code__header--open {
          border-color: var(--grey-300);
        }

        .loyalty-code-header__status {
          display: flex;
          gap: var(--space-24);
          justify-items: end;
        }

        /* :global is necessary so to override the color of the icon */
        :global(.loyalty-code-header__icon) {
          color: var(--grey-500);
        }

        .loyalty-code-header__title {
          font-size: var(--FONT-SIZES-C1);
        }
      `}</style>
    </button>
  )
}
