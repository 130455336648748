import { IncomingMessage } from 'http'
import { DUFFEL_API_AUTHENTICATION_COOKIE_NAME } from './constants'
import cookie from 'cookie'

export function getDuffelAPIAuthenticationCookie(req?: IncomingMessage) {
  const cleanCookieString = (req?.headers.cookie || document.cookie).replace(
    `${DUFFEL_API_AUTHENTICATION_COOKIE_NAME}=; `,
    ''
  )
  const cookies = cookie.parse(cleanCookieString)
  return `${DUFFEL_API_AUTHENTICATION_COOKIE_NAME}=${cookies[DUFFEL_API_AUTHENTICATION_COOKIE_NAME]}`
}
