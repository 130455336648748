import React from 'react'

interface PopoverContextType {
  popoverCount: number
  incrementPopoverCount: () => void
  decrementPopoverCount: () => void
}

const noOp = () => {
  console.warn(
    'This function has not been properly initialised by the PopoverContext'
  )
}

const PopoverContext = React.createContext<PopoverContextType>({
  popoverCount: 0,
  incrementPopoverCount: noOp,
  decrementPopoverCount: noOp,
})

export const WithPopoverContext: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [popoverCount, setPopoverCount] = React.useState(0)
  return (
    <PopoverContext.Provider
      value={{
        popoverCount,
        // going to use useCallback here to keep the same reference as the consuming component
        // may include this inside useEffect's dependencies
        incrementPopoverCount: React.useCallback(
          () => setPopoverCount((prev) => prev + 1),
          []
        ),
        decrementPopoverCount: React.useCallback(
          () => setPopoverCount((prev) => prev - 1),
          []
        ),
      }}
    >
      {children}
    </PopoverContext.Provider>
  )
}

export const usePopoverContext = () => React.useContext(PopoverContext)
