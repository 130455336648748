import * as React from 'react'
import { SplitFormFields } from '../SplitFormFields'
import { FormikIataCodeLookup } from './FormikIataCodeLookup'

interface OriginDestinationFieldsProps {
  originFieldName: string
  destinationFieldName: string
  disabled?: boolean
  tooltipText?: string
}

export const OriginDestinationFields: React.FC<
  OriginDestinationFieldsProps
> = ({ originFieldName, destinationFieldName, disabled, tooltipText }) => {
  return (
    <SplitFormFields>
      <FormikIataCodeLookup
        label="Origin"
        name={originFieldName}
        disabled={disabled}
        tooltipText={tooltipText}
      />
      <FormikIataCodeLookup
        label="Destination"
        name={destinationFieldName}
        disabled={disabled}
        tooltipText={tooltipText}
      />
    </SplitFormFields>
  )
}
