import { useField, useFormikContext } from 'formik'
import * as React from 'react'
import { DateRangePicker } from '@components/DateRangePicker'
import { SearchType } from '@lib/types'
import { SearchFormValues } from '@modules/air-search-v2/lib'
import { FormikSearchTimeFilters } from '@modules/air-search-v2/components/SearchTimeFilters/FormikSearchTimeFilters'

import styles from './DepartureReturnDateRangeField.module.css'
interface DepartureReturnDateRangeFieldProps {
  disabled?: boolean
  tooltipText?: string
  includesSearchTimeFilters?: boolean
}

export const DepartureReturnDateRangeField: React.FC<
  DepartureReturnDateRangeFieldProps
> = ({ disabled, tooltipText, includesSearchTimeFilters }) => {
  const { setFieldValue, isSubmitting, handleBlur } =
    useFormikContext<SearchFormValues<SearchType>>()
  const [departureField, departureFieldProps] = useField({
    name: 'departureDate',
  })
  const [returnField, returnFieldProps] = useField({ name: 'returnDate' })
  const isDisabled = isSubmitting || disabled

  return (
    <div className="departure-return-date-range-field">
      <DateRangePicker
        disabled={isDisabled}
        tooltipText={tooltipText}
        id="return-form-dates"
        value={{ start: departureField.value, end: returnField.value }}
        startDateLabel="Departure date"
        startDateError={
          departureFieldProps.touched ? departureFieldProps.error : undefined
        }
        endDateLabel="Return date"
        endDateError={
          returnFieldProps.touched ? returnFieldProps.error : undefined
        }
        onChange={(value) => {
          if (!value) return

          const { start: departureDate, end: returnDate } = value
          setFieldValue('departureDate', departureDate, true)
          // HACK: need to set timeout here otherwise the validation wouldn't run properly for the first setter
          setTimeout(() => {
            setFieldValue('returnDate', returnDate, true)
          }, 0)
        }}
        onBlur={(event, field) => {
          handleBlur(field === 'startDate' ? 'departureDate' : 'returnDate')(
            event
          )
        }}
      />
      {includesSearchTimeFilters && (
        <div className={styles['search-time-filters']}>
          <FormikSearchTimeFilters name="departureTimeRanges" />
          <FormikSearchTimeFilters name="returnTimeRanges" />
        </div>
      )}
      <style jsx>{`
        .departure-return-date-range-field {
          margin-bottom: 24px;
        }
      `}</style>
    </div>
  )
}
