import { array, object } from 'yup'
import { iataCode, sliceDepartureDate } from '@lib/form-validation'
import { DuffelAPI } from '@lib/types'

export const getCreateOrderChangeFormValidationSchema = (
  _order: DuffelAPI.Types.Order
) =>
  object().shape({
    slices: array().of(
      object().shape({
        origin: iataCode('Origin'),
        destination: iataCode('Destination'),
        departureDate: sliceDepartureDate('departure'),
      })
    ),
  })
