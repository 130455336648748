import { DuffelAPI } from '@lib/types'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.OrderDocument => {
    return {
      passengerIds: [`pas_${(seedId++).toString().padStart(5, '0')}`],
      type: 'electronic_ticket',
      uniqueIdentifier: (seedId++).toString().padStart(13, '0'),
    }
  }
})()

export const makeMockOrderDocument = (
  extendDefault?: Partial<DuffelAPI.Types.OrderDocument>
): DuffelAPI.Types.OrderDocument =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
