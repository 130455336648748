import { useFormikContext } from 'formik'
import * as React from 'react'
import { DuffelAPI, SearchType } from '@lib/types'
import { SearchFormValues } from '@modules/air-search-v2/lib'
import { IataCodeLookup } from './IataCodeLookup'

export interface FormikIataCodeLookupProps {
  name: string
  label: string
  onChangeHook?: (
    field: string,
    newValue: string | DuffelAPI.Types.Place
  ) => void
  disabled?: boolean
  tooltipText?: string
}

export const FormikIataCodeLookup: React.FC<FormikIataCodeLookupProps> = ({
  name,
  label,
  onChangeHook,
  disabled,
  tooltipText,
}) => {
  const { setFieldValue, isSubmitting, handleBlur } =
    useFormikContext<SearchFormValues<SearchType>>()
  const handleChange = (field: string, value) => {
    const code = typeof value === 'string' ? value : value.iataCode
    setFieldValue(field, code)
    onChangeHook && onChangeHook(field, code)
  }
  const isDisabled = isSubmitting || disabled

  return (
    <IataCodeLookup
      onChange={(value) => handleChange(name, value)}
      onBlur={handleBlur(name)}
      name={name}
      label={label}
      placeholder={label}
      disabled={isDisabled}
      tooltipText={tooltipText}
    />
  )
}
