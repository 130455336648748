export { Air } from './Air'
export { Billing } from './Billing'
export { Identity } from './Identity'
export { BaseClient } from './lib'
export { Manage } from './Manage'
import { isDev, isProduction, isPureDev, isStaging } from '@lib/env'
import { DuffelAPI, DuffelProxy } from '@lib/types'
import { DuffelClientClass } from './Client'

export const createDuffelClient = (org?: string, liveMode?: boolean) => {
  const client = new DuffelClientClass({
    env: { isDev, isStaging, isProduction },
    org,
    liveMode,
  })
  client.Air.registerBeforeSendRequest((axiosConfig) => ({
    ...axiosConfig,
    params: { trace: true, ...axiosConfig.params },
  }))
  return client
}

export const getAvatarLink = (
  forObject:
    | DuffelProxy.Types.Self
    | DuffelProxy.Types.SelfOrganisation
    | string
): string => {
  if (!forObject) return ''

  let avatarUrl: string

  if (typeof forObject === 'string') avatarUrl = forObject
  else if (!forObject.avatarUrl) return ''
  else avatarUrl = forObject.avatarUrl

  return isPureDev
    ? `https://localhost:4000/${avatarUrl.replace('/tmp/static', '')}`
    : avatarUrl
}

const baggageMap = {
  checked: 'Checked',
  carry_on: 'Carry On',
}

export const compileBaggageInfo = (
  passengers: DuffelAPI.Types.OrderSegmentPassenger[]
) => {
  if (passengers.length === 0) return ''
  const { baggages } = passengers[0]

  const baggageCompiled = {}
  baggages.forEach(({ type, quantity }) => {
    if (!(type in baggageCompiled)) {
      baggageCompiled[type] = 0
    }
    baggageCompiled[type] += quantity
  })

  return Object.keys(baggageCompiled)
    .filter((type) => baggageCompiled[type] !== 0)
    .map((type) => `${baggageCompiled[type]} ${baggageMap[type]}`)
    .join(', ')
}

type Itinerary = ItineraryFlight[]

interface ItineraryFlight {
  from: string
  to: string
  flight: string
  departure: string
  arrival: string
  baggage: string
}

export const getItinerary = (order: DuffelAPI.Types.Order): Itinerary => {
  const itinerary: Itinerary = []

  for (const slice of order.slices) {
    for (const segment of slice.segments) {
      itinerary.push({
        from: `${segment.origin.iataCode}`,
        to: `${segment.destination.iataCode}`,
        flight: `${segment.marketingCarrier.iataCode} ${segment.marketingCarrierFlightNumber}`,
        departure: segment.departingAt,
        arrival: segment.arrivingAt,
        baggage: compileBaggageInfo(segment.passengers),
      })
    }
  }

  return itinerary
}

export const getPassengersName = (
  passengers: DuffelAPI.Types.OrderPassenger[]
) => {
  const givenNames = passengers.reduce(
    (names, { givenName }) =>
      givenName && !names.includes(givenName) ? [...names, givenName] : names,
    new Array<string>()
  )
  const familyNames = passengers.reduce(
    (names, { familyName }) =>
      familyName && !names.includes(familyName)
        ? [...names, familyName]
        : names,
    new Array<string>()
  )

  if (givenNames.length === 0 && familyNames.length === 0) return ''
  else if (familyNames.length > 0) return familyNames.join(', ')
  else if (givenNames.length > 0) return givenNames.join(', ')
  return ''
}

export const getPassengerBreakdown = <
  T extends DuffelAPI.Inputs.OrderPassenger | DuffelAPI.Types.OrderPassenger
>(
  passengers: Array<T>
): { adults: T[]; children: T[]; infants: T[] } => ({
  adults: (passengers as any).filter(({ type }) => type === 'adult'),
  children: (passengers as any).filter(({ type }) => type === 'child'),
  infants: (passengers as any).filter(
    ({ type }) => type === 'infant_without_seat'
  ),
})

export const getPassengersIndex = <
  T extends DuffelAPI.Inputs.OrderPassenger | DuffelAPI.Types.OrderPassenger
>(
  passengers: Array<T>
): { adults: number[]; children: number[]; infants: number[] } => {
  const adults = new Array<number>()
  const children = new Array<number>()
  const infants = new Array<number>()

  for (let index = 0; index < passengers.length; index++) {
    const passenger = passengers[index] as any

    switch (passenger.type) {
      case 'adult':
        adults.push(index)
        break
      case 'child':
        children.push(index)
        break
      case 'infant_without_seat':
        infants.push(index)
        break
    }
  }

  return { adults, children, infants }
}
