import { useRouter } from 'next/router'
import * as React from 'react'
import { Button } from '@components/Button'
import { HSpace } from '@components/HSpace'
import { UNKNOWN_ERROR_MESSAGE } from '@lib/constants'
import { moneyStringFormatter } from '@lib/helpers'
import { AuthorisationGuard } from '@lib/security/AuthorisationGuard'
import { useWorkspace } from '@lib/workspace-context'
import { handleHoldOrderResultConfirmation } from '../../helpers'
import { AxiosResponse } from 'axios'
import { getDuffelAPIClient } from '@lib/duffel-api/getDuffelAPIClient'
import { DuffelAPI } from '@lib/types'

interface PayHoldOrderDialogActionsProps {
  order: DuffelAPI.Types.Order
  closeModal: () => void
  setIsCardPaymentModalOpen: (isOpen: boolean) => void
}

export const PayHoldOrderDialogActions: React.FC<
  PayHoldOrderDialogActionsProps
> = ({ order, closeModal, setIsCardPaymentModalOpen }) => {
  const router = useRouter()
  const { addToast, openDialog, closeDialog, updateDialog } = useWorkspace()
  const price = moneyStringFormatter(order.totalCurrency)(+order.totalAmount)

  const onPaymentClick = (paymentType: DuffelAPI.PaymentType) => {
    let dialogTitle = 'Pay with Duffel Balance'
    let message = (
      <>
        By selecting this option, we will automatically debit <b>{price}</b>{' '}
        from your account balance.
      </>
    )

    if (paymentType === 'arc_bsp_cash') {
      dialogTitle = 'Pay with ARC/BSP Cash'
      message = (
        <>
          By selecting this option, we will automatically debit <b>{price}</b>{' '}
          from your account
        </>
      )
    }

    return openDialog({
      title: dialogTitle,
      message: <div>{message}</div>,
      confirmButtonLabel: 'Confirm Booking',
      disableCloseOnEscapeKey: true,
      onConfirm: async () => {
        updateDialog({ isConfirmButtonLoading: true })

        const client = getDuffelAPIClient(
          undefined,
          undefined,
          router.query.org as string,
          router.query.mode as 'live' | 'test'
        )
        let response: AxiosResponse | null = null
        try {
          response = await client.post('/air/payments/', {
            data: {
              order_id: order.id,
              payment: {
                type: paymentType,
                amount: order.totalAmount,
                currency: order.totalCurrency,
              },
            },
          })
        } catch (error) {
          console.error(error)
          updateDialog({ isConfirmButtonLoading: false })
          addToast({
            intent: 'warning',
            message: UNKNOWN_ERROR_MESSAGE,
            closeAfterTimeout: false,
          })
        }

        if (response) {
          handleHoldOrderResultConfirmation(
            response.data,
            addToast,
            closeDialog,
            async () => {
              closeDialog()
            },
            () => router.replace(router.asPath)
          )
        }
      },
      onCancel: () => {
        closeDialog()
      },
    })
  }

  return (
    <>
      <HSpace space={8} fill>
        <Button medium fill text="Cancel" intent="MUTED" onClick={closeModal} />
        {order.availablePaymentTypes?.includes('balance') && (
          <Button
            medium
            fill
            text="Pay with balance"
            onClick={() => onPaymentClick('balance')}
          />
        )}
        {order.availablePaymentTypes?.includes('arc_bsp_cash') && (
          <Button
            medium
            fill
            text="Pay with ARC/BSP Cash"
            onClick={() => onPaymentClick('arc_bsp_cash')}
          />
        )}
        {order.availablePaymentTypes?.includes('card') && (
          <>
            <AuthorisationGuard requiresDuffelUser>
              <Button
                medium
                fill
                onClick={() => setIsCardPaymentModalOpen(true)}
                text="Pay with card"
              />
            </AuthorisationGuard>
          </>
        )}
      </HSpace>
    </>
  )
}
