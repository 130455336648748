import * as PopperJS from '@popperjs/core'
import React from 'react'
import { usePopper } from 'react-popper'
import { usePopoverContext } from '@lib/popover-context'

type UsePopperReturnType = ReturnType<typeof usePopper>

interface UseDuffelPopper {
  popper: UsePopperReturnType
  setReferenceElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  setPopperElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  setArrowElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>
}

export const useDuffelPopper = (
  isOpen: boolean,
  onClose: (event: MouseEvent | KeyboardEvent) => void,
  popperOptions: Partial<PopperJS.Options>,
  options?: {
    shouldInsideClickClose?: boolean
  }
): UseDuffelPopper => {
  const { shouldInsideClickClose } = Object.assign(
    {},
    { shouldInsideClickClose: false },
    options
  )

  const [referenceElement, setReferenceElement] =
    React.useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(
    null
  )
  const [arrowElement, setArrowElement] = React.useState<HTMLElement | null>(
    null
  )
  const defaultOptions = {
    placement: 'auto' as PopperJS.Placement,
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
  }
  const usePopperOptions = { ...defaultOptions, ...popperOptions }
  const popper = usePopper(referenceElement, popperElement, usePopperOptions)

  const onDocumentClickHandler = React.useMemo(
    () => (event: MouseEvent) => {
      if (popperElement !== null) {
        const eventTarget = (event as any).target
        const isClickInsidePopover = popperElement?.contains(eventTarget)
        const isClickInsideReferenceElement =
          referenceElement?.contains(eventTarget)

        event.stopPropagation()
        event.preventDefault()
        if (!isClickInsidePopover && !isClickInsideReferenceElement) {
          onClose(event)
        }
        if (shouldInsideClickClose && isClickInsidePopover) {
          setTimeout(() => onClose(event), 10)
        }
      }
    },
    [popperElement, referenceElement, onClose, shouldInsideClickClose]
  )

  const onDocumentKeyDownHandler = React.useMemo(
    () => (event: KeyboardEvent) => {
      if (event.key !== 'Escape') return
      onClose(event)
    },
    [onClose]
  )

  React.useEffect(() => {
    if (document === null) {
      return
    }
    /*
      The timeout logic here is necessary because without it the event listener immediately
      fires and closes the popover. Essentially, the click that causes the popover to be created
      also ends up triggering the onClose handler, and it's immediately closed before the DOM
      has time to show it.

      This is related to a change to how useEffect works in React 18, see here for more context:
      https://github.com/facebook/react/pull/21150

      React itself used the same timeout solution as us when the change broke part of the React Devtools:
      https://github.com/facebook/react/pull/21173/files#diff-813b62396bc31a37a804199ed9e3a124a1fab3c5a0077a427ae3d39ce46a34b1
    */

    let timeoutId
    if (isOpen) {
      timeoutId = setTimeout(() => {
        timeoutId = null
        document.body.addEventListener('click', onDocumentClickHandler)
        document.body.addEventListener('keydown', onDocumentKeyDownHandler)
      }, 0)
    }

    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId)
      }
      if (referenceElement !== null) {
        document.body.removeEventListener('click', onDocumentClickHandler)
        document.body.removeEventListener('keydown', onDocumentKeyDownHandler)
      }
    }
  }, [
    isOpen,
    onDocumentClickHandler,
    onDocumentKeyDownHandler,
    referenceElement,
  ])

  const { incrementPopoverCount, decrementPopoverCount } = usePopoverContext()

  const hasBeenOpened = React.useRef(false)

  React.useEffect(() => {
    if (isOpen) {
      incrementPopoverCount()
      hasBeenOpened.current = true
      return
    }

    // The initial popover state tends to be false, so we need this check so that
    // we don't decrement popover count into the negatives.
    if (!hasBeenOpened.current) {
      return
    }

    decrementPopoverCount()
  }, [isOpen, incrementPopoverCount, decrementPopoverCount])

  return {
    popper,
    setReferenceElement,
    setPopperElement,
    setArrowElement,
  }
}
