import * as React from 'react'
import classNames from 'classnames'
import { ColorWithoutWeight } from '@components/legacy-design-system/product/styles'
import { AirlineLogo } from '@components/AirlineLogo'
import { HSpace } from '@components/HSpace'
import { VSpace } from '@components/VSpace'
import { Text } from '@components/Text'
import { Icon } from '@components/Icon'
import {
  convertDurationToString,
  getDayDiff,
  getDurationString,
  getTimeString,
} from '@lib/date'
import { iso8601DurationRegex } from '@lib/date/constants'
import {
  getHighlightStyles,
  getSegmentDates,
  getSliceDetails,
  Segment,
  Slice,
} from '../lib'
import styles from './SliceDetailsSummaryCompact.module.css'

export const getStopDurationString = (segment: Segment, nextSegment: Segment) =>
  `${getDurationString(
    getSegmentDates(segment).arrivingAt!,
    getSegmentDates(nextSegment).departingAt!
  )} ${segment.destination.iataCode}`

export interface SliceDetailsSummaryCompactProps {
  slice: Slice
  fill?: boolean
  withAirlineLogo?: boolean
  highlightAll?: boolean
  keysToHighlight?: string[]
  highlightColor?: ColorWithoutWeight
  className?: string
}

export const SliceDetailsSummaryCompact: React.FC<
  SliceDetailsSummaryCompactProps
> = ({
  slice,
  fill,
  withAirlineLogo,
  highlightAll = false,
  keysToHighlight,
  highlightColor,
  className,
}) => {
  const { segments } = slice
  const firstSegment = segments[0]
  const sliceDetails = getSliceDetails(slice)
  const lastSegment = segments[segments.length - 1]
  const departingAt = sliceDetails[0].travelDetails?.departingAt
  const arrivingAt =
    sliceDetails[sliceDetails.length - 1].travelDetails?.arrivingAt
  // We need to strip out the time as getDayDiff rounds the time difference up, but here we
  // only care whether the day is the same or not
  const dayDiff =
    departingAt && arrivingAt
      ? getDayDiff(arrivingAt.split('T')[0], departingAt.split('T')[0])
      : 0
  const duration =
    slice.duration &&
    typeof slice.duration === 'string' &&
    slice.duration.match(iso8601DurationRegex) &&
    arrivingAt &&
    departingAt
      ? convertDurationToString(String(slice.duration))
      : null

  const stops = sliceDetails.filter((item) => item.type === 'travel').slice(1)

  const highlightStyles = (key?: string) =>
    getHighlightStyles(
      styles,
      highlightAll,
      key,
      keysToHighlight,
      highlightColor
    )

  const renderLocationWithTime = (
    iataCode: string,
    date: string,
    dayDiff: number,
    key: string,
    alignRight?: boolean
  ) => (
    <VSpace space={4}>
      <Text
        fontWeight="medium"
        textAlign={alignRight ? 'right' : 'left'}
        {...(dayDiff && highlightStyles('dayDiff'))}
      >
        <span {...highlightStyles(key)}>{getTimeString(date)}</span>
        {dayDiff > 0 && (
          <sup
            className={
              keysToHighlight?.includes(key) &&
              !keysToHighlight?.includes('dayDiff')
                ? classNames(styles['day-diff'], styles['day-diff--pad'])
                : styles['day-diff']
            }
          >
            +{dayDiff}
          </sup>
        )}
      </Text>
      <Text color="grey-600" textAlign={alignRight ? 'right' : 'left'}>
        {iataCode}
      </Text>
    </VSpace>
  )

  return (
    <HSpace space={24} alignCenter fill={fill} className={className}>
      {withAirlineLogo && (
        <AirlineLogo
          name={firstSegment.marketingCarrier.name}
          iataCode={firstSegment.marketingCarrier.iataCode}
          size={40}
        />
      )}
      {renderLocationWithTime(
        firstSegment.origin.iataCode!,
        departingAt || firstSegment.departingAt,
        0,
        'departingAt'
      )}
      <VSpace space={0} className={fill ? 'u-flex1' : undefined}>
        {duration && (
          <Text fontSize="C3" color="grey-600" textAlign="center">
            <span {...highlightStyles('duration')}>{duration}</span>
          </Text>
        )}
        <HSpace space={0} fill alignCenter>
          <div className={styles['flight-line']}>
            {stops.map((_: unknown, index: number) => (
              <div
                key={index}
                className={styles['stop-dot']}
                style={{
                  // we will never have more than 2 stops
                  left: stops.length === 1 ? '50%' : `${(index + 1) * 33}%`,
                }}
              />
            ))}
          </div>
          <Icon
            name="airplanemode_active"
            size={16}
            className={styles['plane-icon']}
          />
        </HSpace>
        {stops.length === 0 && (
          <Text fontSize="C3" color="grey-600" textAlign="center">
            Direct
          </Text>
        )}
        {stops.length > 0 && (
          <HSpace space={4} justify="center">
            <Text fontSize="C3" color="grey-900" textAlign="center">
              {stops.length} stop{stops.length > 1 ? 's' : ''}
            </Text>
            <Text fontSize="C3" color="grey-600" textAlign="center">
              {stops
                .map((stop) => stop.travelDetails?.origin.iataCode)
                .join(', ')}
            </Text>
          </HSpace>
        )}
      </VSpace>
      {renderLocationWithTime(
        lastSegment.destination.iataCode!,
        arrivingAt || lastSegment.arrivingAt,
        dayDiff,
        'arrivingAt',
        true
      )}
    </HSpace>
  )
}
