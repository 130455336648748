import classNames from 'classnames'
import * as React from 'react'
import { useTableContext } from '../Table'
import styles from './TableCell.module.css'

export interface TableCellProps {
  /**
   * Manually define the width of the cell. Should be the amount + unit
   * - - -
   * (ex. - `256px` or `2em`)
   */
  width?: string

  /**
   * Should the text be right-aligned?
   * - - -
   * TODO: Change prop name to not use "right" in the name.
   * See related comment: https://github.com/duffelhq/dashboard/pull/3315#discussion_r938761812
   * See related JIRA ticket: https://duffel.atlassian.net/browse/UXP-2786
   *
   * Will also need to update the `CostIndicator` component as well since it uses the `alignRight` prop
   */
  alignRight?: boolean

  /**
   * What should the cell height be in pixels?
   * If this prop is omitted, it will use the value from the table context
   */
  height?: 40 | 48 | 56

  /**
   * Is the table cell a part of the footer? This affect styling
   */
  inFooter?: boolean

  /**
   * CSS classname for layout changes
   */
  className?: string
}

export const TableCell: React.FC<React.PropsWithChildren<TableCellProps>> = ({
  children,
  width,
  alignRight,
  height,
  inFooter,
  className,
  ...props
}) => {
  const { rowHeight } = useTableContext()
  const cellHeight = height || rowHeight
  return (
    <td
      className={classNames(
        styles['td'],
        className,
        alignRight && [styles['align-right']],
        {
          [styles['td--40']]: cellHeight === 40,
          [styles['td--48']]: cellHeight === 48,
          [styles['td--footer']]: inFooter,
        }
      )}
      style={{ width }}
      {...props}
    >
      {children}
    </td>
  )
}
