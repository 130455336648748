import { useField, useFormikContext } from 'formik'
import * as React from 'react'
import { Input, InputProps } from '@components/Input'
import { FormField, FormFieldProps } from '../FormField'

export interface FormikInputProps extends FormFieldProps, InputProps {
  /**
   * The name of the field used by Formik
   */
  name: string
}

export const FormikInput: React.FC<FormikInputProps> = (props) => {
  const {
    label,
    hint,
    optionalField,
    secondaryLabel,
    className,
    disabled,
    type = 'text',
    ...inputProps
  } = props
  const [field, { touched, error }] = useField(props)
  const { submitCount, isSubmitting } = useFormikContext()
  const isDisabled = isSubmitting || disabled

  return (
    <div className="ff-form-input">
      <FormField
        label={label}
        error={touched || submitCount > 0 ? error : undefined}
        hint={hint}
        htmlFor={inputProps.id || props.name}
        optionalField={optionalField}
        secondaryLabel={secondaryLabel}
        className={className}
        disabled={isDisabled}
      >
        <Input
          type={type}
          {...field}
          {...inputProps}
          id={inputProps.id || props.name}
          disabled={isDisabled}
          isError={touched && !!error}
        />
      </FormField>
    </div>
  )
}
