import { BaseClientConfig } from '@lib/types'
import { BaseClient } from './lib'
import { Identity } from './Identity'
import { Manage } from './Manage'
import { Air } from './Air'
import { Payments } from './Payments'
import { Places } from './Places'
import { Billing } from './Billing'
import { Firestore } from './Firestore'
import { Zendesk } from './Zendesk'
import { Stays } from './Stays'

export class DuffelClientClass extends BaseClient {
  public Air: Air
  public Billing: Billing
  public Identity: Identity
  public Manage: Manage
  public Payments: Payments
  public Places: Places
  public Firestore: Firestore
  public Zendesk: Zendesk
  public Stays: Stays

  constructor(config: BaseClientConfig) {
    super(config)
    this.Identity = new Identity(config)
    this.Manage = new Manage(config)
    this.Air = new Air(config)
    this.Payments = new Payments(config)
    this.Places = new Places(config)
    this.Billing = new Billing(config)
    this.Firestore = new Firestore(config)
    this.Zendesk = new Zendesk(config)
    this.Stays = new Stays(config)
  }
}
