import classNames from 'classnames'
import { Text } from '@components/legacy-design-system/product/components/Text'
import { HSpace } from '@components/HSpace'
import { Icon, IconName } from '@components/Icon'
import styles from './FlightInformationUpdate.module.css'

export interface FlightInformationUpdateProps {
  status: 'changed' | 'removed' | 'added' | 'credit-issued'
}

export const FlightInformationUpdate: React.FC<
  React.PropsWithChildren<FlightInformationUpdateProps>
> = ({ status, children }) => {
  const cx = classNames(styles['flight-information-update'], {
    [styles['flight-information-update--changed']]: status === 'changed',
    [styles['flight-information-update--removed']]: status === 'removed',
    [styles['flight-information-update--added']]: status === 'added',
    [styles['flight-information-update--credit-issued']]:
      status === 'credit-issued',
  })

  let iconName: IconName

  if (status === 'changed' || status === 'credit-issued') {
    iconName = 'auto_renew'
  } else if (status === 'removed') {
    iconName = 'close'
  } else {
    iconName = 'add'
  }

  return (
    <div className={cx}>
      <HSpace space={12} alignCenter>
        <Icon name={iconName} size={16} />
        <Text typeStyle="numerical2" color="grey-600">
          {children}
        </Text>
      </HSpace>
    </div>
  )
}
