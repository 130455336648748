import { Button } from '@components/Button'
import { Card } from '@components/Card'
import {
  Invoice,
  InvoiceBody,
  InvoiceCell,
  InvoiceFooter,
  InvoiceHeader,
  InvoiceRow,
} from '@components/Invoice'
import { Modal } from '@components/Modal'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { UNKNOWN_ERROR_MESSAGE } from '@lib/constants'
import { moneyStringFormatter } from '@lib/helpers'
import { ordersShowPathArray } from '@lib/paths'
import { trackEvent } from '@lib/tracking'
import { DuffelAPI } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import { onConfirmChange } from '@modules/air-order-change/lib'
import { useUserFriendlyErrors } from '@modules/air-order/lib/use-user-friendly-errors'
import { DuffelCardFormModal } from '@modules/stays-search/components/StaysCheckoutForm/DuffelCardFormModal'
import { useRouter } from 'next/router'
import * as React from 'react'
import styles from './ChangesPayment.module.css'

export interface ChangesPaymentProps {
  order: DuffelAPI.Types.Order
  initialOrderChange: DuffelAPI.Types.OrderChange
  componentClientKey: string
}

export const ChangesPayment: React.FC<ChangesPaymentProps> = ({
  initialOrderChange,
  order,
  componentClientKey,
}) => {
  const { push } = useRouter()
  const { addToast, permissions, duffelClient } = useWorkspace()
  const { handleOrderErrors } = useUserFriendlyErrors()

  const [isLoadingCreateChange, setIsLoadingCreateChange] =
    React.useState<boolean>(false)
  const [isLoadingConfirmChange, setIsLoadingConfirmChange] =
    React.useState<boolean>(false)
  const [isCardPaymentModalOpen, setIsCardPaymentModalOpen] =
    React.useState(false)
  const [isDialogPaymentModalOpen, setIsDialogPaymentModalOpen] =
    React.useState(false)

  const [orderChange, setOrderChange] =
    React.useState<DuffelAPI.Types.OrderChange>(initialOrderChange)
  const [paymentType, setPaymentType] =
    React.useState<DuffelAPI.PaymentType | null>(null)

  const onCreateChangeActionsFn = async (
    withPaymentTypeOrRefund: DuffelAPI.PaymentType | 'refund'
  ) => {
    if (withPaymentTypeOrRefund !== 'refund')
      setPaymentType(withPaymentTypeOrRefund)

    // retrieve the order change again to ensure we have the latest price and other information
    setIsLoadingCreateChange(true)
    try {
      const response = await duffelClient.Air.getOrderChange(orderChange.id)

      if (response.errors)
        throw response.errors[0]?.message || UNKNOWN_ERROR_MESSAGE
      else if (!response.data) throw UNKNOWN_ERROR_MESSAGE

      setOrderChange(response.data)
      setIsDialogPaymentModalOpen(withPaymentTypeOrRefund !== 'card')
      setIsLoadingCreateChange(false)
    } catch (error) {
      let errorMessage = UNKNOWN_ERROR_MESSAGE
      if (typeof error === 'string') {
        errorMessage = error
      } else if (error instanceof Error) {
        errorMessage = error.message
      }

      addToast({
        message: errorMessage,
        intent: 'warning',
        closeAfterTimeout: false,
      })
      setIsLoadingCreateChange(false)
    }
  }

  const onConfirmChangeActionsFn = () => {
    if (!orderChange) {
      return addToast({
        message: UNKNOWN_ERROR_MESSAGE,
        intent: 'warning',
        closeAfterTimeout: false,
      })
    }

    onConfirmChange(
      duffelClient,
      order.id,
      orderChange.id,
      paymentType
        ? {
            amount: orderChange.changeTotalAmount,
            currency: orderChange.changeTotalCurrency,
            type: paymentType,
          }
        : {},
      {
        setIsLoading: setIsLoadingConfirmChange,
        onSuccess: async () => {
          await push(
            ...ordersShowPathArray(
              permissions?.organisation,
              permissions?.liveMode,
              order.id
            )
          )
          addToast({
            message: 'Your order has been successfully changed.',
            intent: 'success',
          })
        },
        handleErrors: async (errors, requestId) => {
          trackEvent('dashboard_change_flights_confirm_order_failed', {
            error_message: errors?.[0]?.message || UNKNOWN_ERROR_MESSAGE,
            event_type: 'api',
            order_id: order.id,
            order_change_id: orderChange.id,
          })

          await handleOrderErrors(errors, order, {
            actionToSupport: 'change_order',
            requestId: requestId ?? '',
          })

          setIsLoadingConfirmChange(false)
          setIsDialogPaymentModalOpen(false)
        },
      }
    )
  }

  const onConfirmCardPayment = async (cardPaymentValues: {
    type: 'card'
    amount: string
    currency: string
    threeDSecureSessionId: string
  }) => {
    if (!orderChange) {
      return addToast({
        message: UNKNOWN_ERROR_MESSAGE,
        intent: 'warning',
        closeAfterTimeout: false,
      })
    }

    onConfirmChange(duffelClient, order.id, orderChange.id, cardPaymentValues, {
      setIsLoading: setIsLoadingConfirmChange,
      onSuccess: async () => {
        await push(
          ...ordersShowPathArray(
            permissions?.organisation,
            permissions?.liveMode,
            order.id
          )
        )
        addToast({
          message: 'Your order has been successfully changed.',
          intent: 'success',
        })
      },
      handleErrors: async (errors, requestId) => {
        trackEvent('dashboard_change_flights_confirm_order_failed', {
          error_message: errors?.[0]?.message || UNKNOWN_ERROR_MESSAGE,
          event_type: 'api',
          order_id: order.id,
          order_change_id: orderChange.id,
        })

        await handleOrderErrors(errors, order, {
          actionToSupport: 'change_order',
          requestId: requestId ?? '',
        })

        setIsLoadingConfirmChange(false)
        setIsDialogPaymentModalOpen(false)
      },
    })
  }

  if (!orderChange.changeTotalCurrency || !orderChange.changeTotalAmount)
    return null

  const changeTotalAmount = +orderChange.changeTotalAmount
  const changeWillRefund = changeTotalAmount < 0

  let refundDestinationLabel: string
  switch (orderChange.refundTo) {
    case 'voucher':
      refundDestinationLabel = 'voucher'
      break
    case 'original_form_of_payment':
      refundDestinationLabel = 'original form of payment'
      break
    default:
      refundDestinationLabel = ''
  }

  const hasPenalty =
    orderChange.penaltyTotalCurrency &&
    orderChange.penaltyTotalAmount &&
    +orderChange.penaltyTotalAmount !== 0

  const renderMoneyLabel = moneyStringFormatter(orderChange.changeTotalCurrency)

  const confirmedAmountLabel = changeWillRefund
    ? moneyStringFormatter(orderChange.changeTotalCurrency, undefined, {
        signDisplay: 'never',
      })(+orderChange.changeTotalAmount)
    : renderMoneyLabel(+orderChange.changeTotalAmount)

  // The label to display to users depends on whether or not the change
  // is free, will refund them, or will cost them.
  let confirmChangeLabel = `Confirm payment of ${confirmedAmountLabel}`
  if (changeTotalAmount === 0) {
    confirmChangeLabel = 'Confirm change'
  } else if (changeWillRefund) {
    confirmChangeLabel = `Confirm refund of ${confirmedAmountLabel}`
  }

  return (
    <>
      <VSpace space={16}>
        <Text fontSize="H3">Payment</Text>

        <Card>
          <VSpace space={24}>
            <Invoice>
              <InvoiceHeader>
                <InvoiceRow>
                  <InvoiceCell isHeaderCell>Description</InvoiceCell>
                  <InvoiceCell textAlign="right" isHeaderCell>
                    Price ({orderChange.changeTotalCurrency})
                  </InvoiceCell>
                </InvoiceRow>
              </InvoiceHeader>
              <InvoiceBody>
                <InvoiceRow>
                  <InvoiceCell grow>Before change amount</InvoiceCell>
                  <InvoiceCell textAlign="right">
                    {renderMoneyLabel(+order.totalAmount)}
                  </InvoiceCell>
                </InvoiceRow>
                <InvoiceRow>
                  <InvoiceCell grow>After change amount</InvoiceCell>
                  <InvoiceCell textAlign="right">
                    {renderMoneyLabel(+orderChange.newTotalAmount)}
                  </InvoiceCell>
                </InvoiceRow>
              </InvoiceBody>
              <InvoiceFooter>
                <InvoiceRow>
                  <InvoiceCell isFooterCell textAlign="right">
                    {changeWillRefund ? 'Refund amount' : 'Cost of change'}
                    {hasPenalty &&
                      ` (includes ${renderMoneyLabel(
                        +orderChange.penaltyTotalAmount!
                      )} change penalty)`}
                  </InvoiceCell>
                  <InvoiceCell isFooterCell textAlign="right">
                    {moneyStringFormatter(
                      orderChange.newTotalCurrency,
                      undefined,
                      { signDisplay: 'never' }
                    )(+orderChange.changeTotalAmount)}
                  </InvoiceCell>
                </InvoiceRow>
              </InvoiceFooter>
            </Invoice>

            {changeWillRefund && (
              <Button
                fill
                large
                text={`Confirm and refund to ${refundDestinationLabel}`}
                isWaiting={isLoadingCreateChange}
                onClick={() => onCreateChangeActionsFn('refund')}
              />
            )}

            <VSpace space={8} className={styles['payment-buttons']}>
              {!changeWillRefund &&
                orderChange.availablePaymentTypes?.includes('balance') && (
                  <Button
                    fill
                    large
                    text="Confirm and pay with balance"
                    isWaiting={isLoadingCreateChange}
                    onClick={() => onCreateChangeActionsFn('balance')}
                  />
                )}

              {!changeWillRefund &&
                orderChange.availablePaymentTypes?.includes('arc_bsp_cash') && (
                  <Button
                    fill
                    intent="MUTED"
                    large
                    text="Confirm and pay with ARC/BSP Cash"
                    isWaiting={isLoadingCreateChange}
                    onClick={() => onCreateChangeActionsFn('arc_bsp_cash')}
                  />
                )}
              {!changeWillRefund &&
                orderChange.availablePaymentTypes?.includes('card') && (
                  <>
                    <Button
                      fill
                      intent="MUTED"
                      large
                      isWaiting={isLoadingCreateChange}
                      onClick={() => {
                        onCreateChangeActionsFn('card')
                        setIsCardPaymentModalOpen(true)
                      }}
                      text="Confirm and pay with card"
                    />
                    {isCardPaymentModalOpen && (
                      <DuffelCardFormModal
                        clientKey={componentClientKey}
                        resourceId={orderChange.id}
                        onClose={() => {
                          setIsCardPaymentModalOpen(false)
                          setPaymentType(null)
                        }}
                        buttonText={`Pay ${moneyStringFormatter(
                          orderChange.changeTotalCurrency
                        )(+orderChange.changeTotalAmount)}`}
                        onSubmit={({ cardId, threeDSecureSessionId }) =>
                          onConfirmCardPayment({
                            type: 'card',
                            ...(!threeDSecureSessionId && { cardId }),
                            threeDSecureSessionId: threeDSecureSessionId!,
                            amount: orderChange.changeTotalAmount,
                            currency: orderChange.changeTotalCurrency,
                          })
                        }
                      />
                    )}
                  </>
                )}
              {}
            </VSpace>
          </VSpace>
        </Card>
      </VSpace>

      {isDialogPaymentModalOpen && (
        <Modal onClose={() => setIsDialogPaymentModalOpen(false)}>
          <VSpace space={32}>
            <VSpace space={24}>
              <Text fontSize="H2" fontWeight="medium" textAlign="center">
                Confirm changes
              </Text>
              <Text fontSize="C2" textAlign="center" measure>
                {!changeWillRefund && (
                  <>
                    This will be charged to your Duffel Balance. Please note —
                    future changes to this order will require support
                    assistance.
                  </>
                )}
                {changeWillRefund && (
                  <>
                    This will be refunded to
                    {orderChange.refundTo === 'voucher'
                      ? ' a voucher'
                      : ' your original form of payment'}
                    . Please note — future changes to this order will require
                    support assistance.
                  </>
                )}
              </Text>
            </VSpace>

            <VSpace space={8}>
              <Button
                fill
                text={confirmChangeLabel}
                isWaiting={isLoadingConfirmChange}
                onClick={onConfirmChangeActionsFn}
                large
              />
              <Button
                large
                fill
                intent="MUTED"
                text="Back"
                onClick={() => setIsDialogPaymentModalOpen(false)}
              />
            </VSpace>
          </VSpace>
        </Modal>
      )}
    </>
  )
}
