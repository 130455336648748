import { DuffelAPI } from '@lib/types'
import { makeMockAirline } from './make-mock-airline'
import { makeMockDateInThePast } from './make-mock-date'
import { makeMockOrderPassenger } from './make-mock-order-passenger'
import { makeMockOrderSlice } from './make-mock-order-slice'
import { makeMockOrderDocuments } from './make-mock-order-documents'
import { makeMockOrderServices } from '.'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.Order => {
    const id = (seedId++).toString().padStart(5, '0')
    const createdAt = makeMockDateInThePast(1).toISOString()
    const passengerIds = [`pas_${(seedId++).toString().padStart(5, '0')}`]
    const segmentIds = [`seg_${(seedId++).toString().padStart(5, '0')}`]
    const mockOrderPassengers = passengerIds.map((passengerId) =>
      makeMockOrderPassenger({}, passengerId)
    )

    return {
      airlineInitiatedChanges: [],
      availableActions: [],
      baseAmount: '201.42',
      baseCurrency: 'GBP',
      bookingReference: 'ZAKX7V',
      cancelledAt: null,
      changes: [],
      conditions: {
        changeBeforeDeparture: null,
        refundBeforeDeparture: null,
      },
      content: 'managed',
      createdAt,
      documents: makeMockOrderDocuments(),
      id: `ord_${id}`,
      liveMode: false,
      metadata: {},
      owner: makeMockAirline(),
      passengers: mockOrderPassengers,
      paymentStatus: {
        awaitingPayment: false,
        paidAt: createdAt,
      },
      services: makeMockOrderServices([], segmentIds, passengerIds),
      slices: [makeMockOrderSlice({}, segmentIds, passengerIds)],
      syncedAt: createdAt,
      taxAmount: '36.26',
      taxCurrency: 'GBP',
      totalAmount: '237.68',
      totalCurrency: 'GBP',
      cancellation: null,
    }
  }
})()

export const makeMockOrder = (
  extendDefault?: Partial<DuffelAPI.Types.Order>
): DuffelAPI.Types.Order =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
