import { DuffelAPI } from '@lib/types'

export const applyChangeRequestToOrder = (
  order: DuffelAPI.Types.Order,
  orderChangeRequest: DuffelAPI.Types.OrderChangeRequest
): DuffelAPI.Types.Order => {
  if (!order.availableActions.includes('change')) {
    return order
  }

  const newSlices = new Array<DuffelAPI.Types.OrderSlice>()

  if (!orderChangeRequest.orderChangeOffers[0]) return order

  for (const slice of order.slices) {
    const firstOffer = orderChangeRequest.orderChangeOffers[0]
    if (!firstOffer) {
      newSlices.push(slice)
      continue
    }

    const indexOfSliceToBeSwapped = firstOffer.slices.remove.findIndex(
      ({ id }) => id === slice.id
    )
    const newSlice = firstOffer.slices.add[indexOfSliceToBeSwapped]

    newSlices.push(newSlice || slice)
  }

  return Object.assign({}, order, { slices: newSlices })
}
