import { orderBy } from 'lodash'
import { COLORS } from '@components/legacy-design-system/product/styles'
import { getDateString } from '@lib/date'
import { DuffelAPI } from '@lib/types'
import { ExcessSearchValueOnDate, InvoiceItemType } from '../Chart/types'
import { processExcessSearchesData } from './process-excess-searches-data'
import { calculateKeyMetricsPercentChange } from './calculate-key-metrics-percent-change'
import { formatNumberWithCommas } from './format-number-with-commas'
import { getCurrencySymbol } from './get-currency-symbol'
import { getPaidOrdersBreakdownByTier } from './get-paid-orders-breakdown-by-tier'
import { getSearchToBookRatio } from './get-search-to-book-ratio'
import { getInvoiceItemDescriptions } from './get-invoice-item-descriptions'
import { padCurrentMonthWithDays } from './pad-month-with-days'

/**
 * Marshall all data required for charts in the Usage and billing page
 */
export const prepareInvoiceItemData = (
  itemType: InvoiceItemType,
  billingPeriod: Date,
  previousUsageRecords?: DuffelAPI.Types.UsageRecord,
  usageRecords?: DuffelAPI.Types.UsageRecord,
  invoice?: DuffelAPI.Types.Invoice
) => {
  if (!invoice || !usageRecords) {
    return null
  }

  const maxSearchToBookRatio =
    invoice.items.find((invoice) => invoice.name === 'Excess Search')
      ?.calculation.params.metricParams?.maxSearchToBookRatio || 1500

  let usageItems

  switch (itemType) {
    case 'Paid orders':
      usageItems = usageRecords.orderConfirmed
      break
    case 'Paid ancillaries':
      usageItems = usageRecords.servicesConfirmed
      break
    case 'Managed content':
      usageItems = usageRecords.managedContentConfirmedOrderVolume
      break
    case 'Excess searches':
      usageItems = processExcessSearchesData(
        billingPeriod,
        maxSearchToBookRatio,
        usageRecords.offerRequestCreated,
        usageRecords.orderConfirmed
      )
      break
  }

  const isExcessSearches = itemType === 'Excess searches'

  usageItems = isExcessSearches
    ? orderBy(usageItems, (item) => item.date, ['asc'])
    : usageItems

  // This is the data that will be plotted by Chart component
  const values: number[] = []
  const labels: string[] = []
  const dates: string[] = [] // excess searches only

  const paddedUsageItems: DuffelAPI.Types.UsageItemValueOnDate[] = []
  const paddedExcessSearches: ExcessSearchValueOnDate[] = []

  // Check if billing period is current month
  const currentDate = new Date()
  let today

  if (
    currentDate.getFullYear() === billingPeriod.getFullYear() &&
    currentDate.getMonth() === billingPeriod.getMonth()
  ) {
    today = currentDate.getDate()
  }

  const currentDaysInMonth = padCurrentMonthWithDays(billingPeriod)

  // Pad out usage items with values from invoice, '0', or NaN.
  // A value of '0' will ensure line charts are drawn as one continous line.
  // A value of NaN will prevent the line rendering for dates in the future.
  currentDaysInMonth.map((day, index) => {
    if (!isExcessSearches) {
      const itemForDay = usageItems?.find((item) => item.date === day)
      let value

      if (itemForDay?.value) {
        value = itemForDay?.value
      } else if (index > today - 1) {
        value = Number.NaN
      } else {
        value = '0'
      }

      return paddedUsageItems.push({
        value,
        date: day,
      })
    } else {
      const itemForDay = usageItems?.find((item) => item.date === day)
      let value

      if (itemForDay?.orders) {
        value = itemForDay?.searches
      } else if (index > today - 1) {
        value = Number.NaN
      } else {
        value = '0'
      }
      return paddedExcessSearches.push({
        searches: value,
        orders: itemForDay?.orders || '0',
        date: day,
        maxSearchToBookRatio,
      })
    }
  })

  if (!isExcessSearches) {
    paddedUsageItems?.map(({ value, date }) => {
      values.push(Number(value))
      labels.push(getDateString(date, 'mediumNoYear'))
    })
  } else {
    paddedExcessSearches?.map(({ searches, orders, date }) => {
      values.push(Number(searches))
      labels.push(orders)
      dates.push(getDateString(date, 'mediumNoYear'))
    })
  }

  let invoiceItemName

  switch (itemType) {
    case 'Paid orders':
      invoiceItemName = 'Paid Order'
      break
    case 'Paid ancillaries':
      invoiceItemName = 'Paid Ancillary'
      break
    case 'Managed content':
      invoiceItemName = 'Managed Content'
      break
    case 'Excess searches':
      invoiceItemName = 'Excess Search'
      break
  }

  const invoiceItemSummary = invoice.items.find(
    (item) => item.name === invoiceItemName
  )

  let quantity

  switch (itemType) {
    case 'Paid orders':
      quantity =
        invoiceItemSummary?.calculation.metricValues.paidOrderCount || '0'
      break
    case 'Paid ancillaries':
      quantity =
        invoiceItemSummary?.calculation.metricValues.paidAncillaryCount || '0'
      break
    case 'Managed content':
      quantity =
        invoiceItemSummary?.calculation.metricValues
          .managedContentPaidOrderVolume || '0'
      break
    case 'Excess searches':
      quantity = invoiceItemSummary?.calculation.value
  }

  const totalAmount =
    `${getCurrencySymbol(
      invoiceItemSummary?.currency || 'USD'
    )}${formatNumberWithCommas(invoiceItemSummary?.calculation.value, true)}` ||
    '0'

  const totalAmountUnformatted = invoiceItemSummary?.calculation.value || '0'

  const currency = invoiceItemSummary?.currency

  const { primaryDescription, secondaryDescription } =
    getInvoiceItemDescriptions(itemType, invoiceItemSummary, quantity)

  const keyMetricsPercentChange = calculateKeyMetricsPercentChange(
    billingPeriod,
    maxSearchToBookRatio,
    usageRecords,
    previousUsageRecords
  )

  return {
    usageItemType: itemType,
    quantity:
      itemType === 'Managed content'
        ? `${getCurrencySymbol(
            invoiceItemSummary?.currency || 'USD'
          )}${formatNumberWithCommas(quantity, true)}`
        : formatNumberWithCommas(quantity),
    billingPeriod: billingPeriod,
    keyMetricsPercentChange: keyMetricsPercentChange,
    totalAmount: totalAmount,
    totalAmountUnformatted: totalAmountUnformatted,
    currency: currency,
    steppedTierOrPerItem: 'stepped-tier' as const,
    primaryDescription: primaryDescription,
    secondaryDescription: secondaryDescription,
    ...(itemType === 'Excess searches' && {
      searchToBookRatio: getSearchToBookRatio(invoice),
      excessSearchesData: paddedExcessSearches,
    }),
    ...(itemType === 'Paid orders' && {
      paidOrdersBreakdown: getPaidOrdersBreakdownByTier(invoice),
    }),
    chartData: {
      labels,
      datasets: [
        {
          data: values,
          backgroundColor: COLORS['purple-600'],
          borderColor: COLORS['purple-600'],
        },
      ],
    },
    chartType:
      itemType === 'Paid ancillaries' ? ('bar' as const) : ('line' as const),
  }
}
