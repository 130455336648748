import * as React from 'react'

export const FontFaceProvider: React.FC = () => (
  <style jsx global>{`
    // GT America

    @font-face {
      font-display: swap;
      font-family: 'GT America';
      font-style: normal;
      font-weight: 400;
      src: url('/fonts/gt-america-standard/GT-America-Standard-Regular.eot');
      src: url('/fonts/gt-america-standard/GT-America-Standard-Regular.eot?#iefix')
          format('embedded-opentype'),
        url('/fonts/gt-america-standard/GT-America-Standard-Regular.woff2')
          format('woff2'),
        url('/fonts/gt-america-standard/GT-America-Standard-Regular.woff')
          format('woff'),
        url('/fonts/gt-america-standard/GT-America-Standard-Regular.ttf')
          format('truetype');
    }

    @font-face {
      font-display: swap;
      font-family: 'GT America';
      font-style: italic;
      font-weight: 400;
      src: url('/fonts/gt-america-standard/GT-America-Standard-Regular-Italic.eot');
      src: url('/fonts/gt-america-standard/GT-America-Standard-Regular-Italic.eot?#iefix')
          format('embedded-opentype'),
        url('/fonts/gt-america-standard/GT-America-Standard-Regular-Italic.woff2')
          format('woff2'),
        url('/fonts/gt-america-standard/GT-America-Standard-Regular-Italic.woff')
          format('woff'),
        url('/fonts/gt-america-standard/GT-America-Standard-Regular-Italic.ttf')
          format('truetype');
    }

    @font-face {
      font-display: swap;
      font-family: 'GT America';
      font-style: normal;
      font-weight: 600;
      src: url('/fonts/gt-america-standard/GT-America-Standard-Medium.eot');
      src: url('/fonts/gt-america-standard/GT-America-Standard-Medium.eot?#iefix')
          format('embedded-opentype'),
        url('/fonts/gt-america-standard/GT-America-Standard-Medium.woff2')
          format('woff2'),
        url('/fonts/gt-america-standard/GT-America-Standard-Medium.woff')
          format('woff'),
        url('/fonts/gt-america-standard/GT-America-Standard-Medium.ttf')
          format('truetype');
    }

    @font-face {
      font-display: swap;
      font-family: 'GT America';
      font-style: italic;
      font-weight: 600;
      src: url('/fonts/gt-america-standard/GT-America-Standard-Medium-Italic.eot');
      src: url('/fonts/gt-america-standard/GT-America-Standard-Medium-Italic.eot?#iefix')
          format('embedded-opentype'),
        url('/fonts/gt-america-standard/GT-America-Standard-Medium-Italic.woff2')
          format('woff2'),
        url('/fonts/gt-america-standard/GT-America-Standard-Medium-Italic.woff')
          format('woff'),
        url('/fonts/gt-america-standard/GT-America-Standard-Medium-Italic.ttf')
          format('truetype');
    }

    @font-face {
      font-display: swap;
      font-family: 'GT America Mono';
      font-style: normal;
      font-weight: 400;
      src: url('/fonts/gt-america-mono/GT-America-Mono-Regular.eot');
      src: url('/fonts/gt-america-mono/GT-America-Mono-Regular.eot?#iefix')
          format('embedded-opentype'),
        url('/fonts/gt-america-mono/GT-America-Mono-Regular.woff2')
          format('woff2'),
        url('/fonts/gt-america-mono/GT-America-Mono-Regular.woff')
          format('woff'),
        url('/fonts/gt-america-mono/GT-America-Mono-Regular.ttf')
          format('truetype');
    }

    @font-face {
      font-display: swap;
      font-family: 'GT America Mono';
      font-style: normal;
      font-weight: 600;
      src: url('/fonts/gt-america-mono/GT-America-Mono-Bold.eot');
      src: url('/fonts/gt-america-mono/GT-America-Mono-Bold.eot?#iefix')
          format('embedded-opentype'),
        url('/fonts/gt-america-mono/GT-America-Mono-Bold.woff2') format('woff2'),
        url('/fonts/gt-america-mono/GT-America-Mono-Bold.woff') format('woff'),
        url('/fonts/gt-america-mono/GT-America-Mono-Bold.ttf')
          format('truetype');
    }
  `}</style>
)
