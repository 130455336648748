import { NextApiRequest, NextApiResponse } from 'next'
import { verifyCSRFToken } from '@lib/csrf'
import { ORG_HEADER_NAME, LIVE_MODE_HEADER_NAME } from '@lib/duffel-client/lib'
import { getTokenWithMode } from './data-helpers'
import { maybeGetJWT } from './maybe-get-jwt'
import selfCache from './self-cache'
import { getSelfInvalidationTimeFromCookie } from './cookie'

export const getJWTPayloadFromSelf = async (
  req: NextApiRequest,
  res: NextApiResponse,
  skipCsrf?: boolean
) => {
  const jwt = maybeGetJWT(req, res)
  // We allow this option while migrating endpoints to new auth
  if (!skipCsrf) {
    verifyCSRFToken(req, jwt)
  }

  if (!jwt) {
    return null
  }

  selfCache.maybeInvalidate(jwt.token, getSelfInvalidationTimeFromCookie(req))

  // use the token in jwt from cookie to fetch the corresponding self
  const self = await selfCache.getOrFetch(jwt.token ?? '')

  // if the user doesn't have an organisation, use the jwt that was set upon signed in
  // (which is a personal access token)
  if (self.organisationSlugs.length === 0) {
    return jwt
  }

  const org = String(req.headers[ORG_HEADER_NAME])

  // ensure that we default to live mode if it's a verified org and no mode was specified
  const liveMode =
    req.headers[LIVE_MODE_HEADER_NAME] ||
    String(self.organisationsBySlug[org as string]?.isVerified) ||
    'false'

  // grab the access token based on the org and mode in the header from self
  const payload = getTokenWithMode(self, org, liveMode === 'true')

  return payload
}
