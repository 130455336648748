import { LiveModeAlertCallout } from '@components/LiveModeAlertCallout'
import { moneyStringFormatter } from '@lib/helpers'
import { trackEvent } from '@lib/tracking'
import { DuffelAPI } from '@lib/types'
import { WorkspaceContextType } from '@lib/workspace-context'

export const onPayWithBalance = async (
  offerRequestId: string,
  offer: DuffelAPI.Types.Offer,
  totalAmountWithServices: string,
  context: WorkspaceContextType,
  submitForm: () => void
) => {
  context.openDialog({
    title: 'Pay with Duffel Balance',
    message: (
      <div>
        By selecting this option, we will automatically debit{' '}
        <b>
          {moneyStringFormatter(offer.totalCurrency)(+totalAmountWithServices)}
        </b>{' '}
        from your account balance.
        <LiveModeAlertCallout>
          You are about to create a live order
        </LiveModeAlertCallout>
      </div>
    ),
    confirmButtonLabel: 'Confirm Booking',
    disableCloseOnEscapeKey: true,
    onConfirm: () => {
      trackEvent('dashboard_offer_checkout_pay_confirm_button_clicked', {
        event_type: 'interaction',
        offer_request_id: offerRequestId,
        offer_id: offer.id,
        payment_type: 'balance',
      })
      submitForm()
      context.updateDialog({ isConfirmButtonLoading: true })
    },
    onCancel: () => {
      trackEvent('dashboard_offer_checkout_pay_cancel_button_clicked', {
        event_type: 'interaction',
        offer_request_id: offerRequestId,
        offer_id: offer.id,
        payment_type: 'balance',
      })
      context.closeDialog()
    },
  })
}

export const onPayWithARCBSPCash = async (
  offerRequestId: string,
  offer: DuffelAPI.Types.Offer,
  totalAmountWithServices: string,
  context: WorkspaceContextType,
  submitForm: () => void
) => {
  context.openDialog({
    title: 'Pay with ARC/BSP Cash',
    message: (
      <div>
        By selecting this option, we will automatically debit{' '}
        <b>
          {moneyStringFormatter(offer.totalCurrency)(+totalAmountWithServices)}
        </b>{' '}
        from your account.
        <LiveModeAlertCallout>
          You are about to create a live order
        </LiveModeAlertCallout>
      </div>
    ),
    confirmButtonLabel: 'Confirm Booking',
    disableCloseOnEscapeKey: true,
    onConfirm: () => {
      trackEvent('dashboard_offer_checkout_pay_confirm_button_clicked', {
        event_type: 'interaction',
        offer_request_id: offerRequestId,
        offer_id: offer.id,
        payment_type: 'arc_bsp_cash',
      })
      submitForm()
      context.updateDialog({ isConfirmButtonLoading: true })
    },
    onCancel: () => {
      trackEvent('dashboard_offer_checkout_pay_cancel_button_clicked', {
        event_type: 'interaction',
        offer_request_id: offerRequestId,
        offer_id: offer.id,
        payment_type: 'arc_bsp_cash',
      })
      context.closeDialog()
    },
  })
}

export const onPayLater = async (
  offerRequestId: string,
  offer: DuffelAPI.Types.Offer,
  context: WorkspaceContextType,
  submitForm: () => void
) => {
  context.openDialog({
    title: 'Pay later',
    width: 450,
    message: (
      <div>
        By selecting this option, this order will be put on hold. <br />
        That means this transaction is not complete and will require payment at
        a later time.
        <LiveModeAlertCallout>
          You are about to create a live order
        </LiveModeAlertCallout>
      </div>
    ),
    confirmButtonLabel: 'Confirm Pay Later',
    onConfirm: () => {
      trackEvent('dashboard_offer_checkout_pay_confirm_button_clicked', {
        event_type: 'interaction',
        offer_request_id: offerRequestId,
        offer_id: offer.id,
        payment_type: 'pay_later',
      })
      submitForm()
      context.updateDialog({ isConfirmButtonLoading: true })
    },
    onCancel: () => {
      trackEvent('dashboard_offer_checkout_pay_cancel_button_clicked', {
        event_type: 'interaction',
        offer_request_id: offerRequestId,
        offer_id: offer.id,
        payment_type: 'pay_later',
      })
      context.closeDialog()
    },
  })
}
