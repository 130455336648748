import { parse } from 'cookie'
import { getCookieDomain } from '@lib/cookies'
import { isBrowser } from '@lib/env'

const isDuffelUserCookieKey = 'ff-idu'
const isExistingUserCookieKey = 'ff-ieu'

export const areCohortPropertiesSetOnCookie = (): boolean => {
  if (!isBrowser || !document.cookie) return false

  return document.cookie
    .split(';')
    .some(
      (item) =>
        item.trim().startsWith(`${isDuffelUserCookieKey}=`) ||
        item.trim().startsWith(`${isExistingUserCookieKey}=`)
    )
}

export const setCohortPropertiesOnCookie = (isDuffelUser: boolean) => {
  if (!isBrowser || !window.document.cookie) return {}
  const domain = getCookieDomain(undefined, true)

  document.cookie = `${isDuffelUserCookieKey}=${isDuffelUser.toString()}; expires=Fri, 31 Dec 9999 23:59:59 GMT; domain=${domain}`
  document.cookie = `${isExistingUserCookieKey}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; domain=${domain}`
}

export const getCohortPropertiesFromCookie = () => {
  if (!isBrowser || !document.cookie) return

  const cookieObject = parse(document.cookie)

  return {
    isDuffelUser:
      (cookieObject[isDuffelUserCookieKey] &&
        cookieObject[isDuffelUserCookieKey] === 'true') ||
      false,
    isExistingUser:
      (cookieObject[isExistingUserCookieKey] &&
        cookieObject[isExistingUserCookieKey] === 'true') ||
      false,
  }
}
