import { DuffelAPI } from '@lib/types'
import { makeMockOfferRequestAdultPassenger } from '.'

const getDefaultArray = (() => {
  return (): DuffelAPI.Inputs.OfferRequestAdultPassenger[] => [
    makeMockOfferRequestAdultPassenger(),
    makeMockOfferRequestAdultPassenger({
      givenName: 'Pepper',
      familyName: 'Potts',
    }),
  ]
})()

export const makeMockOfferRequestAdultPassengers = (
  extendDefault: DuffelAPI.Inputs.OfferRequestAdultPassenger[] = []
): DuffelAPI.Inputs.OfferRequestAdultPassenger[] => [
  ...getDefaultArray(),
  ...extendDefault,
]
