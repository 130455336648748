import { useRouter } from 'next/router'
import * as React from 'react'

/**
 * This hook is useful for when you are applying a loading state to a Button
 * that navigates to another page. This hook will set the loading state to
 * false when the page navigation completes or errors.
 */
export const useNavigationStateStopper = (): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
] => {
  const router = useRouter()

  const [isNavigating, setIsNavigating] = React.useState(false)

  const handleStop = () => {
    setIsNavigating(false)
  }

  React.useEffect(() => {
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router.events])

  return [isNavigating, setIsNavigating]
}
