import { DuffelAPI } from '@lib/types'

export const DEFAULT_SEARCH_TIME_FROM = '00:00'
export const DEFAULT_SEARCH_TIME_TO = '23:59'

export const SEARCH_TIME_OPTIONS = [...Array(25).keys()].map((_, idx) => {
  if (idx === 24) {
    return DEFAULT_SEARCH_TIME_TO
  }

  return `${idx < 10 ? '0' : ''}${idx}:00`
})

export const defaultSearchTimeRanges = {
  departureTime: {
    from: DEFAULT_SEARCH_TIME_FROM,
    to: DEFAULT_SEARCH_TIME_TO,
  },
  arrivalTime: {
    from: DEFAULT_SEARCH_TIME_FROM,
    to: DEFAULT_SEARCH_TIME_TO,
  },
}

export const isDefaultSearchTimeRange = (
  timeRange: DuffelAPI.Inputs.OfferRequestSliceTimeRange
) =>
  timeRange.from === DEFAULT_SEARCH_TIME_FROM &&
  timeRange.to === DEFAULT_SEARCH_TIME_TO

export interface SearchTimeRanges {
  departureTime: {
    from: string
    to: string
  }
  arrivalTime: {
    from: string
    to: string
  }
}

export const isDefaultSearchTimeRanges = (
  timeRanges: SearchTimeRanges | undefined
) => {
  if (!timeRanges) {
    return true
  }
  const { departureTime, arrivalTime } = timeRanges
  return (
    isDefaultSearchTimeRange(departureTime) &&
    isDefaultSearchTimeRange(arrivalTime)
  )
}

export const formatTimeRangeForPayload = (timeRange?: {
  from: string
  to: string
}) => {
  // only include the time if it's not the default value
  if (!timeRange || isDefaultSearchTimeRange(timeRange)) {
    return
  }

  const timeRangeForPayload: DuffelAPI.Inputs.OfferRequestSliceTimeRange = {
    from: timeRange.from,
  }
  // only include the to part if tbe departure time is not 23:59
  if (timeRange.to !== DEFAULT_SEARCH_TIME_TO) {
    timeRangeForPayload.to = timeRange.to
  }

  return timeRangeForPayload
}
