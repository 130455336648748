import { encrypt } from '@lib/encryption'
import { JwtPayload } from '@lib/types'

export const createBasisForToken = (fromJwt: JwtPayload): string =>
  JSON.stringify({ userId: fromJwt.userId, iat: fromJwt.iat || Date.now() })

export const getBasisFromTokenString = (
  fromString: string
): Partial<JwtPayload> => JSON.parse(fromString)

export function createCSRFToken(fromJwt: JwtPayload): string {
  return encrypt(createBasisForToken(fromJwt))
}
