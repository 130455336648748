import * as React from 'react'
import { useFormikContext } from 'formik'
import { PassengerInfoNameContainer } from '@components/PassengerInfoNameContainer'
import { CommonSearchFormSectionValues } from '../CommonSearchFormSection'
import { LoyaltyAccountsInput } from './LoyaltyAccountsInput'
import {
  initialLoyaltyProgrammeAccountValues,
  OfferRequestPassengerWithIndex,
} from '.'

interface PassengerLoyaltyAccountsFormDrawerProps {
  /**
   * OfferRequestPassenger object with an index from an OfferRequestPassenger[]
   */
  passenger: OfferRequestPassengerWithIndex
}

export const PassengerLoyaltyAccountsFormDrawer: React.FC<
  PassengerLoyaltyAccountsFormDrawerProps
> = ({ passenger }) => {
  const { setFieldValue } = useFormikContext<CommonSearchFormSectionValues>()
  const areLoyaltyProgrammeAccountsMissing =
    !passenger.loyaltyProgrammeAccounts ||
    passenger.loyaltyProgrammeAccounts.length === 0

  // initialize passenger.loyaltyProgrammeAccounts if it doesn't exist
  if (areLoyaltyProgrammeAccountsMissing) {
    setFieldValue(
      `passengers.${passenger.index}.loyaltyProgrammeAccounts[0].airlineIataCode`,
      initialLoyaltyProgrammeAccountValues.airlineIataCode
    )
    setFieldValue(
      `passengers.${passenger.index}.loyaltyProgrammeAccounts[0].accountNumber`,
      initialLoyaltyProgrammeAccountValues.accountNumber
    )
  }

  return (
    <section
      className="loyalty-code__drawer"
      data-testid="loyalty-code__drawer"
    >
      <PassengerInfoNameContainer
        index={passenger.index}
        intent="loyalty-programmes"
        excludeTitle
        optionalFields="all"
      />
      {passenger.loyaltyProgrammeAccounts &&
        passenger.loyaltyProgrammeAccounts.map((_account, index) => (
          <LoyaltyAccountsInput
            key={`loyaltyCodes.${index}.title`}
            passenger={passenger}
            accountIndex={index}
          />
        ))}

      <style jsx>{`
        .loyalty-code__drawer {
        }
      `}</style>
    </section>
  )
}
