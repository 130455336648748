import { CabinClassMap, DuffelAPI } from '@lib/types'

export const getFareBrandName = (
  fareBrandName: string | null,
  forSegment:
    | DuffelAPI.Types.OfferSliceSegment
    | DuffelAPI.Types.OrderSliceSegment
) =>
  fareBrandName ||
  (forSegment.passengers &&
    forSegment.passengers.length > 0 &&
    (forSegment.passengers[0]?.cabinClassMarketingName ||
      CabinClassMap[forSegment.passengers[0].cabinClass])) ||
  ''
