import { DuffelAPI } from '@lib/types'

export const makeMockOrderServices = (
  extendDefault: DuffelAPI.Types.OrderService[] = [],
  segmentIds?: DuffelAPI.Types.OrderService['segmentIds'],
  passengerIds?: DuffelAPI.Types.OrderService['passengerIds']
): DuffelAPI.Types.OrderService[] => {
  const mockOrderSeatService = getDefaultSeatObject()
  const mockOrderBaggageService = getDefaultBaggageObject()

  if (segmentIds) {
    mockOrderSeatService.segmentIds = segmentIds
    mockOrderBaggageService.segmentIds = segmentIds
  }

  if (passengerIds) {
    mockOrderSeatService.passengerIds = passengerIds
    mockOrderBaggageService.passengerIds = passengerIds
  }

  return [...extendDefault, mockOrderSeatService, mockOrderBaggageService]
}

const getDefaultSeatObject = (() => {
  let seedId = 1
  const passengerId = (seedId++).toString().padStart(5, '0')
  const segmentId = (seedId++).toString().padStart(5, '0')

  return (): DuffelAPI.Types.OrderService => {
    return {
      type: 'seat',
      totalCurrency: 'GBP',
      totalAmount: '15.00',
      segmentIds: [`seg_${segmentId}`],
      quantity: 1,
      passengerIds: [`pas_${passengerId}`],
      metadata: {
        designator: '14B',
        disclosures: [
          'Do not seat children in exit row seats',
          'Do not seat passengers with special needs in exit row seats',
        ],
        name: 'Exit row seat',
      },
      id: `ser_${(seedId++).toString().padStart(5, '0')}`,
    }
  }
})()

const getDefaultBaggageObject = (() => {
  let seedId = 1
  const passengerId = (seedId++).toString().padStart(5, '0')
  const segmentId = (seedId++).toString().padStart(5, '0')

  return (): DuffelAPI.Types.OrderService => {
    return {
      type: 'baggage',
      totalCurrency: 'GBP',
      totalAmount: '15.00',
      segmentIds: [`seg_${segmentId}`],
      quantity: 1,
      passengerIds: [`pas_${passengerId}`],
      metadata: {
        maximumDepthCm: null,
        maximumHeightCm: null,
        maximumLengthCm: null,
        maximumWeightKg: null,
        type: 'checked',
      },
      id: `ser_${(seedId++).toString().padStart(5, '0')}`,
    }
  }
})()
