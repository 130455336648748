export const FONTS = {
  GTAmericaMono: 'GT America Mono' as const,
  GTAmerica: 'GT America' as const,
}

export type FontFamily = keyof typeof FONTS
export type FontValue = (typeof FONTS)[FontFamily]

export interface FontWeightMap {
  GTAmericaMono: 400 | 600
  GTAmerica: 400 | 500
}

export interface FontStyleMap {
  GTAmericaMono: 'normal'
  GTAmerica: 'normal' | 'italic'
}
