import { useField, useFormikContext } from 'formik'
import * as React from 'react'
import { FormField } from '@components/Form'
import { Select } from '@components/Select'
import { COUNTRIES, CountryCode, getApprovedCountries } from '@lib/constants'

interface CountrySelectProps {
  id?: string
  label?: string
  error?: string
  isFieldOptional?: boolean
  className?: string
  value: CountryCode
  onChange: (countryCode: CountryCode | '') => void
  showInfoTooltip?: boolean
  infoTooltipText?: string
  restrictCountries?: boolean
  disabled?: boolean
  placeholder?: string
}

export const CountrySelect: React.FC<CountrySelectProps> = ({
  id,
  label,
  error,
  isFieldOptional,
  className,
  value,
  onChange,
  showInfoTooltip,
  infoTooltipText,
  restrictCountries = false,
  disabled = false,
  placeholder,
}) => {
  const countriesList = restrictCountries ? getApprovedCountries() : COUNTRIES
  return (
    <FormField
      label={label || 'Country'}
      error={error}
      optionalField={isFieldOptional}
      htmlFor={id}
      className={className}
      showInfoTooltip={showInfoTooltip}
      infoTooltipText={
        infoTooltipText ||
        'This should be the country where your business operates from, and will determine the currency your fees will be charged in.'
      }
      disabled={disabled}
    >
      <Select
        id={id}
        data-testid={id}
        value={value}
        isError={!!error}
        onChange={(event) => onChange(event.target.value as CountryCode | '')}
        disabled={disabled}
        placeholder={placeholder}
      >
        {!placeholder && <option value="">--</option>}
        {Object.keys(countriesList).map((code) => (
          <option value={code} key={code}>
            {countriesList[code].name} ({code})
          </option>
        ))}
      </Select>
    </FormField>
  )
}

export interface FormikCountrySelectProps {
  id?: string
  name: string
  label?: string
  optionalField?: boolean
  className?: string
  onChange?: (value: CountryCode | '') => void
  showInfoTooltip?: boolean
  restrictCountries?: boolean
  placeholder?: string
}

export const FormikCountrySelect: React.FC<FormikCountrySelectProps> = ({
  id,
  name,
  label,
  optionalField,
  className,
  onChange,
  showInfoTooltip = false,
  restrictCountries = false,
  placeholder,
}) => {
  const [{ value }, { touched, error }, { setValue }] = useField({ name })
  const { submitCount, isSubmitting } = useFormikContext()

  return (
    <CountrySelect
      id={id || name}
      onChange={(value) => {
        setValue(value)
        onChange && onChange(value)
      }}
      value={value}
      error={touched || submitCount > 0 ? error : undefined}
      label={label}
      isFieldOptional={optionalField}
      className={className}
      showInfoTooltip={showInfoTooltip}
      restrictCountries={restrictCountries}
      disabled={isSubmitting}
      placeholder={placeholder}
    />
  )
}
