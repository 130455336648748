import { DuffelAPI } from '@lib/types'
import { InvoiceItemType } from '../Chart/types'
import { formatNumberWithCommas } from './format-number-with-commas'
import { getCurrencySymbol } from './get-currency-symbol'

/**
 * Returns primary and secondary descriptions for each invoice
 * item type, to display in charts
 */
export const getInvoiceItemDescriptions = (
  itemType: InvoiceItemType,
  invoiceItemSummary?: DuffelAPI.Types.InvoiceItem,
  quantity?: string
) => {
  const tiers = invoiceItemSummary?.calculation.params.tiers

  const isDependentOnTiers: InvoiceItemType[] = [
    'Paid orders',
    'Paid ancillaries',
    'Managed content',
  ]

  const tierDiffs = tiers
    ?.map((tier) => {
      const rate = Number(tier.rate)
      const limit = Number(tier.limit)
      const diff = limit - Number(quantity)
      return { rate, limit, diff }
    })
    .filter((tier) => tier.diff > 0)

  const currentTier = tierDiffs && tierDiffs[0]
  const nextTier = tierDiffs && tierDiffs[1]

  // If tiers are not defined in payment plan, do not show descriptions
  if (
    isDependentOnTiers.includes(itemType) &&
    (!currentTier?.rate || !nextTier?.rate || !currentTier.diff)
  ) {
    return {
      primaryDescription: '',
      secondaryDescription: '',
    }
  }

  switch (itemType) {
    case 'Paid orders':
      return {
        primaryDescription: `${getCurrencySymbol(
          invoiceItemSummary?.currency || 'USD'
        )}${currentTier?.rate.toFixed(2)} per order`,
        secondaryDescription: `${formatNumberWithCommas(
          currentTier?.diff
        )} more orders to ${getCurrencySymbol(
          invoiceItemSummary?.currency || 'USD'
        )}${nextTier?.rate.toFixed(2)} per order`,
      }

    case 'Paid ancillaries':
      return {
        primaryDescription: `${getCurrencySymbol(
          invoiceItemSummary?.currency || 'USD'
        )}${currentTier?.rate.toFixed(2)} per paid ancillary`,
        secondaryDescription: `${currentTier?.diff} more to ${getCurrencySymbol(
          invoiceItemSummary?.currency || 'USD'
        )}${nextTier?.rate.toFixed(2)} per paid ancillary`,
      }

    case 'Managed content':
      return {
        primaryDescription:
          (currentTier &&
            `${(currentTier?.rate * 100).toFixed(2)}% Total Order Value`) ||
          '',
        secondaryDescription:
          (nextTier &&
            `${getCurrencySymbol(
              invoiceItemSummary?.currency || 'USD'
            )}${formatNumberWithCommas(currentTier?.diff, true)} more to ${(
              nextTier?.rate * 100
            ).toFixed(2)}% TOV`) ||
          '',
      }

    case 'Excess searches':
      return {
        primaryDescription:
          `${getCurrencySymbol(invoiceItemSummary?.currency || 'USD')}${
            invoiceItemSummary?.calculation.params.rate
          } per excess search` || '',
        secondaryDescription: '',
      }

    default:
      return {
        primaryDescription: '',
        secondaryDescription: '',
      }
  }
}
