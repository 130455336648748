import { format } from 'date-fns'
import { Text } from '@components/Text'
import { DuffelAPI } from '@lib/types'
import {
  SUPPORT_CATEGORY_COPY_MAP,
  SupportCategory,
  ZendeskSupportTicket,
} from '@lib/zendesk'
import { getDateString } from '@lib/date'
import {
  ErrorSupportOrderManagementAction,
  OrderManagementAction,
  SupportOrderManagementAction,
} from './types'

export const ORDER_MANAGEMENT_ERROR_SUPPORT_TITLE_MAP: Record<
  ErrorSupportOrderManagementAction,
  string
> = {
  add_bags: 'Adding bags unsuccessful',
  change_order: 'Change unsuccessful',
  cancel_order: 'Cancellation unsuccessful',
  accept_airline_initiated_change: 'Action unsuccessful',
}

export const ORDER_MANAGEMENT_ACTION_UNAVAILABLE_SUPPORT_TITLE_MAP: Record<
  SupportOrderManagementAction,
  string
> = {
  add_seats: 'Request seats',
  cancel_order: 'Request cancellation',
  change_order: 'Request change',
  name_correction: 'Request name change',
  add_bags: 'Request bags',
  dashboard_other: 'Other requests',
}

export const ORDER_MANAGEMENT_ERROR_ACTION_PHRASE_MAP: Record<
  ErrorSupportOrderManagementAction,
  string
> = {
  accept_airline_initiated_change: 'accept the changes for the order',
  add_bags: 'add bags to the order',
  change_order: 'change the order',
  cancel_order: 'cancel the order',
}

export const getUnavailableActionDescription = (
  actionToSupport: SupportOrderManagementAction
): React.ReactElement => {
  switch (actionToSupport) {
    case 'cancel_order': {
      return (
        <>
          <Text color="grey-700" data-selector="fs-show">
            Cancellations with this airline aren’t available programmatically
            yet, but we can guide you in making a request. Our travel support
            team can assist you with the cancellation of this flight.
          </Text>
          <Text color="grey-700" data-selector="fs-show">
            Feel free to add any additional information below.
          </Text>
        </>
      )
    }
    case 'change_order': {
      return (
        <>
          <Text color="grey-700" data-selector="fs-show">
            Order changes with this airline aren’t available programmatically
            yet, but we can guide you in making a request. Our travel support
            team can assist you with the change of this flight(s).
          </Text>
          <Text color="grey-700" data-selector="fs-show">
            To help us process this faster, tell us the flight(s) you want to
            change and the preferred dates/time or specific flights, as well as
            any additional information.
          </Text>
        </>
      )
    }
    case 'add_seats': {
      return (
        <>
          <Text color="grey-700" data-selector="fs-show">
            This functionality isn’t available programmatically yet, but we can
            guide you in making a request. Our travel support team can assist
            you with the purchase of seats.
          </Text>
          <Text color="grey-700" data-selector="fs-show">
            Please tell us what seat preference (aisle, middle, window, or any
            specific seat number) and to which passenger/flight you want to make
            the request for, as well as any additional information.
          </Text>
        </>
      )
    }
    case 'name_correction': {
      return (
        <>
          <Text color="grey-700" data-selector="fs-show">
            This functionality isn't available programmatically yet, but we can
            guide you in making a request. Our travel support team can assist
            you making name corrections.
          </Text>
          <Text color="grey-700" data-selector="fs-show">
            Please tell us the passenger and their correct name, as well as any
            additional information.
          </Text>
        </>
      )
    }
    case 'add_bags': {
      return (
        <>
          <Text color="grey-700" data-selector="fs-show">
            This functionality isn't available programmatically yet, but we can
            guide you in making a request. Our travel support team can assist
            you with the purchase of additional bags.
          </Text>
          <Text color="grey-700" data-selector="fs-show">
            Please tell us how many, what weight/type of bags, and to which
            passenger/flight you want to make a request for, as well as any
            additional information.
          </Text>
        </>
      )
    }
    case 'dashboard_other': {
      return (
        <>
          <Text color="grey-700" data-selector="fs-show">
            Our travel support team can assist you with your request.
          </Text>
          <Text color="grey-700" data-selector="fs-show">
            Please add any additional detail.
          </Text>
        </>
      )
    }
  }
}

const getUnavailableActionTicketBody = (
  order: DuffelAPI.Types.Order,
  actionToSupport: SupportOrderManagementAction
): string => {
  switch (actionToSupport) {
    case 'cancel_order':
      return `I'd like to request a quote for the cancellation of the order ${order.bookingReference}.`
    case 'change_order':
      return [
        `I'd like to request a quote for the following changes to the order ${order.bookingReference}.\n`,
        ...order.slices
          .flatMap((slice) => slice.segments)
          .map((segment, index, segments) => {
            const lines = [
              getFlightDescription(segment),
              '\tPreferred date/time or specific flight: <add info>',
            ]

            const section = lines.join('\n')

            if (index !== segments.length - 1) {
              return section.concat('\n')
            }
            return section
          }),
      ].join('\n')
    case 'add_seats': {
      return [
        `I'd like to request the following seats quote for the order ${order.bookingReference}\n`,
        ...order.slices
          .flatMap((slice) => slice.segments)
          .map((segment, index, segments) => {
            const lines: string[] = [getFlightDescription(segment)]

            order.passengers.forEach((passenger) => {
              lines.push(
                `\tPassenger Name: ${passenger.givenName} ${passenger.familyName}`
              )
              lines.push('\t\tSeat preference: <insert seat number/preference>')
            })

            const section = lines.join('\n')

            if (index !== segments.length - 1) {
              return section.concat('\n')
            }
            return section
          }),
      ].join('\n')
    }
    case 'name_correction': {
      const request = `I'd like to request a passenger name correction for the order ${order.bookingReference}.`
      const passengerNames = order.passengers.map(
        ({ givenName, familyName }) => `${givenName} ${familyName}`
      )
      const passengerNameChanges = passengerNames
        .map(
          (name) =>
            `\nCurrent name: ${name}\nCorrected name: <insert name here>`
        )
        .join('\n')
      return [request, passengerNameChanges].join('\n')
    }
    case 'add_bags': {
      return [
        `I'd like to request the following bags for the order ${order.bookingReference}.\n`,
        ...order.slices
          .flatMap((slice) => slice.segments)
          .map((segment, index, segments) => {
            const lines: string[] = [getFlightDescription(segment)]
            order.passengers.forEach((passenger) => {
              lines.push(
                `Passenger Name: ${passenger.givenName} ${passenger.familyName}`
              )
              lines.push('\tNumber of bags: <insert number of bags>')
              lines.push('\t\tBag weight/type: <specify bag weight/type>')
            })

            const section = lines.join('\n')

            if (index !== segments.length - 1) {
              return section.concat('\n')
            }
            return section
          }),
      ].join('\n')
    }
    case 'dashboard_other': {
      const lines: string[] = []
      lines.push(
        `I'd like to make a request relating to the order ${order.bookingReference}.`
      )
      lines.push('<Your request>')
      return lines.join('\n')
    }
  }
}

const getFlightDescription = (segment: DuffelAPI.Types.OrderSliceSegment) =>
  `Flight: ${segment.origin.iataCode} to ${
    segment.destination.iataCode
  } on ${getDateString(segment.departingAt, 'long')}`

const getErrorTicketBody = (
  order: DuffelAPI.Types.Order,
  actionToSupport: OrderManagementAction
): string =>
  `I was trying to ${ORDER_MANAGEMENT_ERROR_ACTION_PHRASE_MAP[actionToSupport]} ${order.bookingReference}, but I ran into an error. Could you please support with this?`

const SUPPORT_TICKET_HEADING = 'Hi Duffel team,'
const SUPPORT_TICKET_VALEDICTION = 'Kind regards,'

export const getErrorOrderSupportTicketMessage = (
  order: DuffelAPI.Types.Order,
  actionToSupport: ErrorSupportOrderManagementAction,
  userFullName: DuffelAPI.Types.User['fullName'],
  organisationName: DuffelAPI.Types.Organisation['name']
) => {
  const body = getErrorTicketBody(order, actionToSupport)
  const signature = `${userFullName}, ${organisationName}`

  return [
    SUPPORT_TICKET_HEADING,
    `\n${body}\n`,
    SUPPORT_TICKET_VALEDICTION,
    signature,
  ].join('\n')
}

export const getUnavailableActionSupportTicketMessage = (
  order: DuffelAPI.Types.Order,
  actionToSupport: SupportOrderManagementAction,
  userFullName: DuffelAPI.Types.User['fullName'],
  organisationName: DuffelAPI.Types.Organisation['name']
) => {
  const body = getUnavailableActionTicketBody(order, actionToSupport)
  const signature = `${userFullName}, ${organisationName}`

  return [
    SUPPORT_TICKET_HEADING,
    `\n${body}\n`,
    SUPPORT_TICKET_VALEDICTION,
    signature,
  ].join('\n')
}

export const getOrderSupportTicketCategory = (
  actionToSupport: OrderManagementAction
): SupportCategory => {
  switch (actionToSupport) {
    case 'accept_airline_initiated_change':
      return 'airline-initiated-change'
    case 'add_bags':
      return 'baggage_request'
    case 'cancel_order':
      return 'cancellation-refund'
    case 'change_order':
      return 'order-change'
    case 'add_seats':
      return 'seat_selection'
    case 'name_correction':
      return 'name_correction'
    case 'dashboard_other':
      return 'dashboard-other'
  }
}

export const getUnsolvedOrderSupportTicketForCategory = (
  tickets: ZendeskSupportTicket[],
  category: SupportCategory
): ZendeskSupportTicket | undefined => {
  return tickets.find(
    (ticket) =>
      ticket.category === category &&
      ticket.isOrderSupportTicket &&
      (ticket.status === 'new' ||
        ticket.status === 'open' ||
        ticket.status === 'pending' ||
        ticket.status === 'hold')
  )
}

export const getUnsolvedOrderSupportTicketNoticeCopy = (
  unsolvedSupportTicket: ZendeskSupportTicket
): string | null => {
  if (!unsolvedSupportTicket.category || !unsolvedSupportTicket.createdAt) {
    return null
  }

  const ticketActionPhrase =
    SUPPORT_CATEGORY_COPY_MAP[unsolvedSupportTicket.category].actionPhrase

  const ticketFormattedCreatedAt = format(
    new Date(unsolvedSupportTicket.createdAt),
    'eee, d LLL y, p'
  )

  return `Please note, there is already an open support ticket to ${ticketActionPhrase} submitted on ${ticketFormattedCreatedAt}`
}
