import * as React from 'react'
import classNames from 'classnames'
import { VSpace } from '@components/VSpace'
import { Text } from '@components/Text'
import { RangeSlider, Range } from '@components/RangeSlider/RangeSlider'
import { HSpace } from '@components/HSpace'
import { Icon, IconName } from '@components/Icon'
import { trackEvent } from '@lib/tracking'
import { SEARCH_TIME_OPTIONS } from '@modules/air-search-v2/forms/SearchForm/lib/search-time-ranges'
import { isAnyTimeRange } from './lib'

import styles from './TimeRangeSelector.module.css'

interface TimeRangeSelectorProps {
  selectedRange: Range
  onChange: (range: Range) => void
  label: string
  icon: IconName
  disabled?: boolean
}

export const getRangeLabel = (range: Range): string => {
  if (isAnyTimeRange(range)) {
    return 'at any time'
  }

  if (range.from === 0) {
    return `before ${SEARCH_TIME_OPTIONS[range.to]}`
  }

  if (range.to === 24) {
    return `after ${SEARCH_TIME_OPTIONS[range.from]}`
  }

  return `${SEARCH_TIME_OPTIONS[range.from]} - ${SEARCH_TIME_OPTIONS[range.to]}`
}

export const TimeRangeSelector: React.FC<TimeRangeSelectorProps> = ({
  label,
  selectedRange,
  onChange,
  icon,
  disabled,
}) => (
  <VSpace space={20}>
    <HSpace space={8} justify="space-between" alignCenter>
      <HSpace space={8} alignCenter justify="space-between" fill>
        <HSpace space={8} alignCenter>
          <Icon name={icon} size={16} className={styles['icon']} />
          <Text fontSize="C2" color="grey-700" data-selector="fs-show">
            {label}
          </Text>
        </HSpace>
        <HSpace space={8} alignCenter>
          <Text
            fontSize="C2"
            asElement="span"
            data-selector="fs-show"
            color={isAnyTimeRange(selectedRange) ? 'grey-500' : 'grey-900'}
          >
            {getRangeLabel(selectedRange)}
          </Text>
          {!isAnyTimeRange(selectedRange) && (
            <button
              onClick={() => {
                trackEvent('dashboard_search_time_ranges_reset', {
                  event_type: 'interaction',
                })
                onChange({ from: 0, to: 24 })
              }}
              className={classNames(styles['reset-button'], {
                'u-hidden': isAnyTimeRange(selectedRange),
              })}
            >
              <Icon name="close" size={12} />
            </button>
          )}
        </HSpace>
      </HSpace>
    </HSpace>

    <VSpace space={4}>
      <RangeSlider
        selectedRange={selectedRange}
        minValue={0}
        maxValue={24}
        step={1}
        onChange={(range: Range) => {
          if (disabled) {
            return
          }
          trackEvent('dashboard_search_time_ranges_changed', {
            event_type: 'interaction',
          })
          onChange(range)
        }}
        ariaLabel={`${label} Time`}
        disabled={disabled}
      />
      <HSpace space={0} justify="space-between">
        {['00:00', '08:00', '16:00', '23:59'].map((time) => (
          <Text key={time} fontSize="C3" color="grey-600">
            {time}
          </Text>
        ))}
      </HSpace>
    </VSpace>
  </VSpace>
)
