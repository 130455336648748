import { TrackingEvent } from './types'

export const isIdentifiableEvent = (event: TrackingEvent) =>
  new Set<TrackingEvent>([
    'signed_in',
    'dashboard_signup_confirmed',
    'dashboard_user_preferences_save_changes_confirmed',
    'dashboard_create_new_team_confirmed',
    'dashboard_join_accept_invitation_confirmed',
  ]).has(event)
