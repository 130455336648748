import { DuffelAPI } from '@lib/types'

const getDefaultObject = (): DuffelAPI.Types.OrderChangeRequestSlices => ({
  remove: [{ sliceId: 'sli_00001' }],
  add: [
    {
      origin: 'SEA',
      destination: 'MIA',
      departureDate: '2021-04-22',
      cabinClass: 'economy',
    },
  ],
})

export const makeMockOrderChangeRequestSlices = (
  extendDefault?: Partial<DuffelAPI.Types.OrderChangeRequestSlices>
): DuffelAPI.Types.OrderChangeRequestSlices =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
