import {
  flightSearchNGSResultsPathArray,
  flightSearchV2PartialResultPathArray,
} from '@lib/paths'
import { SearchType } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import { useRouter } from 'next/router'
import * as React from 'react'
import { SearchForm } from '../forms/SearchForm'
import { getInitialSearchFormValues } from '../lib/get-initial-search-form-values'
import { saveSearchFormValues } from '../lib/multi-step-search/search-params'
import { SearchFormValues } from '../lib/transform-search-form-values'

interface SearchFormContainerProps {
  initialSearchType?: SearchType
  initialFormValues?: SearchFormValues<SearchType>
  onSubmit?: () => void
}

// We seem to be having issues with feature flags here, so I'm hardcoding the NGS organisations for now
// This should really prevent any non-NGS organisations from using the NGS flow
const ngsOrganisations = [
  'org_0000AfNToEXKDIpHC6iuiu', // delta-test
  'org_0000AVi4T0BYSSj679BM9Y', // rippling-limited-subset-testing
  'org_00009rRTUGZJ9WORQRvZw0', // Organisation Duffel Employee - Steve D
  'org_00009UgX9goFl8ES7C2EOe', // Duffel Staging - temporary for testing previews
  // eslint-disable-next-line spellcheck/spell-checker
  'org_0000AMspesqqjh7Knnzjmb', // Duffel Demo US - Aliesha - Oct 2024
]

export const SearchFormContainer: React.FC<SearchFormContainerProps> = ({
  initialFormValues,
  onSubmit,
}) => {
  const { permissions, currentOrganisation } = useWorkspace()
  const router = useRouter()
  const showNGSView =
    // useFeatureFlags('dashboard_enable_ngs_flow') && // This is the feature flag that should be used in theory
    currentOrganisation?.id
      ? ngsOrganisations.includes(currentOrganisation?.id)
      : false

  return (
    <SearchForm
      initialFormValues={
        showNGSView
          ? {
              ...getInitialSearchFormValues(), // in case initialFormValues is undefined
              ...initialFormValues,
              cabinClass: 'any',
            }
          : initialFormValues
      }
      onSubmit={async (searchFormValues) => {
        const searchParamsId = await saveSearchFormValues(searchFormValues)

        if (showNGSView) {
          await router.push(
            ...flightSearchNGSResultsPathArray(
              permissions?.organisation,
              permissions?.liveMode,
              searchParamsId
            )
          )
          onSubmit?.()
        } else {
          await router.push(
            ...flightSearchV2PartialResultPathArray(
              permissions?.organisation,
              permissions?.liveMode,
              searchParamsId
            )
          )
          onSubmit?.()
        }
      }}
      hideCabinClass={showNGSView}
    />
  )
}
