import type { IncomingMessage } from 'http'
import { isDev } from '@lib/env'

/**
 * This module enables you to easily get the protocol and host of your Next.js app, both on the server and the client.
 * Optionally, you can set a localhost variable, which is useful for local development if you have local lambda functions running on a different port.
 * This is a fork from https://github.com/jakeburden/next-absolute-url
 * @param req
 * @param localhostAddress
 */
export const getUrl = (
  req?: IncomingMessage,
  localhostAddress = 'localhost:3000'
) => {
  let host =
    (req?.headers ? req.headers.host : window.location.host) || localhostAddress

  // we only use http on local development (NODE_ENV !== 'production')
  let protocol = isDev ? 'http:' : 'https:'

  if (
    req &&
    req.headers['x-forwarded-host'] &&
    typeof req.headers['x-forwarded-host'] === 'string'
  ) {
    host = req.headers['x-forwarded-host']
  }

  if (
    req &&
    req.headers['x-forwarded-proto'] &&
    typeof req.headers['x-forwarded-proto'] === 'string'
  ) {
    protocol = `${req.headers['x-forwarded-proto']}:`
  }

  let origin = protocol + '//' + host

  // this a little hack to make Unleash Proxy works locally
  if (host.includes('localhost:3000')) {
    origin = 'https://localhost:3000'
  }

  return {
    protocol,
    host,
    origin,
  }
}
