import { useRouter } from 'next/router'
import * as React from 'react'
import { VSpace } from '@components/VSpace'
import { captureException } from '@lib/sentry'
import { UNKNOWN_ERROR_MESSAGE } from '@lib/constants'
import { ordersPendingOrderChangePathObject } from '@lib/paths'
import { trackEvent } from '@lib/tracking'
import { DuffelAPI } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import { useUserFriendlyErrors } from '@modules/air-order/lib/use-user-friendly-errors'
import { OrderChangeOfferCard } from '../OrderChangeOfferCard'

export interface OrderChangeResultsListProps {
  order: DuffelAPI.Types.Order
  orderChangeRequest: DuffelAPI.Types.OrderChangeRequest
}

export const OrderChangeResultsList: React.FC<OrderChangeResultsListProps> = ({
  orderChangeRequest,
  order,
}) => {
  const [submittingId, setSubmittingId] = React.useState<string | null>(null)
  const { permissions, addToast, duffelClient } = useWorkspace()
  const router = useRouter()
  const { handleOrderErrors } = useUserFriendlyErrors()

  const offers = orderChangeRequest.orderChangeOffers

  const onOrderChangeOfferSelected = async (
    orderChangeOffer: DuffelAPI.Types.OrderChangeRequestOffer
  ) => {
    trackEvent(
      'dashboard_change_flights_offer_checkout_create_pending_change_buton_clicked',
      {
        event_type: 'interaction',
        order_id: order.id,
        order_change_offer_id: orderChangeOffer.id,
      }
    )
    setSubmittingId(orderChangeOffer.id)
    try {
      const response = await duffelClient.Air.createPendingOrderChange({
        selectedOrderChangeOffer: orderChangeOffer.id,
      })

      const metadata = {
        requestId: response.meta?.requestId ?? '',
        actionToSupport: 'change_order',
        bookingReference: order.bookingReference,
        orderId: order.id,
      } as const

      if (response.errors) {
        trackEvent('dashboard_change_flights_create_pending_change_failed', {
          error_message: response.errors[0].message,
          event_type: 'api',
          order_id: order.id,
          order_change_id: orderChangeOffer.id,
        })

        await handleOrderErrors(response.errors, order, metadata)
        return
      }

      if (!response.data) {
        trackEvent('dashboard_change_flights_create_pending_change_failed', {
          error_message: UNKNOWN_ERROR_MESSAGE,
          event_type: 'api',
          order_id: order.id,
          order_change_id: orderChangeOffer.id,
        })

        await handleOrderErrors([], order, metadata)
        return
      }

      const orderChangeId = response.data.id

      trackEvent(
        'dashboard_change_flights_offer_checkout_create_pending_change_completed',
        {
          event_type: 'api',
          order_id: order.id,
          order_change_offer_id: orderChangeOffer.id,
          order_change_id: orderChangeId,
        }
      )

      router.push(
        ordersPendingOrderChangePathObject(
          permissions?.organisation,
          permissions?.liveMode,
          order.id,
          orderChangeRequest.id,
          orderChangeId
        ).as
      )
    } catch (error: any) {
      const alertMessage =
        typeof error === 'string' ? error : UNKNOWN_ERROR_MESSAGE
      addToast({
        message: alertMessage,
        intent: 'warning',
        closeAfterTimeout: false,
      })

      trackEvent(
        'dashboard_change_flights_offer_checkout_create_pending_change_alert_displayed',
        {
          event_type: 'alert',
          order_id: order.id,
          order_change_offer_id: orderChangeOffer.id,
          alert_message: alertMessage,
        }
      )

      captureException(new Error(error))

      setSubmittingId(null)
    }
  }

  return (
    <div className="order-change-results-list">
      <VSpace asElement="ul" space={16} className="list">
        {offers.map((orderChangeOffer) => (
          <li key={orderChangeOffer.id}>
            <OrderChangeOfferCard
              orderChangeOffer={orderChangeOffer}
              order={order}
              onSelect={onOrderChangeOfferSelected}
              disabled={Boolean(submittingId)}
              isWaiting={submittingId === orderChangeOffer.id}
            />
          </li>
        ))}
      </VSpace>
      <style jsx>{`
        :global(.order-change-results-list .list) {
          padding: 0px;
          list-style: none;
        }
      `}</style>
    </div>
  )
}
