import * as React from 'react'
import { isDev } from '@lib/env'
import { useWorkspace } from '@lib/workspace-context'
import { hasAuthorisation } from './has-authorisation'
import { UserRole } from './types'

interface AuthorisationGuard {
  requiredRole?: UserRole
  requiresDuffelUser?: boolean
}

export const AuthorisationGuard: React.FC<
  React.PropsWithChildren<AuthorisationGuard>
> = ({ requiredRole, requiresDuffelUser, children }) => {
  const { permissions, user } = useWorkspace()

  if (requiredRole) {
    if (!permissions || !hasAuthorisation(permissions, requiredRole))
      return null
    return <React.Fragment>{children}</React.Fragment>
  } else if (requiresDuffelUser) {
    if (!user || (!isDev && !user.duffelAdmin)) return null
    return <React.Fragment>{children}</React.Fragment>
  } else {
    console.warn(
      'AuthorisationGuard is being used without any props: requiredRole or requiresDuffelUser'
    )
    return null
  }
}
