import { UNKNOWN_ERROR_MESSAGE } from '@lib/constants'
import { CSRF_HEADER_NAME } from '@lib/csrf'

export const notAuthorisedErrorResponseFixture = {
  errors: [
    {
      code: 'missing_jwt_cookie',
      documentation_url: 'https://docs.duffel.com/api/overview/errors',
      message:
        "The JWT token present in this request's cookie is either missing or is not valid",
      title: 'Invalid or missing JWT cookie',
      type: 'authentication_error',
    },
  ],
  meta: {
    request_id: null,
    status: 401,
  },
}
export class NotAuthorisedError extends Error {
  name = 'NotAuthorisedError'
}

export const csrfTokenErrorResponseFixture = {
  errors: [
    {
      code: 'missing_csrf_token',
      message: `Your session has expired. Please refresh the page.`,
      title: `Missing or incorrect '${CSRF_HEADER_NAME}' header`,
      type: 'forbidden_error',
    },
  ],
  meta: {
    request_id: null,
    status: 403,
  },
}
export class CsrfError extends Error {
  name = 'CsrfError'
}

export const getModeMismatchErrorResponseFixture = (message: string) => {
  return {
    errors: [
      {
        code: 'mode_mismatch',
        message,
        title: 'Mismatched modes',
        type: 'authentication_error',
      },
    ],
    meta: {
      request_id: null,
      status: 401,
    },
  }
}

export class ModeMismatchError extends Error {
  name = 'ModeMismatchError'
}

export const notFoundErrorResponseFixture = {
  errors: [
    {
      code: 'not_found',
      documentation_url: 'https://docs.duffel.com/api/overview/errors',
      message: 'The resource you are trying to access does not exist',
      title: 'Not Found',
      type: 'invalid_request_error',
    },
  ],
  meta: {
    request_id: null,
    status: 404,
  },
}

export class NotFoundError extends Error {
  name = 'NotFoundError'
}

export const getUnprocessableEntityError = (invalidParam: string) => ({
  errors: [
    {
      code: 'unprocessable_entity',
      documentation_url: 'https://docs.duffel.com/api/overview/errors',
      message: `A validation error occured. The ${invalidParam} is invalid.`,
      title: 'UnprocessableEntity',
      type: 'validation_error',
    },
  ],
  meta: {
    request_id: null,
    status: 422,
  },
})
export class UnprocessableEntityError extends Error {
  name = 'UnprocessableEntity'
  invalidParam: string

  constructor(invalidParam: string) {
    super()
    this.invalidParam = invalidParam
  }
}

export const unexpectedErrorResponseFixture = {
  errors: [
    {
      code: 'unexpected_error',
      message: UNKNOWN_ERROR_MESSAGE,
      title: 'Unexpected error',
      type: 'unexpected_error',
    },
  ],
  meta: {
    request_id: null,
    status: 500,
  },
}
