import { DuffelAPI } from '@lib/types'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.WalletAccount => ({
    topUpReference: `DUFFT${seedId++}FD${seedId++}V`,
    minimumBalance: '0',
    lowBalanceThreshold: String(Math.random() * (2000 - 0) + 0),
    liveMode: false,
    id: `wal_${seedId++}`,
    currency: 'GBP',
    balance: String(Math.random() * (100 - 1) + 1),
  })
})()

export const makeMockOrganisationWallet = (
  extendDefault?: Partial<DuffelAPI.Types.WalletAccount>
): DuffelAPI.Types.WalletAccount =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
