import { isValidDateString } from '@lib/date/is-valid-date'
import { DuffelAPI } from '@lib/types'

export const validateOrderAttributes = (
  order: DuffelAPI.Types.Order
): boolean =>
  order.slices &&
  Array.isArray(order.slices) &&
  order.slices.every(
    (slice) =>
      !!slice.id &&
      !!slice.origin &&
      !!slice.origin.iataCode &&
      !!slice.destination &&
      !!slice.destination.iataCode &&
      slice.segments[0].departingAt &&
      isValidDateString(slice.segments[0].departingAt)
  )
