import { omit } from 'lodash'
import { SearchType } from '@lib/types'
import {
  MultiCitySearchFormValues,
  Slice,
} from '../forms/SearchForm/MultiCitySearchForm'
import { OneWaySearchFormValues } from '../forms/SearchForm/OneWaySearchForm'
import { ReturnSearchFormValues } from '../forms/SearchForm/ReturnSearchForm'
import { defaultSearchTimeRanges } from '../forms/SearchForm/lib/search-time-ranges'

const emptySlice: Slice = {
  origin: '',
  destination: '',
  departureDate: null,
  timeRanges: defaultSearchTimeRanges,
}

interface OriginDestinationPair {
  origin: string
  destination: string
}

export const getOriginDestinationsFromSearchFormValues = (
  searchFormValues: SearchFormValues<SearchType>
): OriginDestinationPair[] => {
  switch (searchFormValues.type) {
    case 'one_way':
      return [
        {
          origin: searchFormValues.origin,
          destination: searchFormValues.destination,
        },
      ]
    case 'return':
      return [
        {
          origin: searchFormValues.origin,
          destination: searchFormValues.destination,
        },
        {
          origin: searchFormValues.destination,
          destination: searchFormValues.origin,
        },
      ]
    case 'multi_city':
      return searchFormValues.slices
  }
}

export type SearchFormValues<T_SearchType extends SearchType = SearchType> =
  T_SearchType extends 'one_way'
    ? OneWaySearchFormValues
    : T_SearchType extends 'return'
    ? ReturnSearchFormValues
    : T_SearchType extends 'multi_city'
    ? MultiCitySearchFormValues
    : never

const oneWayToReturn = (
  formValues: SearchFormValues<'one_way'>
): SearchFormValues<'return'> => {
  return {
    ...formValues,
    type: 'return',
    departureTimeRanges: formValues.timeRanges,
    returnDate: null,
    returnTimeRanges: defaultSearchTimeRanges,
  }
}

const oneWayToMultiCity = (
  formValues: SearchFormValues<'one_way'>
): SearchFormValues<'multi_city'> => {
  return {
    passengers: formValues.passengers,
    cabinClass: formValues.cabinClass,
    airlines: formValues.airlines,
    slices: [
      {
        origin: formValues.origin,
        destination: formValues.destination,
        departureDate: formValues.departureDate,
        timeRanges: formValues.timeRanges,
      },
      emptySlice,
    ],
    type: 'multi_city',
    supplierTimeout: formValues.supplierTimeout,
  }
}

const returnToOneWay = (
  formValues: SearchFormValues<'return'>
): SearchFormValues<'one_way'> => {
  return {
    ...omit(formValues, 'returnDate', 'returnTimeRanges'),
    type: 'one_way',
    timeRanges: formValues.departureTimeRanges,
  }
}

const returnToMultiCity = (
  formValues: SearchFormValues<'return'>
): SearchFormValues<'multi_city'> => {
  return {
    type: 'multi_city',
    supplierTimeout: formValues.supplierTimeout,
    passengers: formValues.passengers,
    cabinClass: formValues.cabinClass,
    airlines: formValues.airlines,
    slices: [
      {
        origin: formValues.origin,
        destination: formValues.destination,
        departureDate: formValues.departureDate,
        timeRanges: formValues.departureTimeRanges,
      },
      {
        origin: formValues.destination,
        destination: formValues.origin,
        departureDate: formValues.returnDate,
        timeRanges: formValues.returnTimeRanges,
      },
    ],
  }
}

const multiCityToOneWay = (
  formValues: SearchFormValues<'multi_city'>
): SearchFormValues<'one_way'> => {
  const firstSlice = formValues.slices[0] || emptySlice
  return {
    type: 'one_way',
    supplierTimeout: formValues.supplierTimeout,
    passengers: formValues.passengers,
    cabinClass: formValues.cabinClass,
    airlines: formValues.airlines,
    ...firstSlice,
  }
}

const multiCityToReturn = (
  formValues: SearchFormValues<'multi_city'>
): SearchFormValues<'return'> => {
  const firstSlice = formValues.slices[0] || emptySlice
  const secondSlice = formValues.slices[1] || emptySlice
  return {
    type: 'return',
    supplierTimeout: formValues.supplierTimeout,
    passengers: formValues.passengers,
    cabinClass: formValues.cabinClass,
    airlines: formValues.airlines,
    ...firstSlice,
    departureTimeRanges: firstSlice.timeRanges,
    returnDate: secondSlice.departureDate,
    returnTimeRanges: secondSlice.timeRanges,
  }
}

export const transformSearchFormValues = <
  T_FromSearchType extends SearchType,
  T_ToSearchType extends SearchType
>(
  fromSearchType: T_FromSearchType,
  toSearchType: T_ToSearchType,
  formValues: SearchFormValues<T_FromSearchType>
): SearchFormValues<T_ToSearchType> => {
  const conversionType = `${fromSearchType}:${toSearchType}`

  switch (conversionType) {
    case 'one_way:return':
      return oneWayToReturn(
        formValues as SearchFormValues<'one_way'>
      ) as SearchFormValues<T_ToSearchType>
    case 'one_way:multi_city':
      return oneWayToMultiCity(
        formValues as SearchFormValues<'one_way'>
      ) as SearchFormValues<T_ToSearchType>
    case 'return:one_way':
      return returnToOneWay(
        formValues as SearchFormValues<'return'>
      ) as SearchFormValues<T_ToSearchType>
    case 'return:multi_city':
      return returnToMultiCity(
        formValues as SearchFormValues<'return'>
      ) as SearchFormValues<T_ToSearchType>
    case 'multi_city:one_way':
      return multiCityToOneWay(
        formValues as SearchFormValues<'multi_city'>
      ) as SearchFormValues<T_ToSearchType>
    case 'multi_city:return':
    default:
      return multiCityToReturn(
        formValues as SearchFormValues<'multi_city'>
      ) as SearchFormValues<T_ToSearchType>
  }
}
