import { DuffelAPI } from '@lib/types'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.OrderSegmentPassenger => {
    const id = (seedId++).toString().padStart(5, '0')
    return {
      passengerId: `pas_${id}`,
      cabinClassMarketingName: 'Economy',
      cabinClass: 'economy',
      baggages: [
        {
          type: 'checked',
          quantity: 1,
        },
        {
          type: 'carry_on',
          quantity: 1,
        },
      ],
    }
  }
})()

export const makeMockOrderSliceSegmentPassenger = (
  extendDefault?: Partial<DuffelAPI.Types.OrderSegmentPassenger>,
  passengerId?: DuffelAPI.Types.OrderSegmentPassenger['passengerId']
): DuffelAPI.Types.OrderSegmentPassenger => {
  const mockOrderSlice = Object.assign(
    {},
    getDefaultObject(),
    extendDefault || {}
  )

  if (passengerId) {
    mockOrderSlice.passengerId = passengerId
  }

  return mockOrderSlice
}
