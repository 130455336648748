import * as React from 'react'
import { FilterableWebhookEventTypes } from '@lib/types'
import {
  maxConnectionsOptions,
  offerSortingOptions,
  orderChangeSortingOptions,
  PendingFilterValue,
} from '../lib/types'

import { PassengerNameFilterInput } from './PassengerNameFilterInput'
import { DateConditionFilterInput } from './DateConditionFilterInput'
import { IataCodeFilterInput } from './IataCodeFilterInput'
import { AirlinesFilterInput } from './AirlinesFilterInput'
import { OptionsFilterInput } from './OptionsFilterInput'
import { TimeConditionFilterInput } from './TimeConditionFilterInput'
import { getFilterNames } from '../lib/helpers'

interface FilterDetailsProps {
  value: PendingFilterValue
  onChange: (val: PendingFilterValue) => void
}

const dateFilterInputLabel = {
  departingAt: 'Departing',
  arrivingAt: 'Arriving',
  createdAt: 'Created',
} as const

export const FilterDetails: React.FC<FilterDetailsProps> = ({
  value,
  onChange,
}) => {
  switch (value.type) {
    case 'passengerName':
      return (
        <PassengerNameFilterInput
          value={value.value}
          onChange={(val) => {
            onChange({ type: 'passengerName', value: val })
          }}
        />
      )
    case 'departingAt':
    case 'arrivingAt':
    case 'createdAt':
      return (
        <DateConditionFilterInput
          label={dateFilterInputLabel[value.type]}
          value={value.value}
          onChange={(condition) => {
            onChange({ type: value.type, value: condition })
          }}
        />
      )
    case 'departingAtTime':
    case 'arrivingAtTime':
      return (
        <TimeConditionFilterInput
          label={getFilterNames(value.type).split(' ')[0]}
          value={value.value}
          onChange={(condition) => {
            onChange({ type: value.type, value: condition })
          }}
        />
      )
    case 'originId':
    case 'destinationId':
      return (
        <IataCodeFilterInput
          value={value.value}
          onChange={(airportId) =>
            onChange({ type: value.type, value: airportId })
          }
        />
      )
    case 'airlineId':
      return (
        <AirlinesFilterInput
          value={value.value}
          onChange={(airlineId) =>
            onChange({ type: 'airlineId', value: airlineId })
          }
        />
      )
    case 'maxConnections':
      return (
        <OptionsFilterInput
          value={value.value}
          onChange={(maxConnections) => {
            onChange({ type: 'maxConnections', value: maxConnections })
          }}
          options={maxConnectionsOptions}
        />
      )
    case 'offerSorting':
      return (
        <OptionsFilterInput
          value={value.value}
          onChange={(offerSorting) => {
            onChange({ type: 'offerSorting', value: offerSorting })
          }}
          options={offerSortingOptions}
        />
      )
    case 'orderChangeSorting':
      return (
        <OptionsFilterInput
          value={value.value}
          onChange={(orderChangeSorting) => {
            onChange({ type: 'orderChangeSorting', value: orderChangeSorting })
          }}
          options={orderChangeSortingOptions}
        />
      )
    case 'webhookEventType':
      return (
        <OptionsFilterInput
          value={value.value}
          onChange={(filterableWebhookEventType) => {
            onChange({
              type: 'webhookEventType',
              value: filterableWebhookEventType,
            })
          }}
          options={FilterableWebhookEventTypes}
        />
      )
  }

  switch (value.type.type) {
    case 'options':
      return (
        <OptionsFilterInput
          value={value.value}
          onChange={(newValue) =>
            onChange({ type: value.type, value: newValue })
          }
          options={value.type.options}
        />
      )
  }
}
