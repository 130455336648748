import { DuffelSources, sourceCapabilitiesMap } from '@lib/sources'
import { SourceAirlineWithSourceId } from '../forms'

/** Alphabetically sorts an array of airlines by airlines.name */
const sortAirlinesByName = (
  airlines: SourceAirlineWithSourceId[]
): SourceAirlineWithSourceId[] =>
  airlines.sort((airlineOne, airlineTwo) => {
    if (airlineOne.name < airlineTwo.name) {
      return -1
    }
    if (airlineOne.name > airlineTwo.name) {
      return 1
    }

    return 0
  })

/**
 * This function will return all the featured airlines from a list of sources
 */
const getAirlinesFromSources = (
  sourcesWithLoyaltyProgrammes: string[]
): SourceAirlineWithSourceId[] => {
  return sourcesWithLoyaltyProgrammes.reduce((airlines, sourceId) => {
    const featuredAirlines = DuffelSources[sourceId]?.featuredAirlines
    if (Array.isArray(featuredAirlines)) {
      const featuredAirlinesWithSourceId = featuredAirlines.map((airline) => ({
        sourceId,
        ...airline,
      }))

      return [...airlines, ...featuredAirlinesWithSourceId]
    } else {
      return airlines
    }
  }, new Array<SourceAirlineWithSourceId>())
}

export const getLoyaltyProgrammesList = (
  isLive: boolean
): SourceAirlineWithSourceId[] => {
  let sourcesWithLoyaltyProgrammes = Object.entries(sourceCapabilitiesMap)
    .filter(
      ([_source, capabilities]) =>
        capabilities['Loyalty programmes'] === 'Full support'
    )
    .map(([source]) => source)

  if (isLive) {
    sourcesWithLoyaltyProgrammes = sourcesWithLoyaltyProgrammes.filter(
      (source) => source !== 'duffel_airways'
    )
  }

  const airlinesWithLoyaltyProgrammes = getAirlinesFromSources(
    sourcesWithLoyaltyProgrammes
  )

  return sortAirlinesByName(airlinesWithLoyaltyProgrammes)
}
