import * as React from 'react'
import { useField, useFormikContext } from 'formik'
import dynamic from 'next/dynamic'
import { Drawer } from '@components/Drawer'
import { SourceId } from '@lib/sources/DuffelSources'
import { CommonSearchFormSectionValues } from './CommonSearchFormSection'
import { PassengerLoyaltyAccountsContainerProps } from './PassengerLoyaltyAccountsForm/PassengerLoyaltyAccountsContainer'
import { useAvailableSourceAirlines } from './FormFields/AirlinesSelect/lib'
import { FormikAirlinesSelect } from './FormFields/FormikAirlinesSelect'
import { SourceAirlineWithSourceId } from './FormFields/AirlinesSelect'
import styles from './AdvancedOptionsSection.module.css'
import { FormikInput } from '@components/Form'
import { VSpace } from '@components/VSpace'

export const DEFAULT_DEPARTURE_TIME_FROM = '00:00'
export const DEFAULT_DEPARTURE_TIME_TO = '23:59'

export const departureTimeOptions = [...Array(25).keys()].map((_, idx) => {
  if (idx === 24) {
    return DEFAULT_DEPARTURE_TIME_TO
  }

  return `${idx < 10 ? '0' : ''}${idx}:00`
})

const PassengerLoyaltyAccountsContainer =
  dynamic<PassengerLoyaltyAccountsContainerProps>(() =>
    import(
      './PassengerLoyaltyAccountsForm/PassengerLoyaltyAccountsContainer'
    ).then((mod) => mod.PassengerLoyaltyAccountsContainer)
  )

export interface AdvancedOptionsSectionProps {
  hideAirlineSelect?: boolean
  initialStateOpen?: boolean
}

export const AdvancedOptionsSection: React.FC<AdvancedOptionsSectionProps> = ({
  hideAirlineSelect,
  initialStateOpen: isOpenInitialState,
}) => {
  const {
    values: { passengers },
  } = useFormikContext<CommonSearchFormSectionValues>()

  const sources = useAvailableSourceAirlines()

  const formFieldName = 'airlines'

  const [{ value: sourceFilters }, , { setValue: setSourceFilters }] = useField<
    SourceId[]
  >({
    name: formFieldName,
  })

  const [{ value: departureTimeFrom }] = useField('departureTime.from')
  const [{ value: departureTimeTo }, , { setValue: setDepartureTimeTo }] =
    useField('departureTime.to')

  // ensure that departure time's to is greater than the departure time's from
  React.useEffect(() => {
    if (departureTimeTo < departureTimeFrom) {
      setDepartureTimeTo(
        departureTimeOptions[
          departureTimeOptions.findIndex(
            (option) => option === departureTimeFrom
          ) + 1
        ]
      )
    }
  }, [departureTimeFrom, departureTimeTo])

  const areTherePassengers = passengers && passengers.length !== 0

  const formikAirlinesSelectOnChangeHandler = (
    selectedAirline: SourceAirlineWithSourceId | null
  ) => {
    /** reset to default state if null (ex. via clicking "all airlines") is passed in */
    if (!selectedAirline) {
      setSourceFilters([])
    } else if (sourceFilters.includes(selectedAirline.sourceId)) {
      /**  toggle if the selectedAirline has already been selected */
      const removeIndex = sourceFilters.findIndex(
        (sourceAirline) => sourceAirline === selectedAirline.sourceId
      )
      setSourceFilters([
        ...sourceFilters.slice(0, removeIndex),
        ...sourceFilters.slice(removeIndex + 1),
      ])
    } else {
      /**
       * reset to default state (ex - "all airlines")
       * if the updated selected airlines matches the total number of airlines
       */
      sourceFilters.length + 1 === sources.length
        ? setSourceFilters([])
        : setSourceFilters([...sourceFilters, selectedAirline.sourceId])
    }
  }

  return (
    <div className={styles['wrapper']}>
      <Drawer
        className={styles['drawer']}
        isOpenInitialState={isOpenInitialState}
      >
        <VSpace space={16}>
          {!hideAirlineSelect && (
            <FormikAirlinesSelect
              airlinesList={sources}
              onChange={formikAirlinesSelectOnChangeHandler}
              label="Source"
            />
          )}

          <FormikInput
            name="supplierTimeout"
            label="Supplier timeout"
            optionalField
            hint={
              <>
                The maximum amount of time (in seconds) that Duffel will wait
                for a response from the supplier.
                <br />
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://duffel.com/docs/api/v1/offer-requests/create-offer-request#offer-requests-create-an-offer-request-query-parameters-supplier-timeout"
                >
                  Learn more on our documentation
                </a>
                .
              </>
            }
            type="number"
            min="2000"
            max="60000"
          />

          {areTherePassengers && (
            <PassengerLoyaltyAccountsContainer passengers={passengers} />
          )}
        </VSpace>
      </Drawer>
    </div>
  )
}
