import { DuffelContext } from '@lib/types'

function getCSRFTokenFromDocument(): string {
  return (
    document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content') || ''
  )
}

function getCSRFTokenFromContext(ctx: DuffelContext): string {
  return ctx.csrfToken || ''
}

export function getCSRFToken(ctx?: DuffelContext): string {
  return ctx ? getCSRFTokenFromContext(ctx) : getCSRFTokenFromDocument()
}
