export const hasSameDate = (
  date1: Date | null,
  date2: Date | null
): boolean => {
  return (
    date1 !== null &&
    date2 !== null &&
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  )
}
