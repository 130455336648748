import { ZendeskSupportTicket } from '@lib/zendesk'
import { OrderActivityEvent } from '../lib/types'

export const getSupportTicketEvents = (
  supportTickets: ZendeskSupportTicket[]
): OrderActivityEvent[] =>
  supportTickets.map((supportTicket) => ({
    type: 'support_ticket_created',
    date: new Date(supportTicket.createdAt),
    initiatedBy: 'organisation',
    submitterId: supportTicket.submitterId,
    supportTicket: {
      category: supportTicket.category || 'dashboard-other',
      subject: supportTicket.subject,
      createdAt: supportTicket.createdAt,
      status: supportTicket.status,
      email: '',
      message: supportTicket.description,
      updatedAt: supportTicket.updatedAt,
    },
  }))
