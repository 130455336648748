import { DateFilterCondition } from '@components/Filters/lib/types'

export interface NaiveDateTimeFilter {
  before?: string
  after?: string
}

export function getNaiveDateTimeQueryParamFilter(
  queryParam: string,
  filter: DateFilterCondition
): NaiveDateTimeFilter {
  const beforeParam = `${queryParam}[before]`
  const afterParam = `${queryParam}[after]`

  if (filter.condition === 'before') {
    return {
      [beforeParam]: filter.date.toISOString().split('.')[0],
    }
  }

  if (filter.condition === 'after') {
    return {
      [afterParam]: filter.date.toISOString().split('.')[0],
    }
  }

  // filter.condition === 'between'
  return {
    [afterParam]: filter.date1.toISOString().split('.')[0],
    [beforeParam]: filter.date2.toISOString().split('.')[0],
  }
}
