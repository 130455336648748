import * as React from 'react'
import { Modal } from '@components/Modal'
import { SearchFormContainer } from '../SearchFormContainer'
import { HSpace } from '@components/HSpace'
import { Button } from '@components/Button'
import styles from './SearchResultsContainer.module.css'
import { SearchFormValues } from '@modules/air-search-v2/lib'
import { OrganisationSourcesContextProvider } from '@lib/sources/organisation-sources-context'
import { DuffelAPI } from '@lib/types'
import { captureException } from '@sentry/core'
import { useWorkspace } from '@lib/workspace-context'

interface EditSearchModalProps {
  formValues: SearchFormValues
  setIsEditSearchModalOpen: (value: boolean) => void
}

export const EditSearchModal = ({
  setIsEditSearchModalOpen,
  formValues,
}: EditSearchModalProps) => {
  const { duffelClient } = useWorkspace()

  const [organisationSources, setOrganisationSources] =
    React.useState<DuffelAPI.Types.OrganisationSources>([])
  // fetch sources for edit search modal
  React.useEffect(() => {
    const getSources = async () => {
      const { data, errors } = await duffelClient.Air.getOrganisationSources()
      // we are fine with failing silently here since this is not critical to the page
      if (errors || !data) {
        captureException(
          new Error('failed to get airlines for search results page')
        )
        return
      }
      setOrganisationSources(data)
    }

    getSources()
  }, [])

  return (
    <OrganisationSourcesContextProvider
      organisationSources={organisationSources}
    >
      <Modal
        className={styles['edit-search-modal']}
        onClose={() => setIsEditSearchModalOpen(false)}
      >
        <SearchFormContainer
          initialFormValues={formValues}
          onSubmit={() => {
            setIsEditSearchModalOpen(false)
          }}
        />
        <HSpace space={0} fill className="u-marginTop8">
          <Button
            text="Cancel"
            onClick={() => {
              // TODO: track event
              setIsEditSearchModalOpen(false)
            }}
            intent="MUTED"
            fill
            large
            transparent
          />
        </HSpace>
      </Modal>
    </OrganisationSourcesContextProvider>
  )
}
