import { DuffelAPI } from '@lib/types'

export const getSearchToBookRatio = (invoice: DuffelAPI.Types.Invoice) => {
  const searchUsageRecord = invoice.items.find(
    (item) =>
      item.description === 'Search which exceeds the search-to-book ratio'
  )
  const searchToBookRatio =
    searchUsageRecord?.calculation.params.metricParams?.maxSearchToBookRatio

  return searchToBookRatio
}
