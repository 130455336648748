import classNames from 'classnames'
import * as React from 'react'

import styles from './Table.module.css'
import { SortMetadata, TableHeader } from './TableHeader'
import { TableRow } from './TableRow'

export type TableColumn = {
  alignRight?: boolean
  /**
   * Name of column
   * - - -
   * Can be omitted to create a column without a header
   */
  name?: string

  /**
   * Width of column
   * - - -
   * Ex. `128px`
   */
  width?: string

  /**
   * is Table header sortable? If so, pass sortMetadata
   */
  sortMetadata?: SortMetadata
}

export type TableProps = {
  /**
   * What should the row height be in pixels?
   * (This will affect the table header row and any row that aren't the monthly summary or total footer)
   * - - -
   * Default: `56`
   */
  rowHeight?: 40 | 48 | 56

  /**
   * Table columns metadata
   * - - -
   * An array of strings just sets the column names
   *
   * An array of TableColumn objects can set column width and sort metadata along with name
   */
  columns?: string[] | TableColumn[]

  /**
   * Do the table rows contain interactive elements?
   * If the rows are not interactive, set to `TRUE` and a hover effect will be added to the rows.
   * If not, set to `FALSE`
   * Note: You can override this on the individual `TableRow` components if necessary
   * - - -
   * Default: `FALSE`
   */
  disableRowInteraction?: boolean

  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string
}

export type TableContextValue = {
  rowHeight?: TableProps['rowHeight']
  columns?: TableProps['columns']
  disableRowInteraction?: TableProps['disableRowInteraction']
}

export const TableContext = React.createContext<TableContextValue>({
  rowHeight: 56,
  columns: [],
  disableRowInteraction: false,
})

export const useTableContext = () => React.useContext(TableContext)

export const Table: React.FC<React.PropsWithChildren<TableProps>> = ({
  children,
  rowHeight = 56,
  columns,
  disableRowInteraction = false,
  className,
}) => {
  return (
    <TableContext.Provider
      value={{
        rowHeight,
        columns,
        disableRowInteraction,
      }}
    >
      <table className={classNames(styles['table'], className)}>
        {columns && columns?.length > 0 && (
          <thead data-testid="table-head-element">
            <TableRow>
              {columns.map((column: string | TableColumn) => (
                <TableHeader
                  key={typeof column === 'string' ? column : column.name}
                  /** conditionally add props only if they exist */
                  {...(typeof column === 'object' &&
                    column.width && { width: column.width })}
                  {...(typeof column === 'object' &&
                    column.alignRight && { alignRight: column.alignRight })}
                  {...(typeof column === 'object' &&
                    column.sortMetadata && {
                      sortMetadata: column.sortMetadata,
                    })}
                >
                  {typeof column === 'string' ? column : column.name}
                </TableHeader>
              ))}
            </TableRow>
          </thead>
        )}
        {children}
      </table>
    </TableContext.Provider>
  )
}
