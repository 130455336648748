import * as React from 'react'
import Fuse from 'fuse.js'
import { Element, scroller } from 'react-scroll'
import { Input } from '@components/Input'
import { VSpace } from '@components/VSpace'
import { Text } from '@components/Text'
import { AirlinesContext } from '@modules/air-order/lib/airlines-context'

import styles from './AirlinesFilterInput.module.css'
import { AirlinesSelectOption } from '@modules/air-search-v2/forms/SearchForm/FormFields/AirlinesSelect/AirlinesSelectOption'

const scrollOptions = {
  duration: 200,
  containerId: 'options-container',
  offset: -50,
  smooth: true,
}

interface AirlinesFilterInputProps {
  value: string
  onChange: (val: string) => void
}

export const AirlinesFilterInput: React.FC<AirlinesFilterInputProps> = ({
  value,
  onChange,
}) => {
  const [searchTerm, setSearchTerm] = React.useState('')
  const airlines = React.useContext(AirlinesContext)
  const [activeOptionIndex, setActiveOptionIndex] = React.useState(0)

  const filteredAirlines = searchTerm
    ? new Fuse(airlines, {
        keys: ['name', 'iataCode', 'region', 'alliance'],
        threshold: 0.2,
      })
        .search(searchTerm)
        .map((res) => res.item)
    : airlines

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowDown': {
          event.preventDefault()
          const newActiveOptionIndex =
            activeOptionIndex + 1 > filteredAirlines.length - 1
              ? 0
              : activeOptionIndex + 1
          setActiveOptionIndex(newActiveOptionIndex)
          scroller.scrollTo(`option-${newActiveOptionIndex}`, scrollOptions)
          break
        }
        case 'ArrowUp': {
          event.preventDefault()
          const newActiveOptionIndex =
            activeOptionIndex - 1 < 0
              ? filteredAirlines.length - 1
              : activeOptionIndex - 1
          setActiveOptionIndex(newActiveOptionIndex)
          scroller.scrollTo(`option-${newActiveOptionIndex}`, scrollOptions)
          break
        }
        case 'Enter': {
          // filtered airlines might not have any result, so we need to ignore it
          if (filteredAirlines.length === 0) {
            return
          }
          // If the enter key is pressed on the selected option, let it through so that the enter key can trigger "Apply" button
          // on the parent component.
          if (value === filteredAirlines[activeOptionIndex].id) {
            return
          }
          event.preventDefault()
          onChange(filteredAirlines[activeOptionIndex].id)
          break
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [filteredAirlines, activeOptionIndex, onChange])

  return (
    <VSpace space={12} className={styles['container']}>
      <Input
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value)}
        placeholder="Search airlines by name"
        containerSize="small"
      />

      {filteredAirlines.length > 0 && (
        <Element
          id="options-container"
          name="options-container"
          className={styles['options-container']}
        >
          {filteredAirlines.map((airline, idx) => (
            <Element name={`option-${idx}`} key={airline.id}>
              <AirlinesSelectOption
                name={airline.name}
                airline={{
                  name: airline.name,
                  // we don't really care if there's no iataCode here
                  iataCode: airline.iataCode ?? '',
                }}
                selected={airline.id === value}
                active={idx === activeOptionIndex}
                onClick={() => onChange(airline.id)}
                onMouseEnter={() => setActiveOptionIndex(idx)}
              />
            </Element>
          ))}
        </Element>
      )}
      {filteredAirlines.length === 0 && (
        <div className="u-paddingBottom8 u-marginLeft8">
          <Text fontSize="C2" color="grey-700">
            No airline matches '{searchTerm}'
          </Text>
        </div>
      )}
    </VSpace>
  )
}
