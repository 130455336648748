import { MenuItem } from '@components/Menu'
import { ordersChangePathObject } from '@lib/paths'
import { DuffelAPI } from '@lib/types'
import { useFeatureFlags } from '@lib/unleash'
import { useWorkspace } from '@lib/workspace-context'
import { useOrderCancellation } from '@modules/air-order-change/lib'
import { SupportOrderManagementAction } from '@modules/air-order/components/OrderSupport/types'
import { getOrderStatus } from '@modules/air-order/helpers'
import { useUnavailableActionDialog } from '@modules/air-order/lib/use-unavailable-action-dialog'
import * as React from 'react'
import {
  ORDER_MANAGEMENT_ACTION_MENU_ITEM_LABEL_MAP,
  SELF_MANAGED_TOOLTIP_TEXT,
  TEST_MODE_TOOLTIP_TEXT,
} from './constants'
import { isActionAvailableProgrammatically } from './lib/is-action-available-programmatically'

export interface OrderManagementMenuItemProps {
  order: DuffelAPI.Types.Order
  orderManagementAction: SupportOrderManagementAction
  availableServices?: DuffelAPI.Types.OrderAvailableService[] | null
  setBaggageModalOpen: (value: React.SetStateAction<boolean>) => void
  setBaggageConfirmationModalOpen: (
    value: React.SetStateAction<boolean>
  ) => void
}

export const OrderManagementMenuItem: React.FC<
  OrderManagementMenuItemProps
> = ({
  order,
  orderManagementAction,
  availableServices,
  setBaggageModalOpen,
}) => {
  const showOrderSupportForTestOrders = useFeatureFlags(
    'dashboard_show_order_support_options_in_test_mode'
  )
  const { permissions } = useWorkspace()
  const { openCancellationDialog } = useOrderCancellation(order)
  const { openSupportDialog } = useUnavailableActionDialog()

  const isAPIResolutionAvailable = isActionAvailableProgrammatically(
    orderManagementAction,
    order,
    availableServices
  )

  const label =
    ORDER_MANAGEMENT_ACTION_MENU_ITEM_LABEL_MAP[orderManagementAction]

  if (!isAPIResolutionAvailable) {
    if (!order.liveMode && !showOrderSupportForTestOrders) {
      return (
        <MenuItem
          label={label}
          disabled
          disabledTooltipText={TEST_MODE_TOOLTIP_TEXT}
        />
      )
    }

    if (order.content === 'self_managed') {
      return (
        <MenuItem
          label={label}
          disabled
          disabledTooltipText={SELF_MANAGED_TOOLTIP_TEXT}
        />
      )
    }

    return (
      <MenuItem
        label={label}
        onClick={() => openSupportDialog(order, orderManagementAction)}
      />
    )
  }

  switch (orderManagementAction) {
    case 'cancel_order': {
      const orderStatus = getOrderStatus(order)
      if (orderStatus === 'on-hold' || orderStatus === 'confirmed') {
        return (
          <MenuItem
            label={label}
            onClick={() => {
              openCancellationDialog()
            }}
          />
        )
      }
      return null
    }
    case 'change_order': {
      return (
        <MenuItem
          label={label}
          {...ordersChangePathObject(
            permissions?.organisation,
            permissions?.liveMode,
            order.id
          )}
        />
      )
    }
    case 'add_bags': {
      return (
        <MenuItem
          label={label}
          onClick={() => {
            setBaggageModalOpen(true)
          }}
        />
      )
    }
  }
}
