import classNames from 'classnames'
import * as React from 'react'
import { ButtonProps, ChromelessButton } from '@components/Button'
import { Icon } from '@components/Icon'

export const CloseButton: React.FC<Partial<ButtonProps>> = ({
  onClick,
  className,
}) => (
  <ChromelessButton
    className={classNames('close-button', className)}
    onClick={onClick}
  >
    <Icon name="close" className="support-button__icon" />
    <style jsx>{`
      :global(.close-button) {
        height: 40px;
        width: 40px;
        background-color: white !important;
        color: var(--grey-500) !important;
        border-radius: 50% !important;
        border: 1px solid var(--grey-300) !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      :global(.close-button):hover {
        cursor: pointer;
        color: var(--grey-700) !important;
        border-color: var(--grey-300) !important;
      }

      :global(.close-button):active {
        background-color: var(--grey-100) !important;
        color: var(--grey-700) !important;
      }

      :global(.close-button):focus-visible {
        border: 2px solid var(--purple-500) !important;
      }

      .close-button__icon {
        margin: auto;
      }
    `}</style>
  </ChromelessButton>
)
