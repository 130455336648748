import { AnalyticsBrowser } from '@segment/analytics-next'
import { isBrowser } from '@lib/env'
import { captureException } from '@lib/sentry'

/* eslint-disable no-console */
const devAnalytics = {
  load: (...args: any[]) =>
    console.info(`[analytics] load was called`, ...args),
  identify: (...args: any[]) =>
    console.info(`[analytics] identified user`, ...args),
  track: (...args: any[]) =>
    console.info(`[analytics] tracked user event`, ...args),
  page: (...args: any[]) =>
    console.info(`[analytics] tracked page event`, ...args),
  alias: (...args: any[]) => console.info(`[analytics] aliased user`, ...args),
} as AnalyticsBrowser

export const getAnalyticsObject = (): AnalyticsBrowser | undefined => {
  if (!isBrowser) return undefined

  // Using `window as any` in this function to avoid conflicting types for window.analytics
  if ('analytics' in window) return (window as any).analytics

  if (!process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY) {
    if (process.env.NODE_ENV === 'production') {
      captureException(
        new Error(
          'NEXT_PUBLIC_SEGMENT_WRITE_KEY is missing from process.env object'
        )
      )
    }
    return devAnalytics
  }

  // we only want to load the analytics object if the user has accepted tracking cookies
  // this happens in the places that where AnalyticsConsentManager is used
  if (isTrackingPreferencesCookieSet()) {
    return ((window as any).analytics = AnalyticsBrowser.load({
      writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    }))
  } else {
    return ((window as any).analytics = new AnalyticsBrowser())
  }
}

const cookieName = 'tracking-preferences'
export const isTrackingPreferencesCookieSet = (): boolean => {
  if (!isBrowser || !document.cookie) return false

  return document.cookie
    .split(';')
    .some((item) => item.trim().startsWith(`${cookieName}=`))
}
