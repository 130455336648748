import { DuffelAPI, SearchType } from '@lib/types'

export const getSearchType = (
  fromSlices: Array<
    DuffelAPI.Types.OfferRequestSlice | DuffelAPI.Types.OrderSlice
  >
): SearchType | null => {
  if (fromSlices.length === 0) return null

  if (fromSlices.length === 1) return 'one_way'

  if (
    fromSlices.length === 2 &&
    fromSlices[0].origin.iataCode === fromSlices[1].destination.iataCode &&
    fromSlices[0].destination.iataCode === fromSlices[1].origin.iataCode
  )
    return 'return'

  return 'multi_city'
}
