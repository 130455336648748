import { DuffelAPI } from '@lib/types'
import {
  getPassengerType,
  splitPassengersByKind,
  PassengerType,
} from '@modules/air-search-v2/forms/SearchForm/FormFields/PassengersSelect/lib'

/**
 * Calculates which adult/child passenger a given passenger is
 * This value is used to generate the default passenger label if no
 * name is passed in.
 *
 * Example - given this array of passengers [adult, adult, child],
 * the child passenger is "Passenger 3", but more importantly, "Child 1"
 */
export const getPassengerIndexOfType = (
  passenger: DuffelAPI.Inputs.OfferRequestPassenger,
  passengersFromFormik: DuffelAPI.Inputs.OfferRequestPassenger[]
): number => {
  const [adults, children] = splitPassengersByKind(passengersFromFormik)

  return getPassengerType(passenger) === PassengerType.Adult
    ? adults.indexOf(<DuffelAPI.Inputs.OfferRequestAdultPassenger>passenger) + 1
    : children.indexOf(<DuffelAPI.Inputs.OfferRequestChildPassenger>passenger) +
        1
}
