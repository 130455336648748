import * as React from 'react'
import { useField, useFormikContext } from 'formik'
import { Autofill } from '@components/Autofill'
import { FormField } from '@components/Form'
import { slugify } from '@lib/helpers'
import { DuffelAPI } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'

interface IataCodeLookupProps {
  label: string
  name: string
  placeholder?: string
  onChange: (newValue: string | DuffelAPI.Types.Place) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
  disabled?: boolean
  tooltipText?: string
}

// Apply button and input overrides throughout
export const IataCodeLookup: React.FC<IataCodeLookupProps> = ({
  label,
  name,
  placeholder,
  onChange,
  disabled,
  tooltipText,
  onBlur,
}) => {
  const { duffelClient } = useWorkspace()
  const [{ value }, { touched, error }] = useField({ name })
  const { submitCount } = useFormikContext()
  const id = slugify(name)
  return (
    <FormField
      htmlFor={id}
      label={label}
      optionalField
      error={touched || submitCount > 0 ? error : undefined}
      disabled={disabled}
      tooltipText={tooltipText}
    >
      <Autofill
        id={id}
        placeholder={placeholder}
        lookup={async (query: string) => {
          const { data } = await duffelClient.Places.suggest(query)
          return data ?? []
        }}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        renderResultLabel={({ iataCode, name }) => `${name} (${iataCode})`}
        disabled={disabled}
        tooltipText={tooltipText}
      />
    </FormField>
  )
}
