type ArrayType = {
  ['key']: string | number
  ['value']: string | number
}[]

/**
 * Convert Array to HashMap/Dictionary
 * @param array - array that we want to convert
 * @param key - which key should we use to map
 * @param value - which value should be associated to the key
 * @param ascending - should we sort ascending?
 * @returns hashmap
 */
export function convertArrayToHashMap(array: ArrayType) {
  return array.reduce((hashMap, currentElement) => {
    if (currentElement.key) {
      hashMap[currentElement.key] = currentElement.value
    }
    return hashMap
  }, {})
}
