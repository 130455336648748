import { useRouter } from 'next/router'
import { DialogProps } from '@components/Dialog'
import { UNKNOWN_ERROR_MESSAGE } from '@lib/constants'
import { trackEvent } from '@lib/tracking'
import { DuffelAPI } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import { useUserFriendlyErrors } from '@modules/air-order/lib/use-user-friendly-errors'
import { Callout } from '@components/Callout'
import { VSpace } from '@components/VSpace'
import { getDuffelAPIClient } from '@lib/duffel-api/getDuffelAPIClient'
import { AxiosResponse } from 'axios'

export interface AICDialogProps extends DialogProps {
  actionTaken?: DuffelAPI.Types.ActionTakenType
}

const DialogContents: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <VSpace space={16}>
    <div>{children}</div>
    <Callout intent="warning" iconName="info">
      This will only update the order in Duffel; no further action will be
      taken.
    </Callout>
  </VSpace>
)

export const useAICDialog = (
  order: DuffelAPI.Types.Order,
  airlineInitiatedChangeId: DuffelAPI.Types.AirlineInitiatedChange['id']
) => {
  const { addToast, openDialog, closeDialog, updateDialog } = useWorkspace()
  const { handleOrderErrors } = useUserFriendlyErrors()
  const router = useRouter()

  const openActionDialog = async (dialogProps: AICDialogProps) => {
    const { actionTaken } = dialogProps

    let title = 'Accept changes'
    let message = (
      <>
        By accepting these changes, the traveller will be subject to the
        original fare conditions for this booking.
        <br />
        Do you want to proceed?
      </>
    )

    switch (actionTaken) {
      case 'cancelled':
        title = 'Mark as cancelled'
        message = (
          <DialogContents>
            Press confirm if you've cancelled the order in an external system
            and want to mark the order as cancelled in Duffel.
          </DialogContents>
        )
        break

      case 'changed':
        title = 'Mark as changed'
        message = (
          <DialogContents>
            Press confirm if you've changed the order in an external system and
            want to mark the order as changed in Duffel.
          </DialogContents>
        )
        break

      case 'accepted':
        title = 'Mark as accepted'
        message = (
          <DialogContents>
            Press confirm if you've accepted the change in an external system
            and want to mark the change as accepted in Duffel.
          </DialogContents>
        )
    }

    const onConfirmFn = async () => {
      updateDialog({ isConfirmButtonLoading: true })

      // Mark as... flow
      if (actionTaken) {
        const client = getDuffelAPIClient(
          undefined,
          undefined,
          router.query.org as string,
          router.query.mode as 'live' | 'test'
        )
        let response: AxiosResponse | null = null
        try {
          response = await client.post(
            `/air/airline_initiated_changes/${airlineInitiatedChangeId}`,
            { data: { action_taken: actionTaken } }
          )
        } catch (error) {
          console.error(error)
          trackEvent('dashboard_airline_initiated_change_update_failed', {
            error_message: UNKNOWN_ERROR_MESSAGE,
            event_type: 'api',
            order_id: order.id,
          })

          return await handleOrderErrors([], order, {
            actionToSupport: 'accept_airline_initiated_change',
            requestId: '',
          })
        }

        if (response) {
          if (response.data['errors']) {
            trackEvent('dashboard_airline_initiated_change_update_failed', {
              error_message:
                response.data['errors']?.[0]?.message || UNKNOWN_ERROR_MESSAGE,
              event_type: 'api',
              order_id: order.id,
              status: response.data['meta']?.status,
            })

            return await handleOrderErrors(
              response.data['errors'] ?? [],
              order,
              {
                actionToSupport: 'accept_airline_initiated_change',
                requestId: response.data['meta']?.requestId ?? '',
              }
            )
          } else {
            updateDialog({ isConfirmButtonLoading: false })

            const message =
              actionTaken === 'accepted'
                ? 'You have accepted the airline changes. This page will be updated once the changes are confirmed.'
                : `Success! We have marked your order as ${actionTaken}.`

            addToast({
              intent: 'success',
              message: message,
            })
          }
        }
      } else {
        const client = getDuffelAPIClient(
          undefined,
          undefined,
          router.query.org as string,
          router.query.mode as 'live' | 'test'
        )
        let response: AxiosResponse | null = null
        try {
          response = await client.post(
            `/air/airline_initiated_changes/${airlineInitiatedChangeId}/actions/accept`
          )
        } catch (error) {
          console.error(error)
          trackEvent('dashboard_airline_initiated_change_accept_failed', {
            error_message: UNKNOWN_ERROR_MESSAGE,
            event_type: 'api',
            order_id: order.id,
          })

          return await handleOrderErrors([], order, {
            actionToSupport: 'accept_airline_initiated_change',
            requestId: '',
          })
        }

        if (response) {
          if (response.data['errors']) {
            trackEvent('dashboard_airline_initiated_change_accept_failed', {
              error_message:
                response.data['errors']?.[0]?.message || UNKNOWN_ERROR_MESSAGE,
              event_type: 'api',
              order_id: order.id,
              status: response.data['meta']?.status,
            })

            return await handleOrderErrors(
              response.data['errors'] ?? [],
              order,
              {
                actionToSupport: 'accept_airline_initiated_change',
                requestId: response.data['meta']?.requestId ?? '',
              }
            )
          } else {
            // 200 response payload includes the updated airline initiated change
            // 202 response payload is {} and might need to be wrapped in data
            addToast({
              intent: 'success',
              message:
                'Success! We have updated your order to include the changes you have accepted.',
            })
          }
        }
      }

      closeDialog()
      await router.replace(router.asPath)
    }

    openDialog({
      title: title,
      message: message,
      onConfirm: onConfirmFn,
      cancelButtonLabel: 'Back',
      onCancel: closeDialog,
      width: 500,
    })
  }

  return {
    openActionDialog,
  }
}
