import dynamic from 'next/dynamic'
import * as React from 'react'
import { Drawer } from '@components/Drawer'
import { Text } from '@components/Text'
import { CreateOrderChangeFormProps } from '@modules/air-order-change/forms'
import styles from './AdvancedOptions.module.css'

const PrivateFaresInputContainer = dynamic<CreateOrderChangeFormProps>(() =>
  import('../../containers/PrivateFaresInputContainer').then(
    (mod) => mod.PrivateFaresInputContainer
  )
)

export const AdvancedOptions: React.FC<CreateOrderChangeFormProps> = (
  props
) => {
  const drawerContents = {
    styledTextWhenDrawerIsClosed: (
      <Text fontSize="C2" fontWeight="medium" color="purple-700">
        Show advanced options
      </Text>
    ),
    styledTextWhenDrawerIsOpen: (
      <Text fontSize="C2" fontWeight="medium" color="purple-700">
        Hide advanced options
      </Text>
    ),
    styled: true,
  }

  return (
    <div className={styles['change-order-advanced-options-container']}>
      <Drawer
        className={styles['change-order-advanced-options']}
        actionText={drawerContents}
      >
        <PrivateFaresInputContainer {...props} />
      </Drawer>
    </div>
  )
}
