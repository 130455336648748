import { FilterValue } from '@components/Filters/lib/types'
import { UNKNOWN_ERROR_MESSAGE } from '@lib/constants'
import { download } from '@lib/helpers'
import { trackEvent } from '@lib/tracking'
import { WorkspaceContextType } from '@lib/workspace-context'
import { getOrderIndexFiltersQueryParams } from './getOrderIndexFiltersQueryParams'
import { AxiosInstance, AxiosResponse } from 'axios'
import { DuffelAPI } from '@lib/types'

export const downloadOrdersDataAsCSV = async (
  addToast: WorkspaceContextType['addToast'],
  client: AxiosInstance,
  orderFilters?: FilterValue[]
) => {
  addToast({
    intent: 'info',
    message: 'Your download will start shortly',
  })
  trackEvent('dashboard_orders_download_button_clicked', {
    event_type: 'interaction',
    download_type: 'csv',
  })

  const handleFailure = (alertMessage = UNKNOWN_ERROR_MESSAGE) => {
    trackEvent('dashboard_orders_download_alert_displayed', {
      event_type: 'alert',
      download_type: 'csv',
      alert_message: alertMessage,
      request_id: response?.data['meta']?.requestId,
    })
    addToast({
      intent: 'warning',
      message: alertMessage,
      closeAfterTimeout: false,
    })
  }

  const filterQueryParams = getOrderIndexFiltersQueryParams(orderFilters)
  let response: AxiosResponse | null = null
  try {
    response = await client.get('/air/orders', {
      responseType: 'blob',
      headers: {
        accept: 'text/csv',
      },
      params: {
        _format: 'csv',
        limit: 200,
        ...filterQueryParams,
      },
    })
  } catch (error) {
    console.error(error)
    handleFailure()
  }

  if (!response) {
    handleFailure()
    return
  } else {
    if (response.data['errors']) {
      handleFailure(response.data['errors'][0].message)
      return
    }
    setTimeout(() => {
      const fileName = 'Duffel orders summary.csv'
      const attachment: DuffelAPI.Types.FileAttachment = {
        content: response!.data,
      }

      if (response!.headers && response!.headers['content-disposition']) {
        const parts = response!.headers['content-disposition'].split(';')
        if (parts.length > 1) {
          attachment.fileName = parts[1].replace(/^\sfilename=/, '').trim()
        }
      }
      download(attachment.content as any, fileName, 'text/csv')
      trackEvent('dashboard_orders_download_button_clicked', {
        event_type: 'interaction',
        download_type: 'csv',
      })
    }, 500)
  }
}
