import { parse } from 'cookie'
import { nanoid } from 'nanoid'
import { isBrowser, isDev } from '@lib/env'

const simpleSessionId = 'ff-session'

export const setSessionPropertiesOnCookie = () => {
  if (!isBrowser || !window.document.cookie) return {}
  const domain = !isDev ? `.${window.location.host}` : ''

  document.cookie = `${simpleSessionId}=${nanoid()}; expires=Fri, 31 Dec 9999 23:59:59 GMT; domain=${domain}`
}

export const getSessionPropertiesFromCookie = () => {
  if (!isBrowser || !document.cookie) return {}

  const cookieObject = parse(document.cookie)
  return { sessionId: cookieObject[simpleSessionId] || '' }
}

export const clearSessionPropertiesFromCookie = () => {
  if (!isBrowser || !document.cookie) return {}

  const domain = !isDev ? `.${window.location.host}` : ''

  document.cookie = `${simpleSessionId}=; expires=${new Date()}; domain=${domain}`
}
