import { DuffelAPI } from '@lib/types'
import { makeMockOfferSlicePlace } from './make-mock-offer-slice-place'
import { makeMockOfferSliceSegmentFromOriginDestination } from './make-mock-offer-slice-segment'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.OfferSlice => ({
    id: `pre_${(seedId++).toString().padStart(5, '0')}`,
    ngsShelf: 1,
    conditions: {
      refundBeforeDeparture: null,
      changeBeforeDeparture: null,
      advanceSeatSelection: false,
      priorityBoarding: false,
      priorityCheckIn: false,
    },
    destination: makeMockOfferSlicePlace({
      iataCode: 'JFK',
      name: 'John F. Kennedy Airport',
    }),
    destinationType: 'airport',
    duration: 'PT03H15M',
    fareBrandName: 'Economy',
    origin: makeMockOfferSlicePlace({
      iataCode: 'LHR',
      name: 'Heathrow Airport',
    }),
    originType: 'airport',
    segments: [makeMockOfferSliceSegmentFromOriginDestination('LHR', 'JFK')],
  })
})()

export const makeMockOfferSlice = (
  extendDefault?: Partial<DuffelAPI.Types.OfferSlice>
): DuffelAPI.Types.OfferSlice =>
  Object.assign({}, getDefaultObject(), extendDefault || {})

export const makeMockOfferSliceFromOriginDestination = (
  originIataCode: string,
  destinationIataCode: string,
  passengerId?: string
) =>
  makeMockOfferSlice({
    origin: makeMockOfferSlicePlace({ iataCode: originIataCode }),
    destination: makeMockOfferSlicePlace({ iataCode: destinationIataCode }),
    segments: [
      makeMockOfferSliceSegmentFromOriginDestination(
        originIataCode,
        destinationIataCode,
        passengerId
      ),
    ],
  })
