import { NextApiRequest, NextApiResponse } from 'next'
import requestIp from 'request-ip'
import { maybeGetAuthProps } from './maybeGetAuthProps'
import { JsonPayloadForLogging } from './types'

const getHeader = (
  headers:
    | NextApiRequest['headers']
    | ReturnType<NextApiResponse['getHeaders']>,
  headerName: keyof NextApiRequest['headers']
): string => {
  const value = headers[headerName]
  if (!value) return ''
  else if (Array.isArray(value)) return value.join(', ')
  else return value.toString()
}

export const getJsonPayload = async (
  fromRequest: NextApiRequest,
  withResponse: NextApiResponse,
  requestReceivedAt: number | null
): Promise<JsonPayloadForLogging> => {
  return new Promise<JsonPayloadForLogging>((resolve, reject) => {
    if (!fromRequest) {
      reject(new Error('The request object is missing'))
    } else if (!withResponse) {
      reject(new Error('The response object is missing'))
    } else if (!withResponse.addListener) {
      reject(new Error('The response.addListener function is missing'))
    } else {
      withResponse.addListener('finish', () => {
        resolve({
          requestId: getHeader(withResponse.getHeaders(), 'x-request-id'),
          httpRequest: {
            requestMethod: fromRequest.method,
            requestUrl: fromRequest.url,
            requestSize: fromRequest.socket?.bytesRead,
            userAgent: getHeader(fromRequest.headers, 'user-agent'),
            remoteIp: requestIp.getClientIp(fromRequest),
            referer: getHeader(fromRequest.headers, 'referer'),
            status: withResponse.statusCode,
            responseSize: fromRequest.socket?.bytesWritten,
            ...(typeof requestReceivedAt === 'number' && {
              responseTime: Date.now() - requestReceivedAt,
            }),
            org: getHeader(fromRequest.headers, 'x-org'),
            liveMode: getHeader(fromRequest.headers, 'x-live-mode'),
          },
          ...maybeGetAuthProps(getHeader(fromRequest.headers, 'cookie')),
        })
      })
    }
  })
}
