import * as React from 'react'
import { WorkspaceContextType } from '@lib/workspace-context'
import { Toast, ToastProps } from './Toast'
import styles from './Toaster.module.css'

interface ToasterProps {
  toasts: ToastProps[]
  addToast: WorkspaceContextType['addToast']
  closeToast: WorkspaceContextType['closeToast']
  closeAllToasts: WorkspaceContextType['closeAllToasts']
}

export const Toaster: React.FC<ToasterProps> = ({
  closeToast,
  closeAllToasts,
  toasts,
}) => {
  React.useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      const isEscapeKey = event.key === 'Escape' || event.keyCode === 27
      if (isEscapeKey) {
        closeAllToasts()
      }
    }

    if (toasts.length > 0) {
      document.addEventListener('keydown', onKeyDown)
    }

    return () => document.removeEventListener('keydown', onKeyDown)
  }, [toasts, closeAllToasts])

  if (toasts.length === 0) return null

  return (
    <div className={styles['toasts']}>
      {toasts.map((toastProps, index) => (
        <Toast
          key={toastProps.id}
          index={index}
          onClose={() => closeToast(index)}
          {...toastProps}
        />
      ))}
    </div>
  )
}
