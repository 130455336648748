import React from 'react'
import { DuffelAPI } from '@lib/types'

export type OrganisationSources = Array<DuffelAPI.Types.OrganisationSource>

export type OrganisationSourcesContextType = OrganisationSources

export const OrganisationSourcesContext =
  React.createContext<OrganisationSourcesContextType>([])

export const OrganisationSourcesContextProvider: React.FC<
  React.PropsWithChildren<{
    organisationSources: OrganisationSources
  }>
> = ({ children, organisationSources }) => (
  <OrganisationSourcesContext.Provider value={organisationSources}>
    {children}
  </OrganisationSourcesContext.Provider>
)

export const useOrganisationSourcesContext = () => {
  return React.useContext<OrganisationSourcesContextType>(
    OrganisationSourcesContext
  )
}
