import { ErrorBoundary } from '@components/ErrorBoundary'
import {
  Offer,
  OfferAvailableServiceBaggage,
  Order,
  OrderAvailableService,
} from '@duffel/api/types'
import { CurrencyConversion } from '@duffel/components'
import { isEqual } from 'lodash'
import * as React from 'react'
import { AdditionalBaggage } from './AdditionalBaggage'
import styles from './AdditionalBaggageSelection.module.css'
import { PassengersLayout } from './PassengersLayout'
import { formatConvertedCurrency } from './lib/formatConvertedCurrency'
import {
  PassengersProvider,
  usePassengersContext,
} from './lib/usePassengersContext'

type Baggage = {
  id: string
  quantity: number
}

export interface LayoutSelectionPassenger {
  id: string
  name?: string | null
}

export interface AdditionalBaggageSelectionCommonProps {
  /**
   * List of all passengers that will be purchasing baggages
   */
  passengers: LayoutSelectionPassenger[]
  /**
   * What to do when the user presses the Confirm button
   */
  onSubmit: (baggages: Baggage[]) => void
  /**
   * Already selected baggages to initialize the component with
   */
  initialBaggageSelection?: Baggage[]
  /**
   * Optional currency conversion to enable prices to be shown in an alternative currency
   */
  currencyConversion?: CurrencyConversion
}

export interface AdditionalBaggageSelectionOfferProps
  extends AdditionalBaggageSelectionCommonProps {
  /**
   * The offer we are selecting additional baggages for
   */
  offer: Offer

  /**
   * The order we are selecting additional baggages for
   */
  order?: never

  /**
   * The available services that can be applied to this order
   */
  availableServices?: never
}

export interface AdditionalBaggageSelectionServicesProps
  extends AdditionalBaggageSelectionCommonProps {
  /**
   * The offer we are selecting additional baggages for
   */
  offer?: never

  /**
   * The order we are selecting additional baggages for
   */
  order?: Order

  /**
   * The available services that can be applied to this order
   */
  availableServices: OrderAvailableService[]
}

export type AdditionalBaggageSelectionProps =
  | AdditionalBaggageSelectionOfferProps
  | AdditionalBaggageSelectionServicesProps

const AdditionalBaggageSelect: React.FC<
  Omit<AdditionalBaggageSelectionProps, 'passengers'>
> = ({
  offer,
  order,
  availableServices,
  onSubmit,
  initialBaggageSelection,
  currencyConversion,
}) => {
  const passengersContext = usePassengersContext()
  const [additionalBaggages, setAdditionalBaggages] = React.useState<Baggage[]>(
    initialBaggageSelection ?? []
  )

  let baggageServiceAppliesToAllSegments = true

  if (!passengersContext) {
    throw new Error(
      'AdditionalBaggageSelect must be used within PassengersContext'
    )
  }

  if (!offer && !order && !availableServices) {
    throw new Error(
      'AdditionalBaggageSelect must have either an offer or order and available services'
    )
  }
  const { selectedPassenger, selectedSegment } = passengersContext
  let baggageServicesToDisplay: OfferAvailableServiceBaggage[] = []
  if (offer) {
    baggageServicesToDisplay = (
      offer.available_services as OfferAvailableServiceBaggage[]
    ).filter(
      (service) =>
        service.passenger_ids.includes(selectedPassenger.id) &&
        service.segment_ids.includes(selectedSegment.id) &&
        service.type === 'baggage'
    )
  } else if (availableServices) {
    baggageServicesToDisplay = availableServices.filter(
      (service) =>
        service.passenger_ids.includes(selectedPassenger.id) &&
        service.segment_ids.includes(selectedSegment.id) &&
        service.type === 'baggage'
    )
  }

  const baggageServiceIds = baggageServicesToDisplay?.map(({ id }) => id)
  const baggagesToDisplay = additionalBaggages.filter(({ id }) =>
    baggageServiceIds?.includes(id)
  )

  const totalBaggagesAdded = additionalBaggages.reduce(
    (total, baggage) => total + baggage.quantity,
    0
  )

  let baggagePriceById: Record<string, number>

  if (offer) {
    baggagePriceById = offer.available_services.reduce(
      (priceById, baggage) => ({
        ...priceById,
        [baggage.id]: +baggage.total_amount,
      }),
      {}
    )
  } else if (availableServices) {
    baggagePriceById = availableServices.reduce(
      (priceById, baggage) => ({
        ...priceById,
        [baggage.id]: +baggage.total_amount,
      }),
      {}
    )
  }

  const currency =
    currencyConversion?.currency && currencyConversion?.rate
      ? currencyConversion.currency
      : offer?.total_currency || availableServices?.[0].total_currency

  const totalAmount = additionalBaggages
    .map((baggage) => +baggage.quantity * baggagePriceById[baggage.id])
    .reduce((acc, amount) => acc + amount, 0)
    .toString()

  const formattedAmount = formatConvertedCurrency(
    totalAmount,
    currency!,
    currencyConversion?.rate ?? 1
  )

  // Determine whether each baggage service is contained in every segment in the itinerary

  if (order && availableServices) {
    const orderSegmentIds = order.slices.flatMap((slice) =>
      slice.segments.flatMap((segment) => segment.id)
    )

    availableServices.forEach((availableService) => {
      if (!isEqual(availableService.segment_ids, orderSegmentIds)) {
        baggageServiceAppliesToAllSegments = false
      }
    })
  } else if (offer) {
    const offerSegmentIds = offer.slices.flatMap((slice) =>
      slice.segments.flatMap((segment) => segment.id)
    )

    offer.available_services.forEach((availableService) => {
      if (!isEqual(availableService.segment_ids, offerSegmentIds)) {
        baggageServiceAppliesToAllSegments = false
      }
    })
  }

  return (
    <PassengersLayout
      renderPassengerSelectionDetails={(passengerId, segmentId) => {
        const baggageIds = offer
          ? offer.available_services
              .filter(
                (service) =>
                  service.passenger_ids.includes(passengerId) &&
                  service.segment_ids.includes(segmentId) &&
                  service.type === 'baggage'
              )
              .map((baggage) => baggage.id)
          : availableServices &&
            availableServices
              .filter(
                (service) =>
                  service.passenger_ids.includes(passengerId) &&
                  service.segment_ids.includes(segmentId) &&
                  service.type === 'baggage'
              )
              .map((baggage) => baggage.id)
        const baggagesAdded = additionalBaggages
          .filter((baggage) => baggageIds?.includes(baggage.id))
          .reduce((total, baggage) => total + baggage.quantity, 0)

        return `${baggagesAdded} bag${baggagesAdded !== 1 ? 's' : ''} added`
      }}
      summaryTitle={`${totalBaggagesAdded} bag${
        totalBaggagesAdded !== 1 ? 's' : ''
      } added`}
      formattedTotalAmount={formattedAmount}
      onSubmit={() => onSubmit(additionalBaggages)}
    >
      <div className={styles['additional-baggage-select']}>
        <div className={styles['additional-baggage-select--container']}>
          <div className={styles['additional-baggage-select__title']}>
            Purchase additional baggage
          </div>
          {baggageServiceAppliesToAllSegments && (
            <div className={styles['additional-baggage-select__notice']}>
              Bags selected will be applied to all flights in this itinerary
            </div>
          )}
          <AdditionalBaggage
            availableServices={baggageServicesToDisplay}
            additionalBaggages={baggagesToDisplay}
            onChange={(updatedBaggages) => {
              setAdditionalBaggages((prev) => [
                // change in selected baggages
                ...prev.map(
                  (baggage) =>
                    updatedBaggages.find(
                      (updatedBaggage) => updatedBaggage.id === baggage.id
                    ) ?? baggage
                ),
                // new baggages selected
                ...updatedBaggages.filter(
                  (baggage) =>
                    !prev.find(
                      (updatedBaggage) => updatedBaggage.id === baggage.id
                    )
                ),
              ])
            }}
            currencyConversion={currencyConversion}
          />
        </div>
      </div>
    </PassengersLayout>
  )
}

/**
 * @deprecated This is here to support legacy code.
 * Please use `@duffel/components` for baggage selection instead.
 */
export const AdditionalBaggageSelection: React.FC<
  AdditionalBaggageSelectionProps
> = (props) => {
  let segments: any = []
  if (props.offer) {
    segments = props.offer.slices.map((slice) => slice.segments).flat()
  } else if (props.order) {
    segments = props.order.slices.map((slice) => slice.segments).flat()
  }

  return (
    <ErrorBoundary
      title="Error loading extra bag selection"
      message="We weren’t able to load extra bag selection at this time."
    >
      <PassengersProvider passengers={props.passengers} segments={segments}>
        <AdditionalBaggageSelect {...props} />
      </PassengersProvider>
    </ErrorBoundary>
  )
}
