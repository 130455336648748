import { useFormikContext } from 'formik'
import * as React from 'react'
import { array, lazy, number, object, string } from 'yup'
import { FormikSubmitButton } from '@components/Form/FormikSubmitButton'
import { CabinClass, DuffelAPI } from '@lib/types'
import { AdvancedOptionsSection } from './AdvancedOptionsSection'
import {
  CabinClassSelect,
  PassengersSelect,
  SplitFormFields,
} from './FormFields'
import { SEARCH_TIME_OPTIONS } from './lib/search-time-ranges'
import { DEFAULT_SUPPLIER_TIMEOUT } from '@modules/air-search-v2/lib'

export interface CommonSearchFormSectionValues {
  passengers: DuffelAPI.Inputs.OfferRequestPassenger[]
  cabinClass: CabinClass
  airlines: string[]
  supplierTimeout: number
}

export interface CommonSearchFormSectionProps {
  onSubmitButtonClicked?: () => void
  hideCabinClass?: boolean
}

export const CommonSearchFormSection: React.FC<
  CommonSearchFormSectionProps
> = ({ onSubmitButtonClicked, hideCabinClass }) => {
  const formik = useFormikContext<CommonSearchFormSectionValues>()

  return (
    <>
      <SplitFormFields>
        <PassengersSelect
          onChange={(passengers) => {
            formik.setFieldValue('passengers', passengers, true)
          }}
        />
        {!hideCabinClass && (
          <CabinClassSelect
            onChange={(cabinClass) => {
              formik.setFieldValue('cabinClass', cabinClass)
            }}
          />
        )}
      </SplitFormFields>
      {/* if the airliens are already selected, show the advanced option initially  */}
      <AdvancedOptionsSection
        initialStateOpen={
          formik.values.airlines.length > 0 ||
          formik.values.supplierTimeout != DEFAULT_SUPPLIER_TIMEOUT
        }
      />
      <div className="submit-button-container">
        <FormikSubmitButton
          text="Find available flights"
          onClick={onSubmitButtonClicked}
        />
      </div>
      <style jsx>{`
        .submit-button-container {
          margin-top: 28px;
        }
      `}</style>
    </>
  )
}

const passengerSchema: any = lazy((passengerObject) => {
  const { age } = passengerObject

  if (age) {
    return object().shape({
      key: string(),
      age: number().min(0),
      loyaltyProgrammeAccounts: array(),
    })
  }

  return object().shape({
    key: string(),
    type: string(),
    loyaltyProgrammeAccounts: array(),
  })
})

export const commonValidationSchema = {
  passengers: array()
    .min(1) // Need at least one passenger
    .of(passengerSchema)
    .test(
      'adult-infant-assignment-validation',
      'The number of infants (under 2 years old) can’t exceed the number of adults.',
      (passengers: DuffelAPI.Inputs.OfferRequestPassenger[] | undefined) => {
        const infantPassengers = passengers?.filter(
          (passenger) => passenger.age !== undefined && passenger.age <= 1
        )
        const adultPassengers = passengers?.filter(
          (passenger) => passenger.type === 'adult'
        )

        return (infantPassengers?.length ?? 0) <= (adultPassengers?.length ?? 0)
      }
    )
    .required(),
  cabinClass: string()
    .oneOf(['first', 'business', 'premium_economy', 'economy', 'any'])
    .required(),
  airlines: array().of(string()),
}

export const searchTimeRangesSchema = object().shape({
  departureTime: object().shape({
    from: string().oneOf(SEARCH_TIME_OPTIONS),
    to: string().oneOf(SEARCH_TIME_OPTIONS),
  }),
  arrivalTime: object().shape({
    from: string().oneOf(SEARCH_TIME_OPTIONS),
    to: string().oneOf(SEARCH_TIME_OPTIONS),
  }),
})
