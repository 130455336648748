import * as React from 'react'

export interface FormProps {
  children: React.ReactNode
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void
  autoComplete?: string
  method?: 'post'
  /**
   * Element ID
   */
  id?: string
}

export const Form = React.forwardRef<HTMLFormElement, FormProps>(
  ({ children, onSubmit, autoComplete = 'off', method, id }, ref) => (
    <form
      onSubmit={onSubmit}
      autoComplete={autoComplete}
      method={method}
      id={id}
      data-testid={id}
      ref={ref}
    >
      {children}
    </form>
  )
)
