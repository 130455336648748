import { DuffelAPI } from '@lib/types'

const getDefaultObject = (() => {
  return (): DuffelAPI.Types.LoyaltyProgrammeAccount => ({
    airlineIataCode: 'LX',
    accountNumber: 'LX60',
  })
})()

export const makeMockLoyaltyProgrammeAccount = (
  extendDefault?: Partial<DuffelAPI.Types.LoyaltyProgrammeAccount>
): DuffelAPI.Types.LoyaltyProgrammeAccount =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
