import { useField, useFormikContext } from 'formik'
import * as React from 'react'
import { Select, SelectProps } from '@components/Select'
import { trackEvent } from '@lib/tracking'
import { FormField, FormFieldProps } from './FormField'

export interface FormikSelectProps extends FormFieldProps, SelectProps {
  /**
   * The name of the field used by Formik
   */
  name: string

  /**
   * Tooltip to be displayed
   */
  tooltipText?: string

  /**
   * Show disabled styles
   */
  disabled?: boolean

  /**
   * Custom error message that will override the default error message generated from Formik
   */
  error?: string
}

export const FormikSelect: React.FC<
  React.PropsWithChildren<FormikSelectProps>
> = (props) => {
  const {
    label,
    hint,
    optionalField: isFieldOptional,
    secondaryLabel,
    className,
    tooltipText,
    infoTooltipText,
    showInfoTooltip,
    disabled,
    error,
    beta,
    placeholder,
    ...selectProps
  } = props
  const [field, { touched, error: errorFromFormik }] = useField(props)
  const { submitCount, isSubmitting } = useFormikContext()
  const isDisabled = isSubmitting || disabled

  let formattedError
  if (error) {
    formattedError = error
  } else if (touched || submitCount > 0) {
    formattedError = errorFromFormik
  }

  return (
    <div className="ff-form-select">
      <FormField
        label={label}
        error={formattedError}
        hint={hint}
        htmlFor={selectProps.id || props.name}
        optionalField={isFieldOptional}
        secondaryLabel={secondaryLabel}
        className={className}
        disabled={isDisabled}
        tooltipText={tooltipText}
        showInfoTooltip={showInfoTooltip}
        infoTooltipText={infoTooltipText}
        beta={beta}
      >
        <Select
          {...selectProps}
          {...field}
          disabled={isDisabled}
          id={selectProps.id || props.name}
          onChange={(event) => {
            field.onChange(event)
            trackEvent('dashboard_common_select_changed', {
              event_type: 'interaction',
              name: props.name,
            })
          }}
          placeholder={placeholder}
        />
      </FormField>
    </div>
  )
}
