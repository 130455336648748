import { FormikProps } from 'formik'
import * as React from 'react'
import { Form } from '@components/Form'
import { FormikSubmitButton } from '@components/Form/FormikSubmitButton'
import { VSpace } from '@components/VSpace'
import { useFeatureFlags } from '@lib/unleash'
import { CabinClassSelect } from '@modules/air-search-v2/forms/SearchForm/FormFields'
import { CreateOrderChangeRequestFormValues } from '../lib'
import {
  getSliceOrderValidationContextValue,
  SliceOrderValidationContext,
} from '../lib/slice-validation-context'
import { AdvancedOptions } from '../components'
import { CreateOrderChangeFormSlices } from './CreateOrderChangeFormSlices'

export type CreateOrderChangeFormProps =
  FormikProps<CreateOrderChangeRequestFormValues>

export const CreateOrderChangeForm: React.FC<CreateOrderChangeFormProps> = (
  props
) => {
  const { handleSubmit, setFieldValue, values } = props
  const hasChanges = values.slices.some(({ toBeChanged }) => toBeChanged)
  // dashboard_show_private_fares_input_on_change_order flag is only turned on for specific organisations
  const showPrivateFaresInputOnChangeOrder = useFeatureFlags(
    'dashboard_show_private_fares_input_on_change_order'
  )
  return (
    <Form onSubmit={handleSubmit}>
      <VSpace space={24}>
        <CabinClassSelect
          onChange={(cabinClass) => setFieldValue('cabinClass', cabinClass)}
          disableAny
        />

        <SliceOrderValidationContext.Provider
          value={getSliceOrderValidationContextValue(values)}
        >
          <CreateOrderChangeFormSlices {...props} />
        </SliceOrderValidationContext.Provider>

        {showPrivateFaresInputOnChangeOrder && <AdvancedOptions {...props} />}

        <FormikSubmitButton
          text="Search available flights"
          disabled={!hasChanges}
        />
      </VSpace>
    </Form>
  )
}
