import classNames from 'classnames'
import React from 'react'

export interface SkeletonProps {
  width?: number
  height?: number
  color?: 'dark' | 'darker'
}

export const Skeleton: React.FC<SkeletonProps> = ({ width, height, color }) => (
  <div
    className={classNames('u-skeleton', {
      'u-skeleton--dark': color === 'dark',
      'u-skeleton--darker': color === 'darker',
    })}
    style={{
      width: width ? `${width}px` : '100%', // fill the container if not specified
      height: height ? `${height}px` : '100%', // fill the container if not specified
    }}
  />
)

export const Skeletonise: React.FC<React.PropsWithChildren> = ({
  children,
}) => <div className="u-skeletonise">{children}</div>
