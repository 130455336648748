import { DuffelAPI } from '@lib/types'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.SeatMapCabinRowSectionAvailableService => ({
    id: `sea_${(seedId++).toString().padStart(5, '0')}`,
    passengerId: 'pas_1',
    totalAmount: '10.99',
    totalCurrency: 'GBP',
  })
})()

export const makeMockSeatMapCabinRowSectionAvailableService = (
  extendDefault?: Partial<DuffelAPI.Types.SeatMapCabinRowSectionAvailableService>
): DuffelAPI.Types.SeatMapCabinRowSectionAvailableService =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
