import { DuffelAPI } from '@lib/types'
import { processExcessSearchesData } from './process-excess-searches-data'

/**
 * Calculates excess search percent change.
 */
export const calculateExcessSearchPercentChange = (
  billingPeriod: Date,
  maxSearchToBookRatio: number,
  usageRecords: DuffelAPI.Types.UsageRecord,
  previousUsageRecords?: DuffelAPI.Types.UsageRecord
) => {
  const dayToCompare = billingPeriod.getDate()

  const previousMonth =
    billingPeriod.getMonth() === 0 ? 11 : billingPeriod.getMonth() - 1
  const previousYear =
    billingPeriod.getMonth() === 0 ? 11 : billingPeriod.getFullYear()
  const previousBillingPeriod = new Date(
    previousYear,
    previousMonth,
    dayToCompare
  )

  const previousExcessSearches = processExcessSearchesData(
    previousBillingPeriod,
    maxSearchToBookRatio,
    previousUsageRecords?.offerRequestCreated,
    previousUsageRecords?.orderConfirmed
  )

  const currentExcessSearches = processExcessSearchesData(
    billingPeriod,
    maxSearchToBookRatio,
    usageRecords.offerRequestCreated,
    usageRecords.orderConfirmed
  )

  const previousExcessSearchRecord = previousExcessSearches.find(
    (excessSearch) =>
      excessSearch.date === previousBillingPeriod.toISOString().substring(0, 10)
  )

  const previousSearchesValue =
    (previousExcessSearchRecord &&
      parseInt(previousExcessSearchRecord.searches)) ||
    0

  const previousOrdersValue =
    (previousExcessSearchRecord &&
      parseInt(previousExcessSearchRecord.orders)) ||
    0

  const currentExcessSearchRecord = currentExcessSearches.find(
    (excessSearch) =>
      excessSearch.date === billingPeriod.toISOString().substring(0, 10)
  )

  const currentSearchesValue =
    (currentExcessSearchRecord &&
      parseInt(currentExcessSearchRecord.searches)) ||
    0

  const currentOrdersValue =
    (currentExcessSearchRecord && parseInt(currentExcessSearchRecord.orders)) ||
    0

  let previousExcessSearchRatio
  if (previousOrdersValue === 0) {
    previousExcessSearchRatio = 1
  } else {
    previousExcessSearchRatio = previousSearchesValue / previousOrdersValue
  }

  let currentExcessSearchRatio
  if (currentOrdersValue === 0) {
    currentExcessSearchRatio = 1
  } else {
    currentExcessSearchRatio = currentSearchesValue / currentOrdersValue
  }

  const excessSearchPercentChange = Math.floor(
    ((currentExcessSearchRatio - previousExcessSearchRatio) /
      previousExcessSearchRatio) *
      100
  )

  const excessSearchPercentChangeString =
    excessSearchPercentChange >= 0
      ? `+${excessSearchPercentChange.toFixed()}%`
      : `${excessSearchPercentChange.toFixed()}%`

  return excessSearchPercentChangeString
}
