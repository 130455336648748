import { isEqual } from 'lodash'
import React from 'react'
import { VSpace } from '@components/VSpace'
import { Button } from '@components/Button'
import { Text } from '@components/Text'
import {
  defaultSearchTimeRanges,
  SearchTimeRanges,
  SEARCH_TIME_OPTIONS,
} from '@modules/air-search-v2/forms/SearchForm/lib/search-time-ranges'
import {
  isTimeRangeContained,
  parseSearchTimeRanges,
} from '../SearchTimeFilters/lib'
import { TimeRangeSelector } from '../SearchTimeFilters/TimeRangeSelector'

interface FlightTimeFiltersProps {
  postSearchTimeRanges?: SearchTimeRanges
  preSearchTimeRanges?: SearchTimeRanges
  onSubmit: (timeRanges: SearchTimeRanges) => void
  disabled?: boolean
}

export const FlightTimeFilters: React.FC<FlightTimeFiltersProps> = ({
  postSearchTimeRanges,
  preSearchTimeRanges,
  disabled,
  onSubmit,
}) => {
  const searchTimeRanges = parseSearchTimeRanges(
    postSearchTimeRanges ?? preSearchTimeRanges ?? defaultSearchTimeRanges
  )
  const [departureTimeRange, setDepartureTimeRange] = React.useState(
    searchTimeRanges.departureTimeRange
  )
  const [arrivalTimeRange, setArrivalTimeRange] = React.useState(
    searchTimeRanges.arrivalTimeRange
  )

  // if another search happens, sync the state to the pre-search filter
  React.useEffect(() => {
    const searchTimeRanges = parseSearchTimeRanges(
      postSearchTimeRanges ?? preSearchTimeRanges ?? defaultSearchTimeRanges
    )

    setDepartureTimeRange(searchTimeRanges.departureTimeRange)
    setArrivalTimeRange(searchTimeRanges.arrivalTimeRange)
  }, [preSearchTimeRanges, postSearchTimeRanges])

  const hasTimeRangesBeenChanged =
    !isEqual(departureTimeRange, searchTimeRanges.departureTimeRange) ||
    !isEqual(arrivalTimeRange, searchTimeRanges.arrivalTimeRange)

  const formattedPostSearchTimeRanges = {
    departureTime: {
      from: SEARCH_TIME_OPTIONS[departureTimeRange.from],
      to: SEARCH_TIME_OPTIONS[departureTimeRange.to],
    },
    arrivalTime: {
      from: SEARCH_TIME_OPTIONS[arrivalTimeRange.from],
      to: SEARCH_TIME_OPTIONS[arrivalTimeRange.to],
    },
  }
  return (
    <VSpace space={12} className="u-paddingTop4">
      <TimeRangeSelector
        label="Take-off"
        selectedRange={departureTimeRange}
        onChange={(range) => setDepartureTimeRange(range)}
        icon="flight_takeoff"
        disabled={disabled}
      />
      <TimeRangeSelector
        label="Landing"
        selectedRange={arrivalTimeRange}
        onChange={(range) => setArrivalTimeRange(range)}
        icon="flight_land"
        disabled={disabled}
      />

      <VSpace
        space={8}
        style={{
          visibility:
            hasTimeRangesBeenChanged && !disabled ? 'visible' : 'hidden',
        }}
      >
        <Button
          text="Confirm"
          onClick={() => onSubmit(formattedPostSearchTimeRanges)}
        />
        {preSearchTimeRanges &&
          (!isTimeRangeContained(
            preSearchTimeRanges.departureTime,
            formattedPostSearchTimeRanges.departureTime
          ) ||
            !isTimeRangeContained(
              preSearchTimeRanges.arrivalTime,
              formattedPostSearchTimeRanges.arrivalTime
            )) && (
            <Text fontSize="C2" color="grey-600">
              This will trigger a new search.
            </Text>
          )}
      </VSpace>
    </VSpace>
  )
}
