import * as React from 'react'
import classNames from 'classnames'
import styles from './MenuItemButton.module.css'

interface MenuItemButtonProps {
  label: string
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  href?: string
  destructive?: boolean
}
export const MenuItemButton = React.forwardRef<any, MenuItemButtonProps>(
  ({ label, onClick, destructive, ...props }, ref) => {
    return (
      <button
        className={classNames(
          styles['menu-item-button'],
          destructive && styles['menu-item-button--destructive']
        )}
        onClick={onClick}
        {...props}
        ref={ref}
      >
        <span>{label}</span>
      </button>
    )
  }
)
