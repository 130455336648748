import classNames from 'classnames'
import { useField, useFormikContext } from 'formik'
import * as React from 'react'
import { FormField } from '@components/Form'
import { Menu } from '@components/Menu'
import { PopoverContainer } from '@components/PopoverContainer'
import { SelectTarget } from '@components/SelectTarget'
import { goToNextTabbable } from '@lib/helpers'
import { useDuffelPopper } from '@lib/hooks'
import { CabinClass, CabinClassMap } from '@lib/types'

interface CabinClassSelectProps {
  onChange: (value: CabinClass) => void
  disableAny?: boolean
  disableCabinClassSelect?: boolean
}

export const CabinClassSelect: React.FC<CabinClassSelectProps> = ({
  onChange,
  disableAny,
  disableCabinClassSelect,
}) => {
  const [{ value }, { touched, error }] = useField({ name: 'cabinClass' })
  const { submitCount, isSubmitting } = useFormikContext()
  const options = Object.keys(CabinClassMap)
  const [activeOption, setActiveOption] = React.useState(options.indexOf(value))
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
  const onClosePopover = () => setIsPopoverOpen(false)
  const id = 'cabin-class-select'
  const onSelect = (option: CabinClass) => {
    onChange(option)
    goToNextTabbable(document.querySelector<HTMLInputElement>(`#${id}`))
    onClosePopover()
  }
  const isDisabled = isSubmitting || disableCabinClassSelect

  const { popper, setReferenceElement, setPopperElement } = useDuffelPopper(
    isPopoverOpen,
    onClosePopover,
    {
      placement: 'bottom-start',
      modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
    },
    { shouldInsideClickClose: false }
  )

  return (
    <FormField
      disabled={isDisabled}
      htmlFor={id}
      label="Class"
      optionalField
      error={touched || submitCount > 0 ? error : undefined}
    >
      <SelectTarget
        id={id}
        data-testid={id}
        type="button"
        className={classNames({ active: isPopoverOpen })}
        ref={setReferenceElement}
        disabled={isDisabled}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        onKeyDown={(event) => {
          if (event.key === 'ArrowDown') {
            event.preventDefault()
            setActiveOption(Math.min(activeOption + 1, options.length - 1))
          }

          if (event.key === 'ArrowUp') {
            event.preventDefault()
            setActiveOption(Math.max(activeOption - 1, 0))
          }

          if (event.key === 'Enter') {
            if (isPopoverOpen) {
              event.preventDefault()
              onSelect(options[activeOption] as CabinClass)
            }
          }

          if (event.key === 'Tab') {
            onClosePopover()
          }
        }}
      >
        {CabinClassMap[value]}
      </SelectTarget>
      {isPopoverOpen && (
        <PopoverContainer
          ref={setPopperElement}
          style={{
            width: '356px',
            ...popper.styles.popper,
          }}
          {...popper.attributes}
        >
          {
            <Menu>
              {options.map(
                (key, index) =>
                  (key !== 'any' || !disableAny) && (
                    <button
                      key={key}
                      className={classNames('search-form-field--menu-item', {
                        'search-form-field--menu-item--active':
                          activeOption === index,
                        'search-form-field--menu-item--selected':
                          options.indexOf(value) === index,
                      })}
                      onClick={() => {
                        setActiveOption(index)
                        onSelect(key as CabinClass)
                      }}
                      onMouseEnter={() => setActiveOption(index)}
                    >
                      {CabinClassMap[key]}
                    </button>
                  )
              )}
            </Menu>
          }
        </PopoverContainer>
      )}
      <style jsx>{`
        .search-form-field--menu-item {
          cursor: pointer;
          display: block;
          padding: 8px;
          font-size: 16px;
          line-height: 24px;
          border-radius: var(--border-radius-4);
          text-align: start;
          width: 100%;
        }

        .search-form-field--menu-item:hover,
        .search-form-field--menu-item:active,
        .search-form-field--menu-item--active {
          background: var(--purple-100);
        }

        .search-form-field--menu-item--selected {
          font-weight: bold;
        }
      `}</style>
    </FormField>
  )
}
