import { NextApiRequest } from 'next'
import { decrypt } from '@lib/encryption'
import { makeMockDateInTheFuture } from '@lib/testing'
import { JwtPayload } from '@lib/types'
import { CsrfError } from '../proxy/lib'
import {
  CSRF_HEADER_NAME,
  CSRF_TOKEN_NOT_PRESENT_ERROR,
  CSRF_TOKEN_NOT_VALID_ERROR,
} from './constants'
import { getBasisFromTokenString } from './create-csrf-token'

const whitelistedMethods = ['GET']

export function verifyCSRFToken(
  req: NextApiRequest,
  payload: JwtPayload | null
) {
  // If there is no JWT, there is no cookie, so there is no way to forge the request
  if (
    whitelistedMethods.includes(req.method || '') ||
    !payload ||
    !payload.userId
  )
    return
  if (!req.headers[CSRF_HEADER_NAME]) {
    throw new CsrfError(CSRF_TOKEN_NOT_PRESENT_ERROR)
  }

  const computedToken = getBasisFromTokenString(
    decrypt(req.headers[CSRF_HEADER_NAME] as string)
  )
  const tomorrow = makeMockDateInTheFuture(1)
  if (
    computedToken.userId !== payload.userId ||
    !computedToken.iat ||
    +computedToken.iat * 1000 < tomorrow.getTime()
  ) {
    throw new CsrfError(CSRF_TOKEN_NOT_VALID_ERROR)
  }
}
