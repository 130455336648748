import { getCurrencyDigits } from '@lib/helpers'
import { DuffelAPI } from '@lib/types'
import { convertArrayToHashMap } from '@modules/air-search-v2/lib/convert-array-to-hashmap'
import { CheckoutFormValues } from './get-initial-checkout-form-values'
import { getServicesExtraAmount } from './getServicesExtraAmount'

export const transformCheckoutFormValuesIntoPayload = (
  fromFormValues: CheckoutFormValues,
  forOffer: DuffelAPI.Types.Offer
): DuffelAPI.Inputs.Order => {
  const passengers: DuffelAPI.Inputs.OrderPassenger[] =
    fromFormValues.passengers.map((passenger) => ({
      id: passenger.id,
      givenName: passenger.givenName,
      familyName: passenger.familyName,
      gender: passenger.gender,
      title: passenger.title,
      bornOn: passenger.bornOn?.toISOString() || '',
      email: fromFormValues.email,
      phoneNumber: fromFormValues.phoneNumber,
      loyaltyProgrammeAccounts: passenger.loyaltyProgrammeAccounts,
      ...(passenger.responsibleForInfant && {
        infantPassengerId: passenger.responsibleForInfant.infantId,
      }),
      ...(forOffer.supportedPassengerIdentityDocumentTypes.length > 0 &&
        forOffer.supportedPassengerIdentityDocumentTypes[0] === 'passport' &&
        passenger.passportDetails.passportNumber &&
        passenger.passportDetails.expiryDate &&
        passenger.passportDetails.countryOfIssue && {
          identityDocuments: [
            {
              type: 'passport',
              issuingCountryCode: passenger.passportDetails.countryOfIssue,
              expiresOn: passenger.passportDetails.expiryDate,
              uniqueIdentifier: passenger.passportDetails.passportNumber,
            },
          ],
        }),
    }))

  const servicesExtraAmount = getServicesExtraAmount(fromFormValues)
  const currencyDigits = getCurrencyDigits(forOffer.totalCurrency)
  const totalAmountWithServices = (
    parseFloat(forOffer.totalAmount) + servicesExtraAmount
  ).toFixed(currencyDigits)

  const payment = ['balance', 'arc_bsp_cash'].includes(
    fromFormValues.paymentType
  )
    ? {
        type: fromFormValues.paymentType,
        amount: totalAmountWithServices,
        currency: forOffer.totalCurrency,
      }
    : fromFormValues.cardPayment

  // we want to remove any null key from the array, otherwise we don't send any metadata to the API
  const metadata =
    fromFormValues.metadata.filter((item) => item.key !== '').length > 0
      ? convertArrayToHashMap(fromFormValues.metadata)
      : null

  return {
    selectedOffers: [forOffer.id],
    passengers,
    // Our docs don't have documentation on hold and card payments.
    // We can update DuffelAPI.Input.OrderPayment when we have it documented.
    payments:
      fromFormValues.type === 'instant' && !!payment
        ? ([payment] as any)
        : undefined,
    ...(fromFormValues.selectedServices &&
      fromFormValues.selectedServices.length > 0 && {
        services: fromFormValues.selectedServices?.map((service) => ({
          id: service.id,
          quantity: service.quantity,
        })),
      }),
    type: fromFormValues.type,
    metadata,
  }
}
