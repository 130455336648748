import { capitalize, startCase } from 'lodash'
import * as React from 'react'
import { Stamp, StampProps } from '@components/Stamp'
import { captureException } from '@lib/sentry'
import { DuffelAPI } from '@lib/types'
import { getSearchType } from '@modules/air-search-v2/lib'
import { getDateLabel } from '@modules/air-search-v2/lib/get-date-label'
import { areLoyaltyAccountsApplied } from '../../lib'
import { OfferRequestTitle } from '../SearchHeader/OfferRequestTitle'
import { Text } from '@components/legacy-design-system/product/components/Text'
import { getDateString } from '@lib/date'

interface CheckoutHeaderProps {
  offer: DuffelAPI.Types.Offer
  partialOfferRequest: DuffelAPI.Types.OfferRequest
}

export const CheckoutHeader: React.FC<CheckoutHeaderProps> = ({
  offer,
  partialOfferRequest,
}) => {
  const searchType = getSearchType(partialOfferRequest.slices)
  if (searchType === null) {
    captureException(
      Error('Unknown search type - offer request has no slices'),
      {
        partialOfferRequestId: partialOfferRequest.id,
        slices: partialOfferRequest.slices,
      }
    )
  }

  const loyaltyAccountsApplied = React.useMemo(
    () => offer && areLoyaltyAccountsApplied(offer),
    [offer]
  )

  const commonStampProps: Partial<StampProps> = {
    color: 'grey',
    size: 'medium',
    borderRadius: 'full',
  }

  return (
    <div data-selector="fs-show">
      <div className="checkout-header-layout">
        <div>
          <div className="tags-container">
            <Stamp
              {...commonStampProps}
              label={capitalize(searchType!.replace('_', ' '))}
            />
            <Stamp
              {...commonStampProps}
              label={getDateLabel(partialOfferRequest)}
            />
            <Stamp
              {...commonStampProps}
              label={startCase(
                `${partialOfferRequest.passengers.length} passenger${
                  partialOfferRequest.passengers.length > 1 ? 's' : ''
                }`
              )}
            />
            <Stamp
              {...commonStampProps}
              label={capitalize(
                partialOfferRequest.fareClass ||
                  partialOfferRequest.cabinClass?.replace('_', ' ') ||
                  'Any cabin class'
              )}
            />
            {loyaltyAccountsApplied && (
              <Stamp {...commonStampProps} label="Loyalty accounts applied" />
            )}
          </div>
          {searchType && (
            <OfferRequestTitle
              searchType={searchType}
              offerRequest={partialOfferRequest}
            />
          )}
          {offer.expiresAt && (
            <Text color="grey-800" className="u-marginTop4">
              This offer will expire on{' '}
              {getDateString(offer.expiresAt, 'shortWithTime')}
            </Text>
          )}
        </div>
      </div>
      <style jsx>{`
        .checkout-header-layout {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .checkout-header-layout > div:nth-child(2) {
          text-align: right;
        }

        .tags-container {
          display: grid;
          grid-template-columns: repeat(5, auto);
          grid-column-gap: var(--space-8);
        }
      `}</style>
    </div>
  )
}
