// In general, we want to prevent users from typing in invalid slugs in the first place
// But slugify strips a trailing '-', which makes it hard for users to include dashes in slugs as they type
export const slugify = (str: string, allowTrailingDashes = false) =>
  allowTrailingDashes && str.charAt(str.length - 1) === '-'
    ? slugifyString(str.slice(0, str.length - 1)) + '-'
    : slugifyString(str)

const slugifyString = (str: string) => {
  const specialCharacters = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœøṕŕßśșțùúüûǘẃẍÿź·/_,:;'
  const replacementCharacters =
    'aaaaaaaaceeeeghiiiimnnnooooooprssstuuuuuwxyz------'
  const specialCharactersPiped = new RegExp(
    specialCharacters.split('').join('|'),
    'g'
  )
  return str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/\./g, '-') // Replace dots with -
    .replace(specialCharactersPiped, (character) =>
      replacementCharacters.charAt(specialCharacters.indexOf(character))
    ) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}
