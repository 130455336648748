import { Text } from '@components/legacy-design-system/product/components/Text'
import { format } from 'date-fns-tz'
import { getDateString, isTimezoneDate } from '@lib/date'

export const renderTimelineDateTime = (
  date: string,
  showTime = true,

  /**
   * The timezone of `date` if a designator is not included. If not provided,
   * the date is assumed to be in a timezone local to the timeline event (e.g
   * flight departure).
   */
  dateTimeZone?: string
) => {
  const dateHasTimezoneDesignator = isTimezoneDate(date)

  return (
    <div style={!showTime ? { marginTop: 'var(--space-20)' } : undefined}>
      <Text typeStyle="paragraph4" color="grey-600" fontWeight="medium">
        {getDateString(date, 'medium')}
      </Text>
      {showTime && (
        <Text typeStyle="paragraph4" color="grey-600">
          {getDateString(
            date,
            dateHasTimezoneDesignator ? 'timeOnlyWithTimezone' : 'timeOnly'
          )}
          {!dateHasTimezoneDesignator &&
            (dateTimeZone
              ? ` ${format(new Date(date), 'z', { timeZone: dateTimeZone })}`
              : ' local')}
        </Text>
      )}
    </div>
  )
}
