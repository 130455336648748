import { DuffelAPI } from '@lib/types'

export const getBeforeAndAfterSegmentsWithAICs = (
  beforeSlices: DuffelAPI.Types.OrderSlice[],
  afterSlices: DuffelAPI.Types.OrderSlice[]
) => {
  const beforeSegments = beforeSlices.flatMap((slice) =>
    slice.segments?.flatMap((segment) => segment)
  )
  const afterSegments = afterSlices.flatMap((slice) =>
    slice.segments?.flatMap((segment) => segment)
  )

  return {
    beforeSegments,
    afterSegments,
  }
}
