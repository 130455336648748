import { CreateOrderService } from '@duffel/api/types'
import { OnPayloadReadyMetadata } from '@duffel/components'
import { isDev } from '@lib/env'
import {
  DuffelAPI,
  PassengerGender,
  PassengerTitle,
  RandomlyGeneratedPassenger,
} from '@lib/types'
import { getInitialCheckoutFormPassengersValue } from './get-initial-checkout-form-passengers-value'
import { ComponentUsageMetadata } from './types'

export interface OrderMetadata {
  key: string
  value: string
}

export interface CheckoutFormValuesService {
  id: string
  quantity: number
  metadata?: ComponentUsageMetadata
}

export interface CheckoutFormValuesPassengerPassportDetails {
  countryOfIssue: string
  passportNumber: string
  expiryDate: string
}

export interface CheckoutFormValuesPassenger {
  passengerLabel: string
  responsibleForInfant: { infantId: string } | null
  id: string
  title: PassengerTitle
  givenName: string
  familyName: string
  bornOn: Date | null
  gender: PassengerGender
  passportDetails: CheckoutFormValuesPassengerPassportDetails
  loyaltyProgrammeAccounts?: DuffelAPI.Types.LoyaltyProgrammeAccount[]
  // This value is for validation only.
  age: number | null
}

export interface CheckoutFormValues {
  email: string
  phoneNumber: string
  passengers: CheckoutFormValuesPassenger[]
  paymentType: Exclude<DuffelAPI.PaymentType, 'payments'>
  selectedServices?: CreateOrderService[]
  selectedServicesMetadata?: OnPayloadReadyMetadata
  type: DuffelAPI.Inputs.Order['type']
  metadata: OrderMetadata[]
  cardPayment?: {
    type: 'card'
    amount: string
    currency: string
    cardId: string
  }
}

export const getInitialCheckoutFormValues = (
  offer: DuffelAPI.Types.Offer,
  // We need the offer request to access the passenger ages. There is a platform
  // bug which causes ages not to be included in offer passengers, see the
  // ticket below:
  //
  // https://duffel.atlassian.net/browse/SRCH-518
  //
  // @TODO: remove this parameter once the bug is fixed.
  offerRequest: DuffelAPI.Types.OfferRequest,
  randomlyGeneratedPassengers?: RandomlyGeneratedPassenger[]
): CheckoutFormValues => {
  const formValues = {
    email: isDev ? 'traveller@example.com' : '',
    phoneNumber: isDev ? '+16177562626' : '',
    passengers: getInitialCheckoutFormPassengersValue(
      offer,
      offerRequest,
      randomlyGeneratedPassengers
    ),
    paymentType: 'balance' as const,
    type: 'instant' as const,
    metadata: [
      {
        key: '',
        value: '',
      },
    ],
  }

  return formValues
}
