import * as React from 'react'
import { Text } from '@components/legacy-design-system/product/components/Text'
import { KeyValueItem } from '@components/Item/KeyValueItem'
import { Stamp } from '@components/Stamp'
import { VSpace } from '@components/VSpace'
import { getDateString } from '@lib/date'
import { DuffelAPI, PassengerGenderMap } from '@lib/types'
import { OrderPassengerFlightInformation } from '../OrderPassengerFlightInformation'
import styles from './OrderPassengerCard.module.css'

export interface OrderPassengerCardProps {
  passenger: DuffelAPI.Types.OrderPassenger
  passengerTypeIndex: number
  segments: { [segmentId: string]: DuffelAPI.Types.OrderSliceSegment }
  baggages: {
    [segmentId: string]: DuffelAPI.Types.OrderSegmentPassengerBaggage[]
  }
  seats: DuffelAPI.Types.OrderService[]
}

export const OrderPassengerCard = React.forwardRef<
  HTMLDivElement,
  OrderPassengerCardProps
>(({ passenger, passengerTypeIndex, segments, baggages, seats }, ref?) => {
  const passengerLabel =
    passenger.type === 'infant_without_seat' ? 'infant' : passenger.type
  return (
    <div className={styles['order-passenger-card']} ref={ref}>
      <VSpace space={32} className={styles['order-passenger-card__container']}>
        <div>
          <div className={styles['order-passenger-tag__container']}>
            <Stamp
              label={`${passengerLabel} ${passengerTypeIndex}`}
              size="medium"
              color="grey"
              icon="person"
            />
          </div>

          <div className={styles['order-passenger-detail__container']}>
            <KeyValueItem label="Name">
              <Text typeStyle="paragraph2">
                {passenger.title && (
                  <span className={styles['order-passenger__title']}>
                    {passenger.title}{' '}
                  </span>
                )}
                {passenger.givenName} {passenger.familyName}
              </Text>
            </KeyValueItem>
            <KeyValueItem
              label="Date of birth"
              value={getDateString(passenger.bornOn, 'short')}
            />
            <KeyValueItem
              label="Gender"
              value={PassengerGenderMap[passenger.gender]}
            />
            <KeyValueItem label="E-mail" value={passenger.email} />
            <KeyValueItem
              label="Contact number"
              value={passenger.phoneNumber}
            />
          </div>
        </div>
        <OrderPassengerFlightInformation
          segments={segments}
          baggages={baggages}
          seats={seats}
        />
      </VSpace>
    </div>
  )
})
