import { DuffelAPI } from '@lib/types'
import { makeMockAirline } from './make-mock-airline'
import { makeMockDateInThePast } from './make-mock-date'
import { makeMockOrderPassenger } from './make-mock-order-passenger'
import { makeMockOrderSlice } from './make-mock-order-slice'
import { makeMockOrderDocuments } from './make-mock-order-documents'

const getDefaultArray = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.Order[] => {
    const id = (seedId++).toString().padStart(5, '0')
    const createdAt = makeMockDateInThePast(1).toISOString()
    return [
      {
        totalCurrency: 'GBP',
        totalAmount: '237.68',
        taxCurrency: 'GBP',
        taxAmount: '36.26',
        slices: [makeMockOrderSlice()],
        services: [],
        paymentStatus: {
          awaitingPayment: false,
          paidAt: createdAt,
        },
        passengers: [makeMockOrderPassenger()],
        owner: makeMockAirline(),
        liveMode: false,
        id: `ord_${id}`,
        documents: makeMockOrderDocuments(),
        createdAt,
        syncedAt: createdAt,
        cancelledAt: null,
        bookingReference: 'ZAKX7V',
        baseCurrency: 'GBP',
        baseAmount: '201.42',
        metadata: {},
        conditions: {
          changeBeforeDeparture: null,
          refundBeforeDeparture: null,
        },
        airlineInitiatedChanges: [],
        changes: [],
        content: 'managed',
        availableActions: [],
        cancellation: null,
      },
      {
        totalCurrency: 'GBP',
        totalAmount: '685.48',
        taxCurrency: 'GBP',
        taxAmount: '104.56',
        slices: [makeMockOrderSlice()],
        services: [],
        paymentStatus: {
          awaitingPayment: false,
          paidAt: createdAt,
        },
        passengers: [makeMockOrderPassenger()],
        owner: makeMockAirline(),
        liveMode: false,
        id: `ord_${id}`,
        documents: makeMockOrderDocuments(),
        createdAt,
        syncedAt: createdAt,
        cancelledAt: null,
        bookingReference: 'I7J7SZ',
        baseCurrency: 'GBP',
        baseAmount: '580.92',
        metadata: {},
        conditions: {
          changeBeforeDeparture: null,
          refundBeforeDeparture: null,
        },
        airlineInitiatedChanges: [
          { actionTaken: null } as DuffelAPI.Types.AirlineInitiatedChange,
        ],
        changes: [],
        content: 'managed',
        availableActions: [],
        cancellation: null,
      },
      {
        totalCurrency: 'GBP',
        totalAmount: '43.41',
        taxCurrency: 'GBP',
        taxAmount: '6.62',
        slices: [makeMockOrderSlice()],
        services: [],
        paymentStatus: {
          awaitingPayment: false,
          paidAt: createdAt,
        },
        passengers: [makeMockOrderPassenger()],
        owner: makeMockAirline(),
        liveMode: false,
        id: `ord_${id}`,
        documents: makeMockOrderDocuments(),
        createdAt,
        syncedAt: createdAt,
        cancelledAt: null,
        bookingReference: '6RFN4K',
        baseCurrency: 'GBP',
        baseAmount: '36.79',
        metadata: {},
        conditions: {
          changeBeforeDeparture: null,
          refundBeforeDeparture: null,
        },
        airlineInitiatedChanges: [
          { actionTaken: null } as DuffelAPI.Types.AirlineInitiatedChange,
        ],
        changes: [],
        content: 'managed',
        availableActions: [],
        cancellation: null,
      },
      {
        totalCurrency: 'GBP',
        totalAmount: '1874.06',
        taxCurrency: 'GBP',
        taxAmount: '288.06',
        slices: [makeMockOrderSlice()],
        services: [],
        paymentStatus: {
          awaitingPayment: false,
          paidAt: createdAt,
        },
        passengers: [makeMockOrderPassenger()],
        owner: makeMockAirline(),
        liveMode: false,
        id: `ord_${id}`,
        documents: makeMockOrderDocuments(),
        createdAt,
        syncedAt: createdAt,
        cancelledAt: null,
        bookingReference: 'V2WUWQ',
        baseCurrency: 'GBP',
        baseAmount: '1586.00',
        metadata: {},
        conditions: {
          changeBeforeDeparture: null,
          refundBeforeDeparture: null,
        },
        airlineInitiatedChanges: [
          { actionTaken: 'accepted' } as DuffelAPI.Types.AirlineInitiatedChange,
        ],
        changes: [],
        content: 'managed',
        availableActions: [],
        cancellation: null,
      },
    ]
  }
})()

export const makeMockOrders = (
  extendArray: DuffelAPI.Types.Order[] = []
): DuffelAPI.Types.Order[] =>
  new Array(...[...getDefaultArray(), ...extendArray])
