import { Formik } from 'formik'
import { Form } from '@components/Form'

export const WithMockFormik: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <Formik
    initialValues={{}}
    onSubmit={() => {
      /* do nothing */
    }}
  >
    <Form id="mock-formik-form">{children}</Form>
  </Formik>
)
