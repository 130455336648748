import { DuffelAPI } from '@lib/types'
import { makeMockAirport } from './make-mock-airport'
import { makeMockOrderSlice } from './make-mock-order-slice'

const getDefaultObject = (() => {
  let seedId = 10
  return (): DuffelAPI.Types.OrderChangeRequestOfferSlices => {
    const id = (seedId++).toString().padStart(5, '0')
    return {
      remove: [makeMockOrderSlice({ id: 'sli_00001' })],
      add: [
        makeMockOrderSlice({
          id: `sli_${id}`,
          origin: makeMockAirport({ iataCode: 'SEA' }),
          destination: makeMockAirport({ iataCode: 'MIA' }),
        }),
      ],
    }
  }
})()

export const makeMockOrderChangeRequestOfferSlices = (
  extendDefault?: Partial<DuffelAPI.Types.OrderChangeRequestOfferSlices>
): DuffelAPI.Types.OrderChangeRequestOfferSlices =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
