import { DuffelProxy } from '@lib/types'
import { SourceAirlineWithSourceId } from '@modules/air-search-v2'
import { DuffelSources } from './DuffelSources'

/**
 * Returns a `SourceAirline` object that matches the iataCode passed in
 * Will return `false` if no SourceAirlines match the iataCode
 */
export const getAirlineFromIataCode = (
  iataCode: DuffelProxy.Types.SourceAirline['iataCode']
): SourceAirlineWithSourceId | undefined => {
  let match
  for (const [sourceId, sourceData] of Object.entries(DuffelSources)) {
    sourceData.featuredAirlines.forEach((airline) => {
      if (airline.iataCode === iataCode) {
        match = { ...airline, sourceId }
      }
    })
  }

  return match
}
