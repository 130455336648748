import { UrlObject } from 'url'
import { DuffelProxy } from '@lib/types'
import { fallBackPathObject } from './common'
import { PathNameObject } from './types'

export const orgPathsMap = {
  organisationSettingsIndex: '/[org]/settings',
  teamIndex: '/[org]/settings/team',
  teamInvite: '/[org]/settings/team/invite',
  organisationPreferencesIndex: '/[org]/settings/preferences',
  organisationContactEmailsIndex: '/[org]/settings/contact-emails',
  organisationBalancePreferencesIndex: '/[org]/settings/balance',
  organisationWalletPreferencesIndex: '/[org]/settings/wallet',
  organisationUsageAndBillingIndex: '/[org]/settings/usage-and-billing',
  organisationBusinessIndex: '/[org]/settings/business',
  organisationSecurityIndex: '/[org]/settings/security',
  airlinesIndex: '/[org]/airlines',
  airlinesShow: '/[org]/airlines/[airlineId]',
  activationIndex: '/[org]/activation',
  activationEmail: '/[org]/activation/email',
  activationPlan: '/[org]/activation/plan',
  activationBusiness: '/[org]/activation/business',
  activationCard: '/[org]/activation/card',
  activationReview: '/[org]/activation/review',
  activationDone: '/[org]/activation/done',
  email: '/[org]/email',
  linksIndex: '/[org]/links',
} as const

export type OrgPaths = keyof typeof orgPathsMap

export type OrgPathArraySignature = (
  org: string | undefined
) => [UrlObject, string]
export type OrgPathObjectSignature = (org: string | undefined) => {
  href: PathNameObject
  as: string
}
export type OrgFactory = (pathKey: OrgPaths) => {
  obj: OrgPathObjectSignature
  arr: OrgPathArraySignature
}

export const orgPathHelpersFactory: OrgFactory = (path: OrgPaths) => {
  const pathname = orgPathsMap[path]
  const objectHelper: OrgPathObjectSignature = (org) => {
    if (!org) return fallBackPathObject
    return {
      href: {
        pathname,
        query: { org },
      },
      as: pathname.replace('[org]', org),
    }
  }

  return {
    obj: objectHelper,
    arr: (org) => {
      const { href, as } = objectHelper(org)
      return [href, as] as [UrlObject, string]
    },
  }
}

export const {
  arr: organisationSettingsIndexPathArray,
  obj: organisationSettingsIndexPathObject,
} = orgPathHelpersFactory('organisationSettingsIndex')

export const { arr: teamIndexPathArray, obj: teamIndexPathObject } =
  orgPathHelpersFactory('teamIndex')

export const { arr: teamInvitePathArray, obj: teamInvitePathObject } =
  orgPathHelpersFactory('teamInvite')

export const {
  arr: organisationPreferencesIndexPathArray,
  obj: organisationPreferencesIndexPathObject,
} = orgPathHelpersFactory('organisationPreferencesIndex')

export const {
  arr: organisationContactEmailsIndexPathArray,
  obj: organisationContactEmailsIndexPathObject,
} = orgPathHelpersFactory('organisationContactEmailsIndex')

export const {
  arr: organisationBalancePreferencesIndexPathArray,
  obj: organisationBalancePreferencesIndexPathObject,
} = orgPathHelpersFactory('organisationBalancePreferencesIndex')

export const {
  arr: organisationWalletPreferencesIndexPathArray,
  obj: organisationWalletPreferencesIndexPathObject,
} = orgPathHelpersFactory('organisationWalletPreferencesIndex')

export const {
  arr: organisationUsageAndBillingIndexPathArray,
  obj: organisationUsageAndBillingIndexPathObject,
} = orgPathHelpersFactory('organisationUsageAndBillingIndex')

export const {
  arr: organisationBusinessIndexPathArray,
  obj: organisationBusinessIndexPathObject,
} = orgPathHelpersFactory('organisationBusinessIndex')

export const {
  arr: organisationSecurityIndexPathArray,
  obj: organisationSecurityIndexPathObject,
} = orgPathHelpersFactory('organisationSecurityIndex')

export const { arr: linksIndexPathArray, obj: linksIndexPathObject } =
  orgPathHelpersFactory('linksIndex')

export const { arr: emailPathArray, obj: emailPathObject } =
  orgPathHelpersFactory('email')

export const { arr: activationIndexPathArray, obj: activationIndexPathObject } =
  orgPathHelpersFactory('activationIndex')

export const { arr: activationPlanPathArray, obj: activationPlanPathObject } =
  orgPathHelpersFactory('activationPlan')
export const {
  arr: activationBusinessPathArray,
  obj: activationBusinessPathObject,
} = orgPathHelpersFactory('activationBusiness')
export const { arr: activationCardPathArray, obj: activationCardPathObject } =
  orgPathHelpersFactory('activationCard')
export const {
  arr: activationReviewPathArray,
  obj: activationReviewPathObject,
} = orgPathHelpersFactory('activationReview')

export const { arr: activationDonePathArray, obj: activationDonePathObject } =
  orgPathHelpersFactory('activationDone')

export const { arr: airlinesIndexPathArray, obj: airlinesIndexPathObject } =
  orgPathHelpersFactory('airlinesIndex')

export const airlinesShowPathObject = (
  org: string | undefined,
  airlineId: string
) => {
  if (!org) return fallBackPathObject
  return {
    href: {
      pathname: orgPathsMap['airlinesShow'],
      query: { org, airlineId },
    },
    as: `/${org}/airlines/${airlineId}`,
  }
}

export type ActivationLink = {
  href: PathNameObject | string
  as?: string
} | null
export const getActivationLink = (
  currentOrganisation: DuffelProxy.Types.SelfOrganisation | null,
  isEmailConfirmed?: boolean | string | null
): ActivationLink => {
  if (!currentOrganisation) {
    return null
  }
  if (isEmailConfirmed) {
    return currentOrganisation?.verificationFlow === 'stripe_connect'
      ? { href: '/stripe-verification-redirect' }
      : activationIndexPathObject(currentOrganisation?.slug)
  }
  return { ...emailPathObject(currentOrganisation?.slug) }
}
