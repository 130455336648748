import { Formik } from 'formik'
import { useRouter } from 'next/router'
import axios from 'axios'
import * as React from 'react'
import { Text } from '@components/legacy-design-system/product/components/Text'
import { Anchor } from '@components/Anchor'
import {
  Form,
  FormikFileUpload,
  FormikSelect,
  FormikTextArea,
  AttachmentProps,
} from '@components/Form'
import { VSpace } from '@components/VSpace'
import { FormikSubmitButton } from '@components/Form/FormikSubmitButton'
import { FormikSupportContactEmail } from '@components/Form/FormikSupportContactEmail'
import { trackEvent } from '@lib/tracking'
import {
  CONTACT_SUPPORT_CATEGORIES,
  ContactSupportCategory,
  SUPPORT_CATEGORY_COPY_MAP,
} from '@lib/zendesk'
import { DuffelAPI } from '@lib/types'
import {
  getSupportFormInitialValues,
  getSupportFormValidationSchema,
  SupportFormValues,
} from './lib'

export interface SupportContactFormProps {
  onSubmit: (values: SupportFormValues) => void
  className?: string
  initialCategory?: ContactSupportCategory
  initialMessage?: string
  userEmail?: DuffelAPI.Types.User['email']
  organisationContactEmails?: DuffelAPI.Types.Organisation['contactEmails']
}

export const SupportContactForm: React.FC<SupportContactFormProps> = ({
  onSubmit,
  className,
  initialMessage,
  initialCategory,
  userEmail,
  organisationContactEmails,
}) => {
  const [isUploading, setIsUploading] = React.useState(false)
  const router = useRouter()

  const requestHandler = async (
    attachment: AttachmentProps,
    method: 'POST' | 'DELETE'
  ) => {
    setIsUploading(true)
    trackEvent('dashboard_support_upload_attachment_clicked', {
      event_type: 'interaction',
      file_type: attachment.type,
    })
    try {
      const { data } = await axios(`/api/zendesk/uploads`, {
        method,
        params: {
          filename: attachment.filename,
        },
        headers: {
          'Content-Type': 'application/json',
        },
        data: { data: attachment.binary },
      })
      if (!data || data.error) {
        setIsUploading(false)
        return {
          filename: attachment.filename,
          binary: attachment.binary,
          type: attachment.type,
          id: '',
          error: true,
        }
      }
      setIsUploading(false)
      return {
        filename: data.upload.attachment.file_name,
        type: attachment.type,
        id: data.upload.token,
        error: false,
      }
    } catch (err: any) {
      setIsUploading(false)
      return {
        filename: attachment.filename,
        type: attachment.type,
        binary: attachment.binary,
        id: '',
        error: true,
      }
    }
  }

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={getSupportFormInitialValues(
          router?.route,
          userEmail,
          organisationContactEmails,
          initialCategory,
          initialMessage
        )}
        validateOnBlur
        validationSchema={getSupportFormValidationSchema()}
      >
        {({ handleSubmit, values }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <VSpace space={24} className={className}>
                <Text
                  typeStyle="paragraph2"
                  color="grey-600"
                  data-selector="fs-show"
                >
                  Drop us a message about your issue and we'll be in touch, via
                  email, as soon as possible.
                </Text>
                <VSpace space={8}>
                  <FormikSelect
                    label={"What's this about?"}
                    name="category"
                    optionalField
                  >
                    {CONTACT_SUPPORT_CATEGORIES.map((supportCategory) => {
                      if (
                        SUPPORT_CATEGORY_COPY_MAP[supportCategory]
                          .formSelectOptionLabel
                      ) {
                        return (
                          <option value={supportCategory} key={supportCategory}>
                            {
                              SUPPORT_CATEGORY_COPY_MAP[supportCategory]
                                .formSelectOptionLabel
                            }
                          </option>
                        )
                      }
                      return null
                    })}
                  </FormikSelect>
                  {SUPPORT_CATEGORY_COPY_MAP[values.category].helpCenterLink &&
                    SUPPORT_CATEGORY_COPY_MAP[values.category]
                      .helpCenterTopic && (
                      <div className="support-contact__category">
                        <Text
                          typeStyle="paragraph2"
                          color="blue-900"
                          data-selector="fs-show"
                        >
                          Have you seen our{' '}
                          <Anchor
                            href={
                              SUPPORT_CATEGORY_COPY_MAP[values.category]
                                .helpCenterLink
                            }
                          >
                            Help Centre articles
                          </Anchor>{' '}
                          on{' '}
                          {
                            SUPPORT_CATEGORY_COPY_MAP[values.category]
                              .helpCenterTopic
                          }
                          ?
                        </Text>
                      </div>
                    )}
                </VSpace>
                <FormikSupportContactEmail
                  name="email"
                  label="Your email"
                  userEmail={userEmail}
                  orgContactEmails={organisationContactEmails}
                />
                <FormikTextArea
                  name="message"
                  label="Your message"
                  placeholder="How can we help?"
                  optionalField
                />
                <FormikFileUpload
                  name="uploads"
                  label="Add any files that may be useful."
                  multiple
                  requestHandler={requestHandler}
                  uploadSizeLimit={5000000}
                />
                <FormikSubmitButton
                  text="Submit"
                  disabled={!values.email || !values.message || isUploading}
                />
              </VSpace>
            </Form>
          )
        }}
      </Formik>
      <style jsx>{`
        .support-contact__category {
          background-color: var(--grey-100);
          border-radius: var(--border-radius-4);
          padding: 12px var(--space-16);
        }
      `}</style>
    </>
  )
}
