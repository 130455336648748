import { DuffelAPI } from '@lib/types'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.OfferPassenger => ({
    age: 28,
    familyName: 'Tony',
    givenName: 'Stark',
    id: `pas_${(seedId++).toString().padStart(5, '0')}`,
    type: 'adult',
    fareType: 'student',
  })
})()

export const makeMockOfferPassenger = (
  extendDefault?: Partial<DuffelAPI.Types.OfferPassenger>
): DuffelAPI.Types.OfferPassenger =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
