export type UserRole =
  | 'roles/user/viewer'
  | 'roles/user/agent'
  | 'roles/user/developer'
  | 'roles/user/admin'
  | 'roles/user/owner'
  | 'roles/user/personal'
  | 'roles/duffel/travel_ops'

export type TokenRole = 'roles/api/read_write' | 'roles/api/read_only'
export type Roles =
  | TokenRole
  | UserRole
  | 'roles/duffel/travel_ops'
  | 'roles/duffel/admin'

export interface UserRoleDetails {
  name: string
  info: string
}

export const userRolesMap: Record<
  Exclude<UserRole, 'roles/user/personal' | 'roles/duffel/travel_ops'>,
  UserRoleDetails
> = {
  'roles/user/viewer': {
    name: 'Viewer',
    info: 'Viewers will only be able to read resources.',
  },
  'roles/user/agent': {
    name: 'Agent',
    info: 'Agents will only be able to read, create and cancel orders.',
  },
  'roles/user/developer': {
    name: 'Developer',
    info: 'Developers have full access to orders as well as API access tokens.',
  },
  'roles/user/admin': {
    name: 'Administrator',
    info: 'Administrators have full read and write privileges.',
  },
  'roles/user/owner': {
    name: 'Owner',
    info: 'The owner of this organisation.',
  },
}

export const assignableRoles: UserRole[] = [
  'roles/user/viewer',
  'roles/user/agent',
  'roles/user/developer',
  'roles/user/admin',
]
