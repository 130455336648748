import { FilterableWebhookEventType } from '@lib/types'
import { Range } from '@components/RangeSlider/RangeSlider'

export const filterTypes = [
  'passengerName',
  'departingAt',
  'arrivingAt',
  'departingAtTime',
  'arrivingAtTime',
  'createdAt',
  'originId',
  'destinationId',
  'airlineId',
  'maxConnections',
  'offerSorting',
  'orderChangeSorting',
  'webhookEventType',
] as const

export type FilterType =
  | (typeof filterTypes)[number]
  | { type: 'options'; name: string; options: string[] }

// `FilterValue` is the type of the valid filter and is used for storing and displaying active filters
export type FilterValue =
  | { type: Extract<FilterType, 'passengerName'>; value: string }
  | {
      type: Extract<FilterType, 'departingAt' | 'arrivingAt' | 'createdAt'>
      value: DateFilterCondition
    }
  | {
      type: Extract<FilterType, 'departingAtTime' | 'arrivingAtTime'>
      value: Range
    }
  | { type: Extract<FilterType, 'originId' | 'destinationId'>; value: string }
  | { type: Extract<FilterType, 'airlineId'>; value: string }
  | {
      type: { type: 'options'; name: string; options: string[] }
      value: string
    }
  | { type: Extract<FilterType, 'maxConnections'>; value: MaxConnections }
  | { type: Extract<FilterType, 'offerSorting'>; value: OfferSorting }
  | {
      type: Extract<FilterType, 'orderChangeSorting'>
      value: OrderChangeSorting
    }
  | {
      type: Extract<FilterType, 'webhookEventType'>
      value: FilterableWebhookEventType
    }

// `PendingFilterValue` is the pending value used for representing the intermediate value when the filter
// is being added. The value might not be valid for applying filter. The `validateFilterValue` in helpers.ts
// can be used to validate and transform this to the FilterValue type.
export type PendingFilterValue =
  | { type: Extract<FilterType, 'passengerName'>; value: string }
  | {
      type: Extract<FilterType, 'departingAt' | 'arrivingAt' | 'createdAt'>
      value: PendingDateFilterCondition
    }
  | {
      type: Extract<FilterType, 'departingAtTime' | 'arrivingAtTime'>
      value: Range
    }
  | { type: Extract<FilterType, 'originId' | 'destinationId'>; value: string }
  | { type: Extract<FilterType, 'airlineId'>; value: string }
  | {
      type: { type: 'options'; name: string; options: string[] }
      value: string
    }
  | { type: Extract<FilterType, 'maxConnections'>; value: MaxConnections }
  | { type: Extract<FilterType, 'offerSorting'>; value: OfferSorting }
  | {
      type: Extract<FilterType, 'orderChangeSorting'>
      value: OrderChangeSorting
    }
  | {
      type: Extract<FilterType, 'webhookEventType'>
      value: FilterableWebhookEventType
    }

export type DateFilterCondition =
  | { condition: 'before'; date: Date }
  | { condition: 'after'; date: Date }
  | { condition: 'between'; date1: Date; date2: Date }

export type PendingDateFilterCondition =
  | { condition: 'before'; date?: Date }
  | { condition: 'after'; date?: Date }
  | { condition: 'between'; date1?: Date; date2?: Date }

export const maxConnectionsOptions = [
  'Direct only',
  'One stop or fewer',
  'Two stops or fewer',
] as const
export type MaxConnections = (typeof maxConnectionsOptions)[number]

export const offerSortingOptions = [
  'Least expensive',
  'Most expensive',
  'Shortest duration',
  'Longest duration',
] as const
export type OfferSorting = (typeof offerSortingOptions)[number]

export const orderChangeSortingOptions = [
  'Least expensive change',
  'Most expensive change',
  'Shortest duration',
  'Longest duration',
] as const
export type OrderChangeSorting = (typeof orderChangeSortingOptions)[number]
