import { captureException } from '@lib/sentry'
import { AnalyticsError } from './AnalyticsError'
import {
  areCohortPropertiesSetOnCookie,
  getCohortPropertiesFromCookie,
  setCohortPropertiesOnCookie,
} from './cohort-properties'
import { getAnalyticsObject } from './get-analytics-object'
import { getUserPropertiesFromCookie } from './get-user-properties-from-cookie'
import { isIdentifiableEvent } from './is-identifiable-event'
import { isSessionStartEvent } from './is-session-start-event'
import { IdentifiableTraits } from './map-identifiable-traits'
import { getSessionPropertiesFromCookie } from './session-tracking'
import { TrackingEvent, TrackingEventMetadataMap } from './types'

/**
 * `trackEvent` lets you record the actions users perform.
 * The endpoint is responsible for reaching out to segment and identifying users based on the event types
 */
export const trackEvent = async <T_Event extends TrackingEvent>(
  event: T_Event,
  properties: TrackingEventMetadataMap[T_Event],
  identifiableTraits?: IdentifiableTraits
) => {
  try {
    if (!event || !properties)
      throw new AnalyticsError(
        'A call to track event is missing a required value.',
        {
          event,
          properties,
        }
      )

    const userProperties = getUserPropertiesFromCookie()
    const cohortProperties = getCohortPropertiesFromCookie()
    const sessionProperties = getSessionPropertiesFromCookie()

    const eventProperties = {
      ...userProperties,
      ...cohortProperties,
      ...sessionProperties,
      ...properties,
    }

    const analytics = getAnalyticsObject()

    // Analytics might no be able due to adblocks so we don't want to run the rest of the operation
    if (!analytics) {
      return false
    }

    if (isIdentifiableEvent(event) && eventProperties.user_id) {
      if (!identifiableTraits)
        throw new AnalyticsError(
          `Identifiable traits were undefined for "${event}" event.`,
          { event, properties }
        )

      analytics.identify(eventProperties.user_id, {
        ...eventProperties,
        ...identifiableTraits,
      })

      if (isSessionStartEvent(event) || !areCohortPropertiesSetOnCookie()) {
        setCohortPropertiesOnCookie(
          identifiableTraits.email.endsWith('@duffel.com')
        )
      }
    }

    analytics.track(event, eventProperties)
  } catch (err: any) {
    captureException(err, {
      analytics: true,
      event,
      properties,
    })
  }
}

/**
 * We only use this function during the sign-up of the user
 * It will define the alias of the user to be their `userId`
 * @summary Setting an alias should only be used during sign-up
 */
export const setUserAlias = (userId: string) => {
  const analytics = getAnalyticsObject()

  if (!analytics) return
  analytics.alias(userId)
}
