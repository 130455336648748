import * as React from 'react'
import classNames from 'classnames'
import { RadioGroupProps } from '@components/RadioGroup'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { HSpace } from '@components/HSpace'
import { RadioButton } from '@components/RadioButton'
import { hasSameDate } from '@lib/date'
import { getRelativeDateString } from '@lib/date'
import { DuffelAPI } from '@lib/types'

export interface PaymentMethodSelectProps {
  /**
   * Payment requirements
   */
  paymentRequirements: DuffelAPI.Types.PaymentRequirements
  /**
   * Payment options
   */
  options: RadioGroupProps<DuffelAPI.Inputs.Order['type']>['options']
  /**
   * Current payment option selected
   */
  currentPaymentSelected: string
  /**
   * Click event which will handle selecting an option
   */
  handlePaymentMethodClick: (value: string) => void
}

type PaymentTypeDescriptionProps = {
  /**
   * Payment option copy
   */
  copy: string
  /**
   * Optional property which allows you to pass a ReactNode
   * @example
   * <PaymentTypeDescription copy="Hold price & space on this trip and pay" html="a late date" />
   */
  html?: string
}

const PaymentTypeDescription: React.FC<PaymentTypeDescriptionProps> = ({
  copy,
  html,
}) => (
  <>
    {copy} <strong style={{ color: 'var(--grey-900)' }}>{html}</strong>
  </>
)

const PAY_LATER_LABEL_TEXT = 'Hold order'

const getPaymentOptions = (
  paymentRequiredByDate: Date | null,
  priceGuaranteeExpiresAtDate: Date | null,
  options: PaymentMethodSelectProps['options']
) => {
  const optionsCopy = [...options]
  if (paymentRequiredByDate && priceGuaranteeExpiresAtDate) {
    if (hasSameDate(paymentRequiredByDate, priceGuaranteeExpiresAtDate)) {
      const timestamp = getRelativeDateString(paymentRequiredByDate) as string
      optionsCopy[1] = {
        ...optionsCopy[1],
        label: PAY_LATER_LABEL_TEXT,
        description: (
          <PaymentTypeDescription
            copy="Hold price & space on this trip and pay"
            html={timestamp}
          />
        ),
      }
    } else {
      optionsCopy[1] = {
        ...optionsCopy[1],
        label: PAY_LATER_LABEL_TEXT,
        description: (
          <PaymentTypeDescription
            copy={'Hold price and space and pay at a later date'}
          />
        ),
      }
    }
  } else if (paymentRequiredByDate && !priceGuaranteeExpiresAtDate) {
    const timestamp = getRelativeDateString(paymentRequiredByDate) as string
    optionsCopy[1] = {
      ...optionsCopy[1],
      label: PAY_LATER_LABEL_TEXT,
      description: (
        <PaymentTypeDescription
          copy={'Hold space on this trip and pay'}
          html={timestamp}
        />
      ),
    }
  } else if (!paymentRequiredByDate && priceGuaranteeExpiresAtDate) {
    const timestamp = getRelativeDateString(
      priceGuaranteeExpiresAtDate
    ) as string
    optionsCopy[1] = {
      ...optionsCopy[1],
      label: PAY_LATER_LABEL_TEXT,
      description: (
        <PaymentTypeDescription
          copy={'Hold price on this trip and pay'}
          html={timestamp}
        />
      ),
    }
  }

  return optionsCopy
}

export const PaymentMethodSelect = React.forwardRef<
  HTMLDivElement,
  PaymentMethodSelectProps
>(
  (
    {
      options,
      paymentRequirements,
      currentPaymentSelected,
      handlePaymentMethodClick,
    },
    ref
  ) => {
    const { paymentRequiredBy, priceGuaranteeExpiresAt } = paymentRequirements

    const paymentRequiredByDate =
      (paymentRequiredBy && new Date(paymentRequiredBy)) || null
    const priceGuaranteeExpiresAtDate =
      (priceGuaranteeExpiresAt && new Date(priceGuaranteeExpiresAt)) || null

    const paymentOptions = getPaymentOptions(
      paymentRequiredByDate,
      priceGuaranteeExpiresAtDate,
      options
    )

    return (
      <div ref={ref}>
        <VSpace space={32}>
          <Text color="grey-600" className="payment-options__copy">
            Decide whether you want to pay for your trip now in its entirety, or
            whether you'd like to put a hold on the order, and pay at a later
            date. Be aware that you cannot currently select seats or baggage
            when holding an order.
          </Text>
          <HSpace space={16}>
            {paymentOptions.map((paymentOption) => (
              <RadioButton
                key={paymentOption.value}
                {...paymentOption}
                className={classNames('payment-option', {
                  'payment-option--checked':
                    currentPaymentSelected === paymentOption.value,
                })}
                checked={currentPaymentSelected === paymentOption.value}
                color="green"
                fill
                onChange={() => handlePaymentMethodClick(paymentOption.value)}
              />
            ))}
          </HSpace>
        </VSpace>
        <hr className="divider" />
        <style jsx>{`
          :global(.payment-option) {
            display: block;
            padding: var(--space-32);
            background-color: var(--grey-100);
            border-radius: var(--border-radius-4);
          }

          :global(.payment-option:hover) {
            background-color: var(--green-100);
          }

          :global(.payment-option.payment-option--checked) {
            background-color: var(--green-100);
          }

          :global(.payment-options__copy) {
            max-width: 640px;
          }
          :global(.payment-options > div) {
            flex: 1;
          }
          :global(.payment-options > div .ff-radio-group--option) {
            margin-right: 16px;
          }
          :global(.payment-options > div:last-child .ff-radio-group--option) {
            margin-right: 0;
          }
          .divider {
            margin: var(--space-40) 0 0 !important;
            height: 1px;
            border: none;
            background-color: var(--grey-200);
          }
        `}</style>
      </div>
    )
  }
)
