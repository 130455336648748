import { DuffelAPI } from '@lib/types'
import { SupportOrderManagementAction } from '@modules/air-order/components/OrderSupport/types'
import { isAdditionalBaggageAvailable } from './is-additional-baggage-available'

export const isActionAvailableProgrammatically = (
  orderManagementAction: SupportOrderManagementAction,
  order: DuffelAPI.Types.Order,
  availableServices?: DuffelAPI.Types.OrderAvailableService[] | null
): orderManagementAction is Extract<
  SupportOrderManagementAction,
  'cancel_order' | 'change_order' | 'add_bags'
> => {
  // Currently, only flight changes and order cancellations are available via
  // the API.
  //
  // @TODO: add cases as the functionality becomes available.
  switch (orderManagementAction) {
    case 'cancel_order': {
      return order.availableActions.includes('cancel')
    }
    case 'change_order': {
      return order.availableActions.includes('change')
    }
    case 'add_bags': {
      return isAdditionalBaggageAvailable(order, availableServices)
    }
    case 'add_seats':
    case 'name_correction':
    case 'dashboard_other': {
      return false
    }
  }
}
