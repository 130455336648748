import * as React from 'react'
import {
  OrderInformationBox,
  OrderInformationBoxContent,
  OrderInformationBoxDivider,
} from '@components/OrderInformationBox'
import { VSpace } from '@components/VSpace'
import { DuffelAPI } from '@lib/types'
import { PayHoldOrderActions } from '../PayHoldOrderActions'
import { OrderOnHoldInformationItem } from './OrderOnHoldInformationItem'
import { OrderOnHoldTimeline } from './OrderOnHoldTimeline'

export interface OrderOnHoldInformationProps {
  order: DuffelAPI.Types.Order

  // overriding today date for testing
  todayDateOverride?: string
  componentClientKey?: string
  isOrderManagementEnabled?: boolean
}

export const OrderOnHoldInformation = React.forwardRef<
  HTMLDivElement,
  OrderOnHoldInformationProps
>(
  (
    { order, todayDateOverride, componentClientKey, isOrderManagementEnabled },
    ref?
  ) => (
    <OrderInformationBox
      stampText="Order held"
      stampColor="yellow"
      buttons={
        componentClientKey && isOrderManagementEnabled ? (
          <PayHoldOrderActions
            order={order}
            componentClientKey={componentClientKey}
          />
        ) : null
      }
      ref={ref}
    >
      <OrderInformationBoxContent>
        <VSpace space={8}>
          {order.paymentStatus.priceGuaranteeExpiresAt && (
            <OrderOnHoldInformationItem
              date={order.paymentStatus.priceGuaranteeExpiresAt}
              holdType="The price guarantee"
              todayDateOverride={todayDateOverride}
            />
          )}
          {order.paymentStatus.paymentRequiredBy && (
            <OrderOnHoldInformationItem
              date={order.paymentStatus.paymentRequiredBy}
              holdType="Space"
              todayDateOverride={todayDateOverride}
            />
          )}
        </VSpace>
        {order.paymentStatus.priceGuaranteeExpiresAt &&
          order.paymentStatus.paymentRequiredBy && (
            <>
              <OrderInformationBoxDivider />
              <OrderOnHoldTimeline order={order} />
            </>
          )}
      </OrderInformationBoxContent>
    </OrderInformationBox>
  )
)
