import { TravelDetails } from '@lib/types'

export const getChangedProperties = (
  segmentsWithADiff: string[],
  beforeSegmentsForDiff: TravelDetails<'diff'>[],
  afterSegmentsForDiff: TravelDetails<'diff'>[]
) =>
  segmentsWithADiff.map((segmentWithADiff) => {
    const matchedBeforeSegment = beforeSegmentsForDiff.find(
      (beforeSegment) => beforeSegment.originDestination === segmentWithADiff
    )

    const matchedAfterSegment = afterSegmentsForDiff.find(
      (afterSegment) => afterSegment.originDestination === segmentWithADiff
    )

    return {
      originDestination: segmentWithADiff,
      matchedBeforeSegment,
      matchedAfterSegment,
    }
  })
