import { InvoiceCell, InvoiceRow } from '@components/Invoice'
import { CreateOrderService } from '@duffel/api/types'
import { WithServiceInformation } from '@duffel/components'
import * as React from 'react'

function getServiceLabel(service: WithServiceInformation<CreateOrderService>) {
  switch (service.serviceInformation.type) {
    case 'carry_on':
      return 'Additional carry-on baggage'
    case 'checked':
      return 'Additional checked baggage'
    case 'seat':
      return 'Seat selection'
    default:
      return 'Cancel for any reason'
  }
}

const CheckoutPaymentSummaryService: React.FC<{
  service: WithServiceInformation<CreateOrderService>
}> = ({ service }) => {
  if (service.quantity === 0) return null

  const quantity = service.quantity === 1 ? '' : `${service.quantity} x `

  return (
    <InvoiceRow>
      <InvoiceCell grow>
        {quantity} {getServiceLabel(service)}
      </InvoiceCell>
      <InvoiceCell textAlign="right">
        {(
          parseFloat(service.serviceInformation.total_amount) * service.quantity
        ).toFixed(2)}
      </InvoiceCell>
    </InvoiceRow>
  )
}

export const CheckoutPaymentSummaryServices: React.FC<{
  services: WithServiceInformation<CreateOrderService>[]
}> = ({ services }) => {
  return (
    <>
      {services.map((service) => (
        <CheckoutPaymentSummaryService key={service.id} service={service} />
      ))}
    </>
  )
}
