import { utcToZonedTime } from 'date-fns-tz'
import { DuffelAPI } from '@lib/types'
import { getNextDepartureDateInUTC } from '@modules/air-order/helpers/get-next-departure-date-in-utc'

export const getZendeskTicketFirstFlightDate = (
  order: DuffelAPI.Types.Order
): Date | null => {
  const nextDepartureDate = getNextDepartureDateInUTC(order)

  if (nextDepartureDate) {
    // Convert to London time as that's where Travel Ops are based
    const nextDepartureDateInLondonTime = utcToZonedTime(
      nextDepartureDate,
      'Europe/London'
    )

    return nextDepartureDateInLondonTime
  }

  return null
}
