export const SplitFormFields = ({ children }) => (
  <>
    <div className="split-form-fields">{children}</div>
    <style jsx>{`
      .split-form-fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 16px;
        margin-bottom: 24px;
      }

      .split-form-fields:last-of-type {
        margin-bottom: 0px;
      }
    `}</style>
  </>
)
