// https://www.figma.com/file/0YkFzIjNQvTUXnygsdqy87/%5BNEW%5D--WIP-Foundations?node-id=1109%3A21

export const OPACITIES = {
  'opacity-20': '0.2' as const,
  'opacity-40': '0.4' as const,
  'opacity-60': '0.6' as const,
  'opacity-80': '0.8' as const,
  'opacity-100': '1' as const,
}

type OpacitiesMapType = typeof OPACITIES
export type Opacity = keyof OpacitiesMapType

export type OpacityValue = OpacitiesMapType[Opacity]
