import * as React from 'react'
import styles from './PassengerSelection.module.css'
import { usePassengersContext } from './lib/usePassengersContext'
import { Segment } from './Segment'
import classNames from 'classnames'

export interface Passenger {
  id: string
  name?: string | null
}

export interface PassengerSelectionProps {
  renderPassengerSelectionDetails: (
    passengerId: string,
    segmentId: string
  ) => React.ReactNode
}

/**
 * The passenger selection component for seat selection and additional baggage
 */
export const PassengerSelection: React.FC<PassengerSelectionProps> = ({
  renderPassengerSelectionDetails,
}) => {
  const passengersContext = usePassengersContext()
  if (!passengersContext) {
    throw new Error(
      'PassengerSelection can only be used within PassengersProvider'
    )
  }
  return (
    <div className={styles['passenger-selection']}>
      <ul className={styles['passenger-selection__segments']}>
        {passengersContext.segments.map((segment) => (
          <li
            key={segment.id}
            className={styles['passenger-selection-segment']}
          >
            {Object.keys(segment).length > 0 && <Segment segment={segment} />}
            {passengersContext.passengers.map((passenger, passengerIndex) => (
              <button
                data-testid={`passenger-${passenger.id}`}
                type="button"
                key={passenger.id}
                onClick={() =>
                  passengersContext.dispatch({
                    type: 'selectPassenger',
                    passengerId: passenger.id,
                    segmentId: segment.id,
                  })
                }
                className={classNames(
                  styles['passenger-selection-passenger'],
                  passengersContext.selectedSegment.id === segment.id &&
                    passengersContext.selectedPassenger.id === passenger.id &&
                    styles['passenger-selection-passenger--selected']
                )}
              >
                <span
                  className={
                    styles['passenger-selection-passenger__identifier']
                  }
                >
                  {passenger.name || `Passenger ${passengerIndex + 1}`}
                </span>

                {renderPassengerSelectionDetails(passenger.id, segment.id)}
              </button>
            ))}
          </li>
        ))}
      </ul>
    </div>
  )
}
