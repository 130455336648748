import { toUTCDate } from './to-utc-date'
import { getDayDiff } from './get-day-diff'
import { getDateObject } from '.'

const twelveHours = 12 * 60 * 60 * 1000

export const isDateInPastEverywhere = (date: Date | string): boolean => {
  const dateObject = typeof date === 'string' ? getDateObject(date) : date

  if (!dateObject) return false

  // get the UTC date twelve hours ago to account for all timezones
  const twelveHoursAgo = new Date(Date.now() - twelveHours)
  const twelveHoursAgoInUtc = toUTCDate(twelveHoursAgo)

  return getDayDiff(dateObject, twelveHoursAgoInUtc) < 0
}
