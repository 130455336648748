import classNames from 'classnames'
import * as React from 'react'
import { Text } from '@components/legacy-design-system/product/components/Text'
import { Button, ButtonProps } from '@components/Button'
import { HSpace } from '@components/HSpace'
import { Icon, IconName } from '@components/Icon'
import { TextProps } from '@components/Text'
import styles from './Notice.module.css'

export const NOTICE_INTENTS = [
  'informative',
  'positive',
  'warning',
  'severe',
] as const

type NoticeIntent = (typeof NOTICE_INTENTS)[number]

const NOTICE_INTENT_TO_COLOR_MAP: Record<NoticeIntent, TextProps['color']> = {
  informative: 'blue-900',
  positive: 'green-900',
  warning: 'yellow-900',
  severe: 'pink-900',
}

export interface NoticeCommonProps {
  type?: 'default' | 'action'
  intent?: NoticeIntent
  title?: React.ReactNode | string
  fontSize?: Extract<TextProps['fontSize'], 'C1' | 'C2'>
  alignCenter?: boolean
  iconName?: IconName
}

export interface NoticeDefaultProps extends NoticeCommonProps {
  type?: 'default'
  hideIcon?: boolean
  onClickActionButton?: never
  actionButtonText?: never
  actionButtonIsWaiting?: never
}

export interface NoticeActionProps extends NoticeCommonProps {
  type: 'action'
  hideIcon?: never
  onClickActionButton: (event: React.MouseEvent<HTMLButtonElement>) => void
  actionButtonText: ButtonProps['text']
  actionButtonIsWaiting?: ButtonProps['isWaiting']
}

export type NoticeProps = NoticeDefaultProps | NoticeActionProps

export const Notice: React.FC<React.PropsWithChildren<NoticeProps>> = ({
  type = 'default',
  intent = 'informative',
  hideIcon = false,
  onClickActionButton,
  actionButtonText = '',
  actionButtonIsWaiting = false,
  title,
  fontSize = 'C1',
  children,
  alignCenter = true,
  iconName = 'info',
  ...props
}) => {
  const isAction = type === 'action'
  const typeStyle = {
    C1: 'paragraph2' as const, // 16px
    C2: 'paragraph3' as const, // 14px
  }

  const noticeStyles = classNames(styles.notice, styles[`notice--${intent}`], {
    [styles['notice--action']]: isAction,
  })

  return (
    <div className={noticeStyles} {...props}>
      {/* We want to disable alignCenter when Notice has title */}
      <HSpace space={12} alignCenter={title ? false : alignCenter}>
        {type === 'default' && !hideIcon && (
          <Icon size={24} name={iconName} className={styles['icon']}></Icon>
        )}

        <div>
          {title && (
            <Text
              fontWeight="medium"
              color={NOTICE_INTENT_TO_COLOR_MAP[intent]}
              typeStyle="paragraph2"
            >
              {title}
            </Text>
          )}

          <Text
            color={NOTICE_INTENT_TO_COLOR_MAP[intent]}
            typeStyle={typeStyle[fontSize]}
          >
            {children}
          </Text>
        </div>
      </HSpace>

      {type === 'action' && (
        <Button
          className={classNames(
            styles['notice__action-button'],
            styles[`notice__action-button--${intent}`]
          )}
          isWaiting={actionButtonIsWaiting}
          outlined
          text={actionButtonText}
          onClick={onClickActionButton}
        ></Button>
      )}
    </div>
  )
}
