// NOTE: This require will be replaced with `@sentry/browser`
// client side thanks to the webpack config in next.config.js
import * as Sentry from '@sentry/nextjs'
import { cloneDeep } from 'lodash'
import { ApiResponseError, ApiResponseMeta } from '@duffel/api/types'
import { APIResponseError, APIResponseMeta } from '@lib/types'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV
const BUILD_GIT_SHA = process.env.COMMIT_SHA

const urlsToIgnoreInBreadcrumb = [
  'https://app.duffel.com/feature-flag-proxy',
  'https://firestore.googleapis.com/google.firestore.v1.Firestore/Listen',
]

const messagesToGroup = [
  'Failed to fetch',
  'NetworkError when attempting to fetch resource',
  'Cancelled',
  'FetchError',
  'ECONNREFUSED',
  'ECONNRESET',
]

const options: Sentry.BrowserOptions | Sentry.NodeOptions = {
  dsn: SENTRY_DSN,
  release: BUILD_GIT_SHA,
  environment: APP_ENV,
  maxBreadcrumbs: 50,
  attachStacktrace: true,
  // An array of strings or regexps that'll be used to ignore specific errors based on their type/message
  ignoreErrors: [
    // ignoring this as we don't want to spam sentry with things related to this
    // context: https://duffel.slack.com/archives/C013XQ3T2J1/p1652698677101139
    'http://localhost:3001/proxy',
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    // We used to ignore the ones below in the past as they tend to be
    // unactionable, but this leads to us losing some context on the server-side errors.
    // So we will only try to ignore them on the sentry side instead.
    // 'FetchError',
    // 'Failed to fetch',
    // 'NetworkError when attempting to fetch resource.',
    // 'Cancelled',
    // 'TypeError: Failed to fetch',
    // 'TypeError: NetworkError when attempting to fetch resource.',
    // 'TypeError: Cancelled',
  ],
  // https://docs.sentry.io/platforms/javascript/usage/sdk-fingerprinting/#group-errors-more-aggressively
  beforeSend: (event, hint) => {
    try {
      const exception = hint.originalException

      if (
        exception instanceof Error &&
        messagesToGroup.some(
          (message) =>
            exception.message.includes(message) ||
            exception.name.includes(message)
        )
      ) {
        event.fingerprint = ['fetch-error']
      }
      return event
    } catch {
      return event
    }
  },
  beforeBreadcrumb(breadcrumb) {
    // exclude these urls from the breadcrumb as they are very noisy
    if (
      urlsToIgnoreInBreadcrumb.some((url) => breadcrumb.message?.includes(url))
    ) {
      return null
    }
    return breadcrumb
  },
}

if (process.env.NODE_ENV !== 'production') {
  // Don't send errors to Sentry in development
  options.beforeSend = () => null
}

export const sentryInit = () => Sentry.init(options)

export const setSentryUser = (id: string | undefined) => {
  if (id) {
    Sentry.configureScope((scope) => {
      scope.setUser({ id })
    })
  } else {
    Sentry.configureScope((scope) => {
      scope.setUser(null)
    })
  }
}

/**
 * Ensure we don't send session cookies in Sentry error payloads
 */
export const removeSessionCookiesFromCtx = (ctx?: any) => {
  const cookiesToRemove = ['ff-session', 'ff-p', 'ff-s']
  const filteredCtx = cloneDeep(ctx)

  if (!filteredCtx?.req?.cookies) {
    return filteredCtx
  }

  const filteredCookies = Object.keys(filteredCtx.req.cookies)
    .filter((key) => !cookiesToRemove.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: filteredCtx[key],
      }
    }, {})

  filteredCtx.req.cookies = filteredCookies

  return filteredCtx
}

interface ErrorProps extends Error {
  statusCode?: number | string
  errors?: Array<APIResponseError> | Array<ApiResponseError>
  requestId?: string
  meta?: APIResponseMeta | ApiResponseMeta
}

const checkedKeys = [
  'statusCode',
  'query',
  'method',
  'url',
  'params',
  'headers',
  'meta',
  // Omit the following for privacy
  'user',
  'csrfToken', // gets filtered by Sentry but added to be safe
]

export const captureException = (err: ErrorProps, ctx?: any) => {
  const filteredCtx = removeSessionCookiesFromCtx(ctx)

  Sentry.withScope((scope) => {
    if (err.message) {
      scope.setFingerprint([err.message])
    }

    const statusContext = {}
    // Sometimes we receive an array of errors
    if (err.errors && err.errors.length > 0) {
      scope.setFingerprint([err.errors[0].message])
      statusContext['apiErrorCode'] = err.errors[0].code
    }

    if (err?.requestId) {
      scope.setTag('x-request-id', err.requestId)
    }

    if (err?.statusCode) {
      statusContext['statusCode'] = err.statusCode
    }

    if (Object.keys(statusContext).length) {
      scope.setContext('status', statusContext)
    }

    if (filteredCtx) {
      if (filteredCtx.statusCode) {
        scope.setContext('status', {
          ...statusContext,
          ctxStatusCode: filteredCtx.statusCode,
        })
      }

      if (typeof window !== 'undefined') {
        scope.setTag('ssr', 'false')
        scope.setTag('isNext', 'true')
        if (
          filteredCtx?.query &&
          (!Array.isArray(filteredCtx.query) || filteredCtx.query.length)
        ) {
          scope.setContext('request', { query: filteredCtx.query })
        }
      } else {
        if (filteredCtx.proxy) {
          scope.setTag('isAPI', 'true')
          if (filteredCtx?.headers && filteredCtx.headers['x-request-id']) {
            scope.setTag('x-request-id', filteredCtx.headers['x-request-id'])
          }
        } else {
          scope.setTag('isNext', 'true')
          scope.setTag('ssr', 'true')
        }

        if (filteredCtx) {
          const requestContext = {
            url: filteredCtx.url,
            method: filteredCtx.method,
            params: filteredCtx.params,
            query: filteredCtx.query,
          }
          if (Object.keys(requestContext).length) {
            scope.setContext('request', requestContext)
          }
        }
      }

      if (err.requestId) {
        scope.setContext('meta', {
          requestId: err.requestId,
        })
      } else if (err.meta && err.meta.status) {
        scope.setContext('meta', {
          statusCode: err.meta.status,
          ...('requestId' in err.meta && { requestId: err.meta.requestId }),
          ...('request_id' in err.meta && { requestId: err.meta.request_id }),
        })
      } else if ('meta' in filteredCtx && filteredCtx.meta.status) {
        scope.setContext('meta', {
          statusCode: filteredCtx.meta.status,
          requestId: filteredCtx.meta.requestId,
        })
      }

      const extraKeys = Object.keys(filteredCtx).filter(
        (key) => !checkedKeys.includes(key)
      )

      if (extraKeys.length) {
        const sentryContext = {}
        extraKeys.forEach((key) => (sentryContext[key] = filteredCtx[key]))
        scope.setContext('other', sentryContext)
      }
    }

    if (err.errors && err.errors.length > 0) {
      return Sentry.captureException(
        new Error(err.errors[0].title || err.errors[0].message)
      )
    }

    return Sentry.captureException(err)
  })
}

export { Sentry }
