import * as React from 'react'
import { CardForm } from '@components/CardForm'
import { Modal, ModalProps } from '@components/Modal'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { Button } from '@components/Button'
import { Anchor } from '@components/Anchor'
import { Heading } from '@components/Heading'
import styles from './AddBillingDetailsModal.module.css'

export interface AddBillingDetailsModalProps extends ModalProps {
  id: string
  stripeCustomerId: string
  onBillingDetailsAdded: (paymentMethod: string) => void
}

export const AddBillingDetailsModal: React.FC<AddBillingDetailsModalProps> = ({
  id,
  stripeCustomerId,
  onBillingDetailsAdded,
  ...modalProps
}) => {
  const cardFormRef = React.createRef<HTMLButtonElement>()

  const [isSubmitting, setIsSubmitting] = React.useState(false)

  return (
    <Modal {...modalProps}>
      <div className={styles['card-container']}>
        <VSpace space={32}>
          <VSpace space={32}>
            <VSpace space={16}>
              <Heading h2>Add billing details</Heading>
              <VSpace space={8}>
                <Text color="grey-700">
                  We have pay as you go pricing which means there are no
                  up-front costs or monthly commitments, and you only ever pay
                  for what you use.{' '}
                  <Anchor
                    href="https://duffel.com/pricing"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Learn more
                  </Anchor>
                </Text>
                <Text color="grey-700">
                  If you have any questions, or wish to discuss enterprise
                  pricing, please reach out to our{' '}
                  <Anchor
                    href="https://duffel.com/contact-us"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Sales team
                  </Anchor>
                  .
                </Text>
              </VSpace>
            </VSpace>
            <VSpace space={8} className={styles['card-container']}>
              <Text color="grey-900">Billing details</Text>
              <CardForm
                id={id}
                className={styles['card-form']}
                stripeCustomerId={stripeCustomerId}
                onReceivePaymentMethodId={(paymentMethodId) => {
                  onBillingDetailsAdded(paymentMethodId)
                  modalProps.onClose()
                }}
                beforeSubmit={() => setIsSubmitting(true)}
                isSubmitting={isSubmitting}
                onSubmitError={() => setIsSubmitting(false)}
                trackingEventPageName="activation_card"
                forwardRef={cardFormRef}
              />
            </VSpace>
          </VSpace>
          <footer className={styles['card-container__footer']}>
            <VSpace space={16}>
              <Button
                className={styles['card-container__footer-btn']}
                text={'Save billing details'}
                onClick={() => {
                  // This button is basically a proxy to the hidden button in the card form.
                  // We have to do this because most of the submission logic is still in the CardForm
                  // component and we want to reuse that.
                  // TODO(UXP-2177): once we move the submission logic out from `CardForm`, remove this.
                  cardFormRef.current?.click()
                }}
                isWaiting={isSubmitting}
                disabled={isSubmitting}
                fill
                data-selector="fs-show"
              />
              <Button
                className={styles['card-container__footer-btn']}
                text="Dismiss"
                onClick={modalProps.onClose}
                disabled={isSubmitting}
                intent="MUTED"
                fill
                data-selector="fs-show"
              />
            </VSpace>
          </footer>
        </VSpace>
      </div>
    </Modal>
  )
}
