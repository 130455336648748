import * as React from 'react'
import { useField, useFormikContext } from 'formik'
import classNames from 'classnames'
import { FormField, FormFieldProps } from '@components/Form'
import { slugify } from '@lib/helpers'
import { FormDatePicker } from './FormDatePicker'

export const FormikDatePicker: React.FC<{
  name: string
  label: string
  disablePopup?: boolean
  disabled?: boolean
  hint?: FormFieldProps['hint']
  optionalField?: boolean
  enablePastDates?: boolean
}> = ({
  name,
  label,
  disablePopup,
  disabled,
  hint,
  optionalField,
  enablePastDates,
}) => {
  const id = slugify(name)
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField({
    name,
  })
  const { submitCount, isSubmitting } = useFormikContext()
  const isDisabled = isSubmitting || disabled

  return (
    <div className={classNames('date-field', { disabled: disabled })}>
      <FormField
        htmlFor={id}
        label={label}
        error={touched || submitCount > 0 ? error : undefined}
        disabled={isDisabled}
        hint={hint}
        optionalField={optionalField}
      >
        <div className={classNames({ disabled: disabled })}>
          <FormDatePicker
            id={id}
            value={value}
            // overriding onBlur rather than passing onBlur from useField above since
            // <FormDatePicker /> is made up of several <input> elements and the default
            // onBlur will change those element's touched prop rather than <FormikDatePicker />
            // which can cause issues with validation displayed to the user
            onBlur={() => setTouched(true)}
            onChange={setValue}
            disablePopup={disablePopup}
            disabled={isDisabled}
            enablePastDates={enablePastDates}
          />
        </div>
      </FormField>
      <style jsx>{`
        .date-field .disabled :global(.date-picker-form-target),
        .date-field .disabled :global(.date-picker-form-target input) {
          pointer-events: none;
          background-color: var(--grey-100);
          color: var(--grey-400) !important;
        }
      `}</style>
    </div>
  )
}
