import classNames from 'classnames'
import React from 'react'
import { getTokensWithIds } from './get-tokens-with-ids'

/**
 * Find and highlight relevant keywords within a block of text
 * @param  {string} label - The text to parse
 * @param  {string} value - The search keyword to highlight
 * @param  {string} propertyKey - The original propertyKey
 * @param  {string} tokenClassName - The original token class name
 * @param  {any} tokenProps - tokenProps from prismjs
 * @param  {React.ReactNode} children
 * @return {object} A JSX object containing an array of alternating strings and JSX
 */
export const formatApiReferenceLabel = (
  label: string,
  value: string,
  propertyKey: string,
  tokenClassName?: string,
  tokenProps?: any,
  children?: React.ReactNode
): JSX.Element => {
  const updatedLabel = label.replace(propertyKey, value)

  return (
    <span data-testid="property-tooltip-label" {...tokenProps}>
      {updatedLabel.split(value).reduce((prev: any, current: any, index) => {
        if (!index) {
          return [current]
        }
        // fixed this quickly as it was breaking the tests, because key wasn't passed to all elements
        return prev.concat(
          <React.Fragment key={value + current}>
            <u className={tokenClassName} key={value + current}>
              {value}
              {children}
            </u>
            <span
              className={classNames(tokenClassName, 'code-block__line-code', {
                'code-block__line-code--id': getTokensWithIds(current),
              })}
            >
              {current}
            </span>
          </React.Fragment>
        )
      }, [])}
    </span>
  )
}
