import { DuffelAPI } from '@lib/types'

export const applyChangeToOrder = (
  order: DuffelAPI.Types.Order,
  orderChange:
    | DuffelAPI.Types.OrderChangeRequestOffer
    | DuffelAPI.Types.OrderChange
): DuffelAPI.Types.Order => {
  if (!order.availableActions.includes('change')) {
    return order
  }
  const newSlices = new Array<DuffelAPI.Types.OrderSlice>()

  for (const slice of order.slices) {
    const indexOfSliceToBeSwapped = orderChange.slices.remove.findIndex(
      ({ id }) => id === slice.id
    )
    const newSlice = orderChange.slices.add[indexOfSliceToBeSwapped]

    if (!newSlice) {
      newSlices.push(slice)
      continue
    }

    newSlice['_changed'] = true

    // Order change slices don't have all the properties of the order slices
    // https://duffel.com/docs/api/v2/order-changes#order-changes-schema-slices-slices-add
    newSlices.push({
      ...slice,
      ...newSlice,
    } as DuffelAPI.Types.OrderSlice)
  }

  return Object.assign({}, order, { slices: newSlices })
}
