import classNames from 'classnames'
import * as React from 'react'
import { Menu } from '@components/Menu'
import { PopoverContainer } from '@components/PopoverContainer'
import { SelectTarget } from '@components/SelectTarget'
import { useDuffelPopper } from '@lib/hooks'

import styles from './SelectMenu.module.css'

export interface SelectMenuProps {
  id: string
  value: string
  options: readonly string[]
  onChange: (value: string) => void
  disabled?: boolean
  optionsWidth?: number
  size?: 'medium' | 'small'
  selectTargetClassName?: string
}

export const SelectMenu: React.FC<SelectMenuProps> = ({
  id,
  options,
  value,
  onChange,
  disabled,
  optionsWidth,
  size = 'medium',
  selectTargetClassName,
}) => {
  const [activeOption, setActiveOption] = React.useState(options.indexOf(value))
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
  const onClosePopover = () => setIsPopoverOpen(false)
  const onSelect = (option: string) => {
    onChange(option)
    onClosePopover()
  }

  const { popper, setReferenceElement, setPopperElement } = useDuffelPopper(
    isPopoverOpen,
    onClosePopover,
    {
      placement: 'bottom-start',
      modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
    },
    { shouldInsideClickClose: false }
  )

  return (
    <>
      <SelectTarget
        id={id}
        data-testid={id}
        type="button"
        ref={setReferenceElement}
        disabled={disabled}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        onKeyDown={(event) => {
          if (event.key === 'ArrowDown') {
            event.preventDefault()
            setActiveOption(Math.min(activeOption + 1, options.length - 1))
          }

          if (event.key === 'ArrowUp') {
            event.preventDefault()
            setActiveOption(Math.max(activeOption - 1, 0))
          }

          if (event.key === 'Enter') {
            if (isPopoverOpen) {
              event.preventDefault()
              onSelect(options[activeOption])
            }
          }

          if (event.key === 'Tab') {
            onClosePopover()
          }
        }}
        size={size}
        className={selectTargetClassName}
      >
        {value}
      </SelectTarget>
      {isPopoverOpen && (
        <PopoverContainer
          ref={setPopperElement}
          style={{
            width: `${optionsWidth ?? 352}px`,
            ...popper.styles.popper,
          }}
          {...popper.attributes}
        >
          {
            <Menu>
              {options.map((label, index) => (
                <button
                  key={label}
                  className={classNames(styles['menu-item'], {
                    [styles['menu-item--active']]: activeOption === index,
                    [styles['menu-item--selected']]:
                      options.indexOf(value) === index,
                    [styles['menu-item--small']]: size === 'small',
                  })}
                  onClick={() => {
                    setActiveOption(index)
                    onSelect(label)
                  }}
                  onMouseEnter={() => setActiveOption(index)}
                >
                  {label}
                </button>
              ))}
            </Menu>
          }
        </PopoverContainer>
      )}
    </>
  )
}
