import {
  APIResponse,
  APIResponseError,
  BaseClientConfig,
  DuffelContext,
} from '@lib/types'
import {
  AccommodationSuggestion,
  Booking,
  BookingPayload,
  Quote,
  SearchParams,
  SearchResult,
} from '@modules/stays-search/lib/types'
import { BaseClient } from './lib'

export type StaysSearchResponse = APIResponse<{
  results: Array<SearchResult>
  createdAt: string
}>

export class Stays extends BaseClient {
  constructor(config: BaseClientConfig) {
    super(config)
  }

  createBookingResponseToError: { [key: number]: APIResponseError } = {
    202: {
      title: 'Booking Unconfirmed',
      message:
        'The booking is pending confirmation. If confirmed, it will appear in the Stays Bookings list. In the case of an unsuccessful confirmation, our support team will be in touch soon.',
      code: 'unknown',
    },
    200: {
      title: 'Booking Confirmed',
      message:
        'The booking was successfully confirmed. It will appear in the Stays Bookings list soon.',
      code: 'unknown',
    },
  }

  errorCodeToErrorMessage: { [key: string]: string } = {
    booking_already_attempted:
      'Another booking attempt for this rate is pending confirmation. If confirmed, it will appear in the Stays Bookings list. In the case of an unsuccessful confirmation, our support team will be in touch soon.',
    booking_already_confirmed:
      'Another booking attempt for this rate was successfully confirmed. It will appear in the Stays Bookings list soon.',
  }

  async accommodationSuggestions(
    query: string,
    ctx?: DuffelContext
  ): Promise<APIResponse<AccommodationSuggestion[]>> {
    return this.request<AccommodationSuggestion[]>({
      method: 'POST',
      url: `/api/stays/accommodation/suggestions`,
      data: { query },
      ...this.getRequestOptions(ctx),
    })
  }

  async search(
    params: SearchParams,
    ctx?: DuffelContext
  ): Promise<StaysSearchResponse> {
    return this.request<{
      results: Array<SearchResult>
      createdAt: string
    }>({
      method: 'POST',
      url: `/api/stays/search`,
      data: params,
      ...this.getRequestOptions(ctx),
    })
  }

  async fetchAllRates(
    searchResultId: string,
    ctx?: DuffelContext
  ): Promise<APIResponse<SearchResult>> {
    return this.request<SearchResult>({
      method: 'POST',
      url: `/api/stays/search_results/${searchResultId}/actions/fetch_all_rates`,
      ...this.getRequestOptions(ctx),
    })
  }

  async createQuote(
    rateId: string,
    ctx?: DuffelContext
  ): Promise<APIResponse<Quote>> {
    return this.request<Quote>({
      method: 'POST',
      url: `/api/stays/quotes`,
      data: {
        rateId,
      },
      ...this.getRequestOptions(ctx),
    })
  }

  async createBooking(
    // TODO(idp): we'll keep this for now until we are ready to
    // make this public on the @duffel/api package
    payload: BookingPayload & {
      payment?: { cardId: string } | { threeDSecureSessionId: string }
    },
    ctx?: DuffelContext
  ): Promise<APIResponse<Booking>> {
    const config = {
      method: 'POST',
      url: '/api/stays/bookings',
      data: payload,
      ...this.getRequestOptions(ctx),
    }

    try {
      const response = await this.rawRequest<APIResponse<Booking>>(config)

      if (response.status === 202 || response.status === 200) {
        return {
          data: undefined,
          errors: [this.createBookingResponseToError[response.status]],
          meta: response.data.meta,
        }
      }

      return response.data
    } catch (err: unknown) {
      const errorResult = this.handleErrorResponse<Booking>(err, config)

      errorResult.errors?.forEach((error) => {
        if (error.code && this.errorCodeToErrorMessage[error.code]) {
          error.message = this.errorCodeToErrorMessage[error.code]
        }
      })

      return errorResult
    }
  }

  async getBooking(
    bookingId: string,
    ctx?: DuffelContext
  ): Promise<APIResponse<Booking>> {
    return this.request<Booking>({
      method: 'GET',
      url: `/api/stays/bookings/${bookingId}`,
      ...this.getRequestOptions(ctx),
    })
  }

  async cancelBooking(
    bookingId: string,
    ctx?: DuffelContext
  ): Promise<APIResponse<Booking>> {
    return this.request<Booking>({
      method: 'POST',
      url: `/api/stays/bookings/${bookingId}/actions/cancel`,
      ...this.getRequestOptions(ctx),
    })
  }

  async listBookings(ctx?: DuffelContext): Promise<APIResponse<Booking[]>> {
    return this.request<Booking[]>({
      method: 'GET',
      url: `/api/stays/bookings`,
      ...this.getRequestOptions(ctx),
    })
  }
}
