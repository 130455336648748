import { useRouter } from 'next/router'
import * as React from 'react'
import { Spinner } from '@components/Spinner'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { Heading } from '@components/Heading'
import { HSpace } from '@components/HSpace'
import { UNKNOWN_ERROR_MESSAGE } from '@lib/constants'
import { trackEvent } from '@lib/tracking'
import { APIResponseError, DuffelAPI } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import { moneyStringFormatter } from '@lib/helpers'
import { useUserFriendlyErrors } from '@modules/air-order/lib/use-user-friendly-errors'
import { timeout } from '@modules/air-order/lib/timeout'
import { isQuotelessCancellation } from './isQuotelessCancellation'
import { Anchor } from '@components/Anchor'
import { AxiosResponse } from 'axios'
import { getDuffelAPIClient } from '@lib/duffel-api/getDuffelAPIClient'
import { toCamelCaseKeys } from '@lib/proxy/lib/case-utils'

export const REFUND_METHOD_MAP: Record<
  DuffelAPI.Types.OrderCancellation['refundTo'],
  string
> = {
  arc_bsp_cash: 'ARC/BSP Cash',
  balance: 'Balance',
  card: 'Card',
  voucher: 'Voucher',
  awaiting_payment: 'Awaiting payment',
  airline_credits: 'Airline credit',
}

export const useOrderCancellation = (order: DuffelAPI.Types.Order) => {
  const router = useRouter()
  const { addToast, openDialog, closeDialog, updateDialog, openSupportModal } =
    useWorkspace()
  const { handleOrderErrors } = useUserFriendlyErrors()
  const { id: orderId, bookingReference } = order
  const orderTotal = `${order.totalCurrency} ${order.totalAmount}`

  const openCancellationDialog = async () => {
    showLoadingDialog()

    const client = getDuffelAPIClient(
      undefined,
      undefined,
      router.query.org as string,
      router.query.mode as 'live' | 'test'
    )
    let response: AxiosResponse | null = null
    try {
      response = await client.post('/air/order_cancellations', {
        data: {
          order_id: order.id,
        },
      })
    } catch (error) {
      console.error(error)
      trackEvent('dashboard_order_show_cancel_order_request_failed', {
        error_message: UNKNOWN_ERROR_MESSAGE,
        event_type: 'api',
        order_id: orderId,
      })
      return await handleCancellationErrors([])
    }

    if (response) {
      if (response.data['errors']) {
        trackEvent('dashboard_order_show_cancel_order_request_failed', {
          error_message:
            response.data['errors']?.[0]?.message || UNKNOWN_ERROR_MESSAGE,
          event_type: 'api',
          order_id: orderId,
          status: response.data['meta']?.status,
        })
        return await handleCancellationErrors(
          response.data['errors'] || [],
          response.data['meta']?.requestId
        )
      } else {
        const orderCancellation = toCamelCaseKeys(
          response.data['data']
        ) as DuffelAPI.Types.OrderCancellation
        trackEvent('dashboard_order_show_cancel_order_request_completed', {
          order_id: orderId,
          order_total: orderTotal,
          cancellation_refund_amount: orderCancellation.refundCurrency
            ? moneyStringFormatter(orderCancellation.refundCurrency)(
                Number(orderCancellation.refundAmount)
              )
            : orderCancellation.refundAmount ||
              'Null refund amount and currency',
          event_type: 'api',
        })

        if (isQuotelessCancellation(orderCancellation)) {
          await showQuotelessCancellationDialog(orderCancellation)
        } else {
          await showRefundAmountDialog(orderCancellation)
        }
      }
    }
  }

  const showLoadingDialog = () => {
    openDialog({
      customRenderer: () => (
        <div className="u-padding32">
          <VSpace space={16}>
            <Heading h4>Cancel order {bookingReference}</Heading>
            <Text fontSize="C1" textAlign="left" color="grey-700">
              Please hold while we prepare your cancellation and calculate your
              refund…
            </Text>
            <Spinner />
          </VSpace>
        </div>
      ),
      confirmButtonLabel: 'Confirm',
      cancelButtonLabel: 'Dismiss',
      width: 400,
      isConfirmButtonDisabled: true,
      onConfirm: () => undefined,
      onCancel: closeDialog,
    })
  }

  const showRefundAmountDialog = async (
    orderCancellation: DuffelAPI.Types.OrderCancellation
  ) => {
    // Sometimes the API responds instantaneously which causes the UI to 'jump' when it updates.
    // This artificial .8s delay was added to reinforce a refund amount was calculated
    await timeout(800)

    updateDialog({
      customRenderer: () => (
        <div className="u-padding32">
          <VSpace space={16}>
            <Heading h4>Cancel order {bookingReference}</Heading>
            {getCancellationConfirmDialogMessage(orderCancellation)}
          </VSpace>
        </div>
      ),
      onConfirm: () => handleCancellationConfirm(orderCancellation.id),
      onCancel: () => {
        closeDialog()
      },
      confirmButtonLabel: 'Confirm',
      cancelButtonLabel: 'Dismiss',
      width: 400,
      isConfirmButtonDisabled: false,
    })
  }

  const showQuotelessCancellationDialog = async (
    orderCancellation: DuffelAPI.Types.OrderCancellation
  ) => {
    // Sometimes the API responds instantaneously which causes the UI to 'jump' when it updates.
    // This artificial .8s delay was added to reinforce a refund amount was calculated
    await timeout(800)

    updateDialog({
      customRenderer: () => (
        <div className="u-padding32">
          <VSpace space={16}>
            <Heading h4>Cancel order {bookingReference}</Heading>
            <Text fontSize="C1" textAlign="left" color="grey-600">
              We were unable to get a quote for this cancellation.
            </Text>
            <Text fontSize="C1" textAlign="left" color="grey-600">
              By cancelling this order, your customer will no longer be booked
              on this flight. This action cannot be undone. If you need a quote,
              you can{' '}
              <Anchor
                onClick={() =>
                  openSupportModal({ initialCategory: 'cancellation-refund' })
                }
              >
                get in touch with our support team.
              </Anchor>
            </Text>
          </VSpace>
        </div>
      ),
      onConfirm: () => handleCancellationConfirm(orderCancellation.id),
      onCancel: () => {
        closeDialog()
      },
      confirmButtonLabel: 'Confirm',
      cancelButtonLabel: 'Dismiss',
      width: 400,
      isConfirmButtonDisabled: false,
    })
  }

  const handleCancellationConfirm = async (
    cancellationId: DuffelAPI.Types.OrderCancellation['id']
  ) => {
    updateDialog({ isConfirmButtonLoading: true })

    trackEvent('dashboard_order_show_cancel_order_confirm_button_clicked', {
      order_id: orderId,
      event_type: 'interaction',
    })

    const client = getDuffelAPIClient(
      undefined,
      undefined,
      router.query.org as string,
      router.query.mode as 'live' | 'test'
    )
    let response: AxiosResponse | null = null
    try {
      response = await client.post(
        `/air/order_cancellations/${cancellationId}/actions/confirm`
      )
    } catch (error) {
      console.error(error)
      trackEvent('dashboard_order_show_cancel_order_confirmation_failed', {
        error_message: UNKNOWN_ERROR_MESSAGE,
        event_type: 'api',
        order_id: orderId,
      })
      return await handleCancellationErrors([])
    }

    if (response) {
      if (response.data['errors']) {
        trackEvent('dashboard_order_show_cancel_order_confirmation_failed', {
          error_message:
            response.data['errors']?.[0]?.message || UNKNOWN_ERROR_MESSAGE,
          event_type: 'api',
          order_id: orderId,
          status: response.data['meta']?.status,
        })
        return await handleCancellationErrors(
          response.data['errors'] || [],
          response.data['meta']?.requestId
        )
      } else {
        trackEvent('dashboard_order_show_cancel_order_confirmation_completed', {
          order_id: orderId,
          event_type: 'api',
        })

        addToast({
          intent: 'success',
          message: 'Your order has been successfully cancelled.',
        })

        await router.replace(router.asPath)
        closeDialog()
      }
    }
  }

  const handleCancellationErrors = async (
    errors: APIResponseError[],
    requestId?: string
  ) => {
    await handleOrderErrors(errors, order, {
      actionToSupport: 'cancel_order',
      requestId: requestId || '',
    })
  }

  const getCancellationConfirmDialogMessage = (
    orderCancellation: DuffelAPI.Types.OrderCancellation
  ) => {
    const { refundCurrency, refundAmount, refundTo } = orderCancellation

    return (
      <VSpace space={16}>
        <VSpace space={8}>
          <HSpace space={0} spaceBetween>
            <Text fontSize="C1" textAlign="left" color="grey-600">
              Refund amount:
            </Text>
            <Text fontSize="C1" color="grey-900">
              {refundCurrency
                ? moneyStringFormatter(refundCurrency)(Number(refundAmount))
                : 'Unknown'}
            </Text>
          </HSpace>
          <HSpace space={0} spaceBetween>
            <Text fontSize="C1" textAlign="left" color="grey-600">
              Refund type:
            </Text>
            <Text fontSize="C1" color="grey-900">
              {REFUND_METHOD_MAP[refundTo]}
            </Text>
          </HSpace>
        </VSpace>

        <Text fontSize="C1" textAlign="left" color="grey-600">
          By cancelling this order, your customer will no longer be booked on
          this flight. This action cannot be undone.
        </Text>
      </VSpace>
    )
  }

  return { openCancellationDialog }
}
