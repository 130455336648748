import classNames from 'classnames'
import * as React from 'react'
import { HSpace } from '@components/HSpace'
import { Icon, IconName } from '@components/Icon'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { FontSize } from '@lib/styles'
import styles from './ToggleCard.module.css'

export type ToggleCardProps = {
  icon: IconName
  name: React.ReactNode
  selected: boolean
  onClick: (event: React.SyntheticEvent<HTMLButtonElement>) => void
  toggleStyle?: 'circle' | 'square'
  activeColor?: 'green' | 'purple'
  activeBackgroundColor?: 'activeColor' | 'white'
  focused?: boolean
  toggleCardIcon?: IconName
  position?: 'left' | 'right'
} & {
  size?: 'extra-small' | 'small' | 'regular'
  definition?: string
}

const fontSizeMap: { [key in string]: FontSize } = {
  regular: 'H4',
  small: 'C1',
  'extra-small': 'C2',
}

export const ToggleCard: React.FC<ToggleCardProps> = ({
  icon,
  name,
  definition,
  selected,
  onClick,
  size = 'regular',
  toggleStyle = 'circle',
  activeColor = 'green',
  activeBackgroundColor = 'activeColor',
  focused,
  toggleCardIcon,
  position = 'left',
}) => {
  const renderSelect = () => (
    <div
      className={classNames(styles['selection'], {
        [styles['selection--circle']]: toggleStyle === 'circle',
        [styles['selection--selected']]: selected,
      })}
    >
      {selected && <Icon name={icon} size={20} />}
    </div>
  )

  return (
    <button
      type="button"
      className={classNames(styles['toggle-card'], {
        [styles['toggle-card--selected']]: selected,
        [styles['toggle-card--extra-small']]: size === 'extra-small',
        [styles['toggle-card--small']]: size === 'small',
        [styles['toggle-card--purple']]: activeColor === 'purple',
        [styles['toggle-card--white-background']]:
          activeBackgroundColor === 'white',
        [styles['toggle-card--focused']]: focused,
      })}
      onClick={onClick}
    >
      <VSpace space={4}>
        <HSpace
          space={size === 'extra-small' ? 8 : 16}
          className={styles['name-container']}
          spaceBetween={position === 'right'}
        >
          {position === 'left' && renderSelect()}
          <HSpace space={12} alignCenter>
            {toggleCardIcon && <Icon name={toggleCardIcon} size={20} />}
            {typeof name === 'string' ? (
              <Text fontSize={fontSizeMap[size]}>{name}</Text>
            ) : (
              name
            )}
          </HSpace>
          {position === 'right' && renderSelect()}
        </HSpace>
        {definition && (
          <div
            className={
              position === 'left'
                ? 'u-marginLeft32 u-paddingLeft8'
                : toggleCardIcon && 'u-marginLeft32'
            }
          >
            <Text color="grey-600" className={styles['definition']}>
              {definition}
            </Text>
          </div>
        )}
      </VSpace>
    </button>
  )
}
