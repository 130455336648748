import classnames from 'classnames'
import * as React from 'react'
import { Icon } from '@components/Icon'
import { Spinner } from '@components/Spinner'
import { BaseButtonProps } from './common'

import styles from './Button.module.css'

export interface ButtonProps extends BaseButtonProps {
  /**
   * Click event handler
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void

  /**
   * Keydown event handler
   */
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void

  /**
   * HTML type attribute of button. Accepted values are "button", "submit", and "reset".
   */
  type?: 'button' | 'submit' | 'reset'

  /**
   * Element ID
   */
  id?: string

  /**
   * Tab index
   */
  tabIndex?: number
}

/**
 * Useful to communicate actions to your users.
 */
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      disabled = false,
      fill = false,
      iconAfter,
      iconBefore,
      iconOnly,
      intent = 'NEUTRAL',
      isWaiting = false,
      medium = false,
      large = false,
      outlined = false,
      transparent = false,
      text,
      type = 'button',
      id,
      tabIndex,
      dashed,
      ...props
    },
    ref
  ) => {
    const cn = classnames(
      styles['button'],
      {
        [styles['button--default']]: !large && !medium,
        [styles['button--medium']]: medium,
        [styles['button--large']]: large,
        [styles['button--solid']]: !outlined,
        [styles['button--outline']]: outlined,
        [styles['button--transparent']]: transparent,
        [styles['button--neutral']]: intent === 'NEUTRAL',
        [styles['button--primary']]: intent === 'PRIMARY',
        [styles['button--muted']]: intent === 'MUTED',
        [styles['button--destructive']]: intent === 'DESTRUCTIVE',
        [styles['button--inverted']]: intent === 'OUTLINED',
        [styles['button--fill']]: fill,
        [styles['button--icon-only']]: iconOnly,
        [styles['button--is-waiting']]: isWaiting,
        [styles['button--is-disabled']]: disabled || isWaiting,
        [styles['button--icon-before']]: iconBefore,
        [styles['button--icon-after']]: iconAfter,

        // temporary divergent style we had to add to support special cases
        [styles['button--dashed']]: dashed,
        [styles['button--on-dark']]: intent === 'ON_DARK',
      },
      className
    )

    return (
      <button
        ref={ref}
        type={type}
        className={cn}
        {...props}
        disabled={isWaiting || disabled}
        aria-label={text}
        data-testid={id}
        id={id}
        tabIndex={tabIndex}
        {...props}
      >
        {isWaiting && (
          <div className={styles['button__is-waiting']}>
            <Spinner size={large ? 'medium' : 'small'} color="inherit" />
          </div>
        )}
        {iconBefore && (
          <Icon
            className={classnames(styles['button__icon'], 'u-marginRight8')}
            name={iconBefore}
          />
        )}
        {iconOnly && (
          <Icon className={styles['button__icon']} name={iconOnly} />
        )}
        {!iconOnly && <span className={styles['button__text']}>{text}</span>}
        {iconAfter && (
          <Icon
            className={classnames(styles['button__icon'], 'u-marginLeft8')}
            name={iconAfter}
          />
        )}
      </button>
    )
  }
)
