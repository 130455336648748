import { TimeRangeSelector } from '@modules/air-search-v2/components/SearchTimeFilters/TimeRangeSelector'
import { Range } from '@components/RangeSlider/RangeSlider'

export const TimeConditionFilterInput: React.FC<{
  value: Range
  label: string
  onChange: (range: Range) => void
}> = ({ value, label, onChange }) => (
  <TimeRangeSelector
    label={label}
    selectedRange={value}
    onChange={(range) => onChange(range)}
    icon={label.includes('Land') ? 'flight_land' : 'flight_takeoff'}
  />
)
