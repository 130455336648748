import { DuffelAPI } from '@lib/types'
import { makeMockOfferRequestPassenger } from '.'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.OfferRequest => {
    const mockPassenger = makeMockOfferRequestPassenger()
    return {
      cabinClass: 'economy',
      createdAt: 'DATE',
      excludedSources: [],
      fareClass: 'economy',
      id: `off_${(seedId++).toString().padStart(5, '0')}`,
      liveMode: true,
      passengers: [mockPassenger],
      requestedSources: ['ZZ'],
      slices: [],
    }
  }
})()

export const makeMockOfferRequest = (
  extendDefault?: Partial<DuffelAPI.Types.OfferRequest>
): DuffelAPI.Types.OfferRequest =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
