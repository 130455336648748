import classNames from 'classnames'
import * as React from 'react'
import { noop } from 'lodash'
import { Opacity } from '@components/legacy-design-system/product/styles'
import { Portal } from '@components/Portal'
import styles from './Overlay.module.css'

export interface OverlayProps {
  children: React.ReactNode
  backgroundOpacity?: Opacity
  className?: string
  noPortal?: boolean
  onClick?: () => void
}

export const Overlay: React.FC<OverlayProps> = ({
  children,
  backgroundOpacity = 'opacity-40',
  className,
  noPortal,
  onClick,
}) => (
  <>
    {noPortal ? (
      <div
        className={classNames(styles['container'], className)}
        onClick={onClick}
        // Required to allow typing on a modal without it closing
        onKeyDown={noop}
        role="button"
        tabIndex={0}
      >
        {children}
        <div
          style={{ opacity: `var(--${backgroundOpacity})` }}
          className={styles['background']}
        />
      </div>
    ) : (
      <Portal>
        <div
          className={classNames(
            styles['container'],
            styles['container--portal'],
            className
          )}
          onClick={onClick}
          // Required to allow typing on a modal without it closing
          onKeyDown={noop}
          role="button"
          tabIndex={0}
        >
          {children}
          <div
            style={{ opacity: `var(--${backgroundOpacity})` }}
            className={styles['background']}
          />
        </div>
      </Portal>
    )}
  </>
)
