import * as React from 'react'
import {
  useFlag,
  IConfig,
  FlagProvider,
  useVariant,
} from '@unleash/proxy-client-react'
import { noop, partition } from 'lodash'
import { useRouter } from 'next/router'
import { PropsOf } from '@components/legacy-design-system/shared/foundations'
import { isDev } from '@lib/env'
import { DuffelPermissions, DuffelProxy } from '@lib/types'
import {
  DUFFEL_OFFICIAL_ORGS_IDS,
  DUFFEL_STAGING_OFFICIAL_ORGS_IDS,
} from '@lib/constants'

/**
 * Get Unlesah Proxy url
 * If the call is done on the server-side we use a runtime variable NEXT_PUBLIC_PROXY_URL
 * When the call is from the client-side we the browser URL
 * This is because of the preview deployment where we don't have PR_NUMBER on build time
 */
export const getUnleashProxyUrl = () => {
  if (isDev) {
    const UNLEASH_PROXY_URL = process.env.NEXT_PUBLIC_PROXY_URL
    if (!UNLEASH_PROXY_URL) {
      return `http://localhost:3002/proxy`
    }

    if (typeof window !== 'undefined' && window.location.host === 'localhost') {
      return `${window.location.protocol}//${window.location.host}/feature-flag-proxy`
    }

    return UNLEASH_PROXY_URL
  }

  if (typeof window !== 'undefined') {
    return `${window.location.protocol}//${window.location.host}/feature-flag-proxy`
  }

  // Unleash-Proxy runs in the same container as dashboard, so we can communicate directly with it on the server-side
  return 'http://localhost:3001/proxy'
}

export const unleashConfig: Omit<IConfig, 'url'> = {
  clientKey: process.env.NEXT_PUBLIC_UNLEASH_PUBLIC_KEY || '',
  refreshInterval: 60,
  appName: 'dashboard',
  disableMetrics: true,
  environment: process.env.NEXT_PUBLIC_UNLEASH_ENVIRONMENT || 'default',
}

export type OverrideState = 'inherit' | 'overrideEnabled' | 'overrideDisabled'

export const OverrideContext = React.createContext<{
  featureFlagOverrides: Record<string, OverrideState>
  setFeatureFlagOverride: (name: string, state: OverrideState) => void
}>({
  featureFlagOverrides: {},
  setFeatureFlagOverride: noop,
})

const TOGGLE_OVERRIDE_ENABLED_QUERY_KEY = 'overrides-enabled'
const TOGGLE_OVERRIDE_DISABLED_QUERY_KEY = 'overrides-disabled'

const overridesToQuery = (
  queryKey: string,
  overrides: Array<[string, OverrideState]>
) =>
  overrides.length
    ? {
        [queryKey]: overrides.map(([key, _]) => key).join(','),
      }
    : {}

export const FlagProviderWithOverrides: React.FC<
  PropsOf<typeof FlagProvider>
> = ({ children, ...props }) => {
  const router = useRouter()

  // load the overrides from url query params
  const initialOverrides: Record<string, OverrideState> = {}
  const assignInitialOverrides = (
    overrideNames: string[],
    overrideState: OverrideState
  ) =>
    overrideNames.forEach(
      (override) => (initialOverrides[override.trim()] = overrideState)
    )

  const queryOverrideStatePair: Array<[string, OverrideState]> = [
    [TOGGLE_OVERRIDE_ENABLED_QUERY_KEY, 'overrideEnabled'],
    [TOGGLE_OVERRIDE_DISABLED_QUERY_KEY, 'overrideDisabled'],
  ]
  queryOverrideStatePair.forEach(([queryKey, overrideState]) => {
    const overrideFromQuery = router.query[queryKey] ?? []
    assignInitialOverrides(
      typeof overrideFromQuery === 'string'
        ? overrideFromQuery.split(',')
        : overrideFromQuery,
      overrideState
    )
  })

  const [overrides, setOverrides] =
    React.useState<Record<string, OverrideState>>(initialOverrides)

  // save any overrides change to the query params
  React.useEffect(() => {
    const [enabledOverrides, disabledOverrides] = partition(
      Object.entries(overrides).filter(([_, state]) => state !== 'inherit'),
      ([_, state]) => state === 'overrideEnabled'
    )

    const existingQuery = router.query
    delete existingQuery[TOGGLE_OVERRIDE_ENABLED_QUERY_KEY]
    delete existingQuery[TOGGLE_OVERRIDE_DISABLED_QUERY_KEY]

    router.replace(
      {
        pathname: router.pathname,
        query: {
          ...existingQuery,
          ...overridesToQuery(
            TOGGLE_OVERRIDE_ENABLED_QUERY_KEY,
            enabledOverrides
          ),
          ...overridesToQuery(
            TOGGLE_OVERRIDE_DISABLED_QUERY_KEY,
            disabledOverrides
          ),
        },
      },
      undefined,
      { shallow: true }
    )
  }, [overrides])

  return (
    <FlagProvider {...props}>
      <OverrideContext.Provider
        value={{
          featureFlagOverrides: overrides,
          setFeatureFlagOverride: (name: string, state: OverrideState) =>
            setOverrides((prev) => ({ ...prev, [name]: state })),
        }}
      >
        {children}
      </OverrideContext.Provider>
    </FlagProvider>
  )
}

export const useFeatureFlagOverrides = () => React.useContext(OverrideContext)

export const useFeatureFlags = (flag: string): boolean => {
  const isFlagEnabled = useFlag(flag)

  const { featureFlagOverrides, setFeatureFlagOverride } =
    useFeatureFlagOverrides()

  const override = featureFlagOverrides[flag]

  // Populate the override record so that we can have the disabled feature flags on the panel too.
  // We need to do this because unleash proxy is only returning enabled toggles back
  React.useEffect(() => {
    if (!override) {
      setFeatureFlagOverride(flag, 'inherit')
    }
  }, [flag, override])

  // If there's an override, return the overridden value
  if (override && override !== 'inherit') {
    return override === 'overrideEnabled'
  }

  return isFlagEnabled
}

export const useFeatureVariant = (
  name: string
): string | undefined | 'disabled' => {
  const variant = useVariant(name)
  if (variant.name === 'disabled' || !variant.enabled) {
    return 'disabled'
  }
  return variant.name
}

export function getUnleashContextObject(
  user?: DuffelProxy.Types.Self,
  permissions?: DuffelPermissions
) {
  const organisationWithId = permissions?.organisation
    ? user?.organisationsBySlug[permissions?.organisation]?.id ?? ''
    : ''

  if (permissions?.organisation && !organisationWithId) {
    throw new Error(
      `Organisation ${permissions?.organisation} not found in user's organisations`
    )
  }

  return {
    userId: user?.id,
    properties: {
      duffel_admin: String(user?.duffelAdmin),
      duffel_official:
        String(DUFFEL_OFFICIAL_ORGS_IDS.includes(organisationWithId)) ||
        String(DUFFEL_STAGING_OFFICIAL_ORGS_IDS.includes(organisationWithId)) ||
        'false',
      organisation_id: organisationWithId,
    },
  }
}
