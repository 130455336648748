import type { IncomingMessage, ServerResponse } from 'http'
import { NextApiRequest, NextApiResponse } from 'next'
import cookie from 'cookie'
import { getCookieDomain } from '@lib/cookies'
import { isDev } from '@lib/env'

export const LAST_ORG_COOKIE_NAME = 'ff-last-org'
export const LAST_LIVE_MODE_COOKIE_NAME = 'ff-last-live-mode'
export const LAST_SELF_INVALIDATED_COOKIE_NAME = 'ff-last-self-invalidated'

export const makeCookie = (
  req: NextApiRequest | IncomingMessage,
  key: string,
  value: string
) => {
  const cookieDomain = getCookieDomain(req)
  const cookieOptions: cookie.CookieSerializeOptions = {
    httpOnly: true,
    secure: isDev ? false : !!cookieDomain,
    ...(!!cookieDomain && { domain: cookieDomain }),
    sameSite: 'lax',
    path: '/',
    maxAge: 60 * 60 * 2, // 2 hours
  }

  return cookie.serialize(key, value, cookieOptions)
}

export const mergeResponseCookies = (
  res: NextApiResponse | ServerResponse,
  key: string,
  ...cookies: string[]
) => {
  const existingCookies = res.getHeader(key) ?? []
  res.setHeader('Set-Cookie', [
    ...(Array.isArray(existingCookies)
      ? existingCookies
      : [String(existingCookies)]),
    ...cookies,
  ])
}

export const saveSelfInvalidationTimeToCookie = (
  req: NextApiRequest,
  res: NextApiResponse
) => {
  mergeResponseCookies(
    res,
    'Set-Cookie',
    makeCookie(req, LAST_SELF_INVALIDATED_COOKIE_NAME, String(Date.now()))
  )
}

export const getSelfInvalidationTimeFromCookie = (req: NextApiRequest) => {
  return +(req.cookies[LAST_SELF_INVALIDATED_COOKIE_NAME] ?? '0')
}
