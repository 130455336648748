import * as React from 'react'
import styles from './Menu.module.css'

export const Menu: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  return (
    <ul className={styles['menu']} {...props}>
      {children}
    </ul>
  )
}
