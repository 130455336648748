import { ParsedUrlQuery } from 'querystring'
import { getQueryValue } from '@lib/helpers'
import { SortOrdersByOptions } from '@lib/types'

export const ORDERS_SORT_QUERY_PARAM_KEY = 'sort'

export type SortStatus = 'ascending' | 'descending'
export type SortByKeys =
  | 'payment_required_by'
  | 'total_amount'
  | 'next_departure'
  | 'created_at'

export type OrderSortedBy = {
  [Key in SortByKeys as string]: SortStatus
}

export function updateSortStatus(status: SortStatus): SortStatus {
  if (status === 'descending') {
    return 'ascending'
  }

  return 'descending'
}

export function getSort(currentSort: OrderSortedBy): SortOrdersByOptions {
  const key = Object.keys(currentSort)[0] as SortByKeys
  if (currentSort[key] === 'descending') {
    return `-${key}`
  }
  return key
}

export function getSortingFromQuery(query: ParsedUrlQuery): OrderSortedBy {
  const currentQuery = getQueryValue(query[ORDERS_SORT_QUERY_PARAM_KEY])

  if (currentQuery) {
    const sortStatus = currentQuery.includes('-') ? 'descending' : 'ascending'
    const key =
      sortStatus === 'descending' ? currentQuery.split('-')[1] : currentQuery

    return {
      [key]: sortStatus,
    }
  }

  return {
    created_at: 'descending',
  }
}
