import { Space } from '@components/legacy-design-system/product/styles'
import classNames from 'classnames'
import omit from 'lodash/omit'

const spacePropNames = [
  'margin',
  'marginLeft',
  'marginRight',
  'marginTop',
  'marginBottom',
  'marginHorizontal',
  'marginVertical',
  // padding
  'padding',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
  'paddingBottom',
  'paddingHorizontal',
  'paddingVertical',
] as const

export type SpaceProps = Partial<Record<(typeof spacePropNames)[number], Space>>

export const spaceClassNamesFromProps = <P extends SpaceProps>(
  props: P
): { classNames: string; otherProps: Omit<P, keyof SpaceProps> } => ({
  classNames: classNames(
    spacePropNames
      .filter((name) => Boolean(props[name]))
      .map((name) => `u-${name}${props[name]}`)
  ),
  otherProps: omit(props, spacePropNames),
})
