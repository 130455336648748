import * as React from 'react'
import { Text } from '@components/Text'
import { HSpace } from '@components/HSpace'
import { Icon } from '@components/Icon'
import { VSpace } from '@components/VSpace'
import styles from './FlightCancelled.module.css'

export interface FlightCancelledProps {
  from: string | null
  to: string | null
}

export const FlightCancelled: React.FC<FlightCancelledProps> = ({
  from,
  to,
}) => (
  <HSpace space={24} alignCenter data-selector="fs-show">
    <Icon name="close" className={styles['flight-cancelled__icon']} size={24} />
    <VSpace space={0}>
      <Text color="grey-600">
        Flight from{' '}
        <Text
          color="grey-900"
          asElement="span"
          className={styles['text--mono']}
        >
          {from}
        </Text>{' '}
        to{' '}
        <Text
          color="grey-900"
          asElement="span"
          className={styles['text--mono']}
        >
          {to}
        </Text>{' '}
        has been cancelled and not yet replaced
      </Text>
      <Text color="grey-600" fontSize="C2">
        Click “request further changes” to find an alternative flight.
      </Text>
    </VSpace>
  </HSpace>
)
