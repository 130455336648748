import { DuffelAPI } from '@lib/types'
import { makeMockAccessToken } from './make-mock-access-token'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.Organisation => ({
    accessTokens: [makeMockAccessToken()],
    avatarUrl: '',
    createdAt: '',
    id: `org_${(seedId++).toString().padStart(5, '0')}`,
    name: `Test Organisation ${(seedId++).toString().padStart(5, '0')}`,
    settlementCurrency: 'GBP',
    slug: `testOrg${(seedId++).toString().padStart(5, '0')}`,
    verified: true,
    legalEntity: {
      name: `Test Organisation ${(seedId++).toString().padStart(5, '0')}`,
      type: 'corporation',
      typeExtra: null,
      tradingName: null,
      registeredBusinessAddressLine1: '',
      registeredBusinessAddressLine2: null,
      registeredBusinessAddressCity: '',
      registeredBusinessAddressRegion: null,
      registeredBusinessAddressPostalCode: '',
      registeredBusinessAddressCountryCode: '',
      registrationNumber: '',
      registrationCountryCode: '',
      taxIdentificationNumber: '',
      keyContactFirstName: '',
      keyContactLastName: '',
      keyContactJobTitle: '',
      keyContactEmail: '',
      initialTopUpSourceOfFunds: 'revenue',
      initialTopUpSourceOfFundsDescription: null,
      submittedAt: '',
    },
    stripeCustomerId: '',
    stripePaymentMethodId: '',
    verificationFlow: 'stripe_connect',
    isDuffelLinksEnabled: true,
    staysAccessStatus: 'live',
    sessionExpiryTimeout: 30,
    passwordExpiryTimeoutDays: null,
  })
})()

export const makeMockOrganisation = (
  extendDefault?: Partial<DuffelAPI.Types.Organisation>
): DuffelAPI.Types.Organisation =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
