import { captureException } from '@lib/sentry'
import {
  APIResponse,
  BaseClientConfig,
  DuffelAPI,
  DuffelContext,
  DuffelProxy,
  JwtPayload,
  StripeConnectVerificationStatus,
} from '@lib/types'
import { BaseClient } from './lib'

export class Identity extends BaseClient {
  constructor(config: BaseClientConfig) {
    super(config)
  }

  async getSelf(ctx?: DuffelContext, noCache?: boolean) {
    return this.rawRequest<
      APIResponse<{
        firebaseToken: string
        self: DuffelProxy.Types.Self
        permissions?: JwtPayload
      }>
    >({
      method: 'GET',
      url: `/api/identity/self${noCache ? '?no-cache=true' : ''}`,
      ...this.getRequestOptions(ctx),
    })
  }

  updateSelf(data: DuffelAPI.Inputs.UpdateUser, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.User>({
      method: 'PATCH',
      url: '/api/identity/users/self',
      data,
      ...this.getRequestOptions(ctx),
    })
  }

  createOrganisation(
    data: DuffelAPI.Inputs.CreateOrganisation,
    ctx?: DuffelContext
  ) {
    return this.request<DuffelAPI.Types.Organisation>({
      method: 'POST',
      url: '/api/identity/organisations',
      data,
      ...this.getRequestOptions(ctx),
    })
  }

  getOrganisation(id: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.Organisation>({
      method: 'GET',
      url: `/api/identity/organisations/${id}`,
      ...this.getRequestOptions(ctx),
    })
  }

  getLegalEntity(ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.LegalEntity>({
      method: 'GET',
      url: `/api/identity/organisations/self/legal_entity`,
      ...this.getRequestOptions(ctx),
    })
  }

  deleteSelf(ctx?: DuffelContext) {
    return this.request<Record<string, unknown>>({
      method: 'DELETE',
      url: `/api/identity/users/self`,
      ...this.getRequestOptions(ctx),
    })
  }

  signIn(data: { email: string; password: string }, ctx?: DuffelContext) {
    return this.request<DuffelProxy.Types.Self>({
      method: 'POST',
      url: '/api/identity/signin',
      data,
      ...this.getRequestOptions(ctx),
    })
  }

  signUp(data: DuffelAPI.Inputs.UserSignup, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.User>({
      method: 'POST',
      url: '/api/identity/signup',
      data,
      ...this.getRequestOptions(ctx),
    })
  }

  signOut(ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.User>({
      method: 'POST',
      url: '/api/identity/signout',
      ...this.getRequestOptions(ctx),
    })
  }

  getInvitation(token: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.Invitation>({
      method: 'GET',
      url: `/api/identity/organisation_invitation_tokens/${token}`,
      ...this.getRequestOptions(ctx),
    })
  }

  acceptInvitation(token: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.Invitation>({
      method: 'POST',
      url: `/api/identity/organisation_invitations/${token}/accept`,
      ...this.getRequestOptions(ctx),
    })
  }

  declineInvitation(token: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.Invitation>({
      method: 'POST',
      url: `/api/identity/organisation_invitations/${token}/revoke`,
      ...this.getRequestOptions(ctx),
    })
  }

  requestEmailConfirmation(ctx?: DuffelContext) {
    return this.request<Record<string, unknown>>({
      method: 'POST',
      url: `/api/identity/users/confirm_email/resend`,
      data: { id: 'self' },
      ...this.getRequestOptions(ctx),
    })
  }

  confirmEmail(token: string, ctx?: DuffelContext) {
    return this.request<Record<string, unknown>>({
      method: 'POST',
      url: `/api/identity/confirm_email/${token}`,
      ...this.getRequestOptions(ctx),
    })
  }

  requestPasswordReset(email: string, ctx?: DuffelContext) {
    return this.request<Record<string, unknown>>({
      method: 'POST',
      url: `/api/identity/password_reset_tokens`,
      data: { email },
      ...this.getRequestOptions(ctx),
    })
  }

  getPasswordResetToken(token: string, ctx?: DuffelContext) {
    return this.request<{ user: DuffelAPI.Types.User }>({
      method: 'GET',
      url: `/api/identity/password_reset_tokens/${token}`,
      ...this.getRequestOptions(ctx),
    })
  }

  resetPassword(
    data: {
      token: string
      password: string
    },
    ctx?: DuffelContext
  ) {
    const { token, password } = data
    return this.request<DuffelAPI.Types.User>({
      method: 'POST',
      url: `/api/identity/password_reset_tokens/${token}/use`,
      data: { password },
      ...this.getRequestOptions(ctx),
    })
  }

  async createStripeConnectOnboardingURLs(
    data: DuffelAPI.Inputs.StripeConnectOnboardingURLs,
    ctx?: DuffelContext
  ) {
    return this.request<DuffelAPI.Types.StripeConnectOnboardingURLs>({
      method: 'POST',
      url: '/api/identity/verification/stripe_connect_onboarding_urls',
      data,
      ...this.getRequestOptions(ctx),
    })
  }

  getStripeConnectVerificationStatus(ctx?: DuffelContext) {
    return this.request<{
      stripeConnectVerificationStatus: StripeConnectVerificationStatus
    }>({
      method: 'GET',
      url: `/api/identity/verification/stripe_connect_verification_status`,
      ...this.getRequestOptions(ctx),
    })
  }

  async createComponentClientKey(ctx?: DuffelContext) {
    const response = await this.request<{ componentClientKey: string }>({
      method: 'POST',
      url: `/api/identity/component_client_keys`,
      ...this.getRequestOptions(ctx),
    })

    if (
      !response.data ||
      !response.data.componentClientKey ||
      Array.isArray(response.errors)
    ) {
      captureException(new Error(`Failed to create component client key`), {
        response: JSON.stringify(response),
      })
    }

    return response
  }
}
