import { getDurationDifferenceString } from '@lib/date'
import { DuffelAPI } from '@lib/types'
import { getSliceDetails } from './get-slice-details'

export const getLayoverDiffs = (
  beforeSlices: DuffelAPI.Types.OrderSlice[],
  afterSlices: DuffelAPI.Types.OrderSlice[]
) => {
  const beforeLayovers = beforeSlices
    .map((beforeSlice) => getSliceDetails(beforeSlice))
    .flatMap((sliceDetails) =>
      sliceDetails.filter((details) => details.type === 'layover')
    )

  const afterLayovers = afterSlices
    .map((afterSlice) => getSliceDetails(afterSlice))
    .flatMap((sliceDetails) =>
      sliceDetails.filter((details) => details.type === 'layover')
    )

  const layoverDetails = afterLayovers.map((afterLayover) => {
    const matchedBeforeLayover = beforeLayovers.find(
      (beforeLayover) =>
        beforeLayover.layoverDetails?.originDestinationKey ===
        afterLayover.layoverDetails?.originDestinationKey
    )

    const layoverTimeDifference =
      matchedBeforeLayover?.layoverDetails?.duration &&
      afterLayover?.layoverDetails?.duration &&
      getDurationDifferenceString(
        matchedBeforeLayover?.layoverDetails?.duration,
        afterLayover?.layoverDetails.duration
      )

    return {
      layoverTimeDifference: layoverTimeDifference,
      airport: afterLayover.layoverDetails?.airport.iataCode,
      duration: afterLayover.layoverDetails?.duration,
      originDestinationKey: afterLayover.layoverDetails?.originDestinationKey,
      isAddedLayover: !matchedBeforeLayover,
    }
  })

  return layoverDetails
}
