import classNames from 'classnames'
import * as React from 'react'
import { Icon } from '@components/Icon'
import { useTableContext } from '../Table'
import styles from './TableHeader.module.css'

export interface TableHeaderProps {
  alignRight?: boolean

  width?: string
  /**
   * is Table header sortable? If so, pass sortMetadata
   */
  sortMetadata?: SortMetadata

  /**
   * What should the row height be in pixels? This will determine the cell padding
   * - - -
   * Default: `56`
   *
   *
   * Note: since `box-sizing` is set to `border-box`, the height of the component is
   * actually either `42px` or `58px` tall since it includes the two `1px` borders or
   * the top and bottom.
   */
  rowHeight?: 40 | 56

  /**
   * Display top border
   */
  topBorder?: boolean

  /** Scope of <th> cell  element*/
  scope?: HTMLTableCellElement['scope']

  className?: string
}

type SortStatus = 'ascending' | 'descending' | 'inactive'

export type SortMetadata = {
  sortStatus: SortStatus
  onClick: (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => void
}

export const TableHeader: React.FC<
  React.PropsWithChildren<TableHeaderProps>
> = ({
  alignRight,
  children,
  width,
  sortMetadata,
  rowHeight,
  topBorder = true,
  scope = 'col',
  className,
}) => {
  const { rowHeight: rowHeightFromContext } = useTableContext()
  const height = rowHeight || rowHeightFromContext
  const isTableHeaderSortable = sortMetadata
  const renderIcon = () => {
    switch (sortMetadata?.sortStatus) {
      case 'ascending':
        return <Icon size={12} name="arrow_up" />
      case 'inactive':
      case 'descending':
        return <Icon size={12} name="arrow_down" />
    }
  }

  return (
    <th
      onClick={sortMetadata?.onClick}
      className={classNames('th', className, styles['th'], {
        [styles['th--is-sortable']]: isTableHeaderSortable,
        [styles['th--inactive']]:
          isTableHeaderSortable && sortMetadata?.sortStatus === 'inactive',
        [styles['th--is-sortable']]: isTableHeaderSortable,
        [styles['th--40']]: height === 40,
        [styles['th--top-border']]: topBorder,
      })}
      style={{ width }}
      data-selector="fs-show"
      data-testid="table-header-element"
      scope={scope}
    >
      <span
        className={classNames(
          styles['th__content'],
          alignRight && styles['th__content--align-right']
        )}
      >
        {children}
        {isTableHeaderSortable && (
          <span
            className={classNames(styles['th__icon-wrapper'], {
              [styles['th__icon-wrapper--inactive']]:
                isTableHeaderSortable &&
                sortMetadata?.sortStatus === 'inactive',
            })}
            aria-hidden
            data-testid="table-header-icon-wrapper"
          >
            {renderIcon()}
          </span>
        )}
      </span>
    </th>
  )
}
