import { DuffelAPI } from '@lib/types'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.OrderPassenger => {
    const id = (seedId++).toString().padStart(5, '0')
    return {
      type: 'adult',
      title: 'mr',
      infantPassengerId: null,
      id: `pas_${id}`,
      givenName: 'Martin',
      gender: 'm',
      familyName: 'Lockheed',
      bornOn: '1952-08-16',
      email: 'traveller@example.com',
      phoneNumber: '+16177562626',
      loyaltyProgrammeAccounts: [
        {
          accountNumber: '123456789',
          airlineIataCode: 'ZZ',
        },
      ],
    }
  }
})()

export const makeMockOrderPassenger = (
  extendDefault?: Partial<DuffelAPI.Types.OrderPassenger>,
  passengerId?: DuffelAPI.Types.OrderPassenger['id']
): DuffelAPI.Types.OrderPassenger => {
  const mockOrderPassenger = Object.assign(
    {},
    getDefaultObject(),
    extendDefault || {}
  )

  if (passengerId) {
    mockOrderPassenger.id = passengerId
  }

  return mockOrderPassenger
}
