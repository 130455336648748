import classNames from 'classnames'
import * as React from 'react'
import { ColorWithoutWeight } from '@components/legacy-design-system/product/styles'
import { HSpace } from '@components/HSpace'
import { Text } from '@components/Text'
import { Icon } from '@components/Icon'
import styles from './Switch.module.css'

export interface SwitchProps {
  switchedOn: boolean
  onChange?: (value: boolean) => void
  disabled?: boolean
  size?: 'small' | 'medium'
  color?: Extract<ColorWithoutWeight, 'yellow' | 'purple' | 'green'>
  label?: React.ReactNode
  labelPosition?: 'leading' | 'trailing'
  className?: string
  type: React.ButtonHTMLAttributes<HTMLButtonElement>['type']
}

export const Switch: React.FC<SwitchProps> = ({
  switchedOn,
  onChange,
  disabled = false,
  size = 'medium',
  color = 'purple',
  label = '',
  labelPosition = 'trailing',
  className = '',
  type,
}) => {
  const renderLabel = () => {
    if (label) {
      return typeof label === 'string' ? (
        <Text
          color={switchedOn ? 'grey-900' : 'grey-600'}
          fontSize={size === 'small' ? 'C2' : 'C1'}
        >
          {label}
        </Text>
      ) : (
        label
      )
    }
    return null
  }

  return (
    <HSpace
      space={8}
      alignCenter
      className={classNames(className, styles['outer-container'])}
      asElement="button"
      onClick={() => !disabled && onChange?.(!switchedOn)}
      type={type}
    >
      {labelPosition === 'leading' && renderLabel()}
      <div
        className={classNames(
          styles['container'],
          styles[`container--${color}`],
          {
            [styles['container--switched-on']]: switchedOn,
            [styles['container--disabled']]: disabled,
            [styles['container--small']]: size === 'small',
          }
        )}
      >
        <div className={styles['switch']}>
          {size === 'medium' && (
            <Icon name={switchedOn ? 'check' : 'close'} size={16} />
          )}
        </div>
      </div>
      {labelPosition === 'trailing' && renderLabel()}
    </HSpace>
  )
}
