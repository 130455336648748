import * as React from 'react'
import { Space } from '@components/legacy-design-system/product/styles'
import { VSpace } from '@components/VSpace'
import { Heading } from '@components/Heading'
import { HSpace } from '@components/HSpace'
import { OrderHeadingBlockBadge } from '../OrderActivity/OrderHeadingBlockBadge'

export interface OrderHeadingBlockProps {
  title: string
  children: React.ReactNode
  warningBadge?: string
  spaceOverride?: Space
}

export const OrderHeadingBlock: React.FC<OrderHeadingBlockProps> = ({
  title,
  children,
  warningBadge,
  spaceOverride,
}) => (
  <VSpace space={spaceOverride ?? 32} asElement="section">
    <HSpace space={8} alignCenter>
      <Heading h4 asElement="h2" color="purple-900" data-selector="fs-show">
        {title}
      </Heading>
      {warningBadge && (
        <OrderHeadingBlockBadge
          icon="warning"
          badgeType="warning"
          tooltipText={warningBadge}
        />
      )}
    </HSpace>
    {children}
  </VSpace>
)
