import classNames from 'classnames'
import * as React from 'react'
import { ColorWithoutWeight } from '@components/legacy-design-system/product/styles'
import { Icon } from '@components/Icon'
import { Text } from '@components/Text'
import { HSpace } from '@components/HSpace'
import { VSpace } from '@components/VSpace'
import styles from './RadioButton.module.css'

export interface RadioButtonProps<T extends string | number> {
  value: T
  onChange?: (value: boolean) => void
  checked?: boolean
  disabled?: boolean
  label?: string
  description?: React.ReactNode
  radioType?: 'tick' | 'dot'
  color?: Extract<ColorWithoutWeight, 'purple' | 'green'>
  fill?: boolean
  className?: string
}

export const RadioButton: React.FC<RadioButtonProps<any>> = ({
  value,
  checked = false,
  disabled = false,
  label,
  description,
  color = 'purple',
  radioType = 'tick',
  fill = false,
  className,
  onChange,
  ...props
}) => {
  const inputId = `radio-${value}`
  return (
    <div
      className={classNames(styles['wrapper'], {
        [styles['wrapper--fill']]: fill,
      })}
    >
      <input
        id={inputId}
        type="radio"
        value={value}
        checked={checked}
        disabled={disabled}
        className={styles['input']}
        onChange={() => {
          if (!disabled) onChange?.(!checked)
        }}
        {...props}
      />
      <label
        className={classNames(
          className,
          styles['container'],
          styles[`container--${color}`],
          {
            [styles['container--tick']]: radioType === 'tick',
            [styles['container--dot']]: radioType === 'dot',
            [styles['container--is-disabled']]: disabled,
          }
        )}
        htmlFor={inputId}
        aria-checked={checked}
        data-testid={value}
      >
        <HSpace space={radioType === 'tick' ? 12 : 8}>
          <div
            className={classNames(styles['radio'], {
              [styles['radio--is-checked']]: checked,
            })}
          >
            {radioType === 'tick' && checked && <Icon name="check" size={18} />}
            {radioType === 'dot' && checked && (
              <div className={styles['radio__dot']} />
            )}
          </div>

          {label && (
            <VSpace space={4}>
              {label && (
                <Text
                  className={styles['label']}
                  fontSize={radioType === 'tick' ? 'C1' : 'C2'}
                  color="grey-900"
                >
                  {label}
                </Text>
              )}
              {description && (
                <Text fontSize="C2" color="grey-600">
                  {description}
                </Text>
              )}
            </VSpace>
          )}
        </HSpace>
      </label>
    </div>
  )
}
