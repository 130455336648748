import type { IncomingHttpHeaders } from 'http'
import { DuffelAPI } from '@lib/types'
import { client } from './client'
import { NotAuthorisedError } from './error-responses'

export const fetchLiveAndTestTokens = async (
  organisationSlug: string | undefined,
  token: string | undefined,
  requestHeaders: IncomingHttpHeaders
) => {
  if (!token || !organisationSlug) throw new NotAuthorisedError()

  const { body: selfResponseBody, status: selfResponseStatus } =
    await client<DuffelAPI.Types.User>(
      'GET',
      '/identity/self',
      token,
      requestHeaders,
      undefined
    )
  if (!selfResponseBody || !selfResponseBody.data || selfResponseStatus != 200)
    throw Error('The API did not return a response body on GET self')

  // retrieve prod and test tokens
  const self = selfResponseBody.data
  const organisationMembership = self.organisationMemberships?.find(
    (membership) => membership.organisation.slug === organisationSlug
  )
  if (
    !organisationMembership ||
    !organisationMembership.organisation.accessTokens
  )
    throw Error(`The current organisation was not found in user memberships`)

  const activeTokens = organisationMembership.organisation.accessTokens.filter(
    (token) => token.expiresAt && new Date(token.expiresAt) > new Date()
  )

  const testToken = activeTokens.find((token) => token.liveMode === false)
  if (!testToken)
    throw Error(
      'It was not possible to find a test token for the current organisation'
    )
  const liveToken = activeTokens.find((token) => token.liveMode === true)

  return { testToken, liveToken }
}
