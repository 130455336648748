import { serialize } from 'tinyduration'
import { captureException } from '@lib/sentry'
import { iso8601DurationRegex } from './constants'
import { getDurationDifference } from './get-duration-difference'

const durationStringRegex = /(\d+d)? ?(\d+h)? ?(\d+m?)?/i

const getFormattedDurationDifferenceString = (
  isNegative = false,
  daysDifference = 0,
  hoursDifference = 0,
  minutesDifference = 0
): string => {
  let toReturn = ''

  if (daysDifference) {
    toReturn = toReturn.concat(
      Math.abs(daysDifference).toString(),
      Math.abs(daysDifference) === 1 ? ' day' : ' days',
      Math.abs(hoursDifference) > 0 ? ', ' : ''
    )
  }

  if (hoursDifference) {
    toReturn = toReturn.concat(
      Math.abs(hoursDifference).toString(),
      Math.abs(hoursDifference) === 1 ? ' hour' : ' hours',
      Math.abs(minutesDifference) > 0 ? ' and ' : ''
    )
  }

  if (minutesDifference) {
    toReturn = toReturn.concat(
      Math.abs(minutesDifference).toString(),
      Math.abs(minutesDifference) === 1 ? ' minute' : ' minutes'
    )
  }

  // Handle negatives

  if (daysDifference < 0) {
    isNegative = true
  } else if (daysDifference === 0 && hoursDifference < 0) {
    isNegative = true
  } else if (
    daysDifference === 0 &&
    hoursDifference === 0 &&
    minutesDifference < 0
  ) {
    isNegative = true
  }

  return `${isNegative ? '-' : ''}${toReturn}`
}

/**
 * This function returns a formatted duration string, taking two datetimes
 * in either ISO-8601 format or in DDd HHh MMm format.
 *
 * @param beforeTime
 * @param afterTime
 * @returns
 */
export const getDurationDifferenceString = (
  beforeTime: string,
  afterTime: string
) => {
  if (
    beforeTime.match(iso8601DurationRegex) &&
    afterTime.match(iso8601DurationRegex)
  ) {
    const { isNegative, daysDifference, hoursDifference, minutesDifference } =
      getDurationDifference(beforeTime, afterTime)

    return getFormattedDurationDifferenceString(
      isNegative,
      daysDifference,
      hoursDifference,
      minutesDifference
    )
  } else {
    // Compare durations in DDd HHh MMm format
    let beforeMatches
    let afterMatches

    try {
      beforeMatches = beforeTime.match(durationStringRegex)
      afterMatches = afterTime.match(durationStringRegex)
    } catch (err) {
      captureException(
        new Error(`Check duration format: ${beforeMatches}, ${afterMatches}`)
      )
      return ''
    }

    if (!beforeMatches && !afterMatches) return ''

    const beforeDays =
      beforeMatches[1] && beforeMatches[1] !== '0'
        ? parseInt(beforeMatches[1].split('d'))
        : 0
    const beforeHours =
      beforeMatches[2] && beforeMatches[2] !== '0'
        ? parseInt(beforeMatches[2].split('h'))
        : 0
    const beforeMinutes =
      beforeMatches[3] && beforeMatches[3] !== '0'
        ? parseInt(beforeMatches[3].split('m'))
        : 0

    const afterDays =
      afterMatches[1] && afterMatches[1] !== '0'
        ? parseInt(afterMatches[1].split('d'))
        : 0
    const afterHours =
      afterMatches[2] && afterMatches[2] !== '0'
        ? parseInt(afterMatches[2].split('h'))
        : 0
    const afterMinutes =
      afterMatches[3] && afterMatches[3] !== '0'
        ? parseInt(afterMatches[3].split('m'))
        : 0

    const beforeISOString = serialize({
      days: beforeDays,
      hours: beforeHours,
      minutes: beforeMinutes,
    })

    const afterISOString = serialize({
      days: afterDays,
      hours: afterHours,
      minutes: afterMinutes,
    })

    const { isNegative, daysDifference, hoursDifference, minutesDifference } =
      getDurationDifference(beforeISOString, afterISOString)

    return getFormattedDurationDifferenceString(
      isNegative,
      daysDifference,
      hoursDifference,
      minutesDifference
    )
  }
}
