export const rootPathsMap = {
  confirmEmail: '/confirm-email',
  confirmEmailShow: '/confirm-email/[token]',
  index: '/',
  joinIndex: '/join',
  joinShow: '/join/[token]',
  resetPasswordShow: '/reset-password/[token]',
  organisationCreate: '/create-team',
  resetPasswordIndex: '/reset-password',
  settingsIndex: '/user/settings',
  signInIndex: '/sign-in',
  stripeVerificationRedirect: '/stripe-verification-redirect',
  error: '/error',
} as const

export type RootPaths = keyof typeof rootPathsMap
