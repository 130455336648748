import {
  BorderRadius,
  Space,
} from '@components/legacy-design-system/product/styles'
import classNames from 'classnames'
import * as React from 'react'
import styles from './Card.module.css'

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>
export interface CardProps
  extends Pick<DivProps, 'onClick' | 'tabIndex' | 'onKeyDown'> {
  className?: string
  /**
   * Horizontally centers child elements.
   */
  center?: boolean
  padding?: Extract<Space, 24 | 32>
  borderRadius?: Extract<BorderRadius, 4 | 8>
  shadowDepth?: 1 | 2
}

export const Card = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<CardProps>
>(
  (
    {
      className,
      children,
      center,
      padding = 32,
      borderRadius = 4,
      shadowDepth,
      ...props
    },
    ref
  ) => {
    const cardClassNames = classNames(className, styles['card'], {
      [styles['card--center']]: center,
    })

    const borderRadiusValue = `var(--border-radius-${borderRadius})`

    return (
      <div
        ref={ref}
        className={cardClassNames}
        style={{
          padding: `var(--space-${padding})`,
          borderRadius: borderRadiusValue,
          ...(shadowDepth
            ? { boxShadow: `var(--shadow-depth-${shadowDepth})` }
            : {}),
        }}
        {...props}
      >
        {children}
      </div>
    )
  }
)
