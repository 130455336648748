import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'
import { Icon } from '@components/Icon'
import { PopoverContainer } from '@components/PopoverContainer'
import { useDuffelPopper } from '@lib/hooks'
import { searchPathObject } from '@lib/paths/search-path-object'
import { trackEvent } from '@lib/tracking'
import { TrackingEventMetadataMap } from '@lib/tracking/types'

interface SearchMenuProps {
  label: string
  options: { [key: string]: string }
  urlQueryParam: string
  customIsOptionSelected?: (value, selectedValue) => boolean
  selectedOptionLabelModifier?: string
}

export const SearchMenu: React.FC<SearchMenuProps> = ({
  label,
  options,
  urlQueryParam,
  customIsOptionSelected,
  selectedOptionLabelModifier,
}) => {
  const [activeOptionIndex, setActiveOptionIndex] = React.useState(0)
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
  const onClosePopover = () => setIsPopoverOpen(false)

  const router = useRouter()
  const currentValue =
    typeof router.query[urlQueryParam] === 'string'
      ? (router.query[urlQueryParam] as string)
      : ''
  const isSelected = (value, selectedValue) =>
    customIsOptionSelected
      ? customIsOptionSelected(value, selectedValue)
      : value === selectedValue
  const SelectionIndicator = () => (
    <>
      {selectedOptionLabelModifier} <Icon name="check" />
    </>
  )
  const { offerRequestId } = router.query
  const offer_request_id =
    typeof offerRequestId === 'string' ? offerRequestId : ''

  const {
    popper: { styles, attributes },
    setReferenceElement,
    setPopperElement,
  } = useDuffelPopper(
    isPopoverOpen,
    onClosePopover,
    {
      placement: 'bottom-start',
      modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
    },
    { shouldInsideClickClose: true }
  )

  const menuEvent =
    `dashboard_search_results_${label.toLowerCase()}_menu_clicked` as
      | 'dashboard_search_results_sort_menu_clicked'
      | 'dashboard_search_results_stops_menu_clicked'

  const optionEvent =
    `dashboard_search_results_${label.toLowerCase()}_option_clicked` as
      | 'dashboard_search_results_sort_option_clicked'
      | 'dashboard_search_results_stops_option_clicked'

  return (
    <div className="search-results--filter">
      <button
        ref={setReferenceElement}
        tabIndex={0}
        onClick={() => {
          trackEvent(menuEvent, {
            event_type: 'interaction',
            offer_request_id,
          })
          setIsPopoverOpen(!isPopoverOpen)
        }}
        onKeyDown={(event) => {
          if (event.key === 'ArrowDown') {
            event.preventDefault()
            setActiveOptionIndex(
              Math.min(activeOptionIndex + 1, Object.keys(options).length - 1)
            )
          }

          if (event.key === 'ArrowUp') {
            event.preventDefault()
            setActiveOptionIndex(Math.max(activeOptionIndex - 1, 0))
          }

          if (event.key === 'Tab') {
            onClosePopover()
          }

          if (event.key === 'Enter') {
            trackEvent(optionEvent, {
              event_type: 'interaction',
              offer_request_id,
              [label.toLowerCase()]: options[activeOptionIndex],
            } as TrackingEventMetadataMap[typeof optionEvent])
            document
              .getElementById(
                `search-results--filter-menu-item-${urlQueryParam}-${activeOptionIndex}`
              )
              ?.click()
          }
        }}
      >
        {label} <Icon name="unfold_more" size={14} />
      </button>
      {isPopoverOpen && (
        <PopoverContainer
          ref={setPopperElement}
          style={{ zIndex: 50, ...styles.popper }}
          {...attributes.popper}
        >
          <div className="search-results--filter-menu">
            {Object.keys(options).map((key, index) => (
              /**
               * Disabling jsx-a11y here because this is a valid use; wrapping an <a /> in a Next.JS Link is fine
               * but eslint isn't happy with it.
               */

              /* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
              <Link
                key={key}
                {...searchPathObject(
                  router.pathname,
                  router.query,
                  urlQueryParam,
                  key
                )}
                passHref
                legacyBehavior
              >
                <a
                  id={`search-results--filter-menu-item-${urlQueryParam}-${index}`}
                  className={classNames('search-results--filter-menu-item', {
                    'search-results--filter-menu-item--active':
                      activeOptionIndex === index,
                  })}
                  onMouseEnter={() => setActiveOptionIndex(index)}
                  href="#"
                  onClick={() => {
                    trackEvent(optionEvent, {
                      event_type: 'interaction',
                      offer_request_id,
                      [label.toLowerCase()]: key,
                    } as TrackingEventMetadataMap[typeof optionEvent])
                  }}
                >
                  {options[key]}{' '}
                  {isSelected(key, currentValue) && <SelectionIndicator />}
                </a>
              </Link>
              /* eslint-enable */
            ))}
          </div>
        </PopoverContainer>
      )}
      <style jsx>{`
        .search-results--filter {
          display: flex;
        }

        .search-results--filter > button {
          appearance: none;
          border: 1px solid transparent;
          background: inherit;
          font-family: var(--FONTS-SANS);
          font-size: 10px;
          font-weight: bold;
          line-height: 150%;
          color: var(--grey-700);
          display: flex;
          align-items: center;
          margin: 40px 24px 16px 0;
          cursor: pointer;
          padding: 0;
        }

        .search-results--filter > button:focus {
          outline: none;
        }

        .search-results--filter > button:focus-visible {
          border: 1px solid var(--purple-500);
          box-shadow: var(--shadow-focus);
          border-radius: var(--border-radius-4);
        }

        .search-results--filter-menu {
          display: flex;
          flex-direction: column;
          width: 220px;
        }

        :global(.search-results--filter-menu-item) {
          appearance: none;
          border: none;
          background: inherit;
          text-decoration: none !important;
          color: var(--black) !important;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: var(--border-radius-4);
          padding: 8px;
          font-size: 16px;
          line-height: 150%;
        }

        :global(
            .search-results--filter-menu-item:hover,
            .search-results--filter-menu-item:focus,
            .search-results--filter-menu-item:active,
            .search-results--filter-menu-item--active
          ) {
          background-color: var(--purple-100);
          outline: none;
        }

        :global(.search-results--filter-menu-item > svg) {
          color: var(--purple-500);
        }
      `}</style>
    </div>
  )
}
