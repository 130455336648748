import { OfferRequestPassengerWithIndex } from '@modules/air-search-v2'

const getDefaultObject = (() => {
  return (): OfferRequestPassengerWithIndex => ({
    index: 0,
    type: 'adult',
    loyaltyProgrammeAccounts: [{ airlineIataCode: '', accountNumber: '' }],
  })
})()

export const makeMockOfferRequestPassengerWithIndex = (
  extendDefault?: Partial<OfferRequestPassengerWithIndex>
): OfferRequestPassengerWithIndex =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
