import * as React from 'react'
import classnames from 'classnames'
import {
  BorderRadius,
  ColorWithoutWeight,
} from '@components/legacy-design-system/product/styles'
import { Icon, IconName } from '@components/Icon'
import styles from './Stamp.module.css'

export interface StampProps {
  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string

  /**
   * What color theme to use
   */
  color?: Exclude<ColorWithoutWeight, 'white' | 'orange'> | 'light-grey'

  /**
   * The contents of the stamp
   */
  label?: string

  /**
   * An Icon to show after the label
   */
  icon?: IconName

  /**
   * How large should the stamp be?
   */
  size?: 'small' | 'medium'

  /**
   * Set the size of the border-radius if border is present
   */
  borderRadius?: Extract<BorderRadius, 4 | 'full'>

  /**
   * Should the stamp have a border?
   */
  border?: boolean
}

/**
 * A stamp is a short label associated with the parent element it describes.
 */
export const Stamp: React.FC<React.PropsWithChildren<StampProps>> = ({
  className,
  size = 'MEDIUM',
  color = 'grey',
  icon,
  borderRadius = 4,
  border,
  label,
  children,
  ...props
}) => {
  const cx = classnames(
    className,
    [styles['stamp']],
    [styles[`stamp--${color}`]],
    [styles[`stamp--${size.toLowerCase()}`]],
    [styles[`stamp--border-radius-${borderRadius}`]],
    {
      [styles['stamp--border']]: border,
      [styles['stamp--with-icon']]: icon,
    }
  )

  return (
    <span className={cx} {...props}>
      {icon && <Icon className="stamp__icon stamp__icon--left" name={icon} />}
      {label}
      {children}
    </span>
  )
}
