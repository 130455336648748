export const ACCEPTED_CURRENCIES = {
  EUR: {
    code: 'EUR',
    name: 'Euro',
    locale: 'fr-FR',
  },
  GBP: {
    code: 'GBP',
    name: 'Pound Sterling',
    locale: 'en-GB',
  },
  USD: {
    code: 'USD',
    name: 'United States Dollar',
    locale: 'en-US',
  },
  AUD: {
    code: 'AUD',
    name: 'Australian Dollar',
    locale: 'en-AU',
  },
  CAD: {
    code: 'CAD',
    name: 'Canadian Dollar',
    locale: 'en-CA',
  },
}

export type AcceptedCurrencies = keyof typeof ACCEPTED_CURRENCIES
