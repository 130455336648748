import * as React from 'react'
import classNames from 'classnames'
import { VSpace } from '@components/VSpace'
import { Text } from '@components/Text'
import { Icon } from '@components/Icon'
import { convertRelativeDatePartsToString } from '@lib/date'

interface ConfirmAndHoldCondition {
  holdSpaceDate?: Intl.RelativeTimeFormatPart[]
  holdPriceDate?: Intl.RelativeTimeFormatPart[]
}

export const ConfirmAndHoldCondition: React.FC<ConfirmAndHoldCondition> = ({
  holdPriceDate,
  holdSpaceDate,
}) => {
  const priceState = holdPriceDate ? 'check' : 'close'
  return (
    <VSpace space={24}>
      {holdSpaceDate && (
        <div className="ff-pl__conditions">
          <Icon
            name={holdSpaceDate ? 'check' : 'close'}
            className="ff-icon-pl__paylater"
          />
          <Text color="grey-600" fontSize="C1">
            {`Space on this trip will be guaranteed ${convertRelativeDatePartsToString(
              holdSpaceDate,
              true
            )}. After this, the guarantee will expire and the space will
              be released.`}
          </Text>
        </div>
      )}
      <div className="ff-pl__conditions">
        <Icon
          name={priceState}
          className={classNames('ff-icon-pl__paylater', {
            'ff-icon-pl__paylater--close': priceState === 'close',
          })}
        />
        <Text color="grey-600" fontSize="C1">
          {holdPriceDate
            ? `This price will be guaranteed ${
                holdPriceDate &&
                convertRelativeDatePartsToString(holdPriceDate, true)
              }. After this, the guarantee will expire and the price may change.`
            : `This isn't a price guarantee. The price may change between holding the space and paying.`}
        </Text>
      </div>
      <style jsx>{`
        .ff-pl__conditions :global(.ff-icon-pl__paylater) {
          color: var(--green-600);
          margin-right: var(--space-16);
        }
        .ff-pl__conditions
          :global(.ff-icon-pl__paylater.ff-icon-pl__paylater--close) {
          color: var(--pink-500);
        }
        .ff-pl__conditions {
          display: flex;
          align-items: end;
          max-width: 352px;
        }
        .ff-pl__conditions > :global(.ff-icon-pl__paylater) {
          flex: 1 0 auto;
        }
      `}</style>
    </VSpace>
  )
}
