import * as React from 'react'
import { IndicatorStamp, IndicatorStampStatus } from './IndicatorStamp'
import { passwordRequirements, passwordValidationSchema } from '.'

interface RequirementStampsProps {
  value?: string
  isFieldSubmitted: boolean
}

export const RequirementStamps: React.FC<RequirementStampsProps> = ({
  value,
  isFieldSubmitted,
}) => {
  const [errors, setErrors] = React.useState<string[]>([])

  React.useEffect(() => {
    try {
      passwordValidationSchema.validateSync(value, { abortEarly: false })
      setErrors([])
    } catch (error: any) {
      setErrors(error.errors)
    }
  }, [value])

  const getRequirementStatus = (
    requirementMessage: string
  ): IndicatorStampStatus => {
    if (isFieldSubmitted && errors.includes(requirementMessage)) {
      return 'invalid'
    } else if (
      value &&
      value.length > 0 &&
      !errors.includes(requirementMessage)
    ) {
      return 'valid'
    } else {
      return 'neutral'
    }
  }

  return (
    <div className="requirement-stamps u-marginTop12">
      {passwordRequirements.map((requirement, index) => {
        const status = getRequirementStatus(requirement.message)
        if (
          !requirement.onlyDisplayIfInvalid ||
          (requirement.onlyDisplayIfInvalid &&
            isFieldSubmitted &&
            status === 'invalid')
        )
          return (
            <div
              className="requirement-stamps__indicator-stamp-wrapper"
              key={`requirement-${index}`}
            >
              <IndicatorStamp status={status} label={requirement.message} />
            </div>
          )
      })}
      <style jsx>{`
        .requirement-stamps {
          display: flex;
          flex-wrap: wrap;
          margin: 0 calc(var(--space-8) * -1) calc(var(--space-8) * -1) 0;
        }

        .requirement-stamps__indicator-stamp-wrapper {
          max-height: 26px;
          margin: 0 var(--space-8) var(--space-8) 0;
        }
      `}</style>
    </div>
  )
}
