import * as React from 'react'
import classnames from 'classnames'
import { Space } from '@components/legacy-design-system/product/styles'
import {
  spaceClassNamesFromProps,
  SpaceProps,
} from '@lib/styles/utils/spacing-props'

type DivProps = JSX.IntrinsicElements['div']
export interface VSpaceProps extends DivProps {
  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string

  /**
   * The vertical spacing between each child element
   */
  space: Space | 0

  /**
   * Allows you to specify this component's HTML element
   *
   * - - -
   *
   * Default: `div`
   */
  asElement?: string
}

export const VSpace: React.FC<
  React.PropsWithChildren<VSpaceProps & SpaceProps>
> = ({ className, space, children, asElement = 'div', ...props }) => {
  const { classNames: spaceClassNames, otherProps } =
    spaceClassNamesFromProps(props)
  const cx = classnames('vspace', className, spaceClassNames)
  const spacingMarginValue = space === 0 ? '0' : `var(--space-${space})`
  const Element = asElement as any

  return (
    <Element className={cx} {...otherProps}>
      {children}

      <style jsx>{`
        .vspace > :global(* + *) {
          margin-top: ${spacingMarginValue} !important;
        }
      `}</style>
    </Element>
  )
}
