import classnames from 'classnames'
import * as React from 'react'
import { Icon } from '@components/Icon'
import { Spinner } from '@components/Spinner'
import { BaseButtonProps } from './common'

import styles from './Button.module.css'

export interface AnchorButtonProps extends BaseButtonProps {
  /**
   * The location for the link
   */
  href?: string

  /**
   * Click event handler
   */
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void

  /**
   * Keydown event handler
   */
  onKeyDown?: (event: React.KeyboardEvent<HTMLAnchorElement>) => void

  /**
   * The relationship of the linked URL as space-separated link types.
   */
  rel?: string

  /**
   * Add a `tabIndex` to make an AnchorButton focusable.
   */
  tabIndex?: number

  /**
   * Where to display the linked URL, as the name for a browsing context
   */
  target?: '_blank' | '_self' | '_parent' | '_top'
}

/**
 * Useful to communicate actions to your users.
 */
export const AnchorButton = React.forwardRef<
  HTMLAnchorElement,
  AnchorButtonProps
>(
  (
    {
      className,
      disabled = false,
      fill = false,
      iconAfter,
      iconBefore,
      iconOnly,
      intent = 'NEUTRAL',
      isWaiting = false,
      large = false,
      medium = false,
      outlined = false,
      tabIndex = 0,
      text,
      ...props
    },
    ref
  ) => {
    const cn = classnames(
      styles['button'],
      {
        [styles['button--default']]: !large && !medium,
        [styles['button--medium']]: medium,
        [styles['button--large']]: large,
        [styles['button--solid']]: !outlined,
        [styles['button--outline']]: outlined,
        [styles['button--neutral']]: intent === 'NEUTRAL',
        [styles['button--primary']]: intent === 'PRIMARY',
        [styles['button--muted']]: intent === 'MUTED',
        [styles['button--destructive']]: intent === 'DESTRUCTIVE',
        [styles['button--inverted']]: intent === 'OUTLINED',
        [styles['button--fill']]: fill,
        [styles['button--icon-only']]: iconOnly,
        [styles['button--is-waiting']]: isWaiting,
        [styles['button--is-disabled']]: disabled || isWaiting,
      },
      className
    )

    return (
      <a
        className={cn}
        aria-label={text}
        tabIndex={tabIndex}
        ref={ref}
        {...props}
      >
        {isWaiting && (
          <div className={styles['button__is-waiting']}>
            <Spinner size={large ? 'medium' : 'small'} color="inherit" />
          </div>
        )}
        {iconBefore && (
          <Icon
            className={classnames(styles['button__icon'], 'u-marginRight8')}
            name={iconBefore}
          />
        )}
        {iconOnly && (
          <Icon className={styles['button__icon']} name={iconOnly} />
        )}
        {!iconOnly && <span className={styles['button__text']}>{text}</span>}
        {iconAfter && (
          <Icon
            className={classnames(styles['button__icon'], 'u-marginLeft8')}
            name={iconAfter}
          />
        )}
      </a>
    )
  }
)
