import classNames from 'classnames'
import * as React from 'react'
import { UNKNOWN_ERROR_MESSAGE } from '@lib/constants'
import { trackEvent } from '@lib/tracking'
import { DuffelAPI } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import { ContactSupportCategory } from '@lib/zendesk'
import { getZendeskCreateTicketRequestPayload } from '@lib/zendesk/get-zendesk-create-ticket-request-payload'
import { SupportContactForm, SupportFormValues } from './SupportContactForm'
import { SupportContactSubmitted } from './SupportContactSubmitted'

interface SupportContactProps {
  className?: string
  initialCategory?: ContactSupportCategory
  initialMessage?: string
  order?: DuffelAPI.Types.Order
}

export const SupportContact: React.FC<SupportContactProps> = ({
  className,
  initialCategory,
  initialMessage,
  order,
}) => {
  const { user, currentOrganisation, addToast, duffelClient } = useWorkspace()
  const [submitted, setSubmitted] = React.useState(false)
  const [submittedTicketContactEmail, setSubmittedTicketContactEmail] =
    React.useState('')

  const onSubmit = async (values: SupportFormValues) => {
    trackEvent('dashboard_support_ticket_submitted', {
      event_type: 'interaction',
      category: values.category,
    })

    const { category, email, message, uploads } = values

    const ticketPayload = getZendeskCreateTicketRequestPayload(
      category,
      email,
      message,
      window.location.href,
      user?.fullName,
      order,
      uploads
    )

    const { data, errors } = await duffelClient.Zendesk.createSupportTicket(
      ticketPayload
    )

    if (!data || errors) {
      const message = errors?.[0].message || UNKNOWN_ERROR_MESSAGE
      trackEvent('dashboard_support_ticket_alert_displayed', {
        event_type: 'alert',
        alert_message: category,
      })
      addToast({
        intent: 'warning',
        message,
        closeAfterTimeout: false,
      })
      return
    }
    setSubmittedTicketContactEmail(values.email)
    trackEvent('dashboard_support_ticket_completed', {
      event_type: 'api',
      category: values.category,
    })
    setSubmitted(true)
  }

  return (
    <div className="support-contact">
      {submitted ? (
        <SupportContactSubmitted email={submittedTicketContactEmail} />
      ) : (
        <SupportContactForm
          className={classNames('support-contact__form', className)}
          onSubmit={onSubmit}
          initialCategory={initialCategory}
          initialMessage={initialMessage}
          userEmail={user?.email}
          organisationContactEmails={currentOrganisation?.contactEmails}
        />
      )}
      <style jsx>{`
        .support-contact {
          position: relative;
          display: flex;
          flex-direction: column;
        }

        :global(.support-contact__title) {
          display: flex;
          align-items: center;
          padding: var(--space-16) var(--space-24);
          border-bottom: 1px solid var(--grey-200);
        }

        :global(.support-contact__back) {
          cursor: pointer;
        }
      `}</style>
    </div>
  )
}
