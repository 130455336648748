import * as React from 'react'
import { Anchor } from '@components/Anchor'
import { Button } from '@components/Button'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import styles from './AnalyticsConsentManager.module.css'

export interface AnalyticsConsentManagerBannerProps {
  onAllow: () => void
  onDeny: () => void
  onOpenModal: () => void
}

export const AnalyticsConsentManagerBanner: React.FC<
  AnalyticsConsentManagerBannerProps
> = ({ onAllow, onDeny, onOpenModal }) => {
  return (
    <VSpace space={24} className={styles['consent-manager-banner']}>
      <Text>
        We use cookies to improve your experience and for marketing.{' '}
        <Anchor href="https://duffel.com/cookies-policy" target="_blank">
          See our cookie policy.
        </Anchor>
      </Text>

      <div className={styles['consent-manager-banner__buttons-container']}>
        <Button
          type="button"
          onClick={onAllow}
          fill
          text="Allow all"
          data-testid="accept-cookies"
        />
        <Button type="button" onClick={onDeny} fill text="Reject all" />
        <Button
          onClick={onOpenModal}
          type="button"
          fill
          text="Manage cookies"
          intent="MUTED"
        />
      </div>
    </VSpace>
  )
}
