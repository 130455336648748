import classNames from 'classnames'
import { debounce } from 'lodash'
import * as React from 'react'
import { Input } from '@components/Input'
import { PopoverContainer } from '@components/PopoverContainer'
import { Tooltip } from '@components/Tooltip'
import { goToNextTabbable } from '@lib/helpers'
import { useDuffelPopper } from '@lib/hooks'
import styles from './Autofill.module.css'

export interface AutofillProps<T_LookupResponseData> {
  className?: string
  value: string
  id: string
  placeholder?: string
  disabled?: boolean
  tooltipText?: string
  onChange: (value: string | T_LookupResponseData) => void
  onAutofillClose?: () => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
  lookup: (query: string) => Promise<T_LookupResponseData[]>
  renderResultLabel: (data: T_LookupResponseData) => React.ReactNode
  shouldGoToNextTabbable?: boolean
}

export function Autofill<T_LookupResponseData>({
  className,
  value,
  id,
  placeholder,
  disabled,
  tooltipText,
  onChange,
  onAutofillClose,
  lookup,
  onBlur,
  renderResultLabel,
  shouldGoToNextTabbable = true,
}: AutofillProps<T_LookupResponseData>) {
  const [lookupResult, setLookupResult] = React.useState<
    T_LookupResponseData[] | null
  >(null)
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
  const [activeResultItem, setActiveResultItem] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isFocused, setIsFocused] = React.useState(false)

  const resetState = () => {
    setLookupResult(null)
    setIsPopoverOpen(false)
    setActiveResultItem(0)
    setIsLoading(false)
  }
  const onClosePopover = () => {
    setIsPopoverOpen(false)
    onAutofillClose?.()
  }

  const pickResultItem = (index?: number) => {
    if (!lookupResult || lookupResult[index || activeResultItem] === undefined)
      return false
    onChange(lookupResult[index || activeResultItem])
    resetState()
    if (shouldGoToNextTabbable) {
      goToNextTabbable(document.querySelector<HTMLInputElement>(`#${id}`))
    }
    return true
  }

  const setResult = React.useCallback(
    (result: T_LookupResponseData[]) => {
      setLookupResult(result)
      setActiveResultItem(0)
      setIsPopoverOpen(true)
      setIsLoading(false)
    },
    [setLookupResult, setActiveResultItem, setIsLoading]
  )

  const lastLookupTimestamp = React.useRef<number>()
  const lookupCallback = debounce(async (query) => {
    const lookupTimestamp = new Date().valueOf()
    lastLookupTimestamp.current = lookupTimestamp
    const result = await lookup(query)
    // if the request is not the latest request, ignore the result
    if (lastLookupTimestamp.current !== lookupTimestamp) {
      return
    }

    // The API might return `null` values in the array, if the value
    // is not yet present in platform's `AirportCache` or `CityCache`.
    // To prevent breaking the page when searching, we'll filter out `null` values.
    const resultArrayWithoutNulls = result.filter((result) => result !== null)
    setResult(resultArrayWithoutNulls)
  }, 150)

  const {
    popper: { styles: popperStyles, attributes },
    setReferenceElement,
    setPopperElement,
  } = useDuffelPopper(isPopoverOpen, onClosePopover, {
    placement: 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
  })

  return (
    <Tooltip text={tooltipText} placement="bottom">
      <Input
        className={className}
        isWaiting={isLoading}
        disabled={disabled}
        ref={setReferenceElement}
        id={id}
        data-testid={id}
        placeholder={placeholder}
        value={value}
        onChange={(event) => {
          onChange(event.target.value)
          setIsPopoverOpen(false)
          setIsLoading(true)
          event.target.value === ''
            ? resetState()
            : lookupCallback(event.target.value)
        }}
        onKeyDown={(event) => {
          if (event.key === 'ArrowDown') {
            event.preventDefault()
            lookupResult &&
              setActiveResultItem((activeResultItem + 1) % lookupResult.length)
          }

          if (event.key === 'ArrowUp') {
            event.preventDefault()
            lookupResult && activeResultItem === 0
              ? setActiveResultItem(lookupResult.length - 1)
              : setActiveResultItem(activeResultItem - 1)
          }

          if (event.key === 'Enter') {
            event.preventDefault()
            pickResultItem()
          }

          if (event.key === 'Tab') {
            const pickResult = pickResultItem()
            !pickResult && resetState()
          }
        }}
        onFocus={() => {
          setIsFocused(true)
          if (value !== '') {
            lookupCallback(value)
            setIsPopoverOpen(true)
          }
        }}
        onBlur={(event) => {
          // This is to ensure that clicking the popover doesn't cause the popover to
          // disappear before it gets to handle the click event.
          if (isPopoverOpen) {
            return
          }

          onBlur?.(event)
          setIsFocused(false)
        }}
        data-selector="fs-show"
        // Disable the OS's built-in autocompletion so that it doesn't interfere with ours.
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
      />
      {isPopoverOpen && isFocused && (
        <PopoverContainer
          ref={setPopperElement}
          style={{ zIndex: 50, ...popperStyles.popper }}
          {...attributes.popper}
          data-selector="fs-show"
        >
          {!isLoading &&
            lookupResult &&
            (lookupResult.length === 0 ? (
              <div className={styles['result-item']}>No results</div>
            ) : (
              lookupResult.map((result, index) => (
                <button
                  key={`result-${index}`}
                  onClick={(event) => {
                    event.preventDefault()
                    pickResultItem(index)
                  }}
                  onMouseEnter={() => setActiveResultItem(index)}
                  className={classNames(styles['result-item'], {
                    [styles['result-item--active']]: index === activeResultItem,
                  })}
                >
                  {renderResultLabel(result)}
                </button>
              ))
            ))}
        </PopoverContainer>
      )}
    </Tooltip>
  )
}
