import { DuffelAPI } from '@lib/types'
import { makeMockAirline } from './make-mock-airline'
import { makeMockOfferBaggageService } from './make-mock-offer-baggage-service'
import { makeMockOfferPassenger } from './make-mock-offer-passenger'
import { makeMockOfferSliceFromOriginDestination } from './make-mock-offer-slice'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.Offer => {
    const mockPassenger = makeMockOfferPassenger()
    const firstSlice = makeMockOfferSliceFromOriginDestination(
      'LHR',
      'JFK',
      mockPassenger.id
    )
    return {
      id: `off_${(seedId++).toString().padStart(5, '0')}`,
      supportedPassengerIdentityDocumentTypes: ['passport'],
      availablePaymentTypes: ['balance'],
      availableServices: [
        makeMockOfferBaggageService({
          segmentIds: [firstSlice.segments[0].id],
        }),
      ],
      baseAmount: '500.00',
      baseCurrency: 'GBP',
      createdAt: 'DATE',
      expiresAt: 'DATE',
      liveMode: true,
      owner: makeMockAirline(),
      passengerIdentityDocumentsRequired: true,
      passengers: [mockPassenger],
      paymentRequirements: {
        paymentRequiredBy: '2022-01-17T10:42:14.545Z',
        priceGuaranteeExpiresAt: '2022-01-17T10:42:14.545Z',
        requiresInstantPayment: false,
      },
      privateFares: [],
      slices: [
        firstSlice,
        makeMockOfferSliceFromOriginDestination('JFK', 'LHR', mockPassenger.id),
      ],
      taxAmount: '100.00',
      taxCurrency: 'GBP',
      totalAmount: '500.00',
      totalEmissionsKg: '670',
      totalCurrency: 'GBP',
      conditions: { changeBeforeDeparture: null, refundBeforeDeparture: null },
      updatedAt: '',
      partial: false,
      supportedLoyaltyProgrammes: [],
    }
  }
})()

export const makeMockOffer = (
  extendDefault?: Partial<DuffelAPI.Types.Offer>
): DuffelAPI.Types.Offer =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
