import {
  DuffelAPI,
  PassengerType,
  PassengerTypeMap,
  RandomlyGeneratedPassenger,
} from '@lib/types'
import { CheckoutFormValuesPassenger } from './get-initial-checkout-form-values'

const getPassengerGivenName = (
  passenger: DuffelAPI.Types.OfferPassenger,
  generatedPassengerData?: RandomlyGeneratedPassenger
): string => {
  if (passenger.loyaltyProgrammeAccounts && passenger.givenName) {
    return passenger.givenName
  }

  if (generatedPassengerData) {
    return generatedPassengerData.givenName
  }

  return ''
}

const getPassengerFamilyName = (
  passenger: DuffelAPI.Types.OfferPassenger,
  generatedPassengerData?: RandomlyGeneratedPassenger
): string => {
  if (passenger.loyaltyProgrammeAccounts && passenger.familyName) {
    return passenger.familyName
  }

  if (generatedPassengerData) {
    return generatedPassengerData.familyName
  }

  return ''
}

/**
 * Checks if there are remaining unassigned infants and the supplied passenger
 * is an adult. Each infant in an order must be assigned to exactly one adult,
 * as they share a seat.
 */
const isPassengerResponsibleForInfant = (
  passenger: DuffelAPI.Types.OfferPassenger,
  infants: DuffelAPI.Types.OfferPassenger[],
  unassignedInfantIndex: number
): boolean => {
  return (
    passenger.type === 'adult' &&
    infants &&
    infants.length > 0 &&
    infants.length > unassignedInfantIndex
  )
}

export const getInitialCheckoutFormPassengersValue = (
  offer: DuffelAPI.Types.Offer,
  offerRequest: DuffelAPI.Types.OfferRequest,
  randomlyGeneratedPassengers?: RandomlyGeneratedPassenger[]
): CheckoutFormValuesPassenger[] => {
  const infants = offer.passengers.filter(
    (passenger) => passenger.type === 'infant_without_seat'
  )

  const passengerTypeIndexes: Record<PassengerType, number> = {
    adult: 0,
    child: 0,
    infant_without_seat: 0,
  }

  let unassignedInfantIndex = 0

  const formPassengers: CheckoutFormValuesPassenger[] = offer.passengers.map(
    (passenger) => {
      const randomlyGeneratedPassenger = randomlyGeneratedPassengers?.find(
        (randomlyGeneratedPassenger) =>
          randomlyGeneratedPassenger.id === passenger.id
      )

      const offerRequestPassenger = offerRequest.passengers.find(
        (offerRequestPassenger) => offerRequestPassenger.id === passenger.id
      )

      return {
        passengerLabel: `${
          passenger.type ? PassengerTypeMap[passenger.type] : 'Passenger'
        } ${passenger.type && ++passengerTypeIndexes[passenger.type]}`,
        responsibleForInfant: isPassengerResponsibleForInfant(
          passenger,
          infants,
          unassignedInfantIndex
        )
          ? { infantId: infants[unassignedInfantIndex++].id }
          : null,
        id: passenger.id,
        title: randomlyGeneratedPassenger?.title ?? 'mr',
        givenName: getPassengerGivenName(passenger, randomlyGeneratedPassenger),
        familyName: getPassengerFamilyName(
          passenger,
          randomlyGeneratedPassenger
        ),
        bornOn: randomlyGeneratedPassenger
          ? new Date(randomlyGeneratedPassenger?.bornOn)
          : null,
        gender: randomlyGeneratedPassenger?.gender ?? 'm',
        passportDetails: {
          countryOfIssue: '',
          passportNumber: '',
          expiryDate: '',
        },
        services: [],
        loyaltyProgrammeAccounts: passenger.loyaltyProgrammeAccounts,
        age: offerRequestPassenger?.age ?? null,
      }
    }
  )

  return formPassengers
}
