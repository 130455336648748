import * as React from 'react'
import { Notice } from '@components/Notice'
import { DuffelAPI } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import { getDuffelAPIClient } from '@lib/duffel-api/getDuffelAPIClient'
import { useRouter } from 'next/router'
import { DashboardOrder } from '@modules/air-order/lib/types'

interface SimulateAirlineInitiatedChangeNoticeProps {
  orderId: DuffelAPI.Types.Order['id']
  onSuccess?: (order: DashboardOrder) => void
  showAgencyInformation?: boolean
}

/**
 * This component allows Duffel admins to simulate an airline initiated change
 * (AIC) any number times for an eligible order. It is intended for testing
 * purposes as part of the AIC dashboard project (UXP-1985).
 *
 * To see what types or order are eligible for simulating an AIC please read the
 * 'Simulating a change' section of the following doc:
 *
 * https://duffel.com/docs/api/overview/test-your-integration
 */
export const SimulateAirlineInitiatedChangeNotice: React.FC<
  SimulateAirlineInitiatedChangeNoticeProps
> = ({ orderId }) => {
  const router = useRouter()
  const { addToast } = useWorkspace()
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const simulateAIC = async () => {
    const client = getDuffelAPIClient(
      undefined,
      undefined,
      router.query.org as string,
      router.query.mode as 'live' | 'test'
    )
    setIsSubmitting(true)
    const aicResponse = await client.get(`/air/airline_initiated_changes`, {
      params: { order_id: orderId, requires_action: true },
    })

    if (!aicResponse || aicResponse.data['errors']) {
      setIsSubmitting(false)
      return addToast({
        intent: 'warning',
        message:
          'There was an issue simulating the airline initiated change. Please try again shortly.',
        closeAfterTimeout: false,
      })
    }

    router.replace(router.asPath)
  }

  return (
    <Notice
      type="action"
      onClickActionButton={simulateAIC}
      actionButtonText="Simulate new AIC"
      actionButtonIsWaiting={isSubmitting}
    >
      Duffel Admins: this order is eligible for a simulated AIC (airline
      initiated change).
    </Notice>
  )
}
