import { Text } from '@components/legacy-design-system/product/components/Text'
import { CopyableValue } from '@components/CopyableValue'
import { FlightInformationUpdate } from '@components/FlightInformationUpdate'
import {
  OrderInformationBox,
  OrderInformationBoxContent,
} from '@components/OrderInformationBox'
import { Table, TableCell, TableHeader, TableRow } from '@components/Table'
import { moneyStringFormatter } from '@lib/helpers'
import { DuffelAPI } from '@lib/types'
import styles from './AirlineCredit.module.css'

export interface AirlineCreditProps {
  order: DuffelAPI.Types.Order
}

export const AirlineCredit: React.FC<AirlineCreditProps> = ({ order }) => {
  const { cancellation } = order

  if (!cancellation || cancellation.refundTo !== 'airline_credits') {
    return null
  }

  const { refundCurrency, refundAmount, airlineCredits } = cancellation

  const {
    owner: { name },
  } = order
  const { passengers: orderPassengers } = order

  const issuedCreditText = `${name} have issued airline credits totalling `

  return (
    <OrderInformationBox
      stampColor="green"
      stampText="Airline credit issued"
      showDivider={false}
    >
      <OrderInformationBoxContent
        className={styles['airline-credit-information-box']}
      >
        <FlightInformationUpdate status="credit-issued">
          <Text color="grey-900">
            {issuedCreditText}
            <Text asElement="span" fontWeight="medium">
              {refundCurrency &&
                refundAmount &&
                moneyStringFormatter(refundCurrency)(Number(refundAmount))}
            </Text>
          </Text>
        </FlightInformationUpdate>
        <div className={styles['airline-credit-table-container']}>
          <Table className={styles['airline-credit-table']} rowHeight={48}>
            <thead>
              <TableRow>
                <TableHeader
                  className={styles['airline-credit-description-heading']}
                  rowHeight={40}
                >
                  Description
                </TableHeader>
                <TableHeader rowHeight={40}>Passenger</TableHeader>
                <TableHeader rowHeight={40}>Credit Code</TableHeader>
                <TableHeader rowHeight={40}>Issue date</TableHeader>
                <TableHeader
                  rowHeight={40}
                  className={styles['airline-credit-amount-heading']}
                  alignRight
                >
                  Amount
                </TableHeader>
              </TableRow>
            </thead>
            <tbody>
              {/* Adjust left/right margins of table headings and entries */}
              {airlineCredits &&
                airlineCredits.map(
                  (
                    {
                      creditName,
                      creditCode,
                      creditCurrency,
                      creditAmount,
                      passengerId,
                      issuedOn,
                    },
                    index
                  ) => {
                    const passenger = orderPassengers.find(
                      (passenger) => passenger.id === passengerId
                    )

                    return (
                      <TableRow
                        className={styles['airline-credit-row']}
                        key={`airline-credit-row-${index}`}
                      >
                        <TableCell
                          className={styles['airline-credit-description']}
                        >
                          {creditName}
                        </TableCell>
                        <TableCell>
                          {passenger?.givenName.toUpperCase()}{' '}
                          {passenger?.familyName.toUpperCase()}
                        </TableCell>
                        <TableCell className={styles['table-cell-with-copy']}>
                          <CopyableValue valueToCopy={creditCode}>
                            <span>{creditCode}</span>
                          </CopyableValue>
                        </TableCell>
                        <TableCell>{issuedOn}</TableCell>
                        <TableCell
                          className={styles['airline-credit-amount']}
                          alignRight
                        >
                          {moneyStringFormatter(creditCurrency)(
                            Number(creditAmount)
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  }
                )}
            </tbody>
          </Table>
        </div>
      </OrderInformationBoxContent>
    </OrderInformationBox>
  )
}
