import classNames from 'classnames'
import * as React from 'react'
import { useTableContext } from '../Table'
import styles from './TableRow.module.css'

export interface TableRowProps {
  onClick?: () => void
  className?: string

  /**
   * Can the use interact with the table row / something contained in the table row?
   * If `TRUE`, this will add a hover effect
   * - - -
   * If this prop is omitted, it will use the value from the table context
   */
  interactive?: boolean

  /**
   * Should the table row have a border on all sides
   */
  showFullBorder?: boolean

  /**
   * Content to show when row is expanded
   */
  expandedContent?: React.ReactNode

  /**
   * Show expanded content
   */
  showExpandedContent?: boolean

  /**
   * Turns the table row into an anchor element and assigns the href to it.
   * Styles and a11y role are defined as well.
   */
  href?: string

  /**
   * If an href is given, you may also define the anchor target
   */
  target?: React.HTMLAttributeAnchorTarget
}

export const TableRow: React.FC<React.PropsWithChildren<TableRowProps>> = ({
  children,
  onClick,
  className,
  interactive,
  showFullBorder = false,
  expandedContent,
  showExpandedContent = false,
  href,
  target,
}) => {
  const { disableRowInteraction } = useTableContext()
  const isInteractive = interactive ?? !disableRowInteraction

  const Row = () =>
    href === undefined ? (
      <tr
        data-testid="table-row"
        className={classNames('table-row', className, styles['tr'], {
          [styles['tr--expanded']]: showExpandedContent,
          [styles['tr--full-border']]: showFullBorder,
          [styles['clickable']]: onClick,
          ['u-focusVisibleHighlight']:
            onClick /* this style isn't wrapped in `styles[]` since it comes from a focus util CSS file that isn't a CSS module */,
          [styles['tr--interactive']]: isInteractive,
        })}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onClick?.()
          }
        }}
      >
        {children}
      </tr>
    ) : (
      <a
        href={href}
        target={target}
        role="row"
        data-testid="table-row"
        className={classNames(
          'table-row u-focusVisibleHighlight',
          className,
          styles['tr'],
          styles['clickable'],
          styles['tr--interactive'],
          {
            [styles['tr--expanded']]: showExpandedContent,
            [styles['tr--full-border']]: showFullBorder,
          }
        )}
      >
        {children}
      </a>
    )

  const ExpandableRow = () => (
    <tbody
      className={classNames('table-body', styles['table-body'], className, {
        [styles['table-body--full-border']]: showFullBorder,
      })}
    >
      {Row()}
      {showExpandedContent && (
        <tr className={styles['tr--expanded-content']}>
          <td colSpan={100} className={styles['td--expanded-content']}>
            {expandedContent}
          </td>
        </tr>
      )}
    </tbody>
  )

  return expandedContent ? ExpandableRow() : Row()
}
