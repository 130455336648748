import * as React from 'react'
import { Counter } from '@components/Counter'

interface PassengerSelectCounterProps {
  id: string
  label: string
  hint: string
  count: number
  min: number
  max: number
  onChange: (value: number) => void
  onTab?: () => void
}

export const PassengerSelectCounter: React.FC<PassengerSelectCounterProps> = ({
  id,
  label,
  hint,
  min,
  max,
  count,
  onChange,
  onTab,
}) => {
  return (
    <div className="passenger-select-counter">
      <div className="passenger-select-counter--label">
        <span>{label}</span>
        <span>{hint}</span>
      </div>
      <Counter
        id={id}
        min={min}
        max={max}
        value={count}
        onChange={onChange}
        onTabLast={onTab}
      />

      <style jsx>{`
        .passenger-select-counter {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .passenger-select-counter--label {
          display: grid;
          grid-template-rows: 24px 24px;
          grid-row-gap: 4px;
        }

        .passenger-select-counter--label > span:first-child {
          font-size: 16px;
          font-weight: bold;
        }
        .passenger-select-counter--label > span:last-child {
          font-size: 14px;
          font-weight: normal;
        }
      `}</style>
    </div>
  )
}
