import * as React from 'react'
import { sum } from 'lodash'
import {
  Invoice,
  InvoiceHeader,
  InvoiceRow,
  InvoiceCell,
  InvoiceBody,
  InvoiceFooter,
} from '@components/Invoice'
import { DuffelAPI } from '@lib/types'
import { captureException } from '@lib/sentry'
import {
  getAdditionalBaggageLabel,
  moneyStringFormatterWithoutSymbol,
} from '@lib/helpers'

export const OrderInvoiceList: React.FC<{ order: DuffelAPI.Types.Order }> = ({
  order,
}) => {
  if (!order.owner) {
    captureException(Error('Order is missing owner in `OrderInvoiceList`'), {
      order: order.id,
    })
    return null
  }

  const servicesSum = sum(
    order.services?.map((service) => parseFloat(service.totalAmount))
  )

  const serviceDescription = (service) => {
    switch (service.type) {
      case 'seat':
        return 'Seat selection'
      case 'cancel_for_any_reason':
        return 'Cancel for any reason'
      default:
        return getAdditionalBaggageLabel(
          (service.metadata as DuffelAPI.Types.OrderServiceBaggageMetadata).type
        )
    }
  }

  return (
    <Invoice>
      <InvoiceHeader>
        <InvoiceRow>
          <InvoiceCell isHeaderCell>Description</InvoiceCell>
          <InvoiceCell textAlign="right" isHeaderCell>
            Price ({order.baseCurrency})
          </InvoiceCell>
        </InvoiceRow>
      </InvoiceHeader>
      <InvoiceBody>
        <InvoiceRow>
          <InvoiceCell grow>Fare</InvoiceCell>
          <InvoiceCell textAlign="right" isMono>
            {servicesSum
              ? (parseFloat(order.baseAmount) - servicesSum).toFixed(2)
              : order.baseAmount}
          </InvoiceCell>
        </InvoiceRow>

        <InvoiceRow>
          <InvoiceCell>Fare taxes</InvoiceCell>
          <InvoiceCell textAlign="right" isMono>
            {order.taxAmount}
          </InvoiceCell>
        </InvoiceRow>

        {order.services?.map((service) => (
          <InvoiceRow key={service.id}>
            <InvoiceCell>{serviceDescription(service)}</InvoiceCell>
            <InvoiceCell textAlign="right" isMono>
              {moneyStringFormatterWithoutSymbol(
                order.baseCurrency || order.totalCurrency
              )(Number(service.totalAmount))}
            </InvoiceCell>
          </InvoiceRow>
        ))}
      </InvoiceBody>
      <InvoiceFooter>
        <InvoiceRow>
          <InvoiceCell isFooterCell textAlign="right">
            Total ({order.totalCurrency})
          </InvoiceCell>
          <InvoiceCell isFooterCell textAlign="right" isMono>
            {moneyStringFormatterWithoutSymbol(order.totalCurrency)(
              Number(order.totalAmount)
            )}
          </InvoiceCell>
        </InvoiceRow>
      </InvoiceFooter>
    </Invoice>
  )
}
