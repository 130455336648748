import { cloneDeep, orderBy, remove, uniqBy } from 'lodash'
import { getOriginDestinationKey } from '@components/FlightSummary/lib'
import { DuffelAPI } from '@lib/types'
import { getOrderedAICs } from './get-ordered-aics'

/**
 * This function returns added and removed slices with AICs.
 */

export interface Slice extends DuffelAPI.Types.OrderSlice {
  isCancelled?: boolean
}

export const getBeforeAndAfterSlicesAndSegmentsWithAICsByOriginDestinationKey =
  (order: DuffelAPI.Types.Order) => {
    // Order AICs in chronological order
    const airlineInitiatedChanges = getOrderedAICs(order)

    // Filter AICs to get those requiring action
    const unactionedAICs = airlineInitiatedChanges?.filter(
      (change) => change.actionTaken === null
    )

    let beforeSlices = cloneDeep(order.slices)
    let afterSlices = order.slices.map(
      (slice) =>
        ({
          isCancelled: false,
          ...slice,
        } as Slice)
    )

    const removedSoFar: string[] = []
    unactionedAICs?.reverse().forEach((aic) => {
      const removedSegmentIdsInAIC = [
        ...new Set(
          aic.removed.flatMap((slice) =>
            slice.segments?.flatMap((segment) =>
              getOriginDestinationKey(segment)
            )
          )
        ),
      ]
      const addedSegmentIdsInAIC = [
        ...new Set(
          aic.added.flatMap((slice) =>
            slice.segments?.flatMap((segment) =>
              getOriginDestinationKey(segment)
            )
          )
        ),
      ]

      const addedSliceIds = [
        ...new Set(
          aic.added.flatMap((addedSlice) => getOriginDestinationKey(addedSlice))
        ),
      ]
      const removedSliceIds = [
        ...new Set(
          aic.removed.flatMap((removedSlice) =>
            getOriginDestinationKey(removedSlice)
          )
        ),
      ]

      // If slice is in both in added and removed arrays, then it means a segment has been changed
      const slicesInBothAddedAndRemovedChanges = [
        ...new Set(addedSliceIds.filter((id) => removedSliceIds.includes(id))),
      ]

      if (slicesInBothAddedAndRemovedChanges.length) {
        // Find slice that does not contain the removed segment.
        // This is the segment in the final order view.
        remove(beforeSlices, (slice) =>
          slice.segments.find((segment) =>
            addedSegmentIdsInAIC.includes(getOriginDestinationKey(segment))
          )
        )
        // Find slice that contains the removed segment
        const slicesWithSegmentChanged = aic.removed.filter((removedSlice) =>
          removedSlice.segments.find((segment) =>
            removedSegmentIdsInAIC.includes(getOriginDestinationKey(segment))
          )
        )

        // If changing this bit of code, check fixtures 'order-with-multiple-changes' and 'order-with-duplicate-slices-in-aics'
        uniqBy(slicesWithSegmentChanged, (slice) =>
          getOriginDestinationKey(slice)
        ).map((slice) => beforeSlices.push(slice))
      } else {
        addedSliceIds.map((key) => {
          // Handle special case where a slice is added in an AIC and removed in a subsequent AIC
          // In that case, we don't want to show it at all
          if (removedSoFar.includes(key)) {
            remove(
              afterSlices,
              (slice) =>
                key === getOriginDestinationKey(slice) && slice.isCancelled
            )
          } else {
            // Remove slices from beforeSlices which are in the added array
            remove(
              beforeSlices,
              (slice) => key === getOriginDestinationKey(slice)
            )
          }
        })

        // Add any slices from the removed array
        aic.removed.forEach((removedSlice) => {
          beforeSlices.push(removedSlice)
          afterSlices.push({ isCancelled: true, ...removedSlice })
          removedSoFar.push(getOriginDestinationKey(removedSlice))
        })
      }
    })

    // Order beforeSlices chronologically - order by first segment's departure time
    beforeSlices = orderBy(
      beforeSlices,
      (slice) => slice.segments[0].departingAt,
      ['asc']
    )

    // Order afterSlices chronologically - order by first segment's departure time
    afterSlices = orderBy(
      afterSlices,
      (slice) => slice.segments[0].departingAt,
      ['asc']
    )

    return {
      beforeSlices: beforeSlices,
      afterSlices: afterSlices,
    }
  }
