import { format } from 'date-fns'
import { DuffelAPI } from '@lib/types'
import {
  ZENDESK_CONTENT_CUSTOM_FIELD_VALUE_MAP,
  ZENDESK_CUSTOM_FIELD_ID_MAP,
} from './constants'
import { getZendeskTicketFirstFlightDate } from './get-zendesk-ticket-first-flight-date'
import { ZendeskSupportTicketRequestPayload } from './types'

export const getZendeskTicketCustomFields = (
  url: string,
  order?: DuffelAPI.Types.Order
) => {
  const customFields: ZendeskSupportTicketRequestPayload['custom_fields'] = [
    {
      id: ZENDESK_CUSTOM_FIELD_ID_MAP.url,
      value: url,
    },
  ]

  if (order) {
    customFields.push(
      // @TODO: possibly remove all of these except `orderId` since they are
      // populated by the webhook (see above).
      {
        id: ZENDESK_CUSTOM_FIELD_ID_MAP.airlineIataCode,
        value: order.owner.iataCode ?? undefined,
      },
      {
        id: ZENDESK_CUSTOM_FIELD_ID_MAP.bookingReference,
        value: order.bookingReference,
      },
      {
        id: ZENDESK_CUSTOM_FIELD_ID_MAP.content,
        value: ZENDESK_CONTENT_CUSTOM_FIELD_VALUE_MAP[order.content],
      },
      {
        id: ZENDESK_CUSTOM_FIELD_ID_MAP.jetUrl,
        value: `https://jet.x15.xyz/air/orders/${order.id}`,
      },
      {
        id: ZENDESK_CUSTOM_FIELD_ID_MAP.orderId,
        value: order.id,
      }
    )

    if (order.liveMode) {
      // When this field and the `orderId` are present in a ticket's custom
      // fields, a Webhook is triggered which populates the remaining order
      // related custom fields. You can see the Webhook here:
      //
      // https://github.com/duffelhq/platform/blob/main/apps/duffel_web/lib/web/controllers/webhooks/zendesk_controller.ex
      //
      // Note: this only works for live orders
      customFields.push({
        id: ZENDESK_CUSTOM_FIELD_ID_MAP.refreshOrder,
        value: 'true',
      })
    }

    const firstFlightDate = getZendeskTicketFirstFlightDate(order)

    if (firstFlightDate) {
      customFields.push({
        id: ZENDESK_CUSTOM_FIELD_ID_MAP.firstFlightDate,
        value: format(firstFlightDate, 'yyyy-MM-dd'),
      })
    }
  }

  return customFields
}
