import * as React from 'react'
import { Select } from '@components/Select'

import styles from './OptionsFilterInput.module.css'

export function OptionsFilterInput<T extends string>(props: {
  options: readonly T[]
  value: T
  onChange: (value: T) => void
}) {
  const { options, value, onChange } = props
  return (
    <div className={styles['options-filter-container']}>
      <Select
        className={styles['options-select']}
        value={value}
        // had to use an assertion here, but since the type of the props should
        // already guarantee the correct types of the incoming options, this should be safe
        onChange={(event) => onChange(event.target.value as T)}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
    </div>
  )
}
