import pino from 'pino'

export const logger = pino({
  name: 'dashboard',
  // __What's this browser stuff in the middleware?__
  //
  // "The problem is that the browser version of pino is being packaged into the middleware,
  // so we need to tell this version that we want to serialize the output,
  // however, for some reason serialize: true is not working for us, so we use the write option.
  // Since JSON.stringify, is rather resilient (throws only for cycles and BigInt),
  // this patch works swell for now."
  // source: https://github.com/vercel/next.js/discussions/33898#discussioncomment-2323400)
  browser: {
    write(obj) {
      try {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(obj))
      } catch (err) {
        if (err instanceof Error) {
          // Without a `replacer` argument, stringify on Error results in `{}`
          // eslint-disable-next-line no-console
          console.log(JSON.stringify(err, ['name', 'message', 'stack']))
        } else {
          // eslint-disable-next-line no-console
          console.log(JSON.stringify({ message: 'Unknown error type' }))
        }
      }
    },
  },
})
