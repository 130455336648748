import { getDefaultAdultPassenger } from '../forms/SearchForm/lib/get-default-passenger'
import { defaultSearchTimeRanges } from '../forms/SearchForm/lib/search-time-ranges'
import { SearchFormValues } from './transform-search-form-values'

export const DEFAULT_SUPPLIER_TIMEOUT = 60000

export const getInitialSearchFormValues = (): SearchFormValues<'return'> => ({
  type: 'return',
  origin: '',
  destination: '',
  departureDate: null,
  returnDate: null,
  departureTimeRanges: defaultSearchTimeRanges,
  returnTimeRanges: defaultSearchTimeRanges,
  passengers: [
    {
      ...getDefaultAdultPassenger(),
      givenName: '',
      familyName: '',
    },
  ],
  airlines: [],
  cabinClass: 'economy',
  supplierTimeout: DEFAULT_SUPPLIER_TIMEOUT,
})
