import * as React from 'react'
import styles from './Segment.module.css'
import classNames from 'classnames'
import { Icon } from '@components/Icon'
import { OfferSliceSegment } from '@duffel/components'

interface SegmentComponentProps {
  segment: OfferSliceSegment
  className?: string
}

export const Segment: React.FC<SegmentComponentProps> = ({
  segment,
  className,
}) => (
  <h3 className={classNames(className, styles['passenger-segment__title'])}>
    {segment.origin.iata_code}
    <Icon
      name="chevron"
      className={styles['passenger-segment__chevron']}
      size={20}
    />
    {segment.destination.iata_code}
  </h3>
)
