import { DuffelAPI } from '@lib/types'
import { makeMockOfferRequestChildPassenger } from '.'

const getDefaultArray = (() => {
  return (): DuffelAPI.Inputs.OfferRequestChildPassenger[] => [
    makeMockOfferRequestChildPassenger(),
    makeMockOfferRequestChildPassenger({
      givenName: 'Harley',
      familyName: 'Keener',
      age: 10,
    }),
  ]
})()

export const makeMockOfferRequestChildPassengers = (
  extendDefault: DuffelAPI.Inputs.OfferRequestChildPassenger[] = []
): DuffelAPI.Inputs.OfferRequestChildPassenger[] => [
  ...getDefaultArray(),
  ...extendDefault,
]
