import classNames from 'classnames'
import * as React from 'react'
import { ChromelessButton } from '@components/Button'
import { Icon } from '@components/Icon'

export interface AccordionProps {
  title: string
  shouldMountExpanded?: boolean
  contentClassName?: string
  onExpansionToggled?: (isExpanded: boolean) => void
}

export const Accordion: React.FC<React.PropsWithChildren<AccordionProps>> = ({
  title,
  children,
  shouldMountExpanded,
  contentClassName,
  onExpansionToggled,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(!!shouldMountExpanded)
  return (
    <div className="accordion">
      <ChromelessButton
        type="button"
        className="accordion__header"
        onClick={() => {
          setIsExpanded(!isExpanded)
          onExpansionToggled && onExpansionToggled(!isExpanded)
        }}
        data-selector="fs-show"
      >
        {title}
        <Icon name={isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} />
      </ChromelessButton>
      {isExpanded && (
        <div className={classNames('accordion__main', contentClassName)}>
          {children}
        </div>
      )}
      <style jsx>{`
        :global(.accordion__header) {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: var(--FONT-SIZES-H3) !important;
          outline: default !important;
          line-height: 1.2 !important;
          border-radius: var(--border-radius-4) !important;
          width: 100%;
          box-sizing: content-box !important;
          margin: -0.5rem !important;
          padding: 0.5rem !important;
          user-select: default !important;
          border: 2px solid transparent !important;
          font-size: var(--FONT-SIZES-H4) !important;
        }

        :global(.chromeless-button:hover) {
          background: var(--grey-100) !important;
        }

        :global(.chromeless-button:focus) {
          border: 2px solid var(--grey-200) !important;
        }

        :global(.chromeless-button:active) {
          border: 2px solid var(--purple-300) !important;
        }

        :global(.accordion__header > svg) {
          color: var(--grey-500);
        }

        .accordion__main {
          border: 1px solid var(--grey-200);
          border-radius: var(--border-radius-4);
          padding: 16px;
          margin-top: 24px;
        }
      `}</style>
    </div>
  )
}

export default Accordion
