import { AxiosRequestConfig } from 'axios'

import { UserRole } from '@lib/security'
import {
  AirlineMarketingData,
  BaseClientConfig,
  DuffelAPI,
  DuffelContext,
  PaginationMeta,
  RandomlyGeneratedPeople,
} from '@lib/types'
import { LinksConfiguration } from '@modules/links/lib/types'
import { LinksAccessRequestFormValues } from '@modules/links/components/LinksAccessRequestModal/lib'
import { DuffelProxy } from '../types/DuffelProxy'
import { BaseClient } from './lib'
import { SearchResponse } from 'pages/api/search'

interface CombinedTokenResponse {
  testTokens: { data: DuffelAPI.Types.AccessToken[]; meta: PaginationMeta }
  liveTokens?: { data: DuffelAPI.Types.AccessToken[]; meta: PaginationMeta }
}

export interface LocationSuggestion {
  name: string
  latitude: number
  longitude: number
  shortName: string
}

export class Manage extends BaseClient {
  constructor(config: BaseClientConfig) {
    super(config)
  }

  getAccessTokens(ctx?: DuffelContext) {
    return this.request<CombinedTokenResponse>({
      method: 'GET',
      url: '/api/identity/access_tokens',
      params: { limit: 200 },
      ...this.getRequestOptions(ctx),
    })
  }

  getAccessToken(id: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.AccessToken>({
      method: 'GET',
      url: `/api/identity/access_tokens/${id}`,
      ...this.getRequestOptions(ctx),
    })
  }

  disableAccessToken(id: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.AccessToken>({
      method: 'POST',
      url: `/api/identity/access_tokens/${id}/disable`,
      ...this.getRequestOptions(ctx),
    })
  }

  createAccessToken(
    data: DuffelProxy.Input.CreateAccessToken,
    ctx?: DuffelContext
  ) {
    return this.request<DuffelAPI.Types.AccessToken>({
      method: 'POST',
      url: `/api/identity/access_tokens`,
      data,
      ...this.getRequestOptions(ctx),
    })
  }

  updateAccessToken_deprecated(
    id: string,
    data: { name: string },
    ctx?: DuffelContext
  ) {
    return this.request<DuffelAPI.Types.AccessToken>({
      method: 'PATCH',
      url: `/api/identity/access_tokens/${id}`,
      data,
      ...this.getRequestOptions(ctx),
    })
  }

  getInvitations(ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.Invitation[]>({
      method: 'GET',
      url: '/api/identity/organisation_invitations',
      ...this.getRequestOptions(ctx),
    })
  }

  getInvitation(id: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.Invitation>({
      url: `/api/identity/organisation_invitations/${id}`,
      method: 'GET',
      ...this.getRequestOptions(ctx),
    })
  }

  createInvitation(
    data: { recipientEmail: string; scope: UserRole },
    ctx?: DuffelContext
  ) {
    return this.request<DuffelAPI.Types.Invitation>({
      method: 'POST',
      url: `/api/identity/organisation_invitations`,
      data: { ...data },
      ...this.getRequestOptions(ctx),
    })
  }

  cancelInvitation(id: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.Invitation>({
      method: 'POST',
      url: `/api/identity/organisation_invitations/${id}/revoke`,
      ...this.getRequestOptions(ctx),
    })
  }

  resendInvitation(id: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.Invitation>({
      method: 'POST',
      url: `/api/identity/organisation_invitations/${id}/resend`,
      ...this.getRequestOptions(ctx),
    })
  }

  getMemberships(paginate?: PaginationMeta, ctx?: DuffelContext) {
    const requestConfig = {
      method: 'GET',
      url: '/api/identity/organisation_memberships',
      ...this.getRequestOptions(ctx),
    } as AxiosRequestConfig
    return paginate
      ? this.requestWithPagination<DuffelAPI.Types.OrganisationMembership[]>(
          requestConfig,
          paginate
        )
      : this.request<DuffelAPI.Types.OrganisationMembership[]>(requestConfig)
  }

  getMembership(id: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.OrganisationMembership>({
      method: 'GET',
      url: `/api/identity/organisation_memberships/${id}`,
      ...this.getRequestOptions(ctx),
    })
  }

  disableMembership(id: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.OrganisationMembership>({
      method: 'POST',
      url: `/api/identity/organisation_memberships/${id}/disable`,
      ...this.getRequestOptions(ctx),
    })
  }

  enableMembership(id: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.OrganisationMembership>({
      method: 'POST',
      url: `/api/identity/organisation_memberships/${id}/enable`,
      ...this.getRequestOptions(ctx),
    })
  }

  updateMembership(id: string, data: { scope: UserRole }, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.OrganisationMembership>({
      method: 'PATCH',
      url: `/api/identity/organisation_memberships/${id}`,
      data,
      ...this.getRequestOptions(ctx),
    })
  }

  updateOrganisation(
    data: DuffelAPI.Inputs.UpdateOrganisation,
    ctx?: DuffelContext
  ) {
    return this.request<DuffelAPI.Types.Organisation>({
      method: 'PATCH',
      url: `/api/identity/organisations/self`,
      data,
      ...this.getRequestOptions(ctx),
    })
  }

  getAirlineDataFromHq(ctx?: DuffelContext) {
    return this.request<AirlineMarketingData>({
      method: 'GET',
      url: `/api/hq/airlines`,
      ...this.getRequestOptions(ctx),
    })
  }

  getMockOrder(mockFixture: string) {
    return this.request<DuffelAPI.Types.Order>({
      method: 'GET',
      url: `/api/mock/order`,
      ...{ params: { mockFixture } },
    })
  }

  /**
   * Generates random passenger data for an offer. This API endpoint is not
   * part of DuffelAPI.
   **/
  generateRandomPeople(numGuests: number, ctx?: DuffelContext) {
    return this.request<RandomlyGeneratedPeople[]>({
      method: 'POST',
      url: `/api/random/people`,
      data: numGuests,
      ...this.getRequestOptions(ctx),
    })
  }

  async exportPdfItinerary(
    orderId: string,
    organisation: DuffelProxy.Types.SelfOrganisation | null,
    isLiveMode?: boolean,
    ctx?: DuffelContext
  ) {
    const requestOptions = this.getRequestOptions(ctx)

    const requestConfig: AxiosRequestConfig = {
      method: 'POST',
      url: '/api/pdf/export-itinerary',
      responseType: 'blob',
      data: {
        orderId,
        isLiveMode,
        organisation,
      },
      ...requestOptions,
    }

    try {
      const response = await this.rawRequest<Blob>(requestConfig)

      const attachment: DuffelAPI.Types.FileAttachment = {
        content: response.data,
      }

      if (response.headers && response.headers['content-disposition']) {
        const parts = response.headers['content-disposition'].split(';')
        if (parts.length > 1) {
          attachment.fileName = parts[1]
            .replace(/filename="(.*?)"/, '$1')
            .trim()
        }
      }

      return {
        data: attachment,
      }
    } catch (errorResult: any) {
      return typeof errorResult === 'string'
        ? { errors: [{ message: errorResult }] }
        : errorResult
    }
  }

  createLinksTestSession(config: LinksConfiguration, ctx?: DuffelContext) {
    return this.request<{ url: string }>({
      method: 'POST',
      url: `/api/links/test_sessions`,
      data: config,
      ...this.getRequestOptions(ctx),
    })
  }

  getLocationSuggestions(searchText: string, ctx?: DuffelContext) {
    return this.request<Array<LocationSuggestion>>({
      method: 'GET',
      url: `/api/geocoding?q=${searchText}`,
      ...this.getRequestOptions(ctx),
    })
  }

  getHotelsFromAlgolia(
    searchTerm: string,
    page?: number,
    filters?: string,
    ctx?: DuffelContext
  ) {
    return this.request<{ hits: Array<any>; page: number; nbHits: number }>({
      method: 'POST',
      url: `/api/algolia?q=${searchTerm}`,
      data: {
        page,
        filters,
      },
      ...this.getRequestOptions(ctx),
    })
  }

  requestAccessToLinks(
    values: LinksAccessRequestFormValues,
    ctx?: DuffelContext
  ) {
    return this.request<{ leadId: string }>({
      method: 'POST',
      url: `/api/links/request_access`,
      data: values,
      ...this.getRequestOptions(ctx),
    })
  }

  /**
   * Use this function to search for Flights Orders and Stays Booking for the organisation
   */
  async search(
    query: string,
    filter: string[],
    sort: string[],
    abortController: AbortController,
    ctx?: DuffelContext
  ): Promise<SearchResponse> {
    // The objects returned actually include redundant fields used for indexing.
    // These should be removed later but might help debugging.
    const searchResponse = await this.request({
      method: 'GET',
      url: `/api/search`,
      params: { query, filter, sort },
      signal: abortController.signal,
      ...this.getRequestOptions(ctx),
    })

    if (searchResponse.errors) {
      throw searchResponse.errors[0]
    }

    return searchResponse as SearchResponse
  }
}
