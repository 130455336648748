import { DuffelAPI } from '@lib/types'
import { convertArrayToHashMap } from '@modules/air-search-v2/lib/convert-array-to-hashmap'
import { CreateOrderChangeRequestFormValues } from './get-create-order-change-request-form-initial-values'

const transformFormSlicesIntoPayloadChanges = ({
  slices,
  cabinClass,
}: CreateOrderChangeRequestFormValues): DuffelAPI.Inputs.CreateOrderChangeRequestSlices => {
  const slicesToChange = slices.filter(({ toBeChanged }) => !!toBeChanged)

  return {
    remove: slicesToChange.map(({ sliceId }) => ({ sliceId })),
    add: slicesToChange.map(({ origin, destination, departureDate }) => ({
      origin,
      destination,
      cabinClass,
      departureDate: departureDate.toISOString()?.split('T')[0],
    })),
  }
}

export const transformCreateOrderChangeRequestFormValuesIntoPayload = (
  values: CreateOrderChangeRequestFormValues,
  order: DuffelAPI.Types.Order
): DuffelAPI.Inputs.CreateOrderChangeRequest => {
  const owner = order.owner.iataCode || ''
  const filteredPrivateFares = values.privateFares.filter(
    (fare) => fare.value.trim() !== ''
  )
  const privateFares = convertArrayToHashMap(filteredPrivateFares)

  return {
    orderId: values.orderId,
    slices: transformFormSlicesIntoPayloadChanges(values),
    ...(Object.keys(privateFares).length > 0 && {
      privateFares: { [owner]: [privateFares] },
    }),
  }
}
