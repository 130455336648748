import { DuffelAPI } from '@lib/types'
import { makeMockDateInThePast } from './make-mock-date'
import { makeMockOrderChangeRequestOffer } from './make-mock-order-change-request-offer'
import { makeMockOrderChangeRequestSlices } from './make-mock-order-change-request-slices'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.OrderChangeRequest => {
    const id = (seedId++).toString().padStart(5, '0')
    return {
      id: `ocr_${id}`,
      createdAt: makeMockDateInThePast(2).toISOString(),
      updatedAt: makeMockDateInThePast(1).toISOString(),
      liveMode: false,
      slices: makeMockOrderChangeRequestSlices(),
      orderChangeOffers: [makeMockOrderChangeRequestOffer()],
    }
  }
})()

export const makeMockOrderChangeRequest = (
  extendDefault?: Partial<DuffelAPI.Types.OrderChangeRequest>
): DuffelAPI.Types.OrderChangeRequest =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
