import { UNKNOWN_ERROR_MESSAGE } from '@lib/constants'
import { APIResponse, DuffelAPI } from '@lib/types'
import { WorkspaceContextMethods } from '@lib/workspace-context'

export const handleHoldOrderResultConfirmation = (
  result: APIResponse<DuffelAPI.Types.Order>,
  addToast: WorkspaceContextMethods['addToast'],
  closeDialog: WorkspaceContextMethods['closeDialog'],
  closePaymentDrawer: () => void,
  redirect: () => void
) => {
  const { errors, data, meta } = result
  if (errors && errors.length > 0)
    return addToast({
      intent: 'warning',
      message: `${errors[0].message} ${
        meta && meta.requestId ? `Request id: ${meta.requestId}` : ''
      }`,
      closeAfterTimeout: false,
    })
  if (!data)
    return addToast({
      intent: 'warning',
      message: `${UNKNOWN_ERROR_MESSAGE} ${
        meta && meta.requestId ? `Request id: ${meta.requestId}` : ''
      }`,
      closeAfterTimeout: false,
    })

  addToast({
    intent: 'success',
    message: 'Your order has been paid and confirmed!',
  })

  setTimeout(async () => {
    await redirect()
    closeDialog()
    closePaymentDrawer()
  }, 0)
}
