import type { IncomingMessage, ServerResponse } from 'http'
import { parseJWTCookies, parseSessionExpiryTimeoutCookie } from '@lib/cookies'
import { verifyJWT } from '@lib/jwt'
import { JwtPayload } from '@lib/types'
import { NotAuthorisedError } from './error-responses'

export const maybeGetJWT = (
  req: IncomingMessage,
  res: ServerResponse
): JwtPayload | null => {
  const { cookie } = req.headers
  if (!cookie) return null

  const { signature, payload } = parseJWTCookies(cookie)
  const sessionExpiryTimeout = parseSessionExpiryTimeoutCookie(cookie)
  if (!!signature && !!payload) {
    const JWTResult = verifyJWT(
      req,
      res,
      { signature, payload },
      sessionExpiryTimeout
    )
    if (JWTResult && 'token' in JWTResult) {
      return JWTResult
    } else {
      throw new NotAuthorisedError(
        "The JWT token present in this request's cookie is either missing or is not valid"
      )
    }
  }
  return null
}
