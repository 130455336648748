import { orderBy } from 'lodash'
import { DuffelAPI } from '@lib/types'
import { InvoiceItemType } from '../Chart/types'
import {
  padCurrentMonthWithDays,
  padPreviousMonthWithDays,
} from './pad-month-with-days'

/**
 * Calculate percentage change from previous period for
 * paid orders, paid ancillaries and managed content.
 */
export const calculateInvoiceItemPercentChange = (
  itemType: InvoiceItemType,
  billingPeriod: Date,
  usageRecords: DuffelAPI.Types.UsageRecord,
  previousUsageRecords?: DuffelAPI.Types.UsageRecord
) => {
  const previousDaysInMonth = padPreviousMonthWithDays(billingPeriod)
  const currentDaysInMonth = padCurrentMonthWithDays(billingPeriod)
  const paddedItemsPreviousPeriod: DuffelAPI.Types.UsageItemValueOnDate[] = []
  const paddedItemsCurrentPeriod: DuffelAPI.Types.UsageItemValueOnDate[] = []

  let usageItem
  switch (itemType) {
    case 'Paid orders':
      usageItem = 'orderConfirmed'
      break
    case 'Paid ancillaries':
      usageItem = 'servicesConfirmed'
      break
    case 'Managed content':
      usageItem = 'managedContentConfirmedOrderVolume'
      break
  }

  const sortedItemsToDatePreviousPeriod =
    previousUsageRecords &&
    orderBy(previousUsageRecords[usageItem], (item) => item.date, ['asc'])

  const sortedItemsToDateCurrentPeriod = orderBy(
    usageRecords[usageItem],
    (item) => item.date,
    ['asc']
  )

  // Number of days to compare against in previous month
  const numberOfDaysToCompare = billingPeriod.getDate()

  let previousItemsToDateSum = 0
  let currentItemsToDateSum = 0

  // Fill previous and current month with all days in calendar month
  previousDaysInMonth.map((day) => {
    const itemForDay = sortedItemsToDatePreviousPeriod?.find(
      (item) => item.date === day
    )

    return paddedItemsPreviousPeriod.push({
      value: itemForDay?.value ?? '0',
      date: day,
    })
  })

  currentDaysInMonth.map((day) => {
    const itemForDay = sortedItemsToDateCurrentPeriod?.find(
      (item) => item.date === day
    )

    return paddedItemsCurrentPeriod.push({
      value: itemForDay?.value ?? '0',
      date: day,
    })
  })

  paddedItemsPreviousPeriod.map((order, index) => {
    if (index <= numberOfDaysToCompare) {
      previousItemsToDateSum = previousItemsToDateSum + parseInt(order.value)
    }
  })

  paddedItemsCurrentPeriod.map((order, index) => {
    if (index <= numberOfDaysToCompare) {
      currentItemsToDateSum = currentItemsToDateSum + parseInt(order.value)
    }
  })

  let itemsPercentChange

  if (previousItemsToDateSum === 0 && currentItemsToDateSum === 0) {
    itemsPercentChange = 0
  } else if (previousItemsToDateSum === 0) {
    itemsPercentChange = 100
  } else {
    itemsPercentChange =
      (Math.floor(currentItemsToDateSum - previousItemsToDateSum) /
        previousItemsToDateSum) *
      100
  }

  const itemsPercentChangeString =
    itemsPercentChange >= 0
      ? `+${itemsPercentChange.toFixed()}%`
      : `${itemsPercentChange.toFixed()}%`

  return itemsPercentChangeString
}
