import snakeCase from 'lodash/snakeCase'
import { NextApiRequest } from 'next'
import {
  isDevProduction,
  isDevStaging,
  isProduction,
  isPureDev,
  isStaging,
} from '@lib/env'
import { transformDataKeys } from './transform-data-keys'
import { CURRENT_API_VERSION } from '@lib/duffel-api/constants'

const getOrigin = () => {
  if (isStaging || isDevStaging) return 'https://api.staging.duffel.com'
  if (isProduction || isDevProduction) return 'https://api.duffel.com'

  return 'https://localhost:4000'
}

async function maybeGetRequestPayload(req: NextApiRequest, payload) {
  const data = payload ? payload : transformDataKeys(req.body, snakeCase)
  return data && JSON.stringify({ data })
}

type RequestParams = {
  port: number
  origin: string
  method: string
  path: string
  payload: any
  headers: {
    accept: string
    'accept-encoding': string
    'content-type': string
    'duffel-version': string
    'duffel-web-proxy-forwarded-for': string
  }
}

export const getRequestParams = async (
  req: NextApiRequest,
  payload?: any
): Promise<RequestParams> => {
  return Object.assign(
    {},
    {
      port: isPureDev ? 80 : 433,
      origin: getOrigin(),
      method: req.method as string,
      path: (req.url as string).replace('/api', ''),
      payload: await maybeGetRequestPayload(req, payload),
      headers: {
        accept: req.headers['accept'] || 'application/json',
        'accept-encoding': req.headers['accept-encoding'] || 'gzip',
        ...(req.body && {
          'content-type': req.headers['content-type'] || 'application/json',
        }),
        'duffel-version': req.headers['duffel-version'] || CURRENT_API_VERSION,
        'duffel-web-proxy-forwarded-for':
          (req.headers['x-forwarded-for'] as string)?.split(',').shift() ||
          req.socket?.remoteAddress,
      },
    }
  )
}
