import { generateId } from '@lib/helpers'
import { DuffelAPI } from '@lib/types'

const getDefaultObject = (() => {
  return (): DuffelAPI.Inputs.OfferRequestAdultPassenger => ({
    key: `pas${generateId()}`,
    givenName: 'Tony',
    familyName: 'Stark',
    /**
     * using initialLoyaltyProgrammeAccountValues from LoyaltyAccountsInput leads
     * to a circular dependency so the initial values have been hard-coded below
     */
    loyaltyProgrammeAccounts: [
      {
        airlineIataCode: '',
        accountNumber: '',
      },
    ],
    type: 'adult',
  })
})()

export const makeMockOfferRequestAdultPassenger = (
  extendDefault?: Partial<DuffelAPI.Inputs.OfferRequestAdultPassenger>
): DuffelAPI.Inputs.OfferRequestAdultPassenger =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
