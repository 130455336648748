import * as React from 'react'
import { Button } from '@components/Button'
import { HSpace } from '@components/HSpace'
import { Icon } from '@components/Icon'
import { FilterTag } from './FilterTag'
import { FilterPopoverContainer } from './FilterPopoverContainer'
import { FiltersAction } from './lib/actions'
import { FiltersState } from './lib/state'

import styles from './Filters.module.css'
import { getFilterNames } from './lib/helpers'

export interface FiltersProps {
  filtersState: FiltersState
  filtersDispatch: React.Dispatch<FiltersAction>
  label?: string
  hideFilterMenuHeader?: boolean
}

/*
 * Filters component will handle adding, displaying, and removing filters.
 * To ensure that the filters state is predictable given that we have multiple panes and multiple update
 * path, the component is driven by the reducer and is designed to be used with `useFilters` hooks.
 *
 * The subcomponents here deals with PendingFilterValue and FilterValue. The difference between them is
 * explained in `Filters/lib/types.ts`.
 */
export const Filters: React.FC<FiltersProps> = ({
  filtersState,
  filtersDispatch,
  hideFilterMenuHeader,
  label = 'Filter',
}) => (
  <>
    {filtersState.activeFilters.map((filter, index) => (
      // Wrap each tag with its own FilterPopoverContainer so that it can show the popover
      // at the right place when clicked.
      <FilterPopoverContainer
        key={getFilterNames(filter.type)}
        filtersState={filtersState}
        filtersDispatch={filtersDispatch}
        mode={{ type: 'edit', editIndex: index }}
      >
        {(setReferenceElement) => (
          <FilterTag
            filter={filter}
            onClick={() =>
              filtersDispatch({ type: 'editFilter', atIndex: index })
            }
            onRemove={() =>
              filtersDispatch({ type: 'removeFilter', atIndex: index })
            }
            ref={setReferenceElement}
          />
        )}
      </FilterPopoverContainer>
    ))}
    <div
      style={{
        // hide + button with visibility toggle instead so that the height of the filters stays consistent
        visibility:
          filtersState.availableFilters.length > 0 ? 'visible' : 'hidden',
      }}
    >
      {/* similar to the filter tag above, we also need filter popover container here so we can deal with the popover dropdown */}
      <FilterPopoverContainer
        filtersState={filtersState}
        filtersDispatch={filtersDispatch}
        mode={{ type: 'add' }}
        hideFilterMenuHeader={hideFilterMenuHeader}
      >
        {(setReferenceElement) =>
          filtersState.activeFilters.length > 0 ? (
            <Button
              text="+"
              transparent
              onClick={() => filtersDispatch({ type: 'showFiltersDropdown' })}
              iconOnly="add"
              dashed
              ref={setReferenceElement}
            />
          ) : (
            // using a custom button here as this filter button has a different spacing than an icon button
            <button
              className={styles['add-filter-button']}
              onClick={() => filtersDispatch({ type: 'showFiltersDropdown' })}
              ref={setReferenceElement}
            >
              <HSpace space={4} alignCenter>
                <Icon name="add" size={16} /> <div>{label}</div>
              </HSpace>
            </button>
          )
        }
      </FilterPopoverContainer>
    </div>
  </>
)
