import { RadioGroupProps } from '@components/RadioGroup'
import { DuffelAPI } from '@lib/types'

export const PaymentMockSameDate: DuffelAPI.Types.PaymentRequirements = {
  paymentRequiredBy: '2022-01-17T10:42:14.545Z',
  priceGuaranteeExpiresAt: '2022-01-17T10:42:14.545Z',
  requiresInstantPayment: false,
}

export const PaymentMockDiffDate: DuffelAPI.Types.PaymentRequirements = {
  paymentRequiredBy: '2022-01-17T10:42:14.545Z',
  priceGuaranteeExpiresAt: '2022-02-18T10:42:14.545Z',
  requiresInstantPayment: false,
}

export const PaymentMockWithoutPriceGuarantee: DuffelAPI.Types.PaymentRequirements =
  {
    paymentRequiredBy: '2022-01-17T10:42:14.545Z',
    priceGuaranteeExpiresAt: null,
    requiresInstantPayment: false,
  }

export const PaymentMockWithoutSpaceGuarantee: DuffelAPI.Types.PaymentRequirements =
  {
    paymentRequiredBy: null,
    priceGuaranteeExpiresAt: '2022-01-18T10:42:14.545Z',
    requiresInstantPayment: false,
  }

export const PaymentMockOptions: RadioGroupProps<
  DuffelAPI.Inputs.Order['type']
>['options'] = [
  {
    value: 'instant',
    label: 'Pay Now',
    description: 'Pay now and confirm seat and baggage selection',
  },
  { value: 'pay_later', label: '', description: '' },
]
