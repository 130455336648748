import { AnalyticsConsentManagerPopup } from '@components/AnalyticsConsentManager'
import '@components/SupportChat/SupportChat.global.css'
import { ThemeProvider } from '@components/ThemeProvider'
import { NOT_FOUND_PATH, STATIC_OR_THIRD_PARTY_PATHS } from '@lib/constants'
import '@lib/styles/dashboard.css'
import {
  InMemoryStorageProvider,
  UnleashClient,
} from '@unleash/proxy-client-react'
import cookie from 'cookie'
import { initializeApp as initialiseFirebaseApp } from 'firebase/app'
import {
  getAuth as getFirebaseAuth,
  signInWithCustomToken as signIntoFirebase,
} from 'firebase/auth'
import { doc, getDoc, getFirestore, onSnapshot } from 'firebase/firestore'
import htmlescape from 'htmlescape'
import { NextPageContext } from 'next'
import { AppContext, AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import NProgress from 'nprogress'
import * as React from 'react'
// We have to change CSRF import because webpack5 doesn't like the normal import
import * as CSRF from '@lib/csrf'
import { createDuffelClient } from '@lib/duffel-client'
import { getQueryValue, redirect } from '@lib/helpers'
import { decodeJWT } from '@lib/jwt'
import { orgModePathsMap, orgPathsMap, rootPathsMap } from '@lib/paths'
import { WithPopoverContext } from '@lib/popover-context/popover-context'
import { signOutAndRedirect } from '@lib/security'
import { captureException, setSentryUser } from '@lib/sentry'
import { FirestoreUserProfile, trackPageNav } from '@lib/tracking'
import { getAnalyticsObject } from '@lib/tracking/get-analytics-object'
import { setSessionPropertiesOnCookie } from '@lib/tracking/session-tracking'
import {
  DuffelContext,
  DuffelPermissions,
  DuffelProxy,
  JwtPayload,
  UnleashContext,
  ValueOf,
} from '@lib/types'
import {
  FlagProviderWithOverrides,
  getUnleashContextObject,
  getUnleashProxyUrl,
  unleashConfig,
} from '@lib/unleash'
import { WithWorkspace } from '@lib/workspace-context'

interface GetSelfResult {
  user?: DuffelProxy.Types.Self
  permissions?: JwtPayload
  csrfToken?: string
  firebaseToken?: string
  signedOut?: boolean
}

interface MyAppProps extends AppProps {
  authProps?: { user: DuffelProxy.Types.Self; permissions: DuffelPermissions }
  pageProps: any
  csrfToken?: string
  firestoreUserProfile?: FirestoreUserProfile
  firebaseToken?: string
  err?: any
  skipRender?: boolean

  /**
   * This value is used to determine if the page ran get initial props or not.
   * We have to choose between server side props and get initial props in _app.
   * So for the pages where we use get server side props, we set this value to true
   * and need to retrieve workspace values ourselves as it won't be available.
   */
  usesGetServerSideProps?: boolean
}

const getLiveMode = (mode?: string) => {
  switch (mode) {
    case 'live':
      return 'true'
    case 'test':
      return 'false'
    default:
      return undefined
  }
}

const getSelfQuery = (
  liveMode: string,
  org: string,
  pathname: string,
  decodedCookie?: JwtPayload
) => {
  let currentOrg = org

  /**
   * if for any reason we don't send an org via query
   * we want to get the current organisation inside our saved cookie
   * if still no none is found, we will make a query without the org
   */
  if (!org && decodedCookie) {
    currentOrg = decodedCookie.organisation
  }

  /**
   * We don't collect liveMode due to switching teams
   * if we pre-populate liveMode with the cookie settings we might be falsely suggesting the other team has liveMode permissions
   */
  return {
    liveMode: FORCE_TEST_MODE_PATHS.some((path) => pathname.startsWith(path))
      ? 'false'
      : getLiveMode(liveMode),
    org: currentOrg,
  }
}

// some paths will only work with test mode (e.g. OCT) so we need to force that
const FORCE_TEST_MODE_PATHS: Array<ValueOf<typeof orgPathsMap>> = [
  orgPathsMap.linksIndex,
]

// some paths require the newest self at all time for them to work properly
const NO_SELF_CACHE_PATHS: Array<
  | ValueOf<typeof orgPathsMap>
  | ValueOf<typeof rootPathsMap>
  | ValueOf<typeof orgModePathsMap>
> = [
  rootPathsMap.index,
  rootPathsMap.joinShow,
  rootPathsMap.confirmEmailShow,
  orgPathsMap.teamIndex,
  orgPathsMap.teamInvite,
  orgPathsMap.organisationPreferencesIndex,
]

const getSelfForApp = async (ctx: NextPageContext): Promise<GetSelfResult> => {
  const sessionCookies = ctx.req
    ? ctx.req.headers.cookie || ''
    : document.cookie
  const parsedCookie = cookie.parse(sessionCookies)
  if (!('ff-p' in parsedCookie) || parsedCookie['ff-p'] === '') {
    return {}
  }

  const decodedCookie = decodeJWT(sessionCookies)

  const { req, res, pathname } = ctx
  const mode = getQueryValue(ctx.query.mode)
  const org = getQueryValue(ctx.query.org)

  const query = getSelfQuery(mode, org, pathname, decodedCookie)

  const duffelClient = createDuffelClient(
    org,
    // only pass liveMode boolean if it's defined. Otherwise, we'll let the server side
    // decide the mode based on the org's verified status.
    query.liveMode ? query.liveMode === 'true' : undefined
  )

  try {
    const forceRefreshSelf = NO_SELF_CACHE_PATHS.some(
      (path) => pathname === path
    )
    const result = await duffelClient.Identity.getSelf(
      {
        req,
        res,
        query,
      } as any,
      forceRefreshSelf
    )

    const { headers, data } = result

    if (req) {
      const cookie = headers && headers['set-cookie']
      if (cookie && res) {
        res.setHeader('set-cookie', cookie)
      }
    }

    setSessionPropertiesOnCookie()
    return {
      user: data.data?.self,
      permissions: data.data?.permissions,
      csrfToken: (headers[CSRF.CSRF_HEADER_NAME] as string) || '',
      firebaseToken: data.data?.firebaseToken,
    }
  } catch (err: any) {
    if (err.meta?.status >= 500) {
      captureException(Error('Server error in getSelf check'), err)
    }
    const { query, req, res } = ctx
    signOutAndRedirect(query.next, req, res)

    // ensure that we don't retain the mode when we sign out
    return { signedOut: true }
  }
}

const initialiseFirestore = async (firebaseToken: string) => {
  const firebaseConfig = process.env.NEXT_PUBLIC_FIREBASE_CONFIG

  if (!firebaseConfig) {
    throw new Error('firebase config not found in env')
  }

  const app = initialiseFirebaseApp(JSON.parse(firebaseConfig))
  await signIntoFirebase(getFirebaseAuth(app), firebaseToken)
}

const env = {
  NODE_ENV: process.env.NODE_ENV,
  NEXT_PUBLIC_APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
  NEXT_PUBLIC_SEGMENT_WRITE_KEY: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
}

let prevRouter = {
  pathname: {},
  query: {
    mode: '',
    org: '',
  },
}

function MyApp({
  Component,
  pageProps,
  authProps,
  csrfToken,
  firestoreUserProfile: appFirestoreUserProfile,
  firebaseToken,
  err,
  skipRender,
  usesGetServerSideProps,
}: MyAppProps) {
  const router = useRouter()

  const unleashClient = React.useMemo(() => {
    const unleashProxyUrl = getUnleashProxyUrl()
    const unleashSsrOption =
      typeof window !== 'undefined'
        ? {}
        : { fetch: fetch, storageProvider: new InMemoryStorageProvider() }
    return new UnleashClient({
      ...unleashSsrOption,
      ...unleashConfig,
      url: unleashProxyUrl,
      bootstrap: pageProps.toggles,
      context: pageProps.unleashContext,
    })
  }, [pageProps.toggles])

  React.useEffect(() => {
    const asyncProcess = async () => {
      // do async work ...
      if (pageProps.isUnleashProxyClientRunning) {
        return unleashClient.start()
      }
      return
    }

    asyncProcess()
  }, [pageProps.isUnleashProxyClientRunning, unleashClient])

  const [firestoreUserProfile, setFirestoreUserProfile] = React.useState(
    appFirestoreUserProfile
  )

  React.useEffect(() => {
    // The parameters of the route event handler here is actually of type any,
    // so we can't guarantee for sure that this is always going to be what is being
    // described here.
    // Hence, we are treating all of these params as optionals so it doesn't crash the page
    // if we ever get this wrong.
    const handleStart = (_path?: string, options?: { shallow?: boolean }) => {
      if (!options?.shallow) {
        NProgress.start()
      }
    }
    const handleStop = () => {
      NProgress.done()
      if (
        prevRouter.query?.mode !== router.query.mode ||
        prevRouter.query?.org !== router.query.org ||
        prevRouter.pathname !== router.pathname
      ) {
        trackPageNav(router.pathname, router.asPath)
      }
      setSentryUser(authProps?.user?.id)
      prevRouter = {
        pathname: router.pathname,
        query: {
          org: getQueryValue(router.query.org),
          mode: getQueryValue(router.query.mode),
        },
      }
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router, authProps?.user?.id])

  React.useEffect(() => {
    setFirestoreUserProfile(appFirestoreUserProfile)
  }, [appFirestoreUserProfile])

  // Ensure that Firebase is initialised on the client, regardless whether the app
  // is being rendered server-side or client-side
  const [firestoreInitialised, setFirestoreInitialised] = React.useState(false)
  React.useEffect(() => {
    const initializeFirestoreUserProfile = async () => {
      setFirestoreInitialised(false)
      if (!authProps?.user?.id || !firebaseToken) {
        return
      }

      try {
        await initialiseFirestore(firebaseToken)
        setFirestoreInitialised(true)
      } catch (error) {
        // If there's a Firebase error, fail gracefully and return empty user profile
        if (error instanceof Error) {
          const currentOrganisation = authProps?.permissions?.organisation
            ? authProps?.user?.organisationsBySlug[
                authProps?.permissions?.organisation
              ]
            : undefined
          captureException(error, {
            userId: authProps?.user.id,
            organisationId: currentOrganisation?.id,
            organisation: currentOrganisation?.name,
          })
        }
      }
    }

    initializeFirestoreUserProfile()
  }, [authProps, firebaseToken])

  React.useEffect(() => {
    if (!firestoreInitialised || !authProps?.user?.id) {
      return
    }

    // subscribe to all the user profile updates from firestore
    return onSnapshot(
      doc(getFirestore(), 'users', authProps.user.id),
      (doc) => {
        setFirestoreUserProfile(doc.data() ?? {})
      }
    )
  }, [firestoreInitialised, authProps?.user?.id])

  if (skipRender) {
    return null
  }

  return (
    <>
      <Head>
        <meta name={CSRF.CSRF_META_TAG_NAME} content={csrfToken} />
      </Head>

      <Script
        id="helpers-env"
        strategy="lazyOnload"
        nonce={process.env.nextScriptRandomIdentifier}
      >{`window.__ENV__ = ${htmlescape(env)}`}</Script>

      <FlagProviderWithOverrides
        unleashClient={unleashClient}
        startClient={false}
      >
        <WithPopoverContext>
          {usesGetServerSideProps ? (
            // Heads up,
            // We don't yet have a need for firestore on the server side props components
            // If you need it, you can do the work to make them available on your page.
            <Component {...pageProps} err={err} />
          ) : (
            <WithWorkspace
              // ensure that we remount the context component when we receive a
              // different mode, so that no other components see the stale mode
              key={String(authProps?.permissions?.liveMode)}
              {...authProps}
              firestoreUserProfile={firestoreUserProfile}
            >
              <Component {...pageProps} err={err} />
            </WithWorkspace>
          )}
        </WithPopoverContext>
      </FlagProviderWithOverrides>

      <AnalyticsConsentManagerPopup analytics={getAnalyticsObject()} />

      <ThemeProvider />
    </>
  )
}

MyApp.getInitialProps = async ({ Component, ctx }: AppContext) => {
  if (Component['usesGetServerSideProps'])
    return { pageProps: {}, authProps: {}, usesGetServerSideProps: true }

  let isUnleashProxyClientRunning
  let unleashContext: UnleashContext | undefined
  // Ensure that urls like sourcemaps and other static urls never affect session cookies
  // redirecting them to 404 pages
  if (
    (ctx.req?.url?.endsWith('.css.map') ||
      STATIC_OR_THIRD_PARTY_PATHS.some((path) =>
        ctx.asPath?.startsWith(path)
      )) &&
    ctx.res
  ) {
    ctx.res?.writeHead(302, { Location: '/404' })
    return ctx.res.end()
  }

  // when it's a 404 request, make sure to not affect page props and cookies
  if (ctx.asPath?.startsWith(NOT_FOUND_PATH)) {
    return { pageProps: {} }
  }

  const { csrfToken, user, permissions, firebaseToken, signedOut } =
    await getSelfForApp(ctx)

  // We want to prevent multiple redirects
  if (signedOut) {
    return { pageProps: {}, skipRender: true }
  }

  const authProps = { user, permissions }

  let duffelContext: DuffelContext = {
    ...ctx,
    user,
    permissions,
    csrfToken,
    currentOrganisation: authProps.permissions?.organisation
      ? authProps.user?.organisationsBySlug[authProps.permissions?.organisation]
      : undefined,
  }

  if (typeof window === 'undefined') {
    isUnleashProxyClientRunning = true
    const unleashProxyUrl = getUnleashProxyUrl()
    // Set up unleash to fetch only once in order to not create
    // bindings for every request which will cause a memory leak
    const unleash = new UnleashClient({
      ...unleashConfig,
      url: unleashProxyUrl,
      disableMetrics: true,
      disableRefresh: true,
      fetch,
      storageProvider: new InMemoryStorageProvider(),
    })

    unleash.on('error', async () => {
      console.info('Unleash Proxy had an error and will stop running')
      // captureException(err, ctx)
      isUnleashProxyClientRunning = false
      unleash.stop()
    })

    /*
     * In future if we want to add more heuristics for Unleash to use in future, e.g. organisation ID or admin status, this is where to add it.
     */
    unleash.updateContext(
      getUnleashContextObject(authProps.user, authProps.permissions)
    )

    await unleash.start()
    const toggles = unleash.getAllToggles()
    unleashContext = unleash.getContext()

    duffelContext = {
      ...duffelContext,
      toggles,
    }
  }

  let pageProps = Component.getInitialProps
    ? await Component.getInitialProps(duffelContext)
    : ({} as any)

  if (pageProps.redirect) {
    redirect(pageProps.redirect, ctx.res)
    return { pageProps, skipRender: true }
  }

  if (typeof window === 'undefined') {
    pageProps = {
      ...pageProps,
      unleashContext,
      toggles: duffelContext.toggles,
      isUnleashProxyClientRunning,
    }
  }

  let firestoreUserProfile = {}
  if (firebaseToken && user?.id) {
    // get the initial firestore user profile
    try {
      await initialiseFirestore(firebaseToken)
      const ref = doc(getFirestore(), 'users', user.id)
      firestoreUserProfile = (await getDoc(ref)).data() ?? {}
    } catch (error) {
      // If there's a Firebase error, fail gracefully and return empty user profile
      if (error instanceof Error) {
        captureException(error, {
          userId: authProps?.user?.id,
          organisationId: duffelContext.currentOrganisation?.id,
          organisation: duffelContext.currentOrganisation?.name,
        })
      }
    }
  }

  return {
    pageProps,
    authProps,
    csrfToken,
    firestoreUserProfile,
    firebaseToken,
  }
}

export default MyApp
