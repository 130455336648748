import * as React from 'react'
import classNames from 'classnames'
import { HSpace } from '@components/HSpace'
import { Text } from '@components/Text'
import { Tooltip, TooltipProps } from '@components/Tooltip'
import { Icon } from '@components/Icon'
import styles from './MenuItemDisabled.module.css'

interface MenuItemDisabledProps {
  tooltipText?: TooltipProps['text']
  destructive?: boolean
}

export const MenuItemDisabled: React.FC<
  React.PropsWithChildren<MenuItemDisabledProps>
> = ({ children, tooltipText, destructive }) => (
  <HSpace className={styles['wrapper']} space={8}>
    <Text
      className={classNames(
        styles['label'],
        destructive && styles['label--destructive']
      )}
      fontSize="C2"
      color="grey-500"
    >
      {children}
    </Text>
    <div>
      {tooltipText && (
        <Tooltip placement="bottom" text={tooltipText}>
          <Icon
            className={classNames(
              styles['icon'],
              destructive && styles['icon--destructive']
            )}
            name="info"
            size={16}
          ></Icon>
        </Tooltip>
      )}
    </div>
  </HSpace>
)
