import { compact } from 'lodash'
import { getSegmentFlightNumber } from '@components/FlightSummary/lib/get-segment-flight-number'
import { getDurationDifference } from '@lib/date'
import { PostSearchFilters } from '@lib/paths'
import { DuffelAPI, OfferSort } from '@lib/types'
import { OfferWithRequestId } from '@modules/air-search-v2/lib/multi-step-search/types'

// if the duration field is null, we can calculate the duration from the
// departure/arrival time instead
export const getFallbackDuration = (offer: DuffelAPI.Types.Offer) => {
  const firstSegment = offer.slices[0].segments[0]
  const lastSegment =
    offer.slices[0].segments[offer.slices[0].segments.length - 1]

  return (
    new Date(lastSegment.arrivingAt).getTime() -
    new Date(firstSegment.departingAt).getTime()
  )
}

export const compareDuration = (
  offerA: DuffelAPI.Types.Offer,
  offerB: DuffelAPI.Types.Offer
) => {
  const durationA = offerA.slices[0].duration
  const durationB = offerB.slices[0].duration
  if (durationA && durationB) {
    return getDurationDifference(durationA, durationB).isNegative ? 1 : -1
  }

  return getFallbackDuration(offerA) - getFallbackDuration(offerB)
}

// format the ISO date string into the hh:MM format used in the search time ranges
// and compare them
const isWithinTimeRange = (
  timeRanges: { from: string; to: string },
  isoDate: string
) => {
  const formattedTime = isoDate.split('T')[1].split(':').slice(0, 2).join(':')
  return (
    formattedTime.localeCompare(timeRanges.from) >= 0 &&
    formattedTime.localeCompare(timeRanges.to) <= 0
  )
}

export const filterOffers =
  (postSearchFilters?: PostSearchFilters) => (offers: OfferWithRequestId[]) =>
    offers.filter((offer) => {
      // NOTE: we can always refer to the 0th index here as there is always only
      // one slice on a partial offer.
      const numTechnicalStops = offer.slices[0].segments
        .flatMap((segment) => segment.stops.length)
        .reduce((acc, next) => acc + next)
      if (
        postSearchFilters?.maxConnections !== undefined &&
        offer.slices[0].segments.length - 1 + numTechnicalStops >
          postSearchFilters.maxConnections
      ) {
        return false
      }

      if (
        postSearchFilters?.airlineIataCode &&
        postSearchFilters.airlineIataCode !== 'all' &&
        !offer.slices[0].segments.some((segment) =>
          compact([
            segment.operatingCarrier.iataCode,
            segment.marketingCarrier.iataCode,
          ]).includes(postSearchFilters.airlineIataCode ?? '')
        )
      ) {
        return false
      }

      if (postSearchFilters?.timeRanges) {
        const { timeRanges } = postSearchFilters

        if (
          !isWithinTimeRange(
            timeRanges.departureTime,
            offer.slices[0].segments[0].departingAt
          ) ||
          !isWithinTimeRange(
            timeRanges.arrivalTime,
            offer.slices[0].segments[offer.slices[0].segments.length - 1]
              .arrivingAt
          )
        ) {
          return false
        }
      }

      if (
        postSearchFilters?.flightNumber?.length &&
        !offer.slices[0].segments.some((segment) =>
          getSegmentFlightNumber(segment)
            .toLowerCase()
            .includes(postSearchFilters.flightNumber?.toLowerCase() ?? '')
        )
      ) {
        return false
      }

      return true
    })

export const sortOffers =
  (sorting?: OfferSort) => (offers: OfferWithRequestId[]) =>
    [...offers].sort((offerA, offerB) => {
      if (!sorting || sorting === 'total_amount') {
        return +offerA.totalAmount - +offerB.totalAmount
      }

      switch (sorting) {
        case '-total_amount':
          return +offerB.totalAmount - +offerA.totalAmount
        case 'total_duration':
          return compareDuration(offerA, offerB)
        case '-total_duration':
          return -compareDuration(offerA, offerB)
      }
    })
