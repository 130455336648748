import { captureException } from '@lib/sentry'
import { getCohortPropertiesFromCookie } from './cohort-properties'
import { getAnalyticsObject } from './get-analytics-object'
import { getUserPropertiesFromCookie } from './get-user-properties-from-cookie'
import { trackEvent } from './track-event'
import { pageNavEventNames } from './types'

const excludedEvents = [
  //  Do not send "password reset" tokens to third parties
  'dashboard_forgotten_password_token_page_visited',
  // do not send tokens that may be on the next param of the sign-in page to third parties
  'dashboard_sign_in_page_visited',
]

export const trackPageNav = (pathname: string, asPath: string) => {
  let eventName = pageNavEventNames[pathname]
  if (excludedEvents.includes(eventName)) {
    return
  }

  if (!eventName) {
    const err = new Error(
      `The event name for ${pathname} nav analytics is missing`
    )
    captureException(err, {
      pathname,
      asPath,
    })

    /*
      If we reach this condition it means we did not add an entry for the given 
      `pathname` into pageNavEventNames (`./types.ts`). If that's the cae, we still want to capture 
      a generic navigation event:
    */
    eventName = 'dashboard_page_visited'
  }

  const userProperties = getUserPropertiesFromCookie()
  const cohortProperties = getCohortPropertiesFromCookie()

  const eventProps = {
    ...userProperties,
    ...cohortProperties,
  }

  const analytics = getAnalyticsObject()
  if (!analytics) {
    return
  }

  trackEvent(eventName, eventProps)
  analytics.page('dashboard', eventName, eventProps)
}
