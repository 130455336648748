import { isDateTimeInTheFuture } from '@lib/date'
import { DuffelAPI } from '@lib/types'

export const isOrderInThePast = (order: DuffelAPI.Types.Order): boolean => {
  /**
   * All parts of the order are over a day ago
   * (as a workaround to dealing with airport timezones)
   */
  let hasSegmentInTheFuture = false
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  order.slices.map((slice) => {
    slice.segments.map((segment) => {
      if (isDateTimeInTheFuture(segment.arrivingAt, yesterday)) {
        hasSegmentInTheFuture = true
        return
      }
    })

    if (hasSegmentInTheFuture) return
  })

  return !hasSegmentInTheFuture
}
