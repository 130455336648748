import { generateId } from '@lib/helpers'
import { DuffelAPI } from '@lib/types'

const getDefaultObject = (() => {
  return (): DuffelAPI.Inputs.OfferRequestChildPassenger => ({
    key: `pas${generateId()}`,
    givenName: 'Morgan',
    familyName: 'Stark',
    /**
     * using initialLoyaltyProgrammeAccountValues from LoyaltyAccountsInput leads
     * to a circular dependency so the initial values have been hard-coded below
     */
    loyaltyProgrammeAccounts: [
      {
        airlineIataCode: '',
        accountNumber: '',
      },
    ],
    age: 4,
  })
})()

export const makeMockOfferRequestChildPassenger = (
  extendDefault?: Partial<DuffelAPI.Inputs.OfferRequestChildPassenger>
): DuffelAPI.Inputs.OfferRequestChildPassenger =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
