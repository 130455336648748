import { capitalize } from 'lodash'
import { string, date } from 'yup'
import { addDays, startOfDay } from 'date-fns'

export const internationalPhoneNumberRegex =
  /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)(\s)?([0-9 ]{1,14})$/
export const dateRegex =
  /^\s*((19|20)\d{2})\-(1[012]|0?[1-9])\-(3[01]|[12][0-9]|0?[1-9])\s*$/
export const emailRgx =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const nameRgx =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇČŠŽ∂ ,.'-]+$/u

export const dateStr = (required?: boolean) =>
  required
    ? string()
        .required('Date is required')
        .matches(dateRegex, 'Please enter a valid date')
    : string().matches(dateRegex, 'Please enter a valid date')

export const iataCode = (label: string) =>
  string()
    .required(`${label} is required`)
    .length(3, `${label} code must be 3 digits`)

export const sliceDepartureDate = (fieldName: 'departure' | 'return') =>
  date()
    .typeError(`${capitalize(fieldName)} date is required`)
    .min(
      startOfDay(addDays(new Date(), -1)),
      `The ${fieldName} date must be in the future`
    )

export const validEmailAddressRequired = () =>
  string()
    .email('Must be a valid email address')
    .required('Email address is required')
    .max(255, 'Email address must be less than 255 characters')

export * from './has-validation-errors'
