import { DuffelAPI } from '@lib/types'

const isNotBlank = (loyaltyProgrammeAccountProperty: string) =>
  !!loyaltyProgrammeAccountProperty

/**
 * Since the LoyaltyCodeInput does not have any validation, there's a chance that
 * some of the loyalty programme account objects may contain empty strings which
 * would result in an error from the API. This function loops over an array of
 * LoyaltyProgrammeAccount and removes any "empty" accounts
 *
 * This ensures these "empty accounts" aren't passed to the API
 *
 * This should only be a temporary solution and should be removed as soon as validation
 * is added to the LoyaltyCodeInput component
 */
export const sanitiseLoyaltyProgrammeAccounts = (
  loyaltyProgrammeAccounts: DuffelAPI.Types.LoyaltyProgrammeAccount[]
): DuffelAPI.Types.LoyaltyProgrammeAccount[] =>
  loyaltyProgrammeAccounts.filter(
    (account) =>
      account.airlineIataCode &&
      isNotBlank(account.airlineIataCode) &&
      isNotBlank(account.accountNumber)
  )

/**
 * Ensures all loyaltyProgrammeAccounts attached to a OfferRequestPassenger[] are non-empty.
 *
 * This should be called before the OfferRequest is sent to the API to ensure there
 * aren't any errors due to "empty" loyalty programme accounts in the request
 */
export const sanitisePassengersLoyaltyProgrammeAccounts = (
  passengers: DuffelAPI.Inputs.OfferRequestPassenger[]
): DuffelAPI.Inputs.OfferRequestPassenger[] =>
  passengers.map((passenger) => {
    // Remove the key before sending to the API since it's only used on the front-end
    const { key: _key, ...mutatedPassenger } = passenger

    const sanitisedLoyaltyProgrammeAccounts =
      mutatedPassenger.loyaltyProgrammeAccounts
        ? sanitiseLoyaltyProgrammeAccounts(
            mutatedPassenger.loyaltyProgrammeAccounts
          )
        : []

    return {
      ...mutatedPassenger,
      loyaltyProgrammeAccounts: sanitisedLoyaltyProgrammeAccounts,
    } as DuffelAPI.Inputs.OfferRequestPassenger
  })
