import { NextApiResponse } from 'next'
import { gzip } from 'node-gzip'

export const sendCompressed = async (
  res: NextApiResponse,
  status: number,
  // TODO: improve the type here so that whenever we send an object for the body, the body is
  // wrapped with { data } so that it can work with `request` function on `BaseClient.ts`
  // without type assertion shenanigans
  body: any
) => {
  res.setHeader('Content-Encoding', 'gzip')
  if (typeof body !== 'string')
    res.setHeader('Content-Type', 'application/json')

  body = typeof body === 'string' ? body : JSON.stringify(body)
  res.status(status).send(await gzip(body))
}
