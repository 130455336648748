import * as React from 'react'
import css from 'styled-jsx/css'
import { scroller } from 'react-scroll'
import { useFormikContext } from 'formik'
import { Button } from '@components/Button'
import { VSpace } from '@components/VSpace'
import { HSpace } from '@components/HSpace'
import {
  hasSameDate,
  getTimeString,
  getDateString,
  getRelativeDateParts,
} from '@lib/date'
import { hasValidationErrors } from '@lib/form-validation'
import { trackEvent } from '@lib/tracking'
import { useWorkspace } from '@lib/workspace-context'
import { useCheckoutContext } from '@modules/air-search-v2/lib/use-checkout-context'
import { CheckoutFormValues } from '@modules/air-search-v2/lib'
import { onPayLater } from '../Checkout/checkout-payment-actions'
import { ConfirmAndHoldCondition, ConfirmAndHold } from './index'

type ConfirmAndHoldProps = {
  /**
   * An object representing the relative time format in parts
   * that can be used for custom locale-aware formatting.
   * @example
   * [
     { type: 'literal', value: 'in ' },
     { type: 'integer', unit: 'month', value: '9' },
     { type: 'literal', value: ' months' }
   ]
   */
  relativeDate: Intl.RelativeTimeFormatPart[]
  /**
   * Relative Payment Time
   * @example
   * 14:00
   */
  relativeTime: string
  /**
   * Payment date
   * @example
   * 27/02/21
   */
  date: string
}

export interface CheckoutPayLaterSummaryProps {
  priceGuaranteeExpiresAt: string | null
  paymentRequiredBy: string | null
}

const { className, styles } = css.resolve`
  .hspace {
    flex: 1;
  }
`
const scrollToValidationAlert = () =>
  scroller.scrollTo(`passenger-info-form-alert-container`, {
    duration: 300,
    smooth: true,
    offset: 400,
  })

const getPaymentLimit = (
  priceGuaranteeExpiresAtDate: Date | null,
  paymentRequiredByDate: Date | null
) => {
  const holdPriceLimit: ConfirmAndHoldProps | null =
    priceGuaranteeExpiresAtDate && {
      relativeDate: getRelativeDateParts(priceGuaranteeExpiresAtDate, 'en-GB'),
      relativeTime: getTimeString(priceGuaranteeExpiresAtDate),
      date: getDateString(priceGuaranteeExpiresAtDate, 'short'),
    }
  const holdSpaceLimit: ConfirmAndHoldProps | null = paymentRequiredByDate && {
    relativeDate: getRelativeDateParts(paymentRequiredByDate, 'en-GB'),
    relativeTime: getTimeString(paymentRequiredByDate),
    date: getDateString(paymentRequiredByDate, 'short'),
  }
  if (holdPriceLimit && holdSpaceLimit) {
    if (hasSameDate(priceGuaranteeExpiresAtDate, paymentRequiredByDate)) {
      return (
        <>
          <HSpace space={8} className={className}>
            <ConfirmAndHold
              label="Hold price & space for"
              type="price"
              relativeDate={holdPriceLimit.relativeDate}
              date={holdPriceLimit.date}
            />
          </HSpace>
          <ConfirmAndHoldCondition
            holdSpaceDate={holdSpaceLimit.relativeDate}
            holdPriceDate={holdPriceLimit.relativeDate}
          />
          {styles}
        </>
      )
    } else {
      return (
        <>
          <HSpace space={8} className={className}>
            <ConfirmAndHold
              label="Hold price for"
              type="price"
              relativeDate={holdPriceLimit.relativeDate}
              date={holdPriceLimit.date}
            />
            <ConfirmAndHold
              label="Hold space for"
              type="space"
              relativeDate={holdSpaceLimit.relativeDate}
              date={holdSpaceLimit.date}
            />
          </HSpace>
          <ConfirmAndHoldCondition
            holdSpaceDate={holdSpaceLimit.relativeDate}
            holdPriceDate={holdPriceLimit.relativeDate}
          />
          {styles}
        </>
      )
    }
  } else if (!holdSpaceLimit && holdPriceLimit) {
    return (
      <>
        <HSpace space={8} className={className}>
          <ConfirmAndHold
            label="Hold price for"
            type="price"
            relativeDate={holdPriceLimit.relativeDate}
            date={holdPriceLimit.date}
          />
        </HSpace>
        <ConfirmAndHoldCondition holdPriceDate={holdPriceLimit.relativeDate} />
        {styles}
      </>
    )
  } else if (holdSpaceLimit && !holdPriceLimit) {
    return (
      <>
        <HSpace space={8} className={className}>
          <ConfirmAndHold
            label="Hold space for"
            type="space"
            relativeDate={holdSpaceLimit.relativeDate}
            date={holdSpaceLimit.date}
          />
        </HSpace>
        <ConfirmAndHoldCondition holdSpaceDate={holdSpaceLimit.relativeDate} />
        {styles}
      </>
    )
  }
}

export const CheckoutPayLaterSummary: React.FC<
  CheckoutPayLaterSummaryProps
> = ({ priceGuaranteeExpiresAt, paymentRequiredBy }) => {
  const workspaceContext = useWorkspace()
  const { offer, incrementPaymentButtonClickCount, partialOfferRequest } =
    useCheckoutContext()
  const priceGuaranteeExpiresAtDate =
    (priceGuaranteeExpiresAt && new Date(priceGuaranteeExpiresAt)) || null
  const paymentRequiredByDate =
    (paymentRequiredBy && new Date(paymentRequiredBy)) || null
  const requirements = getPaymentLimit(
    priceGuaranteeExpiresAtDate,
    paymentRequiredByDate
  )
  const { validateForm, submitForm, isSubmitting } =
    useFormikContext<CheckoutFormValues>()

  return (
    <VSpace space={8}>
      <VSpace space={32}>
        <HSpace space={32} alignCenter>
          {requirements}
        </HSpace>
        <Button
          text="Confirm and hold"
          onClick={async () => {
            trackEvent('dashboard_offer_checkout_pay_button_clicked', {
              event_type: 'interaction',
              offer_request_id: partialOfferRequest.id,
              offer_id: offer.id,
              payment_type: 'pay_later',
            })
            incrementPaymentButtonClickCount()
            const result = await validateForm()
            if (!hasValidationErrors(result)) {
              onPayLater(
                partialOfferRequest.id,
                offer,
                workspaceContext,
                submitForm
              )
            } else {
              submitForm() // only used to enable validation warnings
              scrollToValidationAlert()
            }
          }}
          fill
          large
          isWaiting={isSubmitting}
          disabled={isSubmitting}
        />
      </VSpace>
    </VSpace>
  )
}
