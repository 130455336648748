import { DuffelAPI } from '@lib/types'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.OfferRequestPassenger => ({
    age: 28,
    familyName: 'Tony',
    givenName: 'Stark',
    id: `pas_${(seedId++).toString().padStart(5, '0')}`,
    loyaltyProgrammeAccounts: [
      { airlineIataCode: 'LX', accountNumber: 'LX60' },
    ],
    type: 'adult',
  })
})()

export const makeMockOfferRequestPassenger = (
  extendDefault?: Partial<DuffelAPI.Types.OfferRequestPassenger>
): DuffelAPI.Types.OfferRequestPassenger =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
