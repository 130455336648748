import { DuffelAPI } from '@lib/types'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.OrderCancellation => {
    const id = (seedId++).toString().padStart(5, '0')
    const issuedOn = '2023-03-16'
    const confirmedAt = '2023-03-16'

    return {
      createdAt: '2019-11-25T16:32:53.428376Z',
      expiresAt: '2019-11-25T16:32:53.428376Z',
      refundTo: 'airline_credits',
      refundCurrency: 'GBP',
      refundAmount: '279.96',
      orderId: `ord_${id}`,
      liveMode: false,
      id: `ore_${id}`,
      confirmedAt,
      airlineCredits: [
        {
          id: `fcc_${id}`,
          creditName: 'Future Flight Credit',
          creditCode: '01212345678901',
          creditAmount: '139.98',
          creditCurrency: 'GBP',
          issuedOn,
          passengerId: 'pas_123',
        },
        {
          id: `fcc_${id}`,
          creditName: 'Future Flight Credit',
          creditCode: '01212345678902',
          creditAmount: '139.98',
          creditCurrency: 'GBP',
          issuedOn,
          passengerId: 'pas_456',
        },
      ],
    }
  }
})()

export const makeMockOrderCancellation = (
  extendDefault?: Partial<DuffelAPI.Types.OrderCancellation>
): DuffelAPI.Types.OrderCancellation =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
