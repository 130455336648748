import { DuffelAPI } from '@lib/types'
import { makeMockOffer } from './make-mock-offer'
import { makeMockOfferPassenger } from './make-mock-offer-passenger'
import { makeMockOfferSliceFromOriginDestination } from './make-mock-offer-slice'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.Offer => {
    const partialOfferId = `off_${(seedId++).toString().padStart(5, '0')}_0`
    const mockPassenger = makeMockOfferPassenger()

    return makeMockOffer({
      id: partialOfferId,
      partial: true,
      passengers: [mockPassenger],
      slices: [
        makeMockOfferSliceFromOriginDestination('JFK', 'LHR', mockPassenger.id),
      ],
    })
  }
})()

export const makeMockPartialOffer = (
  extendDefault?: Partial<DuffelAPI.Types.Offer>
): DuffelAPI.Types.Offer =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
