import { useWorkspace } from '@lib/workspace-context'
import { useOrganisationSourcesContext } from '@lib/sources/organisation-sources-context'
import { DuffelSources, tempDisabledSourceIds } from '@lib/sources'
import { Sentry } from '@lib/sentry'
import { SourceAirlineWithSourceId } from '../AirlinesSelect'

// Some sources have been removed and we don't want to alert on it
const ignoredSources = ['etihad_airways']

export const useAvailableSourceAirlines = (): SourceAirlineWithSourceId[] => {
  const { liveMode } = useWorkspace().permissions || {}
  const organisationSources = useOrganisationSourcesContext()

  return organisationSources
    .filter((organisationSource, index, self) => {
      const isActive = !!organisationSource.organisationContentTypes.find(
        (contentType) => contentType.status === 'active'
      )

      // sometimes the API sends duplicated sources entries with the same id and we filter this out here
      const isUnique =
        index ===
        self.findIndex((source) => source.id === organisationSource.id)

      const canDisplayInCurrentMode = organisationSource.source.sandboxOnly
        ? !liveMode
        : true
      const hasStaticDuffelSource = DuffelSources[organisationSource.source.id]
      const isTemporarilyDisabled = tempDisabledSourceIds.includes(
        organisationSource.source.id
      )

      if (
        !hasStaticDuffelSource &&
        !ignoredSources.includes(organisationSource.source.id)
      ) {
        Sentry.captureException(
          new Error(
            `No static Duffel Source found for ${organisationSource.source.id}`
          )
        )
      }

      return (
        isActive &&
        canDisplayInCurrentMode &&
        hasStaticDuffelSource &&
        !isTemporarilyDisabled &&
        isUnique
      )
    })
    .map((organisationSource) => ({
      sourceId: organisationSource.source.id,
      name: DuffelSources[organisationSource.source.id].name,
      iataCode:
        DuffelSources[organisationSource.source.id].featuredAirlines[0]
          .iataCode,
    }))
}
