import { TravelDetails } from '@lib/types'
import { makeMockAirline } from './make-mock-airline'
import { makeMockAirport } from './make-mock-airport'

const getDefaultObject = (() => {
  return (): TravelDetails<any> => ({
    originDestination: 'LHR-BOS',
    departingAt: '2020-12-28T10:00:00',
    origin: makeMockAirport({
      iataCode: 'LHR',
      name: 'Very very long name that goes on forever and may break onto many lines which can be annoying for layouts',
    }),

    arrivingAt: '2020-12-28T11:53:00',
    destination: makeMockAirport({
      iataCode: 'BOS',
      name: 'Very very long name that goes on forever and may break onto many lines which can be annoying for layouts',
    }),

    aircraft: 'Boeing 787 Dreamliner',
    cabinClass: 'Economy',
    fareBrandName: 'Economy',

    flightDuration: '01h 53m',

    flight: 'BA123',
    airline: makeMockAirline(),
  })
})()

export const makeMockTravelDetails = (
  extendDefault?: Partial<TravelDetails<any>>
): TravelDetails<any> =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
