import classnames from 'classnames'
import * as React from 'react'
import { PropsWithoutRef } from 'react'
import { Heading } from '@components/Heading'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import styles from './NonIdealState.module.css'

export interface NonIdealStateProps
  extends PropsWithoutRef<JSX.IntrinsicElements['div']> {
  /**
   * The children to be rendered. This should usually be used to render an action that resolves the non-ideal state. Avoid passing raw strings as they will not receive margins and disrupt the layout flow.
   */
  children?: React.ReactNode

  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string

  /**
   * A longer description of the non-ideal state.
   */
  description?: React.ReactNode

  /**
   * The title of the non-ideal state
   */
  title: string
}

export const NonIdealState: React.FC<NonIdealStateProps> = ({
  className,
  description,
  title,
  children,
  ...props
}) => {
  const cx = classnames(styles['non-ideal-state'], className)

  return (
    <VSpace space={24} className={cx} {...props}>
      <VSpace space={4}>
        <Heading h3 textAlign="center" fontWeight="medium">
          {title}
        </Heading>

        {description && (
          <Text
            asElement="p"
            fontSize="C1"
            lineHeight="copy"
            textAlign="center"
            color="grey-600"
          >
            {description}
          </Text>
        )}
      </VSpace>

      {children && <div className={styles['children']}>{children}</div>}
    </VSpace>
  )
}
