import * as React from 'react'
import classNames from 'classnames'
import { Button } from '@components/Button'
import { HSpace } from '@components/HSpace'
import { Icon } from '@components/Icon'
import { PopoverContainer } from '@components/PopoverContainer'
import { VSpace } from '@components/VSpace'
import { useDuffelPopper } from '@lib/hooks/use-duffel-popper'
import {
  SEARCH_TIME_OPTIONS,
  SearchTimeRanges,
} from '@modules/air-search-v2/forms/SearchForm/lib/search-time-ranges'
import { isAnyTimeRange, parseSearchTimeRanges } from './lib'
import { TimeRangeSelector } from './TimeRangeSelector'
import styles from './SearchTimeFilters.module.css'

export interface SearchTimeFiltersProps {
  selectedSearchTimeRanges: SearchTimeRanges
  onSubmit: (searchTimeRanges: SearchTimeRanges) => void
}

export const SearchTimeFilters: React.FC<SearchTimeFiltersProps> = ({
  selectedSearchTimeRanges,
  onSubmit,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
  const {
    departureTimeRange: selectedDepartureTimeRange,
    arrivalTimeRange: selectedArrivalTimeRange,
  } = parseSearchTimeRanges(selectedSearchTimeRanges)

  const { popper, setReferenceElement, setPopperElement } = useDuffelPopper(
    isPopoverOpen,
    () => {
      // if the filter is closed without confirming, reset the state
      setDepartureTimeRange(selectedDepartureTimeRange)
      setArrivalTimeRange(selectedArrivalTimeRange)
      setIsPopoverOpen(false)
    },
    {
      placement: 'bottom-start',
      modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
    }
  )

  const [departureTimeRange, setDepartureTimeRange] = React.useState(
    selectedDepartureTimeRange
  )
  const [arrivalTimeRange, setArrivalTimeRange] = React.useState(
    selectedArrivalTimeRange
  )

  return (
    <>
      <button
        type="button"
        ref={setReferenceElement}
        onClick={() => setIsPopoverOpen(true)}
        className={classNames(
          styles['stamp-filter'],
          isPopoverOpen && styles['stamp-filter--active']
        )}
      >
        <HSpace space={4} alignCenter>
          <div>
            {isAnyTimeRange(selectedDepartureTimeRange) &&
            isAnyTimeRange(selectedArrivalTimeRange)
              ? 'At any time'
              : 'At specific times'}
          </div>
          <Icon
            name="arrow_drop_down"
            size={12}
            className={styles['dropdown-icon']}
          />
        </HSpace>
      </button>
      {isPopoverOpen && (
        <PopoverContainer
          ref={setPopperElement}
          style={{
            zIndex: 50,
            ...popper.styles.popper,
            padding: 0,
            width: '328px',
          }}
          {...popper.attributes}
        >
          <VSpace space={16} className="u-padding24">
            <TimeRangeSelector
              label="Take-off"
              selectedRange={departureTimeRange}
              onChange={(range) => setDepartureTimeRange(range)}
              icon="flight_takeoff"
            />
            <TimeRangeSelector
              label="Landing"
              selectedRange={arrivalTimeRange}
              onChange={(range) => setArrivalTimeRange(range)}
              icon="flight_land"
            />
            <HSpace space={0} justify="end">
              <Button
                text="Confirm"
                onClick={() => {
                  setIsPopoverOpen(false)
                  onSubmit({
                    departureTime: {
                      from: SEARCH_TIME_OPTIONS[departureTimeRange.from],
                      to: SEARCH_TIME_OPTIONS[departureTimeRange.to],
                    },
                    arrivalTime: {
                      from: SEARCH_TIME_OPTIONS[arrivalTimeRange.from],
                      to: SEARCH_TIME_OPTIONS[arrivalTimeRange.to],
                    },
                  })
                }}
              />
            </HSpace>
          </VSpace>
        </PopoverContainer>
      )}
    </>
  )
}
