import { FeeType } from '@components/PlanPriceCard'
import { AcceptedCurrencies } from '@lib/constants'
import { getCurrencySymbol } from './get-currency-symbol'

/**
 * Helper function to transform volume labels to include currency symbol
 */
export const transformVolumeLabel = (
  volume: string,
  selectedCurrency: AcceptedCurrencies,
  feeType: FeeType
) => {
  const cs = getCurrencySymbol(selectedCurrency)

  if (feeType.type !== 'contentFee') {
    return volume
  }

  switch (volume) {
    case '1,000,000':
      return `${cs}1-${cs}1M`
    case '10,000,000':
      return `${cs}1M-${cs}10M`
    case '10,000,000+':
      return `${cs}10M+`
    default:
      return volume
  }
}
