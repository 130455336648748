import classNames from 'classnames'
import * as React from 'react'
import { captureException } from '@lib/sentry'
import styles from './RangeSlider.module.css'

export interface Range {
  from: number
  to: number
}
export interface RangeSliderProps {
  minValue: number
  maxValue: number
  selectedRange: Range
  step: number
  onChange: (values: { from: number; to: number }) => void
  ariaLabel: string
  disabled?: boolean
  useTooltip?: boolean
  tooltipNumberFormatter?: (value: number) => string
}

const THUMB_SIZE = 16

export const RangeSlider: React.FC<RangeSliderProps> = ({
  selectedRange,
  minValue,
  maxValue,
  step,
  onChange,
  ariaLabel,
  disabled,
  useTooltip,
  tooltipNumberFormatter,
}) => {
  React.useEffect(() => {
    if (selectedRange.from <= selectedRange.to) {
      return
    }

    // we are not going to attempt to correct it since it could lead to infinite render
    // if the consumer doesn't handle it properly
    captureException(
      new Error(
        `Unexpected 'from' value (${selectedRange.from}) being greater than 'to' value (${selectedRange.to})`
      )
    )
  }, [selectedRange.from, selectedRange.to])

  const leftPercentage =
    ((selectedRange.from - minValue) / (maxValue - minValue)) * 100
  const rightPercentage =
    ((maxValue - selectedRange.to) / (maxValue - minValue)) * 100

  return (
    <div className={styles['container']}>
      <div
        className={classNames(styles['track'], styles['track--background'])}
      />
      <div
        className={classNames(styles['track'], styles['track--active'], {
          [styles['track--min-selected']]: selectedRange.from === minValue,
          [styles['track--max-selected']]: selectedRange.to === maxValue,
        })}
        style={{
          // We need this extra adjustment to keep the track aligns with the thumb.
          // There might be a better way to do this, but so far this works
          left: `calc(${leftPercentage}% - ${
            (leftPercentage / 100) * THUMB_SIZE
          }px)`,
          right: `calc(${rightPercentage}% - ${
            (rightPercentage / 100) * THUMB_SIZE
          }px)`,
        }}
      />
      <input
        aria-label={`${ariaLabel} from`}
        type="range"
        min={minValue}
        max={maxValue}
        step={step}
        value={selectedRange.from}
        onChange={(event) => {
          const from = +event.target.value
          // prevent sliding over another thumb
          if (from > selectedRange.to - step) {
            return
          }
          onChange({ from, to: selectedRange.to })
        }}
        disabled={disabled}
        className={classNames(styles['slider'], styles['slider--from'])}
      />
      {useTooltip && (
        <div
          className={styles['tooltip']}
          style={{
            position: 'absolute',
            // We need this extra adjustment to keep the track aligns with the thumb.
            // There might be a better way to do this, but so far this works

            left: `calc(${leftPercentage}% - ${
              (leftPercentage / 100) * THUMB_SIZE
            }px)`,
            transform: `translateX(calc(-50% + ${THUMB_SIZE / 2}px))`,
            width: 'fit-content',
          }}
        >
          {tooltipNumberFormatter
            ? tooltipNumberFormatter(selectedRange.from)
            : selectedRange.from}
        </div>
      )}

      <input
        aria-label={`${ariaLabel} to`}
        type="range"
        min={minValue}
        max={maxValue}
        step={step}
        value={selectedRange.to}
        onChange={(event) => {
          const to = +event.target.value
          // prevent sliding over another thumb
          if (to < selectedRange.from + step) {
            return
          }
          onChange({ from: selectedRange.from, to })
        }}
        className={classNames(styles['slider'], styles['slider--to'])}
        disabled={disabled}
      />
      {useTooltip && (
        <div
          className={styles['tooltip']}
          style={{
            position: 'absolute',
            // We need this extra adjustment to keep the track aligns with the thumb.
            // There might be a better way to do this, but so far this works

            right: `calc(${rightPercentage}% - ${
              (rightPercentage / 100) * THUMB_SIZE
            }px)`,
            transform: `translateX(calc(50% - ${THUMB_SIZE / 4}px))`,
            width: 'fit-content',
          }}
        >
          {tooltipNumberFormatter
            ? tooltipNumberFormatter(selectedRange.to)
            : selectedRange.to}
        </div>
      )}
    </div>
  )
}
