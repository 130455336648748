import { ConsentManagerBuilder } from '@segment/consent-manager'
import { noop } from 'lodash'
import * as React from 'react'
import { captureException } from '@lib/sentry'
import { NEXT_PUBLIC_SEGMENT_WRITE_KEY, isDev } from '@lib/env'
import { AnalyticsConsentManagerModal } from './AnalyticsConsentManagerModal'
import { AnalyticsConsentManagerProps } from './types'

export const AnalyticsConsentManager: React.FC<
  AnalyticsConsentManagerProps
> = ({ children: renderChildrenWithProps, analytics }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  if (NEXT_PUBLIC_SEGMENT_WRITE_KEY === undefined) {
    captureException(
      new Error(
        'Attempted to render the `AnalyticsConsentManager` without a `NEXT_PUBLIC_SEGMENT_WRITE_KEY`'
      )
    )
    return null
  }

  return (
    <ConsentManagerBuilder
      writeKey={NEXT_PUBLIC_SEGMENT_WRITE_KEY}
      // pass a noop to onError if we're in dev so that we don't have to deal with `Failed to fetch` error every time we refresh the page
      onError={isDev ? noop : undefined}
    >
      {(props) => (
        <>
          {renderChildrenWithProps(
            // The props available for the render function can be found on:
            // https://github.com/segmentio/consent-manager#consentmanagerbuilder-render-props
            { setIsModalOpen, ...props }
          )}
          {isModalOpen && (
            <AnalyticsConsentManagerModal
              preferences={props.preferences}
              onAcceptCookies={() => {
                analytics.load({ writeKey: NEXT_PUBLIC_SEGMENT_WRITE_KEY! })

                props.saveConsent(true, false)
                setIsModalOpen(false)
              }}
              onDenyCookies={() => {
                props.saveConsent(false, false)
                setIsModalOpen(false)
              }}
              onClose={() => setIsModalOpen(false)}
            />
          )}
        </>
      )}
    </ConsentManagerBuilder>
  )
}
