import * as React from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { Overlay } from '@components/Overlay'

import { usePopoverContext } from '@lib/popover-context'
import { useKeyPress } from '@lib/hooks'
import styles from './Modal.module.css'

export interface ModalProps {
  onClose: () => void
  className?: string
  shouldCloseOverlayOnClick?: boolean
  width?: number
  animate?: boolean
}

export const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  children,
  onClose,
  className,
  shouldCloseOverlayOnClick = true,
  width,
  animate = false,
}) => {
  const { popoverCount } = usePopoverContext()

  React.useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  useKeyPress('Escape', onClose)

  /*
  If the `width` prop was included, set the width of the modal.

  If you are consuming this component using CSS modules (not styled-jsx),
  you should use the width prop to set the modal's width.
  */
  const widthStyles = width
    ? {
        style: {
          width: `${width}px`,
          maxWidth: `${width}px`,
        },
      }
    : {}

  const onClick = (event) => {
    if (popoverCount > 0) {
      return
    }
    event.stopPropagation()
  }

  return (
    <Overlay
      onClick={
        shouldCloseOverlayOnClick && popoverCount === 0
          ? onClose
          : () => undefined
      }
    >
      <motion.div
        initial={{ opacity: animate ? 0 : 1 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className={styles['modal-overlay']}
      >
        <div
          role="presentation"
          className={classNames(styles['modal-contents'], className)}
          onClick={(event) => onClick(event)}
          {...widthStyles}
        >
          {children}
        </div>
      </motion.div>
    </Overlay>
  )
}
