import { DuffelAPI } from '@lib/types'
import { SupportCategory, SupportCategoryCopy } from './types'

/**
 * You can find Duffel's Zendesk custom fields at:
 *
 * https://duffelhelp.zendesk.com/admin/objects-rules/tickets/ticket-fields
 */
export const ZENDESK_CUSTOM_FIELD_ID_MAP = {
  airlineIataCode: 360023444360,
  bookingReference: 360009924919,
  content: 1900000592614,
  firstFlightDate: 360021140400,
  jetUrl: 360010012219,
  orderId: 360009924879,
  organisationId: 360019577220,
  refreshOrder: 360010036960,
  url: 360019577120,
  userId: 360019577200,
} as const

/**
 * You can find Duffel's Zendesk groups at:
 *
 * https://duffelhelp.zendesk.com/groups
 */
export const ZENDESK_SUPPORT_GROUP_ID_MAP = {
  finance: 4417057195538,
  general: 360003272459,
  technicalAndProduct: 360003061719,
  travel: 360003061739,
} as const

/**
 * duffel-bot requester ID
 */
export const ZENDESK_DUFFEL_BOT_REQUESTER_ID = 379812774080

/**
 * Converts order content type to Zendesk content custom field values,
 * documented here:
 *
 * https://duffelhelp.zendesk.com/admin/objects-rules/tickets/ticket-fields/1900000592614
 */
export const ZENDESK_CONTENT_CUSTOM_FIELD_VALUE_MAP: Record<
  DuffelAPI.Types.Order['content'],
  string
> = {
  managed: 'duffel_content',
  self_managed: 'byo_content',
}

export const ORDER_SUPPORT_TICKET_TAG = 'dashboard_order_support'
export type OrderSupportTicketTag = typeof ORDER_SUPPORT_TICKET_TAG

/**
 * These categories are Zendesk ticket tags owned by Travel Ops. If you need
 * to add anything to this list, please consult them first.
 */
export const CONTACT_SUPPORT_CATEGORIES = [
  'activation',
  'airline-initiated-change',
  // For requesting a new airline that Duffel doesn't currently provide.
  'airline-request',
  'cancellation-refund',
  'legal',
  'order-change',
  'wallet',
  'baggage_request',
  'dashboard-other',
  'seat_selection',
  'name_correction',
] as const

export const OTHER_SUPPORT_CATEGORIES = [
  // For requesting access to a source Duffel currently provides.
  'source-request',
  'duffel-links-access',
] as const

export const SUPPORT_CATEGORIES = [
  ...CONTACT_SUPPORT_CATEGORIES,
  ...OTHER_SUPPORT_CATEGORIES,
] as const

export const SUPPORT_CATEGORY_COPY_MAP: Record<
  SupportCategory,
  SupportCategoryCopy
> = {
  activation: {
    formSelectOptionLabel: 'Activation',
    supportTicketSubject: 'Activation',
  },
  'airline-initiated-change': {
    supportTicketSubject: 'Airline-Initiated Changes',
    actionPhrase: 'accept the changes for the order',
    formSelectOptionLabel: 'Airline-initiated changes',
    helpCenterLink:
      'https://help.duffel.com/hc/en-gb/sections/360005398779-Changes-and-Cancellations',
    helpCenterTopic: 'managing airline-initiated changes',
    orderActivityReason: 'related to an airline-initiated change',
  },
  baggage_request: {
    supportTicketSubject: 'Request bags',
    actionPhrase: 'add bags',
    orderActivityReason: 'for adding bags to a booking',
  },
  'airline-request': {
    supportTicketSubject: 'New Airline Request',
    formSelectOptionLabel: 'Request a new airline',
  },
  'cancellation-refund': {
    supportTicketSubject: 'Cancellation / Refund',
    actionPhrase: 'cancel the order',
    formSelectOptionLabel: 'Cancellations and refunds',
    orderActivityReason: 'for a cancellation',
    helpCenterLink:
      'https://help.duffel.com/hc/en-gb/sections/360005398779-Changes-and-Cancellations',
    helpCenterTopic: 'cancellations and refunds',
  },
  'dashboard-other': {
    supportTicketSubject: 'Other',
    actionPhrase: 'request something else related to this booking',
    orderActivityReason: 'for something else',
    formSelectOptionLabel: 'Other',
  },
  legal: {
    supportTicketSubject: 'Legal',
    formSelectOptionLabel: 'Legal',
  },
  'order-change': {
    supportTicketSubject: 'Order Change',
    actionPhrase: 'change the order',
    formSelectOptionLabel: 'Changing an order',
    orderActivityReason: 'for an order change',
    helpCenterLink:
      'https://help.duffel.com/hc/en-gb/sections/360005398779-Changes-and-Cancellations',
    helpCenterTopic: 'changing orders',
  },
  'source-request': {
    supportTicketSubject: 'Source Request',
  },
  wallet: {
    formSelectOptionLabel: 'Balance',
    supportTicketSubject: 'Balance',
    helpCenterLink:
      'https://help.duffel.com/hc/en-gb/sections/360005362640-Balance',
    helpCenterTopic: 'your Duffel Balance',
  },
  seat_selection: {
    supportTicketSubject: 'Add Seats',
    actionPhrase: 'add seats to a booking',
    orderActivityReason: 'for adding seats to a booking',
  },
  name_correction: {
    supportTicketSubject: 'Name Correction',
    actionPhrase: 'correct one or more passenger names on a booking',
    orderActivityReason: 'for correcting a name on a booking',
  },
  'duffel-links-access': {
    supportTicketSubject: 'Duffel Links Access Request',
  },
}
