import * as React from 'react'
import { AirlineLogo } from '@components/AirlineLogo'
import { Callout } from '@components/Callout'
import { FormikDatePicker } from '@components/DatePicker'
import { HSpace } from '@components/HSpace'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { Button } from '@components/Button'
import { FormField } from '@components/Form'
import { Icon } from '@components/Icon'
import { Stamp } from '@components/Stamp'
import { getDateString } from '@lib/date'
import { FormikIataCodeLookup } from '@modules/air-search-v2/forms/SearchForm'
import { CreateOrderChangeRequestFormValuesSlice } from '../lib'
import { useSliceOrderValidation } from '../lib/slice-validation-context'
import { CreateOrderChangeFormProps } from './CreateOrderChangeForm'

interface CreateOrderChangeFormSliceProps {
  name: string
  slice: CreateOrderChangeRequestFormValuesSlice
  initialSliceValue: CreateOrderChangeRequestFormValuesSlice
  onChange: (slice: CreateOrderChangeRequestFormValuesSlice) => void
}

export const CreateOrderChangeFormSlicePreviewHeader: React.FC<
  CreateOrderChangeFormSliceProps
> = ({ slice, initialSliceValue, onChange }) => {
  const toggleToBeChanged = () =>
    onChange(
      Object.assign({}, initialSliceValue, {
        toBeChanged: !slice.toBeChanged,
      })
    )

  const hasSliceChanged =
    initialSliceValue.origin !== slice.origin ||
    initialSliceValue.destination !== slice.destination ||
    initialSliceValue.departureDate !== slice.departureDate

  return (
    <div className="slice-preview">
      <HSpace space={24} alignCenter>
        <AirlineLogo {...initialSliceValue.owner} />
        <VSpace space={0}>
          <HSpace space={4} alignCenter>
            <Text fontWeight="medium" fontSize="C2">
              {initialSliceValue.origin}
            </Text>
            <Icon size={16} name="arrow_right" />
            <Text fontWeight="medium" fontSize="C2">
              {initialSliceValue.destination}
            </Text>
          </HSpace>
          <Text fontSize="C2" color="grey-500">
            {getDateString(initialSliceValue.departureDate, 'long')}
          </Text>
        </VSpace>
      </HSpace>
      <HSpace space={12} alignCenter>
        {hasSliceChanged && (
          <Stamp color="yellow" borderRadius="full">
            Changed
          </Stamp>
        )}
        <Button
          iconOnly={slice.toBeChanged ? 'unfold_less' : 'unfold_more'}
          onClick={toggleToBeChanged}
          text=""
          outlined
          className="slice-preview-expand-button"
        />
      </HSpace>
      <style jsx>{`
        .slice-preview {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .slice-preview :global(.slice-preview-expand-button) {
          color: var(--grey-700);
          border: 1px solid var(--grey-200);
        }
      `}</style>
    </div>
  )
}

export const CreateOrderChangeFormSliceEdit: React.FC<
  CreateOrderChangeFormSliceProps
> = ({ name, slice }) => {
  const { hasDepartureDateWarning, warning } =
    useSliceOrderValidation()[slice.sliceId]

  return (
    <VSpace space={16} className="slice-edit">
      <HSpace space={16} className="split-container">
        <FormikIataCodeLookup label="Origin" name={`${name}.origin`} />
        <FormikIataCodeLookup
          label="Destination"
          name={`${name}.destination`}
        />
      </HSpace>
      <FormikDatePicker
        name={`${name}.departureDate`}
        label="Departure date"
        optionalField
      />

      {hasDepartureDateWarning && (
        <Callout iconName="warning" intent="warning">
          <Text fontSize="C2">{warning}</Text>
        </Callout>
      )}

      <style jsx>{`
        :global(.split-container > *) {
          flex: 50%;
        }

        :global(.slice-edit) :global(.callout) {
          background: var(--yellow-100) !important;
          margin-bottom: -var(--space-16);
        }

        :global(.slice-edit) :global(.callout-icon) {
          color: var(--yellow-500) !important;
        }
      `}</style>
    </VSpace>
  )
}

export const CreateOrderChangeFormSlice: React.FC<
  CreateOrderChangeFormSliceProps
> = (props) => {
  const { toBeChanged } = props.slice
  return (
    <VSpace space={16} className="slice">
      <CreateOrderChangeFormSlicePreviewHeader {...props} />
      {toBeChanged && <CreateOrderChangeFormSliceEdit {...props} />}
      <style jsx>{`
        :global(.slice) {
          padding: var(--space-16);
          border: solid 2px var(--grey-200);
          border-radius: var(--border-radius-8);
        }
      `}</style>
    </VSpace>
  )
}

export const CreateOrderChangeFormSlices: React.FC<
  CreateOrderChangeFormProps
> = (props) => {
  const { values, setFieldValue, initialValues } = props

  return (
    <FormField label="Flights">
      {values.slices.map((slice, index) => {
        const fieldName = `slices.${index}`
        return (
          <CreateOrderChangeFormSlice
            key={slice.sliceId}
            initialSliceValue={initialValues.slices[index]}
            slice={slice}
            onChange={(newSlice) => setFieldValue(fieldName, newSlice)}
            name={fieldName}
          />
        )
      })}
    </FormField>
  )
}
