export const hasValidationErrors = (validationErrorObject: any) => {
  if (validationErrorObject === undefined || validationErrorObject === null) {
    return false
  } else if (
    typeof validationErrorObject === 'object' &&
    Array.isArray(validationErrorObject)
  ) {
    return validationErrorObject
      .filter((value) => value !== undefined && value !== null)
      .some((value) => hasValidationErrors(value))
  } else if (typeof validationErrorObject === 'object') {
    return Object.entries(validationErrorObject).some(([_, value]) =>
      hasValidationErrors(value)
    )
  } else {
    return true
  }
}
