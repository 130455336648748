export const SPACES = {
  2: '--space-2',
  4: '--space-4',
  8: '--space-8',
  12: '--space-12',
  16: '--space-16',
  20: '--space-20',
  24: '--space-24',
  32: '--space-32',
  40: '--space-40',
  48: '--space-48',
  64: '--space-64',
  80: '--space-80',
  96: '--space-96',
  128: '--space-128',
} as const

export type Space = keyof typeof SPACES
