import { DuffelAPI } from '@lib/types'
import { makeMockOfferBaggageServiceMetadata } from './make-mock-offer-baggage-service-metadata'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.OfferAvailableServiceBaggage => ({
    id: `ase_${(seedId++).toString().padStart(5, '0')}`,
    maximumQuantity: 1,
    metadata: makeMockOfferBaggageServiceMetadata(),
    passengerIds: [`pas_00001`],
    segmentIds: [`seg_00001`],
    totalAmount: '50.00',
    totalCurrency: 'GBP',
    type: 'baggage',
  })
})()

export const makeMockOfferBaggageService = (
  extendDefault?: Partial<DuffelAPI.Types.OfferAvailableServiceBaggage>
): DuffelAPI.Types.OfferAvailableServiceBaggage =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
