import { DuffelAPI } from '@lib/types'

export const getCarriers = (
  forSegments:
    | DuffelAPI.Types.OfferSliceSegment[]
    | DuffelAPI.Types.OrderSliceSegment[]
) => {
  const operatorSet = new Set<string>()
  const operatingCarriers = new Array<DuffelAPI.Types.Airline>()
  const marketingCarrier =
    forSegments.length > 0 ? forSegments[0].marketingCarrier : null

  forSegments.forEach(({ operatingCarrier }) => {
    if (
      operatingCarrier &&
      operatingCarrier.iataCode &&
      !operatorSet.has(operatingCarrier.iataCode)
    ) {
      operatorSet.add(operatingCarrier.iataCode)
      operatingCarriers.push(operatingCarrier)
    }
  })

  return { marketingCarrier, operatingCarriers }
}
