import { getDateString, getTimeString, isDateTimeInTheFuture } from '@lib/date'
import { DuffelAPI } from '@lib/types'

export const getNextDeparture = (
  order: DuffelAPI.Types.Order,
  locale = 'en-GB'
) => {
  // On the new orders pages, we compute the nextDeparture server side and attach it to the order
  if ('nextDeparture' in order) {
    return order.nextDeparture as string
  }
  const slices = order.slices

  const nextSegment = slices
    .flatMap((slice) => slice.segments)
    .find((segment) => isDateTimeInTheFuture(segment.departingAt))

  if (!nextSegment) return '—'

  return `${getDateString(
    nextSegment.departingAt,
    'short',
    locale
  )} ${getTimeString(nextSegment.departingAt, locale)}`
}
