import { DuffelAPI } from '@lib/types'

export interface NumberRange {
  min: number
  max: number
}
export interface PassengerCountAllowedRanges {
  adults: NumberRange
  children: NumberRange
}

const minPassengerCount = 1
const maxPassengerCount = 9

export const getPassengerCountAllowedRanges = (
  passengers: DuffelAPI.Inputs.OfferRequestPassenger[]
): PassengerCountAllowedRanges => {
  const adults = passengers.filter(
    (pax) => 'type' in pax && pax.type === 'adult'
  )
  const children = passengers.filter(
    (pax) => 'age' in pax
  ) as DuffelAPI.Inputs.OfferRequestChildPassenger[]

  return {
    adults: {
      min: !children.length ? minPassengerCount : children.length,
      max: children.length
        ? maxPassengerCount - children.length
        : maxPassengerCount,
    },
    children: {
      min: adults.length ? 0 : minPassengerCount,
      max: adults.length
        ? maxPassengerCount - adults.length
        : maxPassengerCount,
    },
  }
}
