import { DuffelAPI } from '@lib/types'
import { getLatestAIC } from './get-latest-aic'

export const isOrderCancelled = (order: DuffelAPI.Types.Order): boolean => {
  /**
   * The order has been cancelled through Duffel.
   */
  if (order.cancelledAt) {
    return true
  }

  return isOrderCancelledByAirline(order)
}

export const isOrderCancelledByAirline = (
  order: DuffelAPI.Types.Order
): boolean => {
  const latestAIC = getLatestAIC(order)

  /**
   * Just checking for no slices on the order is not enough here, as orders
   * flown in their entirety can also return no slices. We have to also check
   * the latest AIC on the order to confirm a cancellation by the airline.
   */
  if (
    order.slices.length === 0 &&
    latestAIC &&
    latestAIC.added.length === 0 &&
    latestAIC.removed.length > 0 &&
    latestAIC.actionTaken === 'accepted'
  ) {
    return true
  }
  return false
}
