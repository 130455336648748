import * as React from 'react'
import { VSpace } from '@components/VSpace'
import { DuffelAPI } from '@lib/types'
import {
  ChangesPayment,
  ChangesSummary,
  OrderChangeHeader,
} from '../../components'

export interface OrderChangeOfferContainerProps {
  order: DuffelAPI.Types.Order
  orderChangeRequest: DuffelAPI.Types.OrderChangeRequest
  orderChange: DuffelAPI.Types.OrderChange
  componentClientKey: string
}

export const OrderChangeContainer: React.FC<OrderChangeOfferContainerProps> = ({
  order,
  orderChangeRequest,
  orderChange,
  componentClientKey,
}) => {
  return (
    <VSpace
      data-selector="fs-show"
      space={32}
      id="order-change-result-container"
    >
      <OrderChangeHeader
        order={order}
        orderChange={orderChange}
        orderChangeRequest={orderChangeRequest}
      />

      <ChangesSummary order={order} orderChange={orderChange} />
      <ChangesPayment
        order={order}
        initialOrderChange={orderChange}
        componentClientKey={componentClientKey}
      />
    </VSpace>
  )
}
