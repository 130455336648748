import { orderBy } from 'lodash'
import { DuffelAPI } from '@lib/types'
import { ExcessSearchValueOnDate } from '../Chart/types'
import { getDaysInMonth } from './get-days-in-month'

/**
 * This function returns formatted excess search data and fills in calendar
 * dates on which orders were not made so the Excess Search chart
 * labels cover the entire selected month
 */

// v2 refactor: Keep processed data as part of graph solution in case
// we want to bring back the idea of how this metric changes over time
export const processExcessSearchesData = (
  billingPeriod: Date,
  maxSearchToBookRatio: number,
  offerRequestsCreated?: DuffelAPI.Types.UsageItemValueOnDate[],
  ordersConfirmed?: DuffelAPI.Types.UsageItemValueOnDate[]
): ExcessSearchValueOnDate[] => {
  const excessSearches: ExcessSearchValueOnDate[] = []

  const daysInMonth = getDaysInMonth(
    billingPeriod.getMonth(),
    billingPeriod.getFullYear()
  )

  const paddedOfferRequestsCreated: DuffelAPI.Types.UsageItemValueOnDate[] = []
  const paddedOrdersConfirmed: DuffelAPI.Types.UsageItemValueOnDate[] = []

  if (!offerRequestsCreated && !ordersConfirmed) {
    daysInMonth.forEach((day) =>
      excessSearches.push({
        searches: '0',
        orders: '0',
        date: day,
        maxSearchToBookRatio,
      })
    )

    return excessSearches
  }

  offerRequestsCreated = orderBy(offerRequestsCreated, (item) => item.date, [
    'asc',
  ])

  ordersConfirmed = orderBy(ordersConfirmed, (item) => item.date, ['asc'])

  // Pad out offerRequestsCreated with all dates in calendar
  daysInMonth.map((day) => {
    const offerRequestCreatedForDay = offerRequestsCreated?.find(
      (offerRequestCreated) => offerRequestCreated.date === day
    )

    paddedOfferRequestsCreated.push({
      value: offerRequestCreatedForDay ? offerRequestCreatedForDay.value : '0',
      date: day,
    })
  })

  // Pad out ordersConfirmed with all dates in calendar month
  daysInMonth.map((day) => {
    const orderConfirmedForDay = ordersConfirmed?.find(
      (orderConfirmed) => orderConfirmed.date === day
    )

    paddedOrdersConfirmed.push({
      value: orderConfirmedForDay ? orderConfirmedForDay.value : '0',
      date: day,
    })
  })

  // Create cumulative searches and cumulative orders
  const cumulativeOfferRequests: DuffelAPI.Types.UsageItemValueOnDate[] = []
  const cumulativeOrdersConfirmed: DuffelAPI.Types.UsageItemValueOnDate[] = []

  let offerRequestsSum = 0
  paddedOfferRequestsCreated.map((offerRequest) => {
    offerRequestsSum = offerRequestsSum + parseInt(offerRequest.value)
    cumulativeOfferRequests.push({
      date: offerRequest.date,
      value: offerRequestsSum.toString(),
    })
  })

  let ordersConfirmedSum = 0
  paddedOrdersConfirmed.map((order) => {
    ordersConfirmedSum = ordersConfirmedSum + parseInt(order.value)
    cumulativeOrdersConfirmed.push({
      date: order.date,
      value: ordersConfirmedSum.toString(),
    })
  })

  cumulativeOfferRequests.map((offerRequest) => {
    const ordersConfirmedForDate = cumulativeOrdersConfirmed.find(
      (order) => offerRequest.date === order.date
    )

    const orders = ordersConfirmedForDate
      ? ordersConfirmedForDate.value.toString()
      : '0'

    excessSearches.push({
      searches: offerRequest.value,
      orders: orders,
      date: offerRequest.date,
      maxSearchToBookRatio,
    })
  })

  return excessSearches
}
