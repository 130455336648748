import type { IncomingHttpHeaders } from 'http'
import fetch from 'isomorphic-unfetch'
import camelCase from 'lodash/camelCase'
import { APIResponse } from '@lib/types'
import {
  isDevProduction,
  isDevStaging,
  isProduction,
  isStaging,
  BUILD_GIT_SHA,
} from '@lib/env'
import { transformDataKeys } from './transform-data-keys'
import { CURRENT_API_VERSION } from '@lib/duffel-api/constants'

export const getEndpoint = () => {
  if (isDevStaging) return 'https://api.staging.duffel.com'
  if (isDevProduction) return 'https://api.duffel.com'
  if (isStaging) return 'https://platform-v1.staging.svc.cluster.local'
  if (isProduction) return 'https://platform-v1.production.svc.cluster.local'

  return 'https://localhost:4000'
}

const getPlatformHeaders = (response: Response): PlatformHeaders => {
  const headerKeys = [
    'x-request-id',
    'ratelimit-reset',
    'ratelimit-limit',
    'ratelimit-remaining',
    'date',
    'content-type',
    'content-disposition',
    'cache-control',
    'set-cookie',
    'x-csrf-token',
  ]
  const platformHeaders = headerKeys.reduce(
    (headers, key) => ({ [key]: response.headers.get(key), ...headers }),
    {}
  )

  return platformHeaders as PlatformHeaders
}

export interface PlatformHeaders {
  'content-type': string
  'content-disposition': string
  'x-request-id': string
  'ratelimit-reset': string
  'ratelimit-limit': string
  'ratelimit-remaining': string
  'cache-control': string
  date: string
  'set-cookie'?: string | string[]
  'x-csrf-token'?: string
}

export const getRequestHeaders = (
  requestHeaders: IncomingHttpHeaders,
  token: string
): { [key: string]: string } => {
  const headers = {
    accept: 'application/json',
    'accept-encoding': 'gzip',
    'content-type': 'application/json',
    'duffel-version': CURRENT_API_VERSION,
    'user-agent': 'Duffel/v1 duffel_dashboard/' + BUILD_GIT_SHA,
  }

  const forwardableHeaders = [
    'accept',
    'duffel-web-proxy-forwarded-for',
    'duffel-version',
  ]
  if (requestHeaders) {
    for (const fh of forwardableHeaders) {
      if (requestHeaders[fh]) {
        headers[fh] = requestHeaders[fh]
      }
    }
  }

  if (token) {
    headers['authorization'] = `Bearer ${token}`
  }

  // For Next 12, the request originated from the server side will have the header
  // `text/event-stream`. This would end up getting passed along to the backend and cause
  // all the request to fail. So we will explicitly say that the request headers will be `application/json` in such case.
  if (headers['accept'] === 'text/event-stream') {
    headers['accept'] = 'application/json'
  }

  return headers
}

export const client = async <T_Response = any>(
  method: string,
  url: string,
  token: string,
  requestHeaders: IncomingHttpHeaders,
  body?: any,
  rawResponse?: boolean
): Promise<{
  status: number
  body: APIResponse<T_Response>
  headers: PlatformHeaders
}> => {
  const fullUrl = `${getEndpoint()}${url}`

  const requestOptions: RequestInit = {
    method,
    headers: getRequestHeaders(requestHeaders, token),
  }
  if (body) requestOptions.body = body

  const response = await fetch(fullUrl, requestOptions)
  if (rawResponse) return response as any

  const responseHeaders = getPlatformHeaders(response)

  if (response.status === 204 || !response.headers.get('content-type')) {
    return { body: {}, status: response.status, headers: responseHeaders }
  }

  const contentType = response.headers.get('content-type')

  if (contentType && contentType.includes('json')) {
    const responseBody = await response.json()
    const isOrderShowOrCreateRequest = fullUrl.includes('air/orders')
    const parsedBody = body && typeof body === 'string' && JSON.parse(body)
    // If the request is for NGS data, we don't want to transform the keys, as it gets passed straight to the component
    const transformedBody =
      fullUrl.includes('offer_requests') && parsedBody?.data?.retrieve_ngs_data
        ? responseBody
        : transformDataKeys(responseBody, camelCase, isOrderShowOrCreateRequest)

    return {
      body: transformedBody,
      status: response.status,
      headers: responseHeaders,
    }
  } else {
    const responseBody = (await response.text()) as any
    // responseBody should be APIResponse<T> not any or string
    return {
      body: responseBody,
      status: response.status,
      headers: responseHeaders,
    }
  }
}
