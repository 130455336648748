import { createContext, useContext } from 'react'
import { CreateOrderChangeRequestFormValues } from './get-create-order-change-request-form-initial-values'

export const BEFORE_OUTBOUND_DEPARTURE_DATE_WARNING =
  'This departure date is before the flight above.'
export const SAME_DEPARTURE_DATE_WARNING =
  'This departure date is the same as one of the other flights.'
export const AFTER_INBOUND_DEPARTURE_DATE_WARNING =
  'This departure date is after the flight below.'

export interface SliceOrderValidationContextRecord {
  warning: string
  hasDepartureDateWarning: boolean
}

export type SliceOrderValidationContextValue = Record<
  string,
  SliceOrderValidationContextRecord
>
export const SliceOrderValidationContext =
  createContext<SliceOrderValidationContextValue>({})
export const useSliceOrderValidation = () =>
  useContext(SliceOrderValidationContext)

export const getSliceOrderValidationContextValue = (
  fromFormValues: CreateOrderChangeRequestFormValues
): SliceOrderValidationContextValue =>
  fromFormValues.slices.reduce((collection, slice, index) => {
    const record: SliceOrderValidationContextRecord = {
      hasDepartureDateWarning: false,
      warning: '',
    }

    if (!slice.toBeChanged) {
      return Object.assign({}, collection, {
        [slice.sliceId]: record,
      })
    }
    const breaksOrderBefore = fromFormValues.slices
      .slice(0, index)
      .some(({ departureDate }) => +departureDate > +slice.departureDate)

    const breaksOrderAfter = fromFormValues.slices
      .slice(index + 1)
      .some(({ departureDate }) => +departureDate < +slice.departureDate)

    const departureEqualsArrivalDate = fromFormValues.slices.some(
      ({ sliceId, departureDate }) =>
        +departureDate == +slice.departureDate && sliceId !== slice.sliceId
    )

    if (breaksOrderBefore) {
      record.hasDepartureDateWarning = true
      record.warning = BEFORE_OUTBOUND_DEPARTURE_DATE_WARNING
    } else if (breaksOrderAfter) {
      record.hasDepartureDateWarning = true
      record.warning = AFTER_INBOUND_DEPARTURE_DATE_WARNING
    } else if (departureEqualsArrivalDate) {
      record.hasDepartureDateWarning = true
      record.warning = SAME_DEPARTURE_DATE_WARNING
    }

    return Object.assign({}, collection, {
      [slice.sliceId]: record,
    })
  }, {} as SliceOrderValidationContextValue)
