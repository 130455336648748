import { cloneDeep } from 'lodash'
import { captureException } from '@lib/sentry'
import { DuffelAPI } from '@lib/types'

const passengerIdErrorMessage =
  'Found segment information with a passenger ID which is not present in the passengers array on the order.'

export const combineBaggages = (order: DuffelAPI.Types.Order) => {
  const segmentMap: { [segmentId: string]: DuffelAPI.Types.OrderSliceSegment } =
    {}
  const combinedBaggages: {
    [passengerId: string]: {
      [segmentId: string]: DuffelAPI.Types.OrderSegmentPassengerBaggage[]
    }
  } = {}
  const passengerIds = order.passengers.map((passenger) => passenger.id)
  passengerIds.forEach((passengerId) => (combinedBaggages[passengerId] = {}))

  try {
    order.slices.forEach((slice) => {
      slice.segments.forEach((segment) => {
        if (segment.id) {
          segmentMap[segment.id] = segment
          segment.passengers.forEach((passenger) => {
            if (
              passenger.passengerId &&
              !passengerIds.includes(passenger.passengerId)
            ) {
              captureException(Error(passengerIdErrorMessage), {
                passengerId: passenger.passengerId,
                orderId: order.id,
                segmentId: segment.id,
                sliceId: slice.id,
              })
            }
            if (
              passenger.baggages.length > 0 &&
              passenger.passengerId &&
              segment.id
            ) {
              combinedBaggages[passenger.passengerId] = {
                ...combinedBaggages[passenger.passengerId],
                [segment.id]: passenger.baggages,
              }
            }
          })
        }
      })
    })

    order.services?.forEach((service) => {
      service.segmentIds.forEach((segmentId) => {
        service.passengerIds.forEach((passengerId) => {
          if (service.type === 'baggage') {
            if (passengerId && !passengerIds.includes(passengerId)) {
              captureException(Error(passengerIdErrorMessage), {
                passengerId: passengerId,
                orderId: order.id,
                segmentId: segmentId,
              })
            }

            const baggagesForSegmentPassenger = combinedBaggages[passengerId][
              segmentId
            ]
              ? cloneDeep(combinedBaggages[passengerId][segmentId])
              : []
            const serviceBaggageType = (
              service.metadata as DuffelAPI.Types.OrderServiceBaggageMetadata
            ).type
            const serviceBaggageQuantity = service.quantity

            // Check if baggage type already exists in segment passenger baggages
            const baggageTypeIndex = baggagesForSegmentPassenger.findIndex(
              (baggage) => {
                return baggage.type === serviceBaggageType
              }
            )

            // If it does exist, increment the quantity of the baggage type
            if (baggageTypeIndex !== -1) {
              baggagesForSegmentPassenger[baggageTypeIndex].quantity +=
                serviceBaggageQuantity
            } else {
              baggagesForSegmentPassenger.push({
                type: serviceBaggageType,
                quantity: serviceBaggageQuantity,
              })
            }

            // '' + baggageA.type will ensure there's a type to avoid exceptions
            baggagesForSegmentPassenger.sort((baggageA, baggageB) =>
              ('' + baggageA.type).localeCompare(baggageB.type)
            )

            combinedBaggages[passengerId][segmentId] =
              baggagesForSegmentPassenger
          }
        })
      })
    })
  } catch (error: any) {
    captureException(error, { orderId: order.id })
  }

  return {
    combinedBaggages,
    segmentMap,
  }
}
