import classNames from 'classnames'
import * as React from 'react'
import { AirlineLogo } from '@components/AirlineLogo'
import { Icon } from '@components/Icon'
import { DuffelProxy } from '@lib/types'
interface AirlinesSelectOptionProps {
  name: string
  label?: string
  airline?: DuffelProxy.Types.SourceAirline
  selected: boolean
  active: boolean
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onMouseEnter?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
}
export const AirlinesSelectOption: React.FC<AirlinesSelectOptionProps> = ({
  name,
  airline,
  label,
  selected,
  active,
  onClick,
  onMouseEnter,
}) => {
  const cx = classNames('airlines-select-option', { selected, active })

  let labelText = ''
  if (airline) {
    labelText = airline.name
  } else if (label) {
    labelText = label
  }

  return (
    <button
      className={cx}
      onClick={onClick}
      name={name}
      aria-label={labelText}
      onMouseEnter={onMouseEnter}
      data-selector="fs-show"
    >
      {airline && (
        <div className="airlines-selection-option__logo">
          <AirlineLogo {...airline} size={24} />
        </div>
      )}
      <div className="airlines-selection-option__label">{labelText}</div>
      {selected && (
        <div className="airlines-selection-option__icon">
          <Icon name="check" size={18} />
        </div>
      )}
      <style jsx>{`
        .airlines-select-option {
          cursor: pointer;
          display: flex;
          width: 100%;
          background: var(--white);
          padding: 8px;
          border: none;
          outline: none;
          font-size: 14px;
          align-items: center;
          border-radius: var(--border-radius-4);
        }
        .airlines-selection-option__logo {
          flex: 0 0 18px;
        }
        .airlines-selection-option__label {
          flex: 0 1 100%;
          text-align: left;
          margin: 0 24px 0 8px;
          line-height: 24px;
          whitespace: pre;
        }
        .airlines-selection-option__label:first-child {
          margin-left: 0;
        }
        .airlines-selection-option__icon {
          flex: 0 0 18px;
          color: var(--purple-500);
        }
        .airlines-select-option.selected {
          font-weight: bold;
        }
        .airlines-select-option.active.active {
          background: var(--purple-100);
        }
      `}</style>
    </button>
  )
}
