import * as React from 'react'
import { FormikInput } from './FormikInput'
import { FormikSelect } from './FormikSelect'

export interface FormikSupportContactEmailProps {
  name: string
  label: string
  userEmail?: string
  orgContactEmails?: string[]
}

export const FormikSupportContactEmail: React.FC<
  FormikSupportContactEmailProps
> = ({ userEmail, orgContactEmails, ...props }) => {
  if (orgContactEmails && orgContactEmails.length > 0) {
    return (
      <FormikSelect {...props} optionalField>
        {orgContactEmails.map(
          (orgContactEmail) =>
            orgContactEmail !== userEmail && (
              <option value={orgContactEmail} key={orgContactEmail}>
                {orgContactEmail}
              </option>
            )
        )}
        <option value={userEmail}>{userEmail}</option>
      </FormikSelect>
    )
  }

  if (userEmail) {
    return (
      <FormikInput
        {...props}
        disabled
        optionalField
        hint="Additional contact emails can be set up in team settings."
      />
    )
  }

  return <FormikInput {...props}></FormikInput>
}
