declare global {
  interface Navigator {
    msSaveOrOpenBlob: any
  }
}

export type ApplicationMimeType =
  | 'application/javascript'
  | 'application/ecmascript'
  | 'application/json'
  | 'application/pdf'
export type TextMimeType = 'text/plain' | 'text/css' | 'text/html' | 'text/csv'
export type ImageMimeType =
  | 'image/apng'
  | 'image/bmp'
  | 'image/gif'
  | 'image/x-icon'
  | 'image/jpeg'
  | 'image/png'
  | 'image/svg+xml'
  | 'image/tiff'
  | 'image/webp'

export type MimeType = ApplicationMimeType | TextMimeType | ImageMimeType

export const download = (
  data: Blob | string,
  filename: string,
  type: MimeType
) => {
  const file = new Blob([data], { type: type })
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename)
  else {
    // Others
    const link = document.createElement('a'),
      url = URL.createObjectURL(file)
    link.href = url
    link.download = filename

    document.body.appendChild(link)
    link.click()
    setTimeout(function () {
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    }, 0)
  }
}
