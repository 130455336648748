/**
 * Function to return a complete set of days, or a specific number of days in a given month and year
 */
export const getDaysInMonth = (
  month: number,
  year: number,
  numberOfDaysInMonth?: number
) => {
  const daysInMonth = numberOfDaysInMonth
    ? new Array(numberOfDaysInMonth)
        .fill('')
        .map((_, index) => new Date(year, month, index + 1))
        .filter((value) => value.getMonth() === month)
    : new Array(31)
        .fill('')
        .map((_, index) => new Date(year, month, index + 1))
        .filter((value) => value.getMonth() === month)

  const formattedDaysInMonth = daysInMonth.map(
    (day) =>
      new Date(day.getTime() - day.getTimezoneOffset() * 60000)
        .toISOString()
        .split('T')[0]
  )

  return formattedDaysInMonth
}
