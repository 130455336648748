import * as React from 'react'
import classNames from 'classnames'
import styles from './Anchor.module.css'

export type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  /** How is this anchor intended to be used? This will change the color of the text */
  intent?: 'block' | 'inline'

  /**
   * Allows you to override the base element for this component
   */
  asElement?: 'a' | 'button'
}

export const Anchor = React.forwardRef<HTMLAnchorElement, AnchorProps>(
  ({ children, className = '', intent, asElement, ...props }, ref) => {
    const cn = classNames(styles.anchor, className, {
      [styles['anchor--block']]: intent === 'block',
    })
    const Element = asElement || 'a'
    // Casting to any below since we don't know
    // if these will be props for button or anchor
    return (
      <Element {...(props as any)} className={cn} ref={ref as any}>
        {children}
      </Element>
    )
  }
)
