import { DuffelAPI } from '@lib/types'

const getDefaultObject =
  (): DuffelAPI.Inputs.CreateOrderChangeRequestSlices => ({
    add: [],
    remove: [],
  })

export const makeMockCreateOrderChangeRequestPayloadChangesSlices = (
  extendDefault?: Partial<DuffelAPI.Inputs.CreateOrderChangeRequestSlices>
): DuffelAPI.Inputs.CreateOrderChangeRequestSlices =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
