export const convertRelativeDatePartsToString = (
  relativeDate: Intl.RelativeTimeFormatPart[],
  verbose?: boolean
) => {
  const dateInteger = relativeDate.find(
    (dateObject) => dateObject.type === 'integer'
  )

  let dateUnit
  if (dateInteger && dateInteger.type === 'integer') {
    if (Number(dateInteger.value) > 1) {
      dateUnit = `${dateInteger.unit}s`
    } else {
      dateUnit = dateInteger.unit
    }
  }

  if (verbose) {
    return `${dateInteger?.value} ${dateUnit}`
  }

  return {
    value: dateInteger?.value,
    unit: dateUnit,
  }
}
