import { DuffelAPI, DuffelProxy } from '@lib/types'
import { AccountCreationFormValues } from '@modules/identity-onboarding/forms'

// From Paul's mapping here: https://docs.google.com/spreadsheets/d/1nOmMozd_3Iica9sOx3rYr35l8qBhdYce_FLbePs04Vk/edit#gid=0
export const primaryBusinessOptions = {
  Airline: 'Airline',
  'Business banking': 'Business banking services',
  'Consumer banking': 'Consumer banking services',
  Corporate: 'Corporate banking services',
  eCommerce: 'eCommerce',
  'Employee benefits': 'Employee Benefits',
  'Group travel': 'Group Travel',
  'Human Capital Management (HCM)': 'HCM Solution',
  'Hotel business': 'Hotel',
  'Loyalty tech': 'Loyalty tech',
  'TV / Media': 'Media (TV / Radio)',
  'Online Travel Agency (OTA)': 'Online Travel Agency (OTA)',
  Other: 'Other',
  'Payments / FX': 'Payments / FX',
  'Personal Financial Management (PFM)': 'Personal Financial Management (PFM)',
  'Retail Travel Agency': 'Retail Travel Agency',
  Ridesharing: 'Ridesharing',
  'Spend Management': 'Spend Management',
  Technology: 'Technology',
  'Travel Management Company (TMC)': 'TMC',
}

export const departmentFunctions = [
  'Accounting, Administrative',
  'Arts & Design',
  'Business Development',
  'Community and Social Services',
  'Consulting',
  'Customer Success and Support',
  'Education',
  'Engineering',
  'Entrepreneurship',
  'Finance',
  'Healthcare Services',
  'Information Technology',
  'Legal',
  'Marketing',
  'Media and Communication',
  'Military and Protective Services',
  'Operations, Product Management',
  'Other',
  'Program and Project Management',
  'Purchasing',
  'Quality Assurance',
  'Real Estate',
  'Research',
  'Sales',
]
export type DepartmentFunction = (typeof departmentFunctions)[number]

export interface IdentifiableTraits {
  email: string
  name?: string
  createdAt?: string
  unsubscribed_from_emails?: boolean
  organisationIds?: string
  companies?: {
    company_id: string
    created_at: string
    name: string
  }[]
  avatar?: {
    type: 'avatar'
    image_url: string
  }
  // tags are valuable for us to organise and
  // segment our contacts in active campaign
  tags?: string[]

  // Full name is the standard used by Active Campaign.
  'Full Name': string
  // Active campaign fields can't be camel-case.
  'User Types'?: string
  'Company Size'?: string
  'Skipped Questions'?: string
  'Incomplete Submission'?: boolean

  'First Name'?: string
  'Last Name'?: string
  leadId?: string
  language?: string
  'Company name'?: string
  'Company size'?: string
  'Country of operation'?: string
  'Job title'?: string
  'Company booking volume'?: string
  'Additional Information'?: string
  Source?: string
  //  If the value comes from the identity form it could be uppercase since that's the label value
  Email?: string

  primary_business?: (typeof primaryBusinessOptions)[keyof typeof primaryBusinessOptions]
  department_function?: DepartmentFunction
}

export const mapSelfToIdentifiableTraits = (
  user: DuffelProxy.Types.Self,
  withTags?: string[]
): IdentifiableTraits => ({
  email: user.email,
  name: user.fullName,
  'Full Name': user.fullName,
  createdAt: user.createdAt,
  unsubscribed_from_emails: user.sendMarketingEmails,
  organisationIds:
    user.organisationSlugs
      .map((slug) => user.organisationsBySlug[slug].id)
      .join(',') || '',
  companies: user.organisationSlugs.map((slug) => ({
    company_id: user.organisationsBySlug[slug].id,
    created_at: user.organisationsBySlug[slug].createdAt as string,
    name: user.organisationsBySlug[slug].name,
  })),
  avatar: {
    type: 'avatar',
    image_url: user.avatarUrl || '',
  },
  ...(withTags && { tags: withTags }),
})

export const mapUserToIdentifiableTraits = (
  user: DuffelAPI.Types.User,
  withTags?: string[],
  accountCreationFormData?: AccountCreationFormValues
): IdentifiableTraits => ({
  email: user.email,
  name: user.fullName,
  'Full Name': user.fullName,
  createdAt: user.createdAt,
  unsubscribed_from_emails: user.sendMarketingEmails,
  organisationIds:
    user.organisationMemberships?.map((id) => id).join(',') || '',
  companies: (user.organisationMemberships || []).map((membership) => ({
    company_id: membership.organisationId,
    created_at: membership.organisation.createdAt,
    name: membership.organisation.name,
  })),
  avatar: {
    type: 'avatar',
    image_url: user.avatarUrl || '',
  },
  'First Name': accountCreationFormData?.user.firstName,
  'Last Name': accountCreationFormData?.user.lastName,
  primary_business: accountCreationFormData?.user.primaryBusiness,
  department_function: accountCreationFormData?.user.departmentFunction,
  'Company name': accountCreationFormData?.companyName,
  ...(withTags && { tags: withTags }),
})
