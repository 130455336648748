import * as React from 'react'
import {
  CSS_FONTS_VARIABLES,
  CSS_FONT_SIZES_VARIABLES,
  CSS_PAGE_MAX_WIDTH_VARIABLES,
} from '@lib/styles'
import { FontFaceProvider } from './FontFaceProvider'

export const ThemeProvider: React.FC = () => (
  <React.Fragment>
    <FontFaceProvider />
    <style jsx global>{`
      :root {
        ${CSS_FONTS_VARIABLES};
        ${CSS_FONT_SIZES_VARIABLES};
        ${CSS_PAGE_MAX_WIDTH_VARIABLES};
      }

      * {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
      }

      body.no-scroll {
        height: 100%;
        overflow: hidden;
      }

      #nprogress {
        pointer-events: none;
      }

      #nprogress .bar {
        background: var(--purple-500);
        height: 4px;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999999;
      }

      #__portal__ {
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    `}</style>
  </React.Fragment>
)
