import { ServerResponse } from 'http'

export function addHeaderToServerResponse(
  headerName: string,
  headerValues: string[],
  response: ServerResponse
) {
  const existingValues = response.getHeader(headerName)

  if (!existingValues) {
    response.setHeader(headerName, headerValues)
  } else if (Array.isArray(existingValues)) {
    response.setHeader(headerName, [...existingValues, ...headerValues])
  } else if (typeof existingValues === 'string') {
    response.setHeader(headerName, [existingValues, ...headerValues])
  }
}
