import { DuffelAPI } from '@lib/types'
import { getCurrencySymbol } from './get-currency-symbol'
import { formatNumberWithCommas } from '.'

export interface PaidOrdersPerTier {
  items: string
  perItem: string
  subtotal: string
}

export const getPaidOrdersBreakdownByTier = (
  invoice: DuffelAPI.Types.Invoice
) => {
  const paidOrdersUsageRecord = invoice.items.find(
    (item) => item.description === 'Paid order booked via the Duffel platform'
  )

  if (!paidOrdersUsageRecord) {
    return
  }

  const quantity = Number(
    paidOrdersUsageRecord.calculation.metricValues.paidOrderCount
  )
  const tiers = paidOrdersUsageRecord.calculation.params.tiers
  const currency = paidOrdersUsageRecord.currency

  const paidOrdersPerTier: PaidOrdersPerTier[] = []
  let remainingOrders = quantity

  tiers
    ?.filter((tier) => tier.limit !== null)
    ?.map((tier) => {
      const rate = Number(tier.rate)
      const limit = Number(tier.limit)

      if (remainingOrders <= 0) {
        return
      }

      if (remainingOrders >= limit) {
        paidOrdersPerTier.push({
          items: formatNumberWithCommas(limit.toString()),
          perItem: `per ${getCurrencySymbol(currency)}${rate.toFixed(2)}`,
          subtotal: `${getCurrencySymbol(currency)}${formatNumberWithCommas(
            limit * rate,
            true
          )}`,
        })
      } else {
        paidOrdersPerTier.push({
          items: formatNumberWithCommas(remainingOrders.toString()),
          perItem: `per ${getCurrencySymbol(currency)}${rate.toFixed(2)}`,
          subtotal: `${getCurrencySymbol(currency)}${formatNumberWithCommas(
            remainingOrders * rate,
            true
          )}`,
        })
      }
      remainingOrders = remainingOrders - limit
    })

  return paidOrdersPerTier
}
