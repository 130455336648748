import * as React from 'react'
import { VSpace } from '@components/VSpace'
import { Text } from '@components/Text'
import { SelectMenu } from '@components/SelectMenu/SelectMenu'
import { Input } from '@components/Input'
import { RadioGroup, RadioGroupOption } from '@components/RadioGroup'
import { PostSearchFilters } from '@lib/paths'
import { DuffelAPI } from '@lib/types'
import { SearchTimeRanges } from '@modules/air-search-v2/forms/SearchForm/lib/search-time-ranges'
import styles from './SearchResultsFilters.module.css'
import { FlightTimeFilters } from './FlightTimeFilters'

const sortOptions: RadioGroupOption<
  NonNullable<PostSearchFilters['sorting']>
>[] = [
  {
    value: 'total_amount',
    label: 'Least expensive',
  },
  {
    value: '-total_amount',
    label: 'Most Expensive',
  },
  {
    value: 'total_duration',
    label: 'Shortest duration',
  },
  {
    value: '-total_duration',
    label: 'Longest duration',
  },
]

const maxConnectionsOptions: RadioGroupOption<
  NonNullable<PostSearchFilters['maxConnections']>
>[] = [
  {
    value: 0,
    label: 'Direct only',
  },
  {
    value: 1,
    label: '1 stop at most',
  },
  {
    value: 2,
    label: '2 stops at most',
  },
  {
    value: 100,
    label: 'Any number of stops',
  },
]

const SELECT_OPTION_WIDTH = 240

const FilterSection: React.FC<
  React.PropsWithChildren<{ label: string; disabled?: boolean }>
> = ({ label, children, disabled }) => (
  <VSpace
    space={8}
    className={disabled ? styles['section--disabled'] : undefined}
  >
    <Text fontSize="C2" fontWeight="medium">
      <span>{label}</span>
    </Text>
    <div>{children}</div>
  </VSpace>
)

type SearchResultsFiltersProps = {
  disabled?: boolean
  postSearchFilters?: PostSearchFilters
  onPostSearchFiltersUpdate: (postSearchFilters: PostSearchFilters) => void
  airlinesToFilter: DuffelAPI.Types.Airline[]
  preSearchTimeRanges?: SearchTimeRanges
}

export const SearchResultsFilters: React.FC<SearchResultsFiltersProps> = ({
  postSearchFilters,
  onPostSearchFiltersUpdate,
  airlinesToFilter,
  preSearchTimeRanges,
  disabled,
}) => (
  <VSpace space={24} className={styles['side-panel']}>
    <FilterSection label="Sort by" disabled={disabled}>
      <RadioGroup
        value={postSearchFilters?.sorting ?? 'total_amount'}
        options={sortOptions}
        stacked
        radioType="dot"
        onChange={(selectedSort) => {
          onPostSearchFiltersUpdate({
            ...postSearchFilters,
            sorting: selectedSort,
          })
        }}
        disabled={disabled}
      />
    </FilterSection>
    <FilterSection label="Stops" disabled={disabled}>
      <RadioGroup
        options={maxConnectionsOptions}
        value={postSearchFilters?.maxConnections ?? 100}
        onChange={(selectedMaxConnection) => {
          onPostSearchFiltersUpdate({
            ...postSearchFilters,
            maxConnections: selectedMaxConnection,
          })
        }}
        stacked
        radioType="dot"
        disabled={disabled}
      />
    </FilterSection>
    <FilterSection label="Airlines" disabled={disabled}>
      <SelectMenu
        size="small"
        id="post-search-airlines"
        value={
          airlinesToFilter.find(
            (airline) => airline.iataCode === postSearchFilters?.airlineIataCode
          )?.name ?? 'All airlines'
        }
        onChange={(selectedAirlineName) => {
          onPostSearchFiltersUpdate({
            ...postSearchFilters,
            airlineIataCode:
              airlinesToFilter.find(
                (airline) => airline.name === selectedAirlineName
              )?.iataCode ?? undefined,
          })
        }}
        options={[
          'All airlines',
          ...airlinesToFilter.map((airline) => airline.name),
        ]}
        optionsWidth={SELECT_OPTION_WIDTH}
        disabled={disabled}
      />
    </FilterSection>
    <FilterSection label="Flight number" disabled={disabled}>
      <Input
        type="text"
        value={postSearchFilters?.flightNumber ?? ''}
        onChange={(event) =>
          onPostSearchFiltersUpdate({
            ...postSearchFilters,
            flightNumber: event.target.value,
          })
        }
        disabled={disabled}
        containerSize="small"
      />
    </FilterSection>

    <FilterSection label="Flight time" disabled={disabled}>
      <FlightTimeFilters
        // To avoid the confirm button flickering in transition due to
        // stale state, we will always remount this whenever the filter
        // is changed.
        key={JSON.stringify(postSearchFilters?.timeRanges)}
        postSearchTimeRanges={postSearchFilters?.timeRanges}
        preSearchTimeRanges={preSearchTimeRanges}
        onSubmit={(timeRanges) =>
          onPostSearchFiltersUpdate({ ...postSearchFilters, timeRanges })
        }
        disabled={disabled}
      />
    </FilterSection>
  </VSpace>
)
