import { NextApiRequest, NextApiResponse } from 'next'
import { applyHeaders } from './apply-headers'
import { client } from './client'
import { endpoint } from './endpoint'
import { fetchLiveAndTestTokens } from './fetch-live-and-test-tokens'
import { getRequestParams } from './get-request-params'
import { sendCompressed } from './send-compressed'
import { getJWTPayloadFromSelf } from './get-jwt-payload-from-self'

export const getAttemptRequestWithBothSessionTokensEndpoint = () =>
  endpoint(async (req: NextApiRequest, res: NextApiResponse) => {
    const jwt = await getJWTPayloadFromSelf(req, res)
    const {
      method,
      path,
      payload,
      headers: requestHeaders,
    } = await getRequestParams(req)

    const sessionTokens = await fetchLiveAndTestTokens(
      jwt?.organisation,
      jwt?.token,
      requestHeaders
    )

    let response = await client(
      method,
      path,
      sessionTokens.testToken.token,
      requestHeaders,
      payload
    )

    if (![200, 201].includes(response.status) && sessionTokens.liveToken) {
      response = await client(
        method,
        path,
        sessionTokens.liveToken.token,
        requestHeaders,
        payload
      )
    }

    const { body, status, headers } = response
    applyHeaders(res, headers)
    sendCompressed(res, status, body)
  })
