import Link from 'next/link'
import * as React from 'react'
import { Button } from '@components/Button'
import { HSpace } from '@components/HSpace'
import { Tooltip } from '@components/Tooltip'
import { ordersChangePathObject } from '@lib/paths'
import { trackEvent } from '@lib/tracking'
import { DuffelAPI } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import {
  AICDialogProps,
  canOrderBeChanged,
  useAICDialog,
  useOrderCancellation,
} from '@modules/air-order-change'
import { CancelOrderButton } from '@modules/air-order-change/components/CancelOrderButton'
import { useUnavailableActionDialog } from '@modules/air-order/lib/use-unavailable-action-dialog'
import { useDuffelPopper } from '@lib/hooks'
import { Menu, MenuItem } from '@components/Menu'
import { PopoverContainer } from '@components/PopoverContainer'

export interface FlightSummaryWithAICsActionButtonsProps {
  order: DuffelAPI.Types.Order
  isFullCancellation: boolean
  latestAIC: DuffelAPI.Types.AirlineInitiatedChange
  hasInvalidLayovers?: boolean
}

const invalidLayoverText =
  'One or more layovers in schedule change don’t meet the minimum connection time. Please select a different action.'

const AcceptButton: React.FC<{
  hasInvalidLayovers: boolean
  isFullCancellation: boolean
  openActionDialog: (options: AICDialogProps) => void
}> = ({ hasInvalidLayovers, isFullCancellation, openActionDialog }) => (
  <Tooltip
    text={hasInvalidLayovers ? invalidLayoverText : ''}
    placement="bottom"
  >
    <Button
      text={isFullCancellation ? 'Accept cancellation' : 'Accept'}
      onClick={() => {
        trackEvent('dashboard_airline_initiated_change_accept_clicked', {
          event_type: 'interaction',
        })
        openActionDialog({})
      }}
      disabled={hasInvalidLayovers}
    />
  </Tooltip>
)

const markAsAccepted = (
  openActionDialog: (options: AICDialogProps) => void
) => {
  trackEvent('dashboard_airline_initiated_change_mark_as_accepted_clicked', {
    event_type: 'interaction',
  })
  openActionDialog({
    actionTaken: 'accepted',
  })
}

const markAsChanged = (openActionDialog: (options: AICDialogProps) => void) => {
  trackEvent('dashboard_airline_initiated_change_mark_as_changed_clicked', {
    event_type: 'interaction',
  })
  openActionDialog({
    actionTaken: 'changed',
  })
}

const markAsCancelled = (
  openActionDialog: (options: AICDialogProps) => void
) => {
  trackEvent('dashboard_airline_initiated_change_mark_as_cancelled_clicked', {
    event_type: 'interaction',
  })
  openActionDialog({
    actionTaken: 'cancelled',
  })
}

export const FlightSummaryWithAICsActionButtons: React.FC<
  FlightSummaryWithAICsActionButtonsProps
> = ({ order, isFullCancellation, latestAIC, hasInvalidLayovers }) => {
  const { permissions } = useWorkspace()

  const { openActionDialog } = useAICDialog(order, latestAIC.id)
  const { openSupportDialog } = useUnavailableActionDialog()
  const { openCancellationDialog } = useOrderCancellation(order)

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
  const onClosePopover = () => setIsPopoverOpen(false)
  const { popper, setReferenceElement, setPopperElement } = useDuffelPopper(
    isPopoverOpen,
    onClosePopover,
    {
      placement: 'bottom-end',
      modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
    },
    {
      shouldInsideClickClose: true,
    }
  )
  const { styles: popperStyles, attributes } = popper

  // When API resolution isn't available, we just allow the user to "mark" the change as accepted, changed or cancelled.
  // This is the same for managed and self-managed orders.
  if (latestAIC.availableActions?.includes('update')) {
    return (
      <HSpace space={8} justify="end" data-selector="fs-show">
        <Button
          outlined
          intent="MUTED"
          text="Mark as cancelled"
          onClick={() => markAsCancelled(openActionDialog)}
        />
        <Button
          outlined
          intent="MUTED"
          text="Mark as changed"
          onClick={() => markAsChanged(openActionDialog)}
        />
        <Button
          text="Mark as accepted"
          onClick={() => markAsAccepted(openActionDialog)}
        />
      </HSpace>
    )
  }

  // For self managed orders, we always show the ability to mark an order as accepted, changed or cancelled.
  // When there are API actions available too, we put anything other than AcceptButton in a dropdown menu.
  if (order.content === 'self_managed') {
    return (
      <HSpace space={8} justify="end" data-selector="fs-show">
        <Button
          intent="MUTED"
          outlined
          iconAfter="arrow_drop_down"
          ref={setReferenceElement}
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          text="More options"
        />
        {isPopoverOpen && (
          <PopoverContainer
            ref={setPopperElement}
            style={{ ...popperStyles.popper }}
            {...attributes.popper}
          >
            <Menu>
              {!isFullCancellation &&
                latestAIC.availableActions?.includes('cancel') && (
                  <MenuItem
                    label="Request cancellation"
                    onClick={() => {
                      trackEvent(
                        'dashboard_airline_initiated_change_request_cancellation_clicked',
                        {
                          event_type: 'interaction',
                        }
                      )
                      openCancellationDialog()
                    }}
                  ></MenuItem>
                )}
              {canOrderBeChanged(order) && (
                <Link
                  {...ordersChangePathObject(
                    permissions?.organisation,
                    permissions?.liveMode,
                    order.id
                  )}
                  legacyBehavior
                  passHref
                >
                  <MenuItem
                    label="Request change"
                    onClick={() => {
                      trackEvent(
                        'dashboard_airline_initiated_change_request_change_clicked',
                        {
                          event_type: 'interaction',
                        }
                      )
                    }}
                  ></MenuItem>
                </Link>
              )}
              <MenuItem
                label="Mark as accepted"
                onClick={() => markAsAccepted(openActionDialog)}
              />
              <MenuItem
                label="Mark as changed"
                onClick={() => markAsChanged(openActionDialog)}
              />
              <MenuItem
                label="Mark as cancelled"
                onClick={() => markAsCancelled(openActionDialog)}
              />
            </Menu>
          </PopoverContainer>
        )}
        <AcceptButton
          isFullCancellation={isFullCancellation}
          hasInvalidLayovers={hasInvalidLayovers || false}
          openActionDialog={openActionDialog}
        />
      </HSpace>
    )
  }

  // For managed orders, we show action buttons for available actions and the option to request support otherwise.
  return (
    <HSpace space={8} justify="end" data-selector="fs-show">
      {!isFullCancellation &&
        (latestAIC.availableActions?.includes('cancel') ? (
          <CancelOrderButton order={order} />
        ) : (
          <Button
            outlined
            intent="MUTED"
            text="Request cancellation"
            onClick={() => {
              trackEvent(
                'dashboard_airline_initiated_change_request_cancellation_clicked',
                {
                  event_type: 'interaction',
                }
              )
              openSupportDialog(order, 'cancel_order')
            }}
          />
        ))}
      {canOrderBeChanged(order) ? (
        <Link
          {...ordersChangePathObject(
            permissions?.organisation,
            permissions?.liveMode,
            order.id
          )}
          legacyBehavior
        >
          <Button
            outlined
            intent="MUTED"
            text="Request change"
            onClick={() => {
              trackEvent(
                'dashboard_airline_initiated_change_request_change_clicked',
                {
                  event_type: 'interaction',
                }
              )
            }}
          ></Button>
        </Link>
      ) : (
        <Button
          outlined
          intent="MUTED"
          text="Request change"
          onClick={() => {
            trackEvent(
              'dashboard_airline_initiated_change_request_change_clicked',
              {
                event_type: 'interaction',
              }
            )
            openSupportDialog(order, 'change_order')
          }}
        />
      )}
      <AcceptButton
        isFullCancellation={isFullCancellation}
        hasInvalidLayovers={hasInvalidLayovers || false}
        openActionDialog={openActionDialog}
      />
    </HSpace>
  )
}
