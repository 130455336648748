import { compact, capitalize, startCase } from 'lodash'
import * as React from 'react'
import { HSpace } from '@components/HSpace'
import { Icon } from '@components/Icon'
import { Text } from '@components/Text'
import { Tooltip } from '@components/Tooltip'
import { Button } from '@components/Button'
import { VSpace } from '@components/VSpace'
import { getDateString } from '@lib/date'
import { trackEvent } from '@lib/tracking'
import { SearchType } from '@lib/types'
import {
  SearchTimeRanges,
  isDefaultSearchTimeRanges,
  defaultSearchTimeRanges,
  DEFAULT_SEARCH_TIME_FROM,
  DEFAULT_SEARCH_TIME_TO,
  isDefaultSearchTimeRange,
} from '../forms/SearchForm/lib/search-time-ranges'
import { SearchFormValues } from '../lib/transform-search-form-values'

import styles from './SearchDetails.module.css'

const getDepartureDates = (
  searchFormValues: SearchFormValues<SearchType>
): Array<Date> => {
  switch (searchFormValues.type) {
    case 'one_way':
      return compact([searchFormValues.departureDate])
    case 'return':
      return compact([
        searchFormValues.departureDate,
        searchFormValues.returnDate,
      ])
    case 'multi_city': {
      const { slices } = searchFormValues
      return compact([
        slices[0].departureDate,
        slices[slices.length - 1].departureDate,
      ])
    }
  }
}

const describeTimeRange = (timeRange: { from: string; to: string }) => {
  if (isDefaultSearchTimeRange(timeRange)) {
    return 'any time'
  }

  if (timeRange.from === DEFAULT_SEARCH_TIME_FROM) {
    return `after ${timeRange.to}`
  }

  if (timeRange.to === DEFAULT_SEARCH_TIME_TO) {
    return `before ${timeRange.from}`
  }

  return `${timeRange.to} - ${timeRange.from}`
}

const describeSearchTimeRanges = (
  timeRanges: SearchTimeRanges,
  origin: string,
  destination: string
) => {
  const { departureTime, arrivalTime } = timeRanges
  return [
    `Departing from ${origin} ${describeTimeRange(departureTime)}`,
    `Arriving to ${destination} ${describeTimeRange(arrivalTime)}`,
  ].join('\n')
}
/*
  Example:
  Departing from LAX before 10:00
  Arriving to JFK 18:00-22:00
  Departing from JFK any time
  Arriving to LAX after 12:00
  */
const getSearchTimeRangesLabel = (
  searchFormValues: SearchFormValues<SearchType>
): string => {
  switch (searchFormValues.type) {
    case 'one_way': {
      const { origin, destination, timeRanges } = searchFormValues
      if (!timeRanges || isDefaultSearchTimeRanges(timeRanges)) {
        return ''
      }
      return describeSearchTimeRanges(timeRanges, origin, destination)
    }
    case 'return': {
      const { origin, destination, departureTimeRanges, returnTimeRanges } =
        searchFormValues
      if (!departureTimeRanges && !returnTimeRanges) {
        return ''
      }

      if (
        [departureTimeRanges, returnTimeRanges].every(isDefaultSearchTimeRanges)
      ) {
        return ''
      }

      return [
        describeSearchTimeRanges(
          departureTimeRanges ?? defaultSearchTimeRanges,
          origin,
          destination
        ),
        describeSearchTimeRanges(
          returnTimeRanges ?? defaultSearchTimeRanges,
          destination,
          origin
        ),
      ].join('\n')
    }
    case 'multi_city': {
      const { slices } = searchFormValues
      if (
        slices.every((slice) => isDefaultSearchTimeRanges(slice.timeRanges))
      ) {
        return ''
      }

      return slices
        .map((slice) =>
          describeSearchTimeRanges(
            slice.timeRanges ?? defaultSearchTimeRanges,
            slice.origin,
            slice.destination
          )
        )
        .join('\n')
    }
  }
}

const getSearchLabel = (formValues: SearchFormValues) => {
  switch (formValues.type) {
    case 'one_way':
      return `One-way trip to ${formValues.destination}`
    case 'return':
      return `Round trip to ${formValues.destination}`
    case 'multi_city':
      return `Multi-city trip to ${
        formValues.slices[formValues.slices.length - 1].destination
      }`
  }
}

export const SearchDetails: React.FC<{
  formValues: SearchFormValues
  onEditButtonClick?: () => void
}> = ({ formValues: formValues, onEditButtonClick }) => {
  const searchTimeRangesLabel = getSearchTimeRangesLabel(formValues)
  return (
    <VSpace space={8} className={styles['container']}>
      <VSpace
        space={4}
        onClick={() =>
          trackEvent('dashboard_multistep_search_results_header_clicked', {
            event_type: 'interaction',
          })
        }
      >
        <Text fontWeight="medium">{getSearchLabel(formValues)}</Text>
        <HSpace space={8}>
          <Text fontSize="C3" fontWeight="medium" asElement="div">
            {(formValues.type === 'one_way' ||
              formValues.type === 'return') && (
              <HSpace space={4} alignCenter>
                <span>{formValues.origin} </span>
                <Icon
                  className={styles['search-type-icon']}
                  name={formValues.type === 'return' ? 'sync' : 'arrow_right'}
                  size={16}
                />
                <span>{formValues.destination}</span>
              </HSpace>
            )}
            {formValues.type === 'multi_city' && (
              <HSpace space={4} alignCenter>
                <span>{formValues.slices[0].origin} </span>
                <Icon
                  className={styles['search-type-icon']}
                  name="more_horizontal"
                  size={16}
                />
                <span>
                  {formValues.slices[formValues.slices.length - 1].destination}
                </span>
              </HSpace>
            )}
          </Text>
          <Text fontSize="C3" color="grey-700">
            •
          </Text>
          <Text fontSize="C3" color="grey-700">
            {capitalize(formValues.type.replace('_', ' '))}
          </Text>
        </HSpace>
        <HSpace space={8}>
          <Text fontSize="C3" color="grey-700">
            {getDepartureDates(formValues)
              .map((date) => getDateString(date, 'mediumNoYear'))
              .join(' - ')}
          </Text>
          <Text fontSize="C3" color="grey-700">
            •
          </Text>
          {formValues.passengers.length > 0 && (
            <Text fontSize="C3" color="grey-700">
              {startCase(
                `${formValues.passengers.length} passenger${
                  formValues.passengers.length > 1 ? 's' : ''
                }`
              )}
            </Text>
          )}
        </HSpace>
        {searchTimeRangesLabel.length > 0 && (
          <Text fontSize="C3" color="grey-700">
            <Tooltip text={searchTimeRangesLabel} placement={'bottom'}>
              At specific times
            </Tooltip>
          </Text>
        )}
        <Text fontSize="C3" color="grey-700">
          {capitalize(formValues.cabinClass.replace('_', ' '))}
        </Text>
        {formValues.airlines.length > 0 && (
          <Text fontSize="C3" color="grey-700">
            {`${formValues.airlines.length} airline${
              formValues.airlines.length > 1 ? 's' : ''
            }`}
          </Text>
        )}
        {formValues.passengers.some((passenger) =>
          passenger.loyaltyProgrammeAccounts.some(
            (account) =>
              account.accountNumber.length > 0 &&
              account.airlineIataCode &&
              account.airlineIataCode.length > 0
          )
        ) && (
          <Text fontSize="C3" color="grey-700">
            Loyalty accounts applied
          </Text>
        )}
      </VSpace>
      {onEditButtonClick && (
        <>
          <Button
            text="Edit search"
            // if the passengers are empty it means we are still loading
            disabled={formValues.passengers.length === 0}
            onClick={onEditButtonClick}
            intent="MUTED"
            outlined
            fill
          />
        </>
      )}
    </VSpace>
  )
}
