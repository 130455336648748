import { ParsedUrlQuery } from 'querystring'
import * as React from 'react'
import { isEqual, omit } from 'lodash'
import { useRouter } from 'next/router'
import { getQueryValue } from '@lib/helpers'
import { useWorkspace } from '@lib/workspace-context'
import { staysSearchIndexPathArray } from '@lib/paths'
import { captureException } from '@lib/sentry'
import { SearchParams, Guest } from './types'

class MissingStaysQueryParamsError extends Error {
  constructor(missingKey: string) {
    super(`Missing key ${missingKey} from the query params on Stays page`)
  }
}

class InvalidParamsError extends Error {
  constructor(key: string, value: string | number) {
    super(`Invalid param ${key}=${value} on Stays page`)
  }
}

const maybeGet = (query: ParsedUrlQuery, key: string): string | null => {
  if (!query[key]) {
    return null
  }

  return getQueryValue(query[key])
}

const get = (query: ParsedUrlQuery, key: string): string => {
  if (!query[key]) {
    throw new MissingStaysQueryParamsError(key)
  }

  return getQueryValue(query[key])
}

const getDateString = (query: ParsedUrlQuery, key: string): string => {
  const value = get(query, key)
  if (isNaN(new Date(value).getTime())) {
    throw new InvalidParamsError(key, value)
  }

  return value
}

const searchParamsFromQuery = (query: ParsedUrlQuery): SearchParams => {
  const adults = +get(query, 'adults')
  if (adults < 1) {
    throw new InvalidParamsError('adults', adults)
  }

  const adultGuests = Array.from({ length: adults }).map<Guest>(
    (_value, _index) => ({
      type: 'adult',
      age: undefined,
    })
  )

  const childAges = maybeGet(query, 'children')

  const childGuests = childAges
    ? childAges.split(',').map<Guest>((value) => ({
        type: 'child',
        age: parseInt(value),
      }))
    : []

  const guests = [...adultGuests, ...childGuests]

  const rooms = +get(query, 'rooms')
  if (rooms < 1) {
    throw new InvalidParamsError('rooms', rooms)
  }

  if (maybeGet(query, 'ids')) {
    return {
      checkInDate: getDateString(query, 'checkInDate'),
      checkOutDate: getDateString(query, 'checkOutDate'),
      guests: guests,
      rooms,
      accommodation: {
        ids: get(query, 'ids').split(','),
      },
      timestamp: +get(query, 'timestamp'),
    }
  }

  const latitude = +get(query, 'lat')
  const longitude = +get(query, 'long')

  if (
    Number.isNaN(latitude) ||
    Number.isNaN(longitude) ||
    latitude < -90 ||
    latitude > 90 ||
    longitude < -180 ||
    longitude > 180
  ) {
    throw new InvalidParamsError('lat,lng', `${latitude},${longitude}`)
  }

  return {
    checkInDate: getDateString(query, 'checkInDate'),
    checkOutDate: getDateString(query, 'checkOutDate'),
    guests,
    rooms,
    location: {
      name: get(query, 'loc'),
      radius: 5,
      geographicCoordinates: {
        latitude,
        longitude,
      },
    },
    timestamp: +get(query, 'timestamp'),
  }
}

export const useStaysSearchParamsFromQuery = () => {
  const { permissions } = useWorkspace()
  const router = useRouter()
  const [searchParams, setSearchParams] = React.useState<SearchParams>()
  const [redirecting, setRedirecting] = React.useState(false)

  React.useEffect(() => {
    try {
      const parsed = searchParamsFromQuery(router.query)
      // keep the old params if they are equal so that it can keep the same
      // reference
      if (searchParams && isEqual(searchParams, parsed)) {
        return
      }

      setSearchParams(parsed)
    } catch (err) {
      if (redirecting) {
        return
      }

      setRedirecting(true)
      captureException(
        err instanceof Error ? err : new Error(JSON.stringify(err))
      )

      // redirect to the main search page
      // need to add a timeout to avoid the race condition when this redirect
      // happens alongside redirects (e.g. from signing in)
      setTimeout(() => {
        router.push(
          ...staysSearchIndexPathArray(
            permissions?.organisation,
            permissions?.liveMode
          )
        )
      }, 200)
    }
  }, [router.query])

  return searchParams
}

type QueryType = {
  checkInDate?: string
  checkOutDate?: string
  adults?: number
  children?: string
  rooms?: number
  lat?: number
  long?: number
  loc?: string
  ids?: string
}

export const searchParamsToQuery = (params: SearchParams): QueryType => ({
  ...omit(params, ['location', 'accommodation', 'guests']),
  ...('location' in params &&
    params.location && {
      lat: params.location.geographicCoordinates.latitude,
      long: params.location.geographicCoordinates.longitude,
      loc: params.location.name,
    }),
  ...('accommodation' in params &&
    params.accommodation && {
      ids: Array.isArray(params.accommodation.ids)
        ? params.accommodation.ids.join(',')
        : params.accommodation.ids,
    }),
  ...('guests' in params && guestsToGuestParams(params.guests)),
})

const guestsToGuestParams = (
  guests: Guest[]
): { adults: number; children: string } => ({
  adults: guests.filter((guest) => guest.type === 'adult').length,
  children: guests
    .filter((guest) => guest.type === 'child')
    .map((child) => child.age)
    .toString(),
})
