export const SUBMISSION_FAILURE = 'SUBMISSION_FAILURE'
export const SUBMISSION_SUCCESS = 'SUBMISSION_SUCCESS'
export const UNKNOWN_ERROR_MESSAGE =
  'Sorry, we ran into an unexpected error. Please try again shortly.'
export const NETWORK_ERROR_MESSAGE =
  'Due to a network timeout, we could not confirm that your payment has been completed. Please check for confirmation in the Orders page and for more assistance, please contact support.'

export const ORDERS_REQUEST_LIMIT = 100

export const STATIC_OR_THIRD_PARTY_PATHS = [
  '/_next',
  '/feature-flag-proxy',
  '/proxy',
  '/favicon.ico',
]

export const NOT_FOUND_PATH = '/404'

// https://metabase.x15.xyz/question/756-duffel-official-organisations
export const DUFFEL_OFFICIAL_ORGS_IDS = [
  'org_00009rRTUGZJ9WORQRvZw0',
  'org_00009u4MxaVXtDU9rwl1Pc',
  'org_00009UhFcoDDi9M1SF8bKa',
  'org_00009zDqIlnSVd9AdYkFvc',
  'org_00009ziCBt52oKH9h3OJBw',
  'org_0000A23zcoy8H6ofa8fT6W',
  'org_0000A64QN2esqHqpTAKKEi',
  'org_0000ABqsXK8QeSjSjKBayW',
  'org_0000ACNpemTwXOzBjIKgHQ',
  'org_0000AGyD8oPjiJfprqw3SS',
]

// https://metabase.x15.xyz/question#eyJkYXRhc2V0X3F1ZXJ5Ijp7InR5cGUiOiJxdWVyeSIsInF1ZXJ5Ijp7InNvdXJjZS10YWJsZSI6MzA1LCJmaWx0ZXIiOlsiYW5kIixbIj0iLFsiZmllbGQiLDk4ODMxLG51bGxdLHRydWVdXX0sImRhdGFiYXNlIjoxMH0sImRpc3BsYXkiOiJ0YWJsZSIsInZpc3VhbGl6YXRpb25fc2V0dGluZ3MiOnt9fQ==
export const DUFFEL_STAGING_OFFICIAL_ORGS_IDS = ['org_00009UgX9goFl8ES7C2EOe']

export * from './countries-list'
export * from './accepted-currencies'
