import * as React from 'react'
import DayPicker, { DayPickerProps } from 'react-day-picker'
import { PopoverContainer } from '@components/PopoverContainer'

export interface DatePickerDefaultPopoverProps extends DayPickerProps {
  isOpen: boolean
  popperStyles: React.CSSProperties
  popperAttributes?: { [key: string]: string }
}

export const DatePickerDefaultPopover = React.forwardRef<
  HTMLDivElement,
  DatePickerDefaultPopoverProps
>((props, ref) => {
  // TODO: remove isOpen prop
  const { isOpen, popperStyles, popperAttributes, ...dayPickerProps } = props
  if (!isOpen) return null
  return (
    <PopoverContainer
      id="popover-container"
      ref={ref}
      style={popperStyles}
      {...popperAttributes}
      data-selector="fs-show"
    >
      <DayPicker {...dayPickerProps} />

      <style jsx global>{`
        /* DayPicker styles */

        .DayPicker {
          display: inline-block;
          font-size: 14px;
          width: 100%;
        }

        .DayPicker-wrapper {
          outline: none;
          position: relative;
          width: 100%;
          flex-direction: row;
          user-select: none;
          border-radius: var(--border-radius-4);
        }

        .DayPicker-wrapper:focus {
          box-shadow: inset 0 0 0 2px var(--purple-500);
        }

        .DayPicker-Months {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }

        .DayPicker-Month {
          display: table;
          margin: 8px;
          border-spacing: 0;
          border-collapse: collapse;

          user-select: none;
        }

        .DayPicker-NavButton {
          position: absolute;
          top: 8px;
          right: 8px;
          left: auto;

          outline: none;
          display: inline-block;
          margin-top: 2px;
          width: 1.25em;
          height: 1.25em;
          background-position: center;
          background-size: 50%;
          background-repeat: no-repeat;
          color: var(--grey-500);
          cursor: pointer;
        }

        .DayPicker-NavButton:hover,
        .DayPicker-NavButton:focus {
          filter: brightness(30%);
        }

        .DayPicker-NavButton--prev {
          margin-right: 1.5em;
          background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
        }

        .DayPicker-NavButton--next {
          background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
        }

        .DayPicker-NavButton--interactionDisabled {
          display: none;
        }

        .DayPicker-Caption {
          display: table-caption;
          margin-bottom: 16px;
          padding: 0 8px;
          text-align: left;
        }

        .DayPicker-Caption > div {
          font-weight: bold;

          font-size: 16px;
        }

        .DayPicker-Weekdays {
          display: table-header-group;
          margin-top: 1em;
        }

        .DayPicker-WeekdaysRow {
          display: table-row;
        }

        .DayPicker-Weekday {
          display: table-cell;
          height: 32px;
          width: 32px;
          color: var(--grey-700);
          text-align: center;
        }

        .DayPicker-Weekday abbr[title] {
          border-bottom: none;
          text-decoration: none;
        }

        .DayPicker-Body {
          display: table-row-group;
        }

        .DayPicker-Week {
          display: table-row;
        }

        .DayPicker-Day {
          display: table-cell;
          height: 32px;
          width: 32px;
          vertical-align: middle;
          text-align: center;
          cursor: pointer;
          outline: none;
          border-radius: var(--border-radius-4);
        }

        .DayPicker-Day:focus {
          box-shadow: inset 0 0 0 2px var(--purple-500);
        }

        .DayPicker-WeekNumber {
          display: table-cell;
          padding: 0.5em;
          min-width: 1em;
          border-right: 1px solid var(--grey-100);
          color: var(--grey-500);
          vertical-align: middle;
          text-align: right;
          cursor: pointer;
        }

        .DayPicker--interactionDisabled .DayPicker-Day {
          cursor: pointer;
          outline: none;
        }
        .DayPicker-Day--disabled:focus {
          background-color: var(--white);
          text-decoration: line-through;
          box-shadow: inset 0 0 0 2px var(--purple-100);
        }

        .DayPicker--interactionDisabled
          .DayPicker-Day:not(.DayPicker-Day--outside):hover {
          box-shadow: inset 0 0 0 2px var(--purple-100);
        }

        .DayPicker-Footer {
          padding-top: 0.5em;
        }

        .DayPicker-TodayButton {
          border: none;
          background-color: transparent;
          background-image: none;
          box-shadow: none;
          color: var(--purple-500);
          cursor: pointer;
        }

        /* Default modifiers */

        .DayPicker-Day--today {
          color: var(--purple-500);
        }

        .DayPicker-Day--outside {
          color: var(--grey-500);
          cursor: default;
        }

        .DayPicker-Day--disabled {
          color: var(--grey-500);
          cursor: default;
          /* background-color: #eff1f1; */
        }

        /* Example modifiers */

        .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
            .DayPicker-Day--outside
          ) {
          position: relative;
          background-color: var(--purple-200);
          color: var(--purple-700);
        }

        .DayPicker:not(.DayPicker--interactionDisabled)
          .DayPicker-Day:not(.DayPicker-Day--disabled):not(
            .DayPicker-Day--selected
          ):not(.DayPicker-Day--outside):hover {
          background-color: var(--purple-200);
        }

        /* DayPickerInput */

        .DayPickerInput {
          display: inline-block;
        }

        .DayPickerInput-OverlayWrapper {
          position: relative;
        }

        .DayPickerInput-Overlay {
          position: absolute;
          left: 0;
          z-index: 1;

          background: white;
          box-shadow: var(--shadow-depth-2);
        }

        /* Range Day Picker */

        .range-picker
          .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
            .DayPicker-Day--end
          ):not(.DayPicker-Day--outside) {
          background-color: var(--purple-100) !important;
          color: var(--purple-700);
        }

        .range-picker
          .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
            .DayPicker-Day--end
          ):not(.DayPicker-Day--outside):focus {
          background-color: var(--purple-200) !important;
          color: black;
        }

        .range-picker .DayPicker-Day {
          border-radius: 0 !important;
        }
        .range-picker .DayPicker-Day:hover {
          opacity: 0.7;
        }

        .range-picker .DayPicker-Day--start:not(.DayPicker-Day--outside) {
          background-color: var(--purple-200) !important;
          border-bottom-left-radius: 4px !important;
          border-top-left-radius: 4px !important;
        }
        .range-picker .DayPicker-Day--end:not(.DayPicker-Day--outside) {
          background-color: var(--purple-200) !important;
          border-top-right-radius: 4px !important;
          border-bottom-right-radius: 4px !important;
        }
      `}</style>
    </PopoverContainer>
  )
})
