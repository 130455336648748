import { Button } from '@components/Button'
import { Callout } from '@components/Callout'
import { Modal, ModalProps } from '@components/Modal'
import { VSpace } from '@components/VSpace'
import { DuffelCardForm, useDuffelCardFormActions } from '@duffel/components'
import { DuffelCardFormProps } from '@duffel/components/components/DuffelCardForm/lib/types'
import { Text } from '@components/legacy-design-system/product/components/Text'
import { getOriginToDuffelAPI } from '@lib/duffel-api/getOriginToDuffelAPI'
import { captureException } from '@lib/sentry'
import { APIResponse } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import { useFeatureFlags } from '@lib/unleash'
import * as React from 'react'

export interface DuffelCardFormModalProps extends ModalProps {
  buttonText: string
  clientKey: string
  /**
   * You might want to skip 3DSecure session creation if the resource you want to pay for does not support 3DS.
   * e.g. Right now, only flights resources are supported: offers, orders and order changes.
   */
  skipThreeDSecureSessionCreation?: boolean
  onSubmit: (
    paymentData: {
      cardId: string
      /**
       * When `skipThreeDSecureSessionCreation` is `true`, this will be `undefined`.
       */
      threeDSecureSessionId?: string
    },
    setIsLoading: (toggle: boolean) => void
  ) => void

  title?: string
  info?: string

  resourceId: string
  services?: Array<{ id: string; quantity: number }>
}

export const DuffelCardFormModal: React.FC<DuffelCardFormModalProps> = ({
  clientKey,
  onSubmit,
  title,
  info,
  buttonText,
  resourceId,
  services = [],
  skipThreeDSecureSessionCreation,
  ...modalProps
}) => {
  const [isButtonLoading, setIsButtonLoading] = React.useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true)

  const { ref, createCardForTemporaryUse } = useDuffelCardFormActions()

  const { addToast, permissions } = useWorkspace()

  // https://flags.x15.xyz/projects/default/features/dashboard_should_set_cardholder_present
  const isCardholderPresent = useFeatureFlags(
    'dashboard_should_set_cardholder_present'
  )

  return (
    <Modal width={600} {...modalProps}>
      <VSpace space={16}>
        <Text typeStyle="heading2" asElement="h2">
          {title ?? 'Pay with card'}
        </Text>
        {info && (
          <Callout intent="secondary" iconName="info">
            {info}
          </Callout>
        )}
        <DuffelCardForm
          ref={ref}
          tokenProxyEnvironment={
            process.env
              .NEXT_PUBLIC_APP_ENV as DuffelCardFormProps['tokenProxyEnvironment']
          }
          intent="to-create-card-for-temporary-use"
          clientKey={clientKey}
          // validation handlers
          onValidateSuccess={() => {
            setIsButtonDisabled(false)
          }}
          onValidateFailure={() => setIsButtonDisabled(true)}
          // on store for temporary use handlers
          onCreateCardForTemporaryUseSuccess={async ({ id: cardId }) => {
            if (skipThreeDSecureSessionCreation) {
              onSubmit({ cardId }, setIsButtonLoading)
              return
            }

            try {
              const threeDSecureSession =
                await window.createThreeDSecureSession(
                  clientKey,
                  cardId,
                  resourceId,
                  services,
                  isCardholderPresent,
                  {
                    duffelUrl: getOriginToDuffelAPI(),
                    // Staging and Production test mode should both use the Staging appID
                    ...((process.env.NEXT_PUBLIC_APP_ENV === 'staging' ||
                      !permissions?.liveMode) && {
                      evervaultCredentials: {
                        teamID: 'team_a22f3ea22207',
                        appID: 'app_152d304a3d98',
                      },
                    }),
                  }
                )
              onSubmit(
                {
                  cardId,
                  threeDSecureSessionId: threeDSecureSession.id,
                },
                setIsButtonLoading
              )
            } catch (error) {
              console.warn('3DS integration failed with: ', error)
              if ((error as APIResponse<any>).meta?.status === 403) {
                // Fallback to normal payment, as it might not be allowed for this org/airline
                onSubmit({ cardId }, setIsButtonLoading)
              } else {
                setIsButtonLoading(false)
                addToast({
                  title: 'Payment failed',
                  message:
                    'Something went wrong while taking your payment. Try again shortly.',
                  intent: 'error',
                })
              }
            }
          }}
          onCreateCardForTemporaryUseFailure={({ message }) => {
            setIsButtonLoading(false)
            addToast({
              message:
                message ?? 'Something went wrong. Please try again later.',
              intent: 'error',
            })
          }}
          onSecurityPolicyViolation={({ violated_directive }) => {
            captureException(
              new Error(`Security policy violation: ${violated_directive}`)
            )
            addToast({
              message:
                'There is an issue with card payments right now, please contact support.',
              intent: 'error',
            })
          }}
        />
      </VSpace>
      <div>
        <Button
          fill
          medium
          disabled={isButtonDisabled}
          isWaiting={isButtonLoading}
          text={buttonText}
          onClick={() => {
            setIsButtonLoading(true)
            createCardForTemporaryUse()
          }}
        />
      </div>
    </Modal>
  )
}
