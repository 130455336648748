import { useField, useFormikContext } from 'formik'
import * as React from 'react'
import { FormField } from '@components/Form'
import { SourceId } from '@lib/sources'
import { DuffelProxy } from '@lib/types/DuffelProxy'

import { AirlinesSelect, AirlinesSelectProps } from '../AirlinesSelect'

export type FormikAirlinesSelectProps = Omit<AirlinesSelectProps, 'value'> & {
  /**
   * Form field label
   * - - -
   * Default: `Airlines`
   */
  label?: string

  /**
   * Name of the form field to hook into Formik
   * - - -
   * Default: `airlines`
   */
  name?: string
}

export const FormikAirlinesSelect: React.FC<FormikAirlinesSelectProps> = ({
  name = 'airlines',
  label = 'Airlines',
  ...selectProps
}) => {
  const [{ value: selectedAirlines }, { touched, error }] = useField<
    DuffelProxy.Types.SourceAirline['iataCode'] | SourceId[]
  >({
    name,
  })

  const { submitCount, isSubmitting } = useFormikContext()
  const isDisabled = isSubmitting || selectProps.disabled

  return (
    <FormField
      htmlFor="airlines-select"
      label={label}
      optionalField
      error={touched || submitCount > 0 ? error : undefined}
      disabled={isDisabled}
    >
      <AirlinesSelect
        {...selectProps}
        value={selectedAirlines}
        disabled={isDisabled}
      />
    </FormField>
  )
}
