const DEFAULT_NUMBER_OF_DIGITS = 2

const DIGITS = {
  CLP: 0,
}

export const getCurrencyDigits = (forCurrencyCode: string | null) => {
  if (typeof forCurrencyCode !== 'string') return ''

  // If we don't have the correct number of digits for the currency
  // defined, return a sensible default. We should ideally use a
  // currency database to get this right.
  if (forCurrencyCode in DIGITS) {
    return DIGITS[forCurrencyCode]
  } else {
    return DEFAULT_NUMBER_OF_DIGITS
  }
}
