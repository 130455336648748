import { CSRF_TOKEN_HEADER_NAME } from '@lib/authentication/constants'
import { getDuffelAPIAuthenticationCookie } from '@lib/authentication/getDuffelAPIAuthenticationCookie'
import { readCsrfTokenFromCookie } from '@lib/authentication/readCsrfTokenFromCookie'
import { writeCsrfTokenToCookie } from '@lib/authentication/writeCsrfTokenToCookie'
import { BUILD_GIT_SHA } from '@lib/env'
import axios from 'axios'
import { IncomingMessage, ServerResponse } from 'http'
import https from 'https'
import { getOriginToDuffelAPI } from './getOriginToDuffelAPI'
import { CURRENT_API_VERSION } from './constants'

export function getDuffelAPIClient(
  req?: IncomingMessage,
  res?: ServerResponse,
  organisationSlug?: string,
  mode?: 'live' | 'test'
) {
  const httpsAgent = new https.Agent({ rejectUnauthorized: false })

  const duffelApiCookie = getDuffelAPIAuthenticationCookie(req)
  const csrfToken = readCsrfTokenFromCookie(req)

  const headers = {
    accept: 'application/json',
    'content-type': 'application/json',
    'duffel-version': CURRENT_API_VERSION,
    'user-agent': 'Duffel/v1 duffel_dashboard/' + BUILD_GIT_SHA,

    // For requests from the browser, we cannot set the accept-encoding header
    ...(req && { 'accept-encoding': 'gzip' }),

    // For authenticated requests from the server, we need to forward the authentication cookie
    ...(req && duffelApiCookie && { cookie: duffelApiCookie }),

    // For authenticated POST, PATCH and DELETE requests we need to send the CSRF token
    ...(csrfToken && { [CSRF_TOKEN_HEADER_NAME]: csrfToken }),

    // These headers will be added to scope the request to a specific organisation and/or mode
    ...(organisationSlug && { 'x-organisation-slug': organisationSlug }),
    ...(mode && { 'x-access-mode': mode }),
  }

  const axiosInstance = axios.create({
    baseURL: getOriginToDuffelAPI(),
    headers,
    httpsAgent,
    withCredentials: true,
  })

  axiosInstance.interceptors.response.use(
    (response) => {
      // If the response contains a new CSRF token, we need to update the cookie
      if (response.headers[CSRF_TOKEN_HEADER_NAME]) {
        writeCsrfTokenToCookie(response.headers[CSRF_TOKEN_HEADER_NAME], res)
      }

      return response
    }
    // TODO(auth-v2) we can also react to errors here and centralise error handling based on status code
  )

  return axiosInstance
}
