import { useField, useFormikContext } from 'formik'
import * as React from 'react'
import { FormDatePicker } from '@components/DatePicker'
import { FormField } from '@components/Form'
import { slugify } from '@lib/helpers'
import { SearchType } from '@lib/types'
import { SearchFormValues } from '@modules/air-search-v2/lib'
import { FormikSearchTimeFilters } from '@modules/air-search-v2/components/SearchTimeFilters/FormikSearchTimeFilters'

interface DepartureDateFieldProps {
  name: string
  disabled?: boolean
  searchTimeRangesFieldName?: string
}

export const DepartureDateField: React.FC<DepartureDateFieldProps> = ({
  name,
  disabled,
  searchTimeRangesFieldName,
}) => {
  const id = slugify(name)
  const { submitCount, isSubmitting, setFieldValue, handleBlur } =
    useFormikContext<SearchFormValues<SearchType>>()
  const [departureDateField, { touched, error }] = useField({ name })
  const isDisabled = isSubmitting || disabled

  return (
    <div className="departure-date-field">
      <FormField
        htmlFor={id}
        label="Departure date"
        optionalField
        error={touched || submitCount > 0 ? error : undefined}
        disabled={disabled}
      >
        <FormDatePicker
          id={id}
          value={departureDateField.value}
          onChange={(newValue) => {
            setFieldValue(name, newValue, true)
          }}
          onBlur={handleBlur(name)}
          disabled={isDisabled}
          disablePopup={isDisabled}
        />
        {searchTimeRangesFieldName && (
          <FormikSearchTimeFilters name={searchTimeRangesFieldName} />
        )}
      </FormField>

      <style jsx>{`
        .departure-date-field:not(:last-child) {
          margin-bottom: 24px;
        }
      `}</style>
    </div>
  )
}
