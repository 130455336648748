import { UNKNOWN_ERROR_MESSAGE } from '@lib/constants'
import { APIResponseError } from '@lib/types'

export type ErrorUserAction =
  | 'open-support-ticket'
  | 'retry-request'
  | 'no-user-action-required'

export interface UserFriendlyAPIError {
  original: APIResponseError
  action: ErrorUserAction
  message: string
}

/**
 * This function takes raw API response errors and returns an appropriate user-friendly
 * error message and dashboard action, e.g. raise a support ticket, instruct the user
 * to retry, or no further action required.
 *
 * In the case of multiple errors related to one request, all errors and their sequence
 * will be preserved.
 *
 * This error mapping will only be applied to order management actions for the 100% project.
 * Please see https://duffel.atlassian.net/browse/UXP-2671.
 *
 * This can be extended to cover all error handling in the dashboard in the future.
 *
 * Order management actions covered:
 * Change order
 * Cancel order
 * Airline-initiated changes - accept / cancel / change
 * Comprehensive errors list here: https://duffel.com/docs/api/overview/errors
 */
export const getUserFriendlyAPIError = (
  errors: APIResponseError[]
): UserFriendlyAPIError[] => {
  return errors.map((error) => {
    const { code } = error

    let message = ''
    let action: UserFriendlyAPIError['action']

    switch (code) {
      case 'access_token_not_found':
        message =
          'We were unable to authenticate your session. Please re-login and try your request again.'
        action = 'retry-request'
        break

      case 'airline_internal':
        message = 'The airline has responded with an error.'
        action = 'open-support-ticket'
        break

      case 'airline_unknown':
        message = 'The airline has responded with an error.'
        action = 'open-support-ticket'
        break

      case 'already_cancelled':
        message = 'The order has already been cancelled.'
        action = 'no-user-action-required'
        break

      case 'bad_request':
        message = 'The request was invalid.'
        action = 'open-support-ticket'
        break

      case 'duplicate_booking':
        message =
          'A booking with the same details was already found for the selected itinerary.'
        action = 'retry-request'
        break

      case 'duplicate_passenger_name':
        message =
          'The order cannot contain more than one passenger with the same name. Please amend your order.'
        action = 'retry-request'
        break

      case 'expired_access_token':
        message =
          'We were unable to authenticate your session. Please re-login and try your request again.'
        action = 'retry-request'
        break

      case 'insufficient_balance':
        message =
          'You have insufficient funds to complete this transaction. Please top-up your balance and try your request again.'
        action = 'retry-request'
        break

      case 'insufficient_permissions':
        message =
          'You may not have sufficient permissions to perform the requested action.'
        action = 'no-user-action-required'
        break

      case 'internal_server_error':
        message = 'An unknown error occurred'
        action = 'open-support-ticket'
        break

      case 'invalid_email_address':
        message =
          'The airline does not support the format of the email address you provided. Please retry with a different email address.'
        action = 'retry-request'
        break

      case 'invalid_loyalty_card':
        message =
          'The airline did not recognise the loyalty programme account details provided for one or more of the passengers. Please amend and retry your request.'
        action = 'retry-request'
        break

      case 'invalid_phone_number':
        message =
          'The phone number provided is not valid. Please re-enter a valid phone number.'
        action = 'retry-request'
        break

      case 'new_airline_initiated_change':
        message =
          'There is a new airline-initiated change to this order. Please try again.'
        action = 'retry-request'
        break

      case 'offer_expired':
      case 'offer_no_longer_available':
        message =
          'The selected offer has expired or is no longer available. Please try your search again.'
        action = 'retry-request'
        break

      case 'offer_request_already_booked':
        message =
          'An offer from this offer request has already been booked. Please perform a new search.'
        action = 'retry-request'
        break

      case 'order_change_already_actioned':
        message =
          'The order change has already been actioned and cannot be actioned again.'
        action = 'no-user-action-required'
        break

      case 'order_creation_already_attempted':
        message =
          'Order creation has already been attempted for this provided offer. Please perform a new search and try again.'
        action = 'retry-request'
        break

      case 'order_not_created':
        message =
          'Order creation was not successful. You should not retry this request.'
        action = 'open-support-ticket'
        break

      case 'payment_amount_does_not_match_order_amount':
        message =
          'The amount provided in the payment does not match the total amount of the order.'
        action = 'open-support-ticket'
        break

      case 'payment_currency_does_not_match_order_currency':
        message =
          'The currency provided in the payment does not match the currency of the order.'
        action = 'open-support-ticket'
        break

      case 'rate_limit_exceeded':
        message =
          'There have been too many requests to the API. Please try again shortly.'
        action = 'retry-request'
        break

      case 'stale_airline_initiated_change_accept':
        message =
          'The change you tried to accept is not the latest. Please re-load the order page and try your request again.'
        action = 'retry-request'
        break

      case 'stale_airline_initiated_change_update':
        message =
          'The change you tried to update is not the latest. Please re-load the order page and try your request again.'
        action = 'retry-request'
        break

      case 'unavailable_feature':
        message = 'The feature you requested is not available yet.'
        action = 'no-user-action-required'
        break

      default:
        message = UNKNOWN_ERROR_MESSAGE
        action = 'open-support-ticket'
        break
    }

    return {
      original: error,
      message,
      action,
    }
  })
}
