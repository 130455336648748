import { NextApiRequest, NextApiResponse } from 'next'
import { captureException } from '@lib/sentry'
import { getJsonPayload } from './getJsonPayload'
import { logger } from './logger'
import { JsonPayloadForLogging, LoggingOptions } from './types'

const logRequestJsonPayload = (
  jsonPayload: JsonPayloadForLogging,
  loggingOptions: LoggingOptions = {
    severity: 'info',
  }
) => {
  const message = `${jsonPayload.httpRequest.requestMethod} ${jsonPayload.httpRequest.requestUrl}`
  const logThisObject = { message, ...jsonPayload }
  switch (loggingOptions.severity) {
    case 'info':
      logger.info(logThisObject)
      break
    case 'warn':
      logger.warn(logThisObject)
      break
    case 'error':
      logger.error(logThisObject)
  }
}

export const logRequest = async (
  request: NextApiRequest,
  response: NextApiResponse,
  requestReceivedAt: number | null,
  loggingOptions?: LoggingOptions
) => {
  try {
    const payload = await getJsonPayload(request, response, requestReceivedAt)
    logRequestJsonPayload(payload, loggingOptions)
  } catch (error: any) {
    captureException(
      new Error('We ran into an error logging a dashboard request'),
      {
        method: request.method,
        url: request.url,
        error,
      }
    )
  }
}
