import { getDateObject } from '.'

export const isDateTimeInTheFuture = (
  to: Date | string,
  from?: Date | string
): boolean => {
  const toDate = typeof to === 'string' ? getDateObject(to) : to
  if (!toDate) return false

  let fromDate: Date | null = new Date()
  if (from) {
    if (typeof from === 'string') {
      fromDate = getDateObject(from)
    } else {
      fromDate = from
    }
  }

  if (!fromDate) return false
  return toDate.getTime() > fromDate.getTime()
}
