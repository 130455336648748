import { useRouter } from 'next/router'
import * as React from 'react'
import { Button } from '@components/Button'

import { moneyStringFormatter } from '@lib/helpers'
import { APIResponse, DuffelAPI } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import { handleHoldOrderResultConfirmation } from '..'
import { PayHoldOrderDialogActions } from './PayHoldOrderDialogActions'
import { DuffelCardFormModal } from '@modules/stays-search/components/StaysCheckoutForm/DuffelCardFormModal'
import { getDuffelAPIClient } from '@lib/duffel-api/getDuffelAPIClient'
import { AxiosResponse } from 'axios'
import { UNKNOWN_ERROR_MESSAGE } from '@lib/constants'

export const PayHoldOrderActions: React.FC<{
  order: DuffelAPI.Types.Order
  componentClientKey: string
}> = ({ order, componentClientKey }) => {
  const router = useRouter()
  const { openDialog, closeDialog, addToast } = useWorkspace()
  const [isCardPaymentModalOpen, setIsCardPaymentModalOpen] =
    React.useState(false)

  const price = moneyStringFormatter(order.totalCurrency)(+order.totalAmount)

  React.useEffect(() => {
    if (isCardPaymentModalOpen) {
      closeDialog()
    }
  }, [isCardPaymentModalOpen, closeDialog])

  return !isCardPaymentModalOpen ? (
    <Button
      text={`Pay (${price})`}
      intent="PRIMARY"
      onClick={() => {
        openDialog({
          customActions: () => (
            <PayHoldOrderDialogActions
              closeModal={() => closeDialog()}
              setIsCardPaymentModalOpen={setIsCardPaymentModalOpen}
              order={order}
            />
          ),
          title: 'Pay for flight',
          message: 'Confirm and pay for this flight now?',
        })
      }}
    />
  ) : (
    <DuffelCardFormModal
      clientKey={componentClientKey}
      resourceId={order.id}
      onClose={() => setIsCardPaymentModalOpen(false)}
      buttonText={`Pay ${moneyStringFormatter(order.totalCurrency)(
        +order.totalAmount
      )}`}
      onSubmit={async ({ cardId, threeDSecureSessionId }) => {
        const client = getDuffelAPIClient(
          undefined,
          undefined,
          router.query.org as string,
          router.query.mode as 'live' | 'test'
        )
        let response: AxiosResponse | null = null
        try {
          response = await client.post('/air/payments/', {
            data: {
              order_id: order.id,
              payment: {
                type: 'card',
                amount: order.totalAmount,
                currency: order.totalCurrency,
                ...(!threeDSecureSessionId && { cardId }),
                threeDSecureSessionId,
              },
            },
          })
        } catch (error) {
          console.error(error)
          addToast({
            intent: 'warning',
            message: UNKNOWN_ERROR_MESSAGE,
            closeAfterTimeout: false,
          })
        }

        if (response) {
          handleHoldOrderResultConfirmation(
            response.data as APIResponse<DuffelAPI.Types.Order>,
            addToast,
            closeDialog,
            () => setIsCardPaymentModalOpen(false),
            () => router.replace(router.asPath)
          )
        }
      }}
    />
  )
}
