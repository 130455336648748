import { DuffelAPI } from '@lib/types'

export type OfferRequestOrOrder =
  | DuffelAPI.Types.OfferRequest
  | DuffelAPI.Types.Order

export const isOrder = (
  maybeOfferRequest: OfferRequestOrOrder
): maybeOfferRequest is DuffelAPI.Types.Order => {
  return !!(<DuffelAPI.Types.Order>maybeOfferRequest).bookingReference
}
