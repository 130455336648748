import { AxiosRequestConfig } from 'axios'
import { DateRange, StringDateRange } from '@components/DateRangePicker'
import { getDateObject } from '@lib/date'
import { makeMockDateInThePast } from '@lib/testing'
import {
  APIResponse,
  BaseClientConfig,
  DuffelAPI,
  DuffelContext,
  DuffelProxy,
  PaginationMeta,
} from '@lib/types'
import { BaseClient } from './lib'

export type TransactionsDownloadType = 'csv' | 'pdf'

export class Payments extends BaseClient {
  constructor(config: BaseClientConfig) {
    super(config)
  }

  private static getcompleteDateTimeRange = ({
    start,
    end,
  }: StringDateRange) => {
    const timeAtStartOfDay = 'T00:00:00Z'
    const timeAtEndOfDay = 'T23:59:59Z'
    return {
      start: start!.split('T')[0] + timeAtStartOfDay,
      end: end!.split('T')[0] + timeAtEndOfDay,
    }
  }

  static getDefaultTransactionDateRange(
    forOrganisation: DuffelProxy.Types.SelfOrganisation | null | undefined
  ): DateRange {
    const today = new Date()
    const twoWeeksAgo = makeMockDateInThePast(14)

    const orgCreatedAt = getDateObject(forOrganisation?.createdAt as string)

    const start =
      orgCreatedAt === null || orgCreatedAt < twoWeeksAgo
        ? twoWeeksAgo
        : orgCreatedAt
    const end = today

    return { start, end }
  }

  static getDefaultTransactionStringRange(
    forOrganisation: DuffelProxy.Types.SelfOrganisation | null | undefined
  ): StringDateRange {
    const { start, end } =
      Payments.getDefaultTransactionDateRange(forOrganisation)

    return {
      start: start!.toISOString(),
      end: end!.toISOString(),
    }
  }

  static getWalletAccount(
    wallets: DuffelAPI.Types.WalletAccount[],
    liveMode: boolean
  ) {
    return wallets.find((wallet) => wallet.liveMode === liveMode)
  }

  async getWalletAccounts(
    paginate?: PaginationMeta,
    ctx?: DuffelContext,
    hide_unused?: boolean
  ) {
    const requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url: `/api/payments/wallets`,
      ...this.getRequestOptions(ctx),
      params: {
        hide_unused: hide_unused !== undefined ? hide_unused : true,
      },
    }
    return paginate
      ? this.requestWithPagination<DuffelAPI.Types.WalletAccount[]>(
          requestConfig,
          paginate
        )
      : this.request<DuffelAPI.Types.WalletAccount[]>(requestConfig)
  }

  async getWalletAccountTransactions(
    forWalletId: string,
    onDateRange: StringDateRange,
    paginate: PaginationMeta = {},
    ctx?: DuffelContext
  ) {
    const onDateTimeRange = Payments.getcompleteDateTimeRange(onDateRange)

    const requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url: `/api/payments/transactions`,
      params: {
        wallet_id: forWalletId,
        ...onDateTimeRange,
      },
      ...this.getRequestOptions(ctx),
    }
    return paginate
      ? this.requestWithPagination<DuffelAPI.Types.WalletTransaction[]>(
          requestConfig,
          Object.assign({}, paginate, { limit: 200 })
        )
      : this.request<DuffelAPI.Types.WalletTransaction[]>(requestConfig)
  }

  getWalletAccountTransactionsDownloadFn = (
    downloadType: TransactionsDownloadType
  ) => {
    const headerAccept = {
      csv: 'text/csv',
      pdf: 'application/pdf',
    }[downloadType]

    return async (
      forWalletId: string,
      onDateRange: StringDateRange,
      ctx?: DuffelContext
    ) => {
      const onDateTimeRange = Payments.getcompleteDateTimeRange(onDateRange)

      const requestOptions = this.getRequestOptions(ctx)
      requestOptions.headers['accept'] = headerAccept

      const requestConfig: AxiosRequestConfig = {
        method: 'GET',
        url: `/api/payments/transactions/export`,
        responseType: 'blob',
        params: {
          wallet_id: forWalletId,
          ...onDateTimeRange,
        },
        ...requestOptions,
      }

      try {
        const response = await this.rawRequest<Blob>(requestConfig)

        const attachment: DuffelAPI.Types.FileAttachment = {
          content: response.data,
        }

        if (response.headers && response.headers['content-disposition']) {
          const parts = response.headers['content-disposition'].split(';')
          if (parts.length > 1) {
            attachment.fileName = parts[1]
              .replace(/filename="(.*?)"/, '$1')
              .trim()
          }
        }

        const data: APIResponse<DuffelAPI.Types.FileAttachment> = {
          data: attachment,
        }

        return data
      } catch (errorResult: any) {
        return typeof errorResult === 'string'
          ? { errors: [{ message: errorResult }] }
          : errorResult
      }
    }
  }

  getWalletAccountTransactionsCSV =
    this.getWalletAccountTransactionsDownloadFn('csv')

  getWalletAccountTransactionsPDF =
    this.getWalletAccountTransactionsDownloadFn('pdf')

  async createPaymentIntent(
    data: DuffelAPI.Inputs.PaymentIntent,
    ctx?: DuffelContext
  ) {
    return this.request<DuffelAPI.Types.PaymentIntent>({
      method: 'POST',
      url: `/api/payments/payment_intents`,
      data,
      ...this.getRequestOptions(ctx),
    })
  }

  async confirmPaymentIntent(paymentIntentId: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.PaymentIntent>({
      method: 'POST',
      url: `/api/payments/payment_intents/${paymentIntentId}/actions/confirm`,
      ...this.getRequestOptions(ctx),
    })
  }

  async getPaymentIntent(paymentIntentId: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.PaymentIntent>({
      method: 'GET',
      url: `/api/payments/payment_intents/${paymentIntentId}`,
      ...this.getRequestOptions(ctx),
    })
  }

  async createRefund(data: DuffelAPI.Inputs.Refund, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.Refund>({
      method: 'POST',
      url: '/api/payments/refunds',
      data,
      ...this.getRequestOptions(ctx),
    })
  }

  async getRefund(refundId: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.Refund>({
      method: 'GET',
      url: `/api/payments/refunds/${refundId}`,
      ...this.getRequestOptions(ctx),
    })
  }

  async updateWalletLowBalanceThreshold(
    walletId: DuffelAPI.Types.WalletAccount['id'],
    lowBalanceThreshold: DuffelAPI.Types.WalletAccount['lowBalanceThreshold'],
    ctx?: DuffelContext
  ) {
    return this.request<DuffelAPI.Types.Refund>({
      method: 'PATCH',
      url: `/api/payments/wallets/${walletId}`,
      data: { lowBalanceThreshold },
      ...this.getRequestOptions(ctx),
    })
  }
}
