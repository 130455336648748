export const goToPreviousTabbable = (
  fromElement: HTMLInputElement | HTMLButtonElement | string | null | undefined
) => {
  setTimeout(() => {
    if (typeof fromElement === 'string')
      fromElement = document.getElementById(fromElement) as
        | HTMLInputElement
        | HTMLButtonElement
        | null

    if (!fromElement) return

    const tabbables = document.querySelectorAll<
      HTMLInputElement | HTMLButtonElement
    >('input:not([disabled]),button:not([disabled])')
    for (let index = 1; index < tabbables.length; index++) {
      const previousEl = tabbables[index - 1]
      const element = tabbables[index]
      if (element.id === fromElement.id) {
        previousEl.focus()
        break
      }
    }
  }, 50)
}

export const goToNextTabbable = (
  fromElement: HTMLInputElement | HTMLButtonElement | string | null | undefined
) => {
  setTimeout(() => {
    if (typeof fromElement === 'string')
      fromElement = document.getElementById(fromElement) as
        | HTMLInputElement
        | HTMLButtonElement
        | null

    if (!fromElement) return

    const tabbables = document.querySelectorAll<
      HTMLInputElement | HTMLButtonElement
    >('input:not([disabled]),button:not([disabled])')
    for (let index = 1; index < tabbables.length; index++) {
      const previousEl = tabbables[index - 1]
      const element = tabbables[index]
      if (previousEl.id === fromElement.id) {
        element.focus()
        break
      }
    }
  }, 50)
}
