import * as React from 'react'
import classNames from 'classnames'

import styles from './TextArea.module.css'

export interface TextAreaProps
  extends Omit<
    React.DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    'ref'
  > {
  width?: string
  isError?: boolean
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ width = '100%', className, isError, ...props }, ref) => {
    return (
      <textarea
        {...props}
        className={classNames(styles['textarea'], className, {
          [styles['textarea--is-error']]: isError,
        })}
        ref={ref}
        style={{ width }}
      />
    )
  }
)
