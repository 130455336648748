import * as React from 'react'
import { Text } from '@components/Text'
import styles from './MenuHeader.module.css'

interface MenuHeaderProps {
  label: string
}

export const MenuHeader: React.FC<MenuHeaderProps> = ({ label }) => {
  return (
    <li className={styles['wrapper']}>
      <Text
        className={styles['header']}
        fontSize="C4"
        fontWeight="medium"
        color="grey-500"
      >
        {label}
      </Text>
    </li>
  )
}
