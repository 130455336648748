import { ParsedUrlQuery } from 'querystring'
import { NextRouter } from 'next/router'
import { DuffelProxy } from '@lib/types'
import { OrgModePaths, orgModePathsMap } from './org-mode-paths'
import { OrgPaths, orgPathsMap } from './org-paths'
import { RootPaths, rootPathsMap } from './root-paths'

export type PathKey = RootPaths | OrgPaths | OrgModePaths

export const doesPathNameMatch = (pathname: string, pathKey: PathKey) => {
  if (pathKey in orgModePathsMap) {
    return orgModePathsMap[pathKey] === pathname
  } else if (pathKey in orgPathsMap) {
    return orgPathsMap[pathKey] === pathname
  } else if (pathKey in rootPathsMap) {
    return rootPathsMap[pathKey] === pathname
  } else return false
}

export const fallBackPathObject = {
  href: { pathname: '/', query: {} },
  as: '/',
}
export const fallBackPathArray = [{ pathname: '/', query: {} }, '/']

export type NextRouterPushInputArray = [
  Pick<NextRouter, 'query' | 'pathname'>,
  string
]

export const updateParamOnCurrentRouterProps = (
  {
    query,
    pathname,
    asPath,
  }: Pick<NextRouter, 'query' | 'pathname' | 'asPath'>,
  paramName: string,
  newParamValue: string
): NextRouterPushInputArray => {
  if (!pathname.includes(`[${paramName}]`)) return [{ pathname, query }, asPath]

  const updatedAsPath = Object.entries(query).reduce(
    (asPath, [param, value]) => {
      if (param === paramName)
        return asPath.replace(`[${param}]`, newParamValue)
      return Array.isArray(value)
        ? asPath.replace(`[${param}]`, value.join('/'))
        : asPath.replace(`[${param}]`, value as string)
    },
    pathname
  )
  return [
    { pathname, query: { ...query, [paramName]: newParamValue } },
    updatedAsPath,
  ]
}

export const updateParamWithRouter = (
  router: NextRouter,
  paramName: string,
  newParamValue: string
): NextRouterPushInputArray => {
  const { asPath, pathname, query } = router
  return updateParamOnCurrentRouterProps(
    { asPath, pathname, query },
    paramName,
    newParamValue
  )
}

export const toggleModeOnCurrentPath = (
  router: NextRouter
): NextRouterPushInputArray => {
  let newMode
  switch (router.query.mode) {
    case 'test':
      newMode = 'live'
      break
    case 'live':
    default:
      newMode = 'test'
  }

  return updateParamWithRouter(router, 'mode', newMode)
}

export const toggleModeOnPath = (
  router: NextRouter,
  pathname: string
): NextRouterPushInputArray => {
  let newMode
  switch (router.query.mode) {
    case 'test':
      newMode = 'live'
      break
    case 'live':
    default:
      newMode = 'test'
  }

  const org = router.query.org as string
  return [
    {
      pathname,
      query: { mode: newMode, org },
    },
    pathname.replace('[org]', org).replace('[mode]', newMode),
  ]
}

export const switchOrgOnCurrentPath = (
  router: NextRouter,
  organisation: DuffelProxy.Types.SelfOrganisation
): NextRouterPushInputArray => {
  const [{ pathname, query }, asPath] = updateParamWithRouter(
    router,
    'org',
    organisation.slug
  )

  return !organisation.isVerified && query.mode === 'live'
    ? updateParamOnCurrentRouterProps(
        {
          query: query as ParsedUrlQuery,
          pathname: pathname as string,
          asPath,
        },
        'mode',
        'test'
      )
    : [{ pathname, query }, asPath]
}
