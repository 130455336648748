import { DuffelAPI } from '@lib/types'
import { CheckoutFormValuesPassenger } from './get-initial-checkout-form-values'

/**
 * Checks both offer requests and offers for loyalty programme account information.
 *
 * Returns `true` or `false`
 */
export const areLoyaltyAccountsApplied = (
  offer: DuffelAPI.Types.OfferRequest | DuffelAPI.Types.Offer
) =>
  !!offer.passengers?.find(
    (passenger) => passenger.loyaltyProgrammeAccounts?.length !== 0
  )

export const areLoyaltyAccountsAppliedToThePassenger = (
  passenger: CheckoutFormValuesPassenger
) => {
  if (
    passenger.loyaltyProgrammeAccounts &&
    passenger.loyaltyProgrammeAccounts.length > 0
  ) {
    return true
  }
  return false
}
