import { DateRange } from '@components/DateRangePicker'

export type CompleteDateRange = { start: Date; end: Date }

export const isRangeComplete = (
  dateRange: DateRange | null
): dateRange is CompleteDateRange => {
  return (
    dateRange !== null &&
    dateRange.start instanceof Date &&
    dateRange.end instanceof Date
  )
}
