export interface BasicEnv {
  isDev: boolean
  isStaging: boolean
  isProduction: boolean
}

export const isBrowser = typeof window !== 'undefined'

// ! process.env.NEXT_PUBLIC_APP_ENV with now dev
// note that process.env.NEXT_PUBLIC_APP_ENV's default value on dev is "staging"
// Its value is set on our now.json file
// to override it and point to your local API,
// just add 'process.env.NEXT_PUBLIC_APP_ENV=development'to your .env.development file.

export const isDev: boolean = process.env.NODE_ENV !== 'production'
export const isPureDev: boolean =
  isDev && process.env.NEXT_PUBLIC_APP_ENV === 'development'

export const isStaging: boolean =
  !isDev && process.env.NEXT_PUBLIC_APP_ENV === 'staging'
export const isDevStaging: boolean =
  isDev && process.env.NEXT_PUBLIC_APP_ENV === 'staging'

export const isProduction: boolean =
  !isDev && process.env.NEXT_PUBLIC_APP_ENV === 'production'
export const isDevProduction: boolean =
  isDev && process.env.NEXT_PUBLIC_APP_ENV === 'production'

export const JWT_SECRET = process.env.JWT_SECRET as string
export const JWT_ZENDESK_LIVE_CHAT_SECRET = process.env
  .JWT_ZENDESK_LIVE_CHAT_SECRET as string
export const SENTRY_DSN = process.env.SENTRY_DSN as string
export const ENCRYPTION_KEY = process.env.ENCRYPTION_KEY as string
export const NEXT_PUBLIC_SEGMENT_WRITE_KEY = process.env
  .NEXT_PUBLIC_SEGMENT_WRITE_KEY as string
export const AIRTABLE_TOKEN = process.env.AIRTABLE_TOKEN as string
export const BUILD_GIT_SHA = (process.env.COMMIT_SHA || 'localbuild') as string
export const DUFFEL_HQ_URL = process.env.DUFFEL_HQ_URL as string

export const MAPBOX_ACCESS_TOKEN = process.env.MAPBOX_ACCESS_TOKEN as string
export const ALGOLIA_API_KEY = process.env.ALGOLIA_API_KEY as string
export const ALGOLIA_APP_ID = process.env.ALGOLIA_APP_ID as string

const getCurrentEnvironment = () => {
  if (isProduction) {
    return 'production'
  }
  if (isStaging) {
    return 'staging'
  }
  return 'development'
}

export const currentEnvironment = getCurrentEnvironment()
