import classNames from 'classnames'
import * as React from 'react'
import { Spinner } from '@components/Spinner'
import { useCombinedRefs } from '@lib/hooks/use-combined-refs'
import styles from './Input.module.css'

export interface InputProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'ref'
  > {
  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string

  /**
   * If there is a validation or submit error associated with this input.
   */
  isError?: boolean

  /**
   * If set to true, will display a loading spinner on the right of the input
   * box.
   *
   * @default
   * false
   */
  isWaiting?: boolean

  /**
   * The visual size of the input.
   *
   * @default
   * 'medium'
   */
  containerSize?: 'medium' | 'small'

  /**
   * Should the search value be hidden in FullStory?
   */
  maskInputValueOnFullstory?: boolean
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      isError = false,
      isWaiting = false,
      containerSize = 'medium',
      maskInputValueOnFullstory = false,
      disabled: isDisabled,
      id,
      ...props
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = React.useState(false)
    const innerRef = React.useRef(null)
    const combinedRef = useCombinedRefs(ref, innerRef)

    const cn = classNames(styles['wrapper'], className, {
      [styles['wrapper--is-focused']]: isFocused,
      [styles['wrapper--is-error']]: isError,
      [styles['wrapper--is-disabled']]: isDisabled,
      [styles['wrapper--small']]: containerSize === 'small',
    })

    return (
      <div className={cn}>
        <input
          {...props}
          disabled={isDisabled}
          onClick={() => {
            combinedRef?.current.focus()
          }}
          onFocus={(event) => {
            props.onFocus?.(event)
            setIsFocused(true)
          }}
          onBlur={(event) => {
            props.onBlur?.(event)
            setIsFocused(false)
          }}
          className={classNames(styles['input'], className)}
          ref={combinedRef}
          id={id || 'input'}
          aria-describedby={`${id}_desc`}
          {...(maskInputValueOnFullstory && { ['data-selector']: 'fs-mask' })}
        />
        {isWaiting && (
          <div className={styles['spinner-wrapper']}>
            <Spinner size="small" />
          </div>
        )}
      </div>
    )
  }
)
