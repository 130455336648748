import { DuffelAPI } from '@lib/types'
import { getPassengerTitle } from '..'
import { PassengerLoyaltyAccountsForm } from './PassengerLoyaltyAccountsForm'

export type PassengerLoyaltyAccountsContainerProps = {
  /**
   * Array of passengers generated from the CommonSearchFormSection
   */
  passengers: DuffelAPI.Inputs.OfferRequestPassenger[]
}

export const PassengerLoyaltyAccountsContainer: React.FC<
  PassengerLoyaltyAccountsContainerProps
> = ({ passengers }) => (
  <section className="loyalty-accounts__container">
    <header className="loyalty-accounts__header">Loyalty accounts</header>
    {passengers.map((passenger, index) => (
      <PassengerLoyaltyAccountsForm
        key={`passenger-${passenger.key}-loyalty-accounts`}
        passengerTitle={getPassengerTitle(passenger, passengers)}
        passenger={passenger}
        passengerIndex={index}
      />
    ))}
    <style jsx>{`
      .loyalty-accounts__container {
        margin-top: var(--space-16);
      }

      .loyalty-accounts__header {
        font-size: var(--FONT-SIZES-C1);
      }
    `}</style>
  </section>
)
