import * as React from 'react'
import { Text } from '@components/legacy-design-system/product/components/Text'
import { FlightInformationUpdate } from '@components/FlightInformationUpdate'
import { LayoverDiff } from '../ChangeSummary'

export const ChangeLayover: React.FC<LayoverDiff> = ({
  layoverTimeDifference,
  isAddedLayover,
  airport,
  duration,
}) => {
  if (layoverTimeDifference) {
    const sanitisedTimeDifference =
      layoverTimeDifference.charAt(0) === '-'
        ? layoverTimeDifference.split('-')
        : layoverTimeDifference

    return (
      <FlightInformationUpdate status="changed">
        The layover in&nbsp;
        <Text asElement="span" color="grey-900">
          {airport}&nbsp;
        </Text>
        is now&nbsp;
        {sanitisedTimeDifference}&nbsp;
        {layoverTimeDifference.charAt(0) === '-' ? 'shorter' : 'longer'}.
      </FlightInformationUpdate>
    )
  } else if (isAddedLayover) {
    return (
      <FlightInformationUpdate status="added">
        A {duration} layover has been added at&nbsp;
        <Text asElement="span" color="grey-900">
          {airport}
        </Text>
        .
      </FlightInformationUpdate>
    )
  }

  return null
}
