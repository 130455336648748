import { format, max, min, subYears } from 'date-fns'

const MIN_BORN_ON_DATE = '1900-01-01'

/**
 * Generate a random date of birth
 * @returns { Date } Date based on inputs
 */
export const getRandomPassengerBornOnDate = (age: number | null) => {
  const currentDate = new Date()
  let minDate
  let maxDate

  if (age !== null) {
    minDate = max([subYears(currentDate, age), new Date(MIN_BORN_ON_DATE)])
    maxDate = min([subYears(currentDate, age + 1), currentDate])
  } else {
    minDate = new Date(MIN_BORN_ON_DATE)
    // If no age is supplied, we can assume the passenger is an adult.
    // Therefore they must be at least 18 years old.
    maxDate = subYears(currentDate, 18)
  }

  return format(getRandomDate(minDate, maxDate), 'yyyy-MM-dd')
}

const getRandomDate = (minDate: Date, maxDate: Date) => {
  return new Date(
    minDate.getTime() + Math.random() * (maxDate.getTime() - minDate.getTime())
  )
}
