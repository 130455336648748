import * as React from 'react'
import {
  AfterModifier,
  BeforeAfterModifier,
  BeforeModifier,
} from 'react-day-picker'
import addDays from 'date-fns/addDays'
import { DateRange } from '@components/DateRangePicker'
import { toUTCDate } from '@lib/date'
import { goToNextTabbable } from '@lib/helpers'
import { useDuffelPopper } from '@lib/hooks'
import { DatePickerDefaultPopover } from './DatePickerDefaultPopover'
import {
  addValueToDateRange,
  DatePickerWrapperProps,
  makeDayInputId,
  makeYearInputId,
  setupOnTabListenersOnCalendarDays,
} from './lib'
import { isDateRange } from './lib/is-date-range'

export type SelectedDayRange = [
  Date | null | undefined,
  { to?: Date; from?: Date }
]
export type ReactDatePickerModifiers = {
  start: Date | undefined
  end: Date | undefined
}
export type ReactDatePickerMonths = Date | null | undefined

export const DatePickerWrapper: React.FC<DatePickerWrapperProps> = ({
  id,
  value,
  onChange,
  enablePastDates,
  numberOfMonths,
  disablePopup,
  renderTarget,
  rangeValue,
  onClosePopover: onClosePopoverProp,
  onlyAllowRange,
  onSetActive,
  onSetInactive,
  shouldGoToNextTabbable = true,
}) => {
  if (value) {
    if (isDateRange(value)) {
      value = {
        start: toUTCDate(value.start),
        end: toUTCDate(value.end),
      }
    } else {
      value = toUTCDate(value)
    }
  }

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
  const onOpenPopover = React.useCallback(() => {
    !disablePopup && setIsPopoverOpen(true)
    onSetActive && onSetActive()
  }, [onSetActive, disablePopup])

  const onClosePopover = React.useCallback(() => {
    setIsPopoverOpen(false)
    onClosePopoverProp && onClosePopoverProp()
    onSetInactive && onSetInactive()
  }, [onClosePopoverProp, onSetInactive])

  const {
    popper: { styles, attributes },
    setReferenceElement,
    setPopperElement,
  } = useDuffelPopper(
    isPopoverOpen,
    onClosePopover,
    {
      placement: 'bottom-start',
      modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
    },
    { shouldInsideClickClose: false }
  )

  let disabledDays:
    | [BeforeAfterModifier | BeforeModifier | AfterModifier]
    | BeforeModifier
    | undefined = undefined
  if (onlyAllowRange) {
    disabledDays = [
      {
        ...(onlyAllowRange.start && { before: onlyAllowRange.start }),
        ...(onlyAllowRange.end && { after: onlyAllowRange.end }),
      } as BeforeAfterModifier | BeforeModifier | AfterModifier,
    ]
  } else {
    if (!enablePastDates) {
      // Pad the minimum date by one day to account for time zone differences (UXP-982).
      disabledDays = { before: addDays(new Date(), -1) }
    }
  }

  let modifiers: ReactDatePickerModifiers
  let selectedDaysRange: SelectedDayRange
  let month: ReactDatePickerMonths

  if (rangeValue) {
    selectedDaysRange = [
      rangeValue.start,
      {
        ...(rangeValue?.start instanceof Date
          ? { from: rangeValue.start }
          : undefined),
        ...(rangeValue?.end instanceof Date
          ? { to: rangeValue.end }
          : undefined),
      },
    ]
    modifiers = {
      start: rangeValue.start || undefined,
      end: rangeValue.end || undefined,
    }
    month = rangeValue.start || rangeValue.end || undefined
  } else {
    const getNewValue = (
      existingValue: Date | DateRange | null
    ):
      | {
          start: Date | undefined
          end: Date | undefined
        }
      | {
          start: Date
          end?: undefined
        } => {
      if (existingValue === null) {
        return { start: undefined, end: undefined }
      }
      if (isDateRange(existingValue)) {
        return {
          start: existingValue.start || undefined,
          end: existingValue.end || undefined,
        }
      }
      return { start: existingValue }
    }

    const newValue = getNewValue(value)

    modifiers = { start: newValue.start, end: newValue.end }
    selectedDaysRange = [
      newValue.start,
      {
        ...(newValue.start !== undefined && { from: newValue.start }),
        ...(newValue.end !== undefined && { to: newValue.end }),
      } as any,
    ]
    month = newValue.start || newValue.end || undefined
  }

  return (
    <React.Fragment>
      {renderTarget({
        id,
        value,
        onChange,
        onClosePopover,
        ref: setReferenceElement,
        isActive: isPopoverOpen,
        onOpenPopover: (event) => {
          onOpenPopover()
          if (event && (event.target as HTMLDivElement).tagName === 'DIV') {
            const dayInput = document.querySelector<HTMLInputElement>(
              '#' + makeDayInputId(id)
            )
            dayInput?.focus()
          }
        },
      })}
      <DatePickerDefaultPopover
        ref={setPopperElement}
        popperStyles={{ zIndex: 50, ...styles.popper }}
        popperAttributes={attributes.popper}
        isOpen={isPopoverOpen}
        onMonthChange={() => {
          setupOnTabListenersOnCalendarDays(onClosePopover)
        }}
        className={
          isDateRange(value) || rangeValue ? 'range-picker' : undefined
        }
        disabledDays={disabledDays}
        modifiers={modifiers}
        month={month}
        selectedDays={selectedDaysRange as any}
        onDayClick={(date, modifiers) => {
          if (modifiers.disabled) return

          const newValue = isDateRange(value)
            ? addValueToDateRange(value, date)
            : date
          onChange(newValue)

          const shouldClosePopover = isDateRange(newValue) ? false : true
          if (shouldClosePopover) {
            onClosePopover()
            if (shouldGoToNextTabbable) {
              setTimeout(() => goToNextTabbable(makeYearInputId(id)), 0)
            }
          }
        }}
        numberOfMonths={numberOfMonths}
      />
    </React.Fragment>
  )
}
