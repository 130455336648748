export const getCurrencySymbol = (selectedCurrency: string) => {
  switch (selectedCurrency) {
    case 'USD':
      return 'US$'
    case 'GBP':
      return '£'
    case 'EUR':
      return '€'
    case 'AUD':
      return 'A$'
    case 'CAD':
      return 'C$'
    default:
      return null
  }
}
