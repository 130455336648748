import { BaseClientConfig, DuffelAPI, DuffelContext } from '@lib/types'
import { BaseClient } from './lib'

export class Billing extends BaseClient {
  constructor(config: BaseClientConfig) {
    super(config)
  }

  getUsageRecords(billingPeriod: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.UsageRecord>({
      method: 'GET',
      url: `/api/billing/usage_records`,
      params: {
        billing_period: billingPeriod,
      },
      ...this.getRequestOptions(ctx),
    })
  }

  getMockUsageRecords(mockFixture: string) {
    return this.request<DuffelAPI.Types.UsageRecord>({
      method: 'GET',
      url: `/api/mock/usage-records`,
      ...{ params: { mockFixture } },
    })
  }

  getInvoice(billingPeriod: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.Invoice>({
      method: 'GET',
      url: `/api/billing/invoices/${billingPeriod}`,
      ...this.getRequestOptions(ctx),
    })
  }

  getMockInvoice(mockFixture: string) {
    return this.request<DuffelAPI.Types.Invoice>({
      method: 'GET',
      url: `/api/mock/invoices`,
      ...{ params: { mockFixture } },
    })
  }

  getCurrentPlan(billingPeriod: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.PaymentPlan>({
      method: 'GET',
      url: `/api/billing/current_plan`,
      params: {
        billing_period: billingPeriod,
      },
      ...this.getRequestOptions(ctx),
    })
  }
}
