import { object, string } from 'yup'
import { iataCode, sliceDepartureDate } from '@lib/form-validation'
import { DuffelAPI } from '@lib/types'
import {
  CommonSearchFormSectionValues,
  commonValidationSchema,
  searchTimeRangesSchema,
} from '../CommonSearchFormSection'
import { sanitisePassengersLoyaltyProgrammeAccounts } from '../lib'
import {
  formatTimeRangeForPayload,
  SearchTimeRanges,
} from '../lib/search-time-ranges'

export interface OneWaySearchFormValues extends CommonSearchFormSectionValues {
  type: 'one_way'
  origin: string
  destination: string
  departureDate: Date | null
  timeRanges?: SearchTimeRanges
}

export const transformValuesIntoPayload = (
  data: OneWaySearchFormValues
): DuffelAPI.Inputs.OfferRequest => {
  const payload: DuffelAPI.Inputs.OfferRequest = {
    slices: [
      {
        origin: data.origin,
        destination: data.destination,
        departureDate:
          data.departureDate === null
            ? ''
            : data.departureDate.toISOString().split('T')[0],
      },
    ],
    // sanitise loyalty programme accounts to avoid any API errors
    passengers: sanitisePassengersLoyaltyProgrammeAccounts(data.passengers),
    supplierTimeout: data.supplierTimeout,
  }

  if (data.cabinClass !== 'any') {
    payload.cabinClass = data.cabinClass
  }

  if (data.airlines.length > 0) {
    payload.requestedSources = data.airlines
  }

  const departureTime = formatTimeRangeForPayload(
    data.timeRanges?.departureTime
  )
  if (departureTime) {
    payload.slices[0].departureTime = departureTime
  }
  const arrivalTime = formatTimeRangeForPayload(data.timeRanges?.arrivalTime)
  if (arrivalTime) {
    payload.slices[0].arrivalTime = arrivalTime
  }

  return payload
}

export const validationSchema = object().shape({
  type: string().oneOf(['one_way']),
  origin: iataCode('Origin'),
  destination: iataCode('Destination'),
  departureDate: sliceDepartureDate('departure'),
  timeRanges: searchTimeRangesSchema,
  ...commonValidationSchema,
})
