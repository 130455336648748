import * as React from 'react'
import { Formik, FormikHelpers } from 'formik'
import { useRouter } from 'next/router'
import { DuffelAPI } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import { UNKNOWN_ERROR_MESSAGE } from '@lib/constants'
import { trackEvent } from '@lib/tracking'
import {
  CreateOrderChangeForm,
  CreateOrderChangeFormProps,
} from '@modules/air-order-change/forms'
import {
  CreateOrderChangeRequestFormValues,
  getCreateOrderChangeFormInitialValues,
  getCreateOrderChangeFormValidationSchema,
  onCreateOrderChangeRequestFormSubmit,
} from '@modules/air-order-change/lib'
import { useUserFriendlyErrors } from '@modules/air-order/lib/use-user-friendly-errors'

interface CreateOrderChangeFormContainerProps {
  order: DuffelAPI.Types.Order
  orderChangeRequest?: DuffelAPI.Types.OrderChangeRequest
  onSubmissionStarted?: (
    values: CreateOrderChangeRequestFormValues,
    formikHelpers: FormikHelpers<CreateOrderChangeRequestFormValues>
  ) => void
  onSubmissionComplete?: () => void
}

export const CreateOrderChangeFormContainer: React.FC<
  CreateOrderChangeFormContainerProps
> = ({
  order,
  orderChangeRequest,
  onSubmissionStarted,
  onSubmissionComplete,
}) => {
  const workspace = useWorkspace()
  const { push } = useRouter()
  const { handleOrderErrors } = useUserFriendlyErrors()

  const onSubmitFormik = async (
    values: CreateOrderChangeRequestFormValues,
    formikHelpers: FormikHelpers<CreateOrderChangeRequestFormValues>
  ) => {
    if (onSubmissionStarted) {
      onSubmissionStarted(values, formikHelpers)
    }

    await onCreateOrderChangeRequestFormSubmit(
      values,
      formikHelpers,
      order,
      workspace.permissions,
      push,
      async (errors, requestId) => {
        trackEvent('dashboard_change_flights_create_pending_change_failed', {
          error_message: errors?.[0]?.message || UNKNOWN_ERROR_MESSAGE,
          event_type: 'api',
          order_id: order.id,
          order_change_id: orderChangeRequest?.id,
        })

        await handleOrderErrors(errors, order, {
          actionToSupport: 'change_order',
          requestId: requestId ?? '',
        })
      }
    )
    if (onSubmissionComplete) {
      onSubmissionComplete()
    }
  }

  return (
    <div data-selector="fs-show">
      <Formik
        initialValues={getCreateOrderChangeFormInitialValues(
          order,
          orderChangeRequest
        )}
        validationSchema={getCreateOrderChangeFormValidationSchema(order)}
        onSubmit={onSubmitFormik}
      >
        {renderCreateOrderChangeForm}
      </Formik>
    </div>
  )
}

const renderCreateOrderChangeForm = (props: CreateOrderChangeFormProps) => (
  <CreateOrderChangeForm {...props} />
)
