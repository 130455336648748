import { getPassengerName } from '@lib/helpers/get-passenger-name'
import { DuffelAPI } from '@lib/types'
import { OrderTicketWithPassengerName } from '../types'

const getOrderPassengerNameFromId = (
  passengerId: DuffelAPI.Types.OrderPassenger['id'],
  passengers: DuffelAPI.Types.OrderPassenger[]
): string | null => {
  const passenger = passengers.find((passenger) => passenger.id === passengerId)

  if (passenger) {
    return getPassengerName(passenger)
  }
  return null
}

export const getOrderTicketsWithPassengerNames = (
  orderDocuments: DuffelAPI.Types.OrderDocuments,
  orderPassengers: DuffelAPI.Types.OrderPassenger[]
): OrderTicketWithPassengerName[] => {
  const orderPassengerTickets = orderDocuments.filter(
    (document) => document.type === 'electronic_ticket'
  )

  return orderPassengerTickets.map(({ passengerIds, uniqueIdentifier }) => {
    return {
      uid: uniqueIdentifier,
      passengerName:
        // Temporary guard
        passengerIds && passengerIds.length > 0
          ? getOrderPassengerNameFromId(
              /**
               * We can assume that each ticket will have a single passenger id.
               *
               * Relevant slack thread: https://duffel.slack.com/archives/C013XQ3T2J1/p1656923579829529?thread_ts=1656921567.378359&cid=C013XQ3T2J1
               */
              passengerIds[0] ?? 0,
              orderPassengers
            )
          : null,
    }
  })
}
