import { FormikHelpers } from 'formik'
import { NextRouter } from 'next/router'
import { UNKNOWN_ERROR_MESSAGE } from '@lib/constants'
import { ordersChangeResultsPathObject } from '@lib/paths'
import { trackEvent } from '@lib/tracking'
import { DuffelPermissions, APIResponseError, DuffelAPI } from '@lib/types'
import { createDuffelClient } from '@lib/duffel-client'
import { transformCreateOrderChangeRequestFormValuesIntoPayload } from './transform-create-order-change-request-form-values-into-payload'
import { CreateOrderChangeRequestFormValues } from './get-create-order-change-request-form-initial-values'

export const onCreateOrderChangeRequestFormSubmit = async (
  values: CreateOrderChangeRequestFormValues,
  { setSubmitting }: FormikHelpers<CreateOrderChangeRequestFormValues>,
  order: DuffelAPI.Types.Order,
  permissions: DuffelPermissions | null,
  routerPush: NextRouter['push'],
  handleErrors: (
    errors: APIResponseError[],
    requestId?: string
  ) => Promise<void>
) => {
  setSubmitting(true)
  trackEvent('dashboard_change_flights_request_form_search_button_clicked', {
    event_type: 'interaction',
    order_id: values.orderId,
  })

  const orderChangePayload =
    transformCreateOrderChangeRequestFormValuesIntoPayload(values, order)

  const response = await createDuffelClient(
    permissions?.organisation,
    permissions?.liveMode
  ).Air.createOrderChangeRequest(orderChangePayload)

  if (response.errors) {
    await handleErrors(response.errors, response.meta?.requestId)
    response.errors.map(({ message }) => {
      trackEvent(
        'dashboard_change_flights_request_form_search_alert_displayed',
        {
          event_type: 'alert',
          order_id: values.orderId,
          alert_message: message,
        }
      )
    })

    return setSubmitting(false)
  }

  if (!response.data) {
    const message = `${UNKNOWN_ERROR_MESSAGE} ${
      response.meta && response.meta.requestId
        ? `Request id: ${response.meta.requestId}`
        : ''
    }`
    handleErrors([], response.meta?.requestId)

    trackEvent('dashboard_change_flights_request_form_search_alert_displayed', {
      event_type: 'alert',
      order_id: values.orderId,
      alert_message: message,
    })

    return setSubmitting(false)
  }

  trackEvent('dashboard_change_flights_request_form_search_confirmed', {
    event_type: 'api',
    order_id: values.orderId,
    order_change_request_id: response.data.id,
  })

  await routerPush(
    ordersChangeResultsPathObject(
      permissions?.organisation,
      permissions?.liveMode,
      orderChangePayload.orderId,
      response.data.id
    ).as
  )
}
