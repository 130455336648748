import { DuffelAPI } from '@lib/types'

export const getPassengerName = (
  passenger:
    | DuffelAPI.Inputs.OfferRequestPassenger
    | DuffelAPI.Types.OrderPassenger
) => {
  const givenName = passenger.givenName ?? ''
  const familyName = passenger.familyName ?? ''

  if (givenName || familyName) {
    const delimiter = givenName && familyName ? ' ' : ''
    return `${givenName}${delimiter}${familyName}`
  }

  return null
}
