import * as React from 'react'
import { DatePickerWrapper } from './DatePickerWrapper'
import { FormDatePickerTarget } from './FormDatePickerTarget'
import { DatePickerProps } from './lib'

export interface FormDatePickerProps
  extends Omit<DatePickerProps, 'value' | 'onChange'> {
  value: Date | null
  onBlur?: (e: React.FocusEvent) => void
  onChange: (value: Date | null) => void
  disabled?: boolean
  shouldGoToNextTabbable?: boolean
}

export const FormDatePicker: React.FC<FormDatePickerProps> = ({
  onBlur,
  onChange,
  value,
  disabled,
  shouldGoToNextTabbable = true,
  ...datePickerProps
}) => {
  return (
    <DatePickerWrapper
      {...datePickerProps}
      value={value as Date}
      onChange={(value) => onChange(value as Date | null)}
      shouldGoToNextTabbable={shouldGoToNextTabbable}
      renderTarget={(targetProps) => (
        <FormDatePickerTarget
          {...targetProps}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
          shouldGoToNextTabbable={shouldGoToNextTabbable}
        />
      )}
    />
  )
}
