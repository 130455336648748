import { Divider } from '@components/Divider/Divider'
import { FormField, FormikSelect } from '@components/Form'
import { PopoverContainer } from '@components/PopoverContainer'
import { SelectTarget } from '@components/SelectTarget'
import { VSpace } from '@components/VSpace'
import { goToNextTabbable } from '@lib/helpers'
import { useDuffelPopper } from '@lib/hooks'
import { DuffelAPI } from '@lib/types'
import classNames from 'classnames'
import { useField, useFormikContext } from 'formik'
import * as React from 'react'
import { CommonSearchFormSectionValues } from '../../CommonSearchFormSection'
import { PassengerSelectCounter } from './PassengerSelectCounter'
import styles from './PassengersSelect.module.css'
import { getPassengerCountAllowedRanges } from './get-passenger-count-allowed-ranges'
import {
  PassengerType,
  getChangeType,
  getGenericPassengerAgeError,
  getPassengerError,
  getPassengerIndex,
  getUpdatedPassengerArray,
} from './lib'
import { splitPassengersByKind } from './lib/split-passengers-by-kind'
import { renderPassengerSelectLabel } from './render-passenger-select-label'

interface PassengersSelectProps {
  onChange: (value: DuffelAPI.Inputs.OfferRequestPassenger[]) => void
  disabled?: boolean
}

export const PassengersSelect: React.FC<PassengersSelectProps> = ({
  onChange,
  disabled,
}) => {
  const [{ value }, { touched, error }] = useField({ name: 'passengers' })
  const { submitCount, isSubmitting } =
    useFormikContext<CommonSearchFormSectionValues>()
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
  const isDisabled = isSubmitting || disabled

  const onOpenPopover = () => setIsPopoverOpen(true)
  const onClosePopover = () => setIsPopoverOpen(false)

  const { popper, setReferenceElement, setPopperElement } = useDuffelPopper(
    isPopoverOpen,
    onClosePopover,
    {
      placement: 'bottom-start',
      modifiers: [
        { name: 'offset', options: { offset: [0, 8] } },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top', 'left'],
          },
        },
      ],
    },
    { shouldInsideClickClose: false }
  )

  const [adults, children] = splitPassengersByKind(value)

  const currentAdultCount = adults.length
  const currentChildCount = children.length

  const countRange = getPassengerCountAllowedRanges(value)
  const id = 'passengers-select'

  return (
    <FormField
      disabled={disabled}
      htmlFor={id}
      label="Passengers"
      optionalField
      error={getGenericPassengerAgeError({ touched, error }, { submitCount })}
    >
      <SelectTarget
        id={id}
        data-testid={id}
        type="button"
        className={classNames({ active: isPopoverOpen })}
        ref={setReferenceElement}
        onClick={onOpenPopover}
        disabled={isDisabled}
        onKeyDown={(event) => {
          if (event.key === 'Tab') {
            if (event.shiftKey) onClosePopover()
            else {
              event.preventDefault()

              const minusButton = document.querySelector<HTMLButtonElement>(
                '#passenger-adults-select-minus'
              )
              if (minusButton && !minusButton.disabled)
                return minusButton.focus()

              const plusButton = document.querySelector<HTMLButtonElement>(
                '#passenger-adults-select-plus'
              )
              if (plusButton) return plusButton.focus()

              goToNextTabbable(id)
            }
          }
        }}
      >
        {renderPassengerSelectLabel(value)}
      </SelectTarget>
      {isPopoverOpen && (
        <PopoverContainer
          ref={setPopperElement}
          style={{
            width: '280px',
            padding: '16px',
            ...popper.styles.popper,
          }}
          {...popper.attributes}
        >
          <PassengerSelectCounter
            id="passenger-adults-select"
            label="Adults"
            hint="18+"
            count={adults.length}
            min={countRange.adults.min}
            max={countRange.adults.max}
            onChange={(newCount) => {
              const changeType = getChangeType(newCount, currentAdultCount)
              return onChange(
                getUpdatedPassengerArray(
                  { adults, children },
                  { changeType, passengerType: PassengerType.Adult }
                )
              )
            }}
          />

          <Divider />

          <PassengerSelectCounter
            id="passenger-children-select"
            label="Children"
            hint="0—17"
            count={children.length}
            min={countRange.children.min}
            max={countRange.children.max}
            onChange={(newCount) => {
              const changeType = getChangeType(newCount, currentChildCount)
              return onChange(
                getUpdatedPassengerArray(
                  { adults, children },
                  { changeType, passengerType: PassengerType.Child }
                )
              )
            }}
            onTab={() => {
              if (children.length === 0) {
                onClosePopover()
                goToNextTabbable(id)
              }
            }}
          />

          {children.length > 0 && (
            <VSpace space={16} className={styles['child-age-selector']}>
              {children.map((pax, paxIndex) => (
                <FormikSelect
                  label={`Age of child ${paxIndex + 1}`}
                  name={`passengers[${getPassengerIndex(value, pax)}].age`}
                  key={`pax-${paxIndex}`}
                  error={getPassengerError(
                    getPassengerIndex(value, pax),
                    error
                  )}
                  onKeyDown={(event) => {
                    if (
                      event.key === 'Tab' &&
                      !event.shiftKey &&
                      paxIndex + 1 === children.length
                    ) {
                      event.preventDefault()
                      onClosePopover()
                      goToNextTabbable(id)
                    }
                  }}
                >
                  <option value="-1" hidden>
                    Select age
                  </option>
                  {new Array(18).fill(0).map((_, ageIndex) => (
                    <option key={ageIndex}>{ageIndex}</option>
                  ))}
                </FormikSelect>
              ))}

              <p>
                A child's age must be valid for the full duration of journey.
                For example, if a child has a birthday during a trip please use
                their age on the date of the returning flight.
              </p>
            </VSpace>
          )}
        </PopoverContainer>
      )}
    </FormField>
  )
}
