import * as React from 'react'
import { AirlineLogo } from '@components/AirlineLogo'
import { KeyValueItem } from '@components/Item/KeyValueItem'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { getDateString, getTimeString } from '@lib/date'
import { DuffelAPI } from '@lib/types'
import { getSeatsOnTheOrder } from '@modules/air-order/helpers/get-seats-on-the-order'
import { OrderPassengerAncillaries } from '../OrderPassengerAncillaries'
import styles from './OrderPassengerFlightInformation.module.css'

export type OrderServicesBaggages = {
  [segmentId: string]: DuffelAPI.Types.OrderSegmentPassengerBaggage[]
}
export interface OrderPassengerFlightInformationProps {
  segments: { [segmentId: string]: DuffelAPI.Types.OrderSliceSegment }
  baggages: OrderServicesBaggages
  seats: DuffelAPI.Types.OrderService[]
}

export const OrderPassengerFlightInformation = React.forwardRef<
  HTMLDivElement,
  OrderPassengerFlightInformationProps
>(function OrderPassengerFlightInformation(
  { segments, baggages, seats },
  ref?
) {
  if (Object.keys(segments).length === 0) return null

  return (
    <div className={styles['order-passenger-services']} ref={ref}>
      <KeyValueItem label="Flight information" space={8}>
        {Object.keys(segments).map((segmentId) => (
          <VSpace
            space={12}
            key={segmentId}
            className={styles['order-passenger-services__container']}
          >
            <div
              className={
                styles['order-passenger-flight-information__container']
              }
            >
              <AirlineLogo
                name={segments[segmentId].marketingCarrier.name}
                iataCode={segments[segmentId].marketingCarrier.iataCode}
              />
              <div className={styles['order-passenger-services-segment']}>
                <Text color="grey-900">
                  <Text color="grey-900" fontWeight="medium" asElement="strong">
                    {segments[segmentId].origin.iataCode}
                  </Text>{' '}
                  to{' '}
                  <Text color="grey-900" fontWeight="medium" asElement="strong">
                    {segments[segmentId].destination.iataCode}
                  </Text>{' '}
                  on{' '}
                  <Text color="grey-900" fontWeight="medium" asElement="strong">
                    {getDateString(segments[segmentId].departingAt, 'long')}
                  </Text>{' '}
                  at{' '}
                  <Text color="grey-900" fontWeight="medium" asElement="strong">
                    {getTimeString(segments[segmentId].departingAt)}
                  </Text>
                </Text>
              </div>
            </div>
            {(getSeatsOnTheOrder(seats, segmentId) || baggages[segmentId]) && (
              <OrderPassengerAncillaries
                seats={getSeatsOnTheOrder(seats, segmentId)}
                baggages={baggages}
                segmentId={segmentId}
              />
            )}
          </VSpace>
        ))}
      </KeyValueItem>
    </div>
  )
})
