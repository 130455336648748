import { AirlineLogo } from '@components/AirlineLogo'
import { CopyableValue } from '@components/CopyableValue'
import { AIC_BANNER_ORDER_INDEX_PAGE } from '@components/FlightSummary/lib'
import { Notice } from '@components/Notice'
import { Spinner } from '@components/Spinner'
import { TabOption } from '@components/TabButtons'
import { Table, TableCell, TableRow } from '@components/Table'
import { TableHeader } from '@components/Table/TableHeader'
import { Text } from '@components/Text'
import { ORDERS_REQUEST_LIMIT } from '@lib/constants'
import { getDateString } from '@lib/date'
import { getPassengersName } from '@lib/duffel-client'
import { trackEvent } from '@lib/tracking'
import { DuffelAPI, PaginationMeta } from '@lib/types'
import classNames from 'classnames'
import * as React from 'react'
import { OrderStatusStamp } from '../components'
import { getOrderStatus } from '../helpers'
import {
  OrderSortedBy,
  updateSortStatus,
} from '../helpers/handle-order-sorting'
import styles from './OrdersTable.module.css'
import { CsvDownloadNoticeModal } from '@components/CsvDownloadModal'
import { downloadOrdersDataAsCSV } from '../lib/download-orders-data'
import { useWorkspace } from '@lib/workspace-context'
import { FilterValue } from '@components/Filters/lib/types'
import { useRouter } from 'next/router'
import { getDuffelAPIClient } from '@lib/duffel-api/getDuffelAPIClient'
import { getNextDeparture } from '../helpers/get-next-departure'
import { DashboardOrder } from '../lib/types'

export const ordersTabs = [
  'onHold',
  'actionRequired',
  'pending',
  'all',
] as const

export type OrdersTab = (typeof ordersTabs)[number]

export type PartialOrdersByTab = Record<OrdersTab, Partial<DashboardOrder>[]>

export type PaginationMetaByTab = Record<OrdersTab, PaginationMeta>

export const getTabButtonsOptions = (
  actionRequiredOrdersCount: number
): Record<OrdersTab, TabOption> => ({
  all: {
    label: 'All',
  },
  actionRequired: {
    label: 'Needs review',
    numberOfItems: `${actionRequiredOrdersCount}${
      actionRequiredOrdersCount === ORDERS_REQUEST_LIMIT ? '+' : ''
    }`,
    intent: 'danger',
  },
  onHold: {
    label: 'On hold',
  },
  pending: {
    label: 'Pending',
  },
})

export const renderAICNotice = (
  actionRequiredOrdersCount: number,
  activeTab: OrdersTab,
  onTabChange: (activeTab: OrdersTab) => void
) => {
  if (actionRequiredOrdersCount > 0) {
    return activeTab === 'actionRequired' ? (
      <Notice intent="warning" hideIcon data-selector="fs-show">
        {AIC_BANNER_ORDER_INDEX_PAGE}
      </Notice>
    ) : (
      <Notice
        type="action"
        intent="warning"
        onClickActionButton={() => {
          trackEvent(
            'dashboard_orders_airline_initiated_change_notice_view_affected_orders_clicked',
            {
              event_type: 'interaction',
            }
          )
          onTabChange('actionRequired')
        }}
        actionButtonText="View affected orders"
      >
        {AIC_BANNER_ORDER_INDEX_PAGE}
      </Notice>
    )
  }
}

export const OrdersCsvDownloadNoticeModal: React.FC<{
  setIsCsvDownloadModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  activeFilters: FilterValue[]
}> = ({ setIsCsvDownloadModalOpen, activeFilters }) => {
  const { addToast } = useWorkspace()
  const { query } = useRouter()

  const download = async () => {
    const client = getDuffelAPIClient(
      undefined,
      undefined,
      query.org as string,
      query.mode as 'live' | 'test'
    )
    downloadOrdersDataAsCSV(addToast, client, activeFilters)
  }

  return (
    <CsvDownloadNoticeModal
      onConfirmDownload={() => {
        download()
        setIsCsvDownloadModalOpen(false)
      }}
      onClose={() => setIsCsvDownloadModalOpen(false)}
    />
  )
}

export const OrdersTable: React.FC<{
  isLoading: boolean
  currentSort: OrderSortedBy
  onSortChange: (sortOption: OrderSortedBy) => void
  data: DuffelAPI.Types.Order[]
  onOrderClick: (orderId: string) => void
  showAgencyInformation?: boolean
}> = ({
  currentSort,
  onSortChange,
  isLoading,
  data,
  onOrderClick,
  showAgencyInformation = false,
}) => (
  <div className={styles['table-container']}>
    <Table>
      <thead>
        <TableRow>
          <TableHeader>Airline</TableHeader>
          <TableHeader>Booking ref</TableHeader>
          <TableHeader>Status</TableHeader>
          {showAgencyInformation && <TableHeader>Merchant</TableHeader>}
          {showAgencyInformation && <TableHeader>Agency</TableHeader>}
          <TableHeader>ID</TableHeader>
          <TableHeader>Family Names</TableHeader>
          <TableHeader
            width="200px"
            sortMetadata={{
              onClick: () =>
                onSortChange({
                  next_departure: updateSortStatus(
                    currentSort['next_departure']
                  ),
                }),
              sortStatus: currentSort['next_departure'] || 'inactive',
            }}
          >
            Next departure
          </TableHeader>
          <TableHeader
            width="128px"
            sortMetadata={{
              onClick: () =>
                onSortChange({
                  total_amount: updateSortStatus(currentSort['total_amount']),
                }),
              sortStatus: currentSort['total_amount'] || 'inactive',
            }}
          >
            Amount
          </TableHeader>
          <TableHeader
            width="135px"
            sortMetadata={{
              onClick: () =>
                onSortChange({
                  created_at: updateSortStatus(currentSort['created_at']),
                }),
              sortStatus: currentSort['created_at'] || 'inactive',
            }}
          >
            Creation Date
          </TableHeader>
        </TableRow>
      </thead>
      {!isLoading && (
        <tbody>
          {data.map((order) => {
            const orderStatus = getOrderStatus(order)

            return (
              <TableRow
                className={classNames('table-row', styles['table-row'])}
                key={order.id}
                onClick={() => {
                  trackEvent('dashboard_orders_table_order_clicked', {
                    order_id: order.id,
                    event_type: 'interaction',
                  })
                  onOrderClick(order.id)
                }}
              >
                <TableCell data-selector="fs-show">
                  {order.owner && (
                    <div className={styles['airline-logo-container']}>
                      <AirlineLogo
                        name={order.owner.name}
                        iataCode={order.owner.iataCode}
                        size={24}
                      />
                      <label>{order.owner.iataCode}</label>
                    </div>
                  )}
                </TableCell>

                <TableCell className={styles['table-cell-with-copy']}>
                  <CopyableValue
                    valueToCopy={order.bookingReference}
                    stopEventPropagationOnClick
                    iconSize="small"
                  >
                    {order.bookingReference}
                  </CopyableValue>
                </TableCell>

                <TableCell data-selector="fs-show">
                  <OrderStatusStamp status={orderStatus} />
                </TableCell>

                {showAgencyInformation && (
                  <TableCell
                    data-selector="fs-show"
                    className={styles['table-cell-no-wrap']}
                  >
                    {(order as DashboardOrder).organisation.name}
                  </TableCell>
                )}

                {showAgencyInformation && (
                  <TableCell
                    data-selector="fs-show"
                    className={styles['table-cell-no-wrap']}
                  >
                    {(order as DashboardOrder).agreement.agency.name}
                  </TableCell>
                )}

                <TableCell className={styles['table-cell-with-copy']}>
                  <CopyableValue
                    valueToCopy={order.id}
                    stopEventPropagationOnClick
                    iconSize="small"
                  >
                    <span
                      className={styles['order-id']}
                      data-testid="orders-table__id"
                    >
                      {order.id}
                    </span>
                  </CopyableValue>
                </TableCell>

                <TableCell>
                  {getPassengersName(order.passengers) || 'Unknown'}
                </TableCell>
                <TableCell
                  data-selector="fs-show"
                  className={styles['table-cell-no-wrap']}
                >
                  <span className="orders-table__departure-date">
                    {[
                      'confirmed',
                      'cancelled',
                      'needs-review',
                      'pending',
                    ].includes(orderStatus)
                      ? getNextDeparture(order)
                      : '—'}
                  </span>
                </TableCell>
                <TableCell
                  className={styles['table-cell-no-wrap']}
                >{`${order.totalCurrency} ${order.totalAmount}`}</TableCell>
                <TableCell>{getDateString(order.createdAt, 'short')}</TableCell>
              </TableRow>
            )
          })}
        </tbody>
      )}
    </Table>
    {isLoading && (
      <div className={styles['spinner-wrapper']}>
        <Spinner />
      </div>
    )}
    {!isLoading && data.length === 0 && (
      <Text
        className="u-marginTop16"
        textAlign="center"
        data-selector="fs-show"
      >
        Couldn't find any orders matching your criteria
      </Text>
    )}
  </div>
)
