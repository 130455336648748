import { APIResponse, APIResponseError } from '@lib/types'

const getDefaultObject = <T = any>(): APIResponse<T> => ({
  meta: {
    status: 200,
    requestId: 'sample-request-id',
  },
  errors: undefined,
  data: { status: 'ok' } as unknown as T,
})

export const makeMockAPIResponse = <T = any>(
  extendDefault?: Partial<APIResponse<T>>
): APIResponse<T> =>
  Object.assign({}, getDefaultObject<T>(), extendDefault || {})

export const makeMockAPIResponseWithData = <T>(data: T): APIResponse<T> =>
  Object.assign({}, getDefaultObject<T>(), {
    data,
    errors: undefined,
  })

const withErrorsDefault: APIResponseError[] = [
  {
    title: 'Mock error',
    code: 'mock_error_received',
    message: 'This is a mock error message.',
  },
]

export const makeMockAPIResponseWithErrors = <T = any>(
  withErrors = withErrorsDefault
): APIResponse<T> =>
  Object.assign({}, getDefaultObject<T>(), {
    errors: withErrors,
    data: undefined,
    meta: { status: '500', requestId: 'sample-request-id' },
  })
