import { DuffelAPI } from '@lib/types'
import {
  makeMockDateInTheFuture,
  makeMockDateInThePast,
} from './make-mock-date'
import { makeMockOrderChangeRequestOfferSlices } from './make-mock-order-change-request-offer-slices'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.OrderChangeRequestOffer => {
    const id = (seedId++).toString().padStart(5, '0')
    return {
      id: `oco_${id}`,
      updatedAt: makeMockDateInThePast(1).toISOString(),
      refundTo: 'original_form_of_payment',
      penaltyTotalCurrency: 'GBP',
      penaltyTotalAmount: '25.00',
      newTotalCurrency: 'GBP',
      newTotalAmount: '25.00',
      liveMode: false,
      expiresAt: makeMockDateInTheFuture(5).toISOString(),
      createdAt: makeMockDateInThePast(2).toISOString(),
      changeTotalCurrency: 'GBP',
      changeTotalAmount: '50.00',
      slices: makeMockOrderChangeRequestOfferSlices(),
      orderChangeId: null,
    }
  }
})()

export const makeMockOrderChangeRequestOffer = (
  extendDefault?: Partial<DuffelAPI.Types.OrderChangeRequestOffer>
): DuffelAPI.Types.OrderChangeRequestOffer =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
