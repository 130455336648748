import * as React from 'react'
import { FlightSummary } from '@components/FlightSummary'
import { VSpace } from '@components/VSpace'
import { DuffelAPI, RandomlyGeneratedPassenger } from '@lib/types'
import { CheckoutFormContainer } from '../components/Checkout/CheckoutFormContainer'
import { CheckoutHeader } from '../components/Checkout/CheckoutHeader'
import { CheckoutContextProvider } from '../lib/use-checkout-context'
import {
  CheckoutDivider,
  CheckoutSection,
} from '../components/Checkout/CheckoutLayout'

interface SearchOfferContainerProps {
  offer: DuffelAPI.Types.Offer
  partialOfferRequest: DuffelAPI.Types.OfferRequest
  seatMaps?: DuffelAPI.Types.SeatMap[]
  randomlyGeneratedPassengers?: RandomlyGeneratedPassenger[]
  componentClientKey?: string
}

export const OfferCheckoutContainer: React.FC<SearchOfferContainerProps> = ({
  partialOfferRequest,
  offer,
  seatMaps,
  randomlyGeneratedPassengers,
  componentClientKey,
}) => {
  const [paymentButtonClickCount, setPaymentButtonClickCount] =
    React.useState(0)
  const [paymentType, setPaymentType] = React.useState('instant')
  const incrementPaymentButtonClickCount = () =>
    setPaymentButtonClickCount(paymentButtonClickCount + 1)

  const [isOfferExpired, setIsOfferExpired] = React.useState(
    new Date().getTime() > new Date(offer.expiresAt).getTime()
  )

  React.useEffect(() => {
    const expiresAtTime = new Date(offer.expiresAt).getTime()
    const nowTime = new Date().getTime()
    if (expiresAtTime > nowTime) {
      const timeout = setTimeout(() => {
        setIsOfferExpired(true)
      }, expiresAtTime - nowTime)
      return () => clearTimeout(timeout)
    }
  }, [offer.expiresAt])

  return (
    <CheckoutContextProvider
      value={{
        partialOfferRequest,
        offer,
        seatMaps,
        randomlyGeneratedPassengers,
        paymentButtonClickCount,
        incrementPaymentButtonClickCount,
        paymentType,
        setPaymentType,
      }}
    >
      <VSpace space={40}>
        <CheckoutHeader
          partialOfferRequest={partialOfferRequest}
          offer={offer}
        />

        <CheckoutDivider />

        <CheckoutSection heading="Selected flights">
          <FlightSummary flight={offer} isExpanded showFullDate />
        </CheckoutSection>

        <CheckoutDivider />

        <CheckoutFormContainer
          componentClientKey={componentClientKey}
          isOfferExpired={isOfferExpired}
        />
      </VSpace>

      <style jsx>{`
        :global(.page-header) {
          padding-bottom: var(--space-16) !important;
        }
      `}</style>
    </CheckoutContextProvider>
  )
}
