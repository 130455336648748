import { Field, FieldInputProps, FormikProps, FormikValues } from 'formik'
import * as React from 'react'
import { FileUpload } from '@components/FileUpload'
import { FormFieldProps } from './FormField'

export interface FormikFileUploadProps extends FormFieldProps {
  /**
   * The name of the field used by Formik
   */
  name: string
  label: string
  multiple?: boolean | undefined
  requestHandler: (
    attachment: AttachmentProps,
    method: 'POST' | 'DELETE'
  ) => Promise<AttachmentProps>
  uploadSizeLimit?: number
}
export interface AttachmentProps {
  filename: string
  type: string
  binary?: string | undefined
  id?: string | undefined
  error?: boolean | undefined
}

export const FormikFileUpload: React.FC<FormikFileUploadProps> = ({
  name,
  label,
  multiple,
  requestHandler,
  uploadSizeLimit = 5000000,
}) => {
  const [attachmentsState, setAttachments] = React.useState<
    Array<AttachmentProps>
  >([])
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const readAttachment = (file: File) => {
    return new Promise<AttachmentProps>((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = (event) => {
        if (event.loaded == event.total) {
          return resolve({
            filename: file.name as string,
            binary: reader.result as string,
            type: file.type as string,
          })
        }
      }
      reader.readAsDataURL(file)
      reader.onerror = reject
    })
  }

  const filesListHandle = async (
    form: FormikProps<FormikValues>,
    field: FieldInputProps<any>,
    customFilesList: FileList
  ) => {
    const mapAttachments = Array.from(customFilesList).map(
      async (currentFile) => {
        if (currentFile.size > uploadSizeLimit) {
          return {
            filename: currentFile.name,
            type: currentFile.type,
            error: true,
            id: undefined,
          }
        }
        const attachment = await readAttachment(currentFile)
        return requestHandler(attachment, 'POST')
      }
    )

    const attachments = await Promise.all(mapAttachments)

    if (attachments) {
      const mutateFileListState = [...attachmentsState, ...attachments]
      setAttachments(mutateFileListState)
      const filesTokens = mutateFileListState.map((file) => file?.id)
      form.setFieldValue(field.name, filesTokens)
    }
    setIsSubmitting(false)
  }

  const onFileUploadHandle = (
    event: React.ChangeEvent<HTMLInputElement>,
    form: FormikProps<FormikValues>,
    field: FieldInputProps<any>
  ) => {
    const { target } = event
    const files = target.files
    if (!files) return

    return filesListHandle(form, field, files)
  }

  const reUploadFile = async (
    form: FormikProps<FormikValues>,
    field: FieldInputProps<any>,
    name: string
  ) => {
    const mutateFileListState = [...attachmentsState]
    const file = mutateFileListState.find((file) => file.filename === name)
    if (!file) return false

    const attachment = await requestHandler(file, 'POST')

    if (attachment) {
      const fileIndex = mutateFileListState.findIndex(
        (state) => state.filename === attachment?.filename
      )
      if (fileIndex > -1) {
        mutateFileListState[fileIndex] = attachment
        setAttachments(mutateFileListState)
      }
    }

    const filesTokens = mutateFileListState.map((file) => file?.id)
    form.setFieldValue(field.name, filesTokens)
    setIsSubmitting(false)
  }

  const removeFileFromUpload = async (
    form: FormikProps<FormikValues>,
    field: FieldInputProps<any>,
    id: string
  ) => {
    const mutateFileListState = [...attachmentsState]
    const fileIndex = mutateFileListState?.findIndex((file) => file.id === id)
    if (fileIndex > -1) {
      mutateFileListState.splice(fileIndex, 1)
      setAttachments(mutateFileListState)

      const filesTokens = mutateFileListState.map((file) => file?.id)
      form.setFieldValue(field.name, filesTokens)
    }
  }

  return (
    <Field
      id={name}
      name={name}
      multiple={multiple}
      htmlFor={name}
      label={label}
    >
      {({ field, form }) => (
        <FileUpload
          label={label}
          name={name}
          filesList={attachmentsState}
          multiple={multiple}
          isSubmitting={isSubmitting}
          onHandleRefresh={(name) => {
            setIsSubmitting(true)
            return reUploadFile(form, field, name)
          }}
          onClickHandle={(id) => removeFileFromUpload(form, field, id)}
          onChangeHandle={(event: React.ChangeEvent<HTMLInputElement>) => {
            setIsSubmitting(true)
            return onFileUploadHandle(event, form, field)
          }}
        />
      )}
    </Field>
  )
}
