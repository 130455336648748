import { DuffelAPI } from '@lib/types'
import {
  getDefaultAdultPassenger,
  getDefaultChildPassenger,
} from '../../../lib/get-default-passenger'
import { ChangeOptions, PassengersSortedByType } from './types'

/**
 * Creates the new CommonSearchFormPassenger[] if the adults / children
 * passenger array is modified
 */
export const getUpdatedPassengerArray = (
  { adults, children }: PassengersSortedByType,
  { changeType, passengerType }: ChangeOptions
): DuffelAPI.Inputs.OfferRequestPassenger[] => {
  let updatedAdults
  let updatedChildren

  switch (changeType) {
    case 'add':
      updatedAdults =
        passengerType === 'adult'
          ? [...adults, getDefaultAdultPassenger()]
          : adults
      updatedChildren =
        passengerType === 'child'
          ? [...children, getDefaultChildPassenger()]
          : children
      break
    case 'remove':
      updatedAdults =
        passengerType === 'adult' ? [...adults.slice(0, -1)] : adults
      updatedChildren =
        passengerType === 'child' ? [...children.slice(0, -1)] : children
      break
    default:
      updatedAdults = adults
      updatedChildren = children
  }

  return [...updatedAdults, ...updatedChildren]
}
