import { DateRange, safelyGetDateRange } from '@components/DateRangePicker'
import { getDateString } from './get-date-string'
import { DateTimeDisplayFormat } from './get-date-string'

export const getDateRangeString = (
  dateRange: DateRange | null,
  format: DateTimeDisplayFormat,
  locale = 'en-GB'
) => {
  if (!dateRange) return ''

  const { start, end } = safelyGetDateRange(dateRange)
  const startStr = getDateString(start, format, locale)
  const endStr = getDateString(end, format, locale)

  if (!startStr && !endStr) return ''
  if (startStr && !endStr) return `Starts ${startStr}`
  if (!startStr && endStr) return `Starts ${endStr}`

  return `${startStr} - ${endStr}`
}
