import { DuffelAPI } from '@lib/types'
import { getLatestAIC } from './get-latest-aic'
import { isOrderCancelled } from './is-order-cancelled'
import { isOrderInThePast } from './is-order-in-the-past'

export const ORDER_STATUSES = [
  'cancelled',
  'on-hold',
  'confirmed',
  'needs-review',
  'pending',
  'past',
  'expired',
] as const

export type OrderStatus = (typeof ORDER_STATUSES)[number]

export const getOrderStatus = (order: DuffelAPI.Types.Order): OrderStatus => {
  // On the new orders pages, we compute the status server side and attach it to the order
  if ('status' in order) {
    return order.status as OrderStatus
  }

  if (isOrderCancelled(order)) {
    return 'cancelled'
  }

  if (order.paymentStatus && order.paymentStatus.awaitingPayment) {
    if (isOrderInThePast(order)) {
      return 'expired'
    }

    return 'on-hold'
  }

  // if the order is not awaiting payment but yet nothing is paid, consider it expired
  if (order.paymentStatus && !order.paymentStatus.paidAt) {
    return 'expired'
  }

  if (
    order.airlineInitiatedChanges?.length &&
    order.airlineInitiatedChanges?.length > 0
  ) {
    const latestAIC = getLatestAIC(order)

    if (latestAIC) {
      const { actionTaken, travelAgentTicket } = latestAIC

      if (actionTaken === 'cancelled') {
        return 'cancelled'
      }

      if (isOrderInThePast(order)) {
        return 'past'
      }

      if (actionTaken === null) {
        if (travelAgentTicket) {
          return 'pending'
        }

        return 'needs-review'
      }

      return 'confirmed'
    }
  }

  if (isOrderInThePast(order)) {
    return 'past'
  }

  return 'confirmed'
}
