import classNames from 'classnames'
import * as React from 'react'
import { Portal } from '@components/Portal'
import styles from './PopoverContainer.module.css'

export interface PopoverContainerProps {
  id?: string
  style?: React.CSSProperties
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  className?: string
}

export const PopoverContainer = React.forwardRef<
  HTMLDivElement,
  PopoverContainerProps & { children: React.ReactNode }
>(({ children, style, id, className, ...props }, ref) => {
  const popoverContainerClassNames = classNames(
    className,
    styles['popover-container']
  )
  return (
    <Portal>
      <div
        className={popoverContainerClassNames}
        id={id}
        data-testid={id}
        style={style}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    </Portal>
  )
})
