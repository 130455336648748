import * as React from 'react'
import { VSpace } from '@components/VSpace'
import { DuffelAPI } from '@lib/types'
import { combineBaggages } from '@modules/air-order/helpers/combine-baggages'
import { OrderPassengerCard } from '../OrderPassengerCard'

export interface OrderPassengerSummaryProps {
  order: DuffelAPI.Types.Order
}

const filterPassengersByType = (
  passengers: DuffelAPI.Types.OrderPassenger[],
  filterType: DuffelAPI.Types.OrderPassenger['type']
) => {
  return passengers.filter(({ type }) => type === filterType)
}

export const OrderPassengersSummary: React.FC<OrderPassengerSummaryProps> =
  function OrderPassengerSummary({ order }) {
    const adultPassengers = filterPassengersByType(order.passengers, 'adult')
    const childPassengers = filterPassengersByType(order.passengers, 'child')
    const infantPassengers = filterPassengersByType(
      order.passengers,
      'infant_without_seat'
    )
    const seats =
      order.services?.filter((service) => service.type === 'seat') || []
    const { segmentMap, combinedBaggages } = combineBaggages(order)

    return (
      <VSpace space={32}>
        {adultPassengers.map((adultPassenger, index) => (
          <OrderPassengerCard
            key={adultPassenger.id}
            passenger={adultPassenger}
            passengerTypeIndex={index + 1}
            baggages={combinedBaggages[adultPassenger.id]}
            segments={segmentMap}
            seats={seats.filter((seat) =>
              seat.passengerIds.includes(adultPassenger.id)
            )}
          />
        ))}
        {childPassengers.map((childPassenger, index) => (
          <OrderPassengerCard
            key={childPassenger.id}
            passenger={childPassenger}
            passengerTypeIndex={index + 1}
            baggages={combinedBaggages[childPassenger.id]}
            segments={segmentMap}
            seats={seats.filter((seat) =>
              seat.passengerIds.includes(childPassenger.id)
            )}
          />
        ))}
        {infantPassengers.map((infantPassenger, index) => (
          <OrderPassengerCard
            key={infantPassenger.id}
            passenger={infantPassenger}
            passengerTypeIndex={index + 1}
            baggages={combinedBaggages[infantPassenger.id]}
            segments={segmentMap}
            seats={seats.filter((seat) =>
              seat.passengerIds.includes(infantPassenger.id)
            )}
          />
        ))}
      </VSpace>
    )
  }
