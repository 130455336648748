import { forwardRef } from 'react'
import classNames from 'classnames'
import { Icon } from '@components/Icon'
import styles from './Checkbox.module.css'

export interface CheckboxProps {
  /**
   * Element ID
   */
  id: string

  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string

  /**
   * The label for the checkbox.
   * Though, this will not be displayed, it is required for a11y.
   */
  label: string

  /**
   * Effectively, the value for the checkbox component
   */
  checked: boolean

  /**
   * Callback for when a new tab option is selected
   */
  onChange: (value: boolean) => void

  /**
   * Size of the checkbox
   */
  size?: 'regular' | 'small'

  /**
   * intent of the checkbox
   */
  intent?: 'neutral' | 'danger'
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ checked, className, onChange, label, size, intent, ...props }, ref) => {
    return (
      <>
        <input
          type="check"
          ref={ref}
          className={styles['checkbox']}
          {...props}
        />
        <label className={styles['label']} htmlFor={props.id}>
          <button
            className={classNames(styles['checkbox-btn'], className, {
              [styles['checked']]: checked,
              [styles['small']]: size === 'small',
              [styles['danger']]: intent === 'danger',
            })}
            onClick={() => onChange(!checked)}
            type="button"
          >
            {checked && <Icon name="check" size={18} />}
          </button>
          <span className={styles['hidden']}>{label}</span>
        </label>
      </>
    )
  }
)
