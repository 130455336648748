import { DuffelAPI } from '@lib/types'
import { makeMockAirport } from './make-mock-airport'
import { makeMockOrderSliceSegment } from './make-mock-order-slice-segment'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.OrderSlice => {
    const id = (seedId++).toString().padStart(5, '0')
    return {
      segments: [makeMockOrderSliceSegment()],
      originType: 'airport',
      origin: makeMockAirport(),
      id: `sli_${id}`,
      duration: 'PT8H10M',
      destinationType: 'airport',
      destination: makeMockAirport(),
      conditions: {
        changeBeforeDeparture: null,
        refundBeforeDeparture: null,
      },
    }
  }
})()

export const makeMockOrderSlice = (
  extendDefault?: Partial<DuffelAPI.Types.OrderSlice>,
  segmentIds?: DuffelAPI.Types.OrderSliceSegment['id'][],
  passengerIds?: DuffelAPI.Types.OrderSegmentPassenger['passengerId'][]
): DuffelAPI.Types.OrderSlice => {
  const mockOrderSlice = Object.assign(
    {},
    getDefaultObject(),
    extendDefault || {}
  )

  if (segmentIds) {
    mockOrderSlice.segments = segmentIds.map((segmentId) =>
      makeMockOrderSliceSegment({}, segmentId, passengerIds)
    )
  }

  if (!segmentIds && passengerIds) {
    mockOrderSlice.segments = [
      makeMockOrderSliceSegment({}, undefined, passengerIds),
    ]
  }

  return mockOrderSlice
}
