import * as React from 'react'
import { DuffelAPI } from '@lib/types'
import { trackEvent } from '@lib/tracking'
import {
  PassengerLoyaltyAccountsFormHeader,
  PassengerLoyaltyAccountsFormDrawer,
  getLoyaltyProgrammeAccountsCount,
  OfferRequestPassengerWithIndex,
} from '.'

export interface PassengerLoyaltyAccountsFormProps {
  /**
   * This number will come from the Loyalty Codes container component and corresponds
   * to the index on the formik context passengers array this passenger is
   */
  passengerIndex: number

  /**
   * Passenger data from the search form
   */
  passenger: DuffelAPI.Inputs.OfferRequestPassenger

  /**
   * The passenger title
   */
  passengerTitle: string

  /**
   * Sets drawer initial state. IE - if set to true, will set drawer to open on initial load
   * Default: false
   */
  isOpenInitialState?: boolean
}

export const PassengerLoyaltyAccountsForm: React.FC<
  PassengerLoyaltyAccountsFormProps
> = ({
  passenger,
  passengerIndex,
  passengerTitle,
  isOpenInitialState = false,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(isOpenInitialState)

  const passengerWithIndex: OfferRequestPassengerWithIndex = {
    ...passenger,
    index: passengerIndex,
  }

  // count how many loyalty account have been added, which is defined by
  // having a loyalty programme selected and the account number filled out
  const accountsApplied = getLoyaltyProgrammeAccountsCount(
    passengerWithIndex.loyaltyProgrammeAccounts
  )

  const toggleDrawer = (event: React.SyntheticEvent) => {
    event.preventDefault()
    setIsDrawerOpen(!isDrawerOpen)
    trackEvent('dashboard_loyalty_accounts_input_clicked', {
      event_type: 'interaction',
    })
  }

  return (
    <section
      className="loyalty-accounts-form__container"
      data-testid="loyalty-accounts-form__container"
    >
      <PassengerLoyaltyAccountsFormHeader
        onClick={toggleDrawer}
        title={passengerTitle}
        isDrawerOpen={isDrawerOpen}
        accountsApplied={accountsApplied}
      />
      {isDrawerOpen && (
        <PassengerLoyaltyAccountsFormDrawer passenger={passengerWithIndex} />
      )}
      <style jsx>{`
        .loyalty-accounts-form__container {
          border: 1px solid var(--grey-200);
          border-radius: var(--border-radius-6);
          margin-top: var(--space-8);
        }
      `}</style>
    </section>
  )
}
