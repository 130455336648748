import { MS_PER_DAY, MS_PER_HOUR, MS_PER_MINUTE } from './constants'

const relativeTimeFormat = {
  localeMatcher: 'best fit' as const,
  numeric: 'always' as const,
  style: 'long' as const,
}

const getRelativeDateTime = (original: Date | string | null) => {
  if (!original) return null
  const currentDate = Date.now()
  const date = typeof original === 'string' ? new Date(original) : original
  const diff = date.valueOf() - currentDate
  const month = Math.abs(diff / (30 * MS_PER_DAY))

  return {
    year: month >= 12 ? Math.round(diff / (365 * MS_PER_DAY)) : 0,
    month: month >= 1 ? Math.round(diff / (30 * MS_PER_DAY)) : 0,
    week: Math.round(diff / (7 * MS_PER_DAY)),
    day: Math.round(diff / MS_PER_DAY),
    hour: Math.round(diff / MS_PER_HOUR),
    minute: Math.round(diff / MS_PER_MINUTE),
    second: Math.round(diff / 1000),
  }
}

export const getRelativeDateString = (
  original: Date | string | null,
  locale = 'en-GB'
) => {
  const dateTime = getRelativeDateTime(original)
  if (!dateTime) {
    return null
  }

  for (const [key, value] of Object.entries(dateTime)) {
    if (value !== 0) {
      return new Intl.RelativeTimeFormat(locale, relativeTimeFormat).format(
        value,
        key as keyof typeof dateTime
      )
    }
  }

  return ''
}

export const getRelativeDateParts = (
  original: Date | string | null,
  locale = 'en-GB'
) => {
  const dateTime = getRelativeDateTime(original)
  if (!dateTime) {
    return []
  }

  for (const [key, value] of Object.entries(dateTime)) {
    if (value !== 0) {
      return new Intl.RelativeTimeFormat(
        locale,
        relativeTimeFormat
      ).formatToParts(value, key as keyof typeof dateTime)
    }
  }

  return []
}
