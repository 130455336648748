import { FormikCountrySelect } from '@components/CountrySelect'
import { FormikDatePicker } from '@components/DatePicker'
import { FormikInput, FormikSelect } from '@components/Form'
import { Notice } from '@components/Notice'
import { Stamp } from '@components/Stamp'
import { VSpace } from '@components/VSpace'
import { PassengerGenderMap, PassengerTitleMap } from '@lib/types'
import { CheckoutSubSection } from '@modules/air-search-v2/components/Checkout/CheckoutLayout'
import { CheckoutFormValuesPassenger } from '@modules/air-search-v2/lib'
import { useCheckoutContext } from '@modules/air-search-v2/lib/use-checkout-context'
import * as React from 'react'
import { SplitFormFields } from '../../SearchForm/FormFields'

interface PassengerInfoProps {
  passenger: CheckoutFormValuesPassenger
  index: number
  loyaltyAccountsApplied: boolean
}

// FIXME: Temporary loyalty programme map. Replace with merge of UXP-1721
export const LoyaltyProgrammeMap = {
  A3: 'Aegean Airlines',
  AA: 'American Airlines',
  AF: 'Air France',
  BA: 'British Airways',
  CM: 'Copa Airlines',
  EK: 'Emirates',
  KL: 'KLM',
  LH: 'Lufthansa',
  LX: 'Swiss',
  OA: 'Olympic Air',
  OS: 'Austrian',
  QF: 'Qantas',
  QR: 'Qatar Airways',
  SN: 'Brussels Airlines',
  UA: 'United',
  WS: 'WestJet',
  ZZ: 'Duffel Airways',
}

export const PassengerInfo: React.FC<PassengerInfoProps> = ({
  index,
  passenger,
  loyaltyAccountsApplied,
}) => {
  const { offer } = useCheckoutContext()

  const shouldRenderPassportFormSection =
    offer.passengerIdentityDocumentsRequired ||
    offer.supportedPassengerIdentityDocumentTypes.includes('passport')

  return (
    <div className="passenger-info-card">
      <VSpace space={24}>
        <VSpace space={16}>
          <Stamp
            label={passenger.passengerLabel}
            size="medium"
            color="grey"
            borderRadius="full"
          />
          {loyaltyAccountsApplied && (
            <Notice>
              This offer is affected by applied loyalty accounts. To edit this
              passenger's name or loyalty accounts, please go back a step.
            </Notice>
          )}
          <CheckoutSubSection heading="Personal details">
            <div className="passenger-info-name-container">
              <FormikSelect label="Title" name={`passengers.${index}.title`}>
                {Object.entries(PassengerTitleMap).map(([value, label]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </FormikSelect>
              <FormikInput
                label="Given name"
                name={`passengers.${index}.givenName`}
                disabled={loyaltyAccountsApplied}
              />
              <FormikInput
                label="Family name"
                name={`passengers.${index}.familyName`}
                disabled={loyaltyAccountsApplied}
              />
            </div>

            {loyaltyAccountsApplied &&
              passenger.loyaltyProgrammeAccounts?.map(
                (_loyaltyProgramme, loyaltyProgrammeIndex) => (
                  <SplitFormFields
                    key={`passenger-info-loyalty-programmes-passenger-${index}-${loyaltyProgrammeIndex}`}
                  >
                    <FormikSelect
                      disabled
                      label="Loyalty programme"
                      name={`passengers.${index}.loyaltyProgrammeAccounts.${loyaltyProgrammeIndex}.airlineIataCode`}
                    >
                      {Object.entries(LoyaltyProgrammeMap).map(
                        ([value, label]) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        )
                      )}
                    </FormikSelect>
                    <FormikInput
                      label="Loyalty account"
                      name={`passengers.${index}.loyaltyProgrammeAccounts.${loyaltyProgrammeIndex}.accountNumber`}
                      disabled
                    />
                  </SplitFormFields>
                )
              )}

            <SplitFormFields>
              <FormikDatePicker
                disablePopup
                label="Date of birth"
                name={`passengers.${index}.bornOn`}
              />
              <FormikSelect name={`passengers.${index}.gender`} label="Gender">
                {Object.entries(PassengerGenderMap).map(([value, label]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </FormikSelect>
            </SplitFormFields>
          </CheckoutSubSection>
        </VSpace>

        {shouldRenderPassportFormSection && (
          <CheckoutSubSection heading="Passport details">
            <div className="passenger-info-passport-details">
              <FormikCountrySelect
                name={`passengers.${index}.passportDetails.countryOfIssue`}
                label="Country of issue"
                optionalField={!offer.passengerIdentityDocumentsRequired}
              />
              <div />
              <FormikInput
                name={`passengers.${index}.passportDetails.passportNumber`}
                label="Passport number"
                optionalField={!offer.passengerIdentityDocumentsRequired}
              />
              <FormikDatePicker
                label="Expiry date"
                name={`passengers.${index}.passportDetails.expiryDate`}
                optionalField={!offer.passengerIdentityDocumentsRequired}
              />
            </div>
          </CheckoutSubSection>
        )}
      </VSpace>
      <style jsx>{`
        .passenger-info-name-container {
          display: grid;
          grid-template-columns: 4fr 10fr 10fr;
          grid-column-gap: 16px;
          margin-bottom: 24px;
        }

        .passenger-info-passport-details {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr;
          grid-column-gap: 16px;
          grid-row-gap: 24px;
        }
      `}</style>
    </div>
  )
}
