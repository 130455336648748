import * as React from 'react'
import classNames from 'classnames'
import {
  Color,
  TypeStyle,
} from '@components/legacy-design-system/product/styles'
import { Text } from '@components/legacy-design-system/product/components/Text'
import { VSpace } from '@components/VSpace'
import { HSpace } from '@components/HSpace'

import styles from './TimelineItem.module.css'
export interface TimelineItemProps {
  label?: string | React.ReactNode
  description?: string
  dot?: boolean
  dotColor?: Color
  lineColorLeft?: Color
  lineColorRight?: Color
  size?: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large'
  tooltip?: string | boolean
  aboveDot?: React.ReactNode
  date?: string
  typeStyle?: TypeStyle
}

export const TimelineItem = React.forwardRef<HTMLDivElement, TimelineItemProps>(
  (
    {
      label,
      description,
      dot = true,
      dotColor,
      lineColorLeft = 'grey-300',
      lineColorRight = 'grey-300',
      tooltip,
      size,
      aboveDot,
      date,
      typeStyle,
    },
    ref
  ) => {
    return (
      <VSpace
        space={12}
        className={classNames(styles['container'], {
          [styles['container--extra-small']]: size === 'extra-small',
          [styles['container--small']]: size === 'small',
          [styles['container--large']]: size === 'large',
          [styles['container--extra-large']]: size === 'extra-large',
        })}
        ref={ref}
      >
        {tooltip && (
          <div className={styles['tooltip']}>
            Today
            <div className={styles['tooltip-arrow']} />
          </div>
        )}
        <HSpace space={0}>
          <div
            className={styles['line']}
            style={{ backgroundColor: `var(--${lineColorLeft})` }}
          />
          {aboveDot && <div className={styles['above-dot']}>{aboveDot}</div>}
          {dot && (
            <div
              className={styles['dot']}
              style={dotColor ? { backgroundColor: `var(--${dotColor})` } : {}}
            />
          )}
          {label && (
            <VSpace space={0} className={styles['timeline-item']}>
              {date && (
                <Text color="grey-600" textAlign="center" typeStyle={typeStyle}>
                  {date}
                </Text>
              )}
              <Text
                fontWeight="medium"
                textAlign="center"
                typeStyle={typeStyle}
              >
                {label}
              </Text>
              <Text textAlign="center" typeStyle={typeStyle}>
                {description}
              </Text>
            </VSpace>
          )}
          <div
            className={styles['line']}
            style={{
              backgroundColor: `var(--${lineColorRight})`,
              left: dot ? 'calc(50% + 8px)' : '50%',
              width: dot ? 'calc(50% - 8px)' : '50%',
            }}
          />
        </HSpace>
      </VSpace>
    )
  }
)
