import * as React from 'react'
import classNames from 'classnames'
import { Button } from '@components/Button'
import { Card } from '@components/Card'
import { FlightSummary } from '@components/FlightSummary'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { HSpace } from '@components/HSpace'
import { Icon } from '@components/Icon'
import { Fade } from '@components/Fade/Fade'
import { Stamp } from '@components/Stamp'
import { getDateObject } from '@lib/date'
import { moneyStringFormatter } from '@lib/helpers'
import { useNavigationStateStopper } from '@lib/hooks'
import { trackEvent } from '@lib/tracking'
import { DuffelAPI } from '@lib/types'
import styles from './SearchResultCard.module.css'

export interface SearchResultCardProps {
  offer: DuffelAPI.Types.Offer
  partialOfferRequestId: string
  onSelect: () => void
  disabled?: boolean
  showNegotiatedFareStamp?: boolean
}

export const SearchResultCard: React.FC<SearchResultCardProps> = ({
  offer,
  partialOfferRequestId,
  onSelect,
  disabled,
  showNegotiatedFareStamp = false,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const [isNavigatingToOfferCheckout, setIsNavigatingToOfferCheckout] =
    useNavigationStateStopper()

  const expirationDateTime = getDateObject(offer.expiresAt)?.valueOf()
  const [hasOfferExpired, setHasOfferExpired] = React.useState(
    expirationDateTime ? expirationDateTime < new Date().valueOf() : false
  )
  const hasNegotiatedFares = offer.privateFares.some(
    (privateFare) => privateFare.type === 'negotiated'
  )

  React.useEffect(() => {
    if (!expirationDateTime) return

    const timeUntilExpiration = expirationDateTime - new Date().valueOf()

    const timeoutId = setTimeout(() => {
      setHasOfferExpired(true)
    }, timeUntilExpiration)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [expirationDateTime])

  if (
    offer.slices.length === 0 ||
    offer.slices[0].segments.length === 0 ||
    offer.slices[0].segments[0].passengers.length === 0
  )
    return null

  return (
    <Fade>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            trackEvent('dashboard_search_results_offer_card_clicked', {
              event_type: 'interaction',
              offer_request_id: partialOfferRequestId,
              offer_id: offer.id,
              is_expanded: isExpanded,
            })
            setIsExpanded(!isExpanded)
          }
        }}
        onClick={() => {
          trackEvent('dashboard_search_results_offer_card_clicked', {
            event_type: 'interaction',
            offer_request_id: partialOfferRequestId,
            offer_id: offer.id,
            is_expanded: isExpanded,
          })
          setIsExpanded(!isExpanded)
        }}
        className={classNames(styles['container'], 'u-skeletonable--show')}
      >
        <VSpace space={0}>
          <Card
            className={classNames(
              styles['summary-wrapper'],
              'u-skeletonable--show'
            )}
            borderRadius={8}
          >
            <FlightSummary
              flight={offer}
              isExpanded={isExpanded}
              hideFareBrand
              hideConditions
            />
          </Card>
          <Card
            className={classNames(styles['footer'], 'u-skeletonable--show')}
            padding={24}
            borderRadius={8}
          >
            <HSpace space={16} spaceBetween alignCenter>
              <HSpace space={16} alignCenter>
                <HSpace
                  space={4}
                  className={classNames(
                    'u-skeletonable',
                    styles['footer-total']
                  )}
                >
                  <Text
                    fontSize="C3"
                    color={hasOfferExpired ? 'grey-400' : 'grey-600'}
                    className={styles['footer-total__from']}
                  >
                    From
                  </Text>
                  <Text
                    fontSize="H4"
                    fontWeight="medium"
                    color={hasOfferExpired ? 'grey-500' : 'grey-900'}
                    className={styles['footer-total__amount']}
                  >
                    {moneyStringFormatter(offer.totalCurrency)(
                      +offer.totalAmount
                    )}
                  </Text>
                </HSpace>
                {showNegotiatedFareStamp && hasNegotiatedFares && (
                  <Stamp
                    label="Has exclusive fares"
                    icon="travel_plane_ticket"
                    color="purple"
                    size="small"
                    border
                  />
                )}
              </HSpace>

              {hasOfferExpired && (
                <HSpace
                  space={8}
                  alignCenter
                  style={{ color: 'var(--orange-500)' }}
                >
                  <Icon name="error" size={20} />
                  <Text fontSize="C2">Offer expired</Text>
                </HSpace>
              )}

              {!hasOfferExpired && (
                <Button
                  text="Select"
                  tabIndex={0}
                  className="u-skeletonable u-skeletonable--dark"
                  onClick={(event) => {
                    event.stopPropagation()
                    setIsNavigatingToOfferCheckout(true)
                    trackEvent(
                      'dashboard_search_results_offer_checkout_button_clicked',
                      {
                        event_type: 'interaction',
                        offer_request_id: partialOfferRequestId,
                        offer_id: offer.id,
                      }
                    )
                    onSelect()
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.stopPropagation()
                      event.currentTarget.click()
                    }
                  }}
                  isWaiting={isNavigatingToOfferCheckout}
                  disabled={disabled}
                />
              )}
            </HSpace>
          </Card>
        </VSpace>
      </div>
    </Fade>
  )
}
