import * as React from 'react'
import classNames from 'classnames'
import styles from './Mark.module.css'

interface MarkProps {
  className?: string
}

export const Mark: React.FC<MarkProps> = ({ className }) => {
  const svgCX = classNames(styles.logo, className)

  return (
    // We need to have a global `logo` so we can override styles
    <svg className={`logo ${svgCX}`} viewBox="0 0 33 30">
      <path d="M0.108970588,7.47485294 L0.108970588,22.4190441 C2.17235294,22.4190441 3.84507353,19.0736029 3.84507353,14.9468382 C3.84507353,10.8202941 2.17235294,7.47485294 0.108970588,7.47485294 Z M7.21654412,4.51632353 L7.21654412,25.3775735 C11.3433088,25.3775735 14.68875,20.7075 14.68875,14.9468382 C14.68875,9.18617647 11.3433088,4.51632353 7.21654412,4.51632353 Z M33.0044118,14.9468382 C33.0044118,23.2003676 26.31375,29.8910294 18.0602206,29.8910294 L18.0602206,0.00264705882 C26.31375,0.00264705882 33.0044118,6.69352941 33.0044118,14.9468382 L33.0044118,14.9468382 Z" />
    </svg>
  )
}
