import { InvoiceItemProps } from '../CostIndicator/InvoiceItem'
import { invoiceItemIndicatorColorMap } from '.'

export const getInvoiceBreakdown = (
  invoiceItemsForBreakdown
): InvoiceItemProps[] => {
  const breakdown: InvoiceItemProps[] = []
  const totalUsage = invoiceItemsForBreakdown
    .map((invoiceItem) => Number(invoiceItem.totalAmountUnformatted))
    .reduce((amount, acc) => {
      return amount + acc
    }, 0)

  invoiceItemsForBreakdown.map((invoiceItem) => {
    const {
      usageItemType,
      quantity,
      totalAmount,
      totalAmountUnformatted,
      paidOrdersBreakdown,
    } = invoiceItem

    breakdown.push({
      invoiceItemName: usageItemType,
      totalItems: quantity,
      totalAmount: totalAmount,
      percentageOfTotal:
        Number(totalAmountUnformatted) === 0
          ? 0
          : (Number(totalAmountUnformatted) / totalUsage) * 100,
      color: invoiceItemIndicatorColorMap[usageItemType],
      ...(usageItemType === 'Paid orders' && {
        breakdown: paidOrdersBreakdown,
      }),
    })
  })

  return breakdown
}
