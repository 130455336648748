import classNames from 'classnames'
import { forwardRef } from 'react'
import { Icon } from '@components/Icon'

import styles from './SelectTarget.module.css'

export const SelectTarget = forwardRef<
  HTMLButtonElement,
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & { size?: 'medium' | 'small'; noIcon?: boolean }
>(({ children, className, size = 'medium', noIcon, ...props }, ref) => {
  return (
    <button
      ref={ref}
      className={classNames(
        styles['select-target'],
        size === 'small' && styles['select-target--small'],
        className
      )}
      {...props}
    >
      {children}
      {!noIcon && (
        <Icon
          name="arrow_drop_down"
          className={styles['arrow-icon']}
          size={16}
        />
      )}
    </button>
  )
})
