import { getCurrencySymbol } from './get-currency-symbol'
import { formatNumberWithCommas } from '.'

export const getTotalEstimatedCost = (
  invoiceItemsForBreakdown,
  formatted: boolean
): string => {
  const currency = invoiceItemsForBreakdown[0].currency
  const totalEstimatedCost =
    invoiceItemsForBreakdown
      .map((usageItem) => Number(usageItem.totalAmountUnformatted))
      .reduce((amount, acc) => {
        return amount + acc
      }, 0) || '0'

  return formatted
    ? `${getCurrencySymbol(currency)}${formatNumberWithCommas(
        totalEstimatedCost,
        true
      )}`
    : totalEstimatedCost
}
