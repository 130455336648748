import { useField, useFormikContext } from 'formik'
import * as React from 'react'
import { TextArea, TextAreaProps } from '@components/TextArea'
import { FormField, FormFieldProps } from './FormField'

export interface FormikTextAreaProps extends FormFieldProps, TextAreaProps {
  /**
   * The name of the field used by Formik
   */
  name: string

  /**
   * Whether to hide the error. Could be useful if the errors are displayed outside the component.
   */
  hideErrorMessage?: boolean
}

export const FormikTextArea: React.FC<FormikTextAreaProps> = (props) => {
  const {
    label,
    hint,
    optionalField,
    secondaryLabel,
    className,
    disabled,
    hideErrorMessage,
    ...textAreaProps
  } = props
  const [field, { touched, error }] = useField(props)
  const { submitCount, isSubmitting } = useFormikContext()
  const isDisabled = isSubmitting || disabled

  return (
    <div className="ff-form-textarea">
      <FormField
        label={label}
        error={
          (touched || submitCount > 0) && !hideErrorMessage ? error : undefined
        }
        hint={hint}
        htmlFor={textAreaProps.id || props.name}
        optionalField={optionalField}
        secondaryLabel={secondaryLabel}
        className={className}
        disabled={isDisabled}
      >
        <TextArea
          {...field}
          {...textAreaProps}
          disabled={isDisabled}
          id={textAreaProps.id || props.name}
        />
      </FormField>
    </div>
  )
}
