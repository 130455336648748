import { Air } from '@lib/duffel-client'
import { DuffelClientClass } from '@lib/duffel-client/Client'
import { trackEvent } from '@lib/tracking'
import { APIResponseError, DuffelAPI } from '@lib/types'
interface Callbacks {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  onSuccess: (data: DuffelAPI.Types.OrderChange) => void
  handleErrors: (errors: APIResponseError[], requestId?: string) => void
}

export const onConfirmChange = async (
  duffelClient: DuffelClientClass,
  orderId: DuffelAPI.Types.Order['id'],
  orderChangeId: Parameters<Air['confirmOrderChange']>[0],
  payment: Parameters<Air['confirmOrderChange']>[1],
  { setIsLoading, onSuccess, handleErrors }: Callbacks
) => {
  setIsLoading(true)
  trackEvent(
    'dashboard_change_flights_offer_checkout_confirm_change_buton_clicked',
    {
      event_type: 'interaction',
      order_id: orderId,
      order_change_id: orderChangeId,
    }
  )

  try {
    const response = await duffelClient.Air.confirmOrderChange(
      orderChangeId,
      payment
    )

    if (response.errors) {
      handleErrors(response.errors, response.meta?.requestId)
      return
    }

    if (!response.data) {
      handleErrors([], response.meta?.requestId)
      return
    }

    onSuccess(response.data)
    trackEvent(
      'dashboard_change_flights_offer_checkout_confirm_change_completed',
      {
        event_type: 'api',
        order_id: orderId,
        order_change_id: orderChangeId,
      }
    )
  } catch (error: any) {
    trackEvent(
      'dashboard_change_flights_offer_checkout_confirm_change_alert_displayed',
      {
        event_type: 'alert',
        order_id: orderId,
        order_change_id: orderChangeId,
        alert_message: error,
      }
    )

    handleErrors([
      {
        code: 'unknown',
        title: '',
        message: String(error),
      },
    ])
    setIsLoading(false)
  }
}
