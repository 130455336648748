import { FormDatePicker } from '@components/DatePicker'
import { HSpace } from '@components/HSpace'
import { Select } from '@components/Select'
import { VSpace } from '@components/VSpace'
import { Text } from '@components/Text'
import { PendingDateFilterCondition } from '../lib/types'

import styles from './DateConditionFilterInput.module.css'

function assertIsDateConditionType(
  val: string
): asserts val is PendingDateFilterCondition['condition'] {
  if (!['before', 'after', 'between'].includes(val)) {
    throw new Error(`unexpected date filter condition ${val}`)
  }
}

export const DateConditionFilterInput: React.FC<{
  value: PendingDateFilterCondition
  label: string
  onChange: (dateCondition: PendingDateFilterCondition) => void
}> = ({ value, label, onChange }) => (
  <VSpace space={16} className={styles['date-condition-container']}>
    <HSpace space={16} alignCenter>
      <Text>{label}</Text>
      <Select
        className={styles['date-select']}
        value={value.condition}
        onChange={(event) => {
          const newConditionType = event.target.value
          assertIsDateConditionType(newConditionType)

          // special cases where the selected dates aren't aligning
          if (value.condition === 'between' && newConditionType !== 'between') {
            onChange({ condition: newConditionType, date: value.date1 })
            return
          }
          if (value.condition !== 'between' && newConditionType === 'between') {
            onChange({ condition: newConditionType, date1: value.date })
            return
          }

          // otherwise, we can reuse the same date(s)
          onChange({ ...value, condition: newConditionType })
        }}
      >
        <option key="before" value="before">
          Before
        </option>
        <option key="after" value="after">
          After
        </option>
        <option key="between" value="between">
          Between
        </option>
      </Select>
    </HSpace>
    {value.condition !== 'between' && (
      <FormDatePicker
        enablePastDates
        value={value.date ?? null}
        onChange={(date: Date | null) => {
          onChange({
            condition: value.condition,
            date: date ?? undefined,
          })
        }}
        id={''}
      />
    )}
    {value.condition === 'between' && (
      <VSpace space={16}>
        <FormDatePicker
          enablePastDates
          value={value.date1 ?? null}
          onChange={(date: Date | null) => {
            onChange({
              ...value,
              date1: date ?? undefined,
            })
          }}
          id={'filter-date-between-1'}
        />
        <Text>and</Text>
        <FormDatePicker
          enablePastDates
          value={value.date2 ?? value.date1 ?? null}
          onChange={(date: Date | null) => {
            onChange({
              ...value,
              date2: date ?? undefined,
            })
          }}
          id={'filter-date-between-2'}
        />
      </VSpace>
    )}
  </VSpace>
)
