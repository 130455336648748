import { Button, ChromelessButton } from '@components/Button'
import { HSpace } from '@components/HSpace'
import { Icon } from '@components/Icon'
import {
  Invoice,
  InvoiceBody,
  InvoiceCell,
  InvoiceHeader,
  InvoiceRow,
} from '@components/Invoice'
import { Modal } from '@components/Modal'
import { Text } from '@components/Text'
import { CreateOrderService } from '@duffel/api/types'
import { trackEvent } from '@lib/tracking'
import { DuffelAPI } from '@lib/types'
import {
  AdditionalServiceBooking,
  transformAdditionalBaggageToServicePayload,
} from '@modules/air-order-change/lib'
import { getCurrencySymbol } from '@modules/usage-and-billing/components/lib'
import * as React from 'react'
import styles from './BaggageConfirmationModal.module.css'

export interface BaggageConfirmationModalProps {
  additionalBaggages: CreateOrderService[]
  availableServices: DuffelAPI.Types.OrderAvailableService[] | null
  passengers: DuffelAPI.Types.OrderPassenger[]
  setAdditionalBaggages: (
    value: React.SetStateAction<CreateOrderService[] | undefined>
  ) => void
  setBaggageModalOpen: (value: React.SetStateAction<boolean>) => void
  setBaggageConfirmationModalOpen: (
    value: React.SetStateAction<boolean>
  ) => void
  handleAddServicesToExistingOrder: (services: AdditionalServiceBooking) => void
}
export const BaggageConfirmationModal: React.FC<
  BaggageConfirmationModalProps
> = ({
  additionalBaggages,
  availableServices,
  passengers,
  setAdditionalBaggages,
  setBaggageModalOpen,
  setBaggageConfirmationModalOpen,
  handleAddServicesToExistingOrder,
}) => {
  const [isBaggageBeingAdded, setIsBaggageBeingAdded] = React.useState(false)
  const baggageDisplay = transformAdditionalBaggageToServicePayload(
    additionalBaggages,
    availableServices,
    passengers
  )

  if (!baggageDisplay) {
    return null
  }

  const currencySymbol =
    baggageDisplay.totalCurrency &&
    getCurrencySymbol(baggageDisplay.totalCurrency)

  // Attempting to add a baggage service of quantity 0 causes an error. This helper
  // ensures these services don't form part of the request payload.
  const filteredAdditionalBaggages = additionalBaggages
    .map(({ id, quantity }) => {
      return {
        id,
        quantity,
      }
    })
    .filter((service) => service.quantity > 0)

  return (
    <Modal
      onClose={() => setBaggageConfirmationModalOpen(false)}
      className={styles['baggage-confirmation-modal']}
    >
      <div className={styles['baggage-confirmation-container']}>
        <div className={styles['modal-close__container']}>
          <Text
            fontSize="H3"
            fontWeight="medium"
            className={styles['modal-title']}
          >
            Add baggage
          </Text>
          <ChromelessButton
            type="button"
            aria-label="Close"
            className={styles['modal-overlay__button']}
            onClick={() => setBaggageConfirmationModalOpen(false)}
          >
            <Icon name="close" />
          </ChromelessButton>
        </div>

        <div className={styles['bagaage-confirmation-items']}>
          <Invoice>
            <InvoiceHeader>
              <InvoiceRow className={styles['baggage-confirmation-header']}>
                <InvoiceCell isHeaderCell>Description</InvoiceCell>
                <InvoiceCell isHeaderCell textAlign="right">
                  Price ({baggageDisplay.totalCurrency})
                </InvoiceCell>
              </InvoiceRow>
            </InvoiceHeader>

            <InvoiceBody>
              {baggageDisplay.items.map(({ description, price }, index) => (
                <InvoiceRow
                  className={styles['baggage-confirmation-row']}
                  key={`tx_${index}`}
                  id={`transaction-row-${index}`}
                >
                  {description && <InvoiceCell>{description}</InvoiceCell>}
                  <InvoiceCell textAlign="right">{price}</InvoiceCell>
                </InvoiceRow>
              ))}
              <InvoiceRow
                className={styles['baggage-confirmation-total']}
                key="tx_total"
                id="transaction-row-total"
              >
                <InvoiceCell textAlign="right">Total</InvoiceCell>
                <InvoiceCell textAlign="right">
                  {baggageDisplay.totalAmount}
                </InvoiceCell>
              </InvoiceRow>
            </InvoiceBody>
          </Invoice>
        </div>
        <div className={styles['modal-actions']}>
          <HSpace space={8} justify="end" data-selector="fs-show">
            <Button
              className={styles['modal-back-button']}
              medium
              intent="MUTED"
              text="Back"
              iconOnly="arrow_left"
              onClick={() => {
                setAdditionalBaggages(filteredAdditionalBaggages)
                setBaggageModalOpen(true)
                setTimeout(() => setBaggageConfirmationModalOpen(false), 0)
              }}
            />
            <Button
              medium
              text={`Pay ${currencySymbol}${baggageDisplay.totalAmount} from balance`}
              iconAfter="arrow_right"
              isWaiting={isBaggageBeingAdded}
              onClick={async () => {
                setIsBaggageBeingAdded(true)
                trackEvent(
                  'dashboard_add_service_baggage_selection_confirmed_clicked',
                  {
                    event_type: 'interaction',
                  }
                )
                const servicesToAdd: AdditionalServiceBooking = {
                  payment: {
                    type: 'balance',
                    currency: baggageDisplay.totalCurrency || '',
                    amount: baggageDisplay.totalAmount,
                  },
                  addServices: filteredAdditionalBaggages,
                }

                await handleAddServicesToExistingOrder(servicesToAdd)
              }}
            />
          </HSpace>
        </div>
      </div>
    </Modal>
  )
}
