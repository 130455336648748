import classNames from 'classnames'
import Link from 'next/link'
import * as React from 'react'
import { MenuItemButton } from '../MenuItemButton'
import { MenuItemDisabled } from '../MenuItemDisabled'
import styles from './MenuItem.module.css'

export interface MenuItemElementProps {
  className?: string
}

const MenuItemElement: React.FC<
  React.PropsWithChildren<MenuItemElementProps>
> = ({ children, className, ...props }) => {
  return (
    <li className={classNames(className, styles['menu-item'])} {...props}>
      {children}
    </li>
  )
}

export interface MenuItemProps {
  label: string
  href?: string | { pathname: string; query: any }
  as?: string
  disabled?: boolean
  className?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  disabledTooltipText?: string
  destructive?: boolean
}

export const MenuItem: React.FC<MenuItemProps> = ({
  label,
  href,
  as,
  className,
  disabled,
  disabledTooltipText,
  onClick,
  destructive,
  ...props
}) => {
  let isInternal = false
  if (typeof href === 'string' && href.startsWith('/')) {
    isInternal = true
  }

  if (disabled) {
    return (
      <MenuItemElement className={className} {...props}>
        <MenuItemDisabled
          tooltipText={disabledTooltipText}
          destructive={destructive}
        >
          {label}
        </MenuItemDisabled>
      </MenuItemElement>
    )
  }

  if (isInternal && !as) {
    console.warn(
      `It's recommended to use both href and as for internal navigation as Link relies on it.`
    )
  }

  // Uses on click
  if (!href) {
    return (
      <MenuItemElement className={className}>
        <MenuItemButton
          destructive={destructive}
          label={label}
          onClick={(event) => {
            event.preventDefault()
            onClick && onClick(event)
          }}
        />
      </MenuItemElement>
    )
  }

  // Otherwise internally navigate
  return (
    <MenuItemElement className={className}>
      <Link href={href} as={as} passHref legacyBehavior>
        <MenuItemButton
          destructive={destructive}
          label={label}
          onClick={(event) => onClick && onClick(event)}
        />
      </Link>
    </MenuItemElement>
  )
}
