import * as React from 'react'
import { Icon } from '@components/Icon'
import { useWorkspace } from '@lib/workspace-context'

export const LiveModeAlertCallout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { permissions } = useWorkspace()

  if (!permissions?.liveMode) return null

  return (
    <p className="production-danger">
      <Icon name="alert" />
      <span>{children}</span>
      <style jsx>{`
        .production-danger {
          color: var(--pink-500);
          font-weight: bold;
          display: flex;
          align-items: center;
          margin-bottom: -8px;
        }
        .production-danger span {
          margin-left: 8px;
        }
      `}</style>
    </p>
  )
}
