import { getDateObject } from '@lib/date'
import { CabinClass, DuffelAPI } from '@lib/types'
import { validateOrderAttributes } from './validate-order-attributes'

export interface PrivateFare {
  key: 'corporateCode' | 'tourCode' | 'trackingReference'
  value: string
}

export interface CreateOrderChangeRequestFormValues {
  owner: DuffelAPI.Types.Airline
  orderId: string
  cabinClass: CabinClass
  slices: CreateOrderChangeRequestFormValuesSlice[]
  // We only support private fares from the airline owner only
  privateFares: PrivateFare[]
}

// Tighten up types!
export interface CreateOrderChangeRequestTransformedValues {
  owner: DuffelAPI.Types.Airline
  orderId: string
  cabinClass: CabinClass
  slices: CreateOrderChangeRequestFormValuesSlice[]
  // We only support private fares from the airline owner only
  privateFares: Record<
    string,
    DuffelAPI.Inputs.CreateOrderChangeRequestPrivateFare[]
  >
}

export interface CreateOrderChangeRequestFormValuesSlice
  extends Omit<
      DuffelAPI.Inputs.CreateOrderChangeRequestSlicesAddItem,
      'departureDate' | 'cabinClass'
    >,
    DuffelAPI.Inputs.CreateOrderChangeRequestSlicesRemoveItem {
  departureDate: Date
  toBeChanged: boolean
  owner: DuffelAPI.Types.Airline
}

export const getCreateOrderChangeFormInitialValues = (
  order: DuffelAPI.Types.Order,
  orderChangeRequest?: DuffelAPI.Types.OrderChangeRequest
): CreateOrderChangeRequestFormValues => {
  if (!validateOrderAttributes(order)) {
    throw Error(
      `The order ${order.id} does not match the expected schema. It was not possible to continue with creating order change request.`
    )
  }

  const firstSlice = order.slices[0] || {}
  const firstSegment = firstSlice.segments[0] || {}
  const firstPassenger = firstSegment.passengers[0] || {}
  const inferredCabinClass = firstPassenger.cabinClass || 'economy'
  const cabinClass = orderChangeRequest
    ? orderChangeRequest.slices.add[0].cabinClass || inferredCabinClass
    : inferredCabinClass
  const owner = order.owner
  const privateFares = [
    {
      key: 'corporateCode' as const,
      value: '',
    },
  ]

  return {
    owner,
    orderId: order.id,
    cabinClass,
    privateFares,
    slices: order.slices.map((slice) => {
      const firstSegment = slice.segments[0]
      const sliceToRemoveIndex =
        orderChangeRequest &&
        orderChangeRequest.slices.remove.findIndex(
          ({ sliceId }) => sliceId === slice.id
        )

      if (
        orderChangeRequest &&
        sliceToRemoveIndex !== undefined &&
        sliceToRemoveIndex !== -1
      ) {
        const sliceToAdd = orderChangeRequest!.slices.add[sliceToRemoveIndex]

        return {
          sliceId: slice.id,
          origin: sliceToAdd.origin,
          destination: sliceToAdd.destination,
          toBeChanged: true,
          departureDate: getDateObject(sliceToAdd.departureDate),
          owner: firstSegment.marketingCarrier || firstSegment.operatingCarrier,
        } as CreateOrderChangeRequestFormValuesSlice
      } else {
        return {
          sliceId: slice.id,
          origin: slice.origin.iataCode,
          destination: slice.destination.iataCode,
          toBeChanged: false,
          departureDate: getDateObject(firstSegment.departingAt),
          owner: firstSegment.marketingCarrier || firstSegment.operatingCarrier,
        } as CreateOrderChangeRequestFormValuesSlice
      }
    }),
  }
}
