import { getDaysInMonth } from 'date-fns'
import { getCurrencySymbol } from './get-currency-symbol'
import { formatNumberWithCommas } from '.'
import { getTotalEstimatedCost } from '.'

export const getProjectedCostForPeriod = (invoiceItemsForBreakdown): string => {
  const currency = invoiceItemsForBreakdown[0].currency
  const billingPeriod = invoiceItemsForBreakdown[0].billingPeriod
  const totalEstimatedCost = Number(
    getTotalEstimatedCost(invoiceItemsForBreakdown, false)
  )

  const daysElapsed = billingPeriod.getDate()
  const daysInMonth = getDaysInMonth(billingPeriod)
  const remainingDaysInMonth = daysInMonth - daysElapsed
  const currentDate = new Date()

  const averageCostPerDay = totalEstimatedCost / daysElapsed
  const projectedAdditionalCostForPeriod =
    averageCostPerDay * remainingDaysInMonth

  const isBillingPeriodSameAsCurrentDate =
    billingPeriod.getMonth() === currentDate.getMonth() &&
    billingPeriod.getFullYear() === currentDate.getFullYear()

  const projectedCostForPeriod = isBillingPeriodSameAsCurrentDate
    ? totalEstimatedCost + projectedAdditionalCostForPeriod || '0'
    : totalEstimatedCost || '0'

  return `${getCurrencySymbol(currency)}${formatNumberWithCommas(
    projectedCostForPeriod,
    true
  )}`
}
