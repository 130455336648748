import { customAlphabet } from 'nanoid'
import { getDateObject } from '@lib/date'
import { DuffelAPI } from '@lib/types'

const getRandomPNR = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVXYWZ', 6)

const getRandomTransactionBasis = (): [
  number,
  DuffelAPI.Types.WalletTransaction['type']
] => {
  const amount = +(Math.random() * 2000 - 1000).toFixed(2)
  return [amount, amount > 0 ? 'order_cancellation' : 'order']
}

const STARTING_BALANCE = 5e3

const getDefaultObject = (() => {
  let seedId = 1
  let runningBalance = STARTING_BALANCE

  return (): DuffelAPI.Types.WalletTransaction => {
    const [amount, type] = getRandomTransactionBasis()
    runningBalance = +(amount + runningBalance).toFixed(2)

    return {
      id: `wal_${(seedId++).toString().padStart(5, '0')}`,
      createdAt: '2020-09-05',
      type: type,
      orderId: 'ord_0000000000000000000022',
      bookingReference: getRandomPNR(),
      orderCancellationId:
        type === 'order_cancellation' ? 'ore_0000000000000000000022' : null,
      amount: amount.toString(),
      runningBalanceAmount: runningBalance.toString(),
      ownerName: 'Lufthansa Group',
      currency: 'GBP',
      runningBalanceCurrency: 'GBP',
      resourceId: null,
      source: null,
      chargeAmount: null,
      chargeCurrency: null,
      balanceChangeAmount: null,
      balanceChangeCurrency: null,
    }
  }
})()

export const makeMockTransaction = (
  extendDefault?: Partial<DuffelAPI.Types.WalletTransaction>
): DuffelAPI.Types.WalletTransaction =>
  Object.assign({}, getDefaultObject(), extendDefault || {})

const DAY_IN_MS = 24 * 60 * 60 * 1000
const getRandomOrderedDateTimes = (date: Date, count: number): Date[] => {
  const dateTimes = new Array<Date>()
  for (let index = 0; index < count; index++) {
    const newDateTime = new Date(date.valueOf())
    newDateTime.setHours(Math.floor(Math.random() * 23))
    newDateTime.setMinutes(Math.floor(Math.random() * 59))
    dateTimes.push(newDateTime)
  }

  return dateTimes.sort((first, second) => first.valueOf() - second.valueOf())
}
export const makeMockTransactionListInDateRange = (
  startStr: string,
  endStr: string
) => {
  const start = getDateObject(startStr)
  const end = getDateObject(endStr)

  if (!start)
    throw `makeMockTransactionListInDateRange received an invalid argument for startStr: ${startStr}`
  if (!end)
    throw `makeMockTransactionListInDateRange received an invalid argument for endStr: ${endStr}`

  const numberOfDays = Math.round((end.valueOf() - start.valueOf()) / DAY_IN_MS)
  const daysInBetween = [...new Array(numberOfDays).keys()].map(
    (index) => new Date(start.valueOf() + index * DAY_IN_MS)
  )

  const transactions = new Array<DuffelAPI.Types.WalletTransaction>()

  for (const date of daysInBetween) {
    const numberOfTransactionsOnDay = Math.ceil(Math.random() * 12)
    const transactionDateTimes = getRandomOrderedDateTimes(
      date,
      numberOfTransactionsOnDay
    )

    for (const createdAt of transactionDateTimes) {
      transactions.push(
        makeMockTransaction({ createdAt: createdAt.toISOString() })
      )
    }
  }

  return transactions
}
