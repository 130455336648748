import { getDayDiff } from '@lib/date'
import { DuffelAPI, SliceDetails } from '@lib/types'
import { getStopDurationString } from '../SliceDetailsSummary'
import { getAirlinesText } from './get-airlines-text'
import { getOriginDestinationKey } from './get-origin-destination-key'

export const getSliceWithComparableProperties = (
  slice: DuffelAPI.Types.OrderSlice,
  sliceDetails: SliceDetails
) => {
  const departingAt = sliceDetails[0].travelDetails?.departingAt
  const arrivingAt =
    sliceDetails[sliceDetails.length - 1].travelDetails?.arrivingAt
  const segmentDurations = {}
  slice.segments.map((segment, index: number) => {
    if (index + 1 < slice.segments.length) {
      segmentDurations[getOriginDestinationKey(segment)] =
        getStopDurationString(segment, slice.segments[index + 1])
    } else {
      segmentDurations[getOriginDestinationKey(segment)] =
        "The contents here don't matter for now, we just wanted the key in the object for the sake of segment comparison later."
    }
  })
  const dayDiff =
    departingAt && arrivingAt ? getDayDiff(arrivingAt, departingAt) : 0

  return {
    departingAt,
    arrivingAt,
    stops: slice.segments.length,
    airlinesText: getAirlinesText(slice),
    dayDiff,
    ...segmentDurations,
    ...slice,
  }
}
