import { captureException } from '@lib/sentry'

/**
 * Get the query value from next.js router
 * @return {string}
 */
export const getQueryValue = (value?: string | string[]): string => {
  if (Array.isArray(value)) {
    // maybe send some warning to sentry too since we don't expect this?
    captureException(Error('Query returned by Next.js was an array'))
    return value.length ? value[0] : ''
  }

  return value ?? ''
}
