import { PassengersChangeType } from './types'

export const getChangeType = (
  updatedCount: number,
  currentCount: number
): PassengersChangeType => {
  if (updatedCount > currentCount) return 'add'

  if (currentCount > updatedCount) return 'remove'

  return 'no_change'
}
