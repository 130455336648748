import { cloneDeep, orderBy, remove } from 'lodash'
import { DuffelAPI } from '@lib/types'
import { getOrderedAICs } from './get-ordered-aics'

/**
 * This function returns added and removed slices with AICs.
 *
 * Scenario: Slice IDs remain unchanged, but a segment within it has changed:
 *
 * It is possible to receive AICs where slice IDs remain unchanged, but a segment within it has changed.
 * In this case, an `unchangedSliceIdsWithChangedSegments` array will contain the IDs of the affected slices.
 * These slice IDs determine the correct change status, `Flight removed` or `New flight added` of the order's
 * before and after view.
 */

export const getBeforeAndAfterSlicesAndSegmentsWithAICs = (
  order: DuffelAPI.Types.Order
) => {
  // Order AICs in chronological order
  const airlineInitiatedChanges = getOrderedAICs(order)

  // Filter AICs to get those requiring action
  const unactionedAICs = airlineInitiatedChanges?.filter(
    (change) => change.actionTaken === null
  )

  let beforeSlices = cloneDeep(order.slices)
  const unchangedSliceIdsWithChangedSegments: string[] = []
  const segmentIdsInOrder = beforeSlices.flatMap((slice) =>
    slice.segments?.flatMap((segment) => segment.id)
  )
  const removedSegmentIdsInAICs = [
    ...new Set(
      unactionedAICs?.flatMap((aic) =>
        aic.removed.flatMap((slice) =>
          slice.segments?.flatMap((segment) => segment.id)
        )
      )
    ),
  ]
  const addedSegmentIdsInAICs = [
    ...new Set(
      unactionedAICs?.flatMap((aic) =>
        aic.added.flatMap((slice) =>
          slice.segments?.flatMap((segment) => segment.id)
        )
      )
    ),
  ]
  const removedSegmentIdsNotInOrder = removedSegmentIdsInAICs.filter(
    (id) => !segmentIdsInOrder.includes(id)
  )

  unactionedAICs?.reverse().forEach((aic) => {
    const addedSliceIds = [
      ...new Set(aic.added.flatMap((addedSlice) => addedSlice.id)),
    ]
    const removedSliceIds = [
      ...new Set(aic.removed.flatMap((removedSlice) => removedSlice.id)),
    ]

    // If slice is in both in added and removed arrays, then it means a segment has been changed
    const slicesInBothAddedAndRemovedChanges = [
      ...new Set(addedSliceIds.filter((id) => removedSliceIds.includes(id))),
    ]

    if (slicesInBothAddedAndRemovedChanges.length) {
      // Find slice that contains the removed segment
      const sliceWithSegmentChanged = aic.removed.find((removedSlice) =>
        removedSlice.segments.find((segment) =>
          removedSegmentIdsNotInOrder.includes(segment.id)
        )
      )

      if (sliceWithSegmentChanged) {
        beforeSlices.push(sliceWithSegmentChanged)
        unchangedSliceIdsWithChangedSegments.push(sliceWithSegmentChanged.id!)
      }

      // Find slice that does not contain the removed segment.
      // This is the segment in the final order view.
      const segmentToRemove = addedSegmentIdsInAICs.filter(
        (id) => !removedSegmentIdsInAICs.includes(id)
      )
      remove(beforeSlices, (slice) =>
        slice.segments.find((segment) => segmentToRemove.includes(segment.id))
      )
    } else {
      // Remove slices from beforeSlices which are in the added array
      remove(beforeSlices, (slice) => addedSliceIds.includes(slice.id))

      // Add any slices from the removed array
      aic.removed.forEach((removedSlice) => {
        beforeSlices.push(removedSlice)
      })
    }
  })

  // Order beforeSlices chronologically - order by first segment's departure time
  beforeSlices = orderBy(
    beforeSlices,
    (slice) => slice.segments[0].departingAt,
    ['asc']
  )

  const afterSlices = cloneDeep(order.slices)

  return {
    beforeSlices,
    afterSlices,
    unchangedSliceIdsWithChangedSegments,
  }
}
