import { capitalize } from 'lodash'
import { DuffelAPI } from '@lib/types'
import { getPassengerType } from '@modules/air-search-v2/forms/SearchForm/FormFields/PassengersSelect/lib/get-passenger-type'
import { getPassengerIndexOfType } from './get-passenger-index-of-type'

export const getDefaultPassengerTitle = (
  passenger: DuffelAPI.Inputs.OfferRequestPassenger,
  passengers: DuffelAPI.Inputs.OfferRequestPassenger[]
) => {
  const passengerType = capitalize(getPassengerType(passenger))
  return `${passengerType} ${getPassengerIndexOfType(passenger, passengers)}`
}
