import { UserRole } from '@lib/security'
import { captureException } from '@lib/sentry'
import { DuffelProxy, JwtPayload } from '@lib/types'
import { getPersonalToken } from '.'

/**
 * Get either a live or testing token for an organisation.
 *
 * @param {Object} self Authenticated user's self object
 * @param {String} slug The current organisation slug
 * @param {Boolean} live The desired token mode
 *
 * @returns {Object} An API token and the organisation's slug
 */
export function getTokenWithMode(
  self: DuffelProxy.Types.Self,
  slug: string,
  live = true
): JwtPayload | null {
  const { organisationsBySlug, organisationSlugs } = self

  if (!organisationSlugs.includes(slug)) return null

  const { isVerified, accessTokens, id } = organisationsBySlug[slug]
  if (!accessTokens) return null

  const testTokenKeys = Object.keys(accessTokens.test || {})
  const liveTokenKeys = Object.keys(accessTokens.live || {})

  if (isVerified && live && accessTokens.live && liveTokenKeys.length > 0) {
    const liveToken = accessTokens.live[liveTokenKeys[0]]
    return {
      token: liveToken.token,
      liveMode: !!liveToken.liveMode,
      scope: liveToken.scope as UserRole,
      organisation: slug,
      userId: self.id,
      organisationId: id,
      email: self.email,
    }
  }

  if (!live && accessTokens.test && testTokenKeys.length > 0) {
    const testToken = accessTokens.test[testTokenKeys[0]]
    return {
      token: testToken.token,
      liveMode: !!testToken.liveMode,
      scope: testToken.scope as UserRole,
      organisation: slug,
      userId: self.id,
      organisationId: id,
      email: self.email,
    }
  }

  return null
}

/**
 * Get any token for an organisation with a preference for a live token
 *
 * @param self Authenticated user's self object
 * @param slug The current organisation slug
 *
 * @returns A JWT payload for a user access token
 */
export function getAnyToken(
  self: DuffelProxy.Types.Self,
  slug: string
): JwtPayload | null {
  if (!slug || Object.keys(self.organisationsBySlug).length === 0) {
    const error = new Error(
      'getAnyToken was called without any organisation present.'
    )
    captureException(error, { self, slug })
    // Fallback to getting personal access token if the user does not have an organisation
    return getPersonalToken(self)
  }

  return (
    getTokenWithMode(self, slug, true) || getTokenWithMode(self, slug, false)
  )
}
