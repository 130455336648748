import { Color } from '@components/legacy-design-system/product/styles'
import { IndicatorStampStatus } from './IndicatorStamp'

export const backgroundColorStyleFromStatus = (
  status: IndicatorStampStatus
): Color => {
  switch (status) {
    case 'valid':
      return 'green-100'
    case 'invalid':
      return 'pink-100'
    case 'neutral':
    default:
      return 'grey-100'
  }
}

export const colorStyleFromStatus = (status: IndicatorStampStatus): Color => {
  switch (status) {
    case 'valid':
      return 'green-800'
    case 'invalid':
      return 'pink-800'
    case 'neutral':
    default:
      return 'grey-600'
  }
}

export const iconColorStyleFromStatus = (
  status: IndicatorStampStatus
): Color => {
  switch (status) {
    case 'valid':
      return 'green-800'
    case 'invalid':
      return 'pink-800'
    case 'neutral':
    default:
      return 'grey-400'
  }
}
