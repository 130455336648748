import * as React from 'react'
import { Icon } from '@components/Icon'
import { DuffelAPI } from '@lib/types'
import { OrderServicesBaggages } from '../OrderPassengerFlightInformation'
import styles from './OrderPassengerAncillaries.module.css'

export interface OrderPassengerAncillariesProps {
  segmentId: string
  seats?: DuffelAPI.Types.OrderService
  baggages?: OrderServicesBaggages
}

export const OrderPassengerAncillaries = React.forwardRef<
  HTMLDivElement,
  OrderPassengerAncillariesProps
>(({ seats, baggages, segmentId }, ref?) => {
  const seatMetadata =
    seats && (seats.metadata as DuffelAPI.Types.OrderServiceSeatMetadata)
  return (
    <div className={styles['order-passenger-ancillaries']} ref={ref}>
      {seatMetadata && (
        <div className={styles['order-passenger-ancillary']}>
          <Icon name="seat" className="service-icon" />
          Seat {seatMetadata.designator}
        </div>
      )}
      {baggages &&
        baggages[segmentId] &&
        Object.values(baggages[segmentId]).map(({ quantity, type }, index) => {
          const key = `${type}-${index}`
          if (!quantity) return <React.Fragment key={key} />

          if (type !== 'carry_on' && type !== 'checked') {
            return (
              <div key={key}>
                <div>
                  {quantity} {type}
                </div>
              </div>
            )
          }

          const icon = type === 'carry_on' ? 'cabin_bag' : 'checked_bag'
          return (
            <div key={key} className={styles['order-passenger-ancillary']}>
              <Icon name={icon} className="service-icon" />
              <div>
                {quantity} {type.replace('_', ' ')} bag
                {quantity > 1 ? `s` : ''}
              </div>
            </div>
          )
        })}
    </div>
  )
})
