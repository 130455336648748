import * as React from 'react'
import { SliceItem } from '@lib/types'
import { SliceDetailsLayoverItem } from '../SliceDetailsLayoverItem'
import { SliceDetailsTravelItem } from '../SliceDetailsTravelItem'

interface SliceDetailsItemProps {
  item: SliceItem
}

export const SliceDetailsItem: React.FC<SliceDetailsItemProps> = ({ item }) => {
  if (item.type === 'travel' && item.travelDetails) {
    return <SliceDetailsTravelItem travelDetails={item.travelDetails} />
  }
  if (item.type === 'layover' && item.layoverDetails) {
    return <SliceDetailsLayoverItem layoverDetails={item.layoverDetails} />
  }
  return null
}
