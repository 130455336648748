import classNames from 'classnames'
import * as React from 'react'
import { Icon } from '@components/Icon'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { HSpace } from '@components/HSpace'
import { useTimeout } from '@lib/hooks'
import styles from './Toast.module.css'

const TOAST_TIMEOUT = 7000

export interface ToastProps {
  id: string
  intent?: 'success' | 'info' | 'warning' | 'error'
  title?: string
  message: string
  onClose?: (e: React.MouseEvent) => void
  closeAfterTimeout?: boolean
}

export const Toast: React.FC<ToastProps & { index: any }> = ({
  index,
  intent = 'success',
  title,
  message,
  onClose,
  closeAfterTimeout = true,
}) => {
  const defaultStyles = classNames(styles['toast'], {
    [styles['toast--success']]: intent === 'success',
    [styles['toast--info']]: intent === 'info',
    [styles['toast--warning']]: intent === 'warning',
    [styles['toast--error']]: intent === 'error',
  })

  const [isOpen, setIsOpen] = React.useState<boolean>(true)
  const [cx, setCx] = React.useState<string>()
  const timeout = closeAfterTimeout ? TOAST_TIMEOUT : 0

  React.useEffect(() => {
    if (isOpen) {
      setCx(classNames(defaultStyles))
    } else {
      setCx(classNames(defaultStyles, styles['fade-out']))
    }
  }, [isOpen, defaultStyles])

  const handleClose = () => {
    setIsOpen(false)
    setTimeout(() => {
      onClose && onClose(index)
    }, 300)
  }

  useTimeout(() => {
    if (timeout > 0 && onClose) {
      handleClose()
    }
  }, timeout)

  return (
    <div className={cx} data-selector="fs-show">
      <span className={styles['status-indicator']} />
      <HSpace space={4}>
        <VSpace space={4}>
          <div className={styles['toast__messages']}>
            <Text fontSize="C2" fontWeight="medium">
              {title}
            </Text>
            <Text fontSize="C2" color={title ? 'grey-600' : 'grey-900'}>
              {message}
            </Text>
          </div>
        </VSpace>
      </HSpace>

      <button
        type="button"
        className={styles['toast__action']}
        onClick={handleClose}
      >
        <Icon name="close" size={16} />
      </button>
    </div>
  )
}
