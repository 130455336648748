// https://www.figma.com/file/0YkFzIjNQvTUXnygsdqy87/[NEW]-WIP-Foundations?node-id=640%3A1340

export const COLORS = {
  black: '#000000',
  white: '#FFFFFF',
  'grey-100': '#F9F8FC',
  'grey-200': '#EDEDF3',
  'grey-300': '#E2E2E8',
  'grey-400': '#CCCCD4',
  'grey-500': '#ABABB4',
  'grey-600': '#71717A',
  'grey-700': '#4B4B55',
  'grey-800': '#34343D',
  'grey-900': '#09090A',

  'purple-100': '#F2EBFF',
  'purple-200': '#E8DCFF',
  'purple-300': '#DBC8FF',
  'purple-400': '#C7ADFF',
  'purple-500': '#B696F9',
  'purple-600': '#9E7CE8',
  'purple-700': '#7459AC',
  'purple-800': '#413463',
  'purple-850': '#24153E',
  'purple-900': '#100024',

  'green-100': '#E5F8F4',
  'green-200': '#CCEFE9',
  'green-300': '#AFE7DC',
  'green-400': '#89CFC2',
  'green-500': '#6CBFB0',
  'green-600': '#5AAA9C',
  'green-700': '#388275',
  'green-800': '#206C5E',
  'green-900': '#103735',

  'pink-100': '#FFE9E9',
  'pink-200': '#FFD4D4',
  'pink-300': '#FFB5B5',
  'pink-400': '#F88989',
  'pink-500': '#FD6F77',
  'pink-600': '#F6515B',
  'pink-700': '#DB2F46',
  'pink-800': '#9E1A2B',
  'pink-900': '#600E19',

  'blue-100': '#E5F8F4',
  'blue-200': '#D4E7FF',
  'blue-300': '#BBD9FF',
  'blue-400': '#92BFF8',
  'blue-500': '#6DA7F2',
  'blue-600': '#5B92E3',
  'blue-700': '#4C73C3',
  'blue-800': '#3C4D89',
  'blue-900': '#172A50',

  'orange-100': '#FFEADD',
  'orange-200': '#FFD9C1',
  'orange-300': '#FFC2A0',
  'orange-400': '#FFA681',
  'orange-500': '#FF8963',
  'orange-600': '#DF582D',
  'orange-700': '#DF582D',
  'orange-800': '#B24522',
  'orange-900': '#71260E',

  'yellow-100': '#FFF3DC',
  'yellow-200': '#FFEAC2',
  'yellow-300': '#FFDE9E',
  'yellow-400': '#FFD27C',
  'yellow-500': '#FFC350',
  'yellow-600': '#FFB524',
  'yellow-700': '#DF9912',
  'yellow-800': '#AC7813',
  'yellow-900': '#7D5300',
} as const

type ColorsMapType = typeof COLORS
export type Color = keyof ColorsMapType

export type ColorValue = ColorsMapType[Color]

export const getColorWeight = (color: Color): number => {
  const numberString = color.match(/\d+/)
  if (!numberString || !isNaN(+numberString[0])) return 0
  return +numberString[0]
}

export type ColorWithoutWeight =
  | 'black'
  | 'white'
  | 'grey'
  | 'purple'
  | 'green'
  | 'pink'
  | 'blue'
  | 'orange'
  | 'yellow'
