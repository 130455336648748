import {
  dateConditionToAPIParam,
  OrderChangeOfferSorting,
} from '@components/Filters/lib/helpers'
import { transformDataKeys } from '@lib/proxy/lib/transform-data-keys'
import {
  APIResponse,
  BaseClientConfig,
  DuffelAPI,
  DuffelContext,
  DuffelProxy,
  OfferSort,
  PaginationMeta,
  WebhookEventType,
} from '@lib/types'
import { AxiosRequestConfig } from 'axios'
import { camelCase } from 'lodash'
import { BaseClient } from './lib'

const DEFAULT_MAX_CONNECTIONS: DuffelAPI.Inputs.OfferRequest['maxConnections'] = 2

export class Air extends BaseClient {
  constructor(config: BaseClientConfig) {
    super(config)
  }

  getAircraft = (paginate?: PaginationMeta, ctx?: DuffelContext) => {
    const requestConfig = {
      method: 'GET',
      url: `/api/air/aircraft`,
      ...this.getRequestOptions(ctx),
    } as AxiosRequestConfig

    return paginate
      ? this.requestWithPagination<DuffelAPI.Types.Aircraft[]>(
          requestConfig,
          paginate
        )
      : this.request<DuffelAPI.Types.Aircraft[]>(requestConfig)
  }

  getAirlines = (paginate?: PaginationMeta, ctx?: DuffelContext) => {
    const requestConfig = {
      method: 'GET',
      url: `/api/air/airlines`,
      ...this.getRequestOptions(ctx),
    } as AxiosRequestConfig
    return paginate
      ? this.requestWithPagination<DuffelAPI.Types.Airline[]>(
          requestConfig,
          paginate
        )
      : this.request<DuffelAPI.Types.Airline[]>(requestConfig)
  }

  getAirline = (airlineId: string, ctx?: DuffelContext) => {
    const requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url: `/api/air/airlines/${airlineId}`,
      ...this.getRequestOptions(ctx),
    }
    return this.request<DuffelAPI.Types.Airline>(requestConfig)
  }

  getAirports = (paginate?: PaginationMeta, ctx?: DuffelContext) => {
    const requestConfig = {
      method: 'GET',
      url: `/api/air/airports`,
      ...this.getRequestOptions(ctx),
    } as AxiosRequestConfig
    return paginate
      ? this.requestWithPagination<DuffelAPI.Types.Airport[]>(
          requestConfig,
          paginate
        )
      : this.request<DuffelAPI.Types.Airport[]>(requestConfig)
  }

  createOfferRequest = (
    { supplierTimeout, ...payload }: DuffelAPI.Inputs.OfferRequest,
    returnOffers: boolean,
    retrieveNGSData: boolean,
    ctx?: DuffelContext,
    config?: AxiosRequestConfig
  ) => {
    return this.request<DuffelAPI.Types.OfferRequest>({
      method: 'POST',
      url: `/api/air/offer_requests`,
      data: {
        maxConnections: DEFAULT_MAX_CONNECTIONS,
        retrieveNGSData,
        ...payload,
      },
      params: {
        return_offers: returnOffers,
        ...(supplierTimeout && {
          supplier_timeout: supplierTimeout,
        }),
      },
      ...(config ? config : undefined),
      ...this.getRequestOptions(ctx),
    })
  }

  createPartialOfferRequest = async (
    { supplierTimeout, ...payload }: DuffelAPI.Inputs.OfferRequest,
    ctx?: DuffelContext
  ): Promise<APIResponse<DuffelAPI.Types.OfferRequest>> => {
    const response = await this.request<DuffelAPI.Types.OfferRequest>({
      method: 'POST',
      url: `/api/air/partial_offer_requests`,
      params: {
        ...(supplierTimeout && {
          supplier_timeout: supplierTimeout,
        }),
      },
      data: {
        ...payload,
        includeEmptyFareBrandsOffers: true,
        maxConnections: DEFAULT_MAX_CONNECTIONS,
      },
      ...this.getRequestOptions(ctx),
    })

    const transformedResponse = transformDataKeys(response, camelCase)
    if (response.data?.id) {
      this.partialOfferRequestsCache.set(
        keyForPartialOfferRequestCache(response.data.id, []),
        transformedResponse
      )
    }
    return transformedResponse
  }

  getOfferRequestById = async (id: string, ctx?: DuffelContext) => {
    return this.request<DuffelAPI.Types.OfferRequest>({
      method: 'GET',
      url: `/api/air/offer_requests/${id}`,
      ...this.getRequestOptions(ctx),
    })
  }

  partialOfferRequestsCache: Map<
    string,
    APIResponse<DuffelAPI.Types.OfferRequest>
  > = new Map()

  getPartialOfferRequestById = async (
    id: string,
    selectedPartialOfferIds: string[],
    ctx?: DuffelContext
  ): Promise<APIResponse<DuffelAPI.Types.OfferRequest>> => {
    const cacheKey = keyForPartialOfferRequestCache(id, selectedPartialOfferIds)
    const cachedResponse = this.partialOfferRequestsCache.get(cacheKey)
    if (cachedResponse) {
      return cachedResponse
    }
    const response = await this.request<DuffelAPI.Types.OfferRequest>({
      method: 'GET',
      url: `/api/air/partial_offer_requests/${id}`,
      params: {
        include_empty_fare_brands_offers: true,
        ...(selectedPartialOfferIds.length > 0
          ? { 'selected_partial_offer[]': selectedPartialOfferIds }
          : {}),
      },
      ...this.getRequestOptions(ctx),
    })

    const transformedResponse = transformDataKeys(response, camelCase)
    this.partialOfferRequestsCache.set(cacheKey, transformedResponse)

    return transformedResponse
  }

  getFaresByPartialOfferRequestId = async (
    id: string,
    selectedPartialOfferIds: string[],
    ctx?: DuffelContext
  ): Promise<APIResponse<DuffelAPI.Types.OfferRequest>> => {
    const response = await this.request<DuffelAPI.Types.OfferRequest>({
      method: 'GET',
      url: `/api/air/partial_offer_requests/${id}/fares`,
      params: {
        ...(selectedPartialOfferIds.length > 0 && {
          selected_partial_offer: selectedPartialOfferIds,
        }),
      },
      ...this.getRequestOptions(ctx),
    })

    return transformDataKeys(response, camelCase)
  }

  getOffer = async (offerId: string, ctx?: DuffelContext) => {
    const response = await this.request<DuffelAPI.Types.Offer>({
      method: 'GET',
      url: `/api/air/offers/${offerId}`,
      params: {
        return_available_services: true,
      },
      ...this.getRequestOptions(ctx),
    })

    return transformDataKeys(response, camelCase)
  }

  getOffers = async (
    offerRequestId: string,
    options: {
      sort?: OfferSort
      maxConnections?: number
      paginate?: PaginationMeta
    },
    ctx?: DuffelContext
  ) => {
    const params: any = {
      offer_request_id: offerRequestId,
    }
    if (options.sort) params.sort = options.sort
    if (options.maxConnections !== undefined)
      params.max_connections = options.maxConnections

    const requestConfig = {
      method: 'GET',
      url: `/api/air/offers`,
      params,
      ...this.getRequestOptions(ctx),
    } as AxiosRequestConfig

    return options.paginate
      ? this.requestWithPagination<DuffelAPI.Types.Offer[]>(
          requestConfig,
          options.paginate
        )
      : this.request<DuffelAPI.Types.Offer[]>(requestConfig)
  }

  createOrder = async (
    payload: DuffelAPI.Inputs.Order,
    ctx?: DuffelContext
  ) => {
    return this.request<DuffelAPI.Types.Order>({
      method: 'POST',
      url: `/api/air/orders`,
      data: payload,
      ...this.getRequestOptions(ctx),
    })
  }

  getOrder = async (orderId: string, ctx?: DuffelContext) => {
    return this.request<DuffelAPI.Types.Order>({
      method: 'GET',
      url: `/api/air/orders/${orderId}`,
      ...this.getRequestOptions(ctx),
    })
  }

  getOrders = async (
    paginate?: PaginationMeta,
    options?: DuffelAPI.Types.OrdersOptions,
    ctx?: DuffelContext
  ) => {
    const params = transformOrdersOptions(options)

    return paginate
      ? this.requestWithPagination<DuffelAPI.Types.Order[]>(
          {
            method: 'GET',
            url: `/api/air/orders`,
            params,
            ...this.getRequestOptions(ctx),
          },
          paginate
        )
      : this.request<DuffelAPI.Types.Order[]>({
          method: 'GET',
          url: `/api/air/orders`,
          params,
          ...this.getRequestOptions(ctx),
        })
  }

  getSeatMaps = async (offerId: string, ctx?: DuffelContext) => {
    return this.request<DuffelAPI.Types.SeatMap[]>({
      method: 'GET',
      url: `/api/air/seat_maps?offer_id=${offerId}&preview=true`,
      ...this.getRequestOptions(ctx),
    })
  }

  createOrderChangeRequest = async (
    data: DuffelAPI.Inputs.CreateOrderChangeRequest,
    ctx?: DuffelContext
  ) => {
    return this.request<DuffelAPI.Types.OrderChangeRequest>({
      method: 'POST',
      url: `/api/air/order_change_requests`,
      data,
      ...this.getRequestOptions(ctx),
    })
  }

  getOrderChangeRequest = async (
    id: DuffelAPI.Types.OrderChangeRequest['id'],
    ctx?: DuffelContext
  ) => {
    return this.request<DuffelAPI.Types.OrderChangeRequest>({
      method: 'GET',
      url: `/api/air/order_change_requests/${id}`,
      ...this.getRequestOptions(ctx),
    })
  }

  listOrderChangeOffers = async (
    forOrderChangeRequestOfferId: DuffelAPI.Types.OrderChangeRequestOffer['id'],
    sort?: OrderChangeOfferSorting,
    ctx?: DuffelContext
  ) => {
    return this.request<DuffelAPI.Types.OrderChangeRequestOffer[]>({
      method: 'GET',
      url: `/api/air/order_change_offers`,
      params: {
        order_change_request_id: forOrderChangeRequestOfferId,
        sort,
      },
      ...this.getRequestOptions(ctx),
    })
  }

  getOrderChangeOffer = async (
    id: DuffelAPI.Types.OrderChangeRequestOffer['id'],
    ctx?: DuffelContext
  ) => {
    return this.request<DuffelAPI.Types.OrderChangeRequestOffer>({
      method: 'GET',
      url: `/api/air/order_change_offers/${id}`,
      ...this.getRequestOptions(ctx),
    })
  }

  getOrderChange = async (
    id: DuffelAPI.Types.OrderChange['id'],
    ctx?: DuffelContext
  ) => {
    return this.request<DuffelAPI.Types.OrderChange>({
      method: 'GET',
      url: `/api/air/order_changes/${id}`,
      ...this.getRequestOptions(ctx),
    })
  }

  createPendingOrderChange = async (
    data: DuffelAPI.Inputs.CreatePendingOrderChange,
    ctx?: DuffelContext
  ) => {
    return this.request<DuffelAPI.Types.OrderChange>({
      method: 'POST',
      url: `/api/air/order_changes`,
      data,
      ...this.getRequestOptions(ctx),
    })
  }

  confirmOrderChange = async (
    id: DuffelAPI.Types.OrderChange['id'],
    payment: DuffelAPI.Inputs.ConfirmOrderChangePayment,
    ctx?: DuffelContext
  ) => {
    return this.request<DuffelAPI.Types.OrderChange>({
      method: 'POST',
      url: `/api/air/order_changes/${id}/actions/confirm`,
      data: { payment },
      ...this.getRequestOptions(ctx),
    })
  }

  getOrganisationSources(ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.OrganisationSources>({
      method: 'GET',
      url: `/api/air/organisation_sources`,
      ...this.getRequestOptions(ctx),
    })
  }

  getOrganisationSourceById(
    id: DuffelAPI.Types.OrganisationSource['id'],
    ctx?: DuffelContext
  ) {
    return this.request<DuffelAPI.Types.OrganisationSource>({
      method: 'GET',
      url: `/api/air/organisation_sources/${id}`,
      ...this.getRequestOptions(ctx),
    })
  }

  createOrganisationSourceRequest(
    data: DuffelProxy.Input.CreateOrganisationSourceRequest,
    ctx?: DuffelContext
  ) {
    return this.request<DuffelProxy.Types.CreateOrganisationSourceRequestResponse>(
      {
        method: 'POST',
        url: `/api/air/organisation_source_requests`,
        data,
        ...this.getRequestOptions(ctx),
      }
    )
  }

  createWebhook(url: string, events: string[], ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.Webhook & { secret: string }>({
      method: 'POST',
      url: '/api/air/webhooks',
      data: {
        url,
        events,
      },
      ...this.getRequestOptions(ctx),
    })
  }

  listWebhooks(ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.Webhook[] | null>({
      method: 'GET',
      url: '/api/air/webhooks',
      ...this.getRequestOptions(ctx),
    })
  }

  pingWebhook(id: string, ctx?: DuffelContext) {
    return this.request({
      method: 'POST',
      url: `/api/air/webhooks/${id}/actions/ping`,
      ...this.getRequestOptions(ctx),
    })
  }

  updateWebhook(
    id: string,
    url: string,
    active: boolean,
    events?: WebhookEventType[],
    ctx?: DuffelContext
  ) {
    return this.request<DuffelAPI.Types.Webhook>({
      method: 'PATCH',
      url: `/api/air/webhooks/${id}`,
      data: {
        url,
        active,
        events,
      },
      ...this.getRequestOptions(ctx),
    })
  }

  deleteWebhook(id: string, ctx?: DuffelContext) {
    return this.request({
      method: 'DELETE',
      url: `/api/air/webhooks/${id}`,
      ...this.getRequestOptions(ctx),
    })
  }

  listWebhookDeliveries(
    paginate?: PaginationMeta,
    options?: DuffelAPI.Inputs.WebhookDeliveriesOptions,
    ctx?: DuffelContext
  ) {
    let params: any = {}
    if (options?.endpointId) params['endpoint_id'] = options.endpointId
    if (options?.type) params.type = options.type
    if (options?.deliverySuccess !== undefined)
      params['delivery_success'] = options.deliverySuccess
    if (options?.createdAt) {
      params = {
        ...params,
        ...dateConditionToAPIParam('created_at', options.createdAt),
      }
    }
    if (options?.type) {
      params['type'] = options.type
    }

    const requestConfig = {
      method: 'GET',
      url: '/api/air/webhooks/deliveries',
      params,
      ...this.getRequestOptions(ctx),
    }

    return paginate
      ? this.requestWithPagination<DuffelAPI.Types.WebhookDelivery[]>(
          requestConfig,
          paginate
        )
      : this.request<DuffelAPI.Types.WebhookDelivery[]>(requestConfig)
  }

  redeliverWebhookEvent(id: string, ctx?: DuffelContext) {
    return this.request({
      method: 'POST',
      url: `/api/air/webhooks/events/${id}/actions/redeliver`,
      ...this.getRequestOptions(ctx),
    })
  }

  getWebhookEvent(id: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.WebhookEvent>({
      method: 'GET',
      url: `/api/air/webhooks/events/${id}`,
      ...this.getRequestOptions(ctx),
    })
  }
}

const transformOrdersOptions = (
  options: DuffelAPI.Types.OrdersOptions | undefined
) => {
  let params: any = {}
  if (options?.sort) params.sort = options.sort
  if (options?.awaitingPayment)
    params.awaiting_payment = options.awaitingPayment
  if (options?.bookingReference)
    params.booking_reference = options.bookingReference
  if (options?.passengerNames) {
    params.passenger_name = options.passengerNames
  }
  if (options?.requiresAction) {
    params.requires_action = options.requiresAction
  }
  if (options?.ownerIds) {
    params.owner_id = options.ownerIds
  }
  if (options?.departingAt) {
    params = {
      ...params,
      ...dateConditionToAPIParam('departing_at', options.departingAt),
    }
  }
  if (options?.arrivingAt) {
    params = {
      ...params,
      ...dateConditionToAPIParam('arriving_at', options.arrivingAt),
    }
  }
  if (options?.createdAt) {
    params = {
      ...params,
      ...dateConditionToAPIParam('created_at', options.createdAt),
    }
  }
  if (options?.originIds) {
    params.origin_id = options.originIds
  }
  if (options?.destinationIds) {
    params.destination_id = options.destinationIds
  }
  return params
}

const keyForPartialOfferRequestCache = (
  id: string,
  selectedPartialOfferIds: string[]
) =>
  selectedPartialOfferIds.length
    ? [id, selectedPartialOfferIds.join(',')].join('_')
    : id
