import { useField, useFormikContext } from 'formik'
import * as React from 'react'
import { FormField } from '@components/Form'
import { internationalPhoneNumberRegex } from '@lib/form-validation'
import { Input } from '../Input'

const formatRawInput = (value: string): string => {
  if (value === '+') return '+'

  value = value.replace(/([^0-9\s])/g, '')
  if (value === '') return value
  value = '+' + value

  const inputMatch = value.match(internationalPhoneNumberRegex)

  if (!inputMatch) return value

  const code = inputMatch[1]
  let number = value.replace(`+${code} `, '')
  if (number === value) {
    number = value.replace(`+${code}`, '')
  }

  if (number.length === 14) number = number.slice(0, 13)

  value = `+${code} ${number}`

  return value
}

interface PhoneInputProps {
  id?: string
  value: string
  onBlur?: (e: React.FocusEvent) => void
  onChange: (value: string) => void
  disabled: boolean
}

export const PhoneInput: React.FC<PhoneInputProps> = ({
  value,
  onBlur,
  onChange,
  id,
  disabled,
  ...fieldProps
}) => {
  return (
    <Input
      {...fieldProps}
      id={id}
      placeholder="+1 617 756 2626"
      type="tel"
      value={formatRawInput(value)}
      onChange={(event) => {
        onChange(formatRawInput(event.target.value))
      }}
      onBlur={onBlur}
      disabled={disabled}
    />
  )
}

interface FormikPhoneInputProps {
  className?: string
  id?: string
  showRequired?: boolean
  label: string
  name: string
  disabled?: boolean
}

export const FormikPhoneInput: React.FC<FormikPhoneInputProps> = ({
  id,
  name,
  label,
  showRequired,
  className,
  disabled = false,
}) => {
  const [field, { touched, error }, { setValue }] = useField({
    name,
  })
  const { submitCount, isSubmitting } = useFormikContext()
  const isDisabled = isSubmitting || disabled

  return (
    <FormField
      className={className}
      label={label}
      htmlFor={id}
      optionalField={!showRequired}
      error={touched || submitCount > 0 ? error : undefined}
      disabled={isDisabled}
    >
      <PhoneInput
        {...field}
        id={id}
        disabled={isDisabled}
        onChange={setValue}
      />
    </FormField>
  )
}
