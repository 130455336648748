export const setupOnTabListenersOnCalendarDays = (onClosePopover: () => void) =>
  setTimeout(() => {
    const monthContainer = document.getElementsByClassName('DayPicker-Month')
    const lastMonth = monthContainer.item(monthContainer.length - 1)
    if (!lastMonth) return

    const days = lastMonth.querySelectorAll<HTMLDivElement>(
      '.DayPicker-Day:not(.DayPicker-Day--outside)'
    )
    days.forEach((dayElement) =>
      dayElement.addEventListener('keydown', (event) => {
        if (event.key === 'Tab') {
          setTimeout(() => onClosePopover(), 0)
        }
      })
    )
  }, 0)
