import { DuffelAPI, SearchType } from '@lib/types'
import { generateId } from '@lib/helpers'
import { getDateObject } from '@lib/date'
import { captureException } from '@lib/sentry'
import { CommonSearchFormSectionValues } from '../forms/SearchForm/CommonSearchFormSection'
import { SearchFormValues } from './transform-search-form-values'
import { DEFAULT_SUPPLIER_TIMEOUT } from './get-initial-search-form-values'

export const getSearchFormValues = (
  fromOfferRequest: DuffelAPI.Types.OfferRequest,
  searchFormValuesFromQuery: SearchFormValues<SearchType>,
  removeKeyForUnitTest?: boolean
): SearchFormValues<SearchType> => {
  const commonValues: Omit<CommonSearchFormSectionValues, 'airlines'> = {
    passengers: fromOfferRequest.passengers.map((passenger) => {
      // we don't want to send ID back to the API
      const {
        id: _id,
        age: age,
        familyName,
        givenName,
        loyaltyProgrammeAccounts = [],
        type,
      } = passenger

      const formattedPassenger: DuffelAPI.Inputs.OfferRequestPassengerCommon = {
        ...(familyName ? { familyName } : {}),
        ...(givenName ? { givenName } : {}),
        loyaltyProgrammeAccounts,
      }

      if (type === 'child' && age !== undefined) {
        const childPassenger: DuffelAPI.Inputs.OfferRequestChildPassenger = {
          ...(!removeKeyForUnitTest ? { key: generateId() } : undefined),
          ...formattedPassenger,
          age,
        }
        return childPassenger
      } else {
        const adultPassenger: DuffelAPI.Inputs.OfferRequestPassenger = {
          ...(!removeKeyForUnitTest ? { key: generateId() } : undefined),
          type: 'adult',
          ...formattedPassenger,
        }
        return adultPassenger
      }
    }),
    cabinClass: fromOfferRequest.cabinClass || 'any',
    supplierTimeout:
      searchFormValuesFromQuery.supplierTimeout || DEFAULT_SUPPLIER_TIMEOUT,
  }

  const slicesFromOfferRequest = fromOfferRequest.slices.map((slice) => ({
    origin: slice.origin.iataCode,
    destination: slice.destination.iataCode,
    departureDate: getDateObject(slice.departureDate),
  }))

  if (searchFormValuesFromQuery.type === 'one_way') {
    const formValues: SearchFormValues<'one_way'> = {
      ...searchFormValuesFromQuery,
      type: 'one_way',
      ...slicesFromOfferRequest[0],
      ...commonValues,
    }
    return formValues
  } else if (searchFormValuesFromQuery.type === 'return') {
    const formValues: SearchFormValues<'return'> = {
      ...searchFormValuesFromQuery,
      type: 'return',
      ...slicesFromOfferRequest[0],
      returnDate:
        slicesFromOfferRequest.length > 1
          ? slicesFromOfferRequest[1].departureDate
          : null,
      ...commonValues,
    }
    return formValues
  }

  // capture exception if the slice of the offer request doesn't align with the
  // slice from the query
  if (
    slicesFromOfferRequest.length !== searchFormValuesFromQuery.slices.length
  ) {
    captureException(
      new Error(
        `The number of slices from the offer request (${slicesFromOfferRequest.length}) doesn't match the number of slices from the query (${searchFormValuesFromQuery.slices.length}). OfferRequestId: ${fromOfferRequest.id}`
      )
    )
  }

  const formValues: SearchFormValues<'multi_city'> = {
    ...searchFormValuesFromQuery,
    type: 'multi_city',
    slices: slicesFromOfferRequest.map((slice, index) => ({
      ...slice,
      // fill in the time ranges from the query since the offer request
      // doesn't store that information
      timeRanges: searchFormValuesFromQuery.slices[index]?.timeRanges,
    })),
    ...commonValues,
  }
  return formValues
}
