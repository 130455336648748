import * as React from 'react'
import { Icon } from '@components/Icon'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'

export interface SupportContactSubmittedProps {
  email: string
}

export const SupportContactSubmitted: React.FC<
  SupportContactSubmittedProps
> = ({ email }) => (
  <VSpace space={16} className="support-contact__submitted-container">
    <div className="circle">
      <Icon name="check" />
    </div>
    <VSpace space={8}>
      <Text
        color="grey-900"
        fontWeight="medium"
        textAlign="center"
        data-selector="fs-show"
      >
        Message sent
      </Text>
      <Text color="grey-700" fontSize="C2" textAlign="center">
        We aim to respond to all messages within 1 working day. We've sent
        confirmation of your query to <b>{email || 'your email'}</b>.
      </Text>
    </VSpace>
    <style jsx>{`
      :global(.support-contact__submitted-container) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        padding: var(--space-32);
      }

      .circle {
        height: 40px;
        width: 40px;
        background-color: var(--green-600) !important;
        color: white !important;
        border-radius: 50% !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `}</style>
  </VSpace>
)
