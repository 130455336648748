import * as React from 'react'
import { HSpace } from '@components/HSpace'
import { TimelineItem } from '@components/TimelineItem'
import { renderTimelineDateTime } from '@components/TimelineItem/lib/render-timeline-datetime'
import { DuffelAPI } from '@lib/types'
import { moneyStringFormatter } from '@lib/helpers'
import styles from './OrderOnHoldTimeline.module.css'

export interface OrderOnHoldTimelineProps {
  order: DuffelAPI.Types.Order
}

export const OrderOnHoldTimeline = React.forwardRef<
  HTMLDivElement,
  OrderOnHoldTimelineProps
>(({ order }, ref) => {
  if (
    !order.paymentStatus.priceGuaranteeExpiresAt ||
    !order.paymentStatus.paymentRequiredBy
  ) {
    return null
  }

  const firstSegment = order.slices[0]?.segments[0]
  const priceAndSpaceExpireOnSameDay =
    order.paymentStatus.priceGuaranteeExpiresAt ===
    order.paymentStatus.paymentRequiredBy

  const departureTimezone = firstSegment.origin.timeZone

  return (
    <HSpace space={0} className={styles['timeline-info']} ref={ref}>
      <TimelineItem
        label="Booked"
        aboveDot={renderTimelineDateTime(order.createdAt)}
        lineColorRight="green-700"
        dotColor="green-700"
      />
      {priceAndSpaceExpireOnSameDay ? (
        <TimelineItem
          label="Price & space hold expires"
          description={moneyStringFormatter(order.totalCurrency)(
            +order.totalAmount
          )}
          aboveDot={renderTimelineDateTime(
            order.paymentStatus.priceGuaranteeExpiresAt!
          )}
          lineColorLeft="green-700"
          lineColorRight="pink-700"
          dotColor="pink-700"
        />
      ) : (
        <>
          <TimelineItem
            label="Price hold expires"
            description={moneyStringFormatter(order.totalCurrency)(
              +order.totalAmount
            )}
            aboveDot={renderTimelineDateTime(
              order.paymentStatus.priceGuaranteeExpiresAt!
            )}
            lineColorLeft="green-700"
            lineColorRight="yellow-700"
            dotColor="yellow-700"
          />
          <TimelineItem
            label="Space hold expires"
            aboveDot={renderTimelineDateTime(
              order.paymentStatus.paymentRequiredBy!
            )}
            lineColorLeft="yellow-700"
            lineColorRight="pink-700"
            dotColor="pink-700"
          />
        </>
      )}
      <TimelineItem
        label={`Flight to ${firstSegment.destination.iataCode}`}
        aboveDot={renderTimelineDateTime(
          firstSegment.departingAt,
          true,
          departureTimezone
        )}
        lineColorLeft="pink-700"
        size="extra-large"
      />
    </HSpace>
  )
})
