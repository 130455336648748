import Axios from 'axios'
import * as React from 'react'
import { captureException } from '@lib/sentry'

interface StripeCardData {
  brand: string
  exp_month: number
  exp_year: number
  last4: string
}

export const useStripeCard = (stripePaymentMethodId?: string) => {
  const [cardData, setCardData] = React.useState<StripeCardData | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)
  React.useEffect(() => {
    const getCardData = async () => {
      try {
        setIsLoading(true)
        const { data } = await Axios.get(`/api/stripe/payment_methods`, {
          params: { id: stripePaymentMethodId },
        })
        setCardData(data.card)
      } catch (error) {
        if (Axios.isAxiosError(error)) {
          // We can ignore 404 errors since we expect some users to not have
          // any card details on the database.
          if (error.response?.status !== 404) {
            captureException(error)
          }
        }
      } finally {
        setIsLoading(false)
      }
    }
    if (stripePaymentMethodId) {
      getCardData()
    }
  }, [setCardData, stripePaymentMethodId])

  return { cardData, isLoading }
}
