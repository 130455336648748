import * as React from 'react'
import classNames from 'classnames'

export interface SelectProps {
  id?: string
  name?: string
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  value?: string | number | string[]
  width?: string
  className?: string
  disabled?: boolean
  tabIndex?: number
  onKeyDown?: (event: React.KeyboardEvent<HTMLSelectElement>) => void
  isError?: boolean
  placeholder?: string
}

export const Select: React.FC<React.PropsWithChildren<SelectProps>> = ({
  className,
  width = '100%',
  children,
  isError = false,
  placeholder,
  ...props
}) => {
  return (
    <>
      <select
        className={classNames('ff-select', className, {
          'ff-select--is-error': isError,
        })}
        {...(placeholder && {
          placeholder: placeholder,
          required: true,
          defaultValue: '',
        })}
        {...props}
      >
        {placeholder && (
          <option
            value=""
            disabled
            hidden
            defaultValue=""
            className="select__placeholder"
          >
            {placeholder}
          </option>
        )}
        {children}
      </select>
      <style jsx>{`
        .ff-select {
          appearance: none;
          background: var(--white);
          background-image: url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 3.70801L0.5 0.208008H7.5L4 3.70801Z' fill='%23161618'/%3E%3C/svg%3E%0A");
          background-position: right 16px center;
          background-repeat: no-repeat;
          border: 1px solid var(--grey-300);
          background-size: 10px 10px;
          font-family: var(--FONTS-SANS);
          font-size: 16px;
          font-weight: normal;
          height: 40px;
          width: ${width};
          outline: 0;
          padding: 0 56px 0 16px;
          vertical-align: middle;
          color: var(--purple-900);
          border-radius: var(--border-radius-6);
          transition: all var(--transitions-default);
        }

        .ff-select:hover {
          border: solid 1px var(--purple-600);
        }

        .ff-select:focus {
          border: solid 1px var(--purple-600);
          box-shadow: 0 0 0 3px var(--purple-200);
        }

        .ff-select--is-error {
          border-color: var(--pink-400);
        }

        .ff-select--is-error:hover,
        .ff-select.ff-select--is-error:focus {
          box-shadow: 0 0 0 3px var(--pink-200);
          border-color: var(--pink-600);
        }

        .ff-select:disabled,
        .ff-select:disabled:hover {
          color: var(--grey-500);
          border: 1px solid var(--grey-300);
          background-color: var(--grey-100);
          box-shadow: none;
          cursor: default;
        }

        select:invalid {
          color: var(--grey-400);
        }

        select:required:invalid {
          color: var(--grey-400);
        }
      `}</style>
    </>
  )
}
