import { differenceInMilliseconds, isFuture } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { DuffelAPI } from '@lib/types'

export const getNextDepartureDateInUTC = (
  order: DuffelAPI.Types.Order
): Date | null => {
  const sortedDepartureDates = order.slices
    .flatMap((slice) => slice.segments)
    .map((segment) =>
      zonedTimeToUtc(new Date(segment.departingAt), segment.origin.timeZone)
    )
    .filter(isFuture)
    .sort(differenceInMilliseconds)

  return sortedDepartureDates.length > 0 ? sortedDepartureDates[0] : null
}
