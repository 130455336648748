import * as React from 'react'
import classNames from 'classnames'
import { Text } from '@components/Text'
import { convertRelativeDatePartsToString } from '@lib/date'

interface ConfirmAndHoldProps {
  label: string
  relativeDate: Intl.RelativeTimeFormatPart[]
  date: string
  type: string
}

export const ConfirmAndHold: React.FC<ConfirmAndHoldProps> = ({
  label,
  relativeDate,
  date,
  type,
}) => {
  const dateStrings = convertRelativeDatePartsToString(relativeDate) as {
    type: Exclude<Intl.NumberFormatPartTypes, 'literal'>
    value: string
    unit: Intl.RelativeTimeFormatUnitSingular
  }
  return (
    <div className={classNames('pay-later__card', `pay-later__card--${type}`)}>
      <Text fontSize="C1" color="grey-900" textAlign="center">
        {label}
      </Text>
      <Text fontSize="C1" color="grey-900" textAlign="center">
        <Text fontSize="H1" asElement="span">
          {dateStrings ? dateStrings.value : ''}
        </Text>{' '}
        {dateStrings.unit}
      </Text>
      <Text fontSize="C3" color="grey-900" textAlign="center">
        Pay by {date}
      </Text>
      <style jsx>{`
        .pay-later__card {
          min-height: 200px;
          justify-content: space-evenly;
          flex-direction: column;
          display: flex;
          padding: var(--space-32);
          flex: 1 65%;
        }
        .pay-later__card :global(> p:not(:first-child)) {
          margin-top: var(--space-16);
        }
        .pay-later__card:only-child {
          flex: 1 0 auto;
        }
        .pay-later__card.pay-later__card--price {
          background-color: var(--yellow-100);
        }
        .pay-later__card.pay-later__card--space {
          background-color: var(--purple-100);
        }
      `}</style>
    </div>
  )
}
