import { SearchResponse } from 'pages/api/search'
import { makeMockOrderSearchResult } from './make-mock-order-search-result'

const getDefaultObject = (() => (): SearchResponse => ({
  orders: [
    makeMockOrderSearchResult(),
    makeMockOrderSearchResult(),
    makeMockOrderSearchResult(),
  ],
  bookings: [],
  estimatedTotalHits: 3,
  returnedHits: 3,
}))()

export const makeMockSearchResponse = (
  extendDefault?: Partial<SearchResponse>
): SearchResponse => Object.assign({}, getDefaultObject(), extendDefault || {})
