import { ParsedUrlQuery } from 'querystring'
import { OrdersTab, ordersTabs } from '../containers'

export const ORDERS_ACTIVE_TAB_QUERY_PARAM_KEY = 'activeTab'

const isOrdersTab = (value: unknown): value is OrdersTab => {
  return ordersTabs.find((tab) => tab === value) !== undefined
}

export const ordersActiveTabFromQuery = (query: ParsedUrlQuery): OrdersTab => {
  const activeTabParam = query[ORDERS_ACTIVE_TAB_QUERY_PARAM_KEY]

  if (isOrdersTab(activeTabParam)) {
    return activeTabParam
  }
  return 'all'
}
