export const DUFFEL_API_AUTHENTICATION_COOKIE_NAME = '_duffel_web_key'
export const CSRF_TOKEN_COOKIE_NAME = '_duffel_csrf_token'
export const CSRF_TOKEN_HEADER_NAME = 'x-csrf-token'

export const CSRF_TOKEN_COOKIE_OPTIONS = {
  path: '/',
  secure: true,
  domain:
    process.env.NEXT_PUBLIC_APP_ENV === 'staging' ||
    process.env.NEXT_PUBLIC_APP_ENV === 'production'
      ? '.duffel.com'
      : '.localhost',
  maxAge: 86400, // 24 hours
}
