import { DuffelAPI } from '@lib/types'
import { makeMockAircraft } from './make-mock-aircraft'
import { makeMockAirline } from './make-mock-airline'
import { makeMockAirport } from './make-mock-airport'
import { makeMockOrderSliceSegmentPassenger } from './make-mock-order-slice-segment-passenger'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.OrderSliceSegment => {
    const id = (seedId++).toString().padStart(5, '0')
    return {
      id: `seg_${id}`,
      passengers: [makeMockOrderSliceSegmentPassenger()],
      originTerminal: '2',
      origin: makeMockAirport(),
      operatingCarrierFlightNumber: '0455',
      operatingCarrier: makeMockAirline(),
      marketingCarrierFlightNumber: '0455',
      marketingCarrier: makeMockAirline(),
      duration: 'PT8H10M',
      distance: '5688.773368595326',
      destinationTerminal: '7',
      destination: makeMockAirport(),
      departingAt: '2028-03-24T23:00:00',
      arrivingAt: '2028-03-25T07:10:00',
      aircraft: makeMockAircraft(),
      stops: [],
    }
  }
})()

export const makeMockOrderSliceSegment = (
  extendDefault?: Partial<DuffelAPI.Types.OrderSliceSegment>,
  segmentId?: DuffelAPI.Types.OrderSliceSegment['id'],
  passengerIds?: DuffelAPI.Types.OrderSegmentPassenger['passengerId'][]
): DuffelAPI.Types.OrderSliceSegment => {
  const mockOrderSliceSegment = Object.assign(
    {},
    getDefaultObject(),
    extendDefault || {}
  )

  if (segmentId) {
    mockOrderSliceSegment.id = segmentId
  }

  if (passengerIds) {
    mockOrderSliceSegment.passengers = passengerIds.map((passengerId) =>
      makeMockOrderSliceSegmentPassenger({}, passengerId)
    )
  }

  return mockOrderSliceSegment
}
