import * as React from 'react'
import classNames from 'classnames'
import { Icon } from '@components/Icon'
import { Tooltip } from '@components/Tooltip'
import { copyToClipboard } from '@lib/helpers'
import styles from './CopyableValue.module.css'

export interface CopyableValueProps {
  children: React.ReactNode
  valueToCopy: string
  className?: string
  iconSize?: 'small' | 'default'
  stopEventPropagationOnClick?: boolean
  tooltipText?: string
  inline?: boolean
}

export const CopyableValue: React.FC<CopyableValueProps> = ({
  children,
  valueToCopy,
  className,
  iconSize = 'default',
  stopEventPropagationOnClick = false,
  tooltipText = 'Copy',
  inline = false,
}) => {
  const [hasCopied, setHasCopied] = React.useState(false)

  return (
    <div
      className={classNames(
        'copyable-value',
        inline && styles['copyable-value--inline'],
        className
      )}
    >
      <Tooltip text={hasCopied ? 'Copied' : tooltipText} placement="top">
        <button
          className={styles['container']}
          onClick={(event) => {
            stopEventPropagationOnClick && event.stopPropagation()
            copyToClipboard(valueToCopy)
            setHasCopied(true)
          }}
          onMouseLeave={() => setHasCopied(false)}
        >
          {children}
          <Icon
            className={styles['copy-icon']}
            name="copy_outline"
            size={iconSize === 'small' ? 16 : 18}
          />
        </button>
      </Tooltip>
    </div>
  )
}
