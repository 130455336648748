import * as React from 'react'
import { Autofill } from '@components/Autofill'

import { useWorkspace } from '@lib/workspace-context'
import styles from './IataCodeFilterInput.module.css'

interface IataCodeFilterInputProps {
  value?: string
  onChange: (id: string) => void
}

export const IataCodeFilterInput: React.FC<IataCodeFilterInputProps> = ({
  value,
  onChange,
}) => {
  const { duffelClient } = useWorkspace()
  const [searchTerm, setSearchTerm] = React.useState(
    value?.split('_')[1]?.toUpperCase() ?? ''
  )
  return (
    <div className={styles['container']}>
      <Autofill
        id={'iata-filter-autofill'}
        className={styles['autofill-input']}
        value={searchTerm}
        onChange={(value) => {
          if (typeof value === 'string') {
            setSearchTerm(value)
            // send the empty value back to signal that
            // the pending value is now not valid
            onChange('')
            return
          }

          onChange(value.id)
          setSearchTerm(value.iataCode)
        }}
        lookup={async (query: string) => {
          const { data } = await duffelClient.Places.suggest(query)
          return data ?? []
        }}
        renderResultLabel={({ iataCode, name }) => `${name} (${iataCode})`}
      />
    </div>
  )
}
