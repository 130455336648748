import * as React from 'react'
import { useField } from 'formik'
import { Checkbox, CheckboxProps } from '@components/Checkbox'
import { HSpace } from '@components/HSpace'
import { Text } from '@components/Text'
import { FormField } from './FormField'

interface FormikCheckboxProps extends Pick<CheckboxProps, 'size' | 'intent'> {
  name: string
  label: string
  hint?: string
}

export const FormikCheckbox: React.FC<FormikCheckboxProps> = ({
  name,
  label,
  hint,
  ...props
}) => {
  const [, { value }, { setValue }] = useField<boolean>(name)
  return (
    <FormField hint={hint}>
      <HSpace space={8} alignCenter>
        <div>
          <Checkbox
            id={name}
            label={label}
            checked={value}
            onChange={setValue}
            {...props}
          />
        </div>
        <Text>{label}</Text>
      </HSpace>
    </FormField>
  )
}
