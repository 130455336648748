import { DuffelAPI } from '@lib/types'

export const getLatestAIC = (
  order: DuffelAPI.Types.Order
): DuffelAPI.Types.AirlineInitiatedChange | null => {
  const { airlineInitiatedChanges } = order

  if (airlineInitiatedChanges && airlineInitiatedChanges?.length > 0) {
    return airlineInitiatedChanges.reduce((first, second) =>
      new Date(first.createdAt) > new Date(second.createdAt) ? first : second
    )
  }

  return null
}
