import { ColorWithoutWeight } from '@components/legacy-design-system/product/styles'
import classnames from 'classnames'
import * as React from 'react'

import styles from './Invoice.module.css'

export interface InvoiceCommonProps {
  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string

  /**
   * Used to specify a unique id for an HTML element
   */
  id?: string
}

export const InvoiceHeader: React.FC<
  React.PropsWithChildren<InvoiceCommonProps>
> = ({ className, id, children }) => {
  const idProps = {
    id,
    'date-testid': id,
    'data-selector': 'fs-show',
  }
  return (
    // this "invoice-header" class is being reached in from outside the component
    // in Transactions so we are keeping it for now to avoid having to update
    // that file too.
    <thead {...idProps} className={classnames('invoice-header', className)}>
      {children}
    </thead>
  )
}

export const InvoiceRow: React.FC<
  React.PropsWithChildren<InvoiceCommonProps>
> = ({ className, id, children }) => {
  const idProps = {
    id,
    'data-testid': id,
  }
  return (
    <tr {...idProps} className={className}>
      {children}
    </tr>
  )
}

export const InvoiceBody: React.FC<
  React.PropsWithChildren<InvoiceCommonProps>
> = ({ className, id, children }) => {
  const cn = classnames(styles['invoice-body'], className)
  const idProps = {
    id,
    'date-testid': id,
  }
  return (
    <tbody {...idProps} className={cn}>
      {children}
    </tbody>
  )
}

export const InvoiceFooter: React.FC<
  React.PropsWithChildren<InvoiceCommonProps>
> = ({ className, id, children }) => {
  const idProps = {
    id,
    'date-testid': id,
  }
  return (
    <tfoot {...idProps} className={className}>
      {children}
    </tfoot>
  )
}

export interface InvoiceProps
  extends InvoiceCommonProps,
    React.DetailedHTMLProps<
      React.TableHTMLAttributes<HTMLTableElement>,
      HTMLTableElement
    > {
  /**
   * What color theme to use
   */
  color?: Extract<ColorWithoutWeight, 'grey' | 'purple'> | 'white'
}

/**
 * An invoice visualises a granular breakdown of paid services.
 */
export const Invoice: React.FC<InvoiceProps> = ({
  className,
  children,
  color = 'grey',
  ...props
}) => {
  const cn = classnames(
    styles['invoice'],
    styles[`invoice--${color}`],
    className
  )

  return (
    <table className={cn} {...props}>
      {children}
    </table>
  )
}
