import { ref, string } from 'yup'

export const passwordRequirements: {
  message: string
  onlyDisplayIfInvalid: boolean
}[] = [
  {
    message: 'at least 10 characters',
    onlyDisplayIfInvalid: false,
  },
  {
    message: 'less than 100 characters',
    onlyDisplayIfInvalid: true,
  },
  {
    message: 'a lowercase letter',
    onlyDisplayIfInvalid: false,
  },
  {
    message: 'an uppercase letter',
    onlyDisplayIfInvalid: false,
  },
  {
    message: 'a numerical character',
    onlyDisplayIfInvalid: false,
  },
  {
    message: 'a special character',
    onlyDisplayIfInvalid: false,
  },
]

/**
 * Password validation criteria used in yup validation schemas.
 */
export const passwordValidationSchema = string()
  .required('Cannot be blank')
  .min(10, passwordRequirements[0].message)
  .max(100, passwordRequirements[1].message)
  .matches(/[a-z]/, passwordRequirements[2].message)
  .matches(/[A-Z]/, passwordRequirements[3].message)
  .matches(/[0-9]/, passwordRequirements[4].message)
  .matches(/[^a-zA-Z0-9]/, passwordRequirements[5].message)

/**
 * Confirm password validation criteria used in validation schemas.
 * @param matchPasswordField The name of the first password field to match against.
 * @returns The validation schema object for password confirmation.
 */
export const confirmPasswordValidation = (matchPasswordField: string) =>
  string()
    .required('Cannot be blank')
    .oneOf([ref(matchPasswordField)], 'Passwords must match')

/**
 * Translates a validation error string for displaying to the user.
 * If the error starts with a lower-case letter, it's treated as a "must
 * contain" error; otherwise, it's treated as a full error string already.
 * @param error The validation error string.
 * @returns The formatted error string.
 */
export const formatValidationErrorForDisplay = (error: string) => {
  return error[0] !== error[0].toUpperCase() ? 'Must contain ' + error : error
}
