import * as React from 'react'
import classNames from 'classnames'
import { HSpace } from '@components/HSpace'
import { Icon } from '@components/Icon'
import { Text } from '@components/Text'
import { getDayDiff, getTimeString, isDateTimeInTheFuture } from '@lib/date'
import styles from './OrderOnHoldInformationItem.module.css'

export interface OrderOnHoldInformationItemProps {
  date: string
  holdType: 'The price guarantee' | 'Space'

  // overriding today date for testing
  todayDateOverride?: string
}

export const OrderOnHoldInformationItem = React.forwardRef<
  HTMLDivElement,
  OrderOnHoldInformationItemProps
>(({ date, holdType, todayDateOverride }, ref) => {
  const isInTheFuture = isDateTimeInTheFuture(date, todayDateOverride)
  const cx = classNames(styles['content'], {
    [styles['content--future']]: isInTheFuture,
    [styles['content--past']]: !isInTheFuture,
  })
  const dayDiff = getDayDiff(date, todayDateOverride)
  let dayDiffString = 'today at ' + getTimeString(date, 'short')
  if (dayDiff === 1) {
    dayDiffString = 'tomorrow at ' + getTimeString(date, 'short')
  } else if (dayDiff > 1) {
    dayDiffString = `in ${dayDiff} days`
  }

  const iconName = isInTheFuture ? 'check' : 'close'
  const before =
    holdType === 'The price guarantee'
      ? 'After this prices for your trip may change.'
      : 'After this the space will be released and you will need to rebook.'
  const after = 'Prices for your trip could now change.'

  return (
    <HSpace space={12} className={cx} ref={ref}>
      <Icon name={iconName} size={16} />
      <Text color="grey-600">
        {holdType}{' '}
        {isInTheFuture ? (
          <>
            expires{' '}
            <Text color="grey-900" fontWeight="medium" asElement="span">
              {dayDiffString}
            </Text>
            .
          </>
        ) : (
          'has expired.'
        )}{' '}
        {isInTheFuture ? before : after}
      </Text>
    </HSpace>
  )
})
