import classNames from 'classnames'
import * as React from 'react'
import { PropsOf } from '@components/legacy-design-system/shared/foundations'
import { HSpace } from '@components/HSpace'
import { Stamp } from '@components/Stamp'

import styles from './TabButtons.module.css'

export interface TabOption {
  label: string
  numberOfItems?: string
  intent?: 'default' | 'danger'
}

export interface TabButtonsProps<T extends string> {
  /**
   * Current active tab
   */
  value: string
  /**
   * onChange function to handle tab functionality
   */
  onChange: (value: T) => void
  /**
   * Tabs items
   */
  options: Record<T, TabOption>
  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string
  /**
   * Disable buttons due to same event
   */
  disabled?: boolean

  /**
   * Hides tab options where `numberOfItems` is 0
   */
  showTabOptionsWithNoItems?: boolean
}

const TabButton: React.FC<PropsOf<'button'> & { active: boolean }> = ({
  active,
  className,
  ...props
}) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  React.useEffect(() => {
    if (active) {
      buttonRef.current?.focus()
    }
  }, [active])
  return (
    <button
      {...props}
      ref={buttonRef}
      className={classNames(
        className,
        styles['tab-button'],
        active && styles['tab-button--active']
      )}
      role="tab"
      aria-selected={active}
      tabIndex={active ? undefined : -1}
    />
  )
}

export const TabButtons = <T extends string>({
  value,
  onChange,
  options,
  showTabOptionsWithNoItems = true,
  className,
  ...props
}: TabButtonsProps<T>): React.ReactElement => {
  const [isTabFocused, setIsTabFocused] = React.useState(false)

  React.useEffect(() => {
    if (!isTabFocused) {
      return
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      const optionKeys = Object.keys(options)
      const currentOptionIndex = optionKeys.findIndex((key) => key === value)
      switch (event.key) {
        case 'ArrowRight': {
          event.preventDefault()
          onChange(
            optionKeys[(currentOptionIndex + 1) % optionKeys.length] as T
          )
          break
        }
        case 'ArrowLeft': {
          event.preventDefault()
          onChange(
            optionKeys[
              (currentOptionIndex + optionKeys.length - 1) % optionKeys.length
            ] as T
          )
          break
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isTabFocused, options, value, onChange])

  return (
    <HSpace
      className={classNames(className, styles['tabs'])}
      space={24}
      role="tablist"
      {...props}
    >
      {Object.entries<TabOption>(options)
        .filter(
          ([_, option]) =>
            showTabOptionsWithNoItems || option.numberOfItems !== '0'
        )
        .map(([key, option]) => (
          <div className={styles['tab-item']} key={key}>
            <TabButton
              id={key}
              // Needed to use type assertion here since Object.keys will always have key as a string
              // In this particular case, we are reasonably confident that the object won't have the extra keys
              // that we don't expect here. And doing the assertion in here would allow the outside component to use
              // this component with more type safety, so the tradeoff is worth it.
              onClick={() => onChange(key as T)}
              onFocus={() => setIsTabFocused(true)}
              onBlur={() => setIsTabFocused(false)}
              active={value === key}
              data-testid={key}
              className="u-focusVisible"
            >
              <div className={`u-focusVisibleHighlight ${styles['inner']}`}>
                {option.label}
                {option.numberOfItems && option.numberOfItems !== '0' && (
                  <Stamp
                    className={classNames(
                      styles['tab-button__number-of-items-stamp'],
                      option.intent === 'danger' &&
                        styles['tab-button__number-of-items-stamp--danger']
                    )}
                    label={option.numberOfItems}
                    size="small"
                    borderRadius="full"
                  />
                )}
              </div>
            </TabButton>
          </div>
        ))}
    </HSpace>
  )
}
