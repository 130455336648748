import * as React from 'react'
import { HSpace } from '@components/HSpace'
import { Text } from '@components/Text'
import { DuffelAPI } from '@lib/types'
import { Condition } from './Condition'
import { Slice } from './lib'
import { SliceDetails } from './SliceDetails'

type OrderOrOfferSlice = DuffelAPI.Types.OfferSlice & DuffelAPI.Types.OrderSlice

interface SliceWithChangedStatus extends OrderOrOfferSlice {
  _changed?: boolean
}

interface FlightSummaryProps {
  flight: DuffelAPI.Types.Offer | DuffelAPI.Types.Order
  isExpanded?: boolean
  showFullDate?: boolean
  onlyShowChangedSlices?: boolean
  hideConditions?: boolean
  hideFareBrand?: boolean
}

export const FlightSummary: React.FC<FlightSummaryProps> = ({
  flight,
  onlyShowChangedSlices = false,
  isExpanded = false,
  showFullDate = false,
  hideConditions = false,
  hideFareBrand = false,
}) => {
  const slices = onlyShowChangedSlices
    ? (flight.slices as SliceWithChangedStatus[]).filter(
        ({ _changed }) => _changed
      )
    : flight.slices

  if (slices.length === 0) {
    return (
      <Text>
        There are no flights booked at the moment. This might be because all of
        the flights are in the past, or because someone cancelled them.
      </Text>
    )
  }

  const shouldShowOfferConditions =
    !hideConditions &&
    (flight.conditions.changeBeforeDeparture ||
      flight.conditions.refundBeforeDeparture)

  return (
    <>
      {slices.map((slice: Slice, index: number) => (
        <React.Fragment key={slice.id}>
          <SliceDetails
            slice={slice}
            isExpanded={isExpanded}
            showFullDate={showFullDate}
            hideConditions={hideConditions}
            hideFareBrand={hideFareBrand}
          />
          {index + 1 < slices.length && (
            <div className="flight-summary__slice-divider" />
          )}
        </React.Fragment>
      ))}

      {shouldShowOfferConditions && isExpanded && (
        <>
          <div className="flight-summary__slice-divider" />
          <div className="flight-summary__conditions" data-selector="fs-show">
            <HSpace space={12} fill>
              {flight.conditions.changeBeforeDeparture && (
                <div className="flight-summary__condition-item">
                  <Condition
                    target="order"
                    type="changeBeforeDeparture"
                    condition={flight.conditions.changeBeforeDeparture}
                  />
                </div>
              )}
              {flight.conditions.refundBeforeDeparture && (
                <div className="flight-summary__condition-item">
                  <Condition
                    target="order"
                    type="refundBeforeDeparture"
                    condition={flight.conditions.refundBeforeDeparture}
                  />
                </div>
              )}
            </HSpace>
          </div>
        </>
      )}

      <style jsx>{`
        .flight-summary__slice-divider {
          border-top: 1px dashed var(--grey-200);
          margin: ${isExpanded ? 32 : 24}px 0;
        }

        .flight-summary__condition-item {
          flex: 1;
          max-width: 50%;
        }
      `}</style>
    </>
  )
}
