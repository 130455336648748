import { SearchResponse } from 'pages/api/search'

const getDefaultObject = (() => (): SearchResponse => ({
  orders: [],
  bookings: [],
  estimatedTotalHits: 12,
  returnedHits: 2,
}))()

export const makeMockEmptySearchResponse = (
  extendDefault?: Partial<SearchResponse>
): SearchResponse => Object.assign({}, getDefaultObject(), extendDefault || {})
