import { COMMAS_EVERY_THIRD_POSITION_REGEX } from './constants'

/**
 * Format number with commas in every third spot.
 * If `isCurrency` is true, round to two decimal places.
 *
 * Example: 145282.25 to 145,282.25
 */
export const formatNumberWithCommas = (
  number: number | string | undefined,
  isCurrency?: boolean
) => {
  if (!number) {
    return ''
  }

  const parsedNumber = typeof number === 'string' ? parseFloat(number) : number

  // Round to two decimal places
  const roundedNumber = Math.round(parsedNumber * 100) / 100

  return isCurrency
    ? roundedNumber
        .toFixed(2)
        .toString()
        .replace(COMMAS_EVERY_THIRD_POSITION_REGEX, ',')
    : roundedNumber.toString().replace(COMMAS_EVERY_THIRD_POSITION_REGEX, ',')
}
