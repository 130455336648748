import * as React from 'react'
import { Icon, IconName } from '@components/Icon'
import { Text } from '@components/Text'
import {
  iconColorStyleFromStatus,
  backgroundColorStyleFromStatus,
  colorStyleFromStatus,
} from './style-helpers'

export type IndicatorStampStatus = 'neutral' | 'valid' | 'invalid'

interface IndicatorStampProps {
  status: IndicatorStampStatus
  label: string
}

export const IndicatorStamp: React.FC<IndicatorStampProps> = ({
  status,
  label,
}) => {
  let iconName: IconName
  switch (status) {
    case 'valid':
      iconName = 'check'
      break
    case 'invalid':
      iconName = 'close'
      break
    default:
      iconName = 'dot'
  }

  return (
    <div className="indicator-stamp">
      <div className="indicator-stamp__icon-wrapper">
        <Icon name={iconName} size={status === 'neutral' ? 12 : 16} />
      </div>
      <Text fontSize="C3">{label}</Text>

      <style jsx>{`
        .indicator-stamp {
          display: inline-flex;
          align-items: center;
          padding: var(--space-4) var(--space-16) var(--space-4) 0.75rem;
          border-radius: var(--border-radius-full);
        }

        .indicator-stamp__icon-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18px;
          height: 18px;
          margin-right: var(--space-4);
        }
      `}</style>
      <style jsx>{`
        .indicator-stamp {
          color: var(--${iconColorStyleFromStatus(status)});
          background-color: var(--${backgroundColorStyleFromStatus(status)});
        }

        .indicator-stamp :global(.text) {
          color: var(--${colorStyleFromStatus(status)});
        }
      `}</style>
    </div>
  )
}
