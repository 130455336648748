import { HSpace } from '@components/HSpace'
import { Icon } from '@components/Icon'
import { VSpace } from '@components/VSpace'
import { Text } from '@components/Text'
import { DuffelAPI } from '@lib/types'
import { moneyStringFormatter } from '@lib/helpers'
import { ACCEPTED_CURRENCIES } from '@lib/constants'

type OrderConditions = keyof DuffelAPI.Types.Order['conditions']
type SliceConditions = keyof DuffelAPI.Types.OrderSlice['conditions']

export type ConditionProps = (
  | { target: 'order'; type: OrderConditions }
  | { target: 'slice'; type: SliceConditions }
) & {
  condition?: DuffelAPI.Types.OfferCondition
}

const orderConditionTitles: Record<OrderConditions, string> = {
  changeBeforeDeparture: 'Order change policy',
  refundBeforeDeparture: 'Order refund policy',
}

const orderConditionDescriptions: Record<OrderConditions, string> = {
  changeBeforeDeparture:
    'Make changes to this order up until the initial departure date',
  refundBeforeDeparture:
    'This order is refundable up until the initial departure date',
}

const orderConditionNotAllowedDescriptions: Record<OrderConditions, string> = {
  changeBeforeDeparture: 'This order is not changeable',
  refundBeforeDeparture: 'This order is not refundable',
}

const getOrderConditionDescription = (
  type: OrderConditions,
  allowed?: boolean
) =>
  allowed
    ? orderConditionDescriptions[type]
    : orderConditionNotAllowedDescriptions[type]

const getPenaltyDescription = (
  condition: DuffelAPI.Types.OfferCondition,
  type: OrderConditions
) => {
  const penalty = conditionPenalty(condition)

  if (penalty?.amount === 0) {
    return ' (no penalty)'
  }

  let formattedPenalty: string | null = null
  if (penalty && penalty.amount > 0) {
    if (ACCEPTED_CURRENCIES[penalty.currency]) {
      formattedPenalty = moneyStringFormatter(
        penalty.currency,
        ACCEPTED_CURRENCIES[penalty.currency].locale
      )(penalty.amount)
    } else {
      formattedPenalty = moneyStringFormatter(penalty.currency)(penalty.amount)
    }
  }

  const conditionType = type === 'refundBeforeDeparture' ? 'refund' : 'change'

  return penalty && formattedPenalty
    ? ` (a ${conditionType} penalty of ${formattedPenalty} will apply)`
    : ` (penalty unknown)`
}

const sliceConditionTitles: Record<
  keyof DuffelAPI.Types.Offer['conditions'],
  string
> = {
  changeBeforeDeparture: 'Flight change policy',
  refundBeforeDeparture: 'Flight refund policy',
}

const sliceConditionDescriptions: Record<SliceConditions, string> = {
  changeBeforeDeparture:
    'Make changes to this flight up until the departure date',
  refundBeforeDeparture:
    'This flight is refundable up until the departure date',
}

const sliceConditionNotAllowedDescriptions: Record<SliceConditions, string> = {
  changeBeforeDeparture: 'This flight is not changeable',
  refundBeforeDeparture: 'This flight is not refundable',
}

const getSliceConditionDescription = (
  type: OrderConditions,
  allowed?: boolean
) =>
  allowed
    ? sliceConditionDescriptions[type]
    : sliceConditionNotAllowedDescriptions[type]

const conditionPenalty = (condition?: DuffelAPI.Types.OfferCondition) =>
  condition && condition.penaltyAmount !== null && condition.penaltyCurrency
    ? {
        amount: +condition.penaltyAmount,
        currency: condition.penaltyCurrency,
      }
    : undefined

export const Condition: React.FC<ConditionProps> = ({
  target,
  type,
  condition,
}) => {
  const title =
    target == 'order' ? orderConditionTitles[type] : sliceConditionTitles[type]

  const description =
    target == 'order'
      ? getOrderConditionDescription(type, condition?.allowed)
      : getSliceConditionDescription(type, condition?.allowed)

  return (
    <div className="condition">
      <HSpace space={8} fill>
        <div>
          <Icon
            size={16}
            name="travel_plane_ticket"
            className="condition__icon"
          />
        </div>
        <VSpace space={4}>
          <Text color="grey-900" fontWeight="medium">
            {title}
          </Text>
          <Text color="grey-700">
            {description}
            {condition?.allowed && getPenaltyDescription(condition, type)}
          </Text>
        </VSpace>
      </HSpace>
      <style jsx>{`
        .condition {
          width: 100%;
          height: 100%;
          padding: 16px 20px;
          border: 1px solid var(--grey-200);
          border-radius: var(--border-radius-4);
        }

        /* need to reach into icon to modify the color since there's no color prop yet */
        .condition :global(.condition__icon) {
          color: var(--green-600);
          margin-top: 5px;
        }
      `}</style>
    </div>
  )
}
