import { DuffelAPI } from '@lib/types'
import { makeMockAccessToken } from './make-mock-access-token'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.UserOrganisationMembership => ({
    user: {} as any,
    organisationId: `org_${seedId}`,
    userId: 'usr_001',
    scope: 'roles/user/admin',
    owner: false,
    organisation: {
      id: `org_${seedId}`,
      verified: false,
      slug: 'test-org',
      name: 'Duffel',
      createdAt: '2018-03-18T07:04:55.569492Z',
      avatarUrl: 'https://image.flaticon.com/icons/svg/2089/2089910.svg',
      accessTokens: [makeMockAccessToken()],
      settlementCurrency: 'GBP',
      verificationFlow: 'duffel_2020',
      isDuffelLinksEnabled: true,
      sessionExpiryTimeout: 30,
      staysAccessStatus: 'live',
      passwordExpiryTimeoutDays: null,
    },
    id: `orgm_${seedId++}`,
    createdAt: '2021-04-27T09:48:41.922931Z',
    disabledAt: null,
    avatarUrl: null,
  })
})()

export const makeMockUserOrganisationMembership = (
  extendDefault?: Partial<DuffelAPI.Types.UserOrganisationMembership>
): DuffelAPI.Types.UserOrganisationMembership =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
