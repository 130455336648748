import { isBrowser } from '@lib/env'
import { captureException } from '@lib/sentry'

export const isSafeRedirect = (str: string, currentOrigin: string) => {
  try {
    const absoluteRedirectUrl = new URL(str, currentOrigin)
    return absoluteRedirectUrl.origin === currentOrigin
  } catch (error: any) {
    captureException(error, {
      currentOrigin,
      str,
      isBrowser,
    })
    return false
  }
}
