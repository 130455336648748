import * as React from 'react'
import { useFormikContext } from 'formik'
import { FormikInput, FormikSelect } from '@components/Form'
import { ChromelessButton } from '@components/Button'
import { Text } from '@components/Text'
import { CreateOrderChangeRequestFormValues } from '@modules/air-order-change/lib'
import styles from './PrivateFaresInputContainer.module.css'

export type PrivateFareInput =
  | 'corporateCode'
  | 'tourCode'
  | 'trackingReference'

const privateFareInputTypes: Record<PrivateFareInput, string> = Object.freeze({
  corporateCode: 'Corporate code',
  tourCode: 'Tour code',
  trackingReference: 'Tracking reference',
})

export const PrivateFaresInputContainer: React.FC = () => {
  const { values, setFieldValue } =
    useFormikContext<CreateOrderChangeRequestFormValues>()

  return (
    <div className={styles['private-fare-input-container']}>
      {values.privateFares.map((currentFormikValue, currentIndex) => {
        return (
          <div className={styles['private-fare-input']} key={currentIndex}>
            <FormikSelect
              name={`privateFares.${currentIndex}.key`}
              label="Private fare"
              optionalField
              className={styles['input']}
            >
              {Object.keys(privateFareInputTypes).map((fareInputType) => {
                if (
                  currentFormikValue.key === fareInputType ||
                  !values.privateFares.find(
                    (fare) => fare.key === fareInputType
                  )
                ) {
                  return (
                    <option
                      key={fareInputType}
                      label={privateFareInputTypes[fareInputType]}
                    >
                      {fareInputType}
                    </option>
                  )
                }
              })}
            </FormikSelect>

            <FormikInput
              label="Code"
              name={`privateFares.${currentIndex}.value`}
              optionalField
              className={styles['input']}
            />

            {currentIndex > 0 && (
              <ChromelessButton
                className={`${styles['button']} ${styles['remove-private-fare']}`}
                onClick={(event) => {
                  event.preventDefault()
                  const clonePrivateFaresArray = values.privateFares.slice(0)
                  clonePrivateFaresArray.splice(currentIndex, 1)
                  setFieldValue(`privateFares`, clonePrivateFaresArray)
                }}
              >
                <Text color="pink-700" fontSize="C2">
                  Remove
                </Text>
              </ChromelessButton>
            )}

            {/* We only support up to three private fare code types */}
            {currentIndex < 2 && (
              <ChromelessButton
                className={`${styles['button']} ${styles['add-private-fare']}`}
                onClick={(event) => {
                  event.preventDefault()
                  // Determine private fare types already inputted
                  const inputtedPrivateFares = values.privateFares.flatMap(
                    (privateFare) => privateFare.key as string
                  )
                  let remainingFareInputOptions = Object.keys(
                    privateFareInputTypes
                  )
                  remainingFareInputOptions = remainingFareInputOptions.filter(
                    (option) => !inputtedPrivateFares.includes(option)
                  )

                  // Pre-populate next field
                  Object.keys(remainingFareInputOptions).length > 0 &&
                    setFieldValue(`privateFares.${currentIndex + 1}`, {
                      key: remainingFareInputOptions[0],
                      value: '',
                    })
                }}
              >
                <Text
                  color="purple-700"
                  fontSize="C2"
                  fontWeight="medium"
                  textAlign="right"
                >
                  Add another private fare code
                </Text>
              </ChromelessButton>
            )}
          </div>
        )
      })}
    </div>
  )
}
