import classNames from 'classnames'
import * as React from 'react'
import { HSpace } from '@components/HSpace'
import { Icon, IconName } from '@components/Icon'
import styles from './Callout.module.css'

export interface CalloutProps {
  iconName?: IconName
  actionButton?: React.ReactNode
  intent?: 'default' | 'danger' | 'secondary' | 'warning'
  className?: string
}

export const Callout: React.FC<React.PropsWithChildren<CalloutProps>> = ({
  iconName,
  intent,
  actionButton,
  children,
  className,
  ...props
}) => {
  const cx = classNames(
    styles['callout'],
    {
      [styles['danger']]: intent === 'danger',
      [styles['secondary']]: intent === 'secondary',
      [styles['warning']]: intent === 'warning',
    },
    className
  )

  return (
    <div data-selector="fs-show" className={cx} {...props}>
      <HSpace space={0} spaceBetween alignCenter>
        {iconName && (
          <div className={styles['callout-icon']}>
            <Icon name={iconName} />
          </div>
        )}
        <div className={styles['callout-text']}>{children}</div>
        <div className={styles['callout-action-button']}>{actionButton}</div>
      </HSpace>
    </div>
  )
}
