/**
 * Return a function to format a number into a money amount for a given currency
 *
 * @param currency The ISO-4217 currency code to be used by the formatter
 */
export const moneyStringFormatter = (
  currency: string,
  locale = 'en-GB',
  options = {}
) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    ...options,
  })
  return (value: number) => {
    const numberFormatted = formatter.format(value)
    const signFormatted =
      options &&
      options['signDisplay'] &&
      (options['signDisplay'] === 'always' ||
        (options['signDisplay'] === 'exceptZero' && value !== 0))
        ? numberFormatted.replace(/^([+-])/, '$1 ')
        : numberFormatted

    return signFormatted
  }
}

/**
 * Returns a function to format a number into a money amount for a given currency without the currency symbol
 *
 * @param currency The ISO-4217 currency code to be used by the formatter
 */
export const moneyStringFormatterWithoutSymbol = (
  currency: string,
  locale = 'en-GB'
) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
  })
  return (value: number) =>
    formatter
      .format(value)
      .replace(/[a-z]{3}/i, '')
      .trim()
}
/**
 * Return a function to format a number into a money amount for a given currency, split into integer and decimal
 *
 * @param currency The ISO-4217 currency code to be used by the formatter
 */
export const moneyStringFormatterSplit = (
  currency: string,
  locale = 'en-GB'
) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })
  return (value: number) => {
    const parts = formatter.formatToParts(value)
    const isNegative = parts.findIndex((part) => part.type === 'minusSign') >= 0
    const currencyIndex = parts.findIndex((part) => part.type === 'currency')
    const decimalIndex = parts.findIndex((part) => part.type === 'decimal')
    const integerParts = decimalIndex > 0 ? parts.slice(0, decimalIndex) : parts
    const integer = integerParts
      .filter((part) => part.type === 'integer' || part.type === 'group')
      .map((part) => part.value)
      .join('')
    const fraction = parts.find((part) => part.type === 'fraction')
    return {
      isNegative,
      currency: parts[currencyIndex]?.value || '',
      integer,
      fraction: fraction ? fraction.value : '',
      currencyBeforeNumber:
        currencyIndex < parts.findIndex((part) => part.type === 'integer'),
    }
  }
}
