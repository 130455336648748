/// <reference types="@types/segment-analytics" />

import { TransactionsDownloadType } from '@lib/duffel-client/Payments'
import { orgPathsMap, orgModePathsMap, rootPathsMap } from '@lib/paths'
import { UserRole } from '@lib/security'
import { CabinClass, DuffelAPI, SearchType, VerificationFlow } from '@lib/types'
import { ContactSupportCategory } from '@lib/zendesk'
import { SortParam } from '@modules/air-order-change/components/OrderChangeResultsList/SortMenu'
import {
  OrderManagementAction,
  SupportType,
} from '@modules/air-order/components/OrderSupport/types'
import { OrderSortedBy } from '@modules/air-order/helpers/handle-order-sorting'

type ValueOf<T> = T[keyof T]
type Paths =
  | ValueOf<typeof orgPathsMap>
  | ValueOf<typeof orgModePathsMap>
  | ValueOf<typeof rootPathsMap>
  | '/404'

export const pageNavEventNames: Record<Paths, string> = {
  '/': 'dashboard_organisation_list_page_visited',
  '/404': 'dashboard_404_page_visited',
  '/error': 'dashboard_error_page_visited',
  '/create-team': 'dashboard_create_team_page_visited',
  '/user/settings': 'dashboard_user_preferences_page_visited',
  '/sign-in': 'dashboard_sign_in_page_visited',
  '/reset-password': 'dashboard_forgotten_password_page_visited',
  '/reset-password/[token]': 'dashboard_forgotten_password_token_page_visited',
  '/join': 'dashboard_signup_page_visited',
  '/join/[token]': 'dashboard_signup_with_invite_token_page_visited',
  '/confirm-email': 'dashboard_confirm_email_page_visited',
  '/confirm-email/[token]': 'dashboard_confirm_email_with_token_page_visited',
  '/stripe-verification-redirect': 'dashboard_stripe_verification_visited',

  '/[org]/[mode]': 'dashboard_org_index_page_visited',
  '/[org]/[mode]/inbox': 'dashboard_inbox_page_visited',

  '/[org]/[mode]/orders': 'dashboard_orders_index_page_visited',
  '/[org]/[mode]/orders/[orderId]': 'dashboard_order_by_id_page_visited',

  '/[org]/[mode]/orders/[orderId]/change':
    'dashboard_create_order_change_page_visited',
  '/[org]/[mode]/orders/[orderId]/change/[orderChangeRequestId]':
    'dashboard_order_change_results_page_visited',
  '/[org]/[mode]/orders/[orderId]/change/[orderChangeRequestId]/[orderChangeId]':
    'dashboard_order_change_offer_page_visited',

  '/[org]/[mode]/search-v2/results':
    'dashboard_partial_search_results_page_visited',
  '/[org]/[mode]/search-v2': 'dashboard_partial_search_page_visited',
  '/[org]/[mode]/search-v2/[partialOfferRequestId]/[offerId]':
    'dashboard_partial_search_checkout_visited',

  '/[org]/[mode]/search-v2/ngs/results':
    'dashboard_ngs_search_results_page_visited',
  '/[org]/[mode]/search-v2/ngs/[offerRequestId]/[offerId]':
    'dashboard_ngs_checkout_page_visited',

  '/[org]/[mode]/wallet': 'dashboard_wallet_page_visited',
  '/[org]/[mode]/balance': 'dashboard_balance_page_visited',
  '/[org]/[mode]/balance/[paymentIntentId]':
    'dashboard_payment_intent_by_id_page_visited',

  '/[org]/activation': 'dashboard_activation_index_page_visited',
  '/[org]/activation/plan': 'dashboard_activation_plan_page_visited',
  '/[org]/activation/business': 'dashboard_activation_business_page_visited',
  '/[org]/activation/card': 'dashboard_activation_card_page_visited',
  '/[org]/activation/review': 'dashboard_activation_review_page_visited',
  '/[org]/activation/email': 'dashboard_activation_email_page_visited',
  '/[org]/activation/done': 'dashboard_activation_done_page_visited',

  '/[org]/email': 'dashboard_activation_email_page_visited',

  '/[org]/settings': 'dashboard_organisation_settings_page_visited',
  '/[org]/settings/usage-and-billing':
    'dashboard_organisation_settings_usage_and_billing_page_visited',
  '/[org]/settings/preferences':
    'dashboard_organisation_settings_preferences_page_visited',
  '/[org]/settings/business':
    'dashboard_organisation_settings_business_page_visited',
  '/[org]/settings/balance':
    'dashboard_organisation_settings_balance_page_visited',
  '/[org]/settings/contact-emails':
    'dashboard_organisation_settings_contact_emails_page_visited',
  '/[org]/settings/wallet':
    'dashboard_organisation_settings_wallet_page_visited',
  '/[org]/settings/security':
    'dashboard_organisation_settings_security_page_visited',
  '/[org]/settings/team': 'dashboard_team_index_page_visited',
  '/[org]/settings/team/invite': 'dashboard_team_invite_page_visited',

  '/[org]/airlines': 'dashboard_airline_index_page_visited',
  '/[org]/airlines/[airlineId]': 'dashboard_airline_by_id_page_visited',

  '/[org]/[mode]/developers/webhooks':
    'dashboard_developers_webhooks_page_visited',
  '/[org]/[mode]/developers/tokens':
    'dashboard_developers_tokens_index_page_visited',
  '/[org]/[mode]/developers/tokens/[tokenId]':
    'dashboard_developers_token_by_id_page_visited',
  '/[org]/[mode]/developers/tokens/new':
    'dashboard_developers_create_token_page_visited',
  '/[org]/links': 'dashboard_developers_links_index_page_visited',

  '/[org]/[mode]/stays': 'dashboard_stays_index_visited',
  '/[org]/[mode]/stays/content-analysis':
    'dashboard_stays_content_analysis_visited',
  '/[org]/[mode]/stays/content-analysis-v2':
    'dashboard_stays_content_analysis_v2_visited',
  '/[org]/[mode]/stays/search': 'dashboard_stays_search_visited',
  '/[org]/[mode]/stays/results': 'dashboard_stays_results_visited',
  '/[org]/[mode]/stays/results-by-id': 'dashboard_stays_results_by_id_visited',
  '/[org]/[mode]/stays/[searchResultId]': 'dashboard_stays_result_visited',
  '/[org]/[mode]/stays/[searchResultId]/[rateId]':
    'dashboard_stays_checkout_visited',
  '/[org]/[mode]/stays/bookings/[bookingId]': 'dashboard_stays_booking_visited',
}

export interface TrackingEventMetadataMap {
  dashboard_live_mode_toggle_clicked: {
    event_type: 'interaction'
    live_mode: boolean
  }
  dashboard_create_new_team_alert_displayed: {
    event_type: 'alert'
    organisation_slug: string
    alert_message: string
  }
  dashboard_create_new_team_button_clicked: {
    event_type: 'interaction'
    organisation_slug: string
  }
  dashboard_create_new_team_confirmed: {
    event_type: 'api'
    organisation_slug: string
  }
  dashboard_forgotten_password_send_reset_link_alert_displayed: {
    event_type: 'alert'
    alert_message: string
  }
  dashboard_forgotten_password_send_reset_link_button_clicked: {
    event_type: 'interaction'
    email: string
  }
  dashboard_forgotten_password_send_reset_link_confirmed: {
    event_type: 'api'
    email: string
  }
  dashboard_confirm_email_resend_verification_email_link_clicked: {
    event_type: 'interaction'
    email: string
  }
  dashboard_getting_started_cancel_order_alert_displayed: {
    event_type: 'alert'
    alert_message: string
    order_id: string
  }
  dashboard_getting_started_cancel_order_button_clicked: {
    event_type: 'interaction'
    order_id: string
  }
  dashboard_getting_started_cancel_order_confirmed: {
    event_type: 'api'
    order_id: string
  }
  dashboard_getting_started_complete_sandbox_access_form_button_clicked: {
    event_type: 'interaction'
  }
  dashboard_getting_started_create_access_token_alert_displayed: {
    event_type: 'alert'
    alert_message: string
  }
  dashboard_getting_started_create_access_token_button_clicked: {
    event_type: 'interaction'
    token_name: string
  }
  dashboard_getting_started_create_access_token_confirmed: {
    event_type: 'api'
    token_id: string
  }
  dashboard_getting_started_create_offer_request_alert_displayed: {
    event_type: 'alert'
    alert_message: string
    offer_request_id: string
  }
  dashboard_getting_started_create_offer_request_button_clicked: {
    event_type: 'interaction'
    offer_request_id: string
  }
  dashboard_getting_started_create_offer_request_confirmed: {
    event_type: 'api'
    offer_request_id: string
  }
  dashboard_getting_started_create_order_alert_displayed: {
    event_type: 'alert'
    alert_message: string
    order_id: string
  }
  dashboard_getting_started_create_order_button_clicked: {
    event_type: 'interaction'
    order_id: string
  }
  dashboard_getting_started_create_order_confirmed: {
    event_type: 'api'
    order_id: string
  }
  dashboard_getting_started_jump_to_section_clicked: {
    event_type: 'interaction'
    section: string
  }
  dashboard_getting_started_sandbox_access_form_completed: {
    event_type: 'api'
  }
  dashboard_order_show_cancel_order_confirm_button_clicked: {
    event_type: 'interaction'
    order_id: string
  }
  dashboard_order_show_cancel_order_confirmation_failed: {
    event_type: 'api'
    order_id: string
    error_message: string
    status?: number
  }
  dashboard_order_show_cancel_order_confirmation_completed: {
    event_type: 'api'
    order_id: string
  }
  dashboard_order_show_cancel_order_request_failed: {
    event_type: 'api'
    order_id: string
    error_message: string
    status?: number
  }
  dashboard_order_show_cancel_order_request_completed: {
    event_type: 'api'
    order_id: string
    cancellation_refund_amount: string
    order_total: string
  }
  dashboard_order_show_cancel_order_button_clicked: {
    event_type: 'interaction'
    order_id: string
  }
  dashboard_orders_table_order_clicked: {
    event_type: 'interaction'
    order_id: string
  }
  dashboard_orders_request_alert_displayed: {
    event_type: 'alert'
    alert_message: string
    request_id?: string
  }
  dashboard_orders_request_completed: {
    event_type: 'api'
    transaction_count: number
  }
  dashboard_orders_download_button_clicked: {
    event_type: 'interaction'
    download_type: TransactionsDownloadType
  }
  dashboard_orders_download_alert_displayed: {
    event_type: 'alert'
    download_type: TransactionsDownloadType
    alert_message: string
    request_id?: string
  }
  dashboard_orders_download_completed: {
    event_type: 'api'
    download_type: TransactionsDownloadType
    transaction_count: number
  }
  dashboard_orders_airline_initiated_change_notice_view_affected_orders_clicked: {
    event_type: 'interaction'
  }

  dashboard_org_settings_avatar_update_alert_displayed: {
    event_type: 'alert'
    alert_message: string
  }
  dashboard_org_settings_avatar_update_clicked: {
    event_type: 'interaction'
  }
  dashboard_org_settings_avatar_update_completed: { event_type: 'api' }
  dashboard_org_settings_avatar_update_submitted: {
    event_type: 'interaction'
  }
  dashboard_org_settings_details_update_clicked: {
    event_type: 'interaction'
  }
  dashboard_org_settings_details_update_completed: { event_type: 'api' }
  dashboard_org_settings_details_update_submitted: {
    event_type: 'interaction'
  }
  dashboard_org_settings_details_update_alert_displayed: {
    event_type: 'alert'
    alert_message: string
  }
  dashboard_org_settings_contact_support_link_clicked: {
    event_type: 'interaction'
  }
  dashboard_org_settings_card_form_submitted: {
    event_type: 'interaction'
  }
  dashboard_org_settings_card_form_completed: {
    event_type: 'api'
  }
  dashboard_org_settings_card_alert_displayed: {
    event_type: 'alert'
    alert_message: string
  }
  dashboard_org_settings_wallet_preferences_update_form_submitted: {
    event_type: 'interaction'
  }
  dashboard_org_settings_wallet_preferences_update_completed: {
    event_type: 'api'
  }
  dashboard_org_settings_wallet_preferences_update_alert_displayed: {
    event_type: 'alert'
    alert_message: string
  }

  dashboard_org_settings_security_settings_updated: {
    event_type: 'interaction'
  }

  dashboard_signin_alert_displayed: {
    event_type: 'alert'
    alert_message: string
  }
  dashboard_signin_button_clicked: { event_type: 'interaction'; email: string }
  dashboard_signout_link_clicked: { event_type: 'interaction' }
  dashboard_signup_alert_displayed: {
    event_type: 'alert'
    alert_message: string
  }
  dashboard_signup_button_clicked: { event_type: 'interaction'; email: string }
  dashboard_signup_confirmed: {
    event_type: 'api'
    email: string
    full_name: string
    organisation_id?: string
  }

  dashboard_airline_card_clicked: {
    event_type: 'interaction'
    airlineId: string
  }

  dashboard_airline_index_page_filter_button_clicked: {
    event_type: 'interaction'
  }

  dashboard_airline_index_page_filter_applied: {
    event_type: 'interaction'
    filter: string
    value: string
  }

  dashboard_airline_index_page_search_clicked: {
    event_type: 'interaction'
  }

  dashboard_source_request_activation_clicked: {
    event_type: 'interaction'
    sourceId: string
  }

  dashboard_source_request_activation_modal_cancel_button_clicked: {
    event_type: 'interaction'
    sourceId: string
  }

  dashboard_source_request_activation_modal_continue_button_clicked: {
    event_type: 'interaction'
    sourceId: string
    content_type_id: DuffelAPI.Types.OrganisationSourceContentType['id']
  }

  dashboard_source_request_activation_alert_displayed: {
    event_type: 'alert'
    alert_message: string
    sourceId: string
    content_type_id: DuffelAPI.Types.OrganisationSourceContentType['id']
  }

  dashboard_source_request_activation_completed: {
    event_type: 'api'
    sourceId: string
    content_type_id: DuffelAPI.Types.OrganisationSourceContentType['id']
  }

  dashboard_team_update_user_permission_option_selected: {
    event_type: 'interaction'
    teammate_user_id: string
    permission_update: UserRole | 'freeze-account' | 'unfreeze-account'
  }
  dashboard_team_update_user_permission_alert_displayed: {
    event_type: 'alert'
    alert_message: string
    teammate_user_id: string
    permission_update: UserRole | 'freeze-account' | 'unfreeze-account'
  }
  dashboard_team_update_user_permission_confirmed: {
    event_type: 'api'
    teammate_user_id: string
    permission_update: UserRole | 'freeze-account' | 'unfreeze-account'
  }
  dashboard_team_resend_invitation_button_clicked: {
    event_type: 'interaction'
    invitation_id: string
  }
  dashboard_team_resend_invitation_alert_displayed: {
    event_type: 'alert'
    alert_message: string
    invitation_id: string
  }
  dashboard_team_resend_invitation_confirmed: {
    event_type: 'api'
    invitation_id: string
  }
  dashboard_team_send_invitation_alert_displayed: {
    event_type: 'alert'
    alert_message: string
    email: string
  }
  dashboard_team_send_invitation_button_clicked: {
    event_type: 'interaction'
    email: string
    scope: string
  }
  dashboard_team_send_invitation_completed: {
    event_type: 'api'
    invitation_id: string
  }
  dashboard_join_accept_invitation_confirmed: {
    event_type: 'api'
    organisation_slug: string
  }
  dashboard_join_decline_invitation_confirmed: {
    event_type: 'api'
    organisation_slug: string
  }
  dashboard_join_alert_displayed: {
    event_type: 'alert'
    alert_message: string
    organisation_slug: string
  }
  dashboard_tokens_cancel_delete_button_clicked: {
    event_type: 'interaction'
    token_id: string
  }
  dashboard_tokens_confirm_delete_button_clicked: {
    event_type: 'interaction'
    token_id: string
  }
  dashboard_tokens_contact_support_link_clicked: {
    event_type: 'interaction'
  }
  dashboard_tokens_copy_token_button_clicked: {
    event_type: 'interaction'
    token_id: string
  }
  dashboard_tokens_create_new_token_alert_displayed: {
    event_type: 'alert'
    alert_message: string
  }
  dashboard_tokens_create_new_token_button_clicked: {
    event_type: 'interaction'
    mode: string
    scope: string
    token_name: string
  }
  dashboard_tokens_create_new_token_confirmed: {
    event_type: 'api'
    token_id: string
    mode: 'live' | 'test'
  }
  dashboard_tokens_delete_token_alert_displayed: {
    event_type: 'alert'
    alert_message: string
    token_id: string
  }
  dashboard_tokens_delete_token_button_clicked: {
    event_type: 'interaction'
    token_id: string
  }
  dashboard_tokens_delete_token_confirmed: {
    event_type: 'api'
    token_id: string
  }
  dashboard_tokens_read_docs_link_clicked: { event_type: 'interaction' }
  dashboard_tokens_verify_your_team_link_clicked: {
    event_type: 'interaction'
  }
  dashboard_user_preferences_resend_verification_email_link_clicked: {
    event_type: 'interaction'
    email: string
  }
  dashboard_user_preferences_save_changes_button_clicked: {
    event_type: 'interaction'
    email: string
    full_name: string
  }
  dashboard_user_preferences_password_save_changes_button_clicked: {
    event_type: 'interaction'
  }
  dashboard_user_preferences_save_changes_alert_displayed: {
    event_type: 'alert'
    alert_message: string
    email: string
  }
  dashboard_user_preferences_save_changes_confirmed: {
    event_type: 'api'
    email: string
    full_name: string
  }
  dashboard_user_preferences_delete_account_button_clicked: {
    event_type: 'interaction'
  }
  dashboard_user_preferences_delete_account_cancel_button_clicked: {
    event_type: 'interaction'
  }
  dashboard_user_preferences_delete_account_confirm_button_clicked: {
    event_type: 'interaction'
  }
  dashboard_user_preferences_delete_account_alert_displayed: {
    event_type: 'alert'
    alert_message: string
  }
  dashboard_user_preferences_delete_account_confirmed: { event_type: 'api' }
  signed_in: { event_type: 'api' }
  signed_out: { event_type: 'api' }

  dashboard_loyalty_accounts_input_clicked: { event_type: 'interaction' }
  dashboard_loyalty_accounts_add_another_account_clicked: {
    event_type: 'interaction'
  }
  dashboard_loyalty_accounts_remove_account_clicked: {
    event_type: 'interaction'
  }
  dashboard_search_submitted: {
    event_type: 'interaction'
    journey_type: SearchType
    cabin_class: CabinClass | undefined
  }
  dashboard_search_completed: { event_type: 'api'; offer_request_id: string }
  dashboard_search_alert_displayed: {
    event_type: 'alert'
    alert_message: string
  }
  dashboard_search_results_edit_search_button_clicked: {
    event_type: 'interaction'
    offer_request_id: string
  }
  dashboard_search_results_edit_search_submitted: {
    event_type: 'interaction'
    offer_request_id: string
    journey_type: SearchType
    cabin_class: CabinClass | undefined
  }
  dashboard_search_results_edit_search_completed: {
    event_type: 'api'
    offer_request_id: string
  }
  dashboard_search_results_edit_search_cancel_button_clicked: {
    event_type: 'interaction'
    offer_request_id: string
  }
  dashboard_search_results_next_button_clicked: {
    event_type: 'interaction'
    offer_request_id: string
  }
  dashboard_search_results_prev_button_clicked: {
    event_type: 'interaction'
    offer_request_id: string
  }
  dashboard_search_results_sort_menu_clicked: {
    event_type: 'interaction'
    offer_request_id: string
  }
  dashboard_search_results_sort_option_clicked: {
    event_type: 'interaction'
    offer_request_id: string
    sort_by: string
  }
  dashboard_search_results_stops_menu_clicked: {
    event_type: 'interaction'
    offer_request_id: string
  }
  dashboard_search_results_stops_option_clicked: {
    event_type: 'interaction'
    offer_request_id: string
    stops: string
  }
  dashboard_search_results_offer_card_clicked: {
    event_type: 'interaction'
    offer_request_id: string
    offer_id: string
    is_expanded: boolean
  }
  dashboard_search_results_offer_checkout_button_clicked: {
    event_type: 'interaction'
    offer_request_id: string
    offer_id: string
  }
  dashboard_search_results_alert_displayed: {
    event_type: 'alert'
    alert_message: string
    offer_request_id: string
  }
  dashboard_offer_checkout_pay_button_clicked: {
    event_type: 'interaction'
    offer_request_id: string
    offer_id: string
    payment_type: DuffelAPI.PaymentType | 'pay_later'
  }
  dashboard_offer_checkout_pay_confirm_button_clicked: {
    event_type: 'interaction'
    offer_request_id: string
    offer_id: string
    payment_type: DuffelAPI.PaymentType | 'pay_later'
  }
  dashboard_offer_checkout_pay_cancel_button_clicked: {
    event_type: 'interaction'
    offer_request_id: string
    offer_id: string
    payment_type: DuffelAPI.PaymentType | 'pay_later'
  }
  dashboard_offer_checkout_alert_displayed: {
    event_type: 'alert'
    alert_message: string
    offer_request_id: string
    offer_id: string
  }
  dashboard_offer_checkout_form_submitted: {
    event_type: 'interaction'
    offer_request_id: string
    offer_id: string
  }
  dashboard_offer_checkout_form_completed: {
    event_type: 'api'
    offer_request_id: string
    offer_id: string
    order_id: string
  }
  dashboard_offer_checkout_baggage_counter_clicked: {
    event_type: 'interaction'
    offer_request_id: string
    offer_id: string
    baggage_amount: number
  }
  dashboard_offer_checkout_seat_selection_clicked: {
    event_type: 'interaction'
    offer_request_id: string
    offer_id: string
  }
  dashboard_offer_checkout_seat_selection_cancel_button_clicked: {
    event_type: 'interaction'
    offer_request_id: string
    offer_id: string
  }
  dashboard_offer_checkout_seat_selection_confirm_button_clicked: {
    event_type: 'interaction'
    offer_request_id: string
    offer_id: string
  }
  dashboard_offer_checkout_seat_selection_passenger_clicked: {
    event_type: 'interaction'
    offer_request_id: string
    offer_id: string
  }
  dashboard_offer_checkout_seat_selection_seat_clicked: {
    event_type: 'interaction'
    offer_request_id: string
    offer_id: string
    seat_status:
      | 'available'
      | 'unavailable'
      | 'selectedByCurrentPassenger'
      | 'selectedByOtherPassenger'
  }
  dashboard_offer_checkout_seat_selection_deck_button_clicked: {
    event_type: 'interaction'
    offer_request_id: string
    offer_id: string
  }

  dashboard_activation_email_resend_verification_button_clicked: {
    event_type: 'interaction'
    email: string
  }
  dashboard_activation_email_manual_redirect_link_clicked: {
    event_type: 'interaction'
  }
  dashboard_activation_email_alert_displayed: {
    event_type: 'alert'
    alert_message: string
  }
  dashboard_activation_business_form_submitted: {
    event_type: 'interaction'
  }
  dashboard_activation_business_form_completed: {
    event_type: 'api'
  }
  dashboard_activation_business_alert_displayed: {
    event_type: 'alert'
    alert_message: string
  }
  dashboard_activation_plan_talk_to_sales_button_clicked: {
    event_type: 'interaction'
  }
  dashboard_activation_plan_alert_displayed: {
    event_type: 'alert'
    alert_message: string
  }
  dashboard_activation_plan_selection_submitted: {
    event_type: 'interaction'
  }
  dashboard_activation_plan_selection_completed: {
    event_type: 'api'
  }
  dashboard_activation_card_form_submitted: {
    event_type: 'interaction'
  }
  dashboard_activation_card_form_completed: {
    event_type: 'api'
  }
  dashboard_activation_card_alert_displayed: {
    event_type: 'alert'
    alert_message: string
  }
  dashboard_activation_card_amount_clicked: {
    event_type: 'interaction'
  }
  dashboard_activation_payment_submitted: {
    event_type: 'interaction'
  }
  dashboard_activation_payment_completed: {
    event_type: 'api'
  }
  dashboard_activation_payment_alert_displayed: {
    event_type: 'alert'
    alert_message: string
  }

  dashboard_activation_done_book_a_call_button_clicked: {
    event_type: 'interaction'
  }
  dashboard_wallet_top_up_button_clicked: {
    event_type: 'interaction'
  }
  dashboard_wallet_top_up_info_copy_button_clicked: {
    event_type: 'interaction'
    value_label: string
    currency: string
  }
  dashboard_wallet_transactions_pagination_next_clicked: {
    event_type: 'interaction'
  }
  dashboard_wallet_transactions_pagination_previous_clicked: {
    event_type: 'interaction'
  }
  dashboard_wallet_transactions_date_range_filter_submitted: {
    event_type: 'interaction'
    start: Date
    end: Date
  }
  dashboard_wallet_transactions_request_alert_displayed: {
    event_type: 'alert'
    alert_message: string
    request_id?: string
  }
  dashboard_wallet_transactions_request_completed: {
    event_type: 'api'
    transaction_count: number
  }
  dashboard_wallet_transactions_download_button_clicked: {
    event_type: 'interaction'
    download_type: TransactionsDownloadType
  }
  dashboard_wallet_transactions_download_alert_displayed: {
    event_type: 'alert'
    download_type: TransactionsDownloadType
    alert_message: string
    request_id?: string
  }
  dashboard_wallet_transactions_download_completed: {
    event_type: 'api'
    download_type: TransactionsDownloadType
    transaction_count: number
  }
  dashboard_support_ticket_submitted: {
    event_type: 'interaction'
    category: string
  }
  dashboard_support_ticket_completed: {
    event_type: 'api'
    category: ContactSupportCategory
  }
  dashboard_support_ticket_alert_displayed: {
    event_type: 'alert'
    alert_message: ContactSupportCategory
  }
  dashboard_support_button_clicked: { event_type: 'interaction' }
  dashboard_support_close_clicked: { event_type: 'interaction' }
  dashboard_support_contact_clicked: { event_type: 'interaction' }
  dashboard_support_documentation_link_clicked: { event_type: 'interaction' }
  dashboard_support_help_centre_link_clicked: { event_type: 'interaction' }
  dashboard_support_api_status_link_clicked: { event_type: 'interaction' }

  dashboard_change_flights_change_button_clicked: {
    event_type: 'interaction'
    order_id: string
  }
  dashboard_change_flights_request_change_button_clicked: {
    event_type: 'interaction'
    order_id: string
  }
  dashboard_change_flights_request_form_toggle_slice_checkbox_clicked: {
    event_type: 'interaction'
    order_id: string
    slice_id: string
  }
  dashboard_change_flights_request_form_search_button_clicked: {
    event_type: 'interaction'
    order_id: string
  }
  dashboard_change_flights_request_form_search_alert_displayed: {
    event_type: 'alert'
    order_id: string
    alert_message: string
  }
  dashboard_change_flights_request_form_search_confirmed: {
    event_type: 'api'
    order_id: string
    order_change_request_id: string
  }
  dashboard_change_flights_results_edit_search_button_clicked: {
    event_type: 'interaction'
    order_id: string
    order_change_request_id: string
  }
  dashboard_change_flights_results_edit_search_cancel_button_clicked: {
    event_type: 'interaction'
    order_id: string
    order_change_request_id: string
  }
  dashboard_change_flights_results_sort_dropdown_button_clicked: {
    event_type: 'interaction'
    order_id: string
  }
  dashboard_change_flights_results_sort_button_clicked: {
    event_type: 'interaction'
    order_id: string
    sort_by: SortParam
  }
  dashboard_change_flights_results_change_offer_card_clicked: {
    event_type: 'interaction'
    order_id: string
    order_change_offer_id: string
    will_expand: boolean
  }
  dashboard_change_flights_results_change_offer_price_button_clicked: {
    event_type: 'interaction'
    order_id: string
    order_change_offer_id: string
  }
  dashboard_change_flights_offer_checkout_new_details_accordion_header_clicked: {
    event_type: 'interaction'
    order_id: string
    order_change_offer_id: string
    will_expand: boolean
  }
  dashboard_change_flights_offer_checkout_previous_details_accordion_header_clicked: {
    event_type: 'interaction'
    order_id: string
    order_change_offer_id: string
    will_expand: boolean
  }
  dashboard_change_flights_offer_checkout_create_pending_change_buton_clicked: {
    event_type: 'interaction'
    order_id: string
    order_change_offer_id: string
  }
  dashboard_change_flights_offer_checkout_create_pending_change_alert_displayed: {
    event_type: 'alert'
    order_id: string
    order_change_offer_id: string
    alert_message: string
  }
  dashboard_change_flights_offer_checkout_create_pending_change_completed: {
    event_type: 'api'
    order_id: string
    order_change_offer_id: string
    order_change_id: string
  }
  dashboard_change_flights_create_pending_change_failed: {
    error_message: string
    event_type: 'api'
    order_id: string
    order_change_id?: string
  }
  dashboard_change_flights_offer_checkout_confirm_change_buton_clicked: {
    event_type: 'interaction'
    order_id: string
    order_change_id: string
  }
  dashboard_change_flights_offer_checkout_confirm_change_alert_displayed: {
    event_type: 'alert'
    order_id: string
    order_change_id: string
    alert_message: string
  }
  dashboard_change_flights_offer_checkout_confirm_change_completed: {
    event_type: 'api'
    order_id: string
    order_change_id: string
  }
  dashboard_change_flights_confirm_order_failed: {
    error_message: string
    event_type: 'api'
    order_id: string
    order_change_id: string
  }

  dashboard_support_upload_attachment_clicked: {
    event_type: 'interaction'
    file_type: string
  }

  dashboard_issue_refund_clicked: {
    event_type: 'interaction'
    payment_intent_id: string
  }
  dashboard_issue_refund_submitted: {
    event_type: 'interaction'
    payment_intent_id: string
  }
  dashboard_issue_refund_alert_displayed: {
    event_type: 'alert'
    payment_intent_id: string
  }
  dashboard_issue_refund_completed: {
    event_type: 'api'
    payment_intent_id: string
  }

  dashboard_request_payout_clicked: {
    event_type: 'interaction'
  }
  dashboard_activation_link_clicked: {
    event_type: 'interaction'
    verification_flow?: VerificationFlow
  }
  dashboard_stripe_add_details_clicked: {
    event_type: 'interaction'
  }
  dashboard_pricing_link_clicked: {
    event_type: 'interaction'
  }
  dashboard_api_reference_link_clicked: {
    event_type: 'interaction'
  }
  dashboard_quick_start_link_clicked: {
    event_type: 'interaction'
  }
  dashboard_org_index_name_update_submitted: {
    event_type: 'interaction'
  }
  dashboard_org_index_name_update_alert_displayed: {
    event_type: 'alert'
    alert_message: string
  }
  dashboard_org_index_name_update_completed: {
    event_type: 'api'
  }
  dashboard_org_index_token_copy_clicked: {
    event_type: 'interaction'
  }
  dashboard_org_index_account_set_up_skip_clicked: {
    event_type: 'interaction'
    step: number
  }
  dashboard_org_index_account_set_up_link_clicked: {
    event_type: 'interaction'
    step: number
  }

  dashboard_org_index_segmentation_question_answer_clicked: {
    event_type: 'interaction'
    question: string
  }
  dashboard_org_index_segmentation_question_dismiss_clicked: {
    event_type: 'interaction'
  }

  dashboard_source_beta_help_link_clicked: {
    event_type: 'interaction'
  }

  dashboard_post_signup_welcome_modal_displayed: {
    event_type: 'modal'
  }

  dashboard_post_signup_welcome_modal_get_started_clicked: {
    event_type: 'interaction'
  }

  dashboard_request_new_airline_button_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_side_panel_for_developers_integration_guides_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_side_panel_for_developers_client_libraries_and_sdks_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_side_panel_for_developers_duffel_components_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_side_panel_for_developers_api_reference_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_side_panel_useful_links_support_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_side_panel_useful_links_status_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_side_panel_useful_links_changelog_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_feed_duffel_links_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_feed_see_your_available_airlines_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_feed_discover_our_developer_tools_client_libraries_and_sdks_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_feed_discover_our_developer_tools_duffel_components_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_feed_discover_our_developer_tools_create_duffel_app_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_feed_learn_from_our_industry_expertise_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_feed_visit_our_guides_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_feed_need_some_help_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_feed_keep_up_to_date_with_whats_new_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_feed_start_making_live_orders_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_feed_start_making_live_orders_services_agreement_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_feed_add_billing_details_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_feed_add_billing_details_pricing_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_feed_empty_state_integrating_with_our_apis_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_feed_empty_state_dashboard_booking_tool_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_fast_track_card_go_live_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_fast_track_card_go_live_services_agreement_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_fast_track_card_add_billing_details_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_fast_track_card_add_billing_details_pricing_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_feed_invite_your_team_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_airline_initiated_change_notice_view_affected_orders_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_insights_guide_link_clicked: {
    event_type: 'interaction'
  }

  dashboard_home_page_insights_modal_displayed: {
    event_type: 'modal'
  }

  dashboard_home_page_insights_modal_close_clicked: {
    event_type: 'interaction'
  }

  dashboard_airline_initiated_change_request_cancellation_clicked: {
    event_type: 'interaction'
  }

  dashboard_airline_initiated_change_request_change_clicked: {
    event_type: 'interaction'
  }

  dashboard_airline_initiated_change_accept_clicked: {
    event_type: 'interaction'
  }

  dashboard_airline_initiated_change_mark_as_cancelled_clicked: {
    event_type: 'interaction'
  }

  dashboard_airline_initiated_change_mark_as_changed_clicked: {
    event_type: 'interaction'
  }

  dashboard_airline_initiated_change_mark_as_accepted_clicked: {
    event_type: 'interaction'
  }

  dashboard_airline_initiated_change_accept_with_assistance_clicked: {
    event_type: 'interaction'
  }
  dashboard_airline_initiated_change_update_failed: {
    error_message: string
    event_type: 'api'
    order_id: string
    status?: number
  }
  dashboard_airline_initiated_change_accept_failed: {
    error_message: string
    event_type: 'api'
    order_id: string
    status?: number
  }

  dashboard_orders_table_order_sort_clicked: {
    event_type: 'interaction'
    sortBy: OrderSortedBy
  }

  dashboard_orders_show_tab_clicked: {
    event_type: 'interaction'
    value: string
  }

  dashboard_order_search_input_search_query_started: {
    event_type: 'interaction'
    organisation?: DuffelAPI.Types.Organisation['name']
    organisationId?: DuffelAPI.Types.Organisation['id']
    userId?: DuffelAPI.Types.User['id']
    mode: 'live' | 'test'
  }

  dashboard_order_search_result_clicked: {
    event_type: 'interaction'
    organisation?: DuffelAPI.Types.Organisation['name']
    organisationId?: DuffelAPI.Types.Organisation['id']
    userId?: DuffelAPI.Types.User['id']
    mode: 'live' | 'test'
  }

  dashboard_order_comment_submitted: {
    event_type: 'interaction'
  }

  dashboard_usage_and_billing_page_see_plan_clicked: {
    event_type: 'interaction'
  }

  dashboard_usage_and_billing_page_cost_breakdown_clicked: {
    event_type: 'interaction'
  }

  dashboard_order_support_modal_shown: {
    event_type: 'interaction'
    support_type: SupportType
    action_to_support: OrderManagementAction
    auto_high_priority: boolean
  }

  dashboard_order_support_modal_submitted: {
    event_type: 'interaction'
    support_type: SupportType
    action_to_support: OrderManagementAction
    auto_high_priority: boolean
    requesting_void: boolean
  }

  dashboard_order_support_modal_completed: {
    event_type: 'api'
    support_type: SupportType
    action_to_support: OrderManagementAction
  }

  dashboard_order_support_modal_alert_displayed: {
    event_type: 'alert'
    support_type: SupportType
    action_to_support: OrderManagementAction
  }

  dashboard_common_select_changed: {
    event_type: 'interaction'
    name: string
  }

  dashboard_order_incompatible_aic_view_visited: {
    event_type: 'interaction'
  }

  dashboard_aic_modal_displayed: {
    event_type: 'modal'
  }

  dashboard_aic_modal_close_clicked: {
    event_type: 'interaction'
  }

  dashboard_order_aic_tab_clicked: {
    event_type: 'interaction'
    value: string
  }

  dashboard_multistep_search_legacy_toggle_clicked: {
    event_type: 'interaction'
    target_mode: 'classic' | 'multi-step'
  }

  dashboard_multistep_search_breadcrumb_clicked: {
    event_type: 'interaction'
    breadcrumb_text: string
  }

  dashboard_multistep_search_results_header_clicked: {
    event_type: 'interaction'
  }

  dashboard_multistep_search_journey_type_selected: {
    event_type: 'interaction'
    search_type: SearchType
  }

  dashboard_multistep_search_navigation_step_clicked: {
    event_type: 'interaction'
    current_step: number
    target_step: number
    dead_click: boolean
  }

  dashboard_multistep_edit_search_button_clicked: {
    event_type: 'interaction'
  }

  dashboard_multistep_offer_fare_clicked: {
    event_type: 'interaction'
    num_choices: number
  }

  dashboard_multistep_offer_checkout_clicked: {
    event_type: 'interaction'
  }

  dashboard_search_time_ranges_changed: {
    event_type: 'interaction'
  }

  dashboard_search_time_ranges_reset: {
    event_type: 'interaction'
  }

  dashboard_add_service_baggage_selection_clicked: {
    event_type: 'interaction'
  }

  dashboard_add_service_baggage_selection_confirmed_clicked: {
    event_type: 'interaction'
  }

  dashboard_developers_webhooks_configuration_save_clicked: {
    event_type: 'interaction'
  }

  dashboard_developers_webhooks_edit_clicked: {
    event_type: 'interaction'
  }

  dashboard_developers_webhooks_ping_clicked: {
    event_type: 'interaction'
  }

  dashboard_developers_webhooks_delete_clicked: {
    event_type: 'interaction'
  }

  dashboard_developers_webhooks_delete_modal_closed: {
    event_type: 'interaction'
  }

  dashboard_developers_webhooks_delete_modal_confirmed: {
    event_type: 'interaction'
  }

  dashboard_links_generator_opened: {
    event_type: 'interaction'
  }

  dashboard_links_access_request_action_clicked: {
    event_type: 'interaction'
    action: 'activate' | 'request_access'
  }

  dashboard_links_access_request_submitted: {
    event_type: 'interaction'
    with_custom_domain: boolean
  }

  dashboard_webhooks_deliveries_pagination_next_clicked: {
    event_type: 'interaction'
  }
  dashboard_webhooks_deliveries_pagination_previous_clicked: {
    event_type: 'interaction'
  }
  dashboard_webhooks_resend_event_clicked: {
    event_type: 'interaction'
  }

  dashboard_filter_applied: {
    event_type: 'interaction'
    filter: string
  }

  dashboard_live_chat_open_clicked: {
    event_type: 'interaction'
  }
  dashboard_live_chat_close_clicked: {
    event_type: 'interaction'
  }
  dashboard_live_chat_resume_clicked: {
    event_type: 'interaction'
  }
  dashboard_home_page_feed_stays_cta_clicked: {
    event_type: 'interaction'
  }
  dashboard_stays_search_quick_option_clicked: {
    event_type: 'interaction'
  }

  dashboard_stays_content_analysis_search_submitted: {
    event_type: 'interaction'
    search_term: string
  }
  dashboard_stays_content_analysis_search_result_clicked: {
    event_type: 'interaction'
    search_term: string
    id: string
  }

  dashboard_stays_search_submitted: {
    event_type: 'interaction'
    search_term: string
  }
  dashboard_stays_search_completed: {
    event_type: 'api'
    search_term?: string
  }
  dashboard_stays_search_result_clicked: {
    event_type: 'interaction'
    search_term?: string
    id: string
  }
  dashboard_stays_booking_change_itinerary_dialog_create_new_booking_clicked: {
    event_type: 'interaction'
  }
  dashboard_stays_booking_change_itinerary_menu_item_clicked: {
    event_type: 'interaction'
  }
  dashboard_stays_booking_change_itinerary_go_to_search_clicked: {
    event_type: 'interaction'
  }
  dashboard_stays_booking_cancellation_requested: {
    event_type: 'interaction'
  }
  dashboard_stays_booking_cancellation_request_completed: {
    event_type: 'api'
  }
  dashboard_stays_booking_cancellation_request_failed: {
    event_type: 'alert'
  }
}

export type TrackingEvent = keyof TrackingEventMetadataMap
