import { capitalize, startCase } from 'lodash'
import * as React from 'react'
import { Button } from '@components/Button'
import { Modal } from '@components/Modal'
import { Stamp } from '@components/Stamp'
import { HSpace } from '@components/HSpace'
import { captureException } from '@lib/sentry'
import { trackEvent } from '@lib/tracking'
import { DuffelAPI } from '@lib/types'
import { getDateString } from '@lib/date'
import { CreateOrderChangeFormContainer } from '@modules/air-order-change'
import { applyChangeRequestToOrder } from '@modules/air-order-change/lib'
import { getSearchType } from '@modules/air-search-v2'
import { OrderChangeResultsHeaderTitle } from './OrderChangeResultsHeaderTitle'

interface OrderChangeResultsHeaderProps {
  order: DuffelAPI.Types.Order
  orderChangeRequest: DuffelAPI.Types.OrderChangeRequest
}

export const OrderChangeResultsHeader: React.FC<
  OrderChangeResultsHeaderProps
> = ({ order, orderChangeRequest }) => {
  const [isEditSearchModalOpen, setIsEditSearchModalOpen] =
    React.useState(false)
  const [isEditSearchLoading, setIsEditSearchLoading] = React.useState(false)

  const searchType = getSearchType(order.slices)
  const updatedOrder = applyChangeRequestToOrder(order, orderChangeRequest)

  if (searchType === null) {
    captureException(
      Error('Unknown search type - offer request has no slices'),
      {
        offerRequestId: order.id,
        slices: order.slices,
      }
    )
  }

  const slicesToSearch = orderChangeRequest.slices.add

  return (
    <>
      <div>
        <div className="search-results-header__info">
          <div>
            <div>
              <Stamp
                color="grey"
                size="medium"
                label={capitalize(searchType!.replace('_', ' '))}
              />
            </div>
            <div>
              <Stamp
                color="grey"
                size="medium"
                label={`${getDateString(
                  slicesToSearch[0].departureDate,
                  'long'
                )}${
                  slicesToSearch.length > 1
                    ? ' - ' +
                      getDateString(
                        slicesToSearch[slicesToSearch.length - 1].departureDate,
                        'long'
                      )
                    : ''
                }`}
              />
            </div>
            <div>
              <Stamp
                color="grey"
                size="medium"
                label={startCase(
                  `${order.passengers.length} passenger${
                    order.passengers.length > 1 ? 's' : ''
                  }`
                )}
              />
            </div>
          </div>
          <Button
            text="Edit search"
            onClick={() => {
              trackEvent(
                'dashboard_change_flights_results_edit_search_button_clicked',
                {
                  event_type: 'interaction',
                  order_id: order.id,
                  order_change_request_id: orderChangeRequest.id,
                }
              )
              setIsEditSearchModalOpen(true)
            }}
            intent="MUTED"
          />
        </div>
        {searchType && <OrderChangeResultsHeaderTitle order={updatedOrder} />}
        <style jsx>{`
          .search-results-header__info {
            display: flex;
            justify-content: space-between;
          }

          .search-results-header__info > div {
            display: flex;
            align-items: center;
          }

          .search-results-header__info > div > div {
            margin-right: var(--space-16);
          }

          :global(.edit-search-modal) {
            min-width: 500px !important;
            width: 500px !important;
            max-width: 500px !important;
          }
        `}</style>
      </div>
      {isEditSearchModalOpen && (
        <Modal
          onClose={() => setIsEditSearchModalOpen(false)}
          className="edit-search-modal"
        >
          <CreateOrderChangeFormContainer
            order={order}
            orderChangeRequest={orderChangeRequest}
            onSubmissionComplete={() => {
              setIsEditSearchLoading(false)
              setIsEditSearchModalOpen(false)
            }}
            onSubmissionStarted={() => setIsEditSearchLoading(true)}
          />
          <HSpace space={0} fill className="u-marginTop8">
            <Button
              text="Cancel"
              disabled={isEditSearchLoading}
              onClick={() => {
                trackEvent(
                  'dashboard_change_flights_results_edit_search_cancel_button_clicked',
                  {
                    event_type: 'interaction',
                    order_id: order.id,
                    order_change_request_id: orderChangeRequest.id,
                  }
                )
                setIsEditSearchModalOpen(false)
              }}
              intent="MUTED"
              fill
              large
            />
          </HSpace>
        </Modal>
      )}
    </>
  )
}
