import { Invitation } from '@modules/identity-onboarding'

const getDefaultObject = (() => {
  let seedId = 1
  return (): Invitation => ({
    id: `invite_${(seedId++).toString().padStart(5, '0')}`,
    organisationName: 'Corporation',
    senderName: 'John Smith',
    email: 'test@example.com',
    token: 'token',
  })
})()

export const makeMockInvitation = (
  extendDefault?: Partial<Invitation>
): Invitation => Object.assign({}, getDefaultObject(), extendDefault || {})
