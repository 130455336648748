import * as React from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { lazy } from 'yup'
import { FormField } from '@components/Form'
import { RadioGroup } from '@components/RadioGroup'
import { SearchType, SearchTypeMap } from '@lib/types'
import { trackEvent } from '@lib/tracking'
import {
  getInitialSearchFormValues,
  SearchFormValues,
  transformSearchFormValues,
} from '../../lib'
import {
  MultiCitySearchForm,
  validationSchema as multiCityValidationSchema,
} from './MultiCitySearchForm'
import { validationSchema as oneWayValidationSchema } from './OneWaySearchForm'
import { validationSchema as returnValidationSchema } from './ReturnSearchForm'
import { CommonSearchFormSection } from './CommonSearchFormSection'
import styles from './SearchForm.module.css'
import {
  DepartureDateField,
  DepartureReturnDateRangeField,
  OriginDestinationFields,
} from '.'

export interface SearchFormProps {
  onSubmit: (
    data: SearchFormValues<SearchType>,
    formikActions: FormikHelpers<any>
  ) => void
  initialFormValues?: SearchFormValues<SearchType>
  hideCabinClass?: boolean
}

export const SearchForm: React.FC<SearchFormProps> = ({
  onSubmit,
  initialFormValues,
  hideCabinClass,
}) => {
  const options = Object.entries(SearchTypeMap).map(([value, label]) => ({
    value: value as SearchType,
    label,
  }))

  return (
    <>
      <Formik
        initialValues={initialFormValues || getInitialSearchFormValues()}
        onSubmit={onSubmit}
        validateOnBlur
        validateOnChange={false}
        validationSchema={lazy((value: SearchFormValues<SearchType>) => {
          switch (value.type) {
            case 'one_way':
              return oneWayValidationSchema.required()
            case 'return':
              return returnValidationSchema.required()
            case 'multi_city':
              return multiCityValidationSchema.required()
          }
        })}
      >
        {({ values, handleSubmit, resetForm, errors }) => (
          <>
            <FormField label="Journey type" optionalField>
              <div className={styles['radio-group-container']}>
                <RadioGroup
                  options={options}
                  value={values.type}
                  color="green"
                  onChange={(value: SearchType) => {
                    trackEvent(
                      'dashboard_multistep_search_journey_type_selected',
                      {
                        event_type: 'interaction',
                        search_type: value,
                      }
                    )
                    const newFormValues = transformSearchFormValues(
                      values.type,
                      value,
                      values
                    )
                    // using resetForm here so that we can reset the touched state
                    resetForm({ values: newFormValues })
                  }}
                />
              </div>
            </FormField>

            {values.type === 'one_way' && (
              <Form onSubmit={handleSubmit}>
                <OriginDestinationFields
                  originFieldName="origin"
                  destinationFieldName="destination"
                />
                <DepartureDateField
                  name="departureDate"
                  searchTimeRangesFieldName="timeRanges"
                />
                <CommonSearchFormSection hideCabinClass={hideCabinClass} />
              </Form>
            )}
            {values.type === 'return' && (
              <Form onSubmit={handleSubmit}>
                <OriginDestinationFields
                  originFieldName="origin"
                  destinationFieldName="destination"
                />
                <DepartureReturnDateRangeField includesSearchTimeFilters />
                <CommonSearchFormSection hideCabinClass={hideCabinClass} />
              </Form>
            )}
            {values.type === 'multi_city' && (
              <MultiCitySearchForm formik={{ handleSubmit, values, errors }} />
            )}
          </>
        )}
      </Formik>
    </>
  )
}
