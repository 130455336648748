import { intervalToDuration } from 'date-fns'
import { parse } from 'tinyduration'

/**
 * This function calculates the difference between two ISO-8601 durations
 * @param startDuration
 * @param endDuration
 * @returns isNegative, daysDifference, hoursDifference, minutesDifference
 */
export const getDurationDifference = (
  startDuration: string,
  endDuration: string
) => {
  const {
    days: beginDays,
    hours: beginHours,
    minutes: beginMinutes,
  } = parse(startDuration)

  const {
    days: endDays,
    hours: endHours,
    minutes: endMinutes,
  } = parse(endDuration)

  const start = new Date(
    0,
    0,
    beginDays || 0,
    beginHours || 0,
    beginMinutes || 0
  )
  const end = new Date(0, 0, endDays || 0, endHours || 0, endMinutes || 0)

  const {
    days: daysDifference,
    hours: hoursDifference,
    minutes: minutesDifference,
  } = intervalToDuration({ start: end, end: start })

  const isNegative = start > end

  return {
    isNegative,
    daysDifference,
    hoursDifference,
    minutesDifference,
  }
}
