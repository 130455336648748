import * as React from 'react'
import { noop, uniqBy } from 'lodash'
import { pipe } from 'lodash/fp'
import { VSpace } from '@components/VSpace'
import { Skeletonise } from '@components/Skeleton/Skeleton'
import { Fade } from '@components/Fade/Fade'

import { PostSearchFilters } from '@lib/paths'
import {
  makeMockOffer,
  makeMockOfferSliceFromOriginDestination,
} from '@lib/testing'
import {
  getScrollLocations,
  ScrollLocation,
} from '@lib/helpers/get-scroll-locations'
import { useFeatureFlags } from '@lib/unleash'
import { SearchTimeRanges } from '@modules/air-search-v2/forms/SearchForm/lib/search-time-ranges'
import { SearchFormValues } from '@modules/air-search-v2/lib/transform-search-form-values'
import { SearchDetails } from '@modules/air-search-v2/SearchDetails/SearchDetails'
import {
  getParallelisedSearchState,
  SearchResultsState,
} from '@modules/air-search-v2/lib/multi-step-search/types'
import { NoSearchResults } from '@modules/air-search-v2/components/NoSearchResults/NoSearchResults'
import { SearchResultCard } from '../../components'
import { SearchResultsFilters } from '../../components/SearchResultsFilters/SearchResultsFilters'
import styles from './SearchResultsContainer.module.css'
import { filterOffers, sortOffers } from './lib'
import { EditSearchModal } from './EditSearchModal'

interface SearchResultsContainerProps {
  formValues: SearchFormValues
  searchState: Extract<
    SearchResultsState,
    { state: 'loading-results' | 'results' }
  >
  onPartialOfferSelect: (
    partialOfferId: string,
    partialOfferRequestId: string
  ) => void
  preSearchTimeRanges?: SearchTimeRanges
  onPostSearchFiltersUpdate: (postSearchFilters: PostSearchFilters) => void
}

export const SearchResultsContainer: React.FC<SearchResultsContainerProps> = ({
  formValues,
  onPartialOfferSelect,
  onPostSearchFiltersUpdate,
  searchState,
  preSearchTimeRanges,
}) => {
  const resultsContainerElementRef = React.useRef<HTMLDivElement>(null)
  const sidebarElementRef = React.useRef<HTMLDivElement>(null)
  // dashboard_show_private_fares flag is only turned on for specific organisations
  const showPrivateFares = useFeatureFlags('dashboard_show_private_fares')
  const [resultsScrollLocation, setResultsScrollLocation] =
    React.useState<ScrollLocation>('start')
  const [sidebarScrollLocation, setSidebarScrollLocation] =
    React.useState<ScrollLocation>('start')

  const handleResultsScrollEvent = () => {
    if (resultsContainerElementRef.current) {
      const { scrollY } = getScrollLocations(resultsContainerElementRef.current)
      setResultsScrollLocation(scrollY)
    }
  }

  const handleSidebarScrollEvent = () => {
    if (sidebarElementRef.current) {
      const { scrollY } = getScrollLocations(sidebarElementRef.current)
      setSidebarScrollLocation(scrollY)
    }
  }

  React.useEffect(() => {
    if (searchState.state === 'loading-results') {
      setResultsScrollLocation('start')
    }
  }, [searchState.state])

  const hasOffers =
    searchState.state === 'results' &&
    searchState.partialOfferRequest.offers &&
    searchState.partialOfferRequest.offers.length > 0

  const sortedAndFilteredOffers =
    searchState.state === 'results'
      ? pipe(
          filterOffers(searchState.postSearchFilters),
          sortOffers(searchState.postSearchFilters?.sorting)
        )(searchState.partialOfferRequest.offers ?? [])
      : []

  const [isEditSearchModalOpen, setIsEditSearchModalOpen] =
    React.useState(false)

  return (
    <div className={styles['container']}>
      <div className={styles['sidebar']}>
        <div
          className={styles['gradient-top']}
          style={{ opacity: sidebarScrollLocation === 'start' ? 0 : 1 }}
        />
        <div
          className={styles['gradient-bottom']}
          style={{ opacity: sidebarScrollLocation === 'end' ? 0 : 1 }}
        />
        <div
          ref={sidebarElementRef}
          className={`${styles['sidebar__content']} u-scrollContainer`}
          onScroll={handleSidebarScrollEvent}
        >
          <SearchDetails
            formValues={formValues}
            onEditButtonClick={() => setIsEditSearchModalOpen(true)}
          />
          {isEditSearchModalOpen && (
            <EditSearchModal
              formValues={formValues}
              setIsEditSearchModalOpen={setIsEditSearchModalOpen}
            />
          )}
          <SearchResultsFilters
            postSearchFilters={searchState.postSearchFilters}
            onPostSearchFiltersUpdate={onPostSearchFiltersUpdate}
            airlinesToFilter={
              searchState.state === 'results'
                ? uniqBy(
                    searchState.partialOfferRequest.offers?.flatMap((offer) =>
                      offer.slices[0].segments.flatMap((segment) => [
                        segment.operatingCarrier,
                        segment.marketingCarrier,
                      ])
                    ),
                    'iataCode'
                  ).sort((airlineA, airlineB) =>
                    airlineA.name.localeCompare(airlineB.name)
                  ) ?? []
                : []
            }
            preSearchTimeRanges={preSearchTimeRanges}
            disabled={
              searchState.state !== 'results' ||
              getParallelisedSearchState(searchState) === 'loading'
            }
          />
        </div>
      </div>

      <div className={styles['results']}>
        <div
          className={styles['gradient-top']}
          style={{ opacity: resultsScrollLocation === 'start' ? 0 : 1 }}
        />
        <div
          className={styles['gradient-bottom']}
          style={{ opacity: resultsScrollLocation === 'end' ? 0 : 1 }}
        />

        {searchState.state === 'results' && (
          <>
            {(!hasOffers || !sortedAndFilteredOffers.length) &&
              ['disabled', 'finished'].includes(
                getParallelisedSearchState(searchState)
              ) && (
                <Fade>
                  <div className={styles['no-results-wrapper']}>
                    <NoSearchResults
                      formValues={formValues}
                      postSearchFilters={searchState.postSearchFilters}
                      onEditSearch={() => setIsEditSearchModalOpen(true)}
                      onClearPostSearchFilters={() =>
                        onPostSearchFiltersUpdate({})
                      }
                    />
                  </div>
                </Fade>
              )}

            {sortedAndFilteredOffers.length > 0 && (
              <div
                className={`${styles['results__cards']} u-scrollContainer`}
                onScroll={handleResultsScrollEvent}
                ref={resultsContainerElementRef}
              >
                {sortedAndFilteredOffers?.map((offer) => (
                  <SearchResultCard
                    offer={offer}
                    key={offer.id}
                    partialOfferRequestId={searchState.partialOfferRequest.id}
                    onSelect={() =>
                      onPartialOfferSelect(
                        offer.id,
                        offer.partialOfferRequestId
                      )
                    }
                    showNegotiatedFareStamp={showPrivateFares}
                  />
                ))}
                {/*
                   This loading state is only specific to the parallelised search experience,
                   which is a hidden feature for only demo accounts at the moment.
                */}
                {getParallelisedSearchState(searchState) === 'loading' && (
                  <div className={styles['more-results-skeleton']}>
                    <Skeletonise>
                      <SearchResultCard
                        key={'loading'}
                        offer={makeMockOffer({
                          slices: [
                            makeMockOfferSliceFromOriginDestination(
                              'JFK',
                              'LHR',
                              ''
                            ),
                          ],
                        })}
                        partialOfferRequestId={''}
                        onSelect={noop}
                      />
                    </Skeletonise>
                  </div>
                )}
              </div>
            )}
          </>
        )}

        {(searchState.state === 'loading-results' ||
          (getParallelisedSearchState(searchState) === 'loading' &&
            sortedAndFilteredOffers.length === 0)) && (
          <Fade>
            <Skeletonise>
              <VSpace space={12} className="u-skeletonable--show u-flex1">
                {[0, 1, 2, 3, 4].map((order) => (
                  <SearchResultCard
                    key={order}
                    offer={makeMockOffer({
                      slices: [
                        makeMockOfferSliceFromOriginDestination(
                          'JFK',
                          'LHR',
                          ''
                        ),
                      ],
                    })}
                    partialOfferRequestId={''}
                    onSelect={noop}
                  />
                ))}
              </VSpace>
            </Skeletonise>
          </Fade>
        )}
      </div>
    </div>
  )
}
