import { getDateString } from '@lib/date'
import { DuffelAPI } from '@lib/types'
import { getSearchType } from './get-search-type'
import { isOrder, OfferRequestOrOrder } from './is-order'

export const getDateLabel = (fromObject: OfferRequestOrOrder) => {
  if (!fromObject.slices || !fromObject.slices[0]) return ''

  if (isOrder(fromObject)) {
    return getDateLabelForOrder(fromObject)
  } else {
    return getDateLabelForOfferRequest(fromObject)
  }
}

export const getDateLabelForOfferRequest = (
  offerRequest: DuffelAPI.Types.OfferRequest
) => {
  const firstSlice = offerRequest.slices[0]
  const lastSlice = offerRequest.slices[offerRequest.slices.length - 1]

  const searchType = getSearchType(offerRequest.slices)

  if (searchType === 'one_way') {
    return firstSlice ? getDateString(firstSlice.departureDate, 'long') : ''
  } else {
    return firstSlice && lastSlice
      ? `${getDateString(firstSlice.departureDate, 'long')} - ${getDateString(
          lastSlice.departureDate,
          'long'
        )}`
      : ''
  }
}

export const getDateLabelForOrder = (order: DuffelAPI.Types.Order) => {
  const firstSlice = order.slices[0]
  const lastSlice = order.slices[order.slices.length - 1]

  const firstDeparture =
    firstSlice.segments &&
    firstSlice.segments[0] &&
    firstSlice.segments[0].departingAt
  const lastDeparture =
    lastSlice.segments &&
    lastSlice.segments[0] &&
    lastSlice.segments[0].departingAt

  const searchType = getSearchType(order.slices)
  if (searchType === 'one_way') {
    return firstSlice ? getDateString(firstDeparture, 'long') : ''
  } else if (firstSlice && lastSlice) {
    return `${getDateString(firstDeparture, 'long')} - ${getDateString(
      lastDeparture,
      'long'
    )}`
  } else {
    return ''
  }
}
