import * as React from 'react'
import classNames from 'classnames'
import { SpinnerColor, SpinnerSize } from './types'
import styles from './Spinner.module.css'

const SPINNER_SIZE_WIDTH_MAP: Record<SpinnerSize, number> = {
  small: 16,
  medium: 24,
}
export interface SpinnerProps {
  size?: SpinnerSize

  /**
   * Specifies the colour variant of the spinner.
   *
   * Use `'INHERIT'` if you want the colour of the spinner to match the colour
   * of the parent element.
   *
   * @default
   * 'grey'
   */
  color?: SpinnerColor

  /**
   * Fill width of parent.
   *
   * @default
   * true
   */
  fill?: boolean

  /**
   * Allows for additional CSS class names to be apply
   * (Should only be used for layout changes)
   */
  className?: string
}

export const Spinner: React.FC<SpinnerProps> = ({
  size = 'medium',
  color = 'grey',
  fill = true,
  className,
}) => {
  const cn = classNames(
    className,
    styles['wrapper'],
    styles[`wrapper--${color}`],
    {
      [styles['wrapper--fill']]: fill,
    }
  )

  return (
    <div className={cn}>
      <svg
        className={styles['spinner']}
        width={SPINNER_SIZE_WIDTH_MAP[size]}
        height={SPINNER_SIZE_WIDTH_MAP[size]}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={styles['spinner__ring']}
          d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM2.4 12C2.4 17.3019 6.69807 21.6 12 21.6C17.3019 21.6 21.6 17.3019 21.6 12C21.6 6.69807 17.3019 2.4 12 2.4C6.69807 2.4 2.4 6.69807 2.4 12Z"
        />
        <path
          className={styles['spinner__banana']}
          d="M24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24L12 21.6C14.5461 21.6 16.9879 20.5886 18.7882 18.7882C20.5886 16.9879 21.6 14.5461 21.6 12L24 12Z"
        />
      </svg>
    </div>
  )
}
