import classNames from 'classnames'
import { Icon, IconName } from '@components/Icon'
import { Tooltip } from '@components/Tooltip'
import styles from './OrderHeadingBlockBadge.module.css'

export interface OrderHeadingBlockBadgeProps {
  icon: IconName
  badgeType?: 'warning'
  tooltipText?: string
}

export const OrderHeadingBlockBadge: React.FC<OrderHeadingBlockBadgeProps> = ({
  icon,
  badgeType,
  tooltipText,
}) => {
  const cx = classNames({
    [styles['warning']]: badgeType === 'warning',
  })

  return (
    <div className={styles['order-heading-block-badge']}>
      <Tooltip text={tooltipText} placement="top">
        <Icon name={icon} size={16} className={cx} />
      </Tooltip>
    </div>
  )
}
