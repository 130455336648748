import { DuffelAPI } from '@lib/types'

const getDefaultObject = (() => {
  return (): DuffelAPI.Types.OrderAvailableService => ({
    type: 'baggage',
    totalCurrency: 'GBP',
    totalAmount: '28.99',
    segmentIds: ['seg_1'],
    passengerIds: ['pas_1'],
    metadata: {
      type: 'checked',
      maximumWeightKg: 23,
      maximumLengthCm: null,
      maximumHeightCm: null,
      maximumDepthCm: null,
    },
    maximumQuantity: 1,
    id: 'aso_123',
  })
})()

export const makeMockOrderAvailableServices = (
  extendDefault?: Partial<DuffelAPI.Types.OrderAvailableService>
): DuffelAPI.Types.OrderAvailableService =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
