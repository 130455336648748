import { CookieSerializeOptions, serialize } from 'cookie'

export const setServerCookie = (
  name: string,
  value: unknown,
  cookieOptions: CookieSerializeOptions = {}
) => {
  const stringValue =
    typeof value === 'object' ? 'j:' + JSON.stringify(value) : String(value)
  let options: CookieSerializeOptions = {}

  if (cookieOptions && cookieOptions.maxAge) {
    options = {
      expires: new Date(Date.now() + cookieOptions.maxAge),
      maxAge: cookieOptions.maxAge / 1000,
      ...cookieOptions,
    }
  }

  return serialize(name, stringValue, options)
}
