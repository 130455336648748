import { SearchMenu } from './SearchMenu'

const options = {
  0: 'Non-stop',
  1: 'Single stop',
  2: 'Many stops',
}

export const StopsMenu: React.FC = () => (
  <SearchMenu
    label="STOPS"
    options={options}
    urlQueryParam="maxConnections"
    customIsOptionSelected={(value, selectedValue) =>
      selectedValue === '' || value <= selectedValue
    }
  />
)
