import * as React from 'react'
import { Text } from '@components/legacy-design-system/product/components/Text'
import { FlightInformationUpdate } from '@components/FlightInformationUpdate'
import { getDurationString } from '@lib/date'
import { TravelDetails } from '@lib/types'

export interface SingleChangeItemProps {
  hasGroupedChanges?: never
  origin?: never
  destination?: never
  changedProperty?: string
  diffs?: string[]
  beforeSegment?: TravelDetails<'diff'>
  afterSegment?: TravelDetails<'diff'>
}

export interface MultipleChangeItemProps {
  hasGroupedChanges?: boolean
  origin?: string
  destination?: string
  changedProperty?: never
  diffs?: never
  beforeSegment?: never
  afterSegment?: never
}

export type ChangeItemProps = SingleChangeItemProps | MultipleChangeItemProps

export const ChangeItem: React.FC<ChangeItemProps> = ({
  changedProperty,
  diffs,
  beforeSegment,
  afterSegment,
  hasGroupedChanges,
  origin,
  destination,
}) => {
  if (afterSegment && !beforeSegment) {
    return changedProperty === 'direct' ? (
      <FlightInformationUpdate status="changed">
        <Text asElement="span" color="grey-900">
          {afterSegment.origin}&nbsp;
        </Text>
        to&nbsp;
        <Text asElement="span" color="grey-900">
          {afterSegment.destination}&nbsp;
        </Text>
        is now a non-stop flight.
      </FlightInformationUpdate>
    ) : (
      <FlightInformationUpdate status="added">
        A flight from&nbsp;
        <Text asElement="span" color="grey-900">
          {afterSegment.origin}&nbsp;
        </Text>
        to&nbsp;
        <Text asElement="span" color="grey-900">
          {afterSegment.destination}&nbsp;
        </Text>
        has been added.
      </FlightInformationUpdate>
    )
  } else if (beforeSegment && !afterSegment) {
    return changedProperty === 'direct' ? (
      <FlightInformationUpdate status="changed">
        <Text asElement="span" color="grey-900">
          {beforeSegment.origin}&nbsp;
        </Text>
        to&nbsp;
        <Text asElement="span" color="grey-900">
          {beforeSegment.destination}&nbsp;
        </Text>
        is no longer a non-stop flight.
      </FlightInformationUpdate>
    ) : (
      <FlightInformationUpdate status="removed">
        The flight from&nbsp;
        <Text asElement="span" color="grey-900">
          {beforeSegment.origin}&nbsp;
        </Text>
        to&nbsp;
        <Text asElement="span" color="grey-900">
          {beforeSegment.destination}&nbsp;
        </Text>
        has been cancelled.
      </FlightInformationUpdate>
    )
  } else if (hasGroupedChanges && origin && destination) {
    return (
      <FlightInformationUpdate status="changed">
        There have been multiple changes to the flight from&nbsp;
        <Text asElement="span" color="grey-900">
          {' '}
          {origin}&nbsp;
        </Text>
        to&nbsp;
        <Text asElement="span" color="grey-900">
          {' '}
          {destination}
        </Text>
        .
      </FlightInformationUpdate>
    )
  } else if (beforeSegment && afterSegment) {
    // Changes to departingAt and arrivingAt have to be handled together
    // if both departingAt and arrivingAt have changed
    // if only departingAt has changed
    // if only arrivingAt has changed
    if (changedProperty === 'departingAt' && diffs?.includes('arrivingAt')) {
      const departingAtTimeDifference = getDurationString(
        beforeSegment.departingAt,
        afterSegment.departingAt,
        'long'
      )
      const arrivingAtTimeDifference = getDurationString(
        beforeSegment.arrivingAt,
        afterSegment.arrivingAt,
        'long'
      )

      return (
        <FlightInformationUpdate status="changed">
          Passengers will depart from&nbsp;
          <Text asElement="span" color="grey-900">
            {afterSegment.origin}&nbsp;
          </Text>
          {departingAtTimeDifference}, and arrive at&nbsp;
          <Text asElement="span" color="grey-900">
            {afterSegment.destination}&nbsp;
          </Text>
          {arrivingAtTimeDifference}.
        </FlightInformationUpdate>
      )
    } else if (
      changedProperty === 'arrivingAt' &&
      !diffs?.includes('departingAt')
    ) {
      const arrivingAtTimeDifference = getDurationString(
        beforeSegment.arrivingAt,
        afterSegment.arrivingAt,
        'long'
      )

      return (
        <FlightInformationUpdate status="changed">
          Passengers will depart from&nbsp;
          <Text asElement="span" color="grey-900">
            {afterSegment.origin}&nbsp;
          </Text>
          at the same time, and arrive at&nbsp;
          <Text asElement="span" color="grey-900">
            {afterSegment.destination}&nbsp;
          </Text>
          {arrivingAtTimeDifference}.
        </FlightInformationUpdate>
      )
    } else if (
      changedProperty === 'departingAt' &&
      !diffs?.includes('arrivingAt')
    ) {
      const departingAtTimeDifference = getDurationString(
        beforeSegment.departingAt,
        afterSegment.departingAt,
        'long'
      )

      return (
        <FlightInformationUpdate status="changed">
          Passengers will depart from&nbsp;
          <Text asElement="span" color="grey-900">
            {afterSegment.origin}&nbsp;
          </Text>
          {departingAtTimeDifference}, and arrive at&nbsp;
          <Text asElement="span" color="grey-900">
            {afterSegment.destination}&nbsp;
          </Text>
          at the same time.
        </FlightInformationUpdate>
      )
    } else if (changedProperty === 'aircraft' && afterSegment.aircraft) {
      return (
        <FlightInformationUpdate status="changed">
          The aircraft type has changed on the flight from&nbsp;
          <Text asElement="span" color="grey-900">
            {afterSegment.origin}
          </Text>
          &nbsp;to&nbsp;
          <Text asElement="span" color="grey-900">
            {afterSegment.destination}
          </Text>
          .
        </FlightInformationUpdate>
      )
    } else if (
      changedProperty === 'operatingCarrier' &&
      afterSegment.operatedBy
    ) {
      return (
        <FlightInformationUpdate status="changed">
          The operating carrier has changed on the flight from&nbsp;
          <Text asElement="span" color="grey-900">
            {afterSegment.origin}
          </Text>
          &nbsp;to&nbsp;
          <Text asElement="span" color="grey-900">
            {afterSegment.destination}
          </Text>
          .
        </FlightInformationUpdate>
      )
    } else if (changedProperty === 'cabinClass' && afterSegment.cabinClass) {
      return (
        <FlightInformationUpdate status="changed">
          The cabin class has changed on the flight from&nbsp;
          <Text asElement="span" color="grey-900">
            {afterSegment.origin}
          </Text>
          &nbsp;to&nbsp;
          <Text asElement="span" color="grey-900">
            {afterSegment.destination}
          </Text>
          .
        </FlightInformationUpdate>
      )
    }
  }
  return null
}
