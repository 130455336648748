import * as React from 'react'
import { VSpace } from '@components/VSpace'
import { FiltersAction } from '@components/Filters/lib/actions'
import { FiltersState } from '@components/Filters/lib/state'
import { Filters } from '@components/Filters'
import { HSpace } from '@components/HSpace'
import { DuffelAPI } from '@lib/types'
import { OrderChangeResultsList } from '@modules/air-order-change'
import { OrderChangeResultsHeader } from '@modules/air-order-change'

export interface OrderChangeResultsContainerProps {
  order: DuffelAPI.Types.Order
  orderChangeRequest: DuffelAPI.Types.OrderChangeRequest
  filtersState: FiltersState
  filtersDispatch: React.Dispatch<FiltersAction>
}

export const OrderChangeResultsContainer: React.FC<
  OrderChangeResultsContainerProps
> = ({ order, orderChangeRequest, filtersState, filtersDispatch }) => {
  return (
    <VSpace
      data-selector="fs-show"
      space={8}
      id="order-change-result-container"
    >
      <OrderChangeResultsHeader
        order={order}
        orderChangeRequest={orderChangeRequest}
      />
      <HSpace space={8} className="u-paddingVertical16">
        <Filters
          label="Refine results"
          filtersState={filtersState}
          filtersDispatch={filtersDispatch}
          hideFilterMenuHeader
        />
      </HSpace>
      <OrderChangeResultsList
        order={order}
        orderChangeRequest={orderChangeRequest}
      />
    </VSpace>
  )
}
