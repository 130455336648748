/**
 * @returns {string} The origin to use for the Duffel API based on the current `NEXT_PUBLIC_APP_ENV` environment variable.
 */
export function getOriginToDuffelAPI() {
  const isServer = typeof window === 'undefined'

  if (process.env.NEXT_PUBLIC_APP_ENV === 'production') {
    return isServer
      ? 'https://platform-v1.production.svc.cluster.local'
      : 'https://api.duffel.com'
  }

  if (process.env.NEXT_PUBLIC_APP_ENV === 'staging') {
    return isServer
      ? 'https://platform-v1.staging.svc.cluster.local'
      : 'https://api.staging.duffel.com'
  }

  // NODE_TLS_REJECT_UNAUTHORIZED is needed
  // to reach the local API over HTTPS
  // Set this in your .env.development
  // process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
  return 'https://localhost:4000'
}
