import { date, object, ref } from 'yup'
import { iataCode, sliceDepartureDate } from '@lib/form-validation'
import { DuffelAPI } from '@lib/types'
import {
  CommonSearchFormSectionValues,
  commonValidationSchema,
  searchTimeRangesSchema,
} from '../CommonSearchFormSection'
import { sanitisePassengersLoyaltyProgrammeAccounts } from '../lib'
import {
  formatTimeRangeForPayload,
  SearchTimeRanges,
} from '../lib/search-time-ranges'

export interface ReturnSearchFormValues extends CommonSearchFormSectionValues {
  type: 'return'
  origin: string
  destination: string
  departureDate: Date | null
  returnDate: Date | null
  departureTimeRanges?: SearchTimeRanges
  returnTimeRanges?: SearchTimeRanges
}

export const transformValuesIntoPayload = (
  data: ReturnSearchFormValues
): DuffelAPI.Inputs.OfferRequest => {
  const payload: DuffelAPI.Inputs.OfferRequest = {
    slices: [
      {
        origin: data.origin,
        destination: data.destination,
        departureDate:
          data.departureDate === null
            ? ''
            : data.departureDate.toISOString().split('T')[0],
      },
      {
        origin: data.destination,
        destination: data.origin,
        departureDate:
          data.returnDate === null
            ? ''
            : data.returnDate.toISOString().split('T')[0],
      },
    ],
    // sanitise loyalty programme accounts to avoid any API errors
    passengers: sanitisePassengersLoyaltyProgrammeAccounts(data.passengers),
    supplierTimeout: data.supplierTimeout,
  }

  if (data.cabinClass !== 'any') {
    payload.cabinClass = data.cabinClass
  }

  if (data.airlines.length > 0) {
    payload.requestedSources = data.airlines
  }

  const maybeAddSearchTimeRangesToPayload = (
    timeRanges: SearchTimeRanges | undefined,
    index: number
  ) => {
    const departureTime = formatTimeRangeForPayload(timeRanges?.departureTime)
    if (departureTime) {
      payload.slices[index].departureTime = departureTime
    }
    const arrivalTime = formatTimeRangeForPayload(timeRanges?.arrivalTime)
    if (arrivalTime) {
      payload.slices[index].arrivalTime = arrivalTime
    }
  }
  maybeAddSearchTimeRangesToPayload(data.departureTimeRanges, 0)
  maybeAddSearchTimeRangesToPayload(data.returnTimeRanges, 1)

  return payload
}

export const validationSchema = object().shape({
  origin: iataCode('Origin'),
  destination: iataCode('Destination'),
  departureDate: sliceDepartureDate('departure'),
  returnDate: date().when('departureDate', {
    is: (departureDate) => !isNaN(departureDate),
    then: sliceDepartureDate('return').min(
      ref('departureDate'),
      'Return date must be after departure'
    ),
    otherwise: sliceDepartureDate('return'),
  }),
  departureTimeRanges: searchTimeRangesSchema,
  returnTimeRanges: searchTimeRangesSchema,
  ...commonValidationSchema,
})
