export type TransformFn = (key: string) => string

export const transformDataKeys = (
  value: any,
  transformFunction: TransformFn,
  ignoreMetadata = false
): any => {
  if (Array.isArray(value))
    return value.map((valueEntry) =>
      transformDataKeys(valueEntry, transformFunction)
    )

  if (value && typeof value === 'object') {
    return Object.keys(value).reduce((collected, key) => {
      const newKey = transformFunction(key)
      // we don't want to transform metadata data
      let updatedValue
      if (ignoreMetadata) {
        updatedValue = value[key]
        if (key !== 'metadata') {
          updatedValue = transformDataKeys(
            value[key],
            transformFunction,
            ignoreMetadata
          )
        }
      } else {
        updatedValue = transformDataKeys(value[key], transformFunction)
      }
      collected[newKey] = updatedValue
      return collected
    }, {} as { [key: string]: any })
  }

  return value
}
