import * as React from 'react'

export function useKeyPress(
  targetKey: string,
  eventHandler?: (event: KeyboardEvent) => void
) {
  const [keyPressed, setKeyPressed] = React.useState(false)

  const downHandler = React.useCallback(
    (event) => {
      if (event.key === targetKey) {
        setKeyPressed(true)
        eventHandler && eventHandler(event)
      }
    },
    [targetKey, eventHandler]
  )

  const upHandler = React.useCallback(
    ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false)
      }
    },
    [targetKey]
  )

  React.useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [upHandler, downHandler])

  return keyPressed
}
