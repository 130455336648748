import { FieldMetaProps } from 'formik'
import { PassengerErrorObject } from './types'

export const getPassengerError = (
  passengerIndex: number,
  passengerErrors?: PassengerErrorObject[] | FieldMetaProps<any>['error']
): string | undefined => {
  // only return the custom error if the passenger's age is invalid
  if (
    typeof passengerErrors === 'object' &&
    passengerErrors?.[passengerIndex]?.age
  ) {
    return `Please select the passenger's age`
  }

  return undefined
}
