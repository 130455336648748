import * as React from 'react'
import { Button, ButtonProps } from '@components/Button'
import { trackEvent } from '@lib/tracking'
import { DuffelAPI } from '@lib/types'
import { useOrderCancellation } from '@modules/air-order-change/lib/use-order-cancellation'

interface CancelOrderButtonProps {
  order: DuffelAPI.Types.Order
  text?: ButtonProps['text']
}

export const CancelOrderButton: React.FC<CancelOrderButtonProps> = ({
  order,
  text = 'Request cancellation',
}) => {
  const { openCancellationDialog } = useOrderCancellation(order)

  return (
    <Button
      outlined
      intent="MUTED"
      text={text}
      onClick={() => {
        trackEvent(
          'dashboard_airline_initiated_change_request_cancellation_clicked',
          {
            event_type: 'interaction',
          }
        )
        openCancellationDialog()
      }}
    />
  )
}
