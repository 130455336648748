import { FieldArray, useField, useFormikContext } from 'formik'
import { ChromelessButton } from '@components/Button'
import { FormikInput } from '@components/Form'
import { trackEvent } from '@lib/tracking'
import { DuffelAPI, DuffelProxy } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import { getLoyaltyProgrammesList } from '@modules/air-search-v2/lib'
import { CommonSearchFormSectionValues } from '../CommonSearchFormSection'
import { FormikAirlinesSelect } from '../FormFields/FormikAirlinesSelect'
import { OfferRequestPassengerWithIndex } from '.'

export const initialLoyaltyProgrammeAccountValues: DuffelAPI.Types.LoyaltyProgrammeAccount =
  {
    airlineIataCode: '',
    accountNumber: '',
  }

export interface LoyaltyAccountsInputProps {
  /**
   * OfferRequestPassenger object with an index from an OfferRequestPassenger[]
   */
  passenger: OfferRequestPassengerWithIndex

  /**
   * The Loyalty Programme Account index correlates to the position this specific loyalty programme account
   * is on passenger.loyaltyProgrammeAccounts array for this specific passenger
   */
  accountIndex: number
}

export const LoyaltyAccountsInput: React.FC<LoyaltyAccountsInputProps> = ({
  passenger,
  accountIndex,
}) => {
  const { permissions } = useWorkspace()
  const { setFieldValue } = useFormikContext<CommonSearchFormSectionValues>()
  const loyaltyProgrammes = getLoyaltyProgrammesList(
    permissions?.liveMode || false
  )

  const formFieldName = `passengers.${passenger.index}.loyaltyProgrammeAccounts.${accountIndex}.airlineIataCode`

  const [{ value: loyaltyProgramme }, , { setValue: setLoyaltyProgramme }] =
    useField<DuffelProxy.Types.SourceAirline['iataCode']>({
      name: formFieldName,
    })

  const shouldShowClearButton =
    passenger.loyaltyProgrammeAccounts?.length === 1 &&
    (passenger.loyaltyProgrammeAccounts[accountIndex].accountNumber !== '' ||
      passenger.loyaltyProgrammeAccounts[accountIndex].airlineIataCode !== '')

  const shouldShowRemoveButton =
    (passenger?.loyaltyProgrammeAccounts?.length ?? 0) > 1

  const shouldShowAddButton =
    (passenger?.loyaltyProgrammeAccounts?.length ?? 0) <= 9 &&
    accountIndex === (passenger?.loyaltyProgrammeAccounts?.length ?? 0) - 1

  return (
    <div className="loyalty-account__container">
      <FieldArray
        name={`passengers.${passenger.index}.loyaltyProgrammeAccounts`}
      >
        {({ remove, push }) => (
          <>
            <div className="loyalty-programme-container">
              <FormikAirlinesSelect
                label="Loyalty programme"
                defaultStateText="Select airline"
                name={formFieldName}
                airlinesList={loyaltyProgrammes}
                onChange={(selectedAirline) => {
                  if (selectedAirline?.iataCode === loyaltyProgramme) {
                    setLoyaltyProgramme('')
                  } else {
                    setLoyaltyProgramme(selectedAirline?.iataCode ?? '')
                  }
                }}
              />
              <FormikInput
                label="Loyalty account"
                name={`passengers.${passenger.index}.loyaltyProgrammeAccounts.${accountIndex}.accountNumber`}
                placeholder="12345678"
                optionalField
              />
            </div>
            <div className="loyalty-account__actions">
              {shouldShowClearButton && !shouldShowRemoveButton && (
                <ChromelessButton
                  className="loyalty-account__button--destroy loyalty-account__button--pink"
                  onClick={(event) => {
                    event.preventDefault()
                    // reset the loyalty programme accounts array to initial value
                    setFieldValue(
                      `passengers.${passenger.index}.loyaltyProgrammeAccounts.${accountIndex}.airlineIataCode`,
                      initialLoyaltyProgrammeAccountValues.airlineIataCode
                    )
                    setFieldValue(
                      `passengers.${passenger.index}.loyaltyProgrammeAccounts.${accountIndex}.accountNumber`,
                      initialLoyaltyProgrammeAccountValues.accountNumber
                    )
                  }}
                >
                  Clear
                </ChromelessButton>
              )}
              {shouldShowRemoveButton && (
                <ChromelessButton
                  className="loyalty-account__button--destroy loyalty-account__button--pink"
                  onClick={(event) => {
                    event.preventDefault()
                    remove(accountIndex)
                    trackEvent(
                      'dashboard_loyalty_accounts_remove_account_clicked',
                      {
                        event_type: 'interaction',
                      }
                    )
                  }}
                >
                  Remove
                </ChromelessButton>
              )}
              {shouldShowAddButton && (
                <ChromelessButton
                  className="loyalty-account__button--add loyalty-account__button--purple"
                  onClick={(event) => {
                    event.preventDefault()
                    push(initialLoyaltyProgrammeAccountValues)
                    trackEvent(
                      'dashboard_loyalty_accounts_add_another_account_clicked',
                      {
                        event_type: 'interaction',
                      }
                    )
                  }}
                >
                  Add another account
                </ChromelessButton>
              )}
            </div>
          </>
        )}
      </FieldArray>
      <style jsx>{`
        .loyalty-account__container {
          border-top: 1px dashed var(--grey-200);
          padding: var(--space-20);
        }

        .loyalty-account__actions {
          display: grid;
          grid-template-columns: [destroy-button] 1fr [add-button] 1fr [row-end];
          font-size: var(--FONT-SIZES-C1);
        }

        .loyalty-programme-container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: var(--space-16);
          margin-bottom: var(--space-16);
        }

        /* :global is necessary so to override the styles of the ChromelessButton */
        :global(.loyalty-account__button--destroy),
        :global(.loyalty-account__button--add) {
          cursor: pointer;
        }

        /* :global is necessary so to override the styles of the ChromelessButton */
        :global(.loyalty-account__button--destroy) {
          justify-self: start;
          grid-column: destroy-button;
        }

        /* :global is necessary so to override the styles of the ChromelessButton */
        :global(.loyalty-account__button--add) {
          cursor: pointer;
          justify-self: end;
          grid-column: add-button;
        }

        /* :global is necessary so to override the styles of the ChromelessButton and
          an additional class is necessary to trump the CSS specificity of the styles
         applied to the button element within ChromelessButton  */
        :global(
            .loyalty-account__button--destroy.loyalty-account__button--pink
          ) {
          color: var(--pink-700);
        }

        /* :global is necessary so to override the styles of the ChromelessButton and
          an additional class is necessary to trump the CSS specificity of the styles
         applied to the button element within ChromelessButton  */
        :global(.loyalty-account__button--add.loyalty-account__button--purple) {
          color: var(--purple-700);
          font-size: var(--FONT-SIZES-C1);
        }
      `}</style>
    </div>
  )
}
