import * as React from 'react'
import classNames from 'classnames'
import { Text, TextProps } from '@components/Text'

export interface HeadingProps extends TextProps {
  /**
   * Specifies whether the element and styling are for an `h1` header.
   *
   * Default: `true`
   */
  h1?: boolean

  /**
   * Specifies whether the element and styling are for an `h2` header.
   *
   * Default: `false`
   */
  h2?: boolean

  /**
   * Specifies whether the element and styling are for an `h3` header.
   *
   * Default: `false`
   */
  h3?: boolean

  /**
   * Specifies whether the element and styling are for an `h4` header.
   *
   * Default: `false`
   */
  h4?: boolean

  /**
   * Specifies whether the element and styling are for an `h5` header.
   *
   * Default: `false`
   */
  h5?: boolean

  /**
   * Specifies font-weight for header
   *
   * Default: `regular`
   */
  fontWeight?: 'regular' | 'medium'
}

/**
 * @deprecated replaced by `@duffel/design-system/product/components/Text` component
 */
export const Heading: React.FC<React.PropsWithChildren<HeadingProps>> = ({
  h1 = true,
  h2,
  h3,
  h4,
  h5,
  children,
  className,
  fontWeight = 'medium',
  ...props
}) => {
  const cx = classNames('heading', className)

  if (h5)
    return (
      <Text
        fontSize="H5"
        lineHeight="tight"
        asElement="h5"
        className={cx}
        fontWeight={fontWeight}
        {...props}
      >
        {children}
      </Text>
    )

  if (h4)
    return (
      <Text
        fontSize="H4"
        lineHeight="tight"
        asElement="h4"
        className={cx}
        fontWeight={fontWeight}
        {...props}
      >
        {children}
      </Text>
    )

  if (h3)
    return (
      <Text
        fontSize="H3"
        lineHeight="tight"
        asElement="h3"
        className={cx}
        fontWeight={fontWeight}
        {...props}
      >
        {children}
      </Text>
    )

  if (h2)
    return (
      <Text
        fontSize="H2"
        lineHeight="tight"
        asElement="h2"
        className={cx}
        fontWeight={fontWeight}
        {...props}
      >
        {children}
      </Text>
    )

  if (h1)
    return (
      <Text
        fontSize="H1"
        lineHeight="tight"
        asElement="h1"
        className={cx}
        fontWeight={fontWeight}
        {...props}
      >
        {children}
      </Text>
    )

  return null
}
