import * as React from 'react'
import classNames from 'classnames'
import { VSpace } from '@components/VSpace'
import { Stamp, StampProps } from '@components/Stamp'
import { HSpace } from '@components/HSpace'
import styles from './OrderInformationBox.module.css'

export interface OrderInformationBoxProps {
  stampText?: string
  stampColor?: StampProps['color']
  buttons?: React.ReactNode
  showDivider?: boolean
  children?: React.ReactNode
}

export const OrderInformationBoxDivider: React.FC<{ dashed?: boolean }> = ({
  dashed,
}) => <div className={styles[dashed ? 'divider--dashed' : 'divider']} />

export const OrderInformationBoxContent: React.FC<{
  className?: string
  children: React.ReactNode
}> = ({ className, children }) => (
  <VSpace space={24} className={classNames(className, styles['content'])}>
    {children}
  </VSpace>
)

export const OrderInformationBox = React.forwardRef<
  HTMLDivElement,
  OrderInformationBoxProps
>(
  (
    { stampText, buttons, children, stampColor = 'grey', showDivider = true },
    ref?
  ) => (
    <VSpace space={24} className={styles['container']} ref={ref}>
      {stampText && (
        <Stamp
          className={styles['stamp']}
          label={stampText}
          color={stampColor}
          border
        />
      )}

      {children}

      <VSpace space={12}>
        {showDivider && <OrderInformationBoxDivider />}

        {buttons && (
          <HSpace
            space={8}
            justify="end"
            data-selector="fs-show"
            className={styles['buttons']}
          >
            {buttons}
          </HSpace>
        )}
      </VSpace>
    </VSpace>
  )
)
