import { getDuffelAPIClient } from '@lib/duffel-api/getDuffelAPIClient'
import { AxiosResponse, isAxiosError } from 'axios'
import cookie from 'cookie'
import { IncomingMessage, ServerResponse } from 'http'
import { addHeaderToServerResponse } from './addHeaderToServerResponse'
import {
  CSRF_TOKEN_COOKIE_NAME,
  CSRF_TOKEN_COOKIE_OPTIONS,
  DUFFEL_API_AUTHENTICATION_COOKIE_NAME,
} from './constants'

export async function clearAuthenticationCookies(
  req?: IncomingMessage,
  res?: ServerResponse
) {
  const emptyAndExpiredCsrfTokenCookie = cookie.serialize(
    CSRF_TOKEN_COOKIE_NAME,
    '',
    { ...CSRF_TOKEN_COOKIE_OPTIONS, maxAge: 0 }
  )

  const emptyAndExpiredDuffelApiAuthenticationCookie = cookie.serialize(
    DUFFEL_API_AUTHENTICATION_COOKIE_NAME,
    '',
    { maxAge: 0 }
  )

  // clear session cookie
  const client = getDuffelAPIClient(req, res)
  let signOutResponse: AxiosResponse | undefined
  try {
    signOutResponse = await client.post('/identity/signout')
  } catch (error) {
    if (isAxiosError(error) && error.response?.status !== 401) {
      console.error(
        'Error clearing authentication cookies',
        isAxiosError(error)
          ? console.error(JSON.stringify(error.response?.data))
          : console.error(error)
      )
    }
  }

  // clear CSRF cookie
  if (res) {
    addHeaderToServerResponse(
      'set-cookie',
      [
        emptyAndExpiredCsrfTokenCookie,
        ...(signOutResponse?.headers['set-cookie'] ??
          emptyAndExpiredDuffelApiAuthenticationCookie),
      ],
      res
    )
  } else {
    document.cookie = emptyAndExpiredCsrfTokenCookie
  }
}
