import { DuffelAPI } from '@lib/types'

const defaultObject: DuffelAPI.Types.OfferSliceSegmentPassengerBaggage = {
  type: 'carry_on',
  quantity: 1,
}

export const makeMockOfferSliceSegmentPassengerBaggage = (
  extendDefault?: Partial<DuffelAPI.Types.OfferSliceSegmentPassengerBaggage>
): DuffelAPI.Types.OfferSliceSegmentPassengerBaggage =>
  Object.assign({}, defaultObject, extendDefault || {})
