import classNames from 'classnames'
import * as React from 'react'
import { AnchorButton } from '@components/Button'
import { Card } from '@components/Card'
import { FlightSummary } from '@components/FlightSummary'
import { HSpace } from '@components/HSpace'
import { Icon } from '@components/Icon'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { moneyStringFormatter } from '@lib/helpers'
import { captureException } from '@lib/sentry'
import { trackEvent } from '@lib/tracking'
import { DuffelAPI } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import { applyChangeToOrder } from '@modules/air-order-change/lib'

export interface OrderChangeOfferCardProps {
  order: DuffelAPI.Types.Order
  orderChangeOffer: DuffelAPI.Types.OrderChangeRequestOffer
  onSelect: (orderChangeOffer: DuffelAPI.Types.OrderChangeRequestOffer) => void
  disabled?: boolean
  isWaiting?: boolean
}

export const OrderChangeOfferCard: React.FC<OrderChangeOfferCardProps> = ({
  orderChangeOffer,
  order,
  onSelect,
  disabled,
  isWaiting,
}) => {
  const { currentOrganisation } = useWorkspace()
  const [isExpanded, setIsExpanded] = React.useState(false)

  if (
    order.slices.length === 0 ||
    order.slices[0].segments.length === 0 ||
    order.slices[0].segments[0].passengers.length === 0
  )
    return null

  if (
    !orderChangeOffer.changeTotalCurrency ||
    !orderChangeOffer.changeTotalAmount
  ) {
    captureException(
      new Error(
        `OrderChangeOfferCard was not able to render orderChangeOffer(${orderChangeOffer.id}) because it's missing one of: changeTotalCurrency, changeTotalAmount`
      )
    )

    // fallback
    orderChangeOffer.changeTotalCurrency =
      currentOrganisation?.settlementCurrency || 'GBP'
    orderChangeOffer.changeTotalAmount = ''
  }

  const willRefund =
    orderChangeOffer.changeTotalAmount &&
    orderChangeOffer.changeTotalAmount.startsWith('-') &&
    orderChangeOffer.refundTo
  const orderWithChangeApplied = applyChangeToOrder(order, orderChangeOffer)

  return (
    <Card
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          trackEvent(
            'dashboard_change_flights_results_change_offer_card_clicked',
            {
              event_type: 'interaction',
              order_id: order.id,
              order_change_offer_id: orderChangeOffer.id,
              will_expand: !isExpanded,
            }
          )
          setIsExpanded(!isExpanded)
        }
      }}
      onClick={() => {
        trackEvent(
          'dashboard_change_flights_results_change_offer_card_clicked',
          {
            event_type: 'interaction',
            order_id: order.id,
            order_change_offer_id: orderChangeOffer.id,
            will_expand: !isExpanded,
          }
        )
        setIsExpanded(!isExpanded)
      }}
      className="order-change-offer-card"
    >
      <div className="split">
        <div className="flight-summary-container">
          <FlightSummary
            flight={orderWithChangeApplied}
            isExpanded={isExpanded}
            onlyShowChangedSlices
            hideConditions
          />
        </div>
        <HSpace space={32}>
          <div className="divider"></div>
          <VSpace space={24}>
            <AnchorButton
              isWaiting={isWaiting}
              disabled={disabled}
              className={classNames(
                'split',
                'order-change-offer-card_button',
                willRefund && 'order-change-offer-card_button--refund'
              )}
              iconAfter="arrow_right"
              text={moneyStringFormatter(
                orderChangeOffer.changeTotalCurrency,
                undefined,
                {
                  signDisplay: 'exceptZero',
                }
              )(+orderChangeOffer.changeTotalAmount)}
              onClick={(event) => {
                event.stopPropagation()

                trackEvent(
                  'dashboard_change_flights_results_change_offer_price_button_clicked',
                  {
                    event_type: 'interaction',
                    order_id: order.id,
                    order_change_offer_id: orderChangeOffer.id,
                  }
                )
                onSelect(orderChangeOffer)
              }}
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  event.stopPropagation()
                  trackEvent(
                    'dashboard_change_flights_results_change_offer_price_button_clicked',
                    {
                      event_type: 'interaction',
                      order_id: order.id,
                      order_change_offer_id: orderChangeOffer.id,
                    }
                  )

                  event.target.click()
                }
              }}
              fill
            />
            <VSpace space={16}>
              {willRefund && (
                <Text
                  key={orderChangeOffer.id}
                  color="green-600"
                  className="offer-detail"
                >
                  <Icon
                    size={16}
                    name="auto_renew"
                    className="offer-details__icon"
                  />
                  Refunded to{' '}
                  {orderChangeOffer.refundTo?.toString().replace(/_/g, ' ')}
                </Text>
              )}
            </VSpace>
          </VSpace>
        </HSpace>
      </div>
      <style jsx>{`
        :global(.order-change-offer-card) {
          cursor: pointer;
          border: 1px solid var(--grey-300);
          transition: all var(--transitions-default);
        }

        :global(.order-change-offer-card:focus),
        :global(.order-change-offer-card:hover),
        :global(.order-change-offer-card:active) {
          position: relative;
          cursor: pointer;
          outline: none;
          border: 1px solid var(--purple-500);
          box-shadow: var(--shadow-focus);
          border-radius: var(--border-radius-4);
        }

        :global(.order-change-offer-card .split) {
          display: flex;
          justify-content: space-between;
        }

        :global(.order-change-offer-card_button) {
          min-width: 180px;
          width: 100%;
        }

        :global(.order-change-offer-card .flight-summary-container) {
          flex-grow: 1;
        }

        :global(.order-change-offer-card_button--refund) {
          background-color: var(--green-600);
          border-color: var(--green-600);
        }

        :global(.order-change-offer-card .divider) {
          border-left: 1px solid var(--grey-200);
          margin: calc(var(--space-32) * -1) 0px;
        }

        :global(.order-change-offer-card .offer-detail) {
          display: flex;
          align-items: center;
        }

        :global(.order-change-offer-card .offer-detail svg) {
          margin-right: var(--space-8) !important;
        }
      `}</style>
    </Card>
  )
}
