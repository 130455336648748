import { DuffelAPI } from '@lib/types'
import { makeMockCreateOrderChangeRequestPayloadChangesSlices } from './make-mock-create-order-change-request-payload-changes-slices'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Inputs.CreateOrderChangeRequest => {
    return {
      orderId: `ord_${(seedId++).toString().padStart(5, '0')}`,
      slices: makeMockCreateOrderChangeRequestPayloadChangesSlices(),
    }
  }
})()

export const makeMockCreateOrderChangeRequestPayload = (
  extendDefault?: Partial<DuffelAPI.Inputs.CreateOrderChangeRequest>
): DuffelAPI.Inputs.CreateOrderChangeRequest =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
