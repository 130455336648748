import { array, date, number, object, string } from 'yup'
import { endOfDay, subYears } from 'date-fns'
import { nameRgx, validEmailAddressRequired } from '@lib/form-validation'
import { phoneNumberStr } from '@lib/form-validation/phone-number-str'
import { getPassengerAgeErrorMessage } from './get-passenger-age-error-message'

export const getCheckoutFormValidationSchema = (
  requiresPassportDetails: boolean,
  loyaltyAccountsApplied: boolean,
  offerLastDepartureDate: Date
) =>
  object().shape({
    email: validEmailAddressRequired(),
    phoneNumber: phoneNumberStr(true),
    passengers: array().of(
      object().shape({
        title: string().required('Passenger title is required'),
        givenName: string()
          .matches(nameRgx, 'Passenger name contains invalid characters')
          .required('Passenger given name is required'),
        familyName: string()
          .matches(nameRgx, 'Passenger name contains invalid characters')
          .required('Passenger family name is required'),
        bornOn: date()
          .when('age', (age, schema) => {
            if (age !== null) {
              // If an age was supplied when the order was created (i.e, the
              // passenger was under 18), then validate the `bornOn` field to
              // ensure it matches the supplied age at the date of the
              // last flight departure.

              const minBornOnDate = endOfDay(
                subYears(offerLastDepartureDate, age + 1)
              )
              const maxBornOnDate = endOfDay(
                subYears(offerLastDepartureDate, age)
              )
              const ageValidationErrorMessage = getPassengerAgeErrorMessage(age)

              return schema
                .min(minBornOnDate, ageValidationErrorMessage)
                .max(maxBornOnDate, ageValidationErrorMessage)
            } else {
              // If no age is supplied, we can assume the passenger is over 18
              // and validate accordingly.
              const maxBornOnDate = endOfDay(
                subYears(offerLastDepartureDate, 18)
              )
              return schema.max(
                maxBornOnDate,
                `Passenger must be 18 or older on the date of the last departure.`
              )
            }
          })
          .typeError('Passenger date of birth is required'),
        gender: string().required('Passenger gender is required'),
        ...(requiresPassportDetails && {
          passportDetails: object().shape({
            countryOfIssue: string().required('Country of issue is required'),
            passportNumber: string().required('Passport number is required'),
            expiryDate: date()
              .typeError('Expiry date is required')
              .min(new Date(), 'Expiry date must be in the future'),
          }),
        }),
        ...(loyaltyAccountsApplied && {
          loyaltyProgrammeAccounts: array().of(
            object().shape({
              airlineIataCode: string(),
              accountNumber: string(),
            })
          ),
        }),
        baggage: object().shape({
          type: string(),
          amount: number(),
        }),
      })
    ),
    metadata: array().of(
      object().shape({
        key: string().max(40, 'Exceeds 40 character limit'),
        value: string().max(500, 'Exceeds 500 character limit'),
      })
    ),
  })
