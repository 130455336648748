import * as React from 'react'
import classNames from 'classnames'
import { makeDayInputId } from './lib'
import styles from './DatePickerInput.module.css'

interface DatePickerDayInputProps {
  id: string
  value: string
  onBlur?: (e: React.FocusEvent) => void
  onChange: (value: string) => void
  onTab: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onFocus: () => void
  disabled?: boolean
}

const isValidDateValue = (value: number) => {
  return !isNaN(value) && 0 <= value && value <= 31
}

export const DatePickerDayInput: React.FC<DatePickerDayInputProps> = ({
  id,
  value,
  onBlur,
  onChange,
  onTab,
  onFocus,
  disabled,
}) => {
  const completeId = makeDayInputId(id)
  return (
    <>
      <input
        disabled={disabled}
        id={completeId}
        data-testid={completeId}
        value={value}
        className={classNames(
          styles['date-picker-input'],
          styles['date-picker-input--month']
        )}
        placeholder="DD"
        onFocus={(event) => {
          event.target.select()
          onFocus()
        }}
        onBlur={onBlur}
        onChange={({ target: { value } }) => {
          if (value === '') onChange(value)
          else if (isValidDateValue(+value)) onChange(value)
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Tab' || event.key === 'Enter') onTab(event)
          if (event.key === 'Enter') event.preventDefault()
        }}
      />
    </>
  )
}
