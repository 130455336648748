import classNames from 'classnames'
import * as React from 'react'
import { Button } from '@components/Button'
import { HSpace } from '@components/HSpace'
import { Icon } from '@components/Icon'

export interface FileUploadProps {
  label?: string
  name: string
  className?: string
  filesList?: Array<any>
  multiple: boolean | undefined
  isSubmitting: boolean
  onHandleRefresh: (...args: any[]) => void
  onClickHandle: (...args: any[]) => void
  onChangeHandle: (...args: any[]) => void
}

export const FileUpload: React.FC<FileUploadProps> = ({
  label,
  name,
  className,
  filesList,
  multiple,
  isSubmitting,
  onHandleRefresh,
  onClickHandle,
  onChangeHandle,
}) => {
  const fileInput = React.useRef<HTMLInputElement>(null)
  const resetFileInput = () => {
    const fileInput = document?.querySelector<HTMLInputElement>(
      'input#attachment-upload'
    )
    if (!fileInput) return
    else fileInput.value = ''
  }
  return (
    <div className="ff-upload">
      <input
        name={name}
        type="file"
        id="attachment-upload"
        accept="image/png, image/jpg, image/jpeg, text/plain, application/pdf, image/gif, video/mp4"
        onChange={(event) => {
          onChangeHandle(event)
          resetFileInput()
        }}
        className={classNames('ff-upload__input', className)}
        ref={fileInput}
        multiple={multiple || undefined}
        aria-label="Upload"
      />
      <HSpace space={8} alignCenter>
        <Button
          iconBefore="attachment"
          intent="MUTED"
          outlined
          onClick={() => fileInput.current?.click()}
          text="Upload"
          type="button"
          aria-controls={filesList && filesList.length > 0 ? 'filenames' : null}
          isWaiting={isSubmitting}
        />
        {label && !(Array.isArray(filesList) && filesList.length > 0) && (
          <span className="ff-upload__copy">{label}</span>
        )}
      </HSpace>
      {filesList && filesList.length > 0 && (
        <ul className="ff-upload__list" id="filenames">
          {filesList.map((file, index) => (
            <li
              key={`${file.id}-${index}`}
              className={classNames(
                'ff-upload__list-item',
                `${file.error && 'ff-upload__list-item--error'}`
              )}
            >
              <Icon className="ff-upload__icon--attachment" name="attachment" />
              {file.filename}
              {file.error && (
                <button
                  className="ff-upload__icon--reupload"
                  onClick={() => onHandleRefresh(file.filename)}
                >
                  <Icon name="refresh" />
                </button>
              )}
              <button
                className="ff-upload__icon--delete"
                onClick={() => onClickHandle(file.id)}
              >
                <Icon name="close" />
              </button>
            </li>
          ))}
        </ul>
      )}
      <style jsx>{`
        .ff-upload__input {
          display: none;
        }
        .ff-upload__copy {
          display: inline-block;
          font-family: var(--FONTS-SANS);
          font-size: 14px;
          color: var(--grey-500);
        }
        .ff-upload__btn {
          background-color: var(--purple-200);
          border-radius: var(--border-radius-4);
          padding: var(--space-4) var(--space-16);
          display: inline-block;
        }
        .ff-upload__list {
          display: grid;
          row-gap: var(--space-8);
          list-style: none;
          margin: 0;
          padding: 0;
          margin-top: var(--space-16);
        }
        .ff-upload__list-item {
          display: grid;
          grid-template-columns: 1fr 10fr 1fr;
          align-items: center;
        }
        .ff-upload__list-item :global(svg) {
          fill: var(--grey-300);
          display: inline-block;
          vertical-align: middle;
        }
        .ff-upload__list-item :global(.ff-upload__icon--attachment) {
          transform: rotate(30deg);
          padding-right: var(--space-4);
        }
        .ff-upload__list-item :global(.ff-upload__icon--delete),
        .ff-upload__list-item :global(.ff-upload__icon--reupload) {
          cursor: pointer;
        }
        .ff-upload__list-item {
          color: var(--green-600);
        }
        .ff-upload__list-item.ff-upload__list-item--error {
          color: var(--pink-500);
          grid-template-columns: 1fr 10fr 1fr 1fr;
        }
      `}</style>
    </div>
  )
}
