import { getCarriers } from '@modules/air-search-v2/lib/get-carriers'
import { getFareBrandName } from './get-fare-brand-name'
import { getSegmentFlightNumber } from './get-segment-flight-number'
import { Slice } from './types'

export const getAirlinesText = (
  forSlice: Slice,
  withFlightNumbers?: boolean,
  withFareBrand?: boolean
): string => {
  const fareBrandName = getFareBrandName(
    'fareBrandName' in forSlice ? forSlice.fareBrandName : null,
    forSlice.segments[0]
  )

  const { marketingCarrier, operatingCarriers } = getCarriers(forSlice.segments)
  const isMarketingSameAsOperating =
    operatingCarriers.length > 0 &&
    marketingCarrier &&
    marketingCarrier.name === operatingCarriers[0].name

  const fullyOperatedByOneOtherCarrier =
    operatingCarriers.length === 1 && !isMarketingSameAsOperating

  const fareBrandNameLabel =
    withFareBrand && fareBrandName ? `${fareBrandName} · ` : ''

  const sellerLabel =
    operatingCarriers.length > 1 || fullyOperatedByOneOtherCarrier
      ? 'Sold by '
      : ''

  const operatorLabel = fullyOperatedByOneOtherCarrier ? ', operated by ' : ''

  const partialOperatorLabel =
    operatingCarriers.length > 1 ? ', partially operated by ' : ''

  const airlines = operatingCarriers
    .filter(({ name }) => marketingCarrier && name !== marketingCarrier.name)
    .map(({ name }) => name)
    .join(', ')

  const flightNumbers = withFlightNumbers
    ? ' · ' + forSlice.segments.map(getSegmentFlightNumber).join(', ')
    : ''

  return `${fareBrandNameLabel}${sellerLabel}${
    marketingCarrier && marketingCarrier.name
  }${operatorLabel}${partialOperatorLabel}${airlines}${flightNumbers}`
}
