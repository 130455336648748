import { DuffelAPI } from '@lib/types'
import { makeMockAircraft } from './make-mock-aircraft'
import { makeMockOfferSliceSegmentPassenger } from './make-mock-offer-slice-segment-passenger'
import { makeMockAirline } from './make-mock-airline'
import { makeMockAirport } from './make-mock-airport'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.OfferSliceSegment => ({
    aircraft: makeMockAircraft(),
    arrivingAt: '2021-12-07T19:25:20',
    destinationTerminal: null,
    departingAt: '2021-12-06T19:25:20',
    originTerminal: null,
    destination: makeMockAirport({ iataCode: 'JFK' }),
    distance: null,
    duration: 'PT03H15M',
    id: `seg_${(seedId++).toString().padStart(5, '0')}`,
    marketingCarrier: makeMockAirline(),
    marketingCarrierFlightNumber: 'AA 101',
    origin: makeMockAirport({ iataCode: 'LHR' }),
    operatingCarrier: makeMockAirline(),
    operatingCarrierFlightNumber: 'AA 101',
    passengers: [makeMockOfferSliceSegmentPassenger()],
    stops: [],
  })
})()

export const makeMockOfferSliceSegment = (
  extendDefault?: Partial<DuffelAPI.Types.OfferSliceSegment>
): DuffelAPI.Types.OfferSliceSegment =>
  Object.assign({}, getDefaultObject(), extendDefault || {})

export const makeMockOfferSliceSegmentFromOriginDestination = (
  originIataCode: string,
  destinationIataCode: string,
  passengerId?: string
) =>
  makeMockOfferSliceSegment({
    origin: makeMockAirport({ iataCode: originIataCode }),
    destination: makeMockAirport({ iataCode: destinationIataCode }),
    passengers: [makeMockOfferSliceSegmentPassenger({ passengerId })],
  })
