import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'
import { Text } from '@components/legacy-design-system/product/components/Text'
import { Anchor } from '@components/Anchor'
import { ChromelessButton } from '@components/Button'
import { Icon } from '@components/Icon'
import { VSpace } from '@components/VSpace'
import { trackEvent } from '@lib/tracking'
import { useFeatureFlags } from '@lib/unleash'
import { hasUnsolvedChatAboutOrder, loadChat } from '@lib/zendesk/live-chat'
import { useWorkspace } from '@lib/workspace-context'
import styles from './SupportMenu.module.css'
import { useUser } from '@lib/contexts/WithUserContext'

const relatedLinks = {
  '/[org]/[mode]/orders/[orderId]': {
    href: 'https://help.duffel.com/hc/en-gb/sections/360005398779-Changes-and-Cancellations',
    title: 'Changes and cancellations',
    description: 'Learn how to handle changes and cancellations with Duffel.',
  },
  '/[org]/[mode]/wallet': {
    href: 'https://help.duffel.com/hc/en-gb/sections/360005362640-Balance',
    title: 'Balance',
    description:
      'Discover our simple prepaid account which allows you to pay airlines quickly and seamlessly.',
  },
}

type RelatedLink = keyof typeof relatedLinks

export interface SupportMenuProps {
  onOpenSupportForm: () => void
  onClose: () => void
  relatedLink?: RelatedLink
}

export const SupportMenu: React.FC<SupportMenuProps> = ({
  onOpenSupportForm,
  onClose,
  relatedLink,
}) => {
  const router = useRouter()
  const relatedLinkInfo = relatedLinks[relatedLink || router?.route]
  const [showResumeChat, setShowResumeChat] = React.useState(false)

  // Here we need to reference both the workspace and user context because their values come from different places.
  // Pages pre auth-v2 will use the workspace context, while pages post auth-v2 will use the user context.
  // This allows this component to work on both.
  const { setHideSupportButton, user: userFromWorkspace } = useWorkspace()
  const user = useUser()

  const orderId =
    typeof router.query.orderId === 'string' ? router.query.orderId : undefined
  // dashboard_show_order_support_options is only turned on for specific organisations for live chat
  const showOrderSupportOptions = useFeatureFlags(
    'dashboard_show_order_support_options'
  )

  React.useEffect(() => {
    const checkIfHasUnsolvedChat = async () => {
      if (orderId) {
        const hasUnsolvedChat = await hasUnsolvedChatAboutOrder(orderId)
        setShowResumeChat(hasUnsolvedChat)
      }
    }

    checkIfHasUnsolvedChat()
  }, [orderId])

  return (
    <VSpace
      space={24}
      className={styles['support-menu']}
      data-selector="fs-show"
    >
      <Text
        typeStyle="heading4"
        fontWeight="bold"
        asElement="h2"
        color="grey-700"
        className={classNames(styles['support-menu__title'], {
          [styles['support-menu__title--no-related-link']]: !relatedLinkInfo,
        })}
      >
        Help & support
      </Text>
      <VSpace space={24} className={styles['support-menu__contents']}>
        {relatedLinkInfo && (
          <Link href={relatedLinkInfo.href} passHref legacyBehavior>
            <Anchor className={styles['related-link__container']}>
              <VSpace space={8}>
                <Text
                  typeStyle="paragraph2"
                  color="blue-500"
                  className={styles['related-link']}
                >
                  Related Link
                  <Icon name="arrow_right" size={16} ml={'4px'} />
                </Text>
                <Text typeStyle="paragraph2" color="grey-900">
                  {relatedLinkInfo.title}
                </Text>
                <Text typeStyle="paragraph3" color="grey-600">
                  {relatedLinkInfo.description}
                </Text>
              </VSpace>
            </Anchor>
          </Link>
        )}
        <VSpace space={12}>
          {/* For now, only allow resuming live chat on individual order pages */}
          {showOrderSupportOptions && orderId && showResumeChat && (
            <ChromelessButton
              className={styles['support-menu__item']}
              onClick={() => {
                setHideSupportButton(true)
                trackEvent('dashboard_live_chat_resume_clicked', {
                  event_type: 'interaction',
                })
                loadChat({
                  orderId,
                  shouldOpen: true,
                  onClose: () => {
                    setHideSupportButton(false)
                    trackEvent('dashboard_live_chat_close_clicked', {
                      event_type: 'interaction',
                    })
                  },
                })

                onClose()
              }}
            >
              <Icon name="live_chat" color="purple-700" />
              <Text typeStyle="paragraph2" color="purple-700">
                Resume live chat
              </Text>
            </ChromelessButton>
          )}
          <Anchor
            href="https://duffel.com/docs/"
            className={styles['support-menu__item']}
            onClick={() =>
              trackEvent('dashboard_support_documentation_link_clicked', {
                event_type: 'interaction',
              })
            }
          >
            <Icon name="documentation" color="grey-700" />
            <Text typeStyle="paragraph2" color="grey-700">
              Documentation
            </Text>
          </Anchor>
          <Anchor
            href="https://help.duffel.com/hc/en-gb"
            className={styles['support-menu__item']}
            onClick={() =>
              trackEvent('dashboard_support_help_centre_link_clicked', {
                event_type: 'interaction',
              })
            }
          >
            <Icon name="help" color="grey-700" />
            <Text typeStyle="paragraph2" color="grey-700">
              Help centre
            </Text>
          </Anchor>
          <Anchor
            href="https://www.duffelstatus.com/"
            className={styles['support-menu__item']}
            onClick={() =>
              trackEvent('dashboard_support_api_status_link_clicked', {
                event_type: 'interaction',
              })
            }
          >
            <Icon name="status" color="grey-700" />
            <Text typeStyle="paragraph2" color="grey-700">
              API Status
            </Text>
          </Anchor>
          {userFromWorkspace || user ? (
            <ChromelessButton
              className={styles['support-menu__item']}
              onClick={() => {
                trackEvent('dashboard_support_contact_clicked', {
                  event_type: 'interaction',
                })
                onOpenSupportForm()
              }}
            >
              <Icon name="support" color="grey-700" />
              <Text typeStyle="paragraph2" color="grey-700">
                Contact support
              </Text>
            </ChromelessButton>
          ) : (
            <Anchor
              href="mailto:help@duffel.com"
              className={styles['support-menu__item']}
              onClick={() =>
                trackEvent('dashboard_support_contact_clicked', {
                  event_type: 'interaction',
                })
              }
            >
              <Icon name="support" color="grey-700" />
              <Text typeStyle="paragraph2" color="grey-700">
                Contact support
              </Text>
            </Anchor>
          )}
        </VSpace>
      </VSpace>
    </VSpace>
  )
}
