import { omit } from 'lodash'

type Countries = Record<string, { name: string }>

/* eslint-disable */
export const COUNTRIES: Countries = {
  AF: { name: 'Afghanistan' },
  AX: { name: 'Aland Islands' },
  AL: { name: 'Albania' },
  DZ: { name: 'Algeria' },
  AS: { name: 'American Samoa' },
  AD: { name: 'Andorra' },
  AO: { name: 'Angola' },
  AI: { name: 'Anguilla' },
  AQ: { name: 'Antarctica' },
  AG: { name: 'Antigua and Barbuda' },
  AR: { name: 'Argentina' },
  AM: { name: 'Armenia' },
  AW: { name: 'Aruba' },
  AU: { name: 'Australia' },
  AT: { name: 'Austria' },
  AZ: { name: 'Azerbaijan' },
  BS: { name: 'Bahamas' },
  BH: { name: 'Bahrain' },
  BD: { name: 'Bangladesh' },
  BB: { name: 'Barbados' },
  BY: { name: 'Belarus' },
  BE: { name: 'Belgium' },
  BZ: { name: 'Belize' },
  BJ: { name: 'Benin' },
  BM: { name: 'Bermuda' },
  BT: { name: 'Bhutan' },
  BO: { name: 'Bolivia' },
  BQ: { name: 'Bonaire, Sint Eustatius and Saba' },
  BA: { name: 'Bosnia and Herzegovina' },
  BW: { name: 'Botswana' },
  BV: { name: 'Bouvet Island' },
  BR: { name: 'Brazil' },
  IO: { name: 'British Indian Ocean Territory' },
  VG: { name: 'British Virgin Islands' },
  BN: { name: 'Brunei Darussalam' },
  BG: { name: 'Bulgaria' },
  BF: { name: 'Burkina Faso' },
  BI: { name: 'Burundi' },
  CV: { name: 'Cabo Verde' },
  KH: { name: 'Cambodia' },
  CM: { name: 'Cameroon' },
  CA: { name: 'Canada' },
  KY: { name: 'Cayman Islands' },
  CF: { name: 'Central African Republic' },
  TD: { name: 'Chad' },
  CL: { name: 'Chile' },
  CN: { name: 'China' },
  CX: { name: 'Christmas Island' },
  CC: { name: 'Cocos (Keeling) Islands' },
  CO: { name: 'Colombia' },
  KM: { name: 'Comoros' },
  CG: { name: 'Republic of the Congo' },
  CD: { name: 'Democratic Republic of the Congo' },
  CK: { name: 'Cook Islands' },
  CR: { name: 'Costa Rica' },
  CI: { name: "Côte d'Ivoire" },
  HR: { name: 'Croatia' },
  CU: { name: 'Cuba' },
  CW: { name: 'Curaçao' },
  CY: { name: 'Cyprus' },
  CZ: { name: 'Czechia' },
  DK: { name: 'Denmark' },
  DJ: { name: 'Djibouti' },
  DM: { name: 'Dominica' },
  DO: { name: 'Dominican Republic' },
  EC: { name: 'Ecuador' },
  EG: { name: 'Egypt' },
  SV: { name: 'El Salvador' },
  GQ: { name: 'Equatorial Guinea' },
  ER: { name: 'Eritrea' },
  EE: { name: 'Estonia' },
  SZ: { name: 'Eswatini' },
  ET: { name: 'Ethiopia' },
  FK: { name: 'Falkland Islands (Malvinas)' },
  FO: { name: 'Faroe Islands' },
  FJ: { name: 'Fiji' },
  FI: { name: 'Finland' },
  FR: { name: 'France' },
  GF: { name: 'French Guiana' },
  PF: { name: 'French Polynesia' },
  TF: { name: 'French Southern Territories' },
  GA: { name: 'Gabon' },
  GM: { name: 'Gambia' },
  GE: { name: 'Georgia' },
  DE: { name: 'Germany' },
  GH: { name: 'Ghana' },
  GI: { name: 'Gibraltar' },
  GR: { name: 'Greece' },
  GL: { name: 'Greenland' },
  GD: { name: 'Grenada' },
  GP: { name: 'Guadeloupe' },
  GU: { name: 'Guam' },
  GT: { name: 'Guatemala' },
  GG: { name: 'Guernsey' },
  GN: { name: 'Guinea' },
  GW: { name: 'Guinea-Bissau' },
  GY: { name: 'Guyana' },
  HT: { name: 'Haiti' },
  HM: { name: 'Heard and Mcdonald Islands' },
  VA: { name: 'Holy See (Vatican City State)' },
  HN: { name: 'Honduras' },
  HK: { name: 'Hong Kong, SAR China' },
  HU: { name: 'Hungary' },
  IS: { name: 'Iceland' },
  IN: { name: 'India' },
  ID: { name: 'Indonesia' },
  IR: { name: 'Iran' },
  IQ: { name: 'Iraq' },
  IE: { name: 'Ireland' },
  IM: { name: 'Isle of Man' },
  IL: { name: 'Israel' },
  IT: { name: 'Italy' },
  JM: { name: 'Jamaica' },
  JP: { name: 'Japan' },
  JE: { name: 'Jersey' },
  JO: { name: 'Jordan' },
  KZ: { name: 'Kazakhstan' },
  KE: { name: 'Kenya' },
  KI: { name: 'Kiribati' },
  KP: { name: 'North Korea' },
  KR: { name: 'South Korea' },
  KW: { name: 'Kuwait' },
  KG: { name: 'Kyrgyzstan' },
  LA: { name: 'Lao PDR' },
  LV: { name: 'Latvia' },
  LB: { name: 'Lebanon' },
  LS: { name: 'Lesotho' },
  LR: { name: 'Liberia' },
  LY: { name: 'Libya' },
  LI: { name: 'Liechtenstein' },
  LT: { name: 'Lithuania' },
  LU: { name: 'Luxembourg' },
  MO: { name: 'Macao, SAR China' },
  MG: { name: 'Madagascar' },
  MW: { name: 'Malawi' },
  MY: { name: 'Malaysia' },
  MV: { name: 'Maldives' },
  ML: { name: 'Mali' },
  MT: { name: 'Malta' },
  MH: { name: 'Marshall Islands' },
  MQ: { name: 'Martinique' },
  MR: { name: 'Mauritania' },
  MU: { name: 'Mauritius' },
  YT: { name: 'Mayotte' },
  MX: { name: 'Mexico' },
  FM: { name: 'Federated States of Micronesia' },
  MD: { name: 'Moldova' },
  MC: { name: 'Monaco' },
  MN: { name: 'Mongolia' },
  ME: { name: 'Montenegro' },
  MS: { name: 'Montserrat' },
  MA: { name: 'Morocco' },
  MZ: { name: 'Mozambique' },
  MM: { name: 'Myanmar' },
  NA: { name: 'Namibia' },
  NR: { name: 'Nauru' },
  NP: { name: 'Nepal' },
  NL: { name: 'Netherlands' },
  NC: { name: 'New Caledonia' },
  NZ: { name: 'New Zealand' },
  NI: { name: 'Nicaragua' },
  NE: { name: 'Niger' },
  NG: { name: 'Nigeria' },
  NU: { name: 'Niue' },
  NF: { name: 'Norfolk Island' },
  MP: { name: 'Northern Mariana Islands' },
  NO: { name: 'Norway' },
  OM: { name: 'Oman' },
  PK: { name: 'Pakistan' },
  PW: { name: 'Palau' },
  PS: { name: 'Palestinian Territory' },
  PA: { name: 'Panama' },
  PG: { name: 'Papua New Guinea' },
  PY: { name: 'Paraguay' },
  PE: { name: 'Peru' },
  PH: { name: 'Philippines' },
  PN: { name: 'Pitcairn' },
  PL: { name: 'Poland' },
  PT: { name: 'Portugal' },
  PR: { name: 'Puerto Rico' },
  QA: { name: 'Qatar' },
  MK: { name: 'Republic of Macedonia' },
  RE: { name: 'Réunion' },
  RO: { name: 'Romania' },
  RU: { name: 'The Russian Federation' },
  RW: { name: 'Rwanda' },
  BL: { name: 'Saint-Barthélemy' },
  SH: { name: 'Saint Helena' },
  KN: { name: 'Saint Kitts and Nevis' },
  LC: { name: 'Saint Lucia' },
  MF: { name: 'Saint-Martin (French part)' },
  PM: { name: 'Saint Pierre and Miquelon' },
  VC: { name: 'Saint Vincent and Grenadines' },
  WS: { name: 'Samoa' },
  SM: { name: 'San Marino' },
  ST: { name: 'Sao Tome and Principe' },
  SA: { name: 'Saudi Arabia' },
  SN: { name: 'Senegal' },
  RS: { name: 'Serbia' },
  SC: { name: 'Seychelles' },
  SL: { name: 'Sierra Leone' },
  SG: { name: 'Singapore' },
  SX: { name: 'Sint Maarten ' },
  SK: { name: 'Slovakia' },
  SI: { name: 'Slovenia' },
  SB: { name: 'Solomon Islands' },
  SO: { name: 'Somalia' },
  ZA: { name: 'South Africa' },
  GS: { name: 'South Georgia and the South Sandwich Islands' },
  SS: { name: 'South Sudan' },
  ES: { name: 'Spain' },
  LK: { name: 'Sri Lanka' },
  SD: { name: 'Sudan' },
  SR: { name: 'Suriname' },
  SJ: { name: 'Svalbard and Jan Mayen Islands' },
  SE: { name: 'Sweden' },
  CH: { name: 'Switzerland' },
  SY: { name: 'Syria' },
  TW: { name: 'Taiwan' },
  TJ: { name: 'Tajikistan' },
  TZ: { name: 'Tanzania' },
  TH: { name: 'Thailand' },
  TL: { name: 'Timor-Leste' },
  TG: { name: 'Togo' },
  TK: { name: 'Tokelau' },
  TO: { name: 'Tonga' },
  TT: { name: 'Trinidad and Tobago' },
  TN: { name: 'Tunisia' },
  TR: { name: 'Turkey' },
  TM: { name: 'Turkmenistan' },
  TC: { name: 'Turks and Caicos Islands' },
  TV: { name: 'Tuvalu' },
  UG: { name: 'Uganda' },
  UA: { name: 'Ukraine' },
  AE: { name: 'United Arab Emirates' },
  GB: { name: 'United Kingdom' },
  US: { name: 'United States of America' },
  UM: { name: 'US Minor Outlying Islands' },
  UY: { name: 'Uruguay' },
  UZ: { name: 'Uzbekistan' },
  VU: { name: 'Vanuatu' },
  VE: { name: 'Venezuela' },
  VN: { name: 'Viet Nam' },
  VI: { name: 'Virgin Islands, US' },
  WF: { name: 'Wallis and Futuna Islands' },
  EH: { name: 'Western Sahara' },
  YE: { name: 'Yemen' },
  ZM: { name: 'Zambia' },
  ZW: { name: 'Zimbabwe' },
} as const

// https://www.notion.so/duffel/Sanctioned-countries-05f7084ed65649a098f969a4d15f6583#5a622fb615d54a83ab52263ff5ff4df1
// Country codes from https://www.iban.com/country-codes
export const RESTRICTED_COUNTRY_CODES: CountryCode[] = [
  'CU',
  'UA',
  'IR',
  'KP',
  'SY',
  'RU',
  'VE',
  'BY',
]

// used omit from lodash to get correct typing out of the box
export const getApprovedCountries = () =>
  omit(COUNTRIES, RESTRICTED_COUNTRY_CODES)

export type CountryCode = keyof typeof COUNTRIES
export type Country = (typeof COUNTRIES)[CountryCode]

export const EURO_ZONE_COUNTRY_CODES: CountryCode[] = [
  'AT',
  'BE',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'SE',
  'SI',
  'SK',
  'BG',
  'RO',
]

export const SIGNUP_COUNTRIES = Object.keys(COUNTRIES)
  .filter(
    (key) =>
      EURO_ZONE_COUNTRY_CODES.includes(key) ||
      key === 'GB' ||
      key === 'US' ||
      key === 'CA' ||
      key === 'AU' ||
      key === 'MX'
  )
  .reduce((acc, key) => ((acc[key] = COUNTRIES[key]), acc), {})
