import classNames from 'classnames'
import { snakeCase } from 'lodash'
import * as React from 'react'
import { scroller } from 'react-scroll'
import { Menu } from '@components/Menu'
import { Input } from '@components/Input'
import { PopoverContainer } from '@components/PopoverContainer'
import { SelectTarget } from '@components/SelectTarget'
import { Text } from '@components/Text'
import { useDuffelPopper } from '@lib/hooks'
import { SourceId } from '@lib/sources'
import { DuffelProxy } from '@lib/types'
import { convertToSourceAirlineWithSourceId } from '../../lib/convert-to-source-airline-with-source-id'
import { AirlinesSelectOption } from './AirlinesSelectOption'
import { renderAirlinesSelectLabel } from './lib'
import styles from './AirlinesSelect.module.css'

const scrollOptions = {
  duration: 200,
  containerId: 'airlines-select-options-container',
  offset: -160,
}

export type SourceAirlineWithSourceId = DuffelProxy.Types.SourceAirline & {
  sourceId: SourceId
}

export type AirlinesSelectProps = {
  /** List of airlines to render in the select */
  airlinesList: SourceAirlineWithSourceId[]

  /** Value of the airlines select */
  value: DuffelProxy.Types.SourceAirline['iataCode'] | SourceId[]

  /** on change handler */
  onChange: (selectedAirline: SourceAirlineWithSourceId | null) => void

  /**
   * Used as:
   *   1. Placeholder value for the select
   *   2. Label for the "reset" option
   * - - -
   * Default: 'All airlines'
   */
  defaultStateText?: string

  /**
   * Should the select hide the placeholder/reset option that has the value of null
   * * - - -
   * Default: 'All airlines'
   */
  hideResetOption?: boolean

  /**
   * Is the select currently disabled?
   */
  disabled?: boolean
}

export const AirlinesSelect: React.FC<AirlinesSelectProps> = ({
  airlinesList,
  value,
  onChange,
  defaultStateText = 'All airlines',
  hideResetOption = false,
  disabled = false,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
  const [activeOptionIndex, setActiveOptionIndex] = React.useState(0)
  const [filterText, setFilterText] = React.useState('')
  const [filteredAirlinesList, setFilteredAirlinesList] = React.useState(
    airlinesList.sort((airlineA, airlineB) =>
      airlineA.name.localeCompare(airlineB.name)
    )
  )

  const onOpenPopover = () => {
    setIsPopoverOpen(true)
    setActiveOptionIndex(0)
  }
  const onClosePopover = () => setIsPopoverOpen(false)

  const { popper, setReferenceElement, setPopperElement } = useDuffelPopper(
    isPopoverOpen,
    onClosePopover,
    {
      placement: 'bottom-start',
      modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
    },
    { shouldInsideClickClose: false }
  )

  const selectedAirlines = convertToSourceAirlineWithSourceId(value)

  const changeFocusedOption = (index: number) => {
    let newActiveOptionIndex = index
    if (index < 0) {
      newActiveOptionIndex = filteredAirlinesList.length
    } else if (index > filteredAirlinesList.length) {
      newActiveOptionIndex = 0
    }
    setActiveOptionIndex(newActiveOptionIndex)
    scroller.scrollTo(
      `airlines-select-option-${newActiveOptionIndex}`,
      scrollOptions
    )
  }

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLButtonElement | HTMLInputElement>
  ) => {
    switch (event.key) {
      case 'ArrowDown': {
        event.preventDefault()
        changeFocusedOption(activeOptionIndex + 1)
        break
      }
      case 'ArrowUp': {
        event.preventDefault()
        changeFocusedOption(activeOptionIndex - 1)
        break
      }
      case 'Enter': {
        event.preventDefault()
        if (!isPopoverOpen) return onOpenPopover()

        onChange(
          activeOptionIndex === 0
            ? null
            : filteredAirlinesList[activeOptionIndex - 1]
        )
        break
      }
      case 'Tab':
        setIsPopoverOpen(false)
    }
  }

  const onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filterText = event.target.value
    setFilterText(filterText)

    if (!filterText) {
      setFilteredAirlinesList(airlinesList)
      return
    }
    setFilteredAirlinesList(
      airlinesList.filter((airline) =>
        shouldDisplayAirline(filterText, airline)
      )
    )
  }

  const shouldDisplayAirline = (
    filterText: string,
    airline: SourceAirlineWithSourceId
  ): boolean => {
    if (!filterText) {
      return true
    }
    const normalizedFilterText = filterText.trim().toLowerCase()
    if (airline.name.toLowerCase().includes(normalizedFilterText)) {
      return true
    }
    if (airline.iataCode.toLowerCase().includes(normalizedFilterText)) {
      return true
    }
    return false
  }

  const shouldDisplayAllAirlinesButton = (): boolean => {
    if (hideResetOption) {
      return false
    }
    if (
      filterText &&
      !defaultStateText.toLowerCase().includes(filterText.trim().toLowerCase())
    ) {
      return false
    }
    return true
  }

  return (
    <>
      <SelectTarget
        data-testid="airlines-select"
        type="button"
        className={classNames('airlines-select', { active: isPopoverOpen })}
        ref={setReferenceElement}
        disabled={disabled}
        onClick={onOpenPopover}
        onKeyDown={onKeyDown}
      >
        {renderAirlinesSelectLabel(selectedAirlines, defaultStateText)}
      </SelectTarget>
      {isPopoverOpen && (
        <PopoverContainer
          id="airlines-select-options-container"
          ref={setPopperElement}
          style={{
            minWidth: '240px',
            maxHeight: '350px',
            overflowY: 'scroll',
            ...popper.styles.popper,
          }}
          {...popper.attributes}
        >
          {
            <Menu>
              <div className={styles['airlines-select-filter']}>
                <Input
                  type="text"
                  value={filterText}
                  placeholder="Type to filter"
                  containerSize="small"
                  onKeyDown={onKeyDown}
                  onChange={onChangeFilter}
                  autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                />
              </div>

              {shouldDisplayAllAirlinesButton() && (
                <AirlinesSelectOption
                  name="airlines-select-option-0"
                  label={defaultStateText}
                  selected={selectedAirlines.length === 0}
                  active={activeOptionIndex === 0}
                  onClick={() => onChange(null)}
                  onMouseEnter={() => {
                    setActiveOptionIndex(0)
                  }}
                />
              )}

              {filteredAirlinesList.map((sourceAirline, index) => {
                const activeIndex = index + 1
                return (
                  <AirlinesSelectOption
                    name={`airlines-select-option-${activeIndex}`}
                    key={snakeCase(sourceAirline.name)}
                    airline={sourceAirline}
                    selected={
                      selectedAirlines &&
                      selectedAirlines.some(
                        (selectedAirline) =>
                          selectedAirline.iataCode === sourceAirline.iataCode
                      )
                    }
                    active={activeIndex === activeOptionIndex}
                    onClick={() => onChange(sourceAirline)}
                    onMouseEnter={() => {
                      setActiveOptionIndex(activeIndex)
                    }}
                  />
                )
              })}

              {filteredAirlinesList.length === 0 &&
                !shouldDisplayAllAirlinesButton() && (
                  <div className="u-marginLeft8">
                    <Text fontSize="C2" color="grey-700">
                      No airline matches '{filterText}'
                    </Text>
                  </div>
                )}
            </Menu>
          }
        </PopoverContainer>
      )}
    </>
  )
}
