import { DuffelAPI } from '@lib/types'
import { getPassengerType } from './get-passenger-type'

/**
 * Splits an array of passengers into separate arrays for each kind of passenger:
 * adult and child
 */
export const splitPassengersByKind = (
  passengersArray: DuffelAPI.Inputs.OfferRequestPassenger[]
) => {
  const adults = passengersArray.filter(
    (passenger) => getPassengerType(passenger) === 'adult'
  )

  const children = passengersArray.filter(
    (passenger) => getPassengerType(passenger) === 'child'
  )

  return [adults, children] as [
    DuffelAPI.Inputs.OfferRequestAdultPassenger[],
    DuffelAPI.Inputs.OfferRequestChildPassenger[]
  ]
}
