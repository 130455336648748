export const invoiceItemIndicatorColorMap = {
  'Paid orders': 'purple-800',
  'Excess searches': 'purple-600',
  'Paid ancillaries': 'purple-400',
  'Managed content': 'purple-100',
}

export const COST_INDICATOR_TOOLTIP_TEXT =
  'This is an estimate of your accumulated cost. This may differ from the final invoice as it does not take into account all offline servicing fees.'

export const CHART_STAMP_TOOLTIP_TEXT_FOR_FULL_MONTH =
  'Compared to the last billing period'

export const CHART_STAMP_TOOLTIP_TEXT_FOR_PARTIAL_MONTH = (
  <span>
    Compared to the same day <br />
    in the last billing period
  </span>
)

export const COMMAS_EVERY_THIRD_POSITION_REGEX = /\B(?=(\d{3})+(?!\d))/g
