import { CreateOrderService } from '@duffel/api/types'
import { DuffelAPI } from '@lib/types'

export const transformAdditionalBaggageToServicePayload = (
  additionalBaggages: CreateOrderService[],
  availableServices: DuffelAPI.Types.OrderAvailableService[] | null,
  passengers: DuffelAPI.Types.OrderPassenger[]
) => {
  // Need to map requested availableService IDs in baggage to original availableService object
  // from availableService object, get baggage type from metadata
  let totalAmount = 0

  const items = additionalBaggages
    .map((additionalBaggage) => {
      const baggageService = availableServices?.find(
        (availableService) => availableService.id === additionalBaggage.id
      )
      const baggageType =
        baggageService?.metadata.type === 'carry_on'
          ? 'carry on bag'
          : 'checked bag'
      const baggageTypeAndQuantity =
        additionalBaggage.quantity !== 1
          ? `${additionalBaggage.quantity} ${baggageType}s`
          : `${additionalBaggage.quantity} ${baggageType}`
      const baggageWeight =
        baggageService?.metadata.maximumWeightKg &&
        `up to ${baggageService?.metadata.maximumWeightKg}kg`
      const passenger = passengers.find((passenger) =>
        baggageService?.passengerIds.includes(passenger.id)
      )
      const forPassengerDescription =
        passenger?.givenName && passenger?.familyName
          ? `for ${passenger?.givenName} ${passenger?.familyName}`
          : ''
      const description = baggageWeight
        ? `${baggageTypeAndQuantity} ${baggageWeight} ${forPassengerDescription}`
        : `${baggageTypeAndQuantity} ${forPassengerDescription}`
      const price =
        (baggageService?.totalAmount &&
          additionalBaggage.quantity *
            parseFloat(baggageService?.totalAmount)) ||
        0

      totalAmount = price && totalAmount + price

      return {
        description,
        price,
      }
    })
    .filter((item) => item.price !== 0)

  const totalCurrency =
    availableServices &&
    availableServices[0] &&
    availableServices[0].totalCurrency

  return totalAmount
    ? {
        items,
        totalCurrency,
        totalAmount: totalAmount.toFixed(2),
      }
    : null
}
