import classnames from 'classnames'
import * as React from 'react'
import { PropsWithoutRef } from 'react'
import { Space } from '@components/legacy-design-system/product/styles'
import {
  SpaceProps,
  spaceClassNamesFromProps,
} from '@lib/styles/utils/spacing-props'

// Eventually, we probably should replace spaceBetween props with a more flexible justify prop as we
// are doing justify center way more often, and there's a merit of having both. For now to avoid excessive refactor,
// we will keep it side-by-side and ensure that they are never used together.
type JustifyProps =
  | {
      /**
       * Determine the way the content should align horizontally in the container.
       * Use "center" for centering the content horizontally, and use "space-between" to spread them from one end to another.
       */
      justify?: 'space-between' | 'center' | 'end'
      spaceBetween?: never
    }
  | {
      /**
       * If you'd like all children inside your horizontal space container
       * to be vertically aligned to the center of the div, you can set `alignCenter` to `true`
       *
       * Default: `false`
       */
      spaceBetween?: boolean
      justify?: never
    }
interface BaseHSpaceProps
  extends PropsWithoutRef<JSX.IntrinsicElements['div']> {
  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string

  /**
   * The horizontal spacing between each child element
   */
  space: Space | 0

  /**
   * If you'd like all children inside your horizontal space container
   * to be vertically aligned to the center of the div, you can set `alignCenter` to `true`
   *
   * Default: `false`
   */
  alignCenter?: boolean

  /**
   *
   */
  fill?: boolean

  /**
   * Allows you to specify this component's HTML element
   *
   * - - -
   *
   * Default: `div`
   */
  asElement?: string

  /**
   * Allows specifying the type for inputs
   */
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type']
}

export type HSpaceProps = BaseHSpaceProps & JustifyProps & SpaceProps

export const HSpace = React.forwardRef<HTMLDivElement, HSpaceProps>(
  (
    {
      className,
      space,
      alignCenter,
      spaceBetween,
      justify,
      children,
      fill = false,
      asElement = 'div',
      ...props
    },
    ref
  ) => {
    const { classNames: spaceClassNames, otherProps } =
      spaceClassNamesFromProps(props)
    const cx = classnames(
      'hspace',
      alignCenter && 'hspace--align-center',
      spaceBetween && 'hspace--space-between',
      justify === 'center' && 'hspace--justify-center',
      justify === 'space-between' && 'hspace--justify-space-between',
      justify === 'end' && 'hspace--justify-end',
      fill && 'hspace--fill',
      className,
      spaceClassNames
    )
    const spacingMarginValue = space === 0 ? '0' : `var(--space-${space})`
    const Element = asElement as any

    return (
      <Element className={cx} {...otherProps} ref={ref}>
        {children}

        <style jsx>{`
          .hspace {
            display: flex;
          }

          .hspace--align-center {
            align-items: center;
          }

          .hspace--space-between,
          .hspace--justify-space-between {
            justify-content: space-between;
          }

          .hspace--justify-center {
            justify-content: center;
          }

          .hspace--justify-end {
            justify-content: flex-end;
          }

          .hspace--fill {
            width: 100%;
          }

          .hspace > :global(* + *) {
            margin-left: ${spacingMarginValue} !important;
          }
        `}</style>
      </Element>
    )
  }
)
