import * as React from 'react'
import { Icon } from '@components/Icon'
import { Heading } from '@components/Heading'
import { DuffelAPI, SearchType } from '@lib/types'

interface OfferRequestTitleProps {
  searchType: SearchType
  offerRequest: DuffelAPI.Types.OfferRequest
}

export const OfferRequestTitle: React.FC<OfferRequestTitleProps> = ({
  searchType,
  offerRequest,
}) => (
  <>
    <Heading asElement="h1" className="offer-request-title">
      {offerRequest.slices.length > 0 && offerRequest.slices[0].origin.iataCode}
      <div className="offer-request-title--icon">
        <Icon name={searchType === 'return' ? 'sync' : 'arrow_right'} />
      </div>
      {offerRequest.slices.length > 0 &&
        offerRequest.slices[0].destination.iataCode}
      {searchType === 'multi_city' && (
        <>
          {offerRequest.slices.slice(1).map((slice, index) => (
            <React.Fragment key={`slice-title-${index}`}>
              , {slice.origin.iataCode}
              <div className="offer-request-title--icon">
                <Icon name="arrow_right" />
              </div>
              {slice.destination.iataCode}
            </React.Fragment>
          ))}
        </>
      )}
    </Heading>
    <style jsx>{`
      :global(.heading.offer-request-title) {
        display: flex;
        align-items: center;
        font-size: 24px;
        line-height: var(--space-32);
        margin-top: var(--space-16);
        margin-bottom: 0;
        font-weight: 600;
        color: var(--grey-900);
      }

      .offer-request-title--icon {
        color: var(--grey-400);
        margin: 0 var(--space-8);
      }
    `}</style>
  </>
)
