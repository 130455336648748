import { prepareObjectForCSS } from './prepare-object-for-css'

/* eslint-disable */
const SANS_SERIF = [
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Helvetica',
  'Arial',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
]

const MONOSPACE = [
  'SFMono-Regular',
  'Consolas',
  'Liberation Mono',
  'Menlo',
  'Courier',
  'monospace',
]
/* eslint-enable */

function formatFontName(name: string): string {
  return name.includes(' ') ? `"${name}"` : name
}

function buildFontStack(fonts: string[]): string {
  return fonts.map((name) => formatFontName(name)).join(', ')
}

export const FONTS = {
  SANS: buildFontStack(['GT America', ...SANS_SERIF]),
  MONO: buildFontStack(['GT America Mono', ...MONOSPACE]),
}

export const CSS_FONTS_VARIABLES = prepareObjectForCSS(FONTS, 'FONTS').join(
  '; '
)

export const FONT_SIZES = {
  D1: '72px',
  D2: '60px',
  D3: '48px',
  H1: '40px',
  H2: '30px',
  H3: '24px',
  H4: '20px',
  H5: '18px',
  C1: '16px',
  C2: '14px',
  C3: '12px',
  C4: '10px',
}

export const CSS_FONT_SIZES_VARIABLES = prepareObjectForCSS(
  FONT_SIZES,
  'FONT-SIZES'
).join('; ')

export type FontSize = keyof typeof FONT_SIZES
