import { DuffelAPI } from '@lib/types'

// Additional baggage can be added to an order if:
// * Additional baggage is available as a service
// * Original order doesn't include baggage
export const isAdditionalBaggageAvailable = (
  order: DuffelAPI.Types.Order,
  availableServices?: DuffelAPI.Types.OrderAvailableService[] | null
) => {
  const additionalBaggageService = Boolean(
    availableServices?.find((service) => service.type === 'baggage')
  )
  const originalOrderHasBaggage = order.slices.every((slice) =>
    slice.segments.every((segment) =>
      segment.passengers.every((passenger) => passenger.baggages.length > 0)
    )
  )

  return additionalBaggageService && !originalOrderHasBaggage
}
