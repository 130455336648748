import { ErrorViewProps } from '@components/ErrorView'
import { PostSearchFilters } from '@lib/paths'
import { DuffelAPI } from '@lib/types/DuffelAPI'

export type OfferWithRequestId = DuffelAPI.Types.Offer & {
  partialOfferRequestId: string
}

export type SearchResultsState =
  | { state: 'loading'; loadingMessage: string }
  // this loading state is specific to when loading search results where
  // we want to retain other relevant layout such as the sidebar
  | {
      state: 'loading-results'
      postSearchFilters?: PostSearchFilters
      loadingMessage: string
    }
  | { state: 'error'; error: ErrorViewProps }
  | {
      state: 'results'
      partialOfferRequest: DuffelAPI.Types.OfferRequest & {
        offers?: OfferWithRequestId[]
      }
      postSearchFilters?: PostSearchFilters
      // this is only relevant for parallelised search, a hidden feature
      // only intended to be used by a demo account
      sourceRequestsProgress?: {
        finished: number
        total: number
      }
    }
  | { state: 'fares'; partialOfferRequest: DuffelAPI.Types.OfferRequest }

export const getParallelisedSearchState = (
  state: SearchResultsState
): 'inactive' | 'disabled' | 'loading' | 'finished' => {
  if (state.state !== 'results') {
    return 'inactive'
  }

  if (!state.sourceRequestsProgress) {
    return 'disabled'
  }

  const { finished: numFinishedSources, total: totalSources } =
    state.sourceRequestsProgress
  return numFinishedSources < totalSources ? 'loading' : 'finished'
}
