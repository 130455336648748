import { isEqual } from 'lodash/fp'

/**
 * This function compares two objects and returns an array of fields that are different between the two
 *
 * @param beforeObject
 * @param afterObject
 * @returns string[]
 */
export const getDiffsInBeforeAndAfterObjects = (
  beforeObject: Record<string, any>,
  afterObject: Record<string, any>
): string[] =>
  Object.keys(beforeObject).reduce((acc: string[], key) => {
    return isEqual(beforeObject[key], afterObject[key]) ? acc : acc.concat(key)
  }, [])
