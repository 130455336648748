import * as React from 'react'
import { Icon } from '@components/Icon'
import { DuffelAPI } from '@lib/types'

import styles from './OrderChangeResultsHeaderTitle.module.css'
interface OrderChangeResultsHeaderTitleProps {
  order: DuffelAPI.Types.Order
}

export const OrderChangeResultsHeaderTitle: React.FC<
  OrderChangeResultsHeaderTitleProps
> = ({ order }) => {
  const isReturnFlight =
    order.slices.length === 2 &&
    order.slices[0].origin.iataCode === order.slices[1].destination.iataCode &&
    order.slices[0].destination.iataCode === order.slices[1].origin.iataCode

  return (
    <>
      <div className={styles['offer-request-title']}>
        {order.slices.length > 0 && order.slices[0].origin.iataCode}
        <div className={styles['offer-request-title--icon']}>
          <Icon name={isReturnFlight ? 'sync' : 'arrow_right'} />
        </div>
        {order.slices.length > 0 && order.slices[0].destination.iataCode}
        {order.slices.length > 2 && (
          <>
            {order.slices.slice(1).map((slice, index) => (
              <React.Fragment key={`slice-title-${index}`}>
                , {slice.origin.iataCode}
                <div className={styles['offer-request-title--icon']}>
                  <Icon name="arrow_right" />
                </div>
                {slice.destination.iataCode}
              </React.Fragment>
            ))}
          </>
        )}
      </div>
    </>
  )
}
