import * as React from 'react'
import { UNKNOWN_ERROR_MESSAGE } from '@lib/constants'
import { APIResponseError, DuffelAPI } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import { OrderSupport } from '../components/OrderSupport/OrderSupport'
import { ErrorSupportOrderManagementAction } from '../components/OrderSupport/types'
import {
  ErrorUserAction,
  getUserFriendlyAPIError,
} from './get-user-friendly-api-error'
import { timeout } from './timeout'

interface OrderErrorMetadata {
  actionToSupport: ErrorSupportOrderManagementAction
  requestId: string
}

/**
 * This hook takes an optional callback and returns the function that, upon being called with a list of errors, will execute
 * the action appropriate to the errors being passed in. The function will prioritize the actions in this order.
 * - open a support ticket
 * - show the toast saying that the user needs to retry the request
 * - show the toast that no further action is needed
 */
export const useUserFriendlyErrors = (
  onErrorHandled?: (action: ErrorUserAction) => void
) => {
  const { addToast, openDialog, closeDialog, openAssistantChat } =
    useWorkspace()

  return {
    handleOrderErrors: async (
      errors: APIResponseError[],
      order: DuffelAPI.Types.Order,
      metadata: OrderErrorMetadata
    ) => {
      // Sometimes the API responds instantaneously which causes the UI to 'jump' when it updates.
      // This artificial .8s delay was added to demonstrate an operation was attempted.
      await timeout(800)

      // we'll treat the empty array scenario as an unknown error situation,
      // which we want to open a support ticket for.
      if (errors.length === 0) {
        errors.push({
          title: '',
          code: 'unknown',
          message: UNKNOWN_ERROR_MESSAGE,
        })
      }

      const userFriendlyErrors = getUserFriendlyAPIError(errors)

      const openSupportTicketError = userFriendlyErrors.find(
        (error) => error.action === 'open-support-ticket'
      )
      if (openSupportTicketError) {
        openDialog({
          customRenderer: () => (
            <div className="u-padding24">
              <OrderSupport
                supportType="error"
                openAssistantChat={openAssistantChat}
                error={openSupportTicketError}
                order={order}
                {...metadata}
                onClose={closeDialog}
                onSubmitSuccess={() => {
                  addToast({
                    title: 'Request submitted',
                    message:
                      'Thanks for sending the request. We will get in touch soon.',
                    intent: 'info',
                  })
                  closeDialog()
                }}
              />
            </div>
          ),
          customActions: 'no-actions',
          width: 524,
        })
        onErrorHandled?.(openSupportTicketError.action)
        return
      }

      const retryableError = userFriendlyErrors.find(
        (error) => error.action === 'retry-request'
      )
      if (retryableError) {
        addToast({
          intent: 'warning',
          message: retryableError.message,
          closeAfterTimeout: true,
        })
        onErrorHandled?.(retryableError.action)
        return
      }

      const noActionError = userFriendlyErrors.find(
        (error) => error.action === 'no-user-action-required'
      )
      if (noActionError) {
        addToast({
          intent: 'info',
          message: noActionError.message,
          closeAfterTimeout: true,
        })
        onErrorHandled?.(noActionError.action)
        return
      }
    },
  }
}
