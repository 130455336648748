import * as React from 'react'
import { FlightSummary } from '@components/FlightSummary'
import { FlightSummaryWithAICs } from '@components/FlightSummary/FlightSummaryWithAICs'
import { Spinner } from '@components/Spinner'
import { VSpace } from '@components/VSpace'
import { AirlineCredit } from '@components/AirlineCredit'
import { DuffelPermissions, DuffelProxy } from '@lib/types'
import { SimulateAirlineInitiatedChangeNotice } from '../components'
import { OrderHeadingBlock } from '../components/OrderHeadingBlock'
import { OrderOnHoldInformation } from '../components/OrderOnHoldInformation'
import { OrderPassengersSummary } from '../components/OrderPassengersSummary'
import { OrderStatus } from '../helpers'
import styles from './OrderContainer.module.css'
import { OrderInvoiceList } from './OrderInvoiceList'
import CFARSummary from '../components/CFARSummary/CFARSummary'
import { hasCFARService } from '../components/CFARSummary/lib/hasCFARService'
import { KeyValueItem } from '@components/Item/KeyValueItem'
import { Text } from '@components/legacy-design-system/product/components/Text'
import { CopyableValue } from '@components/CopyableValue'
import { DashboardOrder } from '../lib/types'

export interface OrderContainerProps {
  order: DashboardOrder
  orderStatus?: OrderStatus
  componentClientKey?: string
  showAgencyInformation: boolean
  authProps: {
    user: DuffelProxy.Types.Self
    permissions: DuffelPermissions
    mode: 'live' | 'test'
    organisation: DuffelProxy.Types.SelfOrganisation
  }
  isOrderManagementEnabled?: boolean
}

export const OrderContainer: React.FC<OrderContainerProps> = ({
  order,
  orderStatus,
  componentClientKey,
  authProps: { user },
  showAgencyInformation,
  isOrderManagementEnabled,
}) => {
  const showSimulateAICNotice =
    user?.duffelAdmin &&
    order?.liveMode === false &&
    order.slices.length === 1 &&
    order.slices[0].origin.iataCode === 'LHR' &&
    order.slices[0].destination.iataCode === 'LTN' &&
    isOrderManagementEnabled

  return order && orderStatus ? (
    <VSpace space={40}>
      {(showSimulateAICNotice || orderStatus === 'needs-review') &&
        showSimulateAICNotice && (
          <SimulateAirlineInitiatedChangeNotice orderId={order.id} />
        )}

      {showAgencyInformation && (
        <div className={styles['agency-info-container']}>
          <KeyValueItem label="Source">
            <Text typeStyle="paragraph2">{order.agreement.source.name}</Text>
          </KeyValueItem>
          <KeyValueItem label="Booking Ref">
            <CopyableValue valueToCopy={order.bookingReference}>
              <Text typeStyle="paragraph2">{order.bookingReference}</Text>
            </CopyableValue>
          </KeyValueItem>
          <KeyValueItem label="IATA">
            <CopyableValue valueToCopy={order.agreement.agency.iataNumber}>
              <Text typeStyle="paragraph2">
                {order.agreement.agency.iataNumber}
              </Text>
            </CopyableValue>
          </KeyValueItem>
          <KeyValueItem label="Agreement">
            {order.agreement.pseudoCityCode ? (
              <CopyableValue valueToCopy={order.agreement.pseudoCityCode}>
                <Text typeStyle="paragraph2">
                  {order.agreement.pseudoCityCode}
                </Text>
              </CopyableValue>
            ) : (
              <Text typeStyle="paragraph2">Empty</Text>
            )}
          </KeyValueItem>
          <KeyValueItem label="Order ID">
            <CopyableValue valueToCopy={order.id}>
              <Text typeStyle="paragraph2">{order.id}</Text>
            </CopyableValue>
          </KeyValueItem>
        </div>
      )}

      {orderStatus === 'on-hold' && (
        <OrderOnHoldInformation
          order={order}
          componentClientKey={componentClientKey}
          isOrderManagementEnabled={isOrderManagementEnabled}
        />
      )}

      {orderStatus === 'cancelled' && <AirlineCredit order={order} />}

      <OrderHeadingBlock title="Journey details">
        {orderStatus === 'needs-review' || orderStatus === 'pending' ? (
          <FlightSummaryWithAICs
            order={order}
            isOrderManagementEnabled={isOrderManagementEnabled}
          />
        ) : (
          <FlightSummary flight={order} isExpanded />
        )}
      </OrderHeadingBlock>

      <hr className={styles['divider']} />

      <OrderHeadingBlock title="Passengers">
        <OrderPassengersSummary order={order} />
      </OrderHeadingBlock>

      {hasCFARService(order) && (
        <>
          <hr className={styles['divider']} />
          <OrderHeadingBlock title="Cancel For Any Reason">
            <CFARSummary order={order} />
          </OrderHeadingBlock>
        </>
      )}

      {!order.paymentStatus.awaitingPayment && (
        <>
          <hr className={styles['divider']} />
          <OrderHeadingBlock title="Billing summary">
            <OrderInvoiceList order={order} />
          </OrderHeadingBlock>
        </>
      )}
    </VSpace>
  ) : (
    <div className={styles['spinner-wrapper']}>
      <Spinner />
    </div>
  )
}
