import * as React from 'react'
import { captureException } from '@lib/sentry'

interface AirlineLogoProps {
  name: string
  iataCode: string | null
  size?: number
}

const hasManualOverride = ['TA']
const getFallbackLogoUrl = (iataCode) =>
  `https://www.gstatic.com/flights/airline_logos/70px/${iataCode}.png`

const getSrcForIataCode = (iataCode: string | null, airlineName: string) => {
  if (!iataCode) {
    captureException(
      new Error(`iataCode is passed as "null" value for airline ${airlineName}`)
    )
    return getFallbackLogoUrl('ZZ')
  }

  if (hasManualOverride.includes(iataCode)) {
    return getFallbackLogoUrl(iataCode)
  }

  return `https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/${iataCode}.svg`
}

export const AirlineLogo: React.FC<AirlineLogoProps> = ({
  name,
  iataCode,
  size = 32,
}) => {
  const [src, setSrc] = React.useState(getSrcForIataCode(iataCode, name))
  React.useEffect(() => {
    setSrc(getSrcForIataCode(iataCode, name))
  }, [iataCode])

  return (
    <React.Fragment>
      {/* disabling eslint here because using next/legacy/image made the svg disappear on the airline show page */}
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        alt={name}
        title={name}
        height={size}
        width={size}
        src={src}
        onError={() => setSrc(getFallbackLogoUrl(iataCode))}
        className="u-skeletonable"
      />
      <style jsx>{`
        img {
          display: block;
          filter: grayscale(0);
        }
      `}</style>
    </React.Fragment>
  )
}
