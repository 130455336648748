import { SourceAirlineWithSourceId } from '../AirlinesSelect'

export const renderAirlinesSelectLabel = (
  selectedAirlines: SourceAirlineWithSourceId[],
  /** What the select should display if no airlines have been selected */
  defaultLabel = 'All airlines'
) => {
  if (!selectedAirlines || selectedAirlines.length === 0) return defaultLabel

  if (selectedAirlines.length === 1) return selectedAirlines[0].name

  if (selectedAirlines.length === 2)
    return `${selectedAirlines[0].name}, ${selectedAirlines[1].name}`

  return `${selectedAirlines.length} airlines`
}
