import cookie from 'cookie'
import {
  PAYLOAD_COOKIE_NAME,
  SIGNATURE_COOKIE_NAME,
} from '@lib/cookies/constants'
import { JwtPayload } from '@lib/types'
import { JsonPayloadForLogging } from './types'

type Empty = Record<string, unknown>
type AuthProps = Pick<JsonPayloadForLogging, 'userId' | 'organisationId'>

const getPayloadFromJWT = (jwt: string): JwtPayload | null => {
  try {
    const [_header, payload] = jwt.split('.')
    return JSON.parse(Buffer.from(payload, 'base64').toString())
  } catch (err) {
    return null
  }
}

export const maybeGetAuthProps = (
  fromCookieHeader: string | null
): AuthProps | Empty => {
  const parsed = cookie.parse(fromCookieHeader || '', {})

  const payloadCookie = parsed[PAYLOAD_COOKIE_NAME]
  const signatureCookie = parsed[SIGNATURE_COOKIE_NAME]

  if (payloadCookie !== undefined && signatureCookie !== undefined) {
    const payloadData = getPayloadFromJWT(payloadCookie)
    if (payloadData) {
      const { userId, organisationId } = payloadData
      return {
        userId,
        organisationId,
      }
    }
  }

  return {}
}
