import * as React from 'react'
import { Stamp } from '@components/Stamp'
import { VSpace } from '@components/VSpace'
import { TravelDetails } from '@lib/types'
import {
  getDiffsInBeforeAndAfterObjects,
  getLayoverOriginDestinationKey,
} from '../lib'
import { ChangeItem } from './ChangeItem'
import { ChangeLayover } from './ChangeLayover'
import styles from './ChangeSummary.module.css'

export const CHANGES_DETECTED_TEXT = 'Changes detected'

export interface ChangedProperty {
  originDestination: string
  matchedBeforeSegment?: TravelDetails<'diff'>
  matchedAfterSegment?: TravelDetails<'diff'>
}

export type ChangedProperties = ChangedProperty[]

export interface LayoverDiff {
  layoverTimeDifference: string | undefined
  isAddedLayover?: boolean
  airport: string | null | undefined
  duration: string | undefined
  originDestinationKey: string | undefined
}

export type LayoverDiffs = LayoverDiff[]

export interface ChangeSummaryProps {
  beforeSegments: TravelDetails<'diff'>[]
  afterSegments: TravelDetails<'diff'>[]
  segmentsWithADiff: string[]
  changedProperties: ChangedProperties
  layoverDiffs: LayoverDiffs
}

export const ChangeSummary: React.FC<ChangeSummaryProps> = ({
  beforeSegments,
  afterSegments,
  segmentsWithADiff,
  changedProperties,
  layoverDiffs,
}) => {
  const changedPropertiesDiffed = changedProperties.map(
    ({ originDestination, matchedBeforeSegment, matchedAfterSegment }) => {
      const diffs = getDiffsInBeforeAndAfterObjects(
        matchedBeforeSegment || {},
        matchedAfterSegment || {}
      )

      return {
        originDestination: originDestination,
        beforeSegment: matchedBeforeSegment,
        afterSegment: matchedAfterSegment,
        diffs,
      }
    }
  )

  // TODO: account for stops once order segments contain stops information
  const nowDirect =
    afterSegments.length === 1 &&
    beforeSegments.length > 1 &&
    afterSegments[0].origin === beforeSegments[0].origin &&
    afterSegments[0].destination ===
      beforeSegments[beforeSegments.length - 1].destination
  const noLongerDirect =
    beforeSegments.length === 1 &&
    afterSegments.length > 1 &&
    afterSegments[0].origin === beforeSegments[0].origin &&
    afterSegments[afterSegments.length - 1].destination ===
      beforeSegments[0].destination

  return (
    <VSpace space={8} data-selector="fs-show">
      <Stamp
        label={CHANGES_DETECTED_TEXT}
        className={styles['change-summary-title']}
        border
      />

      {/* Handle special case where flight is now/no longer a direct flight */}
      {noLongerDirect || nowDirect ? (
        <>
          {noLongerDirect && (
            <ChangeItem
              beforeSegment={beforeSegments[0]}
              changedProperty={'direct'}
            />
          )}
          {nowDirect && (
            <ChangeItem
              afterSegment={afterSegments[0]}
              changedProperty={'direct'}
            />
          )}
        </>
      ) : (
        beforeSegments.map((beforeSegment, index) => {
          // Handle case where flight(s) in original itinerary have been removed
          if (
            !afterSegments.find(
              (afterSegment) =>
                afterSegment.originDestination ===
                beforeSegment.originDestination
            )
          ) {
            return (
              <VSpace
                space={8}
                key={`${beforeSegment.originDestination}-${index}`}
              >
                <ChangeItem
                  key={`${beforeSegment.originDestination}-${index}`}
                  beforeSegment={beforeSegment}
                />
              </VSpace>
            )
          }
        })
      )}

      {afterSegments.map((afterSegment, index) => {
        // Handle case where flight(s) in adjusted itinerary are completely new, e.g. not diffable
        if (
          !beforeSegments.find(
            (beforeSegment) =>
              beforeSegment.originDestination === afterSegment.originDestination
          )
        ) {
          const matchedLayover = layoverDiffs.find(
            (layover) =>
              index < afterSegments.length - 1 &&
              layover.originDestinationKey ===
                getLayoverOriginDestinationKey(
                  afterSegment.origin,
                  afterSegment.destination,
                  afterSegments[index + 1].destination
                )
          )

          return (
            <VSpace
              space={8}
              key={`${afterSegment.originDestination}-${index}`}
            >
              {!noLongerDirect && (
                <ChangeItem
                  key={`${afterSegment.originDestination}-${index}`}
                  afterSegment={afterSegment}
                />
              )}
              {matchedLayover && (
                <ChangeLayover
                  key={`${matchedLayover.airport}`}
                  {...matchedLayover}
                />
              )}
            </VSpace>
          )
        } else if (segmentsWithADiff.includes(afterSegment.originDestination)) {
          // If changes excluding those that have to be handled together, for example:
          // arrivingAt/departingAt or operatingCarrier/operatingCarrierIataCode
          // equal more than 1 then group together into one change item
          let diffCountExcludingGroupedChanges

          const groupChanges = changedPropertiesDiffed.map(({ diffs }) => {
            diffCountExcludingGroupedChanges = diffs.length
            if (diffs.includes('arrivingAt' || 'departingAt')) {
              diffCountExcludingGroupedChanges =
                diffCountExcludingGroupedChanges - 1
            }
            if (diffs.includes('operatingCarrier')) {
              diffCountExcludingGroupedChanges =
                diffCountExcludingGroupedChanges - 1
            }
            if (diffs.includes('flightDuration')) {
              diffCountExcludingGroupedChanges =
                diffCountExcludingGroupedChanges - 1
            }

            return {
              hasGroupedChanges: diffCountExcludingGroupedChanges > 1,
              origin: afterSegment.origin,
              destination: afterSegment.destination,
            }
          })[0]

          if (groupChanges.hasGroupedChanges) {
            return (
              <ChangeItem
                hasGroupedChanges
                origin={groupChanges.origin}
                destination={groupChanges.destination}
                key={index}
              />
            )
          } else {
            const matchedLayover = layoverDiffs.find(
              (layover) =>
                index < afterSegments.length - 1 &&
                layover.originDestinationKey ===
                  getLayoverOriginDestinationKey(
                    afterSegment.origin,
                    afterSegment.destination,
                    afterSegments[index + 1].destination
                  )
            )
            return (
              <VSpace
                space={8}
                key={`${afterSegment.originDestination}-${index}`}
              >
                {changedPropertiesDiffed.map(
                  ({
                    diffs,
                    beforeSegment: beforeSegmentDiff,
                    afterSegment: afterSegmentDiff,
                  }) => {
                    if (
                      beforeSegmentDiff?.originDestination ===
                      afterSegment.originDestination
                    ) {
                      return diffs.map((diff, index) => (
                        <div key={`${diff}-${index}`}>
                          <ChangeItem
                            diffs={diffs}
                            beforeSegment={beforeSegmentDiff}
                            afterSegment={afterSegmentDiff}
                            changedProperty={diff}
                          />
                        </div>
                      ))
                    }
                  }
                )}
                {matchedLayover && (
                  <ChangeLayover
                    key={`${matchedLayover.airport}`}
                    {...matchedLayover}
                  />
                )}
              </VSpace>
            )
          }
        }
      })}
    </VSpace>
  )
}
