import classNames from 'classnames'
import * as React from 'react'
import { Icon } from '@components/Icon'
import { Tooltip } from '@components/Tooltip'
import { Stamp } from '@components/Stamp'

export interface FormFieldProps {
  label?: string
  error?: string
  hint?: React.ReactNode
  htmlFor?: string
  optionalField?: boolean
  secondaryLabel?: string | React.ReactNode
  className?: string
  disabled?: boolean
  tooltipText?: string
  showInfoTooltip?: boolean
  infoTooltipText?: string
  beta?: boolean
}

export const FormField: React.FC<React.PropsWithChildren<FormFieldProps>> = ({
  label,
  error,
  hint,
  htmlFor,
  children,
  optionalField,
  secondaryLabel,
  className,
  disabled,
  tooltipText,
  showInfoTooltip,
  infoTooltipText,
  beta,
}) => (
  <Tooltip
    text={tooltipText}
    placement="bottom"
    className="ff-form-field__tooltip-wrapper"
  >
    <div
      className={classNames(
        'ff-form-field',
        { 'ff-form-field--disabled': disabled },
        className
      )}
      data-testid={label}
    >
      {label && (
        <label htmlFor={htmlFor} data-selector="fs-show">
          {label}
          {!optionalField && <span className="required-indicator">*</span>}
          {showInfoTooltip && infoTooltipText && (
            <span className="info-tooltip">
              <Tooltip
                text={infoTooltipText}
                placement="right"
                tooltipTextAlign="left"
              >
                <Icon name="info" size={16} />
              </Tooltip>
            </span>
          )}
          {beta && (
            <Stamp
              label="BETA"
              size="small"
              className="u-marginLeft4"
              borderRadius="full"
            />
          )}
        </label>
      )}
      {secondaryLabel && (
        <div className="secondary-label">{secondaryLabel}</div>
      )}
      <div className="ff-form-field__content">{children}</div>
      {error && (
        <div id={`${htmlFor}_desc`} className="error" data-selector="fs-show">
          <div className="error__icon-wrapper">
            <Icon size={12} name="error" />
          </div>
          <div>{error}</div>
        </div>
      )}
      {!error && hint && (
        <div className="hint" data-selector="fs-show">
          {hint}
        </div>
      )}
      <style jsx>{`
        .ff-form-field {
          height: fit-content;
          width: 100%;
          font-size: var(--FONT-SIZES-C1);
          line-height: 1.5;
        }

        label {
          color: var(--grey-900);
          font-size: var(--FONT-SIZES-C2);
          font-weight: 600;
          line-height: 1.5;
        }

        .info-tooltip :global(div) {
          display: inline-flex;
          align-items: center;
          vertical-align: middle;
        }

        .info-tooltip :global(svg) {
          margin-left: var(--space-4);
        }

        .info-tooltip :global(.tooltip) {
          max-width: 250px;
        }

        .secondary-label {
          font-size: 14px;
          float: right;
        }

        .ff-form-field__content {
          margin-top: var(--space-8);
        }

        .required-indicator {
          margin: 4px;
          font-weight: 600;
        }

        .error,
        .hint {
          margin-top: var(--space-8);
          font-size: 12px;
          font-weight: unset;
          line-height: 20px;
        }

        .error {
          display: flex;
          align-items: start;
          color: var(--pink-700);
        }

        .error__icon-wrapper {
          margin-right: var(--space-8);
          padding: var(--space-4) 0;
        }

        .hint {
          color: var(--grey-600);
        }

        .ff-form-field.ff-form-field--disabled {
          color: var(--grey-400);
          pointer-events: none;
        }

        .ff-form-field.ff-form-field--disabled
          .ff-form-field__content
          > :global(button) {
          color: var(--grey-500);
          background-color: var(--grey-100);
          pointer-events: none;
        }

        .ff-form-field.ff-form-field--disabled
          .ff-form-field__content
          > :global(button):focus,
        .ff-form-field.ff-form-field--disabled
          .ff-form-field__content
          > :global(button):hover {
          border-color: var(--grey-300);
          box-shadow: none;
        }

        .ff-form-field__tooltip-wrapper {
          width: 100%;
        }
      `}</style>
    </div>
  </Tooltip>
)
