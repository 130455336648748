import {
  moneyStringFormatter,
  moneyStringFormatterWithoutSymbol,
} from '@lib/helpers'

export const formatConvertedCurrency = (
  amount: string,
  currency: string,
  rate = 1,
  withoutSymbol = false
) => {
  const convertedAmount = parseFloat(amount) * rate
  return withoutSymbol
    ? moneyStringFormatterWithoutSymbol(currency)(convertedAmount)
    : moneyStringFormatter(currency)(convertedAmount)
}
