import { OrderSearchResult } from 'pages/api/search'
import { makeMockOrder } from './make-mock-order'

const getDefaultObject = (() => (): OrderSearchResult => {
  // using as any here to avoid having to select each of the fields in the object
  return makeMockOrder() as any
})()

export const makeMockOrderSearchResult = (
  extendDefault?: Partial<OrderSearchResult>
): OrderSearchResult =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
