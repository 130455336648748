import * as React from 'react'
import { Button } from '@components/Button'
import { FilterHeader } from './FilterHeader'
import { getFilterNames } from './lib/helpers'
import { FilterType } from './lib/types'

import styles from './FilterDetailsContainer.module.css'

export interface FilterDetailsContainerProps {
  filter: FilterType
  canApply: boolean
  onApplyButtonPressed(): void
  onBackButtonPressed(): void
}

const headerLabelOverrides = {
  offerSorting: 'Sort by',
  orderChangeSorting: 'Sort by',
}

export const FilterDetailsContainer: React.FC<
  React.PropsWithChildren<FilterDetailsContainerProps>
> = ({
  filter,
  canApply,
  onApplyButtonPressed,
  onBackButtonPressed,
  children,
}) => {
  React.useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && canApply) {
        onApplyButtonPressed()
      }
    }

    document.addEventListener('keypress', handleKeyPress)
    return () => document.removeEventListener('keypress', handleKeyPress)
  }, [onApplyButtonPressed, canApply])

  return (
    <div className={styles['container']} data-selector="fs-show">
      <FilterHeader>
        {typeof filter === 'string' && headerLabelOverrides[filter]
          ? headerLabelOverrides[filter]
          : `Filter by ${getFilterNames(filter).toLowerCase()}`}
      </FilterHeader>
      <div className="u-padding16">
        {children}
        {/*
         * We need to use flex-direction reverse here so that when we use this with `goToNextTabbable` components (e.g. datepicker),
         * the apply button would be the one being focused
         */}
        <div className={styles['buttons-row']}>
          <Button
            fill
            intent="PRIMARY"
            text="Apply"
            disabled={!canApply}
            onClick={onApplyButtonPressed}
          />
          <div className="u-marginRight8" />
          <Button
            outlined
            intent="MUTED"
            text="Back"
            onClick={onBackButtonPressed}
          />
        </div>
      </div>
    </div>
  )
}
