// This library is also used on the backend to validate phone numbers
import { PhoneNumberUtil } from 'google-libphonenumber'
import { string } from 'yup'

export const isValidPhoneNumber = (value: string | undefined) => {
  const phoneUtil = PhoneNumberUtil.getInstance()

  try {
    const number = phoneUtil.parse(value)
    return phoneUtil.isValidNumber(number)
  } catch {
    return false
  }
}

export const phoneNumberStr = (required = true) =>
  required
    ? string()
        .required('A phone number is required')
        .test(
          'is-valid-phone-number',
          'This must be a valid international phone number',
          isValidPhoneNumber
        )
    : string().test(
        'is-valid-phone-number',
        {
          message: 'This must be a valid international phone number.',
          excludeEmptyString: true,
        },
        isValidPhoneNumber
      )
