import { getDaysInMonth } from './get-days-in-month'

// Fill previous month with all days
export const padPreviousMonthWithDays = (billingPeriod: Date) =>
  getDaysInMonth(
    billingPeriod.getMonth() === 0 ? 11 : billingPeriod.getMonth() - 1,
    billingPeriod.getMonth() === 0
      ? billingPeriod.getFullYear() - 1
      : billingPeriod.getFullYear()
  )

// Fill current month with all days
export const padCurrentMonthWithDays = (billingPeriod: Date) =>
  getDaysInMonth(billingPeriod.getMonth(), billingPeriod.getFullYear())
