import { DuffelAPI } from '@lib/types'
import { Booking } from '@modules/stays-search/lib/types'
import type SmoochType from 'smooch'

let Smooch: typeof SmoochType

export const smoochOptions: InitOptions = {
  integrationId: '64539fb30bc3687ea4b62b66',
  // Can use the following for auth eventually
  // At the moment, if the user opens Duffel on a new computer/browser, they won't be able to see their support tickets
  // externalId: "",
  // jwt: "",
  businessName: 'Duffel Support',
  businessIconUrl:
    'https://assets.duffel.com/img/duffel-logo/duffel_logo_symbol_black.svg',
  canUserSeeConversationList: false,
  menuItems: {
    imageUpload: true,
    fileUpload: true,
    shareLocation: false,
  },
}

export const smoochInit = async () => {
  // dynamically import the live chat library: https://nextjs.org/docs/app/building-your-application/optimizing/lazy-loading#loading-external-libraries
  Smooch = (await import('smooch')).default

  await Smooch.init(smoochOptions)

  // Make sure we don't show their launcher over our UI
  document.getElementById('web-messenger-container')!.style.display = 'none'
  Smooch.on('widget:closed', () => {
    document.getElementById('web-messenger-container')!.style.display = 'none'
  })

  Smooch.on('widget:opened', () => {
    document.getElementById('web-messenger-container')!.style.display = 'inline'
  })
}

interface CommonShowLiveChatOptions {
  onClose: () => void
  action?: string
}

interface ShowLiveChatOptionsForOrder extends CommonShowLiveChatOptions {
  order: DuffelAPI.Types.Order
  booking?: undefined
}

interface ShowLiveChatOptionsForBooking extends CommonShowLiveChatOptions {
  booking: Partial<Booking>
  order?: undefined
}

type ShowLiveChatOptions =
  | ShowLiveChatOptionsForOrder
  | ShowLiveChatOptionsForBooking

export const showChat = async ({
  booking,
  order,
  onClose,
  action,
}: ShowLiveChatOptions) => {
  if (!Smooch) {
    await smoochInit()
  }

  let id: string | undefined = undefined
  let reference: string | undefined = undefined

  if (booking) {
    id = booking.id
    reference = booking.reference || 'booking' // fallback is needed as reference is an optional type
  } else if (order) {
    id = order.id
    reference = order.bookingReference
  }

  if (!id || !reference) {
    throw new Error(
      'Attempted to open live chat but the id of teh resource is missing.'
    )
  }

  Smooch.on('widget:closed', () => {
    onClose()
  })

  // Testing if we can filter the list of conversations the user sees?
  // E.g. a solved ticket might contain the message "How did your chat go?"
  // Or maybe there's some other way to remove them:
  // https://github.com/zendesk/sunshine-conversations-web#conversationremoved
  // https://docs.smooch.io/rest/#operation/deleteConversation (not sure if this would delete ticket too?)

  // If the user already has an open conversation for this order, see if we can load that
  const conversation = await getOpenConversationIfExists(id)

  if (conversation) {
    Smooch.loadConversation(conversation.id)
    // This event triggers when the user receives a message
    // but not a message from the bot ...
    // Smooch.on('message:received', function (message, data) {
    //   console.info(
    //     `The user received a message in conversation ${data.conversation.id}: `,
    //     message
    //   )
    //   if (Object.keys(message).length === 0) {
    //     Smooch.updateConversation(data.conversation.id, {
    //       metadata: {
    //         ticketClosed: true,
    //       },
    //     }).then(() => {
    //       console.info('conversation updated')
    //     })
    //   }
    // })
  } else {
    Smooch.createConversation({
      displayName: `Help with ${reference}`, // Appears in UI but not one the ticket
      description: id, // Appears in UI but not one the ticket
      metadata: {
        // Unfortunately none of this makes it to Zendesk support ticket, just stays on the Smooch conversation
        resourceId: id,
        reference,
        action: action || 'None',
      },
      messages: [],
    }).then((conversation) => {
      Smooch.loadConversation(conversation.id)
    })
  }

  Smooch.open()
}

export const isLiveChatOpen = () => {
  try {
    return Smooch && Smooch.isOpened()
  } catch {
    return false
  }
}

export const getOpenConversationIfExists = async (orderId: string) => {
  if (!Smooch) {
    await smoochInit()
  }

  try {
    // If the user already has an open conversation for this order, see if we can load that
    const conversations = Smooch.getConversations()
    // Make sure we load and check all messages in the conversation (we might only get the most recent with the above call)
    const fullConversations = await Promise.all(
      conversations.map((conversation) =>
        Smooch.getConversationById(conversation.id)
      )
    )
    const conversation = fullConversations.find(
      (conversation) =>
        (conversation.description.includes(orderId) ||
          conversation.metadata.orderId === orderId) &&
        // conversation.displayName.includes(action) && // TBD if/how we want to support multiple open tickets per order
        // Not sure if there's a better way to see if a ticket is solved?
        conversation.messages.every(
          (message) =>
            message.text !== 'How did your chat go?' &&
            message.text !==
              'Thanks for your feedback. If you need more help, you can ask another question at any time.'
        )
    )

    return conversation
  } catch {
    return undefined
  }
}

export const hasUnsolvedChatAboutOrder = async (orderId: string) => {
  const conversation = await getOpenConversationIfExists(orderId)
  return conversation !== undefined
}

interface LoadLiveChatOptions {
  orderId: string
  shouldOpen: boolean
  onClose?: () => void
}

// Technically there could be an edge case where we show "Resume chat" in the menu
// (because hasUnsolvedChatAboutOrder was true)
// then the ticket gets solved separately,
// and then the user actually clicks the Resume button.
// What do we want to do in that case?
// Currently, nothing would happen except the support menu would close.
export const loadChat = async ({
  orderId,
  shouldOpen,
  onClose,
}: LoadLiveChatOptions) => {
  const conversation = await getOpenConversationIfExists(orderId)
  if (conversation) {
    Smooch.loadConversation(conversation.id)
    if (shouldOpen) {
      Smooch.on('widget:closed', () => {
        onClose && onClose()
      })
      Smooch.open()
    }
  } else {
    Smooch.close()
  }
}
