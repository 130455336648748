export class AnalyticsError extends Error {
  constructor(
    message: string,
    trackingData: { event: string; properties: any }
  ) {
    super(message)
    this.trackingData = trackingData
  }
  trackingData: { event: string; properties: any }
  name = 'AnalyticsError'
}
