import { DuffelAPI } from '@lib/types'
import { makeMockOrganisationSources } from '.'

const mockUrls = {
  logoLockupUrl: 'mockLogoLockupUrl.com',
  logoSymbolUrl: 'mockLogoSymbolUrl.com',
  conditionsOfCarriageUrl: 'mockConditionsOfCarriageUrl.com',
}

const getDefaultArray = (): DuffelAPI.Types.OrganisationAirlines => {
  const organisationSources = makeMockOrganisationSources()

  // Extend organisation sources with airline data
  return organisationSources.reduce(
    (airlines: DuffelAPI.Types.OrganisationAirlines, source) => {
      switch (source.id) {
        case 'aegean_airlines': {
          const airline: DuffelAPI.Types.OrganisationAirline = {
            ...source,
            ...mockUrls,
            name: 'Aegean Airlines',
            iataCode: 'A3',
            region: 'Europe',
            alliance: 'Star Alliance',
          }
          return [...airlines, airline]
        }
        case 'american_airlines': {
          const airline: DuffelAPI.Types.OrganisationAirline = {
            ...source,
            ...mockUrls,
            name: 'American Airlines',
            iataCode: 'AA',
            region: 'North America',
            alliance: 'oneworld',
          }
          return [...airlines, airline]
        }
        case 'british_airways': {
          const airline: DuffelAPI.Types.OrganisationAirline = {
            ...source,
            ...mockUrls,
            name: 'British Airways',
            iataCode: 'BA',
            region: 'Europe',
            alliance: 'oneworld',
          }
          return [...airlines, airline]
        }
        case 'duffel_airways': {
          const airline: DuffelAPI.Types.OrganisationAirline = {
            ...source,
            ...mockUrls,
            name: 'Duffel Airways',
            iataCode: 'ZZ',
            region: 'Worldwide',
            alliance: '',
          }
          return [...airlines, airline]
        }
        case 'emirates': {
          const airline: DuffelAPI.Types.OrganisationAirline = {
            ...source,
            ...mockUrls,
            name: 'Emirates',
            iataCode: 'EK',
            region: 'Middle East',
            alliance: '',
          }
          return [...airlines, airline]
        }
        case 'lufthansa_group': {
          const airline: DuffelAPI.Types.OrganisationAirline = {
            ...source,
            ...mockUrls,
            name: 'Lufthansa',
            iataCode: 'LH',
            region: 'Europe',
            alliance: 'Star Alliance',
          }
          return [...airlines, airline]
        }
        case 'united': {
          const airline: DuffelAPI.Types.OrganisationAirline = {
            ...source,
            ...mockUrls,
            name: 'United Airlines',
            iataCode: 'UA',
            region: 'North America',
            alliance: 'Star Alliance',
          }
          return [...airlines, airline]
        }
        default:
          return airlines
      }
    },
    []
  )
}

export const makeMockOrganisationAirlines = (
  extendArray: DuffelAPI.Types.OrganisationAirlines = []
): DuffelAPI.Types.OrganisationAirlines =>
  new Array(...[...getDefaultArray(), ...extendArray])
