import { Anchor } from '@components/Anchor'
import { VSpace } from '@components/VSpace'
import { Text } from '@components/legacy-design-system/product/components/Text'
import { moneyStringFormatter } from '@lib/helpers'
import { DuffelAPI } from '@lib/types'
import React from 'react'

interface CFARSummaryProps {
  order: DuffelAPI.Types.Order
}

const CFARSummary: React.FC<CFARSummaryProps> = ({ order }) => {
  const cfarService =
    order.services &&
    (order.services.filter(
      (service) => service.type === 'cancel_for_any_reason'
    )[0] as DuffelAPI.Types.OrderCFARService)

  if (!cfarService) return null

  return (
    <VSpace space={24}>
      <Text typeStyle="paragraph2">
        This order has been booked with the <i>Cancel for any reason</i>{' '}
        service. We can refund up to 75% of your base fare if you cancel 24
        hours before your first scheduled departure. To cancel and claim the
        refund, click on 'Manage this order' on the top of this page and select
        'Cancellation quote'. This refund overrides the refund conditions of the
        carrier.{' '}
        <Anchor
          target="_blank"
          href={cfarService.metadata.termsAndConditionsUrl}
        >
          Click here for full terms and conditions
        </Anchor>
        .
      </Text>
      <Text typeStyle="paragraph2">
        Refund amount:{' '}
        <Text asElement="b" fontWeight="bold">
          {moneyStringFormatter(order.totalCurrency)(
            +cfarService.metadata.refundAmount
          )}
        </Text>
      </Text>
    </VSpace>
  )
}

export default CFARSummary
