import NextHead from 'next/head'
import * as React from 'react'
import { isDevProduction, isDevStaging } from '@lib/env'

const FAVICON_SIZES = ['96x96', '64x64', '32x32', '16x16']

interface HeadProps {
  title?: string
}

export const Head: React.FunctionComponent<
  React.PropsWithChildren<HeadProps>
> = ({ children, title }) => {
  let duffel = 'Duffel'
  if (isDevStaging) {
    duffel = 'Duffel Staging'
  } else if (isDevProduction) {
    duffel = 'Duffel Production'
  }

  title = title ? `${duffel} – ${title}` : duffel

  return (
    <NextHead>
      <meta charSet="utf-8" />
      <meta name="robots" content="noindex, nofollow" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{title}</title>
      {FAVICON_SIZES.map((size) => [
        <link
          key={size}
          rel="icon"
          type="image/png"
          sizes={size}
          href={`/favicon-${size}.png`}
        />,
        <link
          key={`apple-touch-icon-${size}`}
          rel="apple-touch-icon"
          type="image/png"
          sizes={size}
          href={`/favicon-${size}.png`}
        />,
      ])}
      {children}
    </NextHead>
  )
}
