import { FieldMetaProps, FormikContextType } from 'formik'
import { CommonSearchFormSectionValues } from '../../../CommonSearchFormSection'
import { PassengerErrorObject } from './types'

/**
 * This type is used to extend the default `FieldMetaProps<any>['error']` type that comes from Formik.
 * See comment for `PassengerErrorObject` type
 * */
interface GenericPassengerErrorFieldMetaProps
  extends Pick<FieldMetaProps<any>, 'touched'> {
  /** Error message or messages of the field */
  error: PassengerErrorObject[] | FieldMetaProps<any>['error']
}

/** Get a generic error message if the passengers array has errors */
export const getGenericPassengerAgeError = (
  { touched, error }: GenericPassengerErrorFieldMetaProps,
  {
    submitCount,
  }: Pick<FormikContextType<CommonSearchFormSectionValues>, 'submitCount'>
) => {
  if (touched || submitCount > 0) {
    return typeof error === 'object'
      ? 'Please select age for child passengers'
      : error
  }
  return undefined
}
