import * as React from 'react'
import { useField, useFormikContext } from 'formik'
import classNames from 'classnames'
import { Icon } from '@components/Icon'
import { Input, InputProps } from '@components/Input'
import { FormField, FormFieldProps } from '../FormField'
import { formatValidationErrorForDisplay } from './password-validation-helpers'
import { RequirementStamps } from './RequirementStamps'
import styles from './FormikPassword.module.css'

export interface FormikPasswordProps extends FormFieldProps, InputProps {
  /**
   * The name of the field used by Formik
   */
  name: string

  /**
   * Should this password show the requirements for setting a password
   */
  showRequirements?: boolean
}

export const FormikPassword: React.FC<FormikPasswordProps> = (props) => {
  const {
    name,
    showRequirements,
    label,
    hint,
    optionalField,
    secondaryLabel,
    disabled,
    ...inputProps
  } = props

  const [requirementsVisible, setRequirementsVisible] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)

  const shouldShowRequirements = requirementsVisible && showRequirements

  const [field, { touched, error }] = useField(props)
  const { submitCount, isSubmitting } = useFormikContext()

  const shouldDisplayError = (touched || submitCount > 0) && error
  const isDisabled = isSubmitting || disabled

  return (
    <div className={styles['form-password']}>
      <FormField
        label={label}
        error={
          shouldDisplayError
            ? formatValidationErrorForDisplay(error)
            : undefined
        }
        hint={hint}
        htmlFor={inputProps.id || name}
        optionalField={optionalField}
        secondaryLabel={secondaryLabel}
        disabled={isDisabled}
      >
        <div className={styles['form-password__input-container']}>
          <Input
            className={styles['form-password__input']}
            {...inputProps}
            {...field}
            onFocus={() => setRequirementsVisible(true)}
            onBlur={() => setRequirementsVisible(false)}
            type={showPassword ? 'text' : 'password'}
            id={inputProps.id || name}
            spellCheck={false}
            isError={!!error}
            disabled={isDisabled}
          />
          <PasswordVisibilityToggle
            onClick={() => setShowPassword((prev) => !prev)}
            showPassword={showPassword}
          />
        </div>
      </FormField>
      {shouldShowRequirements && (
        <div className={styles['form-password__requirement-stamps-wrapper']}>
          <RequirementStamps
            value={field.value}
            isFieldSubmitted={submitCount > 0}
          ></RequirementStamps>
        </div>
      )}
    </div>
  )
}

export const PasswordVisibilityToggle: React.FC<{
  onClick: () => void
  showPassword: boolean
  className?: string
}> = ({ onClick, showPassword, className }) => (
  <button
    className={classNames(styles['form-password__input-toggle'], className)}
    onClick={onClick}
    tabIndex={-1}
    type="button"
    aria-hidden="true"
  >
    <Icon name={showPassword ? 'visibility' : 'visibility_off'} size={20} />
  </button>
)
