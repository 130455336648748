type StyleObject = string | { [key: string]: StyleObject }

export function prepareObjectForCSS(
  object: { [key: string]: StyleObject },
  prefixString?: string
) {
  return Object.keys(object).reduce((acc: string[], objectKey: string) => {
    const value = object[objectKey]
    const cleanObjectKey = objectKey.replace('_', '-').toUpperCase()
    const objectKeyString = prefixString
      ? `${prefixString}-${cleanObjectKey}`
      : cleanObjectKey

    if (typeof value === 'string') {
      acc.push(`--${objectKeyString}: ${value}`)
      return acc
    }

    if (typeof value === 'object') {
      const subValues: any = prepareObjectForCSS(value, objectKeyString)
      return [...acc, ...subValues]
    }

    return acc
  }, [])
}
