import { LayoverDetails, SliceLayoverItem } from '@lib/types'

export const getLayoverDetails = (
  item: SliceLayoverItem
): LayoverDetails | null => {
  const { layoverDetails } = item

  if (!layoverDetails?.airport || !layoverDetails?.duration) {
    return null
  }

  return {
    airport: layoverDetails.airport,
    duration: layoverDetails.duration,
    originDestinationKey: layoverDetails.originDestinationKey,
  }
}
