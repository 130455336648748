import { Button } from '@components/Button'
import { HSpace } from '@components/HSpace'
import { Icon } from '@components/Icon'
import { Notice } from '@components/Notice'
import { ToggleCard } from '@components/ToggleCard'
import { VSpace } from '@components/VSpace'
import { Text } from '@components/legacy-design-system/product/components/Text'
import { UNKNOWN_ERROR_MESSAGE } from '@lib/constants'
import { captureException } from '@lib/sentry'
import { trackEvent } from '@lib/tracking'
import { DuffelAPI } from '@lib/types'
import { useFeatureFlags } from '@lib/unleash'
import { useWorkspace } from '@lib/workspace-context'
import { ZendeskSupportTicketRequestPayload } from '@lib/zendesk'
import { getZendeskCreateTicketRequestPayload } from '@lib/zendesk/get-zendesk-create-ticket-request-payload'
import { isZendeskTicketHighPriority } from '@lib/zendesk/is-zendesk-ticket-high-priority'
import { isLiveChatOpen, showChat } from '@lib/zendesk/live-chat'
import { UserFriendlyAPIError } from '@modules/air-order/lib/get-user-friendly-api-error'
import { AnimatePresence, motion } from 'framer-motion'
import * as React from 'react'
import { ErrorSupportDetailsDrawer } from './ErrorSupportDetailsDrawer/ErrorSupportDetailsDrawer'
import styles from './OrderSupport.module.css'
import {
  OrderSupportContactForm,
  OrderSupportContactFormProps,
} from './OrderSupportContactForm/OrderSupportContactForm'
import {
  ORDER_MANAGEMENT_ACTION_UNAVAILABLE_SUPPORT_TITLE_MAP,
  ORDER_MANAGEMENT_ERROR_SUPPORT_TITLE_MAP,
  getOrderSupportTicketCategory,
  getUnavailableActionDescription,
  getUnsolvedOrderSupportTicketForCategory,
  getUnsolvedOrderSupportTicketNoticeCopy,
} from './lib'
import {
  ErrorSupportOrderManagementAction,
  OrderSupportFormValues,
  SupportOrderManagementAction,
  SupportType,
} from './types'

const SELF_MANAGED_DISCLAIMER =
  'As this order is self-managed, you will need to contact the airline to proceed with your request.'
interface OrderSupportCommonProps {
  supportType: SupportType
  order: DuffelAPI.Types.Order
  onClose?: () => void
  onSubmitSuccess?: () => void
  openAssistantChat?: (
    orderID: string,
    supportType: SupportOrderManagementAction
  ) => void
}

interface ErrorOrderSupportProps extends OrderSupportCommonProps {
  supportType: 'error'
  actionToSupport: ErrorSupportOrderManagementAction
  error: UserFriendlyAPIError
  requestId: string
}

interface UnavailableActionOrderSupportProps extends OrderSupportCommonProps {
  supportType: 'action_unavailable'
  actionToSupport: SupportOrderManagementAction
  error?: never
  requestId?: never
}

export type OrderSupportProps =
  | ErrorOrderSupportProps
  | UnavailableActionOrderSupportProps

export const OrderSupport: React.FC<OrderSupportProps> = ({
  openAssistantChat,
  order,
  onClose,
  onSubmitSuccess,
  supportType,
  actionToSupport,
  error,
  requestId,
}) => {
  const { addToast, user, duffelClient, setHideSupportButton } = useWorkspace()
  // dashboard_show_order_support_options is only turned on for specific organisations for live chat
  const showOrderSupportOptions =
    useFeatureFlags('dashboard_show_order_support_options') &&
    actionToSupport !== 'name_correction' && // we don’t want to offer live chat for Name Correction as we know that will involve some lengthy back and forths
    !isLiveChatOpen() // For now, we hide the live chat option if it's already open

  const isAssistantChatEnabled = useFeatureFlags(
    // This flag has been defined in
    // https://flags.x15.xyz/projects/default/features/dashboard_use_assistant_chat_for_support
    'dashboard_use_assistant_chat_for_support'
  )

  const [showOrderSupportOptionsDialog, setShowOrderSupportOptionsDialog] =
    React.useState<boolean>(showOrderSupportOptions || isAssistantChatEnabled)
  const showOrderSupportForTestOrders = useFeatureFlags(
    'dashboard_show_order_support_options_in_test_mode'
  )
  const [
    unsolvedOrderSupportTicketNoticeCopy,
    setUnsolvedOrderSupportTicketNoticeCopy,
  ] = React.useState<string | null>(null)

  const [supportOptionSelected, setSupportOptionSelected] = React.useState<
    'liveChat' | 'supportTicket'
  >(showOrderSupportOptions ? 'liveChat' : 'supportTicket')

  const title =
    supportType === 'error'
      ? ORDER_MANAGEMENT_ERROR_SUPPORT_TITLE_MAP[actionToSupport]
      : ORDER_MANAGEMENT_ACTION_UNAVAILABLE_SUPPORT_TITLE_MAP[actionToSupport]
  const category = getOrderSupportTicketCategory(actionToSupport)

  // We don't need to set this priority on the ticket payload because the
  // priority will be set automatically by this Webhook:
  //
  // https://github.com/duffelhq/platform/blob/main/apps/duffel_web/lib/web/controllers/webhooks/zendesk_controller.ex
  const isAutoHighPriority = isZendeskTicketHighPriority(order)
  const showLiveModeSupport = order.liveMode || showOrderSupportForTestOrders

  React.useEffect(() => {
    trackEvent('dashboard_order_support_modal_shown', {
      event_type: 'interaction',
      support_type: supportType,
      action_to_support: actionToSupport,
      auto_high_priority: isAutoHighPriority,
    })
  }, [])

  React.useEffect(() => {
    const fetchSupportTickets = async () => {
      const res = await duffelClient.Zendesk.getSupportTickets(order.id)

      if (res.errors || !res.data) {
        captureException(
          new Error('Failed to retrieve Zendesk support tickets'),
          res.errors
        )
        return
      }

      const tickets = res.data.tickets
      const unsolvedOrderSupportTicket =
        getUnsolvedOrderSupportTicketForCategory(tickets, category)

      if (unsolvedOrderSupportTicket) {
        setUnsolvedOrderSupportTicketNoticeCopy(
          getUnsolvedOrderSupportTicketNoticeCopy(unsolvedOrderSupportTicket)
        )
      }
    }

    fetchSupportTickets()
  }, [order.id, duffelClient])

  const submitHandler = async (values: OrderSupportFormValues) => {
    const { email, message, requestingVoid } = values

    const ticketPayload: ZendeskSupportTicketRequestPayload =
      getZendeskCreateTicketRequestPayload(
        category,
        email,
        message,
        window.location.href,
        user?.fullName,
        order,
        undefined,
        true,
        supportType === 'error',
        requestingVoid
      )

    if (requestingVoid) {
      ticketPayload.priority = 'high'
    }

    trackEvent('dashboard_order_support_modal_submitted', {
      event_type: 'interaction',
      support_type: supportType,
      action_to_support: actionToSupport,
      auto_high_priority: isAutoHighPriority,
      requesting_void: requestingVoid,
    })

    const { data, errors } = await duffelClient.Zendesk.createSupportTicket(
      ticketPayload
    )

    if (!data || errors) {
      const toastMessage = errors?.[0].message || UNKNOWN_ERROR_MESSAGE
      addToast({
        intent: 'warning',
        message: toastMessage,
        closeAfterTimeout: false,
      })
      trackEvent('dashboard_order_support_modal_alert_displayed', {
        event_type: 'alert',
        support_type: supportType,
        action_to_support: actionToSupport,
      })
    }

    trackEvent('dashboard_order_support_modal_completed', {
      event_type: 'api',
      support_type: supportType,
      action_to_support: actionToSupport,
    })
    onSubmitSuccess && onSubmitSuccess()
  }

  const renderOrderSupportContactForm = (): React.ReactNode => {
    const props: Omit<
      OrderSupportContactFormProps,
      'supportType' | 'actionToSupport'
    > = {
      order: order,
      isAutoHighPriority: isAutoHighPriority,
      onSubmit: submitHandler,
      onClose,
    }

    if (supportType === 'error') {
      return (
        <OrderSupportContactForm
          {...props}
          supportType={'error'}
          actionToSupport={actionToSupport}
          onBack={
            showOrderSupportOptions
              ? () => setShowOrderSupportOptionsDialog(true)
              : undefined
          }
        />
      )
    }
    return (
      <OrderSupportContactForm
        {...props}
        supportType={'action_unavailable'}
        actionToSupport={actionToSupport}
        onBack={
          showOrderSupportOptions
            ? () => setShowOrderSupportOptionsDialog(true)
            : undefined
        }
      />
    )
  }

  const renderOrderSupport = (): React.ReactNode => {
    return (
      <VSpace space={24}>
        <VSpace space={12}>
          <VSpace space={16}>
            <HSpace space={8} alignCenter>
              {supportType === 'error' && (
                <Icon
                  name="error_outline"
                  className={styles['heading__icon']}
                />
              )}
              <Text
                typeStyle="heading3"
                asElement="h2"
                className={styles['heading__title']}
                data-selector="fs-show"
              >
                {title}
              </Text>
            </HSpace>

            {supportType === 'action_unavailable' &&
              order.content === 'managed' &&
              showLiveModeSupport &&
              getUnavailableActionDescription(actionToSupport)}

            {supportType === 'action_unavailable' &&
              order.content === 'self_managed' &&
              showLiveModeSupport && (
                <Text
                  typeStyle="paragraph2"
                  color="grey-700"
                  data-selector="fs-show"
                >
                  {SELF_MANAGED_DISCLAIMER}
                </Text>
              )}

            {supportType === 'error' && showLiveModeSupport && (
              <>
                <Text
                  typeStyle="paragraph2"
                  color="grey-700"
                  data-selector="fs-show"
                >
                  This action didn’t have any effect on the order.
                </Text>
                <Text
                  typeStyle="paragraph2"
                  color="grey-700"
                  data-selector="fs-show"
                >
                  {order.content === 'managed' &&
                    'Please add any additional details below and submit this request. Our travel support team will get back to you.'}
                  {order.content === 'self_managed' && SELF_MANAGED_DISCLAIMER}
                </Text>
                <AnimatePresence>
                  <motion.div layoutId="dialog" layout="position">
                    {unsolvedOrderSupportTicketNoticeCopy && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3, delay: 0.2 }}
                      >
                        <Notice
                          intent="warning"
                          hideIcon
                          fontSize="C2"
                          data-selector="fs-show"
                        >
                          {unsolvedOrderSupportTicketNoticeCopy}
                        </Notice>
                      </motion.div>
                    )}
                  </motion.div>
                </AnimatePresence>
              </>
            )}

            {!showLiveModeSupport && (
              <>
                <Text
                  typeStyle="paragraph2"
                  color="grey-700"
                  data-selector="fs-show"
                >
                  This booking was created in test mode.
                </Text>
                <Text
                  typeStyle="paragraph2"
                  color="grey-700"
                  data-selector="fs-show"
                >
                  In live mode, we will guide you through some additional steps
                  and connect you to our support team.
                </Text>
              </>
            )}
          </VSpace>
          <hr className={styles['divider']} />
          {supportType === 'error' && (
            <ErrorSupportDetailsDrawer
              requestId={requestId}
              error={error.original}
            />
          )}
        </VSpace>
        {showLiveModeSupport && order.content === 'managed' ? (
          renderOrderSupportContactForm()
        ) : (
          <Button text="Close" medium intent="MUTED" fill onClick={onClose} />
        )}
      </VSpace>
    )
  }

  const commonToggleCardProps = {
    size: 'small',
    toggleStyle: 'circle',
    icon: 'check',
    activeBackgroundColor: 'white',
    activeColor: 'purple',
  } as const

  const renderOrderSupportOptions = (): React.ReactNode => {
    return (
      <VSpace space={24}>
        <VSpace space={16}>
          <Text
            typeStyle="heading3"
            asElement="h2"
            className={styles['heading__title']}
            data-selector="fs-show"
          >
            {title}
          </Text>
          {supportType === 'action_unavailable' &&
            order.content === 'managed' &&
            showLiveModeSupport &&
            getUnavailableActionDescription(actionToSupport)}

          {supportType === 'action_unavailable' &&
            order.content === 'self_managed' &&
            showLiveModeSupport && (
              <Text
                typeStyle="paragraph2"
                color="grey-700"
                data-selector="fs-show"
              >
                {SELF_MANAGED_DISCLAIMER}
              </Text>
            )}

          <hr className={styles['divider']} />

          <Text typeStyle="paragraph3" fontWeight="bold">
            How would you like to request your change?
          </Text>

          <ToggleCard
            {...commonToggleCardProps}
            toggleCardIcon="live_chat"
            name={
              <Text typeStyle="paragraph2" fontWeight="bold">
                Live chat
              </Text>
            }
            size="small"
            selected={supportOptionSelected === 'liveChat'}
            position="right"
            onClick={() => setSupportOptionSelected('liveChat')}
          />

          <ToggleCard
            {...commonToggleCardProps}
            toggleCardIcon="mail"
            name={
              <Text typeStyle="paragraph2" fontWeight="bold">
                Open a support ticket
              </Text>
            }
            size="small"
            selected={supportOptionSelected === 'supportTicket'}
            position="right"
            onClick={() => setSupportOptionSelected('supportTicket')}
          />

          <HSpace space={8} className="u-paddingTop16">
            <Button
              text="Dismiss"
              onClick={onClose}
              medium
              fill
              outlined
              intent="MUTED"
              className="u-marginRight8"
              data-selector="fs-show"
            />

            <Button
              text="Continue"
              onClick={() => {
                if (supportOptionSelected === 'liveChat') {
                  setHideSupportButton(true)
                  trackEvent('dashboard_live_chat_open_clicked', {
                    event_type: 'interaction',
                  })

                  if (isAssistantChatEnabled && openAssistantChat) {
                    // We need to cast because typescript doesn't know that
                    // in this code path actionToSupport is always an `UnavailableActionOrderSupportProps`
                    // and not an `ErrorOrderSupportProps`.
                    openAssistantChat(
                      order.id,
                      actionToSupport as UnavailableActionOrderSupportProps['actionToSupport']
                    )
                  }
                  // Open Zendesk live chat widget
                  else {
                    showChat({
                      order,
                      onClose: () => {
                        setHideSupportButton(false)
                        trackEvent('dashboard_live_chat_close_clicked', {
                          event_type: 'interaction',
                        })
                      },
                      action: title,
                    })
                  }
                  onClose && onClose()
                } else {
                  setShowOrderSupportOptionsDialog(false)
                }
              }}
              medium
              fill
              data-selector="fs-show"
            />
          </HSpace>
        </VSpace>
      </VSpace>
    )
  }

  return (
    <>
      {showOrderSupportOptionsDialog
        ? renderOrderSupportOptions()
        : renderOrderSupport()}
    </>
  )
}
