import { prepareObjectForCSS } from './prepare-object-for-css'

export const PAGE_MAX_WIDTH = {
  xsmall: '480px',
  small: '640px',
  medium: '800px',
  large: '1280px',
  full: '100%',
}

export type PageMaxWidth = keyof typeof PAGE_MAX_WIDTH

export const CSS_PAGE_MAX_WIDTH_VARIABLES = prepareObjectForCSS(
  PAGE_MAX_WIDTH,
  'PAGE-MAX-WIDTH'
).join('; ')
