import { Text } from '@components/Text'
import { getDateString } from '@lib/date'
import { Notice, NoticeCommonProps } from './Notice'

interface ChangeNoticeProps extends NoticeCommonProps {
  timestamp: string
}

export const ChangeNotice: React.FC<
  React.PropsWithChildren<ChangeNoticeProps>
> = ({ timestamp, intent, children }) => {
  const changeDetectedWithTimestamp = (
    <Text asElement="span">
      <b>Changes detected</b> ({getDateString(timestamp, 'mediumWithTime')})
    </Text>
  )

  return (
    <Notice intent={intent} title={changeDetectedWithTimestamp}>
      {children}
    </Notice>
  )
}
