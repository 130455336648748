import { User } from '@duffel/api/types/Identity'
import React from 'react'

// Create a context with an undefined default value
const UserContext = React.createContext<User | undefined>(undefined)

interface WithUserContextProps {
  user: User
  children: React.ReactNode
}

export const WithUserContext: React.FC<WithUserContextProps> = ({
  user,
  children,
}) => {
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

export const useUser = (): User | undefined => {
  const context = React.useContext(UserContext)
  return context
}
