import { NextComponentType } from 'next'
import * as React from 'react'
import { PropsOf } from '@components/legacy-design-system/shared/foundations'
import { ErrorView } from '@components/ErrorView'
import { captureException } from '@lib/sentry'
import { DuffelContext, DuffelPermissions, DuffelProxy } from '@lib/types'
import { hasAuthorisation } from './has-authorisation'
import { UserRole, userRolesMap } from './types'

export interface WithAuthProps {
  user?: DuffelProxy.Types.Self
  permissions?: DuffelPermissions
  notAuthorised?: boolean
}

export interface WithAuthConfig {
  skipOrganisationCheck?: boolean
  skipModeCheck?: boolean
  requiresRole?: UserRole
  shouldRedirectIfUnauthorised?: boolean
}

const orgDependantRoutes = [
  'activation',
  'airlines',
  'settings',
  'team',
  'tokens',
  'orders',
  'search',
  'wallet',
  'balance',
  'stays',
  'developers',
]

const modeDependantRoutes = [
  'orders',
  'wallet',
  'search',
  'balance',
  'stays',
  'developers',
]

// We opt to use {} type here despite it being the banned types because
// the types of the props don't really matter here.
// eslint-disable-next-line @typescript-eslint/ban-types
export const withAuth = <T extends {}>(config?: WithAuthConfig) => {
  return (
    Component: React.ComponentType<T>
  ): React.ComponentClass<T & WithAuthProps> =>
    class extends React.Component<T & WithAuthProps> {
      static async getInitialProps(ctx: DuffelContext) {
        const { user, query, asPath } = ctx
        const { permissions } = ctx
        const { org, mode } = query || { org: null, mode: null }
        const redirectLink = `/sign-in`
        const nextParam =
          asPath !== '/' ? `?next=${encodeURIComponent(asPath || '')}` : ''

        let apiMode: string | string[] = permissions?.liveMode ? 'live' : 'test'
        if (
          mode &&
          typeof mode === 'string' &&
          ['live', 'test'].includes(mode)
        ) {
          apiMode = mode
        }

        const slug = user?.organisationSlugs[0]
        const organisation = slug && user?.organisationsBySlug[slug]
        const isOrgVerified = organisation && organisation.isVerified

        const shouldSkipOrganisationCheck =
          config && config.skipOrganisationCheck
        const shouldSkipModeCheck = config && config.skipModeCheck

        if (!org && !shouldSkipOrganisationCheck) {
          return { redirect: redirectLink }
        }

        if (!user || !permissions) {
          return { redirect: `${redirectLink}${nextParam}` }
        }

        if (
          permissions &&
          org &&
          user.organisationSlugs.length >= 1 &&
          orgDependantRoutes.includes(org as string)
        ) {
          if (asPath && modeDependantRoutes.includes(asPath.split('/')[1])) {
            return {
              redirect: `/${user.organisationSlugs[0]}/${
                isOrgVerified ? apiMode : 'test'
              }${asPath}`,
            }
          }
          return { redirect: `/${user.organisationSlugs[0]}${asPath}` }
        }

        if (
          permissions &&
          org &&
          !user.organisationSlugs.includes(org as string)
        ) {
          return { redirect: '/' }
        }

        if (
          !shouldSkipModeCheck &&
          !shouldSkipOrganisationCheck &&
          !['live', 'test'].includes(mode as string)
        ) {
          captureException(new Error('Mode was not recognised'), {
            mode,
          })

          return { redirect: '/' }
        }

        if (mode === 'live' && !permissions.liveMode) {
          return { redirect: '/' }
        }

        if (
          config &&
          config.requiresRole &&
          !hasAuthorisation(permissions, config.requiresRole)
        ) {
          return config.shouldRedirectIfUnauthorised
            ? { redirect: '/' }
            : { notAuthorised: true }
        }

        // looking good!
        const protectedComponentProps =
          (await (Component as NextComponentType)?.getInitialProps?.(ctx)) ?? {}

        // RETURN PROPS
        return {
          user,
          permissions,
          ...protectedComponentProps,
        }
      }

      renderAuthGuard() {
        const role =
          config && config.requiresRole && userRolesMap[config.requiresRole]

        return (
          <ErrorView
            title="Unauthorised"
            subtitle={`Please request your organisation administrator to grant you ${
              role!.name
            } permission in order to access this page.`}
          />
        )
      }

      render() {
        return this.props.notAuthorised ? (
          this.renderAuthGuard()
        ) : (
          <Component {...this.props} />
        )
      }
    }
}

// a convenient withAuth function that can infer the props from the component being passed in
export const withAuthV2 =
  <T extends React.ComponentType<any>>(config: WithAuthConfig) =>
  (Component: T) =>
    withAuth<PropsOf<T>>(config)(Component)
