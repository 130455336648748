import { Input } from '@components/Input'

export const PassengerNameFilterInput: React.FC<{
  value?: string
  onChange: (val: string) => void
}> = ({ value, onChange }) => (
  <Input
    value={value ?? ''}
    onChange={(event) => {
      onChange(event.target.value)
    }}
    containerSize="small"
  />
)
