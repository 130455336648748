import * as React from 'react'
import { ButtonDatePickerTarget } from './ButtonDatePickerTarget'
import { DatePickerWrapper } from './DatePickerWrapper'
import { DatePickerProps } from './lib'

export interface ButtonDatePickerProps
  extends Omit<DatePickerProps, 'value' | 'onChange'> {
  value: Date | null
  onChange: (value: Date | null) => void
  labelFormatLocale?: string
}

export const ButtonDatePicker: React.FC<ButtonDatePickerProps> = ({
  labelFormatLocale,
  onChange,
  ...datePickerProps
}) => {
  return (
    <DatePickerWrapper
      {...datePickerProps}
      onChange={(value) => onChange(value as Date | null)}
      renderTarget={(targetProps) => (
        <ButtonDatePickerTarget
          labelFormatLocale={labelFormatLocale}
          {...targetProps}
        />
      )}
    />
  )
}
