import { v4 as uuidv4 } from 'uuid'
import { DuffelAPI } from '@lib/types'

const PASSENGERS_PARAM_ID_PREFIX = 'pas_'

const passengersParamsStore = new Map<
  string,
  DuffelAPI.Inputs.OfferRequestPassenger[]
>()

export const getPassengersParams = (id: string) =>
  passengersParamsStore.get(id) ?? null

export const savePassengersParams = (
  passengers: DuffelAPI.Inputs.OfferRequestPassenger[],
  withId?: string
) => {
  const id = withId ?? `${PASSENGERS_PARAM_ID_PREFIX}${uuidv4()}`
  passengersParamsStore.set(id, passengers)
  return id
}

export const isPassengersParamId = (maybeId: string) =>
  maybeId.startsWith(PASSENGERS_PARAM_ID_PREFIX)
