import classNames from 'classnames'
import * as React from 'react'
import { goToNextTabbable } from '@lib/helpers'
import { DatePickerDayInput } from './DatePickerDayInput'
import { DatePickerMonthInput } from './DatePickerMonthInput'
import { DatePickerYearInput } from './DatePickerYearInput'
import {
  DatePickerTargetProps,
  getSplitStringValuesForDateProp,
  makeDayInputId,
  makeMonthInputId,
} from './lib'
import { setupOnTabListenersOnCalendarDays } from './lib/setup-on-tab-listeners-on-calendar-days'

interface FormDatePickerTarget extends Omit<DatePickerTargetProps, 'onChange'> {
  value: Date | null
  onChange: (value: Date | null) => void
  shouldGoToNextTabbable?: boolean
}

// Formats dd/mm/yyyy || dd.mm.yyyy || dd-mm-yyyy
// this doesn't check for leap year
const DATE_FORMAT_REGEX =
  /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/

function getParsedDateFromString(date: string): number {
  let fullDateString: string[]
  if (date.indexOf('/') >= 0) {
    fullDateString = date.split('/')
  } else if (date.indexOf('-') >= 0) {
    fullDateString = date.split('-')
  } else if (date.indexOf('.') >= 0) {
    fullDateString = date.split('.')
  } else {
    return 0
  }
  const [day, month, year] = fullDateString
  return Date.parse(`${year}-${month}-${day}`)
}

export const FormDatePickerTarget = React.forwardRef<
  HTMLDivElement,
  FormDatePickerTarget
>(
  (
    {
      id,
      isActive,
      onBlur,
      onChange,
      onOpenPopover,
      onClosePopover,
      value,
      disabled,
      shouldGoToNextTabbable = true,
    },
    ref
  ) => {
    if (Array.isArray(value))
      throw new Error(
        'A FormDatePickerTarget value props is being used incorrectly. It must be Date | null but DateRange was received.'
      )

    const [dayString, monthString, yearString] =
      getSplitStringValuesForDateProp(value)
    const [dayInputValue, setDayInputValueState] = React.useState(dayString)
    const [monthInputValue, setMonthInputValueState] =
      React.useState(monthString)
    const [yearInputValue, setYearInputValueState] = React.useState(yearString)

    // This value is for keeping track whether the last value update was from the component or from outside (i.e. the value
    // was updated from the parent component without the state values
    const isLastUpdateFromComponent = React.useRef(false)

    const maybeSetCompleteDay = (year: string, month: string, day: string) => {
      // mark that this update is from inside the component

      isLastUpdateFromComponent.current = true
      if (!year || !month || !day) {
        onChange(null)
        return false
      }
      if (day.length !== 2 || month.length !== 2 || year.length !== 4) {
        onChange(null)
        return false
      }

      const fullDateString = `${year}-${month}-${day}`
      const parseResult = Date.parse(fullDateString)
      if (isNaN(parseResult)) {
        onChange(null)
        return false
      } else {
        onChange(new Date(parseResult))
        return true
      }
    }

    const inputValueUpdateHook =
      (
        valueType: 'day' | 'month' | 'year',
        stateUpdate: (value: string) => void
      ) =>
      (value: string) => {
        stateUpdate(value)
        let moveOn = false
        switch (valueType) {
          case 'day':
            moveOn = maybeSetCompleteDay(yearInputValue, monthInputValue, value)
            break
          case 'month':
            moveOn = maybeSetCompleteDay(yearInputValue, value, dayInputValue)
            break
          case 'year':
            moveOn = maybeSetCompleteDay(value, monthInputValue, dayInputValue)
            break
        }
        if (!moveOn) return
      }

    const setDayInputValue = inputValueUpdateHook('day', setDayInputValueState)
    const setMonthInputValue = inputValueUpdateHook(
      'month',
      setMonthInputValueState
    )
    const setYearInputValue = inputValueUpdateHook(
      'year',
      setYearInputValueState
    )

    React.useEffect(() => {
      if (value !== null) {
        const [dayString, monthString, yearString] =
          getSplitStringValuesForDateProp(value)
        setDayInputValueState(dayString)
        setMonthInputValueState(monthString)
        setYearInputValueState(yearString)
        return
      }

      /*
      if value updates from outside without a state change, we need to reset the input values
      */
      if (!isLastUpdateFromComponent.current) {
        setDayInputValueState('')
        setMonthInputValueState('')
        setYearInputValueState('')
      }

      isLastUpdateFromComponent.current = false
    }, [value])

    React.useEffect(() => {
      if (isActive) {
        setupOnTabListenersOnCalendarDays(onClosePopover)
      }
    }, [isActive, onClosePopover])

    return (
      <div
        ref={ref}
        id={id}
        data-testid={id}
        className={classNames(
          'date-picker-form-target',
          { active: isActive },
          { 'date-picker-form-target--disabled': disabled }
        )}
        onPaste={(event) => {
          // Get pasted data via clipboard API
          const clipboardData = event?.clipboardData || window['clipboardData']
          const clipboardValue = clipboardData.getData('Text')
          const fullDateString = clipboardValue.match(DATE_FORMAT_REGEX)
            ? getParsedDateFromString(clipboardValue)
            : undefined

          if (fullDateString) {
            onChange(new Date(fullDateString))
            if (shouldGoToNextTabbable) {
              goToNextTabbable(makeMonthInputId(id))
            }
          }
        }}
      >
        <DatePickerDayInput
          id={id}
          value={dayInputValue}
          onFocus={onOpenPopover}
          onBlur={onBlur}
          onChange={(day) => {
            if (day === '00') return setDayInputValue('')

            setDayInputValue(day)
            if (day.length === 2)
              return shouldGoToNextTabbable
                ? goToNextTabbable(makeDayInputId(id))
                : null
          }}
          onTab={(event) => {
            if (event.shiftKey) onClosePopover()
            const day = (event.target as HTMLInputElement).value
            if (+day === 0) return setDayInputValue('')
            if (+day < 10 && day.length !== 2)
              return setDayInputValue(`0${day}`)
          }}
        />
        <InputDelimiter />
        <DatePickerMonthInput
          id={id}
          value={monthInputValue}
          onFocus={onOpenPopover}
          onBlur={onBlur}
          onChange={(month) => {
            if (month === '00') return setMonthInputValue('')
            setMonthInputValue(month)
            if (month.length === 2)
              return shouldGoToNextTabbable
                ? goToNextTabbable(makeMonthInputId(id))
                : null
          }}
          onTab={(month: string) => {
            if (+month === 0) return setMonthInputValue('')
            if (+month < 10 && month.length !== 2)
              return setMonthInputValue(`0${month}`)
          }}
        />
        <InputDelimiter />
        <DatePickerYearInput
          id={id}
          value={yearInputValue}
          onFocus={onOpenPopover}
          onBlur={onBlur}
          onChange={(year) => {
            setYearInputValue(year)
          }}
          onTab={(year: string) => {
            onClosePopover()
            if (+year === 0) return setYearInputValue('')
            if (+year < 10) return setYearInputValue(`0${year}`)
          }}
        />
        <style jsx>{`
          .date-picker-form-target {
            background: var(--white);
            border: 1px solid var(--grey-300);
            border-radius: var(--border-radius-6);
            height: 40px;
            font-family: var(--FONTS-SANS);
            font-size: var(--FONT-SIZES-C1);
            font-weight: normal;
            outline: 0;
            padding: 0 16px;

            display: flex;
            align-items: center;
          }
          .date-picker-form-target:hover {
            border-color: var(--purple-500);
          }

          .date-picker-form-target.active,
          .date-picker-form-target:focus {
            border-color: var(--purple-500);
            box-shadow: var(--shadow-focus);
          }

          .date-picker-form-target:disabled,
          .date-picker-form-target:disabled:hover {
            background-color: var(--grey-100);
            border-color: var(--grey-300);
            color: var(--grey-500);
          }

          .date-picker-form-target--disabled {
            border: 1px solid var(--grey-300);
            box-shadow: none;
            cursor: default;
          }

          .date-picker-form-target--disabled,
          .date-picker-form-target--disabled > :global(input) {
            color: var(--grey-500);
            background-color: var(--grey-100);
            pointer-events: none;
          }
        `}</style>
      </div>
    )
  }
)

const InputDelimiter: React.FC = () => {
  return (
    <span className="input-delimiter">
      /
      <style jsx>{`
        .input-delimiter {
          color: var(--grey-600);
          margin-left: 4px;
          margin-right: 4px;
        }
      `}</style>
    </span>
  )
}
