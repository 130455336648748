import { generateId } from '@lib/helpers'
import { DuffelAPI } from '@lib/types'
import { initialLoyaltyProgrammeAccountValues } from '../PassengerLoyaltyAccountsForm/LoyaltyAccountsInput'

export const getDefaultChildPassenger =
  (): DuffelAPI.Inputs.OfferRequestChildPassenger => ({
    key: generateId(),
    age: -1,
    loyaltyProgrammeAccounts: [initialLoyaltyProgrammeAccountValues],
  })
export const getDefaultAdultPassenger =
  (): DuffelAPI.Inputs.OfferRequestAdultPassenger => ({
    key: generateId(),
    type: 'adult',
    loyaltyProgrammeAccounts: [initialLoyaltyProgrammeAccountValues],
  })
