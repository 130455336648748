import { getDateString, getTimeString } from '@lib/date'
import { TravelDetails } from '@lib/types'

export const getTravelDetailsWithComparableProperties = (
  travelDetails: TravelDetails<'order'>
) => ({
  departingAtDate: getDateString(travelDetails.departingAt, 'long'),
  departingAtTime: getTimeString(travelDetails.departingAt),
  arrivingAtDate: getDateString(travelDetails.arrivingAt, 'long'),
  arrivingAtTime: getTimeString(travelDetails.arrivingAt),
  ...travelDetails,
})
