import * as React from 'react'
import { Stamp, StampProps } from '@components/Stamp'
import { OrderStatus } from '../../helpers'

export interface OrderStatusStampProps {
  status: OrderStatus
  border?: StampProps['border']
}

const getOrderStatusStampColour = (
  orderStatus: OrderStatus
): StampProps['color'] => {
  switch (orderStatus) {
    case 'cancelled':
    case 'expired':
      return 'pink'
    case 'confirmed':
      return 'green'
    case 'pending':
      return 'blue'
    case 'past':
      return 'grey'
    default:
      return 'yellow'
  }
}

const getOrderStatusStampLabel = (orderStatus: OrderStatus): string => {
  const label = orderStatus.replace('-', ' ')
  return label.charAt(0).toUpperCase() + label.slice(1)
}

export const OrderStatusStamp: React.FC<OrderStatusStampProps> = ({
  status,
  border,
}) => {
  return (
    <Stamp
      color={getOrderStatusStampColour(status)}
      label={getOrderStatusStampLabel(status)}
      border={border}
      borderRadius="full"
      data-selector="fs-show"
    />
  )
}
