import { DuffelAPI } from '@lib/types'
import { makeMockUserOrganisationMembership } from './'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.User => ({
    unconfirmedEmail: 'tony@example.com',
    sendMarketingEmails: false,
    organisationMemberships: [
      makeMockUserOrganisationMembership({ userId: `usr_${seedId}` }),
    ],
    fullName: 'Tony Stark',
    emailConfirmedAt: '2019-04-01T10:55:37.036884Z',
    email: 'tony@example.com',
    duffelAdmin: true,
    createdAt: '2019-02-12T22:23:26.972070Z',
    avatarUrl: null,
    id: `usr_${seedId++}`,
  })
})()

export const makeMockUser = (
  extendDefault?: Partial<DuffelAPI.Types.User>
): DuffelAPI.Types.User =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
