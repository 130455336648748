import { format } from 'date-fns'
import { DuffelAPI } from '@lib/types'
import {
  ORDER_SUPPORT_TICKET_TAG,
  SUPPORT_CATEGORY_COPY_MAP,
  ZENDESK_SUPPORT_GROUP_ID_MAP,
} from './constants'
import {
  SupportCategory,
  ZendeskGroupId,
  ZendeskSupportTicketRequestPayload,
} from './types'
import { getZendeskTicketCustomFields } from './get-zendesk-ticket-custom-fields'
import { getZendeskTicketFirstFlightDate } from './get-zendesk-ticket-first-flight-date'

export const getZendeskCreateTicketRequestPayload = (
  category: SupportCategory,
  email: string,
  message: string,
  url: string,
  requesterName?: string,
  order?: DuffelAPI.Types.Order,
  uploads?: string[],
  isOrderSupportTicket = false,
  isErrorSupportTicket = false,
  requestingVoid = false
): ZendeskSupportTicketRequestPayload => {
  const tags: ZendeskSupportTicketRequestPayload['tags'] = [category]

  if (isOrderSupportTicket) {
    tags.push(ORDER_SUPPORT_TICKET_TAG)
  }

  return {
    comment: {
      body: message,
      uploads,
    },
    custom_fields: getZendeskTicketCustomFields(url, order),
    group_id: getZendeskTicketGroupId(category),
    priority: 'normal',
    requester: { email, name: requesterName || email },
    status: 'new',
    subject: getZendeskTicketSubject(
      category,
      order,
      isErrorSupportTicket,
      requestingVoid
    ),
    tags: tags,
    type: 'question',
  }
}

const getZendeskTicketGroupId = (category: SupportCategory): ZendeskGroupId => {
  switch (category) {
    case 'airline-initiated-change':
    case 'cancellation-refund':
    case 'order-change':
      return ZENDESK_SUPPORT_GROUP_ID_MAP.travel
    case 'airline-request':
    case 'source-request':
      return ZENDESK_SUPPORT_GROUP_ID_MAP.technicalAndProduct
    default:
      return ZENDESK_SUPPORT_GROUP_ID_MAP.general
  }
}

const getZendeskTicketSubject = (
  category: SupportCategory,
  order?: DuffelAPI.Types.Order,
  isErrorSupportTicket = false,
  requestingVoid = false
): string => {
  const subjectSections: string[] = []

  if (requestingVoid) {
    subjectSections.push('Void')
  }

  if (isErrorSupportTicket) {
    subjectSections.push('Dashboard Error Support')
  } else {
    subjectSections.push('Dashboard Support')
  }

  subjectSections.push(SUPPORT_CATEGORY_COPY_MAP[category].supportTicketSubject)

  if (order) {
    subjectSections.push(order.bookingReference)

    const firstFlightDate = getZendeskTicketFirstFlightDate(order)

    if (firstFlightDate) {
      subjectSections.push(
        `Departing ${format(firstFlightDate, 'do MMM yyyy')}`
      )
    }
  }

  return subjectSections.join(' - ')
}
