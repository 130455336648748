import { DuffelSources, SourceId } from '@lib/sources'
import { SourceAirlineWithSourceId } from '../FormFields'

export const convertAirlinesListToSourceAirlineWithSourceIds = (
  airlines: SourceId[]
): SourceAirlineWithSourceId[] => {
  return airlines
    ? airlines.map((airline) => ({
        ...DuffelSources[airline].featuredAirlines[0],
        sourceId: airline,
      }))
    : []
}
