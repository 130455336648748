import classNames from 'classnames'
import * as React from 'react'
import { Icon } from '@components/Icon'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { APIResponseError } from '@lib/types'
import styles from './ErrorSupportDetailsDrawer.module.css'

interface ErrorSupportDetailsDrawerProps {
  error: APIResponseError
  requestId: string
}

export const ErrorSupportDetailsDrawer: React.FC<
  ErrorSupportDetailsDrawerProps
> = ({ error, requestId }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const openButtonText = `${isOpen ? 'Hide' : 'Show'} error details`

  return (
    <VSpace space={12} data-selector="fs-show">
      {isOpen && (
        <VSpace space={12}>
          <Text color="grey-700">{error.message}</Text>
          <Text color="grey-500" fontSize="C2">
            Request ID: {requestId}
          </Text>
        </VSpace>
      )}
      <button
        className={styles['drawer-button']}
        onClick={() => setIsOpen(!isOpen)}
      >
        {openButtonText}{' '}
        <Icon
          className={classNames(styles['drawer-button__icon'], {
            [styles['drawer-button__icon--flipped']]: isOpen === true,
          })}
          name="keyboard_arrow_down"
          ml="4px"
        />
      </button>
    </VSpace>
  )
}
