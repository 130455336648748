import { DuffelAPI } from '@lib/types'

const defaultObject: DuffelAPI.Types.OfferAvailableServiceBaggageMetadata = {
  maximumWeightKg: null,
  maximumHeightCm: null,
  maximumLengthCm: null,
  maximumDepthCm: null,
  type: 'checked',
}

export const makeMockOfferBaggageServiceMetadata = (
  extendDefault?: Partial<DuffelAPI.Types.OfferAvailableServiceBaggageMetadata>
): DuffelAPI.Types.OfferAvailableServiceBaggageMetadata =>
  Object.assign({}, defaultObject, extendDefault || {})
