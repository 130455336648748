import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import Axios from 'axios'
import classNames from 'classnames'
import * as React from 'react'
import { Button } from '@components/Button'
import { CardDisplay } from '@components/CardDisplay'
import { Form } from '@components/Form'
import { Spinner } from '@components/Spinner'
import { VSpace } from '@components/VSpace'
import { captureException } from '@lib/sentry'
import { UNKNOWN_ERROR_MESSAGE } from '@lib/constants'
import { useStripeCard } from '@lib/hooks'
import { trackEvent } from '@lib/tracking'
import { useWorkspace } from '@lib/workspace-context'
import { getStripePromise } from '@modules/dashboard-activation/lib/get-stripe-promise'

interface CardFormProps {
  id?: string
  stripeCustomerId?: string
  stripePaymentMethodId?: string
  beforeSubmit: () => void
  onReceivePaymentMethodId: (value: string) => void
  onSubmitError: (message?: string) => void
  isSubmitting: boolean
  // When a user has successfully added a card for an organisation, the
  // organisation is considered live/verified. However, in some scenarios we
  // might not want to verify the organisation immediately. For example, in the
  // activation flow we allow users to review their details before verifying.
  // This prop provides that option. (UXP-1996)
  verifyOrgOnSuccess?: boolean
  trackingEventPageName: 'activation_card' | 'org_settings_card'
  hideButton?: boolean
  forwardRef?: React.ForwardedRef<HTMLButtonElement>
  className?: string
}

const UnwrappedCardForm: React.FC<CardFormProps> = ({
  className,
  stripeCustomerId,
  stripePaymentMethodId,
  beforeSubmit,
  onSubmitError,
  isSubmitting,
  onReceivePaymentMethodId,
  verifyOrgOnSuccess = true,
  trackingEventPageName,
  forwardRef,
  id,
}) => {
  const { addToast, duffelClient } = useWorkspace()
  const stripe = useStripe()
  const elements = useElements()
  const { cardData, isLoading } = useStripeCard(stripePaymentMethodId)

  const handleError = (message?: string) => {
    onSubmitError()
    trackEvent(
      `dashboard_${trackingEventPageName}_alert_displayed` as
        | 'dashboard_activation_card_alert_displayed'
        | 'dashboard_org_settings_card_alert_displayed',
      {
        event_type: 'alert',
        alert_message: message || UNKNOWN_ERROR_MESSAGE,
      }
    )
    addToast({
      intent: 'warning',
      message: message || UNKNOWN_ERROR_MESSAGE,
      closeAfterTimeout: false,
    })
  }

  // TODO(UXP-2177): extract this logic to a common hook and make this component
  // accept onSubmit prop instead (and remove the ref prop)
  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!elements || !stripe) return
    beforeSubmit?.()
    trackEvent(
      `dashboard_${trackingEventPageName}_form_submitted` as
        | 'dashboard_activation_card_form_submitted'
        | 'dashboard_org_settings_card_form_submitted',
      {
        event_type: 'interaction',
      }
    )
    const cardElement = elements.getElement(CardElement)

    if (!cardElement) {
      captureException(
        new Error('failed to retrieve card element for CardForm')
      )
      return handleError('Something went wrong. Please try again later.')
    }

    try {
      const { data } = await Axios.post('/api/stripe/setup_intents', {
        customer: stripeCustomerId,
      })
      if (!data) {
        return handleError(
          'Failed to create setup intent. Please contact support.'
        )
      }

      const { setupIntent, error } = await stripe.confirmCardSetup(
        data.client_secret,
        {
          payment_method: { card: cardElement },
        }
      )

      if (
        error ||
        !setupIntent?.payment_method ||
        setupIntent.status !== 'succeeded'
      ) {
        return handleError(error?.message || setupIntent?.status)
      }

      const paymentMethod = setupIntent.payment_method
      if (!paymentMethod || typeof paymentMethod === 'object') {
        // stripe can now return an object of payment method now.
        // However, considering that we are using a card payment, we shouldn't get anything besides the string back.
        // Basically, we should never see this error.
        return handleError(
          'Unexpected payment method received. Please contact support.'
        )
      }
      const { errors } = await duffelClient.Manage.updateOrganisation({
        stripePaymentMethodId: paymentMethod,
        ...(verifyOrgOnSuccess && { verified: true }),
      })
      if (errors && errors.length > 0) {
        return handleError(errors[0].message)
      }

      onReceivePaymentMethodId(paymentMethod)
      trackEvent(
        `dashboard_${trackingEventPageName}_form_completed` as
          | 'dashboard_activation_card_form_completed'
          | 'dashboard_org_settings_card_form_completed',
        {
          event_type: 'api',
        }
      )
      addToast({
        intent: 'success',
        message: 'Card saved successfully.',
      })
    } catch (error: any) {
      captureException(error)
      return handleError(error.message)
    }
  }

  return (
    <VSpace space={16}>
      <Form id={id} onSubmit={handleSubmit}>
        {isLoading ? (
          <div className="card-element-placeholder">
            <Spinner />
          </div>
        ) : (
          <VSpace space={16}>
            <CardDisplay
              className={classNames('card-display--activation-flow', className)}
              cardType={cardData?.brand}
              cardExpMonth={cardData?.exp_month}
              cardExpYear={cardData?.exp_year}
              cardNumberLast4={cardData?.last4}
            >
              <VSpace space={16}>
                {elements ? (
                  <CardElement />
                ) : (
                  <div className="card-element-placeholder" />
                )}
                <div className="save-card">
                  <Button
                    type="submit"
                    text="Save card"
                    intent="PRIMARY"
                    isWaiting={isSubmitting}
                    ref={forwardRef}
                  />
                </div>
              </VSpace>
            </CardDisplay>
          </VSpace>
        )}
      </Form>

      <style jsx>{`
        :global(.StripeElement) {
          box-sizing: border-box;
          padding: 14px var(--space-16);
          border: 1px solid var(--grey-300);
          border-radius: var(--border-radius-4);
        }

        :global(.StripeElement:hover) {
          border: solid 1px var(--purple-600);
        }

        :global(.StripeElement--focus) {
          border: solid 1px var(--purple-600);
          box-shadow: 0 0 0 3px var(--purple-200);
        }

        .card-element-placeholder {
          padding: 0;
          background-color: var(--white);
          border-radius: var(--border-radius-4);
          display: flex;
          align-items: center;
        }

        :global(.card-display__container.card-display--activation-flow) {
          border: none;
          background-color: var(--white);
          width: 100%;
        }

        .save-card {
          margin-left: auto;
          margin-right: 0;
        }
      `}</style>
    </VSpace>
  )
}

const stripePromise = getStripePromise()

// The component containing the Stripe Element component needs to be wrapped with the provider
export const CardForm: React.FC<CardFormProps> = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <UnwrappedCardForm {...props} />
    </Elements>
  )
}
