import { WithServiceInformation } from '@duffel/components'
import { CheckoutFormValues } from './get-initial-checkout-form-values'
import { CreateOrderService } from '@duffel/api/types'

export function getServicesExtraAmount(formValues: CheckoutFormValues) {
  let services: WithServiceInformation<CreateOrderService>[] = []
  let servicesExtraAmount = 0
  if (formValues.selectedServicesMetadata) {
    services = [
      ...formValues.selectedServicesMetadata.baggage_services,
      ...formValues.selectedServicesMetadata.seat_services,
      ...formValues.selectedServicesMetadata.cancel_for_any_reason_services,
    ]

    servicesExtraAmount = services.reduce(
      (total, service) =>
        +service.serviceInformation.total_amount * service.quantity + total,
      0
    )
  }
  return servicesExtraAmount
}
