import { Placement } from '@popperjs/core'
import * as React from 'react'
import { useDuffelPopper } from '@lib/hooks'

export const DEFAULT_ON_FOCUS_DELAY = 1000
export const DEFAULT_ON_MOUSE_ENTER_DELAY = 150

export interface TooltipProps {
  /**
   * Text to be displayed in tooltip.
   */
  text: any

  /**
   * Position of tooltip.
   *
   * Possible primary values: `auto`, `top`, `bottom`, `left`, `right`.
   * Each primary value can be optionally appended with `-start` or `-end`.
   *
   * Default: `auto`
   */
  placement: Placement

  /**
   * Alignment of tooltip text.
   *
   * Default: `center`
   */
  tooltipTextAlign?: string

  /**
   * Initial visibility, added primarily for Chromatic snapshots
   *
   * Default: `false`
   */
  initialVisibility?: boolean

  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string

  style?: React.CSSProperties

  // tooltip offset, in a format of [horizontal, vertical]
  customOffset?: [number, number]
}

export const Tooltip: React.FC<React.PropsWithChildren<TooltipProps>> = ({
  children,
  placement,
  text,
  tooltipTextAlign,
  initialVisibility,
  className,
  style,
  customOffset,
}) => {
  let timeoutId: any = null

  const [isVisible, setVisible] = React.useState(initialVisibility || false)

  const onShowTooltip = (timeout: number) => () => {
    timeoutId = setTimeout(() => setVisible(true), timeout)
  }

  const onHideTooltip = () => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    setVisible(false)
    timeoutId = null
  }

  const {
    popper: { styles, attributes },
    setReferenceElement,
    setPopperElement,
    setArrowElement,
  } = useDuffelPopper(isVisible, onHideTooltip, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: customOffset ?? [0, 10],
        },
      },
    ],
  })

  return (
    <>
      <div
        ref={setReferenceElement}
        // Using the 'pointer-events: none' CSS rule will break the 'hover' functionality of the component.
        // Pointer events have been used instead of mouse events due to a React bug.
        // More info: https://github.com/facebook/react/issues/18753
        onPointerEnter={
          text ? onShowTooltip(DEFAULT_ON_MOUSE_ENTER_DELAY) : undefined
        }
        onPointerLeave={text ? onHideTooltip : undefined}
        className={className}
        style={style}
      >
        {children}
      </div>

      {text && isVisible ? (
        <div
          className="tooltip"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <div className="tooltip-message">{text}</div>
          <div
            className="tooltip-arrow"
            ref={setArrowElement}
            style={styles.arrow}
            {...attributes.arrow}
          />
          <style jsx>{`
            .tooltip {
              display: inline-flex;
              justify-content: center;
              color: white !important;
              background-color: var(--grey-900) !important;
              border-radius: var(--border-radius-4);
              padding: 8px 16px;
              max-width: 320px;
              text-transform: none !important;
              font-weight: normal !important;
              z-index: 1;
            }

            .tooltip > :global(.tooltip-message) {
              font-size: 14px !important;
              font-variation-settings: normal !important;
              line-height: 1.4em !important;
              font-family: var(--FONTS-SANS);
              text-align: ${tooltipTextAlign || 'center'};
              letter-spacing: 0 !important;
              white-space: pre-wrap;
            }

            .tooltip-arrow,
            .tooltip-arrow::before {
              position: absolute;
              width: 8px;
              height: 8px;
              background-color: var(--grey-900);
            }

            .tooltip-arrow {
              visibility: hidden;
            }

            .tooltip-arrow::before {
              visibility: visible;
              content: '';
              transform: rotate(45deg);
            }

            .tooltip[data-popper-placement^='top'] > .tooltip-arrow {
              bottom: -4px;
            }

            .tooltip[data-popper-placement^='bottom'] > .tooltip-arrow {
              top: -4px;
            }

            .tooltip[data-popper-placement^='left'] > .tooltip-arrow {
              right: -4px;
            }

            .tooltip[data-popper-placement^='right'] > .tooltip-arrow {
              left: -4px;
            }
          `}</style>
        </div>
      ) : null}
    </>
  )
}
