import classNames from 'classnames'
import * as React from 'react'
import { Button } from '@components/Button'

interface CounterProps {
  id: string
  min: number
  max: number

  value: number
  onChange: (value: number) => void

  size?: 'default' | 'small'
  onTabLast?: (event: React.KeyboardEvent<HTMLButtonElement>) => void
}

export const Counter: React.FC<CounterProps> = ({
  id,
  min,
  max,
  value,
  size = 'default',
  onChange,
  onTabLast,
}) => {
  return (
    <div
      className={classNames('ff-counter', {
        small: size === 'small',
      })}
      id={id}
    >
      <Button
        type="button"
        disabled={value <= min}
        onClick={() => onChange(Math.max(value - 1, min))}
        iconOnly="minus"
        text="Minus"
        id={`${id}-minus`}
      />
      <span className="ff-counter--count-label">{value}</span>
      <Button
        text="Plus"
        iconOnly="add"
        type="button"
        disabled={value >= max}
        onClick={() => onChange(Math.min(value + 1, max))}
        onKeyDown={(event) => {
          if (event.key === 'Tab' && onTabLast) onTabLast(event)
        }}
        id={`${id}-plus`}
      />
      <style jsx>{`
        .ff-counter {
          display: grid;
          grid-template-columns: repeat(3, 32px);
          grid-template-gap: 8px;
          align-items: center;
        }

        .ff-counter--count-label {
          font-size: 21px;
          text-align: center;
          align-self: center;
        }

        .ff-counter :global(.ff-btn) {
          padding: 2px;
        }

        .small .ff-counter--count-label {
          font-size: 16px;
        }
      `}</style>
    </div>
  )
}
