import type { IncomingMessage, ServerResponse } from 'http'
import cookie from 'cookie'
import { getUrl } from '@lib/duffel-client/lib'
import { JwtCookie } from '@lib/types'
import { mergeResponseCookies } from '@lib/proxy/lib/cookie'
import {
  PAYLOAD_COOKIE_NAME,
  SIGNATURE_COOKIE_NAME,
  SESSION_EXPIRY_TIMEOUT_COOKIE_NAME,
} from './constants'

const makeCookie = (
  name: string,
  value: string,
  options?: cookie.CookieSerializeOptions
) => {
  options = Object.assign({}, { path: '/' }, options)
  return cookie.serialize(name, value, options)
}

// Organisations can set a custom session expiry timeout.
// The user might be a member of multiple organisations.
// We need to make sure we use the shortest expiry timeout out of those organisations at all times.
// This is why we're currently storing this separately in a cookie.
const makeTimeoutCookie = (
  sessionExpiryTimeout: number | null,
  domain: string | null,
  expired?: boolean
) => {
  const cookieOptions: cookie.CookieSerializeOptions = {
    secure: true,
    ...(!!domain && { domain }),
    sameSite: 'lax',
    httpOnly: true,
  }

  if (expired || !sessionExpiryTimeout) {
    cookieOptions.expires = new Date()
  } else {
    cookieOptions.maxAge = sessionExpiryTimeout
  }

  return makeCookie(
    SESSION_EXPIRY_TIMEOUT_COOKIE_NAME,
    sessionExpiryTimeout ? String(sessionExpiryTimeout) : '',
    cookieOptions
  )
}

export const makePayloadCookie = (
  payload: string,
  domain: string | null,
  sessionExpiryTimeout: number | null,
  expired?: boolean
) => {
  const cookieOptions: cookie.CookieSerializeOptions = {
    secure: true,
    ...(!!domain && { domain }),
    sameSite: 'lax',
  }

  if (expired || !sessionExpiryTimeout) {
    cookieOptions.expires = new Date()
  } else {
    cookieOptions.maxAge = sessionExpiryTimeout
  }

  return makeCookie(PAYLOAD_COOKIE_NAME, payload, cookieOptions)
}

export function makeSignatureCookie(
  signature: string,
  domain: string | null,
  sessionExpiryTimeout: number | null,
  expired?: boolean
) {
  const cookieOptions: cookie.CookieSerializeOptions = {
    httpOnly: true,
    secure: true,
    ...(!!domain && { domain }),
    sameSite: 'lax',
  }

  if (expired || !sessionExpiryTimeout) {
    cookieOptions.expires = new Date()
  } else {
    cookieOptions.maxAge = sessionExpiryTimeout
  }

  return makeCookie(SIGNATURE_COOKIE_NAME, signature, cookieOptions)
}

/**
 * Get the cookie domain from the request/browser
 * @param {IncomingMessage} - req
 * @param {boolean} - sharedAcrossSubDomains - if we should get/set the cookie with a subdomain or the full domain
 * @example sharedAcrossSubDomains = true: `.duffel.com` // `.staging.duffel.com`
 * @example sharedAcrossSubDomains = false: `.app.duffel.com` // `.app.staging.duffel.com`
 */
export const getCookieDomain = (
  req?: IncomingMessage,
  sharedAcrossSubDomains?: boolean
) => {
  let host = getUrl(req).host

  // .localhost it's not a valid host, so we send as localhost
  if (host.includes('localhost')) {
    return 'localhost'
  }

  if (!host)
    throw Error('getCookieDomain was not able to find a host for this request')

  if (host.match(/:/g)) host = host.slice(0, host.indexOf(':'))

  // staging and preview have more domain parts than production
  let domainPartsLengthToInclude =
    host.includes('staging.duffel.com') || host.includes('preview.duffel.com')
      ? 3
      : 2

  // if we decide to not share across subdomain, the cookie domain has to include the subdomain parts as well
  if (!sharedAcrossSubDomains) {
    domainPartsLengthToInclude++
  }

  return `.${host.split('.').slice(-domainPartsLengthToInclude).join('.')}`
}

export const setJWTCookies = (
  req: IncomingMessage,
  res: ServerResponse,
  { payload, signature }: JwtCookie,
  sessionExpiryTimeout: number
) => {
  if (!payload) console.warn('No payload was passed to setJWTCookies')
  if (!signature) console.warn('No signature was passed to setJWTCookies')

  const domain = getCookieDomain(req, true)
  const payloadCookie = makePayloadCookie(
    payload as string,
    domain,
    sessionExpiryTimeout
  )
  const signatureCookie = makeSignatureCookie(
    signature as string,
    domain,
    sessionExpiryTimeout
  )
  mergeResponseCookies(res, 'Set-Cookie', payloadCookie, signatureCookie)

  if (sessionExpiryTimeout)
    mergeResponseCookies(
      res,
      'Set-Cookie',
      makeTimeoutCookie(sessionExpiryTimeout, domain)
    )
}

export const clearJWTCookies = (req: IncomingMessage, res: ServerResponse) => {
  const domain = getCookieDomain(req, true)

  const payloadCookie = makePayloadCookie('', domain, null, true)
  const signatureCookie = makeSignatureCookie('', domain, null, true)
  mergeResponseCookies(
    res,
    'Set-Cookie',
    payloadCookie,
    signatureCookie,
    makeTimeoutCookie(null, domain)
  )
}

export const getJWTPayload = (cookieHeader: any) => {
  const parsed = cookie.parse(cookieHeader || '')
  return parsed[PAYLOAD_COOKIE_NAME] || null
}

export const getJWTSignature = (cookieHeader: any) => {
  const parsed = cookie.parse(cookieHeader || '')
  return parsed[SIGNATURE_COOKIE_NAME] || null
}

export const parseJWTCookies = (cookieHeader: any): JwtCookie => {
  const parsed = cookie.parse(cookieHeader || '')
  return {
    payload: parsed[PAYLOAD_COOKIE_NAME] || null,
    signature: parsed[SIGNATURE_COOKIE_NAME] || null,
  }
}

// Organisations can set a custom session expiry timeout.
// The user might be a member of multiple organisations.
// We need to make sure we use the shortest expiry timeout out of those organisations at all times.
// This is why we're currently storing this separately in a cookie.
export const parseSessionExpiryTimeoutCookie = (cookieHeader: any): number => {
  const parsed = cookie.parse(cookieHeader || '')
  return +parsed[SESSION_EXPIRY_TIMEOUT_COOKIE_NAME]
}
