import { DuffelAPI } from '@lib/types'
import { PassengerType } from './types'

export const getPassengerType = (
  passenger: DuffelAPI.Inputs.OfferRequestPassenger
): PassengerType => {
  if (passenger.age || passenger.age === 0 || passenger.age === -1) {
    return PassengerType.Child
  }
  // explicitly return 'adult' rather than passenger.type to ensure the function
  // can only return either 'child' or 'adult' and not undefined as by definition,
  // OfferRequestPassenger can only be either a child or an adult
  return PassengerType.Adult
}
