import { DuffelAPI } from '@lib/types'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.Aircraft => ({
    id: `arc_${(seedId++).toString().padStart(5, '0')}`,
    iataCode: '380',
    name: 'Airbus A380',
  })
})()

export const makeMockAircraft = (
  extendDefault?: Partial<DuffelAPI.Types.Aircraft>
): DuffelAPI.Types.Aircraft =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
