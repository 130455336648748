import { noop } from 'lodash'
import * as React from 'react'
import { DuffelAPI } from '@lib/types/DuffelAPI'
import { RandomlyGeneratedPassenger } from '@lib/types/lib'

interface CheckoutContextValue {
  offer: DuffelAPI.Types.Offer
  partialOfferRequest: DuffelAPI.Types.OfferRequest
  seatMaps?: DuffelAPI.Types.SeatMap[]
  randomlyGeneratedPassengers?: RandomlyGeneratedPassenger[]
  paymentButtonClickCount: number
  incrementPaymentButtonClickCount: () => void
  paymentType: string
  setPaymentType: (paymentType: string) => void
}

// TODO: remove the assertions
const CheckoutContext = React.createContext<CheckoutContextValue>({
  offer: {} as DuffelAPI.Types.Offer,
  partialOfferRequest: {} as DuffelAPI.Types.OfferRequest,
  paymentButtonClickCount: 0,
  incrementPaymentButtonClickCount: noop,
  paymentType: 'instant',
  setPaymentType: () => noop,
})

export const CheckoutContextProvider = CheckoutContext.Provider
export const useCheckoutContext = () => React.useContext(CheckoutContext)
