import { DuffelAPI } from '@lib/types'

/** @deprecated */
export const getSegmentDates = (
  segment: DuffelAPI.Types.OfferSliceSegment | DuffelAPI.Types.OrderSliceSegment
) => {
  // Why do we have `as any` here?
  // The API should not be returning arrivalDatetime or departureDatetime in v2, but it does.
  // This function will continue to behave as it did before to have a fallback in case that case shows up.
  const arrivingAt = segment.arrivingAt || (segment as any).arrivalDatetime
  const departingAt = segment.departingAt || (segment as any).departureDatetime
  return { arrivingAt, departingAt }
}
