// https://swr.vercel.app/docs/middleware#keep-previous-result

/* eslint-disable react-hooks/rules-of-hooks */
import * as React from 'react'

// This is a SWR middleware for keeping the data even if key changes.
export const usePreviousResult = (useSWRNext) => {
  return (key, fetcher, config) => {
    // Use a ref to store previous returned data.
    const cachedDataRef = React.useRef()

    // Actual SWR hook.
    const swr = useSWRNext(key, fetcher, config)

    React.useEffect(() => {
      // Update ref if data is not undefined.
      if (swr.data !== undefined) {
        cachedDataRef.current = swr.data
      }
    }, [swr.data])

    // Expose a method to clear the laggy data, if any.
    const resetCache = React.useCallback(() => {
      cachedDataRef.current = undefined
    }, [])

    // Fallback to previous data if the current data is undefined.
    const cached = swr.data === undefined ? cachedDataRef.current : swr.data

    // Is it showing previous data?
    const isLagging =
      swr.data === undefined && cachedDataRef.current !== undefined

    // Also add a `isLagging` field to SWR.
    return Object.assign({}, swr, {
      data: cached,
      isLagging,
      resetCache,
    })
  }
}
