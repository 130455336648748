import omit from 'lodash/omit'
import { useRouter } from 'next/router'
import { Button } from '@components/Button'
import { NonIdealState } from '@components/NonIdealState'
import {
  flightSearchV2PartialResultPathArray,
  PostSearchFilters,
} from '@lib/paths/org-mode-paths'
import { useWorkspace } from '@lib/workspace-context'
import { isDefaultSearchTimeRanges } from '@modules/air-search-v2/forms/SearchForm/lib/search-time-ranges'
import { SearchFormValues } from '@modules/air-search-v2/lib'
import { saveSearchFormValues } from '@modules/air-search-v2/lib/multi-step-search/search-params'

const hasPreSearchFilters = (formValues: SearchFormValues): boolean =>
  // if airlines are selected, or
  formValues.airlines.length > 0 ||
  // if timeRanges are selected
  (formValues.type === 'one_way' &&
    !isDefaultSearchTimeRanges(formValues.timeRanges)) ||
  (formValues.type === 'return' &&
    ![formValues.departureTimeRanges, formValues.returnTimeRanges].every(
      isDefaultSearchTimeRanges
    )) ||
  (formValues.type === 'multi_city' &&
    !formValues.slices
      .map((slice) => slice.timeRanges)
      .every(isDefaultSearchTimeRanges))

const hasNonDefaultPostSearchFilters = (
  postSearchFilters: PostSearchFilters | undefined
) => {
  if (!postSearchFilters) {
    return false
  }

  if (isDefaultSearchTimeRanges(postSearchFilters.timeRanges)) {
    return true
  }

  return Boolean(
    postSearchFilters.airlineIataCode?.length ||
      postSearchFilters.flightNumber?.length
  )
}

const clearPreSearchFiltersFormValues = (oldFormValues: SearchFormValues) => {
  const formValues = { ...oldFormValues }

  // clear time ranges
  if (formValues.type === 'one_way') {
    delete formValues.timeRanges
  } else if (formValues.type === 'return') {
    delete formValues.departureTimeRanges
    delete formValues.returnTimeRanges
  } else {
    formValues.slices = formValues.slices.map((slice) => ({
      ...omit(slice, 'timeRanges'),
    }))
  }

  // clear airlines
  formValues.airlines = []

  return formValues
}

interface NoSearchResultsProps {
  formValues: SearchFormValues
  postSearchFilters?: PostSearchFilters
  onEditSearch: () => void
  onClearPostSearchFilters: () => void
}

export const NoSearchResults: React.FC<NoSearchResultsProps> = ({
  formValues,
  postSearchFilters,
  onEditSearch,
  onClearPostSearchFilters,
}) => {
  const router = useRouter()
  const { permissions } = useWorkspace()

  // if there's a filter, show the action to clear them
  if (
    hasPreSearchFilters(formValues) ||
    hasNonDefaultPostSearchFilters(postSearchFilters)
  ) {
    return (
      <NonIdealState
        title={'No flights matching your search'}
        description={'Try clearing your filters to see results'}
      >
        <Button
          text={'Clear filters'}
          onClick={async () => {
            // if there is a pre-search filter, always try to clear them
            if (hasPreSearchFilters(formValues)) {
              const clearedFormValues =
                clearPreSearchFiltersFormValues(formValues)
              const searchParamsId = await saveSearchFormValues(
                clearedFormValues
              )
              await router.push(
                ...flightSearchV2PartialResultPathArray(
                  permissions?.organisation,
                  permissions?.liveMode,
                  searchParamsId,
                  undefined
                )
              )
              return
            }

            // otherwise, just clear the post-search filters
            onClearPostSearchFilters()
          }}
        />
      </NonIdealState>
    )
  }

  // otherwise, prompt the user to edit the search
  return (
    <NonIdealState
      title={'No flights matching your search'}
      description={
        'Try changing your dates or origin/destination to see results'
      }
    >
      <Button text={'Edit search'} onClick={onEditSearch} />
    </NonIdealState>
  )
}
