import * as React from 'react'
import { PopoverContainer } from '@components/PopoverContainer'
import { useDuffelPopper } from '@lib/hooks'
import { trackEvent } from '@lib/tracking'
import { FilterDetails } from './FilterDetails'
import { FilterDetailsContainer } from './FilterDetailsContainer'
import { FilterDropdown } from './FilterDropdown'
import { FiltersAction } from './lib/actions'
import { getFilterNames, validateFilterValue } from './lib/helpers'
import { FiltersState } from './lib/state'

interface FilterPopoverContainerProps {
  filtersState: FiltersState
  filtersDispatch: React.Dispatch<FiltersAction>
  mode: { type: 'add' } | { type: 'edit'; editIndex: number }
  hideFilterMenuHeader?: boolean
  children: (
    setReferenceElement: React.Dispatch<
      React.SetStateAction<HTMLElement | null>
    >
  ) => React.ReactElement
}

export const FilterPopoverContainer: React.FC<FilterPopoverContainerProps> = ({
  filtersState,
  filtersDispatch,
  children,
  mode,
  hideFilterMenuHeader,
}) => {
  const { popper, setReferenceElement, setPopperElement } = useDuffelPopper(
    ['dropdownOpen', 'addingFilter', 'editingFilter'].includes(
      filtersState.state
    ),
    () => filtersDispatch({ type: 'close' }),
    {
      placement: 'bottom-start',
      modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
    },
    { shouldInsideClickClose: false }
  )

  return (
    <>
      {children(setReferenceElement)}
      {filtersState.state === 'dropdownOpen' && mode.type === 'add' && (
        <PopoverContainer
          ref={setPopperElement}
          style={{
            zIndex: 50,
            ...popper.styles.popper,
            padding: 0,
          }}
          {...popper.attributes}
        >
          <button onClick={(event) => event.stopPropagation()}>
            <FilterDropdown
              onFilterSelect={(filterType) =>
                filtersDispatch({ type: 'selectFilterTypeToAdd', filterType })
              }
              filters={filtersState.availableFilters}
              hideHeader={hideFilterMenuHeader}
            />
          </button>
        </PopoverContainer>
      )}
      {((filtersState.state === 'addingFilter' && mode.type === 'add') ||
        (filtersState.state === 'editingFilter' &&
          mode.type === 'edit' &&
          mode.editIndex === filtersState.editIndex)) && (
        <PopoverContainer
          ref={setPopperElement}
          style={{
            zIndex: 50,
            ...popper.styles.popper,
            padding: 0,
          }}
          {...popper.attributes}
        >
          <button onClick={(event) => event.stopPropagation()}>
            <FilterDetailsContainer
              filter={filtersState.pendingFilter.type}
              canApply={validateFilterValue(filtersState.pendingFilter).isValid}
              onApplyButtonPressed={() => {
                trackEvent('dashboard_filter_applied', {
                  event_type: 'interaction',
                  filter: getFilterNames(filtersState.pendingFilter.type),
                })
                filtersDispatch({ type: 'applyFilter' })
              }}
              onBackButtonPressed={() => {
                filtersDispatch({ type: 'goBack' })
              }}
            >
              <FilterDetails
                value={filtersState.pendingFilter}
                onChange={(filter) =>
                  filtersDispatch({
                    type: 'updatePendingFilter',
                    pendingFilter: filter,
                  })
                }
              />
            </FilterDetailsContainer>
          </button>
        </PopoverContainer>
      )}
    </>
  )
}
