import { DuffelAPI } from '@lib/types'
import { makeMockOrderDocument } from './make-mock-order-document'

export const makeMockOrderDocuments = (): DuffelAPI.Types.OrderDocuments => [
  makeMockOrderDocument(),
  makeMockOrderDocument({
    type: 'electronic_miscellaneous_document_associated',
  }),
  makeMockOrderDocument({
    type: 'electronic_miscellaneous_document_standalone',
  }),
]
