import { object, string } from 'yup'
import { validEmailAddressRequired } from '@lib/form-validation'
import { ContactSupportCategory } from '@lib/zendesk'
import { DuffelAPI } from '@lib/types'
import { getDefaultContactSupportEmail } from '@lib/helpers/get-default-contact-support-email'

const defaultCategoryPerPath: { [key: string]: ContactSupportCategory } = {
  '/[org]/settings': 'legal',
  '/[org]/activation/email': 'activation',
  '/[org]/activation/plan': 'activation',
  '/[org]/activation/business': 'legal',
  '/[org]/activation/review': 'activation',
  '/[org]/activation/card': 'wallet',
  '/[org]/activation/done': 'activation',
  '/[org]/[mode]/wallet': 'wallet',
  '/[org]/[mode]/balance': 'wallet',
  '/[org]/[mode]/orders/[orderId]': 'cancellation-refund',
}

export interface SupportFormValues {
  message: string
  email: string
  category: ContactSupportCategory
  uploads?: string[]
}

export const getSupportFormInitialValues = (
  path: string,
  userEmail?: DuffelAPI.Types.User['email'],
  organisationContactEmails?: DuffelAPI.Types.Organisation['contactEmails'],
  overrideDefaultCategoryPerPath?: ContactSupportCategory,
  initialMessage?: string
) => ({
  category:
    overrideDefaultCategoryPerPath ||
    defaultCategoryPerPath[path] ||
    'dashboard-other',
  email: getDefaultContactSupportEmail(userEmail, organisationContactEmails),
  message: initialMessage || '',
})

export const getSupportFormValidationSchema = () =>
  object().shape({
    email: validEmailAddressRequired(),
    message: string().required('Message is required'),
    category: string().required(),
  })
