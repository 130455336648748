import React from 'react'
import { FormikInput, FormikSelect } from '@components/Form'
import { PassengerTitleMap } from '@lib/types'

interface PassengerInfoNameOptionalFields {
  title?: boolean
  givenName?: boolean
  familyName?: boolean
}

export interface PassengerInfoNameContainerProps {
  /**
   * The index correlates to the position this specific passenger is on the passengers array
   */
  index: number

  /**
   * What/where this component is being used? This will slightly modify the layout and styling
   */
  intent?: 'loyalty-programmes'

  /**
   * Excludes the Title select field
   * Default: false
   */
  excludeTitle?: boolean

  /**
   * Defines which fields in the component are optional
   * Can be set to "all" or specific fields by setting as an object
   * Otherwise, will default all fields to optional
   */
  optionalFields?: 'all' | PassengerInfoNameOptionalFields

  /**
   * onBlur handler for name inputs to update PassengerLoyaltyCodesFormHeader
   */
  handleNameInputsBlur?: (e?: React.FocusEvent) => void
}

export const PassengerInfoNameContainer: React.FC<
  PassengerInfoNameContainerProps
> = ({
  index,
  intent,
  excludeTitle: isTitleExcluded = false,
  optionalFields,
  handleNameInputsBlur,
}) => {
  const isTitleSelectOptional =
    optionalFields === 'all' || optionalFields?.title

  const isGivenNameInputOptional =
    optionalFields === 'all' || optionalFields?.givenName

  const isFamilyNameInputOptional =
    optionalFields === 'all' || optionalFields?.familyName

  return (
    <div
      className="passenger-info-name-container"
      data-testid="passenger-info-name-container"
    >
      {!isTitleExcluded && (
        <FormikSelect
          label="Title"
          name={`passengers.${index}.title`}
          optionalField={isTitleSelectOptional}
        >
          <option value="--" hidden>
            --
          </option>
          {Object.entries(PassengerTitleMap).map(([value, label]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </FormikSelect>
      )}
      <FormikInput
        label="Given name"
        name={`passengers.${index}.givenName`}
        optionalField={isGivenNameInputOptional}
        onBlur={handleNameInputsBlur}
      />
      <FormikInput
        label="Family name"
        name={`passengers.${index}.familyName`}
        optionalField={isFamilyNameInputOptional}
        onBlur={handleNameInputsBlur}
      />
      <style jsx>{`
        .passenger-info-name-container {
          display: grid;
          grid-column-gap: var(--space-16);
        }
      `}</style>
      <style jsx>{`
        .passenger-info-name-container {
          grid-template-columns: ${isTitleExcluded
            ? 'repeat(2, 1fr)'
            : '2fr 3fr 3fr'};
          margin-bottom: ${intent === 'loyalty-programmes'
            ? 0
            : 'var(--space-24)'};
          ${intent === 'loyalty-programmes' ? 'padding: var(--space-20);' : ''}
        }
      `}</style>
    </div>
  )
}
