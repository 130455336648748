import crypto from 'crypto'
import { ENCRYPTION_KEY } from '../env'

export const encrypt = (str: string): string => {
  const iv = crypto.randomBytes(16)
  // eslint-disable-next-line
  const cipher = crypto.createCipheriv(
    'aes-256-cbc',
    Buffer.from(ENCRYPTION_KEY),
    iv
  )
  let encrypted = cipher.update(str)

  encrypted = Buffer.concat([encrypted, cipher.final()])

  return iv.toString('hex') + ':' + encrypted.toString('hex')
}

export const decrypt = (str: string): string => {
  const textParts = str.split(':') as string[]
  const ivHex = textParts.shift()

  if (!ivHex)
    throw Error(
      'the string passed to decrypt does not seem to have the correct format'
    )

  const iv = Buffer.from(ivHex, 'hex')
  const encryptedText = Buffer.from(textParts.join(':'), 'hex')
  // eslint-disable-next-line
  const decipher = crypto.createDecipheriv(
    'aes-256-cbc',
    Buffer.from(ENCRYPTION_KEY),
    iv
  )

  let decrypted = decipher.update(encryptedText)
  decrypted = Buffer.concat([decrypted, decipher.final()])
  return decrypted.toString()
}
