import * as React from 'react'
import classNames from 'classnames'
import { makeMonthInputId } from './lib'
import styles from './DatePickerInput.module.css'

interface DatePickerMonthInputProps {
  id: string
  value: string
  onBlur?: (e: React.FocusEvent) => void
  onChange: (value: string) => void
  onTab: (value: string) => void
  onFocus: () => void
}

const isValidMonthValue = (value: number) => {
  return !isNaN(value) && 0 <= value && value <= 12
}

export const DatePickerMonthInput: React.FC<DatePickerMonthInputProps> = ({
  id,
  value,
  onBlur,
  onChange,
  onTab,
  onFocus,
}) => {
  const completeId = makeMonthInputId(id)
  return (
    <>
      <input
        id={completeId}
        data-testid={completeId}
        value={value}
        className={classNames(
          styles['date-picker-input'],
          styles['date-picker-input--month']
        )}
        placeholder="MM"
        onFocus={(event) => {
          event.target.select()
          onFocus()
        }}
        onBlur={onBlur}
        onChange={({ target: { value } }) => {
          if (value === '') onChange(value)
          else if (isValidMonthValue(+value)) onChange(value)
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Tab' || event.key === 'Enter')
            onTab((event.target as HTMLInputElement).value)
          if (event.key === 'Enter') event.preventDefault()
        }}
      />
    </>
  )
}
