import { startCase } from 'lodash'
import * as React from 'react'
import { Stamp, StampProps } from '@components/Stamp'
import { LayoverDetails, SliceDetailItemChangeStatus } from '@lib/types'
import styles from './SliceDetailsLayoverItem.module.css'

export interface SliceDetailsLayoverItemProps {
  layoverDetails: LayoverDetails
  changeStatus?: SliceDetailItemChangeStatus
  className?: string
}

const getStampColor = (
  changeStatus?: SliceDetailItemChangeStatus
): StampProps['color'] => {
  if (changeStatus === 'changed') return 'yellow'
  if (changeStatus === 'added') return 'green'
  if (changeStatus === 'removed') return 'pink'
  return 'grey'
}

const getLayoverString = (layoverDetails: LayoverDetails) => {
  if (!layoverDetails) return 'Missing layover details'
  const layoverString = `${layoverDetails.duration} layover at ${startCase(
    layoverDetails.airport.name
  )} (${layoverDetails.airport.iataCode})`

  return layoverString
}

export const SliceDetailsLayoverItem: React.FC<
  SliceDetailsLayoverItemProps
> = ({ layoverDetails, changeStatus, className }) => {
  const isNegativeDuration = layoverDetails?.duration.charAt(0) === '-'
  return (
    <div className={styles['layover-item']}>
      <Stamp
        color={isNegativeDuration ? 'yellow' : getStampColor(changeStatus)}
        icon={isNegativeDuration ? 'warning' : undefined}
        label={getLayoverString(layoverDetails)}
        borderRadius="full"
        className={className}
      />
    </div>
  )
}
