import { captureException } from '@lib/sentry'
import { iso8601DurationRegex } from './constants'

export const convertDurationToString = (duration: string): string => {
  let matches
  try {
    matches = duration.match(iso8601DurationRegex)
  } catch (err) {
    captureException(new Error(`The value ${duration} it's not a string`))
    return duration
  }
  if (!matches) return duration

  const daysString = matches[1] && matches[1] !== '0' ? `${matches[1]}d` : ''
  const hoursString =
    matches[2] && matches[2] !== '0'
      ? `${matches[2].toString().padStart(2, '0')}h`
      : ''
  const minutesString =
    matches[3] && matches[3] !== '0'
      ? `${matches[3].toString().padStart(2, '0')}m`
      : ''

  return `${daysString} ${hoursString} ${minutesString}`.trim()
}
