import { DuffelAPI } from '@lib/types'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.City => ({
    id: `cit_${(seedId++).toString().padStart(5, '0')}`,
    iataCode: 'LON',
    iataCountryCode: 'GB',
    name: 'London',
    type: 'city',
  })
})()

export const makeMockCity = (
  extendDefault?: Partial<DuffelAPI.Types.City>
): DuffelAPI.Types.City =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
