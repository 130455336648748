import * as React from 'react'
import classnames from 'classnames'

import { Text } from '@components/legacy-design-system/product/components/Text'
import { TypeStyle } from '@components/legacy-design-system/product/styles'
import styles from './InvoiceCell.module.css'

export interface InvoiceCellProps {
  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string
  /**
   * The number of columns the cell should span
   */
  colSpan?: number
  /**
   * Should the column fill as much space as possible. Only one column is able to grow per invoice.
   */
  grow?: boolean
  /**
   * Should the font be bold?
   */
  isBold?: boolean
  /**
   * Is this a header cell?
   */
  isHeaderCell?: boolean
  /**
   * Is this a footer cell?
   */
  isFooterCell?: boolean
  /**
   * How should the text be aligned?
   */
  textAlign?: 'left' | 'center' | 'right'

  /**
   * excluding left and right padding
   */
  noHorizontalPadding?: boolean

  isMono?: boolean
}

/** An individual cell within an Invoice */
export const InvoiceCell: React.FC<
  React.PropsWithChildren<InvoiceCellProps>
> = ({
  className,
  colSpan,
  children,
  grow,
  isBold,
  isFooterCell,
  isHeaderCell,
  textAlign = 'left',
  noHorizontalPadding = false,
  isMono = false,
}) => {
  const cn = classnames(
    styles['container'],
    {
      [styles['header']]: isHeaderCell,
      // this "invoice-cell--header" class is being reached in from outside the component
      // in Transactions so we are keeping it for now to avoid having to update
      // that file too.
      'invoice-cell--header': isHeaderCell,
      [styles['footer']]: isFooterCell,
      [styles['grow']]: grow,
      [styles['no-horizontal-padding']]: noHorizontalPadding,
    },
    styles[`align-${textAlign}`],
    className
  )

  const CellTag = isHeaderCell ? 'th' : 'td'
  let typeStyle: TypeStyle = 'paragraph2'
  if (isMono) typeStyle = 'mono1'
  else if (isHeaderCell) typeStyle = 'paragraph2'

  return (
    <CellTag className={cn} colSpan={colSpan}>
      <Text
        asElement="span"
        typeStyle={typeStyle}
        color={isHeaderCell ? 'grey-900' : 'grey-700'}
        fontWeight={isBold || isFooterCell ? 'bold' : 'normal'}
        className={classnames(
          isHeaderCell && styles['header-text'],
          isMono && styles['mono-text']
        )}
      >
        {children}
      </Text>
    </CellTag>
  )
}
