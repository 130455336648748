import { useRouter } from 'next/router'
import * as React from 'react'
import { Head } from '@components/Head'
import { Heading } from '@components/Heading'
import { Mark } from '@components/Mark'
import { VSpace } from '@components/VSpace'

export interface ErrorViewProps {
  title?: string
  subtitle?: string
  errorCode?: string
}

function getErrorTitle(errorCode: string) {
  switch (errorCode) {
    case 'not_found':
    case 'invalid_email_confirmation_token':
      return 'Resource not found'
    case 'user_already_confirmed':
      return 'Email already confirmed'
    case 'email_confirmation_token_expired':
      return 'Link expired'
    default:
      return 'Something went wrong'
  }
}

function getErrorDescription(errorCode: string) {
  switch (errorCode) {
    case 'not_found':
    case 'invalid_email_confirmation_token':
      return "Unfortunately, we couldn't find that resource. Please try again!"
    case 'user_already_confirmed':
      return 'This email has already been confirmed. Please sign in.'
    case 'email_confirmation_token_expired':
      return 'Email confirmation links expire after 24 hours.'
    default:
      return 'Something went wrong'
  }
}

export const ErrorView: React.FunctionComponent<ErrorViewProps> = ({
  title,
  subtitle,
  errorCode,
}) => {
  const getTitle = errorCode ? getErrorTitle(errorCode) : title || 'Error'
  const getSubTitle = errorCode
    ? getErrorDescription(errorCode)
    : subtitle || 'An unknown error has occurred'
  const { back, push } = useRouter()

  return (
    <div className="error-view" data-selector="fs-show">
      <Head title={getTitle} />
      <div className="error-view__inner">
        <div className="error-view__header">
          <Mark />
        </div>

        <div className="error-view__content">
          <VSpace space={16}>
            <Heading h2 textAlign="center" fontWeight="medium">
              {getTitle}
            </Heading>
            <p className="error-view-description">{getSubTitle}</p>
          </VSpace>
        </div>

        <div className="error-view__footer">
          <button
            onClick={
              errorCode === 'email_confirmation_token_expired'
                ? () => push('/confirm-email')
                : back
            }
          >
            Go back
          </button>
        </div>
      </div>

      <style jsx>{`
        .error-view {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 100vh;
          padding: 72px 24px;
        }

        .error-view__inner {
          max-width: 400px;
          width: 100%;
        }

        .error-view-title {
          font-family: var(--FONTS-SANS);
          font-weight: 600;
          text-align: center;
          margin-top: 0;
          margin-bottom: 16px;
          line-height: 1.2;
        }

        .error-view-description {
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          margin: 0;
        }

        .error-view__header {
          text-align: center;
          margin-bottom: 24px;
        }

        .error-view__header > :global(.logo) {
          margin-left: auto;
          margin-right: auto;
        }

        .error-view__footer {
          margin-top: 32px;
          text-align: center;
        }

        .error-view__footer button {
          display: inline-flex;
          align-items: center;
          height: 40px;
          font-size: 16px;
          padding: 2px 16px 0;
          line-height: 1;
          text-decoration: none;
          color: inherit;
          outline: none;
          padding-bottom: 2px;
          background-color: var(--grey-200);
          border-radius: var(--border-radius-4);
          cursor: pointer;
        }

        .error-view__footer button:hover,
        .error-view__footer button:focus {
          color: var(--white);
          background-color: var(--purple-500);
        }
      `}</style>
    </div>
  )
}
