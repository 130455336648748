import React from 'react'
import { HSpace } from '@components/HSpace'
import { Icon } from '@components/Icon'
import { Text } from '@components/Text'
import { AirlineLogo } from '@components/AirlineLogo'
import { AirlinesContext } from '@modules/air-order/lib/airlines-context'
import { DateFilterCondition, FilterValue } from './lib/types'
import { Range } from '@components/RangeSlider/RangeSlider'

import styles from './FilterTag.module.css'
import { getFilterNames } from './lib/helpers'
import { getRangeLabel } from '@modules/air-search-v2/components/SearchTimeFilters/TimeRangeSelector'

export interface FilterTagProps {
  filter: FilterValue
  onClick(): void
  onRemove(): void
}

const renderRelationship = (
  noun: string,
  verb: string,
  object: string,
  otherObject?: string
) => (
  <Text fontSize="C2" asElement="span">
    {noun}{' '}
    <Text fontSize="C2" asElement="span" color="grey-600">
      {verb}
    </Text>{' '}
    {object}
    {otherObject && (
      <>
        <Text fontSize="C2" asElement="span" color="grey-600">
          {' '}
          and
        </Text>{' '}
        {otherObject}
      </>
    )}
  </Text>
)

const renderDateFilterCondition = (
  label: string,
  condition: DateFilterCondition
) => {
  switch (condition.condition) {
    case 'before':
    case 'after':
      return (
        <Text fontSize="C2" asElement="span">
          {label}{' '}
          <Text fontSize="C2" asElement="span" color="grey-600">
            {condition.condition}
          </Text>{' '}
          <time
            dateTime={condition.date.toLocaleDateString()}
            suppressHydrationWarning
          >
            {condition.date.toLocaleDateString()}
          </time>
        </Text>
      )

    case 'between':
      return (
        <Text fontSize="C2" asElement="span">
          {label}{' '}
          <Text fontSize="C2" asElement="span" color="grey-600">
            {condition.condition}
          </Text>{' '}
          <time
            dateTime={condition.date1.toLocaleDateString()}
            suppressHydrationWarning
          >
            {condition.date1.toLocaleDateString()}
          </time>
          <Text fontSize="C2" asElement="span" color="grey-600">
            {' '}
            and
          </Text>{' '}
          <time
            dateTime={condition.date2.toLocaleDateString()}
            suppressHydrationWarning
          >
            {condition.date2.toLocaleDateString()}
          </time>
        </Text>
      )
  }
}

const renderTimeFilterCondition = (label: string, range: Range) => (
  <Text fontSize="C2" asElement="span" color="grey-600">
    {label}{' '}
    <Text fontSize="C2" asElement="span">
      {getRangeLabel(range)}
    </Text>
  </Text>
)

const FilterTagContent: React.FC<{ filter: FilterValue }> = ({ filter }) => {
  const airlines = React.useContext(AirlinesContext)

  switch (filter.type) {
    case 'passengerName':
      return renderRelationship(
        getFilterNames(filter.type),
        'matches',
        filter.value
      )
    case 'departingAt':
      return renderDateFilterCondition('Departing', filter.value)
    case 'arrivingAt':
      return renderDateFilterCondition('Arriving', filter.value)
    case 'createdAt':
      return renderDateFilterCondition('Created', filter.value)
    case 'departingAtTime':
      return renderTimeFilterCondition('Take-off', filter.value)
    case 'arrivingAtTime':
      return renderTimeFilterCondition('Landing', filter.value)
    // While we can technically use the airport lookup API to grab the iata code, it would complicate
    // the component rendering quite a bit as we would need to add a loading state and stuff.
    // Since we know that the airport id format will always have the iatacode in the middle, we can extract the
    // code from there directly.
    case 'originId':
      return renderRelationship(
        'Origin',
        'is',
        filter.value.split('_')[1].toUpperCase()
      )
    case 'destinationId':
      return renderRelationship(
        'Destination',
        'is',
        filter.value.split('_')[1].toUpperCase()
      )

    case 'airlineId': {
      const airline = airlines.find((airline) => airline.id === filter.value)
      return (
        <HSpace space={4} alignCenter>
          <Text fontSize="C2" asElement="span">
            Airline
          </Text>
          <Text fontSize="C2" asElement="span" color="grey-600">
            is
          </Text>
          {airline?.iataCode && (
            <AirlineLogo
              size={16}
              name={airline.name}
              iataCode={airline.iataCode}
            />
          )}
          <Text fontSize="C2" asElement="span">
            {airline?.name ?? filter.value}
          </Text>
        </HSpace>
      )
    }
    case 'maxConnections': {
      return renderRelationship('Number of stops:', '', filter.value)
    }
    case 'offerSorting':
    case 'orderChangeSorting': {
      return renderRelationship('Sort', 'by', `${filter.value} first`)
    }
    case 'webhookEventType': {
      return renderRelationship(
        getFilterNames(filter.type),
        'matches',
        filter.value
      )
    }
  }

  switch (filter.type.type) {
    case 'options':
      return renderRelationship(filter.type.name, 'is', filter.value)
  }
}

export const FilterTag = React.forwardRef<HTMLButtonElement, FilterTagProps>(
  ({ filter, onClick, onRemove }, ref) => (
    <HSpace alignCenter space={12} className={styles['tag']}>
      <button onClick={onClick} ref={ref}>
        <FilterTagContent filter={filter} />
      </button>
      <button
        className={styles['remove-button']}
        type="button"
        onClick={(event) => {
          event.stopPropagation()
          onRemove()
        }}
        aria-label="Remove filter"
      >
        <Icon name="close" size={18} />
      </button>
    </HSpace>
  )
)
