import classNames from 'classnames'
import * as React from 'react'
import { ColorWithoutWeight } from '@components/legacy-design-system/product/styles'
import { VSpace } from '@components/VSpace'
import { Icon } from '@components/Icon'
import { HSpace } from '@components/HSpace'
import { getDateString, getTimeString } from '@lib/date'
import { SliceDetailItemChangeStatus, TravelDetails } from '@lib/types'
import { getHighlightStyles } from '../lib'
import styles from './SliceDetailsTravelItem.module.css'

export interface SliceDetailsTravelItemProps {
  changeStatus?: SliceDetailItemChangeStatus
  travelDetails: TravelDetails<'offer' | 'order'>
  highlightAll?: boolean
  keysToHighlight?: string[]
  highlightColor?: ColorWithoutWeight
}

export const getTerminalString = (terminal?: string | null) => {
  if (!terminal) return ''
  return terminal.toLowerCase().includes('terminal')
    ? `, ${terminal}`
    : `, Terminal ${terminal}`
}

export const SliceDetailsTravelItem: React.FC<SliceDetailsTravelItemProps> = ({
  changeStatus,
  travelDetails,
  highlightAll = false,
  keysToHighlight,
  highlightColor,
}) => {
  const origin = travelDetails.origin
  const destination = travelDetails?.destination

  const circleClasses = classNames(
    [styles['circle']],
    [changeStatus && styles[`circle--${changeStatus}`]]
  )

  const itineraryClasses = classNames(
    [styles['itinerary-top']],
    [changeStatus && styles[`itinerary-top--${changeStatus}`]]
  )

  const highlightStyles = (key?: string) => {
    return getHighlightStyles(
      styles,
      highlightAll,
      key,
      keysToHighlight,
      highlightColor
    )
  }

  return (
    <div>
      <div className={styles['itinerary-container']}>
        <VSpace space={16}>
          <div className={itineraryClasses}>
            <div className={styles['itinerary-node-container']}>
              <div className={circleClasses} />
              <div className={styles['itinerary-node-text']}>
                <span {...highlightStyles('departingAtDate')}>
                  <span>
                    {getDateString(travelDetails.departingAt, 'long')}
                  </span>
                  {', '}
                  <span {...highlightStyles('departingAtTime')}>
                    {getTimeString(travelDetails.departingAt)}
                  </span>
                </span>
                <span {...highlightStyles()}>
                  Depart from {origin.name}&nbsp;({origin.iataCode})
                  {getTerminalString(travelDetails.originTerminal)}
                </span>
              </div>
            </div>
          </div>

          {travelDetails.flightDuration ? (
            <div className={styles['itinerary-duration']}>
              <div {...highlightStyles()}>
                Flight duration:{' '}
                <span {...highlightStyles('flightDuration')}>
                  {travelDetails.flightDuration}
                </span>
              </div>
            </div>
          ) : (
            <div>
              <br />
            </div>
          )}

          <div className={styles['itinerary-bottom']}>
            <div className={styles['itinerary-node-container']}>
              <div className={circleClasses} />
              <div className={styles['itinerary-node-text']}>
                <span {...highlightStyles('arrivingAtDate')}>
                  <span>{getDateString(travelDetails.arrivingAt, 'long')}</span>
                  {', '}
                  <span {...highlightStyles('arrivingAtTime')}>
                    {getTimeString(travelDetails.arrivingAt)}
                  </span>
                </span>
                <span {...highlightStyles()}>
                  Arrive at {destination.name}&nbsp;({destination.iataCode})
                  {getTerminalString(travelDetails.destinationTerminal)}
                </span>
              </div>
            </div>
          </div>
        </VSpace>
      </div>
      <div className={styles['travel-item-detail']}>
        {travelDetails.cabinClass && (
          <div {...highlightStyles('cabinClass')}>
            {travelDetails.cabinClass}
          </div>
        )}
        {(travelDetails.operatedBy || travelDetails.airline.name) && (
          <div {...highlightStyles('operatedBy')}>
            {!travelDetails.operatedBy ||
            travelDetails.airline.name === travelDetails.operatedBy
              ? travelDetails.airline.name
              : `Operated by ${travelDetails.operatedBy}`}
          </div>
        )}
        {travelDetails.aircraft && (
          <div {...highlightStyles('aircraft')}>{travelDetails.aircraft}</div>
        )}
        {travelDetails.flight && (
          <div {...highlightStyles('flight')}>{travelDetails.flight}</div>
        )}
        {travelDetails.baggagesIncluded?.carryOn &&
        travelDetails.baggagesIncluded.carryOn > 0 ? (
          <HSpace space={4} alignCenter {...highlightStyles('carryOn')}>
            <Icon name="cabin_bag" size={14} />
            <div>
              {travelDetails.baggagesIncluded.carryOn} carry-on bag
              {travelDetails.baggagesIncluded.carryOn > 1 && 's'}
            </div>
          </HSpace>
        ) : null}
        {travelDetails.baggagesIncluded?.checked &&
        travelDetails.baggagesIncluded.checked > 0 ? (
          <HSpace space={4} alignCenter {...highlightStyles('checked')}>
            <Icon name="checked_bag" size={14} />
            <div>
              {travelDetails.baggagesIncluded.checked} checked bag
              {travelDetails.baggagesIncluded.checked > 1 && 's'}
            </div>
          </HSpace>
        ) : null}
      </div>
    </div>
  )
}
