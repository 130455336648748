import { convertDurationToString, getDurationString } from '@lib/date'
import { iso8601DurationRegex } from '@lib/date/constants'
import { DuffelAPI, SliceDetails, SliceItem, TravelDetails } from '@lib/types'
import { getFareBrandName } from './get-fare-brand-name'
import { getLayoverOriginDestinationKey } from './get-layover-origin-destination-key'
import { getSegmentDates } from './get-segment-dates'
import { getSegmentFlightNumber } from './get-segment-flight-number'
import { Slice, Segment } from './types'

export const getTravelItem = (
  fromSegment: Segment,
  fareBrandName: string | null
): TravelDetails<'order' | 'offer'> => {
  const { origin, destination } = fromSegment
  const { arrivingAt, departingAt } = getSegmentDates(fromSegment)

  return {
    originDestination: `${origin.iataCode}-${destination.iataCode}`,
    arrivingAt: arrivingAt!,
    departingAt: departingAt!,
    origin,
    destination,
    fareBrandName: getFareBrandName(fareBrandName, fromSegment),
    cabinClass:
      fromSegment.passengers?.length > 0
        ? fromSegment.passengers[0].cabinClassMarketingName ||
          fromSegment.passengers[0].cabinClass
        : '',
    flight: getSegmentFlightNumber(fromSegment),
    aircraft: fromSegment.aircraft?.name,
    flightDuration:
      fromSegment.duration &&
      typeof fromSegment.duration === 'string' &&
      fromSegment.duration.match(iso8601DurationRegex)
        ? convertDurationToString(fromSegment.duration)
        : null,

    airline: fromSegment.marketingCarrier,
    operatedBy: fromSegment.operatingCarrier
      ? fromSegment.operatingCarrier.name
      : undefined,
    baggagesIncluded: {
      // NOTE: need to make passengers optional because order request change offer slice
      // shares the same type as offer's slice when it shouldn't
      carryOn: fromSegment.passengers?.[0].baggages?.find(
        (baggage) => baggage.type === 'carry_on'
      )?.quantity,
      checked: fromSegment.passengers?.[0].baggages?.find(
        (baggage) => baggage.type === 'checked'
      )?.quantity,
    },
    originTerminal: fromSegment.originTerminal,
    destinationTerminal: fromSegment.destinationTerminal,
  }
}

export const getSliceDetails = (slice: Slice | undefined): SliceDetails => {
  if (!slice) return []

  const numberOfSegments = slice.segments.length
  const sliceDetailsStack = new Array<SliceItem>()

  for (let index = 0; index < numberOfSegments; index++) {
    const currentSegment = slice.segments[index]
    const lastOnStack = sliceDetailsStack[sliceDetailsStack.length - 1] || {}
    const fareBrandName = 'fareBrandName' in slice ? slice.fareBrandName : null
    const travelDetails = getTravelItem(currentSegment, fareBrandName)

    const { departingAt } = getSegmentDates(currentSegment)
    if (lastOnStack.type === 'travel') {
      if (numberOfSegments === 1) break

      const duration = getDurationString(
        lastOnStack.travelDetails!.arrivingAt,
        departingAt!
      )

      sliceDetailsStack.push({
        type: 'layover',
        layoverDetails: {
          airport: lastOnStack.travelDetails!.destination,
          duration,
          originDestinationKey: getLayoverOriginDestinationKey(
            lastOnStack.travelDetails?.origin.iataCode,
            lastOnStack.travelDetails?.destination.iataCode,
            currentSegment.destination?.iataCode
          ),
        },
      })
    }

    // We have to do this in a type-unsafe way for now because this union of offer and order segment
    // is not collapsible due to the lack of a discriminant field
    if (currentSegment['stops']) {
      const stops: DuffelAPI.Types.OfferSliceSegmentStop[] =
        currentSegment['stops']
      if (stops.length === 0) {
        sliceDetailsStack.push({
          type: 'travel',
          id: currentSegment.id || '',
          travelDetails: travelDetails,
        })
      } else {
        sliceDetailsStack.push(
          ...splitTravelDetailsWithStops(
            travelDetails,
            stops,
            currentSegment.id
          )
        )
      }
    } else {
      sliceDetailsStack.push({
        type: 'travel',
        id: currentSegment.id || '',
        travelDetails: travelDetails,
      })
    }
  }

  return sliceDetailsStack
}

const splitTravelDetailsWithStops = (
  travelDetails: TravelDetails<'offer'>,
  stops: DuffelAPI.Types.OfferSliceSegmentStop[],
  segmentId: string
): SliceItem[] => {
  const items: SliceItem[] = []
  // split the travel details by the number of stops
  let nextOrigin = travelDetails.origin
  let nextDepartingAt = travelDetails.departingAt
  stops.forEach((stop, index) => {
    // add a travel item from the origin to this stop
    items.push({
      type: 'travel',
      id: segmentId,
      travelDetails: {
        ...travelDetails,
        originDestination: `${nextOrigin.iataCode}-${stop.airport!.iataCode}`,
        departingAt: nextDepartingAt,
        arrivingAt: stop.arrivingAt,
        origin: nextOrigin,
        destination: stop.airport,
        flightDuration: getDurationString(nextDepartingAt, stop.arrivingAt),
      },
    })
    // show a stop as a layover item
    items.push({
      type: 'layover',
      layoverDetails: {
        airport: stop.airport,
        duration: convertDurationToString(stop.duration),
        originDestinationKey: getLayoverOriginDestinationKey(
          nextOrigin.iataCode,
          stop.airport.iataCode,
          travelDetails.destination.iataCode
        ),
      },
    })
    // the stop becomes the next origin
    nextOrigin = stop.airport
    nextDepartingAt = stop.departingAt

    // if it's the last stop, add a travel item from the last stop to the segment's destination
    if (index === stops.length - 1) {
      items.push({
        type: 'travel',
        id: segmentId,
        travelDetails: {
          ...travelDetails,
          originDestination: `${stop.airport.iataCode}-${travelDetails.destination.iataCode}`,
          departingAt: stop.departingAt,
          arrivingAt: travelDetails.arrivingAt,
          origin: stop.airport,
          destination: travelDetails.destination,
          flightDuration: getDurationString(
            stop.departingAt,
            travelDetails.arrivingAt
          ),
        },
      })
    }
  })

  return items
}
