import * as React from 'react'
import { useTableContext } from '../Table'
import { TableCell } from '../TableCell'
import { TableRow } from '../TableRow'
import styles from './TableFooter.module.css'

/**
 * The minimum number of columns is two because there will need to be a column for the row heading (ex. "Total (GBP)")
 * and for the actual summary content (ex. "160.00")
 */
const MINIMUM_NUMBER_OF_COLUMNS = 2

export interface TableFooterProps {
  /**
   * This is the heading of the footer row and will be displayed on the far-left column.
   * This should describe whatever data is displayed using the `summary` prop.
   * This can be a simple string or a React component.
   * - - -
   * For example, if the summary data is "160.00", the heading may be "Total (GBP)" as
   * that describes what the "160.00" is/means.
   */
  rowHeading: React.ReactNode

  /**
   * The data or content to show in the footer that is describe by the row heading.
   * This can be a simple string or a React component.
   * - - -
   * For example, if the row heading is "Total (GBP)" the summary might be "160.00"
   * This is displayed on the far-right column of the footer row.
   */
  summary: React.ReactNode

  /**
   * Normally, the `<TableFooter>` component will use the `<Table>`'s`tableContext` to determine
   * how many columns the table has to make sure the summary is displayed in the far-right column.
   * However, this prop acts as an override if the table somehow doesn't use the table context.
   */
  emptyRows?: number
}

export const TableFooter: React.FC<
  React.PropsWithChildren<TableFooterProps>
> = ({ rowHeading, summary, emptyRows, children }) => {
  const { columns } = useTableContext()

  /** Allows for the use of children to override pre-generated markup */
  if (children) {
    return <>{children}</>
  }

  /**
   * Will need to render empty columns to make sure the header is always on the far
   * left column and the data is on the far right column
   */
  const emptyColumnsToRender =
    emptyRows ||
    (columns && columns.length - MINIMUM_NUMBER_OF_COLUMNS) ||
    undefined
  return (
    <tfoot className={styles['table-footer']}>
      <TableRow interactive={false}>
        <th scope="row" className={styles['header']}>
          {rowHeading}
        </th>
        {emptyColumnsToRender &&
          Array.from(
            { length: emptyColumnsToRender },
            (_, emptyColumnIndex) => (
              <td
                key={`empty-footer-row-${emptyColumnIndex}`}
                data-testid="empty-footer-row"
              ></td>
            )
          )}
        <TableCell alignRight inFooter>
          {summary}
        </TableCell>
      </TableRow>
    </tfoot>
  )
}
