import * as React from 'react'
import { useFormikContext } from 'formik'
import { FormikInput } from '@components/Form'
import { ChromelessButton } from '@components/Button'
import { CheckoutFormValues } from '@modules/air-search-v2'
import styles from './CheckoutOrderMetadata.module.css'

const MAX_KEY_VALUE_PAIRS = 50

export const CheckoutOrderMetadata: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<CheckoutFormValues>()

  return (
    <div className={styles['container']}>
      {values.metadata.map((_, currentIndex) => {
        const lastArrayItemIndex = values.metadata.length - 1
        return (
          <div
            className={styles['metadata_container']}
            key={`metadata-${currentIndex}`}
          >
            <FormikInput
              label="Key"
              name={`metadata.${currentIndex}.key`}
              optionalField
              className={styles['input']}
            />
            <FormikInput
              label="Value"
              name={`metadata.${currentIndex}.value`}
              optionalField
              className={styles['input']}
            />
            {values.metadata.length > 1 && (
              <ChromelessButton
                className={`${styles['button']} ${styles['remove-metadata']}`}
                type="button"
                onClick={() => {
                  // we clone metadata array and remove the element we want on setFieldValue
                  const cloneMetadataArray = values.metadata.slice(0)
                  cloneMetadataArray.splice(currentIndex, 1)
                  setFieldValue(`metadata`, cloneMetadataArray)
                }}
              >
                Remove
              </ChromelessButton>
            )}
            {lastArrayItemIndex === currentIndex &&
              values.metadata.length < MAX_KEY_VALUE_PAIRS && (
                <ChromelessButton
                  className={`${styles['button']} ${styles['add-metadata']}`}
                  onClick={() => {
                    setFieldValue(`metadata.${currentIndex + 1}`, {
                      key: '',
                      value: '',
                    })
                  }}
                  type="button"
                >
                  Add another key/value pair
                </ChromelessButton>
              )}
          </div>
        )
      })}
    </div>
  )
}
