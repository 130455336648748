import { utcToZonedTime } from 'date-fns-tz'
import { differenceInSeconds, getDay } from 'date-fns'
import { DuffelAPI } from '@lib/types'
import { getNextDepartureDateInUTC } from '@modules/air-order/helpers/get-next-departure-date-in-utc'

const ONE_DAY_IN_SECONDS = 24 * 60 * 60
const TWO_DAYS_IN_SECONDS = 2 * ONE_DAY_IN_SECONDS
const THREE_DAYS_IN_SECONDS = 3 * ONE_DAY_IN_SECONDS

export const isZendeskTicketHighPriority = (
  order: DuffelAPI.Types.Order
): boolean => {
  const nextDepartureDate = getNextDepartureDateInUTC(order)
  if (!nextDepartureDate) {
    return false
  }

  const currentTimeInLondon = utcToZonedTime(new Date(), 'Europe/London')
  const currentWeekdayInLondon = getDay(currentTimeInLondon)

  let highPriorityThresholdInSeconds: number

  // 5 = Friday, 6 = Saturday
  if (currentWeekdayInLondon === 5 || currentWeekdayInLondon === 6) {
    highPriorityThresholdInSeconds = THREE_DAYS_IN_SECONDS
  } else {
    highPriorityThresholdInSeconds = TWO_DAYS_IN_SECONDS
  }

  // @TODO: Add an extra day to the threshold if the order has an Australian
  // point of sale.

  const timeInSecondsUntilNextDeparture = differenceInSeconds(
    nextDepartureDate,
    currentTimeInLondon
  )

  return timeInSecondsUntilNextDeparture < highPriorityThresholdInSeconds
}
