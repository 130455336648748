import { UrlObject } from 'url'
import qs from 'qs'
import { FilterType } from '@components/Filters/lib/types'
import { OfferSort, SortOrdersByOptions } from '@lib/types'
import { SearchParams as StaysSearchParams } from '@modules/stays-search/lib/types'
import { OrdersTab } from '@modules/air-order'
import { ORDERS_ACTIVE_TAB_QUERY_PARAM_KEY } from '@modules/air-order'
import {
  isDefaultSearchTimeRange,
  SEARCH_TIME_OPTIONS,
  SearchTimeRanges,
} from '@modules/air-search-v2/forms/SearchForm/lib/search-time-ranges'
import { searchParamsToQuery } from '@modules/stays-search/lib/search-params'
import { fallBackPathObject } from './common'
import { PathNameObject } from './types'

export const orgModePathsMap = {
  orgIndex: '/[org]/[mode]',

  inbox: '/[org]/[mode]/inbox',

  flightSearchV2Index: '/[org]/[mode]/search-v2',
  flightSearchV2PartialResult: '/[org]/[mode]/search-v2/results',
  flightSearchNGSResults: '/[org]/[mode]/search-v2/ngs/results',
  offerCheckoutV2: '/[org]/[mode]/search-v2/[partialOfferRequestId]/[offerId]',
  offersShow: '/[org]/[mode]/search-v2/ngs/[offerRequestId]/[offerId]',
  ordersIndex: '/[org]/[mode]/orders',
  ordersShow: '/[org]/[mode]/orders/[orderId]',
  ordersChange: '/[org]/[mode]/orders/[orderId]/change',
  ordersChangeResults:
    '/[org]/[mode]/orders/[orderId]/change/[orderChangeRequestId]',
  ordersChangeOffer:
    '/[org]/[mode]/orders/[orderId]/change/[orderChangeRequestId]/[orderChangeId]',
  walletIndex: '/[org]/[mode]/wallet',
  balanceIndex: '/[org]/[mode]/balance',
  paymentIntent: '/[org]/[mode]/balance/[paymentIntentId]',
  tokensIndex: '/[org]/[mode]/developers/tokens',
  tokensShow: '/[org]/[mode]/developers/tokens/[tokenId]',
  tokensCreate: '/[org]/[mode]/developers/tokens/new',
  webhooksIndex: '/[org]/[mode]/developers/webhooks',

  // stays
  staysIndex: '/[org]/[mode]/stays',
  staysContentAnalysis: '/[org]/[mode]/stays/content-analysis',
  staysContentAnalysisV2: '/[org]/[mode]/stays/content-analysis-v2',
  staysSearchIndex: '/[org]/[mode]/stays/search',
  staysSearchResults: '/[org]/[mode]/stays/results',
  staysSearchResultsById: '/[org]/[mode]/stays/results-by-id',
  staysSearchResult: '/[org]/[mode]/stays/[searchResultId]',
  staysCheckOut: '/[org]/[mode]/stays/[searchResultId]/[rateId]',
  staysBookingShow: '/[org]/[mode]/stays/bookings/[bookingId]',
} as const

export type OrgModePaths = keyof typeof orgModePathsMap

export type OrgModePathArraySignature = (
  org: string | undefined,
  isLiveMode: boolean | undefined
) => [UrlObject, string]

export type OrgModePathObjectSignature = (
  org: string | undefined,
  isLiveMode: boolean | undefined
) => { href: PathNameObject; as: string }

export type OrgModeFactory = (pathKey: OrgModePaths) => {
  obj: OrgModePathObjectSignature
  arr: OrgModePathArraySignature
}

export const orgModePathHelpersFactory: OrgModeFactory = (
  path: OrgModePaths
) => {
  const pathname = orgModePathsMap[path]
  const objectHelper = (
    org: string | undefined,
    isLiveMode: boolean | undefined
  ) => {
    if (!org) return fallBackPathObject
    const mode = isLiveMode === true ? 'live' : 'test'
    return {
      href: {
        pathname,
        query: { org, mode },
      },
      as: pathname.replace('[org]/[mode]', `${org}/${mode}`),
    }
  }

  return {
    obj: objectHelper,
    arr: (org: string | undefined, isLiveMode: boolean | undefined) => {
      const { href, as } = objectHelper(org, isLiveMode)
      return [href, as] as [UrlObject, string]
    },
  }
}

export const { arr: orgIndexPathArray, obj: orgIndexPathObject } =
  orgModePathHelpersFactory('orgIndex')

export const { arr: orgInboxPathArray, obj: orgInboxPathObject } =
  orgModePathHelpersFactory('inbox')

export const {
  arr: staysSearchIndexPathArray,
  obj: staysSearchIndexPathObject,
} = orgModePathHelpersFactory('staysSearchIndex')

export const { arr: walletIndexPathArray, obj: walletIndexPathObject } =
  orgModePathHelpersFactory('walletIndex')
export const { arr: balanceIndexPathArray, obj: balanceIndexPathObject } =
  orgModePathHelpersFactory('balanceIndex')

export const { arr: staysIndexPathArray, obj: staysIndexPathObject } =
  orgModePathHelpersFactory('staysIndex')

export const offersShowPathObject = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  offerRequestId,
  offerId: string
) => {
  if (!org) return fallBackPathObject
  const mode = isLiveMode === true ? 'live' : 'test'
  return {
    href: {
      pathname: orgModePathsMap['offersShow'],
      query: { org, mode, offerId },
    },
    as: `/${org}/${mode}/search-v2/ngs/${offerRequestId}/${offerId}`,
  }
}

export const offersShowPathArray = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  offerRequestId,
  offerId: string
) => {
  const { href, as } = offersShowPathObject(
    org,
    isLiveMode,
    offerRequestId,
    offerId
  )
  return [href, as] as [UrlObject, string]
}

export const ordersShowPathObject = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  orderId: string,
  chatId?: string
) => {
  if (!org) return fallBackPathObject
  const mode = isLiveMode === true ? 'live' : 'test'
  return {
    href: {
      pathname: orgModePathsMap['ordersShow'],
      query: { org, mode, orderId, ...(chatId && { chatId }) },
    },
    as: `/${org}/${mode}/orders/${orderId}${chatId ? `?chatId=${chatId}` : ''}`,
  }
}
export const ordersShowPathArray = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  orderId: string
) => {
  const { href, as } = ordersShowPathObject(org, isLiveMode, orderId)
  return [href, as] as [UrlObject, string]
}

export const ordersIndexPathObject = (
  org?: string,
  isLiveMode?: boolean,
  activeTab?: OrdersTab,
  filters?: Partial<
    Record<Extract<FilterType, string>, string> & {
      options: string
    }
  >,
  sort?: SortOrdersByOptions
) => {
  if (!org) return fallBackPathObject
  const mode = isLiveMode === true ? 'live' : 'test'

  const queryParams = activeTab
    ? `?${ORDERS_ACTIVE_TAB_QUERY_PARAM_KEY}=${activeTab}${
        sort ? `&sort=${sort}` : ''
      }`
    : ''

  return {
    href: {
      pathname: orgModePathsMap['ordersIndex'],
      query: { org, mode, activeTab, sort, ...filters },
    },
    as: `/${org}/${mode}/orders${queryParams}`,
  }
}

export const ordersIndexPathArray = (
  org?: string,
  isLiveMode?: boolean,
  activeTab?: OrdersTab
) => {
  const { href, as } = ordersIndexPathObject(org, isLiveMode, activeTab)
  return [href, as] as [UrlObject, string]
}

export const flightSearchV2IndexPathObject = (
  org?: string,
  isLiveMode?: boolean,
  searchParamsId?: string
) => {
  if (!org) return fallBackPathObject
  const mode = isLiveMode === true ? 'live' : 'test'

  const query = searchParamsId
    ? {
        [SEARCH_PARAMS_ID]: searchParamsId,
      }
    : undefined

  return {
    href: {
      pathname: orgModePathsMap['flightSearchV2Index'],
      query: { org, mode, ...query },
    },
    as: `/${org}/${mode}/search-v2${query ? '?' + qs.stringify(query) : ''}`,
  }
}

export const flightSearchV2IndexPathArray = (
  org?: string,
  isLiveMode?: boolean,
  searchParamsId?: string
) => {
  const { href, as } = flightSearchV2IndexPathObject(
    org,
    isLiveMode,
    searchParamsId
  )
  return [href, as] as [UrlObject, string]
}

export const DEPARTURE_TIME_FROM_QUERY_KEY = 'df'
export const DEPARTURE_TIME_TO_QUERY_KEY = 'dt'
export const REQUEST_SOURCES_QUERY_KEY = 'rs'

export const SEARCH_TIME_RANGES_QUERY_KEY = 'sr'

export const SEARCH_PARAMS_ID = 'sid'
export const PARTIAL_OFFER_REQUEST_ID_KEY = 'prq'
export const PARTIAL_OFFER_IDS_KEY = 'po'

export interface PostSearchFilters {
  sorting?: OfferSort
  maxConnections?: 0 | 1 | 2 | 100
  airlineIataCode?: string
  timeRanges?: SearchTimeRanges
  flightNumber?: string
}

const serialiseSearchTimeRanges = (timeRanges: SearchTimeRanges) => {
  const { departureTime, arrivalTime } = timeRanges
  const serialised: string[] = []
  if (!isDefaultSearchTimeRange(departureTime)) {
    serialised.push(
      `${SEARCH_TIME_OPTIONS.indexOf(
        departureTime.from
      )}-${SEARCH_TIME_OPTIONS.indexOf(departureTime.to)}-d`
    )
  }
  if (!isDefaultSearchTimeRange(arrivalTime)) {
    serialised.push(
      `${SEARCH_TIME_OPTIONS.indexOf(
        arrivalTime.from
      )}-${SEARCH_TIME_OPTIONS.indexOf(arrivalTime.to)}-a`
    )
  }

  return serialised.join(',')
}

export const flightSearchNGSResultsPathObject = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  searchParamsId: string
) => {
  if (!org) return fallBackPathObject
  const mode = isLiveMode === true ? 'live' : 'test'

  const query = {
    [SEARCH_PARAMS_ID]: searchParamsId,
  }

  return {
    href: {
      pathname: orgModePathsMap['flightSearchNGSResults'],
      query: { org, mode, ...query },
    },

    as: `/${org}/${mode}/search-v2/ngs/results?${qs.stringify(query)}`,
  }
}

export const flightSearchNGSResultsPathArray = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  searchParamsId: string
) => {
  const { href, as } = flightSearchNGSResultsPathObject(
    org,
    isLiveMode,
    searchParamsId
  )
  return [href, as] as [UrlObject, string]
}

export const flightSearchV2PartialResultPathObject = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  searchParamsId: string,
  ids?: {
    partialOfferRequestId: string
    selectedPartialOfferIds: string[]
  },
  postSearchFilters?: PostSearchFilters
) => {
  if (!org) return fallBackPathObject
  const mode = isLiveMode === true ? 'live' : 'test'

  let query = {
    [SEARCH_PARAMS_ID]: searchParamsId,
  }

  if (ids?.partialOfferRequestId) {
    query[PARTIAL_OFFER_REQUEST_ID_KEY] = ids.partialOfferRequestId
  }

  if (ids && ids.selectedPartialOfferIds.length > 0) {
    query[PARTIAL_OFFER_IDS_KEY] = ids.selectedPartialOfferIds.join(',')
  }

  if (postSearchFilters) {
    query = { ...query, ...postSearchFilters }
  }

  if (postSearchFilters?.timeRanges) {
    query['timeRanges'] = serialiseSearchTimeRanges(
      postSearchFilters.timeRanges
    )
  }

  return {
    href: {
      pathname: orgModePathsMap['flightSearchV2PartialResult'],
      query: { org, mode, ...query },
    },

    as: `/${org}/${mode}/search-v2/results?${qs.stringify(query)}`,
  }
}

export const flightSearchV2PartialResultPathArray = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  searchParamsId: string,
  ids?: {
    partialOfferRequestId: string
    selectedPartialOfferIds: string[]
  },
  postSearchFilters?: PostSearchFilters
) => {
  const { href, as } = flightSearchV2PartialResultPathObject(
    org,
    isLiveMode,
    searchParamsId,
    ids,
    postSearchFilters
  )
  return [href, as] as [UrlObject, string]
}

export const offerCheckoutV2PathObject = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  searchParamsId: string,
  ids: {
    partialOfferRequestId: string
    selectedPartialOfferIds: string[]
    offerId: string
  }
) => {
  if (!org) return fallBackPathObject
  const mode = isLiveMode === true ? 'live' : 'test'

  const query = {
    [SEARCH_PARAMS_ID]: searchParamsId,
    ...(ids?.partialOfferRequestId
      ? { [PARTIAL_OFFER_REQUEST_ID_KEY]: ids.partialOfferRequestId }
      : {}),
    ...(ids?.selectedPartialOfferIds
      ? { [PARTIAL_OFFER_IDS_KEY]: ids.selectedPartialOfferIds.join(',') }
      : {}),
  }

  return {
    href: {
      pathname: orgModePathsMap['offerCheckoutV2'],
      query: {
        org,
        mode,
        offerId: ids.offerId,
        partialOfferRequestId: ids.partialOfferRequestId,
        ...query,
      },
    },
    as: `/${org}/${mode}/search-v2/${ids.partialOfferRequestId}/${
      ids.offerId
    }?${qs.stringify(query)}`,
  }
}

export const offerCheckoutV2PathArray = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  searchParamsId: string,
  ids: {
    partialOfferRequestId: string
    selectedPartialOfferIds: string[]
    offerId: string
  }
) => {
  const { href, as } = offerCheckoutV2PathObject(
    org,
    isLiveMode,
    searchParamsId,
    ids
  )
  return [href, as] as [UrlObject, string]
}

export const ordersChangePathObject = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  orderId: string
) => {
  if (!org) return fallBackPathObject
  const mode = isLiveMode === true ? 'live' : 'test'
  return {
    href: {
      pathname: orgModePathsMap['ordersChange'],
      query: { org, mode, orderId },
    },
    as: `/${org}/${mode}/orders/${orderId}/change`,
  }
}
export const ordersChangePathArray = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  orderId: string
) => {
  const { href, as } = ordersChangePathObject(org, isLiveMode, orderId)
  return [href, as] as [UrlObject, string]
}

export const ordersChangeResultsPathObject = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  orderId: string,
  orderChangeRequestId: string
) => {
  if (!org) return fallBackPathObject
  const mode = isLiveMode === true ? 'live' : 'test'
  return {
    href: {
      pathname: orgModePathsMap['ordersChangeResults'],
      query: { org, mode, orderId, orderChangeRequestId },
    },
    as: `/${org}/${mode}/orders/${orderId}/change/${orderChangeRequestId}`,
  }
}
export const ordersChangeResultsPathArray = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  orderId: string,
  changeRequestId: string
) => {
  const { href, as } = ordersChangeResultsPathObject(
    org,
    isLiveMode,
    orderId,
    changeRequestId
  )
  return [href, as] as [UrlObject, string]
}

export const ordersPendingOrderChangePathObject = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  orderId: string,
  changeRequestId: string,
  orderChangeId: string
) => {
  if (!org) return fallBackPathObject
  const mode = isLiveMode === true ? 'live' : 'test'
  return {
    href: {
      pathname: orgModePathsMap['ordersChangeOffer'],
      query: { org, mode, orderId, changeRequestId, orderChangeId },
    },
    as: `/${org}/${mode}/orders/${orderId}/change/${changeRequestId}/${orderChangeId}`,
  }
}
export const ordersChangeOfferPathArray = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  orderId: string,
  changeRequestId: string,
  offerId: string
) => {
  const { href, as } = ordersPendingOrderChangePathObject(
    org,
    isLiveMode,
    orderId,
    changeRequestId,
    offerId
  )
  return [href, as] as [UrlObject, string]
}

export const paymentIntentPathObject = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  paymentIntentId: string
) => {
  if (!org) return fallBackPathObject
  const mode = isLiveMode === true ? 'live' : 'test'
  return {
    href: {
      pathname: orgModePathsMap['paymentIntent'],
      query: { org, mode, paymentIntentId },
    },
    as: `/${org}/${mode}/balance/${paymentIntentId}`,
  }
}
export const paymentIntentPathArray = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  paymentIntentId: string
) => {
  const { href, as } = ordersShowPathObject(org, isLiveMode, paymentIntentId)
  return [href, as] as [UrlObject, string]
}

export const { arr: tokensIndexPathArray, obj: tokensIndexPathObject } =
  orgModePathHelpersFactory('tokensIndex')

export const { arr: webhooksIndexPathArray, obj: webhooksIndexPathObject } =
  orgModePathHelpersFactory('webhooksIndex')

export const tokensShowPathObject = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  tokenId: string
) => {
  if (!org) return fallBackPathObject
  const mode = isLiveMode === true ? 'live' : 'test'

  return {
    href: {
      pathname: orgModePathsMap['tokensShow'],
      query: { org, tokenId },
    },
    as: `/${org}/${mode}/developers/tokens/${tokenId}`,
  }
}
export const tokensShowPathArray = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  tokenId: string
) => {
  const { href, as } = tokensShowPathObject(org, isLiveMode, tokenId)
  return [href, as] as [UrlObject, string]
}

export const { arr: tokensCreatePathArray, obj: tokensCreatePathObject } =
  orgModePathHelpersFactory('tokensCreate')

export const staysSearchResultsPathObject = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  searchParams: StaysSearchParams
) => {
  if (!org) return fallBackPathObject
  const mode = isLiveMode === true ? 'live' : 'test'

  const query = searchParamsToQuery(searchParams)
  return {
    href: {
      pathname: orgModePathsMap['staysSearchResults'],
      query: { org, mode, ...query },
    },

    as: `/${org}/${mode}/stays/results?${qs.stringify(query)}`,
  }
}
export const staysSearchResultsByIdPathObject = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  searchParams: StaysSearchParams
) => {
  if (!org) return fallBackPathObject
  const mode = isLiveMode === true ? 'live' : 'test'

  const query = searchParamsToQuery(searchParams)
  return {
    href: {
      pathname: orgModePathsMap['staysSearchResultsById'],
      query: { org, mode, ...query },
    },

    as: `/${org}/${mode}/stays/results-by-id?${qs.stringify(query)}`,
  }
}

export const staysSearchResultsByIdPathArray = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  searchParams: StaysSearchParams
) => {
  const { href, as } = staysSearchResultsByIdPathObject(
    org,
    isLiveMode,
    searchParams
  )
  return [href, as] as [UrlObject, string]
}

export const staysSearchResultsPathArray = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  searchParams: StaysSearchParams
) => {
  const { href, as } = staysSearchResultsPathObject(
    org,
    isLiveMode,
    searchParams
  )
  return [href, as] as [UrlObject, string]
}

export const staysSearchResultPathObject = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  searchResultId: string,
  searchParams: StaysSearchParams
) => {
  if (!org) return fallBackPathObject
  const mode = isLiveMode === true ? 'live' : 'test'

  const query = searchParamsToQuery(searchParams)
  return {
    href: {
      pathname: orgModePathsMap['staysSearchResult'],
      query: { org, mode, searchResultId, ...query },
    },
    as: `/${org}/${mode}/stays/${searchResultId}?${qs.stringify(query)}`,
  }
}

export const staysSearchResultPathArray = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  searchResultId: string,
  searchParams: StaysSearchParams
) => {
  const { href, as } = staysSearchResultPathObject(
    org,
    isLiveMode,
    searchResultId,
    searchParams
  )

  return [href, as] as [UrlObject, string]
}

export const staysCheckOutPathObject = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  searchResultId: string,
  rateId: string,
  searchParams: StaysSearchParams
) => {
  if (!org) return fallBackPathObject
  const mode = isLiveMode === true ? 'live' : 'test'

  const query = searchParamsToQuery(searchParams)
  return {
    href: {
      pathname: orgModePathsMap['staysCheckOut'],
      query: { org, mode, searchResultId, rateId, ...query },
    },
    as: `/${org}/${mode}/stays/${searchResultId}/${rateId}?${qs.stringify(
      query
    )}`,
  }
}

export const staysCheckOutPathArray = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  searchResultId: string,
  rateId: string,
  searchParams: StaysSearchParams
) => {
  const { href, as } = staysCheckOutPathObject(
    org,
    isLiveMode,
    searchResultId,
    rateId,
    searchParams
  )

  return [href, as] as [UrlObject, string]
}

export const staysBookingShowPathObject = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  bookingId: string,
  isFromCheckout?: boolean
) => {
  if (!org) return fallBackPathObject
  const mode = isLiveMode === true ? 'live' : 'test'
  return {
    href: {
      pathname: orgModePathsMap['staysBookingShow'],
      query: { org, mode, bookingId, fromCheckout: isFromCheckout },
    },
    as: `/${org}/${mode}/stays/bookings/${bookingId}${
      isFromCheckout ? '?fromCheckout=true' : ''
    }`,
  }
}
export const staysBookingShowPathArray = (
  org: string | undefined,
  isLiveMode: boolean | undefined,
  bookingId: string,
  isFromCheckout?: boolean
) => {
  const { href, as } = staysBookingShowPathObject(
    org,
    isLiveMode,
    bookingId,
    isFromCheckout
  )
  return [href, as] as [UrlObject, string]
}
