import {
  doc,
  getFirestore,
  addDoc,
  collection,
  getDoc,
  Timestamp,
} from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid'
import { SearchType } from '@lib/types'
import {
  getPassengersParams,
  savePassengersParams,
} from '@modules/air-search-v2/lib/multi-step-search/passengers-params-storage'
import { Slice } from '@modules/air-search-v2/forms/SearchForm/MultiCitySearchForm'
import { SearchFormValues } from '../transform-search-form-values'

export class SearchFormValuesNotFoundError extends Error {
  constructor(id: string) {
    super(`search params not found for id ${id}`)
  }
}

const searchFormValuesMap = new Map<string, SearchFormValues<SearchType>>()

export const saveSearchFormValues = async (
  formValues: SearchFormValues<SearchType>
) => {
  const { passengers } = formValues
  const formValuesForFireStore = { ...formValues }
  // save passengers to memory if it's sensitive
  if (
    passengers.some(
      (passenger) =>
        passenger.familyName ||
        passenger.givenName ||
        passenger.loyaltyProgrammeAccounts.filter(
          (account) => account.accountNumber.length > 0
        ).length > 0
    )
  ) {
    formValuesForFireStore.passengers = []
    formValuesForFireStore['passengerParamsId'] =
      savePassengersParams(passengers)
  }

  try {
    const searchParamsCollection = collection(getFirestore(), 'searchParams')
    const docRef = await addDoc(searchParamsCollection, formValuesForFireStore)

    // use the key in firestore to save it to the memory
    searchFormValuesMap.set(docRef.id, formValues)
    return docRef.id
  } catch {
    // if saving to firestore fails, generate the uuid and use it instead
    const id = uuidv4()
    searchFormValuesMap.set(id, formValues)
    return id
  }
}

export const loadSearchFormValues = async (
  id: string
): Promise<SearchFormValues<SearchType>> => {
  // prioritize the in-memory parmas
  const searchParamsFromMap = searchFormValuesMap.get(id)
  if (searchParamsFromMap) {
    return searchParamsFromMap
  }

  // if we can't find the search params in memory, try loading from firestore
  const searchFormValues = (
    await getDoc(doc(getFirestore(), 'searchParams', id))
  ).data()
  if (!searchFormValues) {
    throw new SearchFormValuesNotFoundError(id)
  }

  // transform passengers if necessary
  if (searchFormValues['passengerParamsId']) {
    const passengers = getPassengersParams(
      searchFormValues['passengerParamsId']
    )
    if (passengers) {
      searchFormValues['passengers'] = passengers
    }
  }

  // when we save the object to firestore, all the dates get transformed
  // to Firestore timestamps, so we need to convert them back.
  const dateFromFirestoreTimestamp = (timestamp: Timestamp) =>
    new Date(timestamp.seconds * 1000)

  switch (searchFormValues.type) {
    case 'one_way':
      searchFormValues.departureDate = dateFromFirestoreTimestamp(
        searchFormValues.departureDate
      )
      break
    case 'return':
      searchFormValues.departureDate = dateFromFirestoreTimestamp(
        searchFormValues.departureDate
      )
      searchFormValues.returnDate = dateFromFirestoreTimestamp(
        searchFormValues.returnDate
      )
      break
    case 'multi_city':
      searchFormValues.slices = searchFormValues.slices.map(
        (slice: Slice & { departureDate: Timestamp }) => ({
          ...slice,
          ...(slice.departureDate
            ? { departureDate: dateFromFirestoreTimestamp(slice.departureDate) }
            : {}),
        })
      )
  }

  // Need to use the assertion as we can't guarantee that the value returned from firestore
  // will have the same type. Technically we could form the search values from scratch, but
  // such effort is not worth it for now
  const assertedSearchFormValues =
    searchFormValues as SearchFormValues<SearchType>
  searchFormValuesMap.set(id, assertedSearchFormValues)
  return assertedSearchFormValues
}
