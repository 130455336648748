import { useField } from 'formik'
import * as React from 'react'
import {
  defaultSearchTimeRanges,
  SearchTimeRanges,
} from '@modules/air-search-v2/forms/SearchForm/lib/search-time-ranges'
import { SearchTimeFilters } from './SearchTimeFilters'

interface FormikSearchTimeFiltersProps {
  name: string
}

export const FormikSearchTimeFilters: React.FC<
  FormikSearchTimeFiltersProps
> = ({ name }) => {
  const [
    { value: departureTime = defaultSearchTimeRanges.departureTime },
    ,
    { setValue: setDepartureTime },
  ] = useField<SearchTimeRanges['departureTime']>({
    name: `${name}.departureTime`,
  })
  const [
    { value: arrivalTime = defaultSearchTimeRanges.arrivalTime },
    ,
    { setValue: setArrivalTime },
  ] = useField<SearchTimeRanges['arrivalTime']>({
    name: `${name}.arrivalTime`,
  })

  return (
    <div className="u-marginTop8">
      <SearchTimeFilters
        selectedSearchTimeRanges={{
          departureTime,
          arrivalTime,
        }}
        onSubmit={({ departureTime, arrivalTime }) => {
          setDepartureTime(departureTime)
          setArrivalTime(arrivalTime)
        }}
      />
    </div>
  )
}
