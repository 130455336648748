import * as React from 'react'
import { AirlinesContext } from '@modules/air-order/lib/airlines-context'
import { FilterHeader } from './FilterHeader'
import { getFilterNames } from './lib/helpers'
import { FilterType } from './lib/types'

import styles from './FilterDropdown.module.css'

export interface FilterDropdownProps {
  filters: FilterType[]
  onFilterSelect: (type: FilterType) => void
  hideHeader?: boolean
}

export const FilterDropdown: React.FC<
  React.PropsWithChildren<FilterDropdownProps>
> = ({ filters, onFilterSelect, hideHeader }) => {
  const airlines = React.useContext(AirlinesContext)
  return (
    <div data-selector="fs-show">
      {!hideHeader && <FilterHeader>Apply a filter</FilterHeader>}
      <ul className={styles['list']}>
        {filters
          // if we don't have the list of airlines, don't show the airlines filter
          .filter((filter) => !(filter === 'airlineId' && airlines.length == 0))
          .map((filter) => (
            <li
              className={styles['list-item']}
              data-testid={`filter-${filter}`}
              key={getFilterNames(filter)}
            >
              <button
                className={styles['list-item-button']}
                onClick={() => onFilterSelect(filter)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    onFilterSelect(filter)
                  }
                }}
                tabIndex={0}
              >
                {getFilterNames(filter)}
              </button>
            </li>
          ))}
      </ul>
    </div>
  )
}
