import { nanoid } from 'nanoid'
import { Dispatch, SetStateAction } from 'react'
import { DialogProps } from '@components/Dialog'
import { ToastProps } from '@components/Toaster'
import { captureException } from '@lib/sentry'
import { mapSelfToIdentifiableTraits, trackEvent } from '@lib/tracking'
import { DuffelAPI, DuffelPermissions, DuffelProxy } from '@lib/types'
import { DuffelClientClass } from '@lib/duffel-client/Client'

function buildOrganisation({
  name,
  slug,
  createdAt,
  verified,
  id,
  settlementCurrency,
  verificationFlow,
  isDuffelLinksEnabled,
  staysAccessStatus,
  sessionExpiryTimeout,
}: DuffelAPI.Types.Organisation) {
  return {
    createdAt,
    isVerified: verified,
    name,
    slug,
    id,
    settlementCurrency,
    verificationFlow,
    isDuffelLinksEnabled,
    staysAccessStatus,
    sessionExpiryTimeout,
  }
}

function buildMembership({ organisation, owner, createdAt, scope }: any) {
  return {
    isOwner: owner,
    joinedAt: createdAt,
    scope,
    ...buildOrganisation(organisation),
  }
}

type SetUserStateType = Dispatch<SetStateAction<DuffelProxy.Types.Self | null>>

export const acceptInvitation = (
  duffelClient: DuffelClientClass,
  user: DuffelProxy.Types.Self | null,
  setUserState: SetUserStateType,
  addToast: (props: Partial<ToastProps>) => void
) => {
  return async (token: string) => {
    if (!user) return null

    const result = await duffelClient.Identity.acceptInvitation(token)

    if (result.errors || !result.data) {
      result.errors?.map(({ message }) => {
        addToast({
          intent: 'warning',
          message: message,
          closeAfterTimeout: false,
        })
        trackEvent('dashboard_join_alert_displayed', {
          organisation_slug: user.invitationsById[token]?.organisation.slug,
          alert_message: message,
          event_type: 'alert',
        })
      })
      return
    }

    const { acceptedAt, recipient } = result.data

    if (
      !recipient?.organisationMemberships ||
      recipient.organisationMemberships.length === 0
    ) {
      captureException(
        new Error(`User has no organisation memberships: ${recipient?.id}`)
      )
      return
    }
    const membership = recipient?.organisationMemberships[0]

    if (user.invitationsById[token]) {
      user.invitationsById[token].acceptedAt = acceptedAt
    } else {
      captureException(
        new Error('Invitation not found for the specified invitation token')
      )
    }
    user.organisationSlugs.push(membership.organisation.slug)
    user.organisationsBySlug[membership.organisation.slug] =
      buildMembership(membership)

    setUserState({ ...user })

    trackEvent(
      'dashboard_join_accept_invitation_confirmed',
      {
        organisation_slug: membership.organisation.slug,
        event_type: 'api',
      },
      mapSelfToIdentifiableTraits(user)
    )

    return result.data
  }
}

export const declineInvitation = (
  duffelClient: DuffelClientClass,
  user: DuffelProxy.Types.Self | null,
  setUserState: SetUserStateType,
  addToast: (props: Partial<ToastProps>) => void
) => {
  return async (token: string) => {
    if (!user) return null

    const result = await duffelClient.Identity.declineInvitation(token)
    if (result.errors) {
      result.errors.map(({ message }) => {
        addToast({
          intent: 'warning',
          message: message,
          closeAfterTimeout: false,
        })
        trackEvent('dashboard_join_alert_displayed', {
          organisation_slug: user.invitationsById[token].organisation.slug,
          alert_message: message,
          event_type: 'alert',
        })
      })
      return
    }

    const { revokedAt } = result.data!
    user.invitationsById[token].revokedAt = revokedAt

    setUserState({ ...user })

    trackEvent('dashboard_join_decline_invitation_confirmed', {
      organisation_slug: user.invitationsById[token].organisation.slug,
      event_type: 'api',
    })

    return result.data
  }
}

export const updateUser = (
  userState: DuffelProxy.Types.Self | null,
  setUserState: SetUserStateType
) => {
  return (userData: Partial<DuffelProxy.Types.Self>) => {
    if (!userState) return null

    const user = Object.assign({}, userState, userData)
    setUserState(user)
  }
}

export const updateCurrentOrganisation = (
  userState: DuffelProxy.Types.Self | null,
  permissions: DuffelPermissions | undefined,
  setUserState: SetUserStateType
) => {
  return (orgUpdateData: Partial<DuffelProxy.Types.SelfOrganisation>) => {
    if (!userState) return null
    if (!permissions) return null
    const userCopy = Object.assign({}, userState)

    if (!userCopy.organisationsBySlug) return null
    if (!userCopy.organisationsBySlug[permissions.organisation]) return null

    userCopy.organisationsBySlug[permissions.organisation] = Object.assign(
      {},
      userCopy.organisationsBySlug[permissions.organisation],
      orgUpdateData
    )

    setUserState(userCopy)
  }
}

export const addOrganisationToUser = (
  userState: DuffelProxy.Types.Self | null,
  setUserState: SetUserStateType
) => {
  return (organisation: DuffelProxy.Types.SelfOrganisation) => {
    if (!userState) return null

    const newUserState: DuffelProxy.Types.Self = Object.assign({}, userState)
    const orgSlug = organisation.slug
    newUserState.organisationSlugs.push(orgSlug)
    newUserState.organisationsBySlug[orgSlug] = organisation

    setUserState(newUserState)
  }
}

type SetPermissionsStateType = Dispatch<
  SetStateAction<DuffelPermissions | null>
>

export const setMode = (
  permissionsState: DuffelPermissions | null,
  setPermissionsState: SetPermissionsStateType
) => {
  return (isLive: boolean) => {
    if (!permissionsState) return null

    const updatedPermissions = Object.assign({}, permissionsState, {
      liveMode: isLive,
    })

    setPermissionsState(updatedPermissions)
  }
}

export const setPermissions = (
  _permissionsState: DuffelPermissions | null,
  setPermissionsState: SetPermissionsStateType
) => {
  return (permissions: DuffelPermissions) => {
    if (!permissions) return null

    setPermissionsState(permissions)
  }
}

type SetToastStateType = Dispatch<SetStateAction<ToastProps[]>>

export const addToast = (
  toastState: ToastProps[],
  setToastState: SetToastStateType
) => {
  return (props: Partial<ToastProps>) => {
    const toast = {
      id: nanoid(),
      ...props,
    }
    const toasts = [...toastState, toast as ToastProps]
    setToastState(toasts)
  }
}

export const closeToast = (
  toastState: ToastProps[],
  setToastState: SetToastStateType
) => {
  return (index: number) => {
    toastState.splice(index, 1)
    setToastState([...toastState])
  }
}

export const closeAllToasts = (
  _toastState: ToastProps[],
  setToastState: SetToastStateType
) => {
  return () => {
    setToastState([])
  }
}

type SetDialogStateType = React.Dispatch<
  React.SetStateAction<DialogProps | null>
>
export const openDialog = (setDialogState: SetDialogStateType) => {
  return (props: DialogProps) => {
    setDialogState(props)
  }
}
export const updateDialog = (setDialogState: SetDialogStateType) => {
  return (props: DialogProps) => {
    setDialogState((dialogState) => ({
      ...dialogState,
      ...props,
    }))
  }
}
export const closeDialog = (setDialogState: SetDialogStateType) => {
  return () => {
    setDialogState(null)
  }
}
