import { camelCase, snakeCase } from 'lodash'
import { CamelCasedPropertiesDeep, SnakeCasedPropertiesDeep } from 'type-fest'
import { transformDataKeys } from '@lib/proxy/lib'

export const toSnakeCaseKeys = <T extends object>(
  value: T
): SnakeCasedPropertiesDeep<T> => transformDataKeys(value, snakeCase)

export const toCamelCaseKeys = <T extends object>(
  value: T
): CamelCasedPropertiesDeep<T> => transformDataKeys(value, camelCase)
