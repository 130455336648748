import { DuffelProxy } from '@lib/types'

/* eslint-disable */
export const DuffelSources: Record<
  string,
  Pick<
    DuffelProxy.Types.Source,
    'name' | 'featuredAirlines' | 'totalAirlineCount'
  >
> = {
  aegean_airlines: {
    name: 'Aegean Airlines',
    featuredAirlines: [
      { iataCode: 'A3', name: 'Aegean Airlines' },
      { iataCode: 'OA', name: 'Olympic Air' },
    ],
  },
  air_canada: {
    name: 'Air Canada',
    featuredAirlines: [{ iataCode: 'AC', name: 'Air Canada' }],
  },
  air_france_klm: {
    name: 'Air France/KLM',
    featuredAirlines: [
      { iataCode: 'AF', name: 'Air France' },
      { iataCode: 'KL', name: 'KLM' },
    ],
  },
  american_airlines: {
    name: 'American Airlines',
    featuredAirlines: [{ iataCode: 'AA', name: 'American Airlines' }],
  },
  avianca: {
    name: 'Avianca',
    featuredAirlines: [
      { iataCode: 'AV', name: 'Avianca' },
      { iataCode: 'TA', name: 'Avianca El Salvador' },
    ],
  },
  british_airways: {
    name: 'British Airways',
    featuredAirlines: [{ iataCode: 'BA', name: 'British Airways' }],
  },
  copa_airlines: {
    name: 'Copa Airlines',
    featuredAirlines: [{ iataCode: 'CM', name: 'Copa Airlines' }],
  },
  delta: {
    name: 'Delta',
    featuredAirlines: [{ iataCode: 'DL', name: 'Delta' }],
  },
  duffel_airways: {
    name: 'Duffel Airways',
    featuredAirlines: [{ iataCode: 'ZZ', name: 'Duffel Airways' }],
  },
  easyjet: {
    name: 'easyJet',
    featuredAirlines: [{ iataCode: 'U2', name: 'easyJet' }],
  },
  emirates: {
    name: 'Emirates',
    featuredAirlines: [{ iataCode: 'EK', name: 'Emirates' }],
  },
  hawaiian_airlines: {
    name: 'Hawaiian Airlines',
    featuredAirlines: [{ iataCode: 'HA', name: 'Hawaiian Airlines' }],
  },
  iberia: {
    name: 'Iberia',
    featuredAirlines: [
      { iataCode: 'IB', name: 'Iberia' },
      { iataCode: 'I2', name: 'Iberia Express' },
    ],
  },
  jetstar: {
    name: 'Jetstar Airways',
    featuredAirlines: [
      { iataCode: 'JQ', name: 'Jetstar Airways' },
      { iataCode: 'GK', name: 'Jetstar Japan' },
      { iataCode: '3K', name: 'Jetstar Asia Airways' },
    ],
  },
  // klm: { name: 'KLM', airlines: [{ iataCode: 'KL', name: 'KLM' }] },
  latam: {
    name: 'LATAM',
    featuredAirlines: [{ iataCode: 'LA', name: 'LATAM' }],
  },
  lufthansa_group: {
    name: 'Lufthansa Group',
    featuredAirlines: [
      { iataCode: 'LH', name: 'Lufthansa' },
      { iataCode: 'OS', name: 'Austrian' },
      { iataCode: 'LX', name: 'SWISS' },
      { iataCode: 'SN', name: 'Brussels Airlines' },
      { iataCode: '4Y', name: 'Eurowings Discover' },
    ],
  },
  // norwegian: { name: 'Norwegian', airlines: [{ iataCode: 'DI', name: 'Norwegian' }] },
  qantas: {
    name: 'Qantas',
    featuredAirlines: [{ iataCode: 'QF', name: 'Qantas' }],
  },
  // Removed as we don't want to expose Sabre and Delta in the dashboard
  // sabre: {
  //   name: 'Sabre',
  //   featuredAirlines: [{ iataCode: 'DL', name: 'Delta' }],
  // },
  scoot: {
    name: 'Scoot',
    featuredAirlines: [{ iataCode: 'TR', name: 'Scoot' }],
  },
  singapore_airlines: {
    name: 'Singapore Airlines',
    featuredAirlines: [{ iataCode: 'SQ', name: 'Singapore Airlines' }],
  },
  spirit: {
    name: 'Spirit',
    featuredAirlines: [{ iataCode: 'NK', name: 'Spirit' }],
  },
  southwest: {
    name: 'Southwest',
    featuredAirlines: [{ iataCode: 'WN', name: 'Southwest' }],
  },
  transavia: {
    name: 'Transavia',
    featuredAirlines: [{ iataCode: 'TO', name: 'Transavia' }],
  },
  travelport: {
    name: 'Travelport',
    featuredAirlines: [
      { iataCode: '1G', name: 'Travelport' },
      { iataCode: 'TK', name: 'Turkish Airlines' },
      { iataCode: 'AI', name: 'Air India' },
    ],
    totalAirlineCount: 72,
  },
  united: {
    name: 'United',
    featuredAirlines: [{ iataCode: 'UA', name: 'United' }],
  },
  virgin_atlantic: {
    name: 'Virgin Atlantic',
    featuredAirlines: [{ iataCode: `VS`, name: 'Virgin Atlantic' }],
  },
  volaris: {
    name: 'Volaris',
    featuredAirlines: [{ iataCode: `Y4`, name: 'Volaris' }],
  },
  vueling_level: {
    name: 'Vueling / LEVEL',
    featuredAirlines: [
      { iataCode: 'VY', name: 'Vueling' },
      { iataCode: 'LV', name: 'LEVEL' },
    ],
  },
  westjet: {
    name: 'WestJet',
    featuredAirlines: [{ iataCode: 'WS', name: 'WestJet' }],
  },
}

export type SourceId = keyof typeof DuffelSources

export const sourceCapabilitiesMap: Record<
  SourceId,
  DuffelProxy.Types.SourceCapability
> = {
  aegean_airlines: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Not supported',
    'Add additional bags when booking': 'Full support',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Full support',
    'Accepted payments': 'Duffel Balance and ARC/BSP Cash',
  },
  air_canada: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Full support',
    'Add additional bags when booking': 'Not supported',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Full support',
    'Accepted payments': 'Duffel Balance and ARC/BSP Cash',
  },
  air_france_klm: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Full support',
    'Add additional bags when booking': 'Full support',
    'Selecting a seat when booking': 'Full support',
    'Loyalty programmes': 'Full support',
    'Accepted payments': 'Duffel Balance and ARC/BSP Cash',
  },
  american_airlines: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Full support',
    'Add additional bags when booking': 'Not supported',
    'Selecting a seat when booking': 'Free seats only',
    'Loyalty programmes': 'Full support',
    'Accepted payments': 'Duffel Balance and ARC/BSP Cash',
  },
  avianca: {
    'Fare brands': 'Coming soon',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Partial support',
    'Add additional bags when booking': 'Not supported',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance',
  },
  british_airways: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Full support',
    'Add additional bags when booking': 'Full support',
    'Selecting a seat when booking': 'Full support',
    'Loyalty programmes': 'Full support',
    'Accepted payments': 'Duffel Balance and ARC/BSP Cash',
  },
  copa_airlines: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Full support',
    'Add additional bags when booking': 'Not supported',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Full support',
    'Accepted payments': 'Duffel Balance',
  },
  delta: {
    'Fare brands': 'Not supported',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Not supported',
    'Add additional bags when booking': 'Not supported',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance and ARC/BSP Cash',
  },
  duffel_airways: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Full support',
    'Add additional bags when booking': 'Full support',
    'Selecting a seat when booking': 'Full support',
    'Loyalty programmes': 'Full support',
    'Accepted payments': 'Duffel Balance',
  },
  easyjet: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Full support',
    'Add additional bags when booking': 'Full support',
    'Selecting a seat when booking': 'Full support',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance',
  },
  emirates: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Full support',
    'Add additional bags when booking': 'Not supported',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Full support',
    'Accepted payments': 'Duffel Balance and ARC/BSP Cash',
  },
  hawaiian_airlines: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Full support',
    'Add additional bags when booking': 'Not supported',
    'Selecting a seat when booking': 'Full support',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance and ARC/BSP Cash',
  },
  iberia: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Full support',
    'Add additional bags when booking': 'Not supported',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance and ARC/BSP Cash',
  },
  ita_airways: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Not supported',
    'Add additional bags when booking': 'Full support',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance',
  },
  jetstar: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Not supported',
    'Add additional bags when booking': 'Full support',
    'Selecting a seat when booking': 'Full support',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance',
  },
  latam: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Not supported',
    'Add additional bags when booking': 'Coming soon',
    'Selecting a seat when booking': 'Coming soon',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance and ARC/BSP Cash',
  },
  klm: {
    'Fare brands': 'Not supported',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Not supported',
    'Add additional bags when booking': 'Not supported',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance and ARC/BSP Cash',
  },
  lufthansa_group: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Full support',
    'Add additional bags when booking': 'Full support',
    'Selecting a seat when booking': 'Full support',
    'Loyalty programmes': 'Full support',
    'Accepted payments': 'Duffel Balance and ARC/BSP Cash',
  },
  norwegian: {
    'Fare brands': 'Not supported',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Not supported',
    'Add additional bags when booking': 'Not supported',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance and ARC/BSP Cash',
  },
  qantas: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Full support',
    'Add additional bags when booking': 'Full support',
    'Selecting a seat when booking': 'Full support',
    'Loyalty programmes': 'Full support',
    'Accepted payments': 'Duffel Balance and ARC/BSP Cash',
  },
  royal_air_maroc: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Not supported',
    'Add additional bags when booking': 'Full support',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance',
  },
  scandinavian_airlines: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Not supported',
    'Add additional bags when booking': 'Full support',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance',
  },
  scoot: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Not supported',
    'Add additional bags when booking': 'Not supported',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance',
  },
  sabre: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Not supported',
    'Add additional bags when booking': 'Not supported',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Full support',
    'Accepted payments': 'Duffel Balance',
  },
  singapore_airlines: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Full support',
    'Add additional bags when booking': 'Not supported',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance and ARC/BSP Cash',
  },
  spirit: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Full support',
    'Add additional bags when booking': 'Full support',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance',
  },
  southwest: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Full support',
    'Add additional bags when booking': 'Not supported',
    'Selecting a seat when booking': 'Not supported',
    // In actual fact, Duffel Balance hasn't been configured for Southwest yet,
    // but we would do this when actually setting someone up!
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance',
  },
  tap_air_portugal: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Not supported',
    'Add additional bags when booking': 'Full support',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance',
  },
  transavia: {
    'Fare brands': 'Not supported',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Not supported',
    'Add additional bags when booking': 'Full support',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance',
  },
  travelport: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Not supported',
    'Add additional bags when booking': 'Not supported',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance',
  },
  united: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Not supported',
    'Add additional bags when booking': 'Full support',
    'Selecting a seat when booking': 'Full support',
    'Loyalty programmes': 'Full support',
    'Accepted payments': 'Duffel Balance and ARC/BSP Cash',
  },
  virgin_atlantic: {
    'Fare brands': 'Not supported',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Not supported',
    'Add additional bags when booking': 'Not supported',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance and ARC/BSP Cash',
  },
  volaris: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Partial support',
    'Cancellations & Refunds': 'Not supported',
    'Add additional bags when booking': 'Full support',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance',
  },
  vueling_level: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Not supported',
    'Add additional bags when booking': 'Full support',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Not supported',
    'Accepted payments': 'Duffel Balance',
  },
  westjet: {
    'Fare brands': 'Full support',
    'Searching & Booking': 'Full support',
    'Cancellations & Refunds': 'Full support',
    'Add additional bags when booking': 'Not supported',
    'Selecting a seat when booking': 'Not supported',
    'Loyalty programmes': 'Full support',
    'Accepted payments': 'Duffel Balance and ARC/BSP Cash',
  },
}

export const defaultAirlineCapabilities = {
  'Fare brands': 'Full support',
  'Searching & Booking': 'Full support',
  'Cancellations & Refunds': 'Not supported',
  'Add additional bags when booking': 'Not supported',
  'Selecting a seat when booking': 'Not supported',
  'Loyalty programmes': 'Not supported',
  'Accepted payments': 'Duffel Balance',
}

/**
 * Source ids for sources that should be temporarily hidden from order search.
 */
export const tempDisabledSourceIds: string[] = []
