import type { IncomingMessage, ServerResponse } from 'http'
import jwt from 'jsonwebtoken'
import { captureException } from '@lib/sentry'
import { clearJWTCookies, parseJWTCookies } from '../cookies'
import { decrypt, encrypt } from '../encryption'
import { JWT_SECRET } from '../env'
import { JwtCookie, JwtPayload } from '../types'

export function signJWT(
  {
    token,
    organisation,
    scope,
    liveMode,
    userId,
    organisationId,
    email,
  }: JwtPayload,
  sessionExpiryTimeout: number
) {
  const encryptedAccessToken = encrypt(token)
  const encryptedEmail = encrypt(email)

  const jwtPayload: JwtPayload = {
    token: encryptedAccessToken,
    organisation,
    scope,
    liveMode,
    userId,
    organisationId,
    email: encryptedEmail,
  }

  const [header, payload, signature] = jwt
    .sign(jwtPayload, JWT_SECRET, {
      expiresIn: sessionExpiryTimeout,
    })
    .split('.')

  return {
    payload: `${header}.${payload}`,
    signature,
  }
}

export const verifyJWT = (
  req: IncomingMessage,
  res: ServerResponse,
  { payload, signature }: JwtCookie,
  sessionExpiryTimeout: number
): JwtPayload | null => {
  try {
    const fullToken = `${payload}.${signature}`
    const {
      organisation,
      organisationId,
      scope,
      userId,
      liveMode,
      ...encrypted
    } = jwt.verify(fullToken, JWT_SECRET, {
      maxAge: sessionExpiryTimeout,
    }) as any

    return {
      token: decrypt(encrypted.token),
      organisation,
      scope,
      liveMode,
      userId,
      organisationId,
      email: decrypt(encrypted.email),
    }
  } catch (error: any) {
    clearJWTCookies(req, res)
    return null
  }
}

export const decodeJWT = (cookieHeader: string): JwtPayload | undefined => {
  const result = parseJWTCookies(cookieHeader)
  let signature = result.signature
  const payloadStr = result.payload

  signature = signature || 'unsigned'
  if (!payloadStr) return

  const decodedJWT = jwt.decode(`${payloadStr}.${signature}`, {
    json: true,
    complete: true,
  })

  if (!decodedJWT) {
    captureException(new Error(`failed to decode JWT`))
    return undefined
  }

  if (typeof decodedJWT.payload === 'string') {
    captureException(
      new Error(`unexpected JWT payload of type string. Expected an object`)
    )
    return undefined
  }

  // unfortunately we still need to do type assertion here since
  // the JwtPayload returned from the `decode` method is basically
  // any key and any value, so we have no choice.
  // (We could check field-by-field to improve our confidence but that might be overkill for now)
  return decodedJWT.payload as JwtPayload
}
