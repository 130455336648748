import { captureMessage } from '@sentry/core'
import { DuffelPermissions } from '@lib/types'
import { UserRole } from './types'

export const rolesRanking: UserRole[] = [
  'roles/user/personal',
  'roles/user/viewer',
  'roles/user/agent',
  'roles/duffel/travel_ops',
  'roles/user/developer',
  'roles/user/admin',
  'roles/user/owner',
]

export const hasAuthorisation = (
  permissions: DuffelPermissions,
  requiredRole: UserRole
) => {
  if (!permissions) return false

  const { scope } = permissions
  const userAccessRank = rolesRanking.findIndex((role) => role === scope)
  const minimumAccessRank = rolesRanking.findIndex(
    (role) => role === requiredRole
  )

  if (minimumAccessRank === -1) {
    captureMessage(
      `The required role (${requiredRole}) used to check permissions hasn't been recognized`
    )
    return false
  }

  if (userAccessRank === -1) {
    captureMessage(
      `An unknown user role (${scope}) has been used on the dashboard`
    )
    return false
  }

  return userAccessRank >= minimumAccessRank
}
