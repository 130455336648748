import * as React from 'react'
import { Text } from '@components/legacy-design-system/product/components/Text'
import { ChromelessButton } from '@components/Button'
import { HSpace } from '@components/HSpace'
import { Icon } from '@components/Icon'
import { trackEvent } from '@lib/tracking'
import { CloseButton } from './CloseButton'
import { SupportButton } from './SupportButton'
import { SupportContact } from './SupportContact'
import { SupportMenu } from './SupportMenu'

export const Support: React.FC<{ bottomOffset?: number }> = ({
  bottomOffset = 8,
}) => {
  const [view, setView] = React.useState<'button' | 'menu' | 'form'>('button')

  return (
    <div className="support">
      {view === 'button' ? (
        <SupportButton
          onClick={() => {
            trackEvent('dashboard_support_button_clicked', {
              event_type: 'interaction',
            })
            setView('menu')
          }}
        />
      ) : (
        <div className="support-content">
          {view === 'menu' ? (
            <SupportMenu
              onOpenSupportForm={() => setView('form')}
              onClose={() => setView('button')}
            />
          ) : (
            <div className="support-contact">
              <HSpace space={24} className="support-contact__title">
                <ChromelessButton
                  onClick={() => setView('menu')}
                  className="support-contact__back"
                >
                  <Icon name="arrow_left" />
                </ChromelessButton>
                <Text
                  data-selector="fs-show"
                  typeStyle="heading4"
                  fontWeight="bold"
                >
                  Contact support
                </Text>
              </HSpace>
              <SupportContact className="side-support-contact" />
            </div>
          )}
          <CloseButton
            onClick={() => {
              trackEvent('dashboard_support_close_clicked', {
                event_type: 'interaction',
              })
              setView('button')
            }}
          />
        </div>
      )}
      <style jsx>{`
        .support-content {
          background-color: white;
          border-radius: var(--border-radius-8);
          box-shadow: var(--shadow-depth-2);
          animation: support-appear 0.2s;
        }

        :global(.close-button) {
          position: absolute;
          bottom: 8px;
          right: 8px;
        }

        .support {
          position: fixed;
          bottom: ${bottomOffset}px;
          right: 8px;
          z-index: 99;
        }

        .support-contact {
          width: 400px;
          min-height: 608px;
          animation: support-appear 0.2s;
        }

        :global(.side-support-contact) {
          padding: var(--space-24) var(--space-24) 72px var(--space-24);
        }

        @keyframes support-appear {
          0% {
            opacity: 0;
            transform: translateX(8px);
          }

          100% {
            opacity: 1;
            transform: translateX(0);
          }
        }
      `}</style>
    </div>
  )
}
