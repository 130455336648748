import cookie from 'cookie'
import { IncomingMessage } from 'http'
import { CSRF_TOKEN_COOKIE_NAME } from './constants'

export function readCsrfTokenFromCookie(req?: IncomingMessage) {
  const cleanCookieString = (req?.headers.cookie || document.cookie).replace(
    `${CSRF_TOKEN_COOKIE_NAME}=; `,
    ''
  )
  const cookies = cookie.parse(cleanCookieString)
  return cookies[CSRF_TOKEN_COOKIE_NAME]
}
