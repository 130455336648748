import classNames from 'classnames'
import { forwardRef, useEffect } from 'react'
import { Button } from '@components/Button'
import { DateRange } from '@components/DateRangePicker'
import { getDateString } from '@lib/date'
import { getDateRangeString } from '@lib/date/get-date-range-string'
import { DatePickerTargetProps } from './lib'
import { isDateRange } from './lib/is-date-range'
import { setupOnTabListenersOnCalendarDays } from './lib/setup-on-tab-listeners-on-calendar-days'

export interface ButtonDatePickerTargetProps
  extends Omit<DatePickerTargetProps, 'onChange'> {
  value: Date | DateRange | null
  onChange: (value: Date | null) => void
  labelFormatLocale?: string
}

export const ButtonDatePickerTarget = forwardRef<
  HTMLDivElement,
  ButtonDatePickerTargetProps
>(
  (
    { id, isActive, onOpenPopover, onClosePopover, value, labelFormatLocale },
    ref
  ) => {
    useEffect(() => {
      if (isActive) {
        setupOnTabListenersOnCalendarDays(onClosePopover)
      }
    }, [isActive, onClosePopover])

    const buttonLabel =
      (isDateRange(value)
        ? getDateRangeString(value, 'medium', labelFormatLocale)
        : getDateString(value, 'medium', labelFormatLocale)) || 'Select date'

    return (
      <div
        role="button"
        tabIndex={0}
        ref={ref}
        id={id}
        data-testid={id}
        className={classNames('date-picker-form-target', { active: isActive })}
        onClick={onOpenPopover}
        onKeyDown={onOpenPopover}
      >
        <Button intent="MUTED" iconBefore="calendar" text={buttonLabel} />
      </div>
    )
  }
)
