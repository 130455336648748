import { useRouter } from 'next/router'
import { DuffelAPI } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import { captureException } from '@lib/sentry'
import { useUserFriendlyErrors } from '@modules/air-order/lib/use-user-friendly-errors'
import { AxiosResponse } from 'axios'
import { getDuffelAPIClient } from '@lib/duffel-api/getDuffelAPIClient'
import { toCamelCaseKeys } from '@lib/proxy/lib/case-utils'

export interface AdditionalService {
  id: string
  quantity: number
}

export type AdditionalServices = AdditionalService[]

export interface AdditionalServicePayment {
  type: 'balance'
  currency: string
  amount: string
}

export interface AdditionalServiceBooking {
  payment: AdditionalServicePayment
  addServices: AdditionalServices
}

export const useAddServices = (order: DuffelAPI.Types.Order) => {
  const router = useRouter()
  const { addToast } = useWorkspace()
  const { handleOrderErrors } = useUserFriendlyErrors()

  // We only support adding bags to existing orders at the moment.
  // This will be extended when the API supports adding other
  // types of services to the order
  const handleAddServicesToExistingOrder = async (
    services: AdditionalServiceBooking
  ) => {
    const payload = {
      payment: services.payment,
      addServices: services.addServices,
    }
    const client = getDuffelAPIClient(
      undefined,
      undefined,
      router.query.org as string,
      router.query.mode as 'live' | 'test'
    )
    let response: AxiosResponse | null = null
    try {
      response = await client.post(
        `/air/orders/${order.id}/available_services/add`,
        {
          data: payload,
        }
      )
    } catch (error) {
      console.error(error)
      addToast({
        message: 'Error adding baggage to order',
        intent: 'error',
      })

      await router.replace(router.asPath)

      return await handleOrderErrors([], order, {
        actionToSupport: 'add_bags',
        requestId: '',
      })
    }

    if (response) {
      if (response.data['errors']) {
        addToast({
          message: 'Error adding baggage to order',
          intent: 'error',
        })

        await router.replace(router.asPath)

        return await handleOrderErrors(response.data['errors'], order, {
          actionToSupport: 'add_bags',
          requestId: response.data['meta']?.requestId || '',
        })
      }
    } else {
      addToast({
        message: 'Baggage added to order',
        intent: 'success',
      })

      await router.replace(router.asPath)
    }
  }

  // Allow call to fail without notifying user
  // order_not_changeable error is thrown when some/all segments have
  // been flown or when the order has been cancelled.
  const getAvailableServices = async () => {
    const client = getDuffelAPIClient(
      undefined,
      undefined,
      router.query.org as string,
      router.query.mode as 'live' | 'test'
    )
    let response: AxiosResponse | null = null
    try {
      response = await client.get(`/air/orders/${order.id}/available_services/`)
    } catch (error) {
      console.error(error)
    }

    if (response) {
      if (response.data['errors']) {
        // Only report the error to Sentry when it's not order_not_changeable and we have some info to debug
        captureException(
          new Error(`getAvailableServices call failed on order page`),
          {
            ...response.data['meta'],
            ...response.data['errors'][0],
          }
        )
        return null
      } else {
        return toCamelCaseKeys(response.data['data'])
      }
    }
  }

  return { handleAddServicesToExistingOrder, getAvailableServices }
}
