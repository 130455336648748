import { Button } from '@components/Button'
import { Callout } from '@components/Callout'
import {
  Invoice,
  InvoiceBody,
  InvoiceCell,
  InvoiceFooter,
  InvoiceHeader,
  InvoiceRow,
} from '@components/Invoice'
import { CreateOrderService } from '@duffel/api/types'
import { WithServiceInformation } from '@duffel/components'
import { hasValidationErrors } from '@lib/form-validation'
import { getCurrencyDigits, moneyStringFormatter } from '@lib/helpers'
import { trackEvent } from '@lib/tracking'
import { useWorkspace } from '@lib/workspace-context'
import { CheckoutFormValues } from '@modules/air-search-v2/lib'
import { getServicesExtraAmount } from '@modules/air-search-v2/lib/getServicesExtraAmount'
import { useCheckoutContext } from '@modules/air-search-v2/lib/use-checkout-context'
import { DuffelCardFormModal } from '@modules/stays-search/components/StaysCheckoutForm/DuffelCardFormModal'
import { useFormikContext } from 'formik'
import * as React from 'react'
import { scroller } from 'react-scroll'
import { CheckoutPaymentSummaryServices } from './CheckoutPaymentSummaryService'
import {
  onPayWithARCBSPCash,
  onPayWithBalance,
} from './checkout-payment-actions'

const scrollToValidationAlert = () =>
  scroller.scrollTo(`passenger-info-form-alert-container`, {
    duration: 300,
    smooth: true,
    offset: -100,
  })

export const CheckoutPaymentSummary: React.FC<{
  componentClientKey?: string
  isOfferExpired: boolean
}> = ({ componentClientKey, isOfferExpired }) => {
  const { offer, incrementPaymentButtonClickCount, partialOfferRequest } =
    useCheckoutContext()
  const workspaceContext = useWorkspace()
  const [isCardPaymentModalOpen, setIsCardPaymentModalOpen] =
    React.useState(false)
  const { values, validateForm, submitForm, setFieldValue, isSubmitting } =
    useFormikContext<CheckoutFormValues>()

  const services: WithServiceInformation<CreateOrderService>[] =
    values.selectedServicesMetadata
      ? [
          ...values.selectedServicesMetadata.baggage_services,
          ...values.selectedServicesMetadata.seat_services,
          ...values.selectedServicesMetadata.cancel_for_any_reason_services,
        ]
      : []
  const servicesExtraAmount = getServicesExtraAmount(values)

  const currencyDigits = getCurrencyDigits(offer.totalCurrency)
  const totalAmountWithServices = (
    parseFloat(offer.totalAmount) + servicesExtraAmount
  ).toFixed(currencyDigits)

  const isBalancePaymentAllowed =
    offer.availablePaymentTypes?.includes('balance')
  const isBSPPaymentAllowed =
    offer.availablePaymentTypes?.includes('arc_bsp_cash')
  const isCardPaymentAllowed =
    offer.availablePaymentTypes?.includes('card') && componentClientKey

  const isDisabled = isSubmitting || isOfferExpired

  return (
    <div>
      <Invoice>
        <InvoiceHeader>
          <InvoiceRow>
            <InvoiceCell isHeaderCell>Description</InvoiceCell>
            <InvoiceCell textAlign="right" isHeaderCell>
              Price ({offer.baseCurrency})
            </InvoiceCell>
          </InvoiceRow>
        </InvoiceHeader>
        <InvoiceBody>
          <InvoiceRow>
            <InvoiceCell grow>Fare</InvoiceCell>
            <InvoiceCell textAlign="right" isMono>
              {offer.baseAmount}
            </InvoiceCell>
          </InvoiceRow>

          <InvoiceRow>
            <InvoiceCell>Fare taxes</InvoiceCell>
            <InvoiceCell textAlign="right" isMono>
              {offer.taxAmount}
            </InvoiceCell>
          </InvoiceRow>
          <CheckoutPaymentSummaryServices services={services} />
        </InvoiceBody>
        <InvoiceFooter>
          <InvoiceRow>
            <InvoiceCell isFooterCell textAlign="right">
              Total ({offer.totalCurrency})
            </InvoiceCell>
            <InvoiceCell isFooterCell textAlign="right" isMono>
              {totalAmountWithServices}
            </InvoiceCell>
          </InvoiceRow>
        </InvoiceFooter>
      </Invoice>

      <div className="payment-button--container" data-selector="fs-show">
        {isBalancePaymentAllowed && (
          <Button
            disabled={isDisabled}
            text="Pay with Balance"
            onClick={async () => {
              trackEvent('dashboard_offer_checkout_pay_button_clicked', {
                event_type: 'interaction',
                offer_request_id: partialOfferRequest.id,
                offer_id: offer.id,
                payment_type: 'balance',
              })
              incrementPaymentButtonClickCount()
              const result = await validateForm()
              if (!hasValidationErrors(result)) {
                setFieldValue('paymentType', 'balance')
                onPayWithBalance(
                  partialOfferRequest.id,
                  offer,
                  totalAmountWithServices,
                  workspaceContext,
                  submitForm
                )
              } else {
                submitForm() // only used to enable validation warnings
                scrollToValidationAlert()
              }
            }}
            fill
            large
          />
        )}

        {isBSPPaymentAllowed && (
          <Button
            disabled={isDisabled}
            text="Pay with ARC/BSP Cash"
            onClick={async () => {
              trackEvent('dashboard_offer_checkout_pay_button_clicked', {
                event_type: 'interaction',
                offer_request_id: partialOfferRequest.id,
                offer_id: offer.id,
                payment_type: 'arc_bsp_cash',
              })
              incrementPaymentButtonClickCount()
              const result = await validateForm()
              if (!hasValidationErrors(result)) {
                setFieldValue('paymentType', 'arc_bsp_cash')
                onPayWithARCBSPCash(
                  partialOfferRequest.id,
                  offer,
                  totalAmountWithServices,
                  workspaceContext,
                  submitForm
                )
              } else {
                submitForm() // only used to enable validation warnings
                scrollToValidationAlert()
              }
            }}
            fill
            large
            isWaiting={isSubmitting}
          />
        )}

        {isCardPaymentAllowed && (
          <Button
            disabled={isDisabled}
            text="Pay with Credit Card"
            onClick={async () => {
              trackEvent('dashboard_offer_checkout_pay_button_clicked', {
                event_type: 'interaction',
                offer_request_id: partialOfferRequest.id,
                offer_id: offer.id,
                payment_type: 'card',
              })
              incrementPaymentButtonClickCount()
              const result = await validateForm()
              if (!hasValidationErrors(result)) {
                setFieldValue('paymentType', 'card')
                setIsCardPaymentModalOpen(true)
              } else {
                submitForm() // only used to enable validation warnings
                scrollToValidationAlert()
              }
            }}
            intent="MUTED"
            fill
            large
          />
        )}
      </div>

      {isCardPaymentModalOpen && componentClientKey && (
        <DuffelCardFormModal
          clientKey={componentClientKey}
          resourceId={offer.id}
          services={services}
          onClose={() => {
            trackEvent('dashboard_offer_checkout_pay_cancel_button_clicked', {
              event_type: 'interaction',
              offer_request_id: partialOfferRequest.id,
              offer_id: offer.id,
              payment_type: 'card',
            })
            setIsCardPaymentModalOpen(false)
          }}
          buttonText={`Pay ${moneyStringFormatter(offer.totalCurrency)(
            +totalAmountWithServices
          )}`}
          onSubmit={async ({ cardId, threeDSecureSessionId }, setIsLoading) => {
            trackEvent('dashboard_offer_checkout_pay_confirm_button_clicked', {
              event_type: 'interaction',
              offer_request_id: partialOfferRequest.id,
              offer_id: offer.id,
              payment_type: 'card',
            })
            setFieldValue('cardPayment', {
              type: 'card',
              threeDSecureSessionId,
              ...(!threeDSecureSessionId && { cardId }),
              amount: totalAmountWithServices,
              currency: offer.totalCurrency,
            })
            setFieldValue('paymentType', 'card')
            setIsLoading(true)
            // without the set timeout, the form is submitted before the card values are set
            setTimeout(await submitForm, 500)
          }}
        />
      )}

      {isOfferExpired && (
        <Callout intent="warning" iconName="clock" className="u-marginTop16">
          This offer has expired. Please refresh the page or retry searching.
        </Callout>
      )}

      <style jsx>{`
        .payment-button--container {
          display: grid;
          grid-row-gap: 8px;
          margin-top: 32px;
        }
      `}</style>
    </div>
  )
}
