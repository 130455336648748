import { BaseClientConfig, DuffelAPI, DuffelContext } from '@lib/types'
import { BaseClient } from './lib'

export class Places extends BaseClient {
  constructor(config: BaseClientConfig) {
    super(config)
  }

  suggest(query: string, ctx?: DuffelContext) {
    return this.request<DuffelAPI.Types.Place[]>({
      method: 'GET',
      url: `/api/places/suggestions`,
      params: { query },
      ...this.getRequestOptions(ctx),
    })
  }
}
