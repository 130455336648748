import { VSpace } from '@components/VSpace'
import { Heading } from '@components/Heading'

interface CheckoutSectionProps {
  heading: string
}

export const CheckoutSection: React.FC<
  React.PropsWithChildren<CheckoutSectionProps>
> = ({ heading, children }) => (
  <VSpace asElement="section" space={16} data-selector="fs-show">
    <Heading h3 asElement="h2" fontWeight="medium">
      {heading}
    </Heading>
    <div data-selector="fs-show">{children}</div>
  </VSpace>
)

export const CheckoutSubSection: React.FC<
  React.PropsWithChildren<CheckoutSectionProps>
> = ({ heading, children }) => (
  <VSpace asElement="section" space={12} data-selector="fs-show">
    <Heading h5 asElement="h3" color="grey-600" fontWeight="medium">
      {heading}
    </Heading>
    <div>{children}</div>
  </VSpace>
)

export const CheckoutDivider: React.FC = () => (
  <hr
    style={{
      height: '1px',
      border: 'none',
      backgroundColor: 'var(--grey-200)',
    }}
  />
)
