import { isBrowser } from '@lib/env'
import { decodeJWT } from '@lib/jwt'

export const getUserPropertiesFromCookie = () => {
  if (!isBrowser) return {} as any

  const jwt = decodeJWT(document.cookie)
  const organisationId = jwt && jwt.organisationId
  const userId = jwt ? jwt.userId : undefined

  const gaClientId =
    (window['gaData'] && Object.keys(window['gaData'])[0]) || ''

  return {
    ...(userId ? { user_id: userId } : {}),
    ...(organisationId ? { organisation_id: organisationId } : {}),
    ...(gaClientId ? { gaClientId } : {}),
  }
}
