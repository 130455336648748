import * as React from 'react'
import { useRouter } from 'next/router'
import { useWorkspace } from '@lib/workspace-context'
import { NETWORK_ERROR_MESSAGE } from '@lib/constants'
import { ordersShowPathArray } from '@lib/paths'
import { trackEvent } from '@lib/tracking'
import { CheckoutForm } from '@modules/air-search-v2/forms/CheckoutForm/CheckoutForm'

export const CheckoutFormContainer: React.FC<{
  componentClientKey?: string
  isOfferExpired: boolean
}> = ({ componentClientKey, isOfferExpired }) => {
  const { addToast, permissions, closeDialog, updateDialog, duffelClient } =
    useWorkspace()
  const router = useRouter()
  const { offerId, offerRequestId } = router.query
  const offer_id = typeof offerId === 'string' ? offerId : ''
  const offer_request_id =
    typeof offerRequestId === 'string' ? offerRequestId : ''

  return (
    <CheckoutForm
      isOfferExpired={isOfferExpired}
      componentClientKey={componentClientKey}
      onSubmit={async (payload, formValues, formik) => {
        trackEvent('dashboard_offer_checkout_form_submitted', {
          event_type: 'interaction',
          offer_id,
          offer_request_id,
        })

        const payloadWithPayment =
          formValues?.paymentType === 'card' && formValues?.cardPayment
            ? { ...payload, payments: [formValues?.cardPayment] }
            : payload

        const { data, errors, meta } = await duffelClient.Air.createOrder(
          payloadWithPayment
        )

        if (errors) {
          errors.map(({ message }) => {
            const alert_message = `${message} ${
              meta && meta.requestId ? `Request id: ${meta.requestId}` : ''
            }`
            trackEvent('dashboard_offer_checkout_alert_displayed', {
              event_type: 'alert',
              offer_id,
              offer_request_id,
              alert_message,
            })
            addToast({
              intent: 'warning',
              message: alert_message,
              closeAfterTimeout: false,
            })
          })
          formik.setSubmitting(false)
          updateDialog({
            isConfirmButtonLoading: false,
          })
        } else if (!data) {
          const alert_message = `${NETWORK_ERROR_MESSAGE} ${
            meta && meta.requestId ? `Request id: ${meta.requestId}` : ''
          }`
          trackEvent('dashboard_offer_checkout_alert_displayed', {
            event_type: 'alert',
            offer_id,
            offer_request_id,
            alert_message,
          })
          addToast({
            intent: 'warning',
            message: alert_message,
            closeAfterTimeout: false,
          })

          formik.setSubmitting(false)
          updateDialog({
            isConfirmButtonLoading: false,
          })
          closeDialog()
        } else {
          trackEvent('dashboard_offer_checkout_form_completed', {
            event_type: 'api',
            offer_id,
            offer_request_id,
            order_id: data.id,
          })
          addToast({
            intent: 'success',
            message: 'Order created successfully.',
          })
          await router.push(
            ...ordersShowPathArray(
              permissions?.organisation,
              permissions?.liveMode,
              data.id
            )
          )
          closeDialog()
        }
      }}
    />
  )
}
