export const BREAKPOINTS = {
  'breakpoint-mobile': 360 as const,
  'breakpoint-tablet': 768 as const,
  'breakpoint-small-desktop': 1024 as const,
  'breakpoint-desktop': 1440 as const,
}

type BreakpointsMapType = typeof BREAKPOINTS
export type Breakpoint = keyof BreakpointsMapType

export type BreakpointValue = BreakpointsMapType[Breakpoint]
