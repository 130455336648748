import { FilterValue } from '@components/Filters/lib/types'
import {
  NaiveDateTimeFilter,
  getNaiveDateTimeQueryParamFilter,
} from './getNaiveDateTimeQueryParamFilter'

export interface OrderIndexFiltersQueryParams {
  sort?:
    | 'payment_required_by'
    | 'created_at'
    | 'total_amount'
    | 'next_departure'
  awaiting_payment?: boolean
  requires_action?: boolean
  booking_reference?: string
  offer_id?: string
  owner_id?: string[]
  passenger_name?: string[]
  origin_id?: string[]
  destination_id?: string[]
  'departing_at[before]'?: NaiveDateTimeFilter
  'departing_at[after]'?: NaiveDateTimeFilter
  'arriving_at[before]'?: NaiveDateTimeFilter
  'arriving_at[after]'?: NaiveDateTimeFilter
  'created_at[before]'?: NaiveDateTimeFilter
  'created_at[after]'?: NaiveDateTimeFilter
}

export function getOrderIndexFiltersQueryParams(
  orderFilters: FilterValue[] = []
): OrderIndexFiltersQueryParams {
  let queryParams: OrderIndexFiltersQueryParams = {}

  for (const orderFilter of orderFilters) {
    switch (orderFilter.type) {
      case 'passengerName':
        queryParams['passenger_name'] = [orderFilter.value]
        break

      case 'departingAt':
        queryParams = {
          ...queryParams,
          ...getNaiveDateTimeQueryParamFilter(
            'departing_at',
            orderFilter.value
          ),
        }
        break

      case 'arrivingAt':
        queryParams = {
          ...queryParams,
          ...getNaiveDateTimeQueryParamFilter('arriving_at', orderFilter.value),
        }
        break

      case 'createdAt':
        queryParams = {
          ...queryParams,
          ...getNaiveDateTimeQueryParamFilter('created_at', orderFilter.value),
        }
        break

      case 'originId':
        queryParams.origin_id = [orderFilter.value]
        break

      case 'destinationId':
        queryParams.destination_id = [orderFilter.value]
        break

      case 'airlineId':
        queryParams.owner_id = [orderFilter.value]
        break
    }
  }

  return queryParams
}
