import * as React from 'react'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { NEXT_PUBLIC_SEGMENT_WRITE_KEY } from '@lib/env'
import { captureException } from '@lib/sentry'
import { AnalyticsConsentManager } from './AnalyticsConsentManager'
import { AnalyticsConsentManagerBanner } from './AnalyticsConsentManagerBanner'

interface AnalyticsConsentManagerPopupProps {
  analytics?: AnalyticsBrowser
}

export const AnalyticsConsentManagerPopup: React.FC<
  AnalyticsConsentManagerPopupProps
> = ({ analytics }) =>
  analytics ? (
    <AnalyticsConsentManager analytics={analytics}>
      {({ setIsModalOpen, saveConsent, newDestinations }) =>
        newDestinations.length > 0 ? (
          <AnalyticsConsentManagerBanner
            onOpenModal={() => setIsModalOpen(true)}
            onAllow={() => {
              analytics
                .load({
                  writeKey: NEXT_PUBLIC_SEGMENT_WRITE_KEY!,
                })
                .catch((error) => {
                  captureException(error)
                })
                .finally(() => {
                  saveConsent(true, false)
                })
            }}
            onDeny={() => {
              saveConsent(false, false)
            }}
          />
        ) : null
      }
    </AnalyticsConsentManager>
  ) : null
