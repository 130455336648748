import { DuffelAPI } from '@lib/types'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.Airline => ({
    id: `arl_${(seedId++).toString().padStart(5, '0')}`,
    iataCode: 'BA',
    name: 'British Airways',
    logoLockupUrl: null,
    logoSymbolUrl: null,
    conditionsOfCarriageUrl: null,
  })
})()

export const makeMockAirline = (
  extendDefault?: Partial<DuffelAPI.Types.Airline>
): DuffelAPI.Types.Airline =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
