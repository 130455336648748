import * as React from 'react'
import classNames from 'classnames'
import { Button, ButtonProps } from '@components/Button'
import { HSpace } from '@components/HSpace'
import { trackEvent, TrackingEvent } from '@lib/tracking'
import styles from './Drawer.module.css'

export interface ActionTextStatic {
  /**
   * Button text when the drawer is in a closed state
   * Default: 'Advanced options'
   */
  whenDrawerIsClosed?: string

  /**
   * Button text when the drawer is in an open state
   * Default: 'Hide advanced options'
   */
  whenDrawerIsOpen?: string

  styledTextWhenDrawerIsClosed?: never
  styledTextWhenDrawerIsOpen?: never
  secondaryComponent?: never
  styled?: never
}

export interface ActionTextStyled {
  whenDrawerIsClosed?: never
  whenDrawerIsOpen?: never

  /**
   * Button text when the drawer is in an open state
   */
  styledTextWhenDrawerIsClosed?: React.ReactNode

  /**
   * Button text when the drawer is in an open state
   */
  styledTextWhenDrawerIsOpen?: React.ReactNode

  /**
   * Optional component to be displayed alongside styled text
   */
  secondaryComponent?: React.ReactNode
  styled?: boolean
}

export type ActionText = ActionTextStatic | ActionTextStyled

export interface DrawerProps {
  /**
   * Sets drawer initial state. IE - if set to true, will set drawer to open on initial load
   * Default: false
   */
  isOpenInitialState?: boolean

  /**
   * The desired text of the toggle button based on the state of the drawer
   * NOTE: this will override any text passed in via `buttonProps`
   */
  actionText?: ActionText

  /**
   * If passed, will render a <Button /> that uses the props passed in, rather than the default <ChromelessButton />
   * NOTE: the text prop is omitted since the button's text will be controlled by the actionText prop above
   */
  buttonProps?: Omit<ButtonProps, 'text'>

  /**
   * Optional event to send when drawer is toggled
   */
  event?: TrackingEvent

  /**
   * React node to display in the drawer content section
   */
  children: React.ReactNode

  className?: string
}

export const Drawer: React.FC<DrawerProps> = ({
  isOpenInitialState = false,
  actionText = {
    whenDrawerIsClosed: 'Advanced options',
    whenDrawerIsOpen: 'Hide advanced options',
    styledTextWhenDrawerIsClosed: null,
    styledTextWhenDrawerIsOpen: null,
    secondaryComponent: null,
    styled: false,
  },
  buttonProps,
  event,
  className,
  children,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(isOpenInitialState)
  const drawerClassNames = classNames(styles['content'], className)
  const buttonClassNames = classNames(
    styles['drawer-button'],
    buttonProps?.className
  )

  const handleClick = (clickEvent) => {
    clickEvent.preventDefault()
    setIsDrawerOpen(!isDrawerOpen)
    event &&
      trackEvent(event, {
        event_type: 'interaction',
      })
  }

  const {
    styled,
    whenDrawerIsClosed,
    whenDrawerIsOpen,
    styledTextWhenDrawerIsClosed,
    styledTextWhenDrawerIsOpen,
    secondaryComponent,
  } = actionText

  const shouldDisplayButton =
    buttonProps && !styled && whenDrawerIsClosed && whenDrawerIsOpen

  const textAlignment = secondaryComponent
    ? { spaceBetween: true, alignCenter: true }
    : { justify: 'end' as const }

  return (
    <div className={styles['container']}>
      {shouldDisplayButton && (
        <Button
          onClick={handleClick}
          className={buttonClassNames}
          {...buttonProps}
          text={isDrawerOpen ? whenDrawerIsOpen : whenDrawerIsClosed}
        />
      )}

      {!buttonProps && (
        <HSpace space={0} {...textAlignment}>
          <button
            className={classNames(
              styles['drawer-button'],
              styles['drawer-button--default'],
              'u-focusVisibleHighlight'
            )}
            onClick={handleClick}
          >
            {styled &&
              (isDrawerOpen
                ? styledTextWhenDrawerIsOpen
                : styledTextWhenDrawerIsClosed)}

            {!styled && (isDrawerOpen ? whenDrawerIsOpen : whenDrawerIsClosed)}
          </button>
          {secondaryComponent}
        </HSpace>
      )}

      {isDrawerOpen && (
        <div className={drawerClassNames} data-testid="drawerContent">
          {children}
        </div>
      )}
    </div>
  )
}
