import { DuffelAPI } from '@lib/types'
import { InvoiceItemType } from '../Chart/types'
import { calculateExcessSearchPercentChange } from './calculate-excess-search-percent-change'
import { calculateInvoiceItemPercentChange } from './calculate-invoice-item-percent-change'

/**
 * Returns percentage changes for all key metrics for Usage and billing page
 */
export const calculateKeyMetricsPercentChange = (
  billingPeriod: Date,
  maxSearchToBookRatio: number,
  usageRecords: DuffelAPI.Types.UsageRecord,
  previousUsageRecords?: DuffelAPI.Types.UsageRecord
): Record<InvoiceItemType, string> => {
  return {
    'Paid orders': calculateInvoiceItemPercentChange(
      'Paid orders',
      billingPeriod,
      usageRecords,
      previousUsageRecords
    ),
    'Excess searches': calculateExcessSearchPercentChange(
      billingPeriod,
      maxSearchToBookRatio,
      usageRecords,
      previousUsageRecords
    ),
    'Managed content': calculateInvoiceItemPercentChange(
      'Managed content',
      billingPeriod,
      usageRecords,
      previousUsageRecords
    ),
    'Paid ancillaries': calculateInvoiceItemPercentChange(
      'Paid ancillaries',
      billingPeriod,
      usageRecords,
      previousUsageRecords
    ),
  }
}
