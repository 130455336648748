import { TrackingEvent } from '@lib/tracking'
import { OrderManagementMenuItemProps } from './OrderManagementMenuItem'

export const ORDER_MANAGEMENT_ACTION_MENU_ITEM_LABEL_MAP: Record<
  OrderManagementMenuItemProps['orderManagementAction'],
  string
> = {
  add_seats: 'Add seats',
  add_bags: 'Add bags',
  cancel_order: 'Cancellation quote',
  change_order: 'Change flight(s)',
  name_correction: 'Name correction',
  dashboard_other: 'Other requests',
}

export const TEST_MODE_TOOLTIP_TEXT =
  'This booking was created in test mode. In live mode, we will guide you through some additional steps and connect you to our support team.'

export const SELF_MANAGED_TOOLTIP_TEXT =
  'As this order is self-managed, you will need to contact the airline to proceed with your request.'

export const ORDER_MANAGEMENT_ACTION_TRACKING_EVENT_MAP: Partial<
  Record<OrderManagementMenuItemProps['orderManagementAction'], TrackingEvent>
> = {
  change_order: 'dashboard_change_flights_request_change_button_clicked',
}
