import { DuffelAPI } from '@lib/types'
import { getPassengerType } from '.'

export const renderPassengerSelectLabel = (
  passengers: DuffelAPI.Inputs.OfferRequestPassenger[]
) => {
  const adults = passengers.filter((pax) => getPassengerType(pax) === 'adult')
  const children = passengers.filter((pax) => getPassengerType(pax) === 'child')

  let adultLabel: string
  switch (adults.length) {
    case 0:
      adultLabel = ''
      break
    case 1:
      adultLabel = '1 adult'
      break
    default:
      adultLabel = `${adults.length} adults`
  }

  let childrenLabel: string
  switch (children.length) {
    case 0:
      childrenLabel = ''
      break
    case 1:
      childrenLabel = '1 child'
      break
    default:
      childrenLabel = `${children.length} children`
  }

  const delimiter = adultLabel && childrenLabel ? ' and ' : ''

  return `${adultLabel}${delimiter}${childrenLabel}`
}
