import { getISOStringWithoutTimezone } from '@lib/date/get-iso-string-without-timezone'
import { FilterableWebhookEventTypes, OfferSort } from '@lib/types'
import {
  DateFilterCondition,
  FilterType,
  FilterValue,
  maxConnectionsOptions,
  offerSortingOptions,
  orderChangeSortingOptions,
  PendingDateFilterCondition,
  PendingFilterValue,
} from './types'

export const getInitialPendingFilterValues = (
  filterType: FilterType
): PendingFilterValue => {
  switch (filterType) {
    case 'passengerName':
    case 'originId':
    case 'destinationId':
    case 'airlineId':
      return {
        type: filterType,
        value: '',
      }
    case 'departingAt':
    case 'arrivingAt':
    case 'createdAt':
      return {
        type: filterType,
        value: {
          condition: 'before',
        },
      }
    case 'departingAtTime':
    case 'arrivingAtTime':
      return {
        type: filterType,
        value: { from: 0, to: 24 },
      }
    case 'maxConnections':
      return {
        type: filterType,
        value: maxConnectionsOptions[0],
      }
    case 'offerSorting':
      return {
        type: filterType,
        value: offerSortingOptions[0],
      }
    case 'orderChangeSorting':
      return {
        type: filterType,
        value: orderChangeSortingOptions[0],
      }
    case 'webhookEventType':
      return {
        type: filterType,
        value: FilterableWebhookEventTypes[0],
      }
  }

  // if the types are not simple string, we know it's an option filter type
  // Using switch here so that it's more extensible.
  switch (filterType.type) {
    case 'options':
      return {
        type: filterType,
        value: filterType.options[0],
      }
  }
}

type ValidationResult =
  | { isValid: true; filter: FilterValue }
  | { isValid: false }

export const validateDateCondition = (
  filterCondition: PendingDateFilterCondition,
  filterType: 'departingAt' | 'arrivingAt' | 'createdAt'
): ValidationResult => {
  switch (filterCondition.condition) {
    case 'before':
    case 'after':
      if (!filterCondition.date) {
        return { isValid: false }
      }
      return {
        isValid: true,
        filter: {
          type: filterType,
          value: { ...filterCondition, date: filterCondition.date },
        },
      }
    case 'between': {
      const { date1, date2 } = filterCondition
      if (!date1 || !date2) {
        return { isValid: false }
      }
      return {
        isValid: true,
        filter: {
          type: filterType,
          value: { ...filterCondition, date1, date2 },
        },
      }
    }
  }
}

export const validateFilterValue = (
  filter: PendingFilterValue
): ValidationResult => {
  switch (filter.type) {
    case 'passengerName':
    case 'originId':
    case 'destinationId':
    case 'airlineId':
      if (!filter.value || filter.value.length <= 0) {
        return { isValid: false }
      }

      return {
        isValid: true,
        filter: { ...filter, value: filter.value },
      }
    case 'departingAt':
    case 'arrivingAt':
    case 'createdAt':
      return validateDateCondition(filter.value, filter.type)

    case 'departingAtTime':
    case 'arrivingAtTime':
    case 'maxConnections':
    case 'offerSorting':
    case 'orderChangeSorting':
    case 'webhookEventType':
      return { isValid: true, filter }
  }

  switch (filter.type.type) {
    case 'options':
      if (!filter.type.options.find((option) => option === filter.value)) {
        return { isValid: false }
      }
      return {
        isValid: true,
        filter,
      }
  }
}

export const dateConditionToAPIParam = (
  apiFilterName: 'departing_at' | 'arriving_at' | 'created_at',
  dateCondition: DateFilterCondition
) => {
  const params = {}
  switch (dateCondition.condition) {
    case 'before':
      params[`${apiFilterName}[before]`] = getISOStringWithoutTimezone(
        dateCondition.date
      )

      break
    case 'after':
      params[`${apiFilterName}[after]`] = getISOStringWithoutTimezone(
        dateCondition.date
      )

      break
    case 'between': {
      const { date1, date2 } = dateCondition
      if (date1.valueOf() > date2.valueOf()) {
        params[`${apiFilterName}[before]`] = getISOStringWithoutTimezone(date1)
        params[`${apiFilterName}[after]`] = getISOStringWithoutTimezone(date2)
      } else {
        params[`${apiFilterName}[before]`] = getISOStringWithoutTimezone(date2)
        params[`${apiFilterName}[after]`] = getISOStringWithoutTimezone(date1)
      }
    }
  }
  return params
}

export const getFilterNames = (filterType: FilterType): string => {
  switch (filterType) {
    case 'passengerName':
      return 'Passenger name'
    case 'departingAt':
      return 'Departure date'
    case 'arrivingAt':
      return 'Arrival date'
    case 'departingAtTime':
      return 'Take-off time'
    case 'arrivingAtTime':
      return 'Landing time'
    case 'createdAt':
      return 'Creation date'
    case 'originId':
      return 'Origin'
    case 'destinationId':
      return 'Destination'
    case 'airlineId':
      return 'Airline'
    case 'maxConnections':
      return 'Number of stops'
    case 'offerSorting':
    case 'orderChangeSorting':
      return 'Sort'
    case 'webhookEventType':
      return 'Event type'
  }

  return filterType.name
}

export const getSortingFromFilters = (
  filters: FilterValue[]
): OfferSort | undefined => {
  let sort: OfferSort | undefined
  filters.forEach((filter) => {
    if (filter.type !== 'offerSorting') {
      return
    }

    switch (filter.value) {
      case 'Least expensive':
        sort = 'total_amount'
        return
      case 'Most expensive':
        sort = '-total_amount'
        return
      case 'Shortest duration':
        sort = 'total_duration'
        return
      case 'Longest duration':
        sort = '-total_duration'
        return
    }
  })

  return sort
}

export type OrderChangeOfferSorting =
  | 'change_total_amount'
  | '-change_total_amount'
  | 'total_duration'
  | '-total_duration'

export const getOrderChangeSortingFromFilters = (
  filters: FilterValue[]
): OrderChangeOfferSorting | undefined => {
  let sort: OrderChangeOfferSorting | undefined
  filters.forEach((filter) => {
    if (filter.type !== 'orderChangeSorting') {
      return
    }

    switch (filter.value) {
      case 'Least expensive change':
        sort = 'change_total_amount'
        return
      case 'Most expensive change':
        sort = '-change_total_amount'
        return
      case 'Shortest duration':
        sort = 'total_duration'
        return
      case 'Longest duration':
        sort = '-total_duration'
        return
    }
  })

  return sort
}

export const getMaxConnectionsFromFilters = (
  filters: FilterValue[]
): number | undefined => {
  let maxConnections: number | undefined
  filters.forEach((filter) => {
    if (filter.type !== 'maxConnections') {
      return
    }

    maxConnections = maxConnectionsOptions.findIndex(
      (option) => option === filter.value
    )

    // the last index means any number of connections is allowed
    if (
      maxConnections < 0 ||
      maxConnections === maxConnectionsOptions.length - 1
    ) {
      maxConnections = undefined
    }
  })

  return maxConnections
}
