import * as React from 'react'
import { RadioButton, RadioButtonProps } from '@components/RadioButton'
import { HSpace } from '@components/HSpace'
import { VSpace } from '@components/VSpace'

export type RadioGroupOption<T extends string | number> = Pick<
  RadioButtonProps<T>,
  'value' | 'disabled' | 'label' | 'description'
>

export interface RadioGroupProps<T extends string | number> {
  value: T
  options: Array<RadioGroupOption<T>>
  color?: RadioButtonProps<T>['color']
  radioType?: RadioButtonProps<T>['radioType']
  stacked?: boolean
  disabled?: boolean
  onChange: (value: T) => void
}

export const RadioGroup = <T extends string | number>({
  options,
  value,
  color = 'purple',
  radioType = 'tick',
  stacked = false,
  disabled = false,
  onChange,
}: RadioGroupProps<T>) => {
  const handleChange = (optionValue) => {
    if (value === optionValue) return
    onChange(optionValue)
  }

  const SpaceElement = stacked ? VSpace : HSpace

  return (
    <SpaceElement space={stacked ? 8 : 24}>
      {options.map((option) => {
        const checked = value === option.value

        return (
          <RadioButton
            key={option.value}
            value={option.value}
            checked={checked}
            color={color}
            radioType={radioType}
            label={option.label}
            description={option.description}
            disabled={option.disabled || disabled}
            onChange={() => handleChange(option.value)}
          />
        )
      })}
    </SpaceElement>
  )
}
