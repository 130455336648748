import { AddBillingDetailsModal } from '@components/AddBillingDetailsModal'
import { Dialog, DialogProps } from '@components/Dialog'
import { Heading } from '@components/Heading'
import { Support, SupportModal, SupportModalProps } from '@components/Support'
import { PrivateDashboardStreamKeysResponse } from '@components/SupportChat/lib/fetchSupportChatData'
import { Text } from '@components/Text'
import { ToastProps, Toaster } from '@components/Toaster'
import { VSpace } from '@components/VSpace'
import { createDuffelClient } from '@lib/duffel-client'
import { DuffelClientClass } from '@lib/duffel-client/Client'
import { isDev } from '@lib/env'
import { signOutAndRedirect } from '@lib/security'
import { captureException } from '@lib/sentry'
import {
  FirestoreUserProfile,
  updateFirestoreUserDismissedFeedCards,
} from '@lib/tracking'
import { getUnleashContextObject } from '@lib/unleash'
import { useUnleashContext } from '@unleash/proxy-client-react'
import { NextRouter, useRouter } from 'next/router'
import * as React from 'react'
import {
  DuffelPermissions,
  DuffelProxy,
  StripeConnectVerificationStatus,
} from '../types'
import {
  acceptInvitation,
  addOrganisationToUser,
  addToast,
  closeAllToasts,
  closeDialog,
  closeToast,
  declineInvitation,
  openDialog,
  setMode,
  setPermissions,
  updateCurrentOrganisation,
  updateDialog,
  updateUser,
} from './workspace-context-actions'
import { getDuffelAPIClient } from '@lib/duffel-api/getDuffelAPIClient'

// Types to allow the custom element to be used in JSX
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'duffel-assistant': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
  const openDuffelAssistant: (props: {
    clientKey: string
    environment?: string
    issueType?: 'cancellation' | 'change' | 'other'
  }) => void
}

export interface WorkspaceContextState {
  toasts: ToastProps[]
  user: DuffelProxy.Types.Self | null
  permissions: DuffelPermissions | null
  dialog: DialogProps | null
  currentOrganisation: DuffelProxy.Types.SelfOrganisation | null
  supportModal: SupportModalProps & { isOpen: boolean }
  hideSupportButton: boolean
  firestoreUserProfile: FirestoreUserProfile
  stripeConnectVerificationStatus:
    | StripeConnectVerificationStatus
    | null
    | 'loading'
  duffelClient: DuffelClientClass
  /**
   * Opens support chat for given `resourceID` (order ID or booking ID) and `supportType`.
   *
   * - Joins existing channel specific to resource ID and support type.  _or_
   * - For existing Zendesk tickets of status ongoing: Agent joins conversation. _or_
   *   - in this situation and the above, the channel is already on the agent UI queue
   * - For existing Zendesk tickets of status hold: Ticket status updates to ongoing.
   *   - in this situation, when the status is set to ongoing, the channel shows up in the agent UI queue
   */
  openAssistantChat: (
    resourceID: PrivateDashboardStreamKeysResponse['resource_id'],
    supportType: PrivateDashboardStreamKeysResponse['support_type']
  ) => void
}

export interface WorkspaceContextUIState {
  state: { [key: string]: any }
  setState: (state: { [key: string]: any }) => void
}
export interface WorkspaceContextMethods {
  acceptInvitation: (id: string) => Promise<any>
  declineInvitation: (id: string) => Promise<any>
  updateUser: (user: Partial<DuffelProxy.Types.Self>) => void
  addOrganisationToUser: (
    organisation: DuffelProxy.Types.SelfOrganisation
  ) => void
  updateCurrentOrganisation: (
    organisation: Partial<DuffelProxy.Types.SelfOrganisation>
  ) => void
  setPermissions: (permissions: DuffelPermissions) => void
  setMode: (isLive: boolean) => void
  addToast: (props: Partial<ToastProps>) => void
  closeToast: (index: number) => void
  closeAllToasts: () => void
  openDialog: (config: DialogProps) => void
  updateDialog: (config: Partial<DialogProps>) => void
  closeDialog: () => void
  openSupportModal: (usingProps?: Partial<SupportModalProps>) => void
  closeSupportModal: () => void
  setHideSupportButton: (isHidden: boolean) => void
  openSessionExpiredDialog: () => void
  openBillingModal: () => void
  setSupportButtonBottomOffset: (offset: number) => void
  ui: WorkspaceContextUIState
}

export type WorkspaceContextType = WorkspaceContextState &
  WorkspaceContextMethods

const noOp = () => {
  console.warn(
    'This function has not been properly initialised by the WorkspaceContext'
  )
}

export const defaultWorkspaceValue: WorkspaceContextType = {
  toasts: [],
  user: null,
  permissions: null,
  dialog: null,
  currentOrganisation: null,
  supportModal: { isOpen: false, onClose: noOp, initialCategory: undefined },
  hideSupportButton: false,
  acceptInvitation: () => Promise.resolve(null),
  declineInvitation: () => Promise.resolve(null),
  updateUser: noOp,
  addOrganisationToUser: noOp,
  updateCurrentOrganisation: noOp,
  setPermissions: noOp,
  setMode: noOp,
  addToast: noOp,
  closeToast: noOp,
  closeAllToasts: noOp,
  openDialog: noOp,
  updateDialog: noOp,
  closeDialog: noOp,
  openSupportModal: noOp,
  closeSupportModal: noOp,
  setHideSupportButton: noOp,
  ui: {
    state: {},
    setState: noOp,
  },
  firestoreUserProfile: {},
  stripeConnectVerificationStatus: null,
  openSessionExpiredDialog: noOp,
  openBillingModal: noOp,
  setSupportButtonBottomOffset: noOp,
  duffelClient: createDuffelClient(),
  openAssistantChat: noOp,
}

export const WorkspaceContext = React.createContext<WorkspaceContextType>(
  defaultWorkspaceValue
)

interface WithWorkspaceProps {
  user?: DuffelProxy.Types.Self
  permissions?: DuffelPermissions
  firestoreUserProfile?: FirestoreUserProfile
}

const SessionExpiredDialog = () => (
  <div className="session-expired-dialog-contents" data-selector="fs-show">
    <VSpace space={8}>
      <Heading h3 textAlign="center" fontWeight="medium">
        Your session has expired
      </Heading>
      <Text color="grey-600" textAlign="center">
        To continue using Duffel, please sign in.
      </Text>
    </VSpace>
    <style jsx>{`
      .session-expired-dialog-contents {
        padding: var(--space-32);
      }
    `}</style>
  </div>
)

export const WithWorkspace: React.FC<
  React.PropsWithChildren<WithWorkspaceProps>
> = ({ children, user, permissions, firestoreUserProfile = {} }) => {
  const updateUnleashContext = useUnleashContext()
  const [userState, setUserState] = React.useState(user || null)
  const [permissionsState, setPermissionsState] = React.useState(
    permissions || null
  )
  const [toastState, setToastState] = React.useState<ToastProps[]>([])
  const [dialogState, setDialogState] =
    React.useState<WorkspaceContextState['dialog']>(null)
  const [supportModalIsOpen, setSupportModalIsOpen] = React.useState(false)
  const [supportModalProps, setSupportModalProps] =
    React.useState<SupportModalProps>({
      onClose: () => setSupportModalIsOpen(false),
    })

  const [uiState, setUiState] = React.useState<any>({})
  const [hideSupportButtonState, setHideSupportButtonState] =
    React.useState<boolean>(false)

  // As we have the footer UI on some pages, we need to move
  // the support button out of the way sometimes. In the future, we might
  // move the footer into the workspace context so that this behavior can be
  // more predictable.
  const [supportButtonBottomOffset, setSupportButtonBottomOffset] =
    React.useState<number>(8)
  const router = useRouter()

  const currentOrganisation =
    user?.organisationsBySlug[permissions?.organisation || ''] || null

  const addToastFn = addToast(toastState, setToastState)
  const closeToastFn = closeToast(toastState, setToastState)
  const closeAllToastsFn = closeAllToasts(toastState, setToastState)
  const setUiStateFn = (state: { [key: string]: any }) => {
    const newState = Object.assign({}, uiState, state)
    setUiState(newState)
  }
  const openSessionExpiredDialog = (router: NextRouter) =>
    openDialog(setDialogState)({
      customRenderer: SessionExpiredDialog,
      disableCloseOnEscapeKey: true,
      disableCloseOnClickOutside: true,
      confirmButtonLabel: 'Sign in',
      onConfirm: () => {
        signOutAndRedirect(router.asPath)
        closeDialog(setDialogState)()
      },
    })

  React.useEffect(() => {
    if (user) setUserState(user)
    if (permissions) setPermissionsState(permissions)

    if (user && permissions) {
      updateUnleashContext(getUnleashContextObject(user, permissions))
    }
  }, [user, permissions])

  // ensure that we don't reinitiate duffel client unless necessary, as this
  // will be part of a lot of useEffect dependencies
  const duffelClient = React.useMemo(() => {
    return createDuffelClient(permissions?.organisation, permissions?.liveMode)
  }, [permissions?.organisation, permissions?.liveMode])

  React.useEffect(() => {
    if (isDev) {
      return
    }
    let timeout: NodeJS.Timeout | null = null
    if (permissions?.exp) {
      const duration = +permissions?.exp * 1000 - Date.now()
      timeout = setTimeout(() => openSessionExpiredDialog(router), duration)
    } else if (timeout) {
      clearTimeout(timeout)
    }

    return () => {
      timeout && clearTimeout(timeout)
    }
  }, [permissions?.exp, router])

  const [stripeConnectVerificationStatus, setStripeConnectVerificationStatus] =
    React.useState<WorkspaceContextState['stripeConnectVerificationStatus']>(
      currentOrganisation?.verificationFlow === 'stripe_connect'
        ? 'loading'
        : null
    )
  React.useEffect(() => {
    if (currentOrganisation?.verificationFlow !== 'stripe_connect') {
      setStripeConnectVerificationStatus(null)
      return
    }

    const fetchStripeVerificationStatus = async () => {
      setStripeConnectVerificationStatus('loading')
      const { data, errors } =
        await duffelClient.Identity.getStripeConnectVerificationStatus()
      if (errors || !data) {
        setStripeConnectVerificationStatus(null)

        // only capture errors that are not authentication errors
        if (errors?.find((error) => error.type !== 'authentication_error')) {
          captureException(
            new Error('Unable to get the stripe verification status'),
            {
              organisationId: currentOrganisation?.id,
              errors: JSON.stringify(errors),
            }
          )
        }
        return
      }
      setStripeConnectVerificationStatus(data.stripeConnectVerificationStatus)
    }

    fetchStripeVerificationStatus()
  }, [
    currentOrganisation?.verificationFlow,
    currentOrganisation?.id,
    duffelClient,
  ])

  const [isBillingModalOpen, setBillingModalOpen] =
    React.useState<boolean>(false)

  const workspaceContextValue: WorkspaceContextType = {
    user: userState,
    permissions: permissionsState,
    toasts: toastState,
    dialog: dialogState,
    supportModal: { ...supportModalProps, isOpen: supportModalIsOpen },
    currentOrganisation: currentOrganisation,
    hideSupportButton: hideSupportButtonState,
    acceptInvitation: acceptInvitation(
      duffelClient,
      userState,
      setUserState,
      addToastFn
    ),
    declineInvitation: declineInvitation(
      duffelClient,
      userState,
      setUserState,
      addToastFn
    ),
    setMode: setMode(permissionsState, setPermissionsState),
    updateUser: updateUser(userState, setUserState),
    addOrganisationToUser: addOrganisationToUser(userState, setUserState),
    updateCurrentOrganisation: updateCurrentOrganisation(
      userState,
      permissions,
      setUserState
    ),
    setPermissions: setPermissions(permissionsState, setPermissionsState),
    addToast: addToastFn,
    closeToast: closeToastFn,
    closeAllToasts: closeAllToastsFn,
    openDialog: openDialog(setDialogState),
    updateDialog: updateDialog(setDialogState),
    closeDialog: closeDialog(setDialogState),
    openSupportModal: (usingProps?: Partial<SupportModalProps>) => {
      setSupportModalIsOpen(true)
      setSupportModalProps({
        ...supportModalProps,
        ...usingProps,
      })
    },
    closeSupportModal: () => {
      setSupportModalIsOpen(false)
    },
    setHideSupportButton: setHideSupportButtonState,
    ui: {
      state: uiState,
      setState: setUiStateFn,
    },
    firestoreUserProfile,
    stripeConnectVerificationStatus,
    openSessionExpiredDialog: () => openSessionExpiredDialog(router),

    openBillingModal: () => setBillingModalOpen(true),

    setSupportButtonBottomOffset,

    duffelClient,
    openAssistantChat: async (
      resourceID: PrivateDashboardStreamKeysResponse['resource_id'],
      supportType: PrivateDashboardStreamKeysResponse['support_type']
    ) => {
      if (!user || !permissions) {
        // attempt to open the assistant chat without a user
        return
      }

      const client = getDuffelAPIClient(
        undefined,
        undefined,
        permissions.organisation,
        permissions.liveMode ? 'live' : 'test'
      )
      const response = await client.post('/identity/component_client_keys', {
        data: {
          user_id: user.id,
          ...(resourceID.startsWith('ord_') && { order_id: resourceID }),
          ...(resourceID.startsWith('bok_') && { booking_id: resourceID }),
        },
      })
      const responseData = response.data

      const assistantSupportType =
        {
          cancel_order: 'cancellation',
          change_order: 'change',
        }[supportType] || 'other'

      const assistantProperties = {
        environment: process.env.NEXT_PUBLIC_APP_ENV,
        clientKey: responseData['data']['component_client_key'],
        issueType: assistantSupportType,
      }

      console.info('Opening assistant with properties', assistantProperties)
      openDuffelAssistant(assistantProperties)
    },
  }

  return (
    <WorkspaceContext.Provider value={workspaceContextValue}>
      {children}

      <duffel-assistant></duffel-assistant>

      <Toaster
        addToast={addToastFn}
        closeToast={closeToastFn}
        toasts={toastState}
        closeAllToasts={closeAllToastsFn}
      />
      {dialogState && <Dialog {...dialogState} />}
      {!hideSupportButtonState && (
        <>
          <Support bottomOffset={supportButtonBottomOffset} />
          {supportModalIsOpen && <SupportModal {...supportModalProps} />}
        </>
      )}
      {isBillingModalOpen && (
        <AddBillingDetailsModal
          id="add-billing-details"
          stripeCustomerId={currentOrganisation?.stripeCustomerId ?? ''}
          onBillingDetailsAdded={async () => {
            await updateFirestoreUserDismissedFeedCards('add-billing-details')

            // reload the page for now so that the user can get the latest verified status
            // TODO: maybe we can make the experience more seamless?
            window.location.reload()
          }}
          onClose={() => setBillingModalOpen(false)}
        />
      )}
    </WorkspaceContext.Provider>
  )
}

export const useWorkspace = () =>
  React.useContext<WorkspaceContextType>(WorkspaceContext)
