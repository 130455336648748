import { DuffelAPI, TravelDetails } from '@lib/types'

interface BeforeAndAfterSegmentsForDiff {
  beforeSegmentsForDiff: TravelDetails<'diff'>[]
  afterSegmentsForDiff: TravelDetails<'diff'>[]
}
/**
 * This function transforms before and after segments into a structure that is intended to make it easier to diff.
 * The key of each segment is its origin-destination.
 *
 * @param segments of 'before' and 'after' slices
 * @returns BeforeAndAfterSegmentsForDiff
 */
export const getSegmentsInDiffForm = (
  beforeSegments: DuffelAPI.Types.OrderSliceSegment[],
  afterSegments: DuffelAPI.Types.OrderSliceSegment[]
): BeforeAndAfterSegmentsForDiff => {
  const parse = (segments: DuffelAPI.Types.OrderSliceSegment[]) =>
    segments.map((segment) => {
      const {
        departingAt,
        origin,
        arrivingAt,
        destination,
        marketingCarrier,
        marketingCarrierFlightNumber,
        operatingCarrier,
        operatingCarrierFlightNumber,
      } = segment

      return {
        originDestination: `${origin.iataCode}-${destination.iataCode}`,
        marketingCarrier: marketingCarrier.name,
        marketingCarrierIataCode: marketingCarrier.iataCode || '',
        marketingCarrierFlightNumber: marketingCarrierFlightNumber,
        operatingCarrier: operatingCarrier.name,
        operatingCarrierIataCode: operatingCarrier.iataCode || '',
        operatingCarrierFlightNumber: operatingCarrierFlightNumber,
        departingAt: departingAt,
        arrivingAt: arrivingAt,
        origin: segment.origin.iataCode || '',
        destination: segment.destination.iataCode || '',
        aircraft: segment.aircraft?.name || '',
        cabinClass: segment.passengers[0]?.cabinClass || '',
        flight: marketingCarrierFlightNumber,
        flightDuration: segment.duration,
        airline: null,
      }
    })

  return {
    beforeSegmentsForDiff: parse(beforeSegments),
    afterSegmentsForDiff: parse(afterSegments),
  }
}
