import classNames from 'classnames'
import { ColorWithoutWeight } from '@components/legacy-design-system/product/styles'

export const getHighlightStyles = (
  styles: any,
  highlightAll: boolean,
  key?: string,
  keysToHighlight?: string[],
  highlightColor?: ColorWithoutWeight
) => {
  const highlightClasses = classNames(
    [styles['highlight']],
    styles[`highlight--${highlightColor}`]
  )

  return {
    ...((highlightAll || (key && keysToHighlight?.includes(key))) && {
      className: highlightClasses,
    }),
  }
}
