import { captureException } from '@lib/sentry'
import { MeiliSearchRequestError } from 'meilisearch'
import { NextApiHandler, NextApiRequest, NextApiResponse } from 'next'
import { saveSelfInvalidationTimeToCookie } from './cookie'
import {
  CsrfError,
  ModeMismatchError,
  NotAuthorisedError,
  NotFoundError,
  UnprocessableEntityError,
  csrfTokenErrorResponseFixture,
  getModeMismatchErrorResponseFixture,
  getUnprocessableEntityError,
  notAuthorisedErrorResponseFixture,
  notFoundErrorResponseFixture,
  unexpectedErrorResponseFixture,
} from './error-responses'
import { getRequestParams } from './get-request-params'
import { logRequest } from './logging/logRequest'
import { maybeGetJWT } from './maybe-get-jwt'
import selfCache from './self-cache'
import { sendCompressed } from './send-compressed'

export type EndpointFunction = (
  req: NextApiRequest,
  res: NextApiResponse
) => void
export type Endpoint = (fn: EndpointFunction) => EndpointFunction

export const endpoint: Endpoint = (endpointFunction: NextApiHandler) => {
  return async (req: NextApiRequest, res: NextApiResponse) => {
    const requestReceivedAt = Date.now()
    try {
      // clear the self cache if the request impacts identity so that
      // the next request can get the new one
      const { path, method } = await getRequestParams(req)
      if (
        ['POST', 'PUT', 'PATCH', 'DELETE'].includes(method) &&
        path.includes('/identity')
      ) {
        const jwt = maybeGetJWT(req, res)
        if (jwt?.token) {
          selfCache.remove(jwt.token)
        }

        // set the invalidation cookie as well so we can invalidate other servers
        saveSelfInvalidationTimeToCookie(req, res)
      }
    } catch (error: unknown) {
      captureException(new Error('Failed to detect and clear self cache.'))
    }

    try {
      await endpointFunction(req, res)
      logRequest(req, res, requestReceivedAt)
    } catch (error: unknown) {
      console.error(`[proxy error]`, { error })

      const headersWithoutCookies = Object.assign({}, req.headers, {
        // removes cookie so we don't send it to sentry
        cookie: null,
      })

      logRequest(req, res, requestReceivedAt, { severity: 'error' })

      if (error instanceof CsrfError) {
        sendCompressed(
          res,
          csrfTokenErrorResponseFixture.meta.status,
          csrfTokenErrorResponseFixture
        )
        captureException(error, {
          proxy: true,
          url: req.url,
          method: req.method,
          headers: headersWithoutCookies,
          query: req.query,
        })
        return
      }

      if (error instanceof NotAuthorisedError) {
        sendCompressed(
          res,
          notAuthorisedErrorResponseFixture.meta.status,
          notAuthorisedErrorResponseFixture
        )
        return
      }

      if (error instanceof NotFoundError) {
        sendCompressed(
          res,
          notFoundErrorResponseFixture.meta.status,
          notFoundErrorResponseFixture
        )
        return
      }

      if (error instanceof UnprocessableEntityError) {
        const errorFixture = getUnprocessableEntityError(error.invalidParam)
        sendCompressed(res, errorFixture.meta.status, errorFixture)
        captureException(error, {
          proxy: true,
          url: req.url,
          method: req.method,
          headers: headersWithoutCookies,
          query: req.query,
        })
        return
      }

      if (error instanceof ModeMismatchError) {
        const modeMismatchErrorResponseFixture =
          getModeMismatchErrorResponseFixture(error.message)
        captureException(error, {
          proxy: true,
          url: req.url,
          method: req.method,
          headers: headersWithoutCookies,
          query: req.query,
        })
        sendCompressed(
          res,
          modeMismatchErrorResponseFixture.meta.status,
          modeMismatchErrorResponseFixture
        )
        return
      }

      if (error instanceof MeiliSearchRequestError) {
        const { message } = error
        captureException(error, {
          proxy: true,
          url: req.url,
          method: req.method,
          message,
          query: req.query,
        })
        sendCompressed(res, 500, {
          errors: [
            {
              ...error,
              message,
            },
          ],
          meta: {
            request_id: null,
            status: 500,
          },
        })
        return
      }

      // otherwise, it's an unknown error so we try our best to capture it
      sendCompressed(res, 500, unexpectedErrorResponseFixture)

      captureException(
        error instanceof Error ? error : new Error(JSON.stringify(error)),
        {
          proxy: true,
          url: req.url,
          method: req.method,
          headers: headersWithoutCookies,
          query: req.query,
        }
      )
    }
  }
}
