import { motion } from 'framer-motion'

export const Fade: React.FC<
  React.PropsWithChildren<{ delay?: number; duration?: number }>
> = ({ delay, duration = 0.5, children }) => (
  <motion.div
    initial="hidden"
    animate="visible"
    exit="hidden"
    variants={{
      hidden: { opacity: 0, transition: { duration, delay } },
      visible: { opacity: 1, transition: { duration, delay } },
    }}
  >
    {children}
  </motion.div>
)
