import { useRef, useEffect } from 'react'

export function useInterval(
  callback: () => void,
  delay: number | null,
  reset?: boolean
) {
  const savedCallback = useRef<() => void>()
  const savedReset = useRef<boolean | undefined>(undefined)

  useEffect(() => {
    savedCallback.current = callback
    savedReset.current = reset
  }, [callback, reset])

  useEffect(() => {
    function tick() {
      savedCallback.current && savedCallback.current()
    }
    if (delay !== null && reset === savedReset.current) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay, reset])
}
