import cookie from 'cookie'
import { ServerResponse } from 'http'
import { addHeaderToServerResponse } from './addHeaderToServerResponse'
import { CSRF_TOKEN_COOKIE_NAME, CSRF_TOKEN_COOKIE_OPTIONS } from './constants'

export function writeCsrfTokenToCookie(
  csrfToken: string,
  res?: ServerResponse
) {
  if (res) {
    addHeaderToServerResponse(
      'set-cookie',
      [
        cookie.serialize(
          CSRF_TOKEN_COOKIE_NAME,
          csrfToken,
          CSRF_TOKEN_COOKIE_OPTIONS
        ),
      ],
      res
    )
  } else {
    document.cookie = csrfToken
  }
}
