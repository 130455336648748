import * as React from 'react'
import { ButtonProps, ChromelessButton } from '@components/Button'
import { Icon } from '@components/Icon'

export const SupportButton: React.FC<Partial<ButtonProps>> = ({ onClick }) => (
  <ChromelessButton className="support-button" onClick={onClick}>
    <Icon name="question_mark" className="support-button__icon" />
    <style jsx>{`
      :global(.support-button) {
        height: 40px;
        width: 40px;
        background-color: var(--grey-900) !important;
        color: white !important;
        border-radius: 50% !important;
        box-shadow: var(--shadow-depth-2);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 8px !important;
      }

      :global(.support-button):hover {
        cursor: pointer;
        background-color: var(--grey-800) !important;
      }

      :global(.support-button):active {
        background-color: var(--grey-200) !important;
      }

      :global(.support-button):focus-visible {
        border: 2px solid var(--purple-500) !important;
      }

      .support-button__icon {
        margin: auto;
      }
    `}</style>
  </ChromelessButton>
)
