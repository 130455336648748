import { Component } from 'react'
import { ErrorView } from '@components/ErrorView'
import { captureException } from '@lib/sentry'

interface State {
  hasError: boolean
  error?: Error
}

export class ErrorBoundary extends Component<
  React.PropsWithChildren<Record<string, unknown>>,
  State
> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    captureException(error)
    return { hasError: true, error }
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <div className="error-boundary-message">
            <ErrorView title="Something went wrong"></ErrorView>
          </div>
          <style jsx>{`
            .error-boundary-message {
              max-height: 50vh !important;
              overflow: hidden;
            }
          `}</style>
        </>
      )
    }

    return this.props.children
  }
}
