import { BaseClientConfig, DuffelContext } from '@lib/types'
import {
  ZendeskCreateTicketResponse,
  ZendeskGetTicketsResponse,
  ZendeskGetUserEmailAddressResponse,
  ZendeskSupportTicketRequestPayload,
} from '@lib/zendesk'
import { BaseClient } from './lib'

export class Zendesk extends BaseClient {
  constructor(config: BaseClientConfig) {
    super(config)
  }

  getSupportTickets(orderId: string, ctx?: DuffelContext) {
    return this.request<ZendeskGetTicketsResponse>({
      method: 'GET',
      url: '/api/zendesk/get-tickets',
      params: {
        orderId,
      },
      ...this.getRequestOptions(ctx),
    })
  }

  getDuffelLinksRequestTickets(ctx?: DuffelContext) {
    return this.request<ZendeskGetTicketsResponse>({
      method: 'GET',
      url: '/api/zendesk/get-tickets',
      params: {
        tags: 'duffel-links-access',
      },
      ...this.getRequestOptions(ctx),
    })
  }

  createSupportTicket(
    payload: ZendeskSupportTicketRequestPayload,
    ctx?: DuffelContext
  ) {
    return this.request<ZendeskCreateTicketResponse>({
      method: 'POST',
      url: '/api/zendesk/create-ticket',
      data: {
        ticket: payload,
      },
      ...this.getRequestOptions(ctx),
    })
  }

  getUserEmailAddress(userId: number, ctx?: DuffelContext) {
    return this.request<ZendeskGetUserEmailAddressResponse>({
      method: 'GET',
      url: '/api/zendesk/get-user-email-address',
      params: {
        userId,
      },
      ...this.getRequestOptions(ctx),
    })
  }

  authenticateUser(ctx?: DuffelContext) {
    return this.request<ZendeskGetUserEmailAddressResponse>({
      method: 'GET',
      url: '/api/zendesk/authenticate',
      ...this.getRequestOptions(ctx),
    })
  }
}
