import { DateRange, safelyGetDateRange } from '@components/DateRangePicker'
import { isRangeComplete } from './is-range-complete'

export const addValueToDateRange = (
  dateRange: DateRange | null,
  newValue: Date
): DateRange => {
  if (!dateRange || isRangeComplete(dateRange))
    return { start: newValue, end: null }

  const { start, end } = dateRange

  if (!start && !end) return { start: newValue, end: null }
  else if (!start)
    return safelyGetDateRange({ start: newValue, end }) as DateRange
  else return safelyGetDateRange({ start, end: newValue }) as DateRange
}
