import https from 'https'
import type { IncomingHttpHeaders } from 'http'
import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
  CanceledError,
} from 'axios'
import { CSRF_HEADER_NAME, getCSRFToken } from '@lib/csrf'
import { BasicEnv } from '@lib/env'
import { captureException } from '@lib/sentry'
import {
  APIResponse,
  APIResponseError,
  BaseClientConfig,
  DuffelContext,
  PaginationMeta,
  requestHookFunction,
  responseHookFunction,
} from '@lib/types'
import { getUrl } from './get-url'

export const LIVE_MODE_HEADER_NAME = 'x-live-mode'
export const ORG_HEADER_NAME = 'x-org'

const getGenericResponseErrorFromStatusCode = (
  statusCode: number
): APIResponseError | undefined => {
  switch (statusCode) {
    case 400:
      return {
        code: 'bad_request',
        title: 'Bad Request',
        message: 'The request was invalid.',
      }
    case 401:
      return {
        type: 'authentication_error',
        code: 'unauthorised',
        title: 'Unauthorised',
        message: 'The request was unauthorised.',
      }
    case 403:
      return {
        code: 'forbidden',
        title: 'Forbidden',
        message: 'The request was forbidden.',
      }
    case 404:
      return {
        code: 'not_found',
        title: 'Not Found',
        message: 'The requested resource was not found.',
      }

    case 500:
      return {
        code: 'internal_server_error',
        title: 'Internal Server Error',
        message: 'Something went wrong. Please try again later.',
      }
  }
}
export class BaseClient {
  env: BasicEnv
  axios: AxiosInstance
  org?: string
  liveMode?: boolean

  constructor(config: BaseClientConfig) {
    this.env = config.env
    this.org = config.org
    this.liveMode = config.liveMode
    const httpsAgent = new https.Agent({ rejectUnauthorized: false })

    this.axios = Axios.create({
      httpsAgent,
    })

    this.axios.interceptors.response.use(
      (res: AxiosResponse) => {
        return res
      },
      (err: any): Promise<never> => {
        // reject promise if the request has been cancelled
        if (err instanceof CanceledError) {
          return Promise.reject(err)
        }

        if (
          err.response &&
          err.response.data &&
          Object.keys(err.response.data).length > 0
        ) {
          return Promise.reject(err.response.data)
        }

        // if it's an axios error and there is no data, we will create a generic error based on the response status
        if (err instanceof AxiosError) {
          const genericResponseError = getGenericResponseErrorFromStatusCode(
            err.response?.status || 500
          )
          if (genericResponseError) {
            return Promise.reject({ errors: [genericResponseError] })
          }
        }

        return Promise.reject({
          code: 'refused_connection',
          message: 'Failed to connect. Please try again.',
        })
      }
    )
  }

  async request<T_APIResponseData>(
    config: AxiosRequestConfig
  ): Promise<APIResponse<T_APIResponseData>> {
    try {
      this.hooks.beforeSendRequest.forEach((hook) => (config = hook(config)))
      let result = await this.axios(config)
      this.hooks.onReceiveResponse.forEach((hook) => (result = hook(result)))

      // if the error message has the word request id in it, put the request id right into the message
      const data: APIResponse<T_APIResponseData> = result.data
      if (data.errors && Array.isArray(data.errors)) {
        data.errors = data.errors.map((error) => {
          if (!data.meta?.requestId) {
            return error
          }
          return {
            ...error,
            message: error.message.replace(
              'request_id',
              `request_id: ${data.meta.requestId}`
            ),
          }
        })
      }

      return data
    } catch (err: unknown) {
      return this.handleErrorResponse<T_APIResponseData>(err, config)
    }
  }

  handleErrorResponse<T>(
    err: unknown,
    config: AxiosRequestConfig
  ): APIResponse<T> {
    const errorResult = err as any
    if (Array.isArray(errorResult['errors']) && errorResult['meta']) {
      if (errorResult.errors && Array.isArray(errorResult.errors)) {
        errorResult.errors = errorResult.errors.map((error) => {
          if (!errorResult.meta?.requestId) {
            return error
          }
          return {
            ...error,
            message: error.message.replace(
              'request_id',
              `request_id: ${errorResult.meta.requestId}`
            ),
          }
        })
        return errorResult
      }
    }

    // request has been cancelled, throw error and surface to caller
    if (err instanceof CanceledError) {
      throw err
    }

    if (typeof err === 'string') {
      // special handling of 504 Gateway Timeout where the server would return
      // the 504 gateway timeout html string
      if (err.startsWith('<html>')) {
        // if it's 504, use 504-specific message
        if (err.includes('504 Gateway')) {
          captureException(new Error(`'504 Gateway - on ${config.url}`))
          return {
            errors: [
              {
                title: '',
                code: 'gateway_timeout_error',
                message: 'The request timed out. Please try again later.',
              },
            ],
          }
        }
        // otherwise, use a generic error message
        captureException(new Error('BaseClient encountered an error'), {
          errorResult: err,
          requestMethod: config.method,
          requestPath: config.url,
        })
        return {
          errors: [
            {
              title: '',
              code: 'unknown',
              message: 'Something went wrong. Please try again later.',
            },
          ],
        }
      }
      return {
        errors: [{ title: '', code: 'unknown', message: err }],
      }
    }

    // TODO: maybe find out what other types of errorResult we can expect here later
    return err as any
  }

  async requestWithPagination<T_APIResponseData>(
    config: AxiosRequestConfig,
    { before, after, limit }: PaginationMeta
  ): Promise<APIResponse<T_APIResponseData>> {
    try {
      this.hooks.beforeSendRequest.forEach((hook) => (config = hook(config)))
      let { data } = await this.axios({
        ...config,
        params: { before, after, limit, ...config.params },
      })
      this.hooks.onReceiveResponse.forEach((hook) => (data = hook(data)))
      return data
    } catch (errorResult: any) {
      return errorResult
    }
  }

  rawRequest<T_APIResponseData>(
    config: AxiosRequestConfig
  ): AxiosPromise<T_APIResponseData> {
    return this.axios(config)
  }

  hooks = {
    onReceiveResponse: new Array<responseHookFunction>(),
    beforeSendRequest: new Array<requestHookFunction>(),
  }

  registerBeforeSendRequest = (fn: requestHookFunction) => {
    this.hooks.beforeSendRequest.push(fn)
  }
  clearBeforeSendRequest = () => {
    this.hooks.beforeSendRequest = []
  }

  registerOnWillReceiveResponse = (fn: responseHookFunction) => {
    this.hooks.onReceiveResponse.push(fn)
  }
  clearOnWillReceiveResponse = () => {
    this.hooks.onReceiveResponse = []
  }

  getRequestOptions(
    context?: DuffelContext
  ): { baseURL: string; headers: any } | Record<string, unknown> {
    const csrfHeader = getCSRFToken(context)

    const ctx = context || ({} as DuffelContext)
    const headers: IncomingHttpHeaders & Record<string, unknown> =
      ctx.req?.headers ?? {}

    // these headers will determine the token used for making API calls
    if (this.liveMode !== undefined) {
      headers[LIVE_MODE_HEADER_NAME] = String(this.liveMode)
    }
    if (this.org !== undefined) {
      headers[ORG_HEADER_NAME] = this.org
    }

    headers[CSRF_HEADER_NAME] = csrfHeader

    if (!ctx.req) {
      return { headers }
    }

    return {
      baseURL: getUrl(ctx.req).origin,
      headers,
    }
  }
}
