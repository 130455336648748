import * as React from 'react'
import {
  PolymorphicComponentPropsWithRef,
  PolymorphicRef,
} from '../../../shared/foundations'
import { TypeStyle, Color } from '../../styles'
import styles from '../../../shared/components/Text/Text.module.css'
import classNames from 'classnames'

export const TEXT_ALIGNMENTS = ['leading', 'center', 'trailing'] as const
export type TextAlignment = (typeof TEXT_ALIGNMENTS)[number]

export const FONT_WEIGHTS = ['medium', 'bold', 'normal'] as const
export type FontWeight = (typeof FONT_WEIGHTS)[number]

export type TextProps<T_ElementName extends React.ElementType = 'p'> =
  PolymorphicComponentPropsWithRef<
    T_ElementName,
    {
      /**
       * Allows you to specify this component's HTML element
       *
       * - - -
       *
       * Default: `p`
       */
      asElement?: T_ElementName

      /**
       * Specifies the color of text according to our pre-defined css variables.
       *
       * - - -
       *
       * Default: `inherit`
       */
      color?: Color | 'inherit'

      /**
       * The font size of the text according to our pre-defined css variables.
       */
      typeStyle?: TypeStyle

      /**
       * The weight to use for the font.
       *
       * Note: `'bold'` is only available for the `'mono1'` type style. `'medium'`
       * is available for all type styles except `'mono1'`.
       */
      fontWeight?: FontWeight

      /**
       * Specifies the horizontal alignment of text in an element.
       *
       * - - -
       *
       * Default: `leading`
       */
      textAlign?: TextAlignment
    }
  >

export const Text = React.forwardRef(
  (
    {
      asElement,
      color = 'inherit',
      textAlign,
      typeStyle,
      fontWeight,
      className,
      children,
      ...props
    }: TextProps<any>,
    ref?: PolymorphicRef<any>
  ) => {
    const Element = asElement || 'p'
    const cx = classNames(
      styles['text'],
      color && styles[`text--${color}`],
      typeStyle && styles[`text--${typeStyle}`],
      fontWeight && styles[`text--${fontWeight}`],
      textAlign && styles[`text--${textAlign}`],
      className
    )

    return (
      <Element {...props} className={cx} ref={ref}>
        {children}
      </Element>
    )
  }
)
