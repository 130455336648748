import { DuffelAPI } from '@lib/types'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.Airport => ({
    id: `arp_${(seedId++).toString().padStart(5, '0')}`,
    iataCode: 'LHR',
    name: 'Heathrow Airport',
    iataCountryCode: 'GB',
    latitude: 51.47,
    longitude: -0.4543,
    icaoCode: 'EGLL',
    timeZone: 'Europe/London',
    iataCityCode: 'LON',
    type: 'airport',
    cityName: 'London',
    city: null,
  })
})()

export const makeMockOfferSlicePlace = (
  extendDefault?: Partial<DuffelAPI.Types.Airport>
): DuffelAPI.Types.Airport =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
