import classNames from 'classnames'
import * as React from 'react'
import { Button } from '@components/Button'
import { HSpace } from '@components/HSpace'
import { Icon } from '@components/Icon'
import { Stamp } from '@components/Stamp'
import { Text } from '@components/Text'
import { Tooltip } from '@components/Tooltip'
import { VSpace } from '@components/VSpace'
import styles from './CardDisplay.module.css'
import { getCardExpiryStatus } from './get-card-expiry-status'

interface SavedCardProps {
  className?: string
  cardType?: string
  cardNumberLast4?: string
  cardExpMonth?: number
  cardExpYear?: number
}

export interface CardDisplayProps extends SavedCardProps {
  fill?: boolean
  children?: React.ReactNode
}

export const CardDisplay: React.FC<CardDisplayProps> = ({
  className,
  cardType,
  cardNumberLast4,
  cardExpMonth,
  cardExpYear,
  fill = false,
  children,
}) => {
  const [isChanging, setIsChanging] = React.useState(!cardExpYear)
  const expiryStatus =
    cardExpYear && cardExpMonth
      ? getCardExpiryStatus(cardExpYear, cardExpMonth)
      : 'error'
  const wrapperClassNames = classNames(
    styles['card-display__container'],
    className
  )

  React.useEffect(() => {
    setIsChanging(false)
  }, [cardType, cardNumberLast4, cardExpYear, cardExpMonth])

  const expiresText =
    cardExpMonth &&
    cardExpYear &&
    `Expires ${cardExpMonth.toString().padStart(2, '0')}/${cardExpYear
      .toString()
      .slice(-2)}`

  return (
    <VSpace space={16} className={wrapperClassNames}>
      {expiryStatus === 'expired' && (
        <div className="card-display__callout">
          <b>Your card has expired.</b> Please add a new payment method
        </div>
      )}

      {cardType && cardNumberLast4 && cardExpMonth && cardExpYear && (
        <div
          className={classNames('card-display', {
            'card-display--editable': children !== undefined,
          })}
        >
          <VSpace space={8}>
            <Text fontSize="C1" color="grey-600">
              Card on file
            </Text>
            <HSpace
              space={0}
              fill={fill}
              spaceBetween
              className={styles['card-display__details']}
            >
              <HSpace space={16} className={styles['card-display__details']}>
                <Icon name="credit_card" />
                <span className={styles['card-display__type']}>{cardType}</span>
                &nbsp;ending in {cardNumberLast4}
                {expiryStatus !== 'error' &&
                  expiryStatus !== 'expires_soon' && (
                    <span className={styles['card-display__expires-text']}>
                      {expiresText}
                    </span>
                  )}
                {expiryStatus == 'expires_soon' && (
                  <div className="card-display__expires-soon">
                    <Tooltip text={expiresText} placement="top">
                      <Stamp
                        color="pink"
                        label="Expires soon"
                        borderRadius="full"
                      />
                    </Tooltip>
                  </div>
                )}
              </HSpace>
              <div className={styles['card-display__action-button']}>
                {children &&
                  (expiryStatus === 'expired' ? (
                    <Stamp color="pink" label="Card expired" />
                  ) : (
                    <Button
                      text={isChanging ? 'Cancel' : 'Change'}
                      intent={isChanging ? 'MUTED' : 'PRIMARY'}
                      onClick={() => setIsChanging(!isChanging)}
                    />
                  ))}
              </div>
            </HSpace>
          </VSpace>
        </div>
      )}
      {(!cardExpYear || isChanging || expiryStatus === 'expired') && children}
    </VSpace>
  )
}
