import { getAirlineFromIataCode } from '@lib/sources'
import { SourceId } from '@lib/sources/DuffelSources'
import { DuffelProxy } from '@lib/types/DuffelProxy'
import { SourceAirlineWithSourceId } from '../FormFields/AirlinesSelect/AirlinesSelect'
import { convertAirlinesListToSourceAirlineWithSourceIds } from './convert-airlines-list-to-source-airline-with-source-ids'

export const convertToSourceAirlineWithSourceId = (
  value: SourceId[] | DuffelProxy.Types.SourceAirline['iataCode']
): SourceAirlineWithSourceId[] => {
  if (typeof value === 'string') {
    const sourceAirlineFromIataCode = getAirlineFromIataCode(value)
    return sourceAirlineFromIataCode ? [sourceAirlineFromIataCode] : []
  }

  return convertAirlinesListToSourceAirlineWithSourceIds(value)
}
