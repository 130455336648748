import { DuffelProxy } from '@lib/types/DuffelProxy'
import { client } from './client'
import { transformSelf } from './data-helpers'
import { NotAuthorisedError } from './error-responses'
import { logger } from './logging/logger'

// Adding this constant to disable the cache for now. This is because
// at times we return the cached self after it has been modified. Like when a new organisation is created.
// We should come back here and completely remove the cache when we can: https://duffel.atlassian.net/browse/UXP-3471
const DISABLE_CACHE = true

// This cache uses the personal token used after signing in as the key for
// two reasons:
// 1. Such token is the one being persisted in the cookie. This means that as
// long as the cookie hasn't expired, the token can be used to retrieve this self.
// 2. Using the token for retrieval means that we could include the fallback request
// when the token is not found in the cache too.
//
// One caveat is that we have 3 instances of dashboard running at any given time, which makes
// cache invalidation unreliable. For now, we will try to make the cache lifetime very short
// to ensure that the user doesn't have to have stale self for too long.
const CACHE_LIFETIME_MS = 60 * 1000

const cache = new Map<
  string,
  {
    self: DuffelProxy.Types.Self
    updatedAt: number
  }
>()

const set = (token: string, self: DuffelProxy.Types.Self) =>
  cache.set(token, { self, updatedAt: Date.now() })

async function getSelfFromAPI(token: string): Promise<DuffelProxy.Types.Self> {
  const { status, body } = await client<DuffelProxy.Types.Self>(
    'GET',
    `/identity/self`,
    token,
    {},
    undefined
  )

  // if this happens, consider it an authentication error
  if (!body?.data || status != 200) {
    throw new NotAuthorisedError()
  }

  const newSelf = transformSelf(body.data)
  set(token, newSelf)
  return newSelf
}

const getOrFetch = async (token: string): Promise<DuffelProxy.Types.Self> => {
  if (DISABLE_CACHE) return getSelfFromAPI(token)

  const data = cache.get(token)
  const cacheAge = Date.now() - data!.updatedAt

  if (data !== undefined && cacheAge <= CACHE_LIFETIME_MS) {
    logger.info({ message: 'dashboard self cache: hit' })
    return data.self
  } else {
    logger.info({ message: 'dashboard self cache: missed' })
    return getSelfFromAPI(token)
  }
}

const remove = (token: string) => cache.delete(token)

const maybeInvalidate = (token: string, lastInvalidatedAt: number) => {
  const self = cache.get(token)

  if (self && self.updatedAt < lastInvalidatedAt) {
    cache.delete(token)
  }
}

export default { getOrFetch, set, remove, maybeInvalidate }
