import { Formik } from 'formik'
import * as React from 'react'
import { boolean, object, string } from 'yup'
import { Button } from '@components/Button'
import { Form, FormikTextArea } from '@components/Form'
import { FormikSubmitButton } from '@components/Form/FormikSubmitButton'
import { FormikSupportContactEmail } from '@components/Form/FormikSupportContactEmail'
import { HSpace } from '@components/HSpace'
import { VSpace } from '@components/VSpace'
import { Icon } from '@components/Icon'
import { Text } from '@components/Text'
import { FormikCheckbox } from '@components/Form/FormikCheckbox'
import { validEmailAddressRequired } from '@lib/form-validation'
import { DuffelAPI } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import { getDefaultContactSupportEmail } from '@lib/helpers/get-default-contact-support-email'
import { getOrderStatus } from '@modules/air-order/helpers'
import {
  OrderSupportFormValues,
  ErrorSupportOrderManagementAction,
  SupportOrderManagementAction,
  SupportType,
} from '../types'
import {
  getErrorOrderSupportTicketMessage,
  getUnavailableActionSupportTicketMessage,
} from '../lib'
import styles from './OrderSupportContactForm.module.css'

const getOrderSupportContactFormValidationSchema = () =>
  object().shape({
    email: validEmailAddressRequired(),
    message: string().required('Message is required'),
    requestingVoid: boolean(),
  })

interface OrderSupportContactFormDefaultProps {
  order: DuffelAPI.Types.Order
  supportType: SupportType
  isAutoHighPriority: boolean
  onSubmit: (values: OrderSupportFormValues) => void
  onClose?: () => void
  onBack?: () => void
}

interface ErrorOrderSupportContactFormProps
  extends OrderSupportContactFormDefaultProps {
  supportType: 'error'
  actionToSupport: ErrorSupportOrderManagementAction
}

interface UnavailableActionOrderSupportContactFormProps
  extends OrderSupportContactFormDefaultProps {
  supportType: 'action_unavailable'
  actionToSupport: SupportOrderManagementAction
}

export type OrderSupportContactFormProps =
  | ErrorOrderSupportContactFormProps
  | UnavailableActionOrderSupportContactFormProps

export const OrderSupportContactForm: React.FC<
  OrderSupportContactFormProps
> = ({
  order,
  actionToSupport,
  supportType,
  isAutoHighPriority,
  onSubmit,
  onClose,
  onBack,
}) => {
  const { user, currentOrganisation } = useWorkspace()

  if (!user || !currentOrganisation) {
    throw Error(
      'Order management error form opened without user or currentOrganisation object'
    )
  }

  const initialMessage =
    supportType === 'error'
      ? getErrorOrderSupportTicketMessage(
          order,
          actionToSupport,
          user.fullName,
          currentOrganisation.name
        )
      : getUnavailableActionSupportTicketMessage(
          order,
          actionToSupport,
          user.fullName,
          currentOrganisation.name
        )

  const shouldShowVoidCheckbox =
    !isAutoHighPriority &&
    actionToSupport === 'cancel_order' &&
    getOrderStatus(order) !== 'on-hold'

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        email: getDefaultContactSupportEmail(
          user.email,
          currentOrganisation.contactEmails
        ),
        message: initialMessage,
        requestingVoid: false,
      }}
      validateOnBlur
      validationSchema={getOrderSupportContactFormValidationSchema()}
    >
      {({ handleSubmit, isValid }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <VSpace space={24}>
              <FormikTextArea name="message" label="Message" />
              {shouldShowVoidCheckbox && (
                <FormikCheckbox
                  name="requestingVoid"
                  label="This order is voidable"
                  size="small"
                  intent="danger"
                  hint="Only use this if you think this order is within its void window."
                />
              )}
              {isAutoHighPriority && (
                <HSpace space={8} data-selector="fs-show">
                  <Icon name="alert" className={styles['high-priority-icon']} />
                  <Text fontSize="C2" color="grey-700">
                    This request will be flagged as high priority, as the flight
                    is departing in the next 48 to 72 hours
                  </Text>
                </HSpace>
              )}
              <FormikSupportContactEmail
                name="email"
                label="Send replies to"
                userEmail={user.email}
                orgContactEmails={currentOrganisation?.contactEmails}
              />
              <HSpace space={8} data-selector="fs-show">
                {onClose && (
                  <Button
                    className="u-marginRight8"
                    text={onBack ? 'Back' : 'Close'}
                    onClick={onBack ? () => onBack() : () => onClose()}
                    outlined
                    fill
                    medium
                    intent="MUTED"
                  />
                )}
                <FormikSubmitButton
                  text="Submit"
                  fill
                  large={false}
                  medium
                  // @TODO: move this inside FormikSubmitButton later?
                  disabled={!isValid}
                />
              </HSpace>
            </VSpace>
          </Form>
        )
      }}
    </Formik>
  )
}
