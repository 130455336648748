import { Card } from '@components/Card'
import { Form } from '@components/Form'
import { NonIdealState } from '@components/NonIdealState'
import { Notice } from '@components/Notice'
import {
  PaymentMethodSelect,
  PaymentMethodSelectProps,
} from '@components/PaymentMethodSelect'
import { VSpace } from '@components/VSpace'
import { CreateOrderPassenger, Offer, SeatMap } from '@duffel/api/types'
import { DuffelAncillaries } from '@duffel/components'
import { Text } from '@components/legacy-design-system/product/components/Text'
import { toSnakeCaseKeys } from '@lib/proxy/lib/case-utils'
import { captureException } from '@lib/sentry'
import { DuffelAPI } from '@lib/types'
import { useFeatureFlags } from '@lib/unleash'
import {
  CheckoutDivider,
  CheckoutSection,
} from '@modules/air-search-v2/components/Checkout/CheckoutLayout'
import { CheckoutPaymentSummary } from '@modules/air-search-v2/components/Checkout/CheckoutPaymentSummary'
import { CheckoutOrderMetadata } from '@modules/air-search-v2/components/CheckoutOrderMetadata'
import { CheckoutPayLaterSummary } from '@modules/air-search-v2/components/CheckoutPayLater'
import {
  CheckoutFormValues,
  areLoyaltyAccountsAppliedToThePassenger,
  getCheckoutFormValidationSchema,
  getInitialCheckoutFormValues,
  getOfferLastDepartureDate,
  transformCheckoutFormValuesIntoPayload,
} from '@modules/air-search-v2/lib'
import { useCheckoutContext } from '@modules/air-search-v2/lib/use-checkout-context'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import * as React from 'react'
import { useRef } from 'react'
import { areLoyaltyAccountsApplied } from '../../lib'
import { ContactDetails } from './form-fields/ContactDetails'
import { PassengerInfo } from './form-fields/PassengerInfo'

interface CheckoutFormRendererProps {
  formik: FormikProps<CheckoutFormValues>
  componentClientKey?: string
  isOfferExpired: boolean
}

const CheckoutFormRenderer: React.FC<CheckoutFormRendererProps> = ({
  formik,
  componentClientKey,
  isOfferExpired,
}) => {
  // dashboard_enable_hold_orders_with_services is currently only turned on for duffel orgs and specific suppliers
  const enableHoldOrdersWithServices = useFeatureFlags(
    'dashboard_enable_hold_orders_with_services'
  )

  const {
    paymentButtonClickCount,
    seatMaps,
    offer,
    paymentType,
    setPaymentType,
  } = useCheckoutContext()

  const servicesDisabled =
    paymentType === 'pay_later' && !enableHoldOrdersWithServices

  const paymentMethodSelectRef = useRef<HTMLDivElement>(null)
  const paymentOptions: PaymentMethodSelectProps['options'] = [
    {
      value: 'instant',
      label: 'Pay now',
      description: 'Pay now and confirm seat and baggage selection',
    },
    { value: 'pay_later', label: '', description: '' },
  ]

  const onReviewButtonClick = (event) => {
    if (paymentMethodSelectRef && paymentMethodSelectRef.current) {
      return paymentMethodSelectRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
    return event.preventDefault()
  }

  const noPaymentMethodsAllowed =
    !offer.availablePaymentTypes || offer.availablePaymentTypes.length === 0

  if (noPaymentMethodsAllowed) {
    captureException(
      Error(`No available payment methods found for ${offer.id}`)
    )
    return (
      <Card center>
        <NonIdealState
          title="No available payment methods"
          description="Sorry, there is an issue with payment methods on this offer. This has been reported to Duffel."
          data-selector="fs-show"
        />
      </Card>
    )
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      <VSpace space={40}>
        {!offer.paymentRequirements.requiresInstantPayment && (
          <CheckoutSection heading="Paying now, or later?">
            <PaymentMethodSelect
              ref={paymentMethodSelectRef}
              options={paymentOptions}
              paymentRequirements={offer.paymentRequirements}
              handlePaymentMethodClick={(value) => {
                formik.setFieldValue('type', value)
                setPaymentType(value)
              }}
              currentPaymentSelected={paymentType}
            />
          </CheckoutSection>
        )}

        {!formik.isValid && paymentButtonClickCount > 0 && (
          <div id="passenger-info-form-alert-container">
            <Notice intent="severe" iconName="alert">
              Please fix the errors below before you continue
            </Notice>
          </div>
        )}

        <CheckoutSection heading="Contact details">
          <ContactDetails />
        </CheckoutSection>

        <CheckoutDivider />

        <CheckoutSection heading="Passengers">
          <VSpace space={24}>
            {formik.values.passengers.map((passenger, index) => (
              <PassengerInfo
                key={`passenger-form-${passenger.id}`}
                passenger={passenger}
                index={index}
                loyaltyAccountsApplied={areLoyaltyAccountsAppliedToThePassenger(
                  passenger
                )}
              />
            ))}
          </VSpace>
        </CheckoutSection>

        <CheckoutDivider />

        <CheckoutSection heading="Add extras">
          {!servicesDisabled ? (
            <DuffelAncillaries
              debug
              offer={toSnakeCaseKeys(offer) as any as Offer}
              seat_maps={(seatMaps?.map(toSnakeCaseKeys) as SeatMap[]) || []}
              services={['bags', 'seats', 'cancel_for_any_reason']}
              passengers={formik.values.passengers.map(
                (passenger) =>
                  ({
                    id: passenger.id,
                    given_name: passenger.givenName,
                    family_name: passenger.familyName,
                    gender: passenger.gender,
                    title: passenger.title,
                    born_on:
                      passenger.bornOn?.toDateString().split('T')[0] ||
                      'unknown',
                  } as CreateOrderPassenger)
              )}
              onPayloadReady={({ services }, metadata) => {
                formik.setFieldValue('selectedServices', services)
                formik.setFieldValue('selectedServicesMetadata', metadata)
              }}
            />
          ) : (
            <Notice
              type="action"
              onClickActionButton={onReviewButtonClick}
              actionButtonText="Review"
            >
              Adding extras is only available on{' '}
              <Text fontWeight="medium" asElement="span" color="blue-900">
                pay now
              </Text>{' '}
              orders.
            </Notice>
          )}
        </CheckoutSection>

        <CheckoutDivider />

        <CheckoutSection heading="Add metadata">
          <CheckoutOrderMetadata />
        </CheckoutSection>

        <CheckoutDivider />

        {paymentType === 'instant' && (
          <CheckoutSection heading="Payment">
            <CheckoutPaymentSummary
              componentClientKey={componentClientKey}
              isOfferExpired={isOfferExpired}
            />
          </CheckoutSection>
        )}

        {paymentType === 'pay_later' && (
          <CheckoutSection heading="Confirm and pay later">
            <Card>
              <CheckoutPayLaterSummary
                priceGuaranteeExpiresAt={
                  offer.paymentRequirements.priceGuaranteeExpiresAt || null
                }
                paymentRequiredBy={
                  offer.paymentRequirements.paymentRequiredBy || null
                }
              />
            </Card>
          </CheckoutSection>
        )}
      </VSpace>
    </Form>
  )
}

interface CheckoutFormProps {
  onSubmit: (
    payload: DuffelAPI.Inputs.Order,
    formikValues: CheckoutFormValues,
    formik: FormikHelpers<CheckoutFormValues>
  ) => void
  componentClientKey?: string
  isOfferExpired: boolean
}

export const CheckoutForm: React.FC<CheckoutFormProps> = ({
  onSubmit,
  componentClientKey,
  isOfferExpired,
}) => {
  const { offer, partialOfferRequest, randomlyGeneratedPassengers } =
    useCheckoutContext()

  return (
    <Formik
      initialValues={getInitialCheckoutFormValues(
        offer,
        partialOfferRequest,
        randomlyGeneratedPassengers
      )}
      validateOnBlur
      validationSchema={getCheckoutFormValidationSchema(
        offer.passengerIdentityDocumentsRequired,
        areLoyaltyAccountsApplied(offer),
        getOfferLastDepartureDate(offer)
      )}
      onSubmit={(values, formikHelpers) =>
        onSubmit(
          transformCheckoutFormValuesIntoPayload(values, offer),
          values,
          formikHelpers
        )
      }
    >
      {(formik) => (
        <CheckoutFormRenderer
          formik={formik}
          componentClientKey={componentClientKey}
          isOfferExpired={isOfferExpired}
        />
      )}
    </Formik>
  )
}
