import { getDayDiff } from '@lib/date'
import { captureException } from '@lib/sentry'

const threeMonths = 90

export const getCardExpiryStatus = (
  cardExpYear: number,
  cardExpMonth: number
): 'expired' | 'expires_soon' | 'normal' | 'error' => {
  try {
    const today = new Date()
    if (typeof cardExpYear !== 'number' || typeof cardExpMonth !== 'number')
      return 'error'
    const expiryDate = new Date(
      `${cardExpYear}-${cardExpMonth.toString().padStart(2, '0')}-01`
    )
    const dayDiff = getDayDiff(today, expiryDate)

    if (dayDiff >= 0) {
      return 'expired'
    }
    if (dayDiff > -threeMonths) {
      return 'expires_soon'
    }
    return 'normal'
  } catch (error: any) {
    const message = `Invalid date when checking card expiry status: year ${cardExpYear} and month ${cardExpMonth}`
    captureException(Error(message))
    return 'error'
  }
}
