import classNames from 'classnames'
import * as React from 'react'
import { Color } from '@components/legacy-design-system/product/styles'
import { FontSize } from '@lib/styles'

export interface TextProps {
  /**
   * Allows you to specify this component's HTML element
   *
   * - - -
   *
   * Default: `p`
   */
  asElement?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'p'
    | 'div'
    | 'span'
    | 'strong'
    | 'em'

  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string

  /**
   * Specifies the color of text according to our pre-defined css variables.
   *
   * - - -
   *
   * Default: `grey-900`
   */
  color?: Color

  /**
   * The font size of the text according to our pre-defined css variables.
   *
   *  __Display__
   * - `D1: 48px` | `D2: 40px`
   *
   *
   * __Heading__
   * - `H1 32px` | `H2 24px` | `H3 20px`
   *
   * __Copy__
   * - `C1 16px` | `C2 14px` | `C3 12px`
   *
   * - - -
   *
   * Default: `C1`
   */
  fontSize?: FontSize

  /**
   * Sets how thick or thin characters in text should be displayed.
   *
   * - - -
   *
   * Default: `regular`
   */
  fontWeight?: 'regular' | 'medium'

  /**
   * Specifies the height of a line of text
   *
   * - `solid 100%` | `tight 130%` | `copy 150%`
   *
   * - - -
   *
   * Default: `copy`
   */
  lineHeight?: 'solid' | 'tight' | 'copy'

  /**
   * Sets a maximum width for better readability
   *
   * - - -
   *
   * Default: false
   */
  measure?: boolean

  /**
   * Specifies the horizontal alignment of text in an element.
   *
   * - - -
   *
   * Default: `left`
   */
  textAlign?: 'left' | 'right' | 'center'
}

/**
 * @deprecated replaced by `@duffel/design-system/product/components/Text` component
 */
export const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  asElement = 'p',
  className,
  color = 'grey-900',
  fontSize = 'C1',
  fontWeight = 'regular',
  lineHeight = 'copy',
  measure,
  textAlign,
  children,
  ...props
}) => {
  const fontSizeVar = `--FONT-SIZES-${fontSize}`
  const colorVar = `--${color}`
  const Element = asElement

  const cx = classNames('text', className, {
    'text--center': textAlign === 'center',
    'text--right': textAlign === 'right',
    'text--medium': fontWeight === 'medium',
    'text--tight': lineHeight === 'tight',
    'text--solid': lineHeight === 'solid',
    'text--measure': measure,
  })

  return (
    <Element className={cx} {...props}>
      {children}
      <style jsx>{`
        .text {
          margin: 0px;
          padding: 0px;

          text-align: left;
          line-height: 1.5;
          font-weight: 400;

          color: var(${colorVar});
          font-size: var(${fontSizeVar});
        }

        .text--center {
          text-align: center;
        }
        .text--right {
          text-align: right;
        }

        .text--medium {
          font-weight: 600;
        }

        .text--tight {
          line-height: 1.3;
        }
        .text--solid {
          line-height: 1;
        }

        .text--measure {
          max-width: 32em;
        }

        .text--mono {
          font-family: var(--FONTS-MONO);
        }
      `}</style>
    </Element>
  )
}
