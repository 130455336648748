import * as React from 'react'
import { Button } from '@components/Button'
import { Heading } from '@components/Heading'
import { Modal, ModalProps } from '@components/Modal'
import { VSpace } from '@components/VSpace'
import { ContactSupportCategory } from '@lib/zendesk'
import { DuffelAPI } from '@lib/types'
import { SupportContact } from '../SupportContact'

export interface SupportModalProps extends ModalProps {
  initialCategory?: ContactSupportCategory
  initialMessage?: string
  order?: DuffelAPI.Types.Order
}

export const SupportModal: React.FC<SupportModalProps> = ({
  initialCategory,
  initialMessage,
  order,
  ...modalProps
}) => {
  return (
    <Modal {...modalProps} width={540}>
      <VSpace space={24}>
        <Heading h2 data-selector="fs-show">
          Contact Support
        </Heading>
        <VSpace space={8}>
          <SupportContact
            initialCategory={initialCategory}
            initialMessage={initialMessage}
            order={order}
          />
          <Button
            large
            fill
            onClick={modalProps.onClose}
            text="Close"
            intent="MUTED"
          />
        </VSpace>
      </VSpace>
      <style jsx>{`
        :global(.support-contact__form) {
          padding: none !important;
        }
      `}</style>
    </Modal>
  )
}
