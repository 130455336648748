// https://www.figma.com/file/0YkFzIjNQvTUXnygsdqy87/Foundations?t=JCMyKkJL3gT3rMl1-0

export const BORDER_RADIUSES = {
  full: '--border-radius-full',
  8: '--border-radius-8',
  6: '--border-radius-6',
  4: '--border-radius-4',
  2: '--border-radius-2',
}

export type BorderRadius = keyof typeof BORDER_RADIUSES
