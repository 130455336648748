import { DuffelAPI } from '@lib/types'
import { makeMockUser } from './make-mock-user'
import { makeMockOrganisation } from '.'

const getDefaultObject = (() => {
  let seedId = 1
  return (): DuffelAPI.Types.Invitation => ({
    acceptedAt: null,
    createdAt: '2021-03-24T14:47:43.717862Z',
    expiresAt: '2021-04-07T14:47:43.714867Z',
    id: `oiv_${(seedId++).toString().padStart(5, '0')}`,
    organisation: makeMockOrganisation(),
    organisationId: `org_${seedId.toString().padStart(5, '0')}`,
    recipient: makeMockUser(),
    recipientEmail: `test${seedId.toString().padStart(3, '0')}@duffel.com`,
    recipientId: '',
    revokedAt: '',
    scope: 'roles/user/developer',
    sender: makeMockUser(),
    senderId: `usr_${seedId.toString().padStart(5, '0')}`,
    sentAt: Date.now().toString(),
    token: 'token',
  })
})()

export const makeMockOrganisationInvitation = (
  extendDefault?: Partial<DuffelAPI.Types.Invitation>
): DuffelAPI.Types.Invitation =>
  Object.assign({}, getDefaultObject(), extendDefault || {})
