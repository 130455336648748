import * as AtDuffelAPITypes from '@duffel/api/types'
import {
  AcceptedCurrencies,
  CountryCode,
  EURO_ZONE_COUNTRY_CODES,
} from '@lib/constants'

// 'any' is needed for filters and forms
export type CabinClass = AtDuffelAPITypes.CabinClass | 'any'

export const CabinClassMap: Record<CabinClass, string> = {
  economy: 'Economy',
  premium_economy: 'Premium Economy',
  business: 'Business',
  first: 'First',
  any: 'Any',
}

export type PassengerType = AtDuffelAPITypes.PassengerType

export const PassengerTypeMap: Record<PassengerType, string> = {
  adult: 'Adult',
  child: 'Child',
  infant_without_seat: 'Infant',
}

export type PassengerTitle = AtDuffelAPITypes.DuffelPassengerTitle

export const PassengerTitleMap: Record<PassengerTitle, string> = {
  mr: 'Mr.',
  ms: 'Ms.',
  mrs: 'Mrs.',
  miss: 'Miss',
  dr: 'Dr.',
}

export type PassengerGender = AtDuffelAPITypes.DuffelPassengerGender

// Disable minimum variable length here as they are intentionally
// single character.
/* eslint-disable id-length */
export const PassengerGenderMap: Record<PassengerGender, string> = {
  m: 'Male',
  f: 'Female',
}
/* eslint-enable */

export const SearchTypeMap = {
  one_way: 'One way',
  return: 'Return',
  multi_city: 'Multi-city',
}
export type SearchType = keyof typeof SearchTypeMap

export const SourceOfFundsMap = {
  revenue: 'Revenue',
  capital_invested: 'Capital invested',
  debt: 'Debt',
  other: 'Other',
}
export type SourceOfFunds = keyof typeof SourceOfFundsMap

export type LegalEntityType = AtDuffelAPITypes.LegalEntityType

export const LegalEntityTypeMap: Record<LegalEntityType, string> = {
  corporation: 'Corporation',
  sole_proprietorship: 'Sole proprietorship',
  partnership: 'Partnership',
  non_profit: 'Non-profit',
  other: 'Other',
}

export type VerificationFlow = 'stripe_connect' | 'duffel_2020'
export type StripeConnectVerificationStatus =
  | 'requirements_due'
  | 'in_review'
  | 'verified'

const CountryCurrencyMap: Partial<Record<CountryCode, AcceptedCurrencies>> = {
  GB: 'GBP',
  AU: 'AUD',
  CA: 'CAD',
}

EURO_ZONE_COUNTRY_CODES.forEach((code: CountryCode) => {
  CountryCurrencyMap[code] = 'EUR'
})

export { CountryCurrencyMap }
