import * as React from 'react'

export const WithPortalWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <div>
      <div id="__portal__"></div>
      {children}
    </div>
  )
}
