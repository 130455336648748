import * as React from 'react'
import { Accordion } from '@components/Accordion'
import { Card } from '@components/Card'
import { FlightSummary } from '@components/FlightSummary'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { trackEvent } from '@lib/tracking'
import { DuffelAPI } from '@lib/types'
import { applyChangeToOrder } from '@modules/air-order-change/lib'

export interface ChangesSummaryProps {
  order: DuffelAPI.Types.Order
  orderChange: DuffelAPI.Types.OrderChange
}

export const ChangesSummary: React.FC<ChangesSummaryProps> = ({
  order,
  orderChange,
}) => {
  const orderWithChangeApplied = applyChangeToOrder(order, orderChange)

  return (
    <VSpace space={16} className="changes-summary" data-selector="fs-show">
      <Text fontSize="H3">Changes summary</Text>
      <Card>
        <VSpace space={32}>
          <Accordion
            title="New flight details"
            shouldMountExpanded
            contentClassName="flight-summary-content"
            onExpansionToggled={(will_expand) => {
              trackEvent(
                'dashboard_change_flights_offer_checkout_new_details_accordion_header_clicked',
                {
                  event_type: 'interaction',
                  order_id: order.id,
                  order_change_offer_id: orderChange.id,
                  will_expand,
                }
              )
            }}
          >
            <FlightSummary
              flight={orderWithChangeApplied}
              isExpanded
              onlyShowChangedSlices
              hideConditions
            />
          </Accordion>
          <div className="spacer" />
          <Accordion
            title="Previous flight details"
            contentClassName="flight-summary-content"
            onExpansionToggled={(will_expand) => {
              trackEvent(
                'dashboard_change_flights_offer_checkout_previous_details_accordion_header_clicked',
                {
                  event_type: 'interaction',
                  order_id: order.id,
                  order_change_offer_id: orderChange.id,
                  will_expand,
                }
              )
            }}
          >
            <FlightSummary flight={order} isExpanded hideConditions />
          </Accordion>
        </VSpace>
      </Card>
      <style jsx>{`
        .spacer {
          border-top: solid 1px var(--grey-200);
        }

        :global(.changes-summary .flight-summary-content) {
          border: none;
          padding-left: 0px;
          padding-right: 0px;
        }
      `}</style>
    </VSpace>
  )
}
