import { DuffelAPI } from '@lib/types'

export const getDefaultContactSupportEmail = (
  userEmail?: string,
  organisationContactEmails?: DuffelAPI.Types.Organisation['contactEmails']
) => {
  if (organisationContactEmails && organisationContactEmails.length > 0) {
    return organisationContactEmails[0]
  }

  return userEmail || ''
}
